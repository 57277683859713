import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../../components'
import React from 'react'
import validator from 'validator'

const validationSchema = () => {
  return yup.object().shape({ extensionInfo: yup.string().max(200) })
}

const ModalEditExtensionInfo = ({ onClose, location, onSubmit, mutation }) => {
  const intl = useIntl()

  const initialFormikData = ({ extensionInfo }) => {
    return {
      extensionInfo: extensionInfo || ''
    }
  }

  const hasValueChanged = (extensionInfo) => {
    return location.extensionInfo !== extensionInfo
  }

  const isValidUrl = (extensionInfo) => {
    return validator.isURL(extensionInfo)
  }

  const handleFormitSubmit = (values) => {
    const { extensionInfo } = values

    if (hasValueChanged(extensionInfo)) {
      onSubmit({
        locationRef: location.ref,
        extensionInfo
      })
    } else {
      onClose()
    }
  }

  return (
    <Formik
      initialValues={initialFormikData(location)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'modalEditExtensionInfo.title'
              },
              {
                name: location.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="modalEditExtensionInfo.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'modalEditExtensionInfo.label.url'
                })}
                value={values.extensionInfo}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                maxLength={200}
                name="extensionInfo"
                error={!!errors.extensionInfo}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={
                mutation.isPending ||
                !hasValueChanged(values.extensionInfo) ||
                !isValidUrl(values.extensionInfo)
              }
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditExtensionInfo
