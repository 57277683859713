import {
  ASSIGNMENT_STATUS_ACCEPTED,
  assignmentStatusMap,
  formatDate
} from '../../../services/utils'
import { Popup, Segment, Table } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'

import RelativeLinkForCurrentUser from '../../../containers/RelativeLinkForCurrentUser'
import TablePagination from '../../../components/TablePagination'
import { get } from '../../../services/utils/api-renewed'
import { renderUserName } from '../../../containers/UserInfo'
import useAuthInfo from '../../../hooks/useAuthInfo'

const renderVehicles = (vehicles) => {
  if (vehicles.length > 0) {
    return vehicles.map((vehicle) => (
      <Table.Row key={vehicle.ref}>
        <Table.Cell>{vehicle.licensePlateNumber}</Table.Cell>
        <Table.Cell>
          {vehicle.licensePlateRecognitionPermissions
            .allowLicensePlateBasedParkingFacilityAccess
            ? 'Kyllä'
            : 'Ei'}
        </Table.Cell>
      </Table.Row>
    ))
  }
  return (
    <Table.Row>
      <Table.Cell colSpan="2">Ei aktiivisia ajoneuvoja</Table.Cell>
    </Table.Row>
  )
}
const AssignmentsTable = ({
  assignments,
  currentActiveVehicleRefs,
  activePage,
  totalPages,
  changePage,
  sorted,
  direction,
  handleSort
}) => {
  const [selectedUserRef, setSelectedUserRef] = useState()
  const [userVehicles, setUserVehicles] = useState({ data: [] })

  const { operatorRealm } = useAuthInfo()

  useEffect(() => {
    const fetchUserVehicles = async () => {
      setUserVehicles({ data: [], loading: true })
      const path = operatorRealm
        ? `/operator/${operatorRealm}/user/${selectedUserRef}/vehicle`
        : `/administration/user/${selectedUserRef}/vehicle`
      const result = await get(path)
      setUserVehicles({
        data: result,
        loading: false
      })
    }
    if (selectedUserRef) {
      fetchUserVehicles()
    }
  }, [selectedUserRef])

  const { data } = (assignments.data && assignments.data) || { data: [] }

  return (
    <Segment basic loading={assignments.isLoading}>
      <Table sortable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sorted === 'startTime' ? direction : null}
              onClick={handleSort('startTime')}
            >
              Alkamispäivä
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sorted === 'endTime' ? direction : null}
              onClick={handleSort('endTime')}
            >
              Päättymispäivä
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sorted === 'customName' ? direction : null}
              onClick={handleSort('customName')}
            >
              Pysäköijä
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sorted === 'phoneNumber' ? direction : null}
              onClick={handleSort('phoneNumber')}
            >
              Puhelinnumero
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sorted === 'additionalInfo' ? direction : null}
              onClick={handleSort('additionalInfo')}
            >
              Lisätieto
            </Table.HeaderCell>
            <Table.HeaderCell>Tila</Table.HeaderCell>
            <Table.HeaderCell>Käyttäjä</Table.HeaderCell>
            <Table.HeaderCell>Aktiiviset ajoneuvot</Table.HeaderCell>
            <Table.HeaderCell>Ajoneuvoja enintään</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((assignment) => (
            <Table.Row
              key={assignment.ref}
              positive={assignment.status === ASSIGNMENT_STATUS_ACCEPTED}
            >
              <Table.Cell>{formatDate(assignment.startTime)}</Table.Cell>
              <Table.Cell>{formatDate(assignment.endTime)}</Table.Cell>
              <Table.Cell>{assignment.customName}</Table.Cell>
              <Table.Cell>{assignment.phoneNumber}</Table.Cell>
              <Table.Cell>{assignment.additionalInfo}</Table.Cell>
              <Table.Cell>{assignmentStatusMap[assignment.status]}</Table.Cell>
              <Table.Cell>
                {assignment.user ? (
                  <RelativeLinkForCurrentUser
                    to={`customers/${assignment.user.ref}`}
                  >
                    {renderUserName(assignment.user)}
                  </RelativeLinkForCurrentUser>
                ) : (
                  ''
                )}
              </Table.Cell>
              <Table.Cell>
                {assignment.user && (
                  <Popup
                    on="click"
                    basic
                    trigger={
                      <a
                        style={{ cursor: 'pointer' }}
                        onClick={() => setSelectedUserRef(assignment.user.ref)}
                      >
                        Näytä
                      </a>
                    }
                  >
                    <Popup.Content>
                      <Segment loading={userVehicles.loading} basic>
                        <Table compact>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>
                                Rekisteritunnus
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                Hallipysäköinti käytössä
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {renderVehicles(
                              userVehicles.data.filter((vehicle) =>
                                currentActiveVehicleRefs.includes(vehicle.ref)
                              )
                            )}
                          </Table.Body>
                        </Table>
                      </Segment>
                    </Popup.Content>
                  </Popup>
                )}
              </Table.Cell>
              <Table.Cell>{assignment.maxActiveVehicles}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TablePagination
          colSpan={9}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={changePage}
        />
      </Table>
    </Segment>
  )
}
export default AssignmentsTable
