export const ASSIGNMENT_STATUS_ACCEPTED = 'ACCEPTED'
export const ASSIGNMENT_STATUS_PENDING = 'PENDING'

export const assignmentStatusMap = {
  [ASSIGNMENT_STATUS_ACCEPTED]: 'Hyväksytty',
  [ASSIGNMENT_STATUS_PENDING]: 'Hyväksymättä'
}

export const paymentMethod = {
  VISMA: 'VI',
  PAYMENT_HIGHWAY: 'PH',
  WEB_PAYMENT: 'W',
  ADYEN: 'AD',
  localizationKeys: {
    VI: 'enum.paymentMethod.VISMA',
    PH: 'enum.paymentMethod.PAYMENT_HIGHWAY',
    W: 'enum.paymentMethod.WEB_PAYMENT',
    AD: 'enum.paymentMethod.ADYEN'
  }
}

export const paymentCardFetchType = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}

export const vehicleFetchType = {
  ALL: 'ALL',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
}

export const invoicingStatus = {
  INVOICED: 'INVOICED',
  NOT_INVOICED: 'NOT_INVOICED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PERMANENTLY_FAILED: 'PERMANENTLY_FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  REFUNDED: 'REFUNDED',
  WAIVED: 'WAIVED',
  CHARGEBACK: 'CHARGEBACK',
  localizationKeys: {
    INVOICED: 'enum.invoicingStatus.INVOICED',
    NOT_INVOICED: 'enum.invoicingStatus.NOT_INVOICED',
    PAYMENT_FAILED: 'enum.invoicingStatus.PAYMENT_FAILED',
    PERMANENTLY_FAILED: 'enum.invoicingStatus.PERMANENTLY_FAILED',
    IN_PROGRESS: 'enum.invoicingStatus.IN_PROGRESS',
    PAUSED: 'enum.invoicingStatus.PAUSED',
    REFUNDED: 'enum.invoicingStatus.REFUNDED',
    WAIVED: 'enum.invoicingStatus.WAIVED',
    CHARGEBACK: 'enum.invoicingStatus.CHARGEBACK'
  }
}

export const invoicingVismaStatus = {
  INVOICED: 'INVOICED',
  NOT_INVOICED: 'NOT_INVOICED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PERMANENTLY_FAILED: 'PERMANENTLY_FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  REFUNDED: 'REFUNDED',
  localizationKeys: {
    INVOICED: 'enum.invoicingVismaStatus.INVOICED',
    NOT_INVOICED: 'enum.invoicingVismaStatus.NOT_INVOICED',
    PAYMENT_FAILED: 'enum.invoicingVismaStatus.PAYMENT_FAILED',
    PERMANENTLY_FAILED: 'enum.invoicingVismaStatus.PERMANENTLY_FAILED',
    IN_PROGRESS: 'enum.invoicingVismaStatus.IN_PROGRESS',
    PAUSED: 'enum.invoicingVismaStatus.PAUSED',
    REFUNDED: 'enum.invoicingVismaStatus.REFUNDED'
  }
}

export const invoicingCardPaymentStatus = {
  INVOICED: 'INVOICED',
  NOT_INVOICED: 'NOT_INVOICED',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PERMANENTLY_FAILED: 'PERMANENTLY_FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  REFUNDED: 'REFUNDED',
  WAIVED: 'WAIVED',
  CHARGEBACK: 'CHARGEBACK',
  localizationKeys: {
    INVOICED: 'enum.invoicingCardPaymentStatus.INVOICED',
    NOT_INVOICED: 'enum.invoicingCardPaymentStatus.NOT_INVOICED',
    PAYMENT_FAILED: 'enum.invoicingCardPaymentStatus.PAYMENT_FAILED',
    PERMANENTLY_FAILED: 'enum.invoicingCardPaymentStatus.PERMANENTLY_FAILED',
    IN_PROGRESS: 'enum.invoicingCardPaymentStatus.IN_PROGRESS',
    REFUNDED: 'enum.invoicingCardPaymentStatus.REFUNDED',
    WAIVED: 'enum.invoicingCardPaymentStatus.WAIVED',
    CHARGEBACK: 'enum.invoicingCardPaymentStatus.CHARGEBACK'
  }
}

export const vismaStatus = {
  NOT_SENT: 'NOT_SENT',
  COLLECTING: 'COLLECTING',
  SUCCESSFUL: 'SUCCESSFUL',
  FAILED: 'FAILED',
  CANCELLED: 'CANCELLED',
  SUSPENDED: 'SUSPENDED',
  COLLECTION_PROHIBITION_ON: 'COLLECTION_PROHIBITION_ON',
  PENDING_CREDIT_NOTE_ORIGINAL_REFUND: 'PENDING_CREDIT_NOTE_ORIGINAL_REFUND,',
  PENDING_CREDIT_NOTE: 'PENDING_CREDIT_NOTE',
  PENDING_CREDIT_NOTE_REFUND: 'PENDING_CREDIT_NOTE_REFUND',
  WEB_PAYMENT_DEBT_COLLECTION_INITIATED:
    'WEB_PAYMENT_DEBT_COLLECTION_INITIATED',
  localizationKeys: {
    NOT_SENT: 'enum.vismaStatus.NOT_SENT',
    COLLECTING: 'enum.vismaStatus.COLLECTING',
    SUCCESSFUL: 'enum.vismaStatus.SUCCESSFUL',
    FAILED: 'enum.vismaStatus.FAILED',
    CANCELLED: 'enum.vismaStatus.CANCELLED',
    SUSPENDED: 'enum.vismaStatus.SUSPENDED',
    COLLECTION_PROHIBITION_ON: 'enum.vismaStatus.COLLECTION_PROHIBITION_ON',
    PENDING_CREDIT_NOTE_ORIGINAL_REFUND:
      'enum.vismaStatus.PENDING_CREDIT_NOTE_ORIGINAL_REFUND,',
    PENDING_CREDIT_NOTE: 'enum.vismaStatus.PENDING_CREDIT_NOTE',
    PENDING_CREDIT_NOTE_REFUND: 'enum.vismaStatus.PENDING_CREDIT_NOTE_REFUND',
    WEB_PAYMENT_DEBT_COLLECTION_INITIATED:
      'enum.vismaStatus.WEB_PAYMENT_DEBT_COLLECTION_INITIATED'
  }
}

export const paymentCardCategory = {
  PRIVATE: 'PRIVATE',
  BUSINESS: 'BUSINESS',
  localizationKeys: {
    PRIVATE: 'enum.paymentCardCategory.PRIVATE',
    BUSINESS: 'enum.paymentCardCategory.BUSINESS'
  }
}

export const subscriptionPricingProfileItemTypes = {
  CONSTANT_PERCENTAGE_DISCOUNT: 'CONSTANT_PERCENTAGE_DISCOUNT',
  MASS_PERCENTAGE_DISCOUNT: 'MASS_PERCENTAGE_DISCOUNT',
  localizationKeys: {
    CONSTANT_PERCENTAGE_DISCOUNT:
      'subscriptionPricingProfile.itemTypes.CONSTANT_PERCENTAGE_DISCOUNT',
    MASS_PERCENTAGE_DISCOUNT:
      'subscriptionPricingProfile.itemTypes.MASS_PERCENTAGE_DISCOUNT'
  }
}

export const voucher = {
  benefitTypes: {
    MONEY: 'BENEFIT_MONEY',
    TIME: 'BENEFIT_TIME',
    PERIOD: 'BENEFIT_PERIOD',
    PERCENT: 'BENEFIT_PERCENT',
    stringValues: {
      BENEFIT_TIME: 'Aika',
      BENEFIT_MONEY: 'Raha',
      BENEFIT_PERIOD: 'Kesto',
      BENEFIT_PERCENT: 'Prosentti'
    }
  },
  categoryTypes: {
    BENEFIT: 'BENEFIT',
    PREBOOKING: 'PREBOOKING',
    LOW_EMISSION_VEHICLE: 'LOW_EMISSION_VEHICLE',
    HYPERIN_SINGLE_USE: 'HYPERIN_SINGLE_USE',
    stringValues: {
      BENEFIT: 'Normaali',
      PREBOOKING: 'Ennakkovaraus',
      LOW_EMISSION_VEHICLE: 'Vähäpäästöinen',
      HYPERIN_SINGLE_USE: 'HyperIn kertakäyttöinen'
    }
  },
  grantTypes: {
    PHONE_NUMBER: 'PHONE_NUMBER',
    LICENSE_PLATE_NUMBER: 'LICENSE_PLATE_NUMBER',
    ALL: 'ALL',
    stringValues: {
      PHONE_NUMBER: 'Puhelin',
      LICENSE_PLATE_NUMBER: 'Rekisteritunnus',
      ALL: 'Molemmat'
    }
  },
  voucherTypes: {
    NEW_MEMBER: 'NEW_MEMBER',
    THIRD_PARTY_PURCHASED: 'THIRD_PARTY_PURCHASED',
    stringValues: {
      NEW_MEMBER: 'Uuden käyttäjän etu',
      THIRD_PARTY_PURCHASED: 'Kolmannen osapuolen tarjoama etu'
    }
  }
}

export const sale = {
  /* TODO: Move paymentMethodTypes out of Sale as their own object.
     And do we really need the longer string versions? Are they used anywhere?
     Maybe we should just use the shorter backend db enums?
     There's also a paymentMethods object exported at the start of the file.
   */
  paymentMethodTypes: {
    PAYMENT_HIGHWAY_TRANSACTION: 'PAYMENT_HIGHWAY_TRANSACTION',
    ADYEN_TRANSACTION: 'ADYEN_TRANSACTION',
    INFODATA_TRANSACTION: 'INFODATA_TRANSACTION',
    VISMA: 'VISMA',
    WEBPAYMENT: 'WEBPAYMENT',
    NO_PAYMENT_EXPECTED: 'NO_PAYMENT_EXPECTED',
    PARKMAN: 'PARKMAN',
    EASYPARK: 'EASYPARK',
    stringValues: {
      PAYMENT_HIGHWAY_TRANSACTION: 'Payment Highway',
      ADYEN_TRANSACTION: 'Adyen',
      INFODATA_TRANSACTION: 'Infodata',
      VISMA: 'VISMA',
      WEBPAYMENT: 'Verkkomaksu',
      NO_PAYMENT_EXPECTED: 'Ei maksettavaa',
      PARKMAN: 'Parkman',
      EASYPARK: 'Easypark'
    },
    localizationKeys: {
      PAYMENT_HIGHWAY_TRANSACTION: 'enum.paymentMethod.PAYMENT_HIGHWAY',
      ADYEN_TRANSACTION: 'enum.paymentMethod.ADYEN',
      INFODATA_TRANSACTION: 'enum.paymentMethod.INFODATA',
      VISMA: 'enum.paymentMethod.VISMA',
      WEBPAYMENT: 'enum.paymentMethod.WEB_PAYMENT',
      NO_PAYMENT_EXPECTED: 'enum.paymentMethod.NO_PAYMENT_EXPECTED',
      PARKMAN: 'enum.paymentMethod.PARKMAN',
      EASYPARK: 'enum.paymentMethod.EASYPARK'
    }
  },
  validity: {
    VALID: 'VALID',
    NOT_VALID: 'NOT_VALID'
  }
}

export const webpayment = {
  webpaymentStatus: {
    NONE: 'NONE',
    WEBPAYMENT_PENDING: 'WEBPAYMENT_PENDING',
    WEBPAYMENT_SUCCESSFUL: 'WEBPAYMENT_SUCCESSFUL',
    WEBPAYMENT_FAILED: 'WEBPAYMENT_FAILED',
    WEBPAYMENT_CANCELLED: 'WEBPAYMENT_CANCELLED',
    MOVED_TO_DEBT_COLLECTION: 'MOVED_TO_DEBT_COLLECTION',
    DEBT_COLLECTION_DONE: 'DEBT_COLLECTION_DONE',
    DEBT_COLLECTION_FAILED: 'DEBT_COLLECTION_FAILED',
    DEBT_COLLECTION_REVOKED: 'DEBT_COLLECTION_REVOKED',
    DEBT_COLLECTION_REQUIRES_MANUAL_STEP:
      'DEBT_COLLECTION_REQUIRES_MANUAL_STEP',
    WEBPAYMENT_ERROR: 'WEBPAYMENT_ERROR',
    localizationKeys: {
      NONE: 'enum.webpaymentStatus.NONE',
      WEBPAYMENT_PENDING: 'enum.webpaymentStatus.WEBPAYMENT_PENDING',
      WEBPAYMENT_SUCCESSFUL: 'enum.webpaymentStatus.WEBPAYMENT_SUCCESSFUL',
      WEBPAYMENT_FAILED: 'enum.webpaymentStatus.WEBPAYMENT_FAILED',
      WEBPAYMENT_CANCELLED: 'enum.webpaymentStatus.WEBPAYMENT_CANCELLED',
      MOVED_TO_DEBT_COLLECTION:
        'enum.webpaymentStatus.MOVED_TO_DEBT_COLLECTION',
      DEBT_COLLECTION_DONE: 'enum.webpaymentStatus.DEBT_COLLECTION_DONE',
      DEBT_COLLECTION_FAILED: 'enum.webpaymentStatus.DEBT_COLLECTION_FAILED',
      DEBT_COLLECTION_REVOKED: 'enum.webpaymentStatus.DEBT_COLLECTION_REVOKED',
      DEBT_COLLECTION_REQUIRES_MANUAL_STEP:
        'enum.webpaymentStatus.DEBT_COLLECTION_REQUIRES_MANUAL_STEP',
      WEBPAYMENT_ERROR: 'enum.webpaymentStatus.WEBPAYMENT_ERROR'
    }
  }
}

export const serviceType = {
  SUBSCRIPTION: 'SUBSCRIPTION',
  PAY_AS_YOU_GO: 'PAY_AS_YOU_GO',
  localizationKeys: {
    SUBSCRIPTION: 'enum.parkingServiceType.SUBSCRIPTION',
    PAY_AS_YOU_GO: 'enum.parkingServiceType.PAY_AS_YOU_GO'
  }
}
export const vismaRoleEnum = {
  DEBT_COLLECTION: 'DEBT_COLLECTION',
  INVOICING: 'INVOICING',
  localizationKeys: {
    DEBT_COLLECTION: 'enum.vismaRoleEnum.DEBT_COLLECTION',
    INVOICING: 'enum.vismaRoleEnum.INVOICING'
  }
}

export const messageCenterEnums = {
  targetType: {
    GROUPS: 'GROUPS',
    PHONENUMBERS: 'PHONENUMBERS',
    localizationKeys: {
      GROUPS: 'enum.messageCenter.targetType.groups',
      PHONENUMBERS: 'enum.messageCenter.targetType.phonenumbers'
    },
    stringValues: {
      GROUPS: 'Ryhmät',
      PHONENUMBERS: 'Puhelinnumerot'
    }
  },
  messageStatus: {
    DRAFT: 'DRAFT',
    PUBLISHED: 'PUBLISHED',
    localizationKeys: {
      DRAFT: 'enum.messageCenter.messageStatus.draft',
      PUBLISHED: 'enum.messageCenter.messageStatus.published'
    },
    stringValues: {
      DRAFT: 'Luonnos',
      PUBLISHED: 'Julkaistu'
    }
  },
  callToActionType: {
    INTERNAL: 'INTERNAL',
    EXTERNAL: 'EXTERNAL',
    localizationKeys: {
      INTERNAL: 'enum.messageCenter.callToActionType.internal',
      EXTERNAL: 'enum.messageCenter.callToActionType.external'
    },
    stringValues: {
      INTERNAL: 'Sisäinen',
      EXTERNAL: 'Ulkoinen'
    }
  },
  callToActionInternalType: {
    SUBSCRIPTIONS: 'SUBSCRIPTIONS',
    SHOP: 'SHOP',
    VOUCHERS: 'VOUCHERS',
    localizationKeys: {
      SUBSCRIPTIONS: 'enum.messageCenter.callToActionInternalType.subscription',
      SHOP: 'enum.messageCenter.callToActionInternalType.shop',
      VOUCHERS: 'enum.messageCenter.callToActionInternalType.vouchers'
    },
    stringValues: {
      SUBSCRIPTIONS: 'Tilaus',
      SHOP: 'Kauppa',
      VOUCHERS: 'Etu'
    }
  },
  targetGroupCategories: {
    subscription: 'subscription',
    personalSubscriptionOnLocationNameV1:
      'personalSubscriptionOnLocationNameV1',
    enterpriseSubscriptionOnLocationNameV1:
      'enterpriseSubscriptionOnLocationNameV1',
    parking: 'parking',
    parkingLocationNameV1: 'parkingLocationNameV1',
    localizationKeys: {
      subscription: 'enum.messageCenter.targetGroup.category.type.subscription',
      personalSubscriptionOnLocationNameV1:
        'enum.messageCenter.targetGroup.category.type.personalSubscriptionOnLocationNameV1',
      enterpriseSubscriptionOnLocationNameV1:
        'enum.messageCenter.targetGroup.category.type.enterpriseSubscriptionOnLocationNameV1',
      parking: 'enum.messageCenter.targetGroup.category.type.parking',
      parkingLocationNameV1:
        'enum.messageCenter.targetGroup.category.type.parkingLocationNameV1'
    }
  },
  targetGroupTimeInterval: {
    MONTHS: 'MONTHS',
    WEEKS: 'WEEKS',
    DAYS: 'DAYS'
  }
}

export const locationEnums = {
  locationType: {
    ZONE: 'ZONE',
    FACILITY: 'FACILITY',
    LOT: 'LOT',
    localizationKeys: {
      ZONE: 'enum.location.zone',
      FACILITY: 'enum.location.facility',
      LOT: 'enum.location.lot'
    },
    stringValues: {
      ZONE: 'Vyöhyke',
      FACILITY: 'Laitos',
      LOT: 'Alue'
    }
  }
}

export const ultimateBeneficialOwnerEnums = {
  reason: {
    DUE_TO_OWNERSHIP: 'DUE_TO_OWNERSHIP',
    DUE_TO_ACTUAL_CONTROL: 'DUE_TO_ACTUAL_CONTROL',
    DUE_TO_SIGNATORY: 'DUE_TO_SIGNATORY',
    localizationKeys: {
      DUE_TO_OWNERSHIP: 'enum.ultimateBeneficialOwnerReason.DUE_TO_OWNERSHIP',
      DUE_TO_ACTUAL_CONTROL:
        'enum.ultimateBeneficialOwnerReason.DUE_TO_ACTUAL_CONTROL',
      DUE_TO_SIGNATORY: 'enum.ultimateBeneficialOwnerReason.DUE_TO_SIGNATORY'
    }
  }
}

export const parkingTypeEnum = {
  MOOVY_PARKING: 'MOOVY_PARKING',
  ANONYMOUS_PARKING: 'ANONYMOUS_PARKING',
  localizationKeys: {
    MOOVY_PARKING: 'enum.parkingTypeEnum.MOOVY_PARKING',
    ANONYMOUS_PARKING: 'enum.parkingTypeEnum.ANONYMOUS_PARKING'
  }
}

export const frontendParkingTypeEnum = {
  ALL: 'ALL',
  MOOVY_PARKING: 'MOOVY_PARKING',
  ANONYMOUS_PARKING: 'ANONYMOUS_PARKING',
  PARKMAN_PARKING: 'PARKMAN_PARKING',
  EASYPARK_PARKING: 'EASYPARK_PARKING',
  localizationKeys: {
    ALL: 'enum.frontendParkingTypeEnum.ALL',
    MOOVY_PARKING: 'enum.frontendParkingTypeEnum.MOOVY_PARKING',
    ANONYMOUS_PARKING: 'enum.frontendParkingTypeEnum.ANONYMOUS_PARKING',
    PARKMAN_PARKING: 'enum.frontendParkingTypeEnum.PARKMAN_PARKING',
    EASYPARK_PARKING: 'enum.frontendParkingTypeEnum.EASYPARK_PARKING'
  }
}

export const settlementRecipientCompanyTypeEnum = {
  NOT_DEFINED: 'NOT_DEFINED',
  BUSINESS: 'BUSINESS',
  PUBLIC_COMPANY: 'PUBLIC_COMPANY',
  NON_PROFIT_COMPANY: 'NON_PROFIT_COMPANY',
  localizationKeys: {
    BUSINESS: 'enum.settlementRecipientCompanyTypeEnum.BUSINESS',
    PUBLIC_COMPANY: 'enum.settlementRecipientCompanyTypeEnum.PUBLIC_COMPANY',
    NON_PROFIT_COMPANY:
      'enum.settlementRecipientCompanyTypeEnum.NON_PROFIT_COMPANY',
    NOT_DEFINED: 'enum.settlementRecipientCompanyTypeEnum.NOT_DEFINED'
  }
}

export const adyenAccountHolderTopMainStatusEnum = {
  NONE: 'NONE',
  PROGRESS: 'PROGRESS',
  FINISHED: 'FINISHED',
  FAILURE: 'FAILURE',
  localizationKeys: {
    NONE: 'enum.adyenAccountHolderTopMainStatusEnum.NONE',
    PROGRESS: 'enum.adyenAccountHolderTopMainStatusEnum.PROGRESS',
    FINISHED: 'enum.adyenAccountHolderTopMainStatusEnum.FINISHED',
    FAILURE: 'enum.adyenAccountHolderTopMainStatusEnum.FAILURE'
  }
}

export const adyenAccountHolderMainStatusEnum = {
  NOT_CREATED: 'NOT_CREATED',
  CREATION_IN_PROGRESS: 'CREATION_IN_PROGRESS',
  CREATION_FAILED: 'CREATION_FAILED',
  CREATED: 'CREATED',
  ACTIVE: 'ACTIVE',
  PAYOUTS_ALLOWED: 'PAYOUTS_ALLOWED',
  INACTIVE: 'INACTIVE',
  SUSPENDED: 'SUSPENDED',
  CLOSED: 'CLOSED',
  localizationKeys: {
    NOT_CREATED: 'enum.adyenAccountHolderMainStatusEnum.NOT_CREATED',
    CREATION_IN_PROGRESS:
      'enum.adyenAccountHolderMainStatusEnum.CREATION_IN_PROGRESS',
    CREATION_FAILED: 'enum.adyenAccountHolderMainStatusEnum.CREATION_FAILED',
    CREATED: 'enum.adyenAccountHolderMainStatusEnum.CREATED',
    ACTIVE: 'enum.adyenAccountHolderMainStatusEnum.ACTIVE',
    PAYOUTS_ALLOWED: 'enum.adyenAccountHolderMainStatusEnum.PAYOUTS_ALLOWED',
    INACTIVE: 'enum.adyenAccountHolderMainStatusEnum.INACTIVE',
    SUSPENDED: 'enum.adyenAccountHolderMainStatusEnum.SUSPENDED',
    CLOSED: 'enum.adyenAccountHolderMainStatusEnum.CLOSED'
  }
}

export const ChargerTypeEnum = {
  CHADEMO: 'CHADEMO',
  CCS: 'CCS',
  SCHUKO: 'SCHUKO',
  TYPE1: 'TYPE1',
  TYPE2: 'TYPE2',

  localizationKeys: {
    CHADEMO: 'enum.chargerType.EV_CHARGER_CHADEMO',
    CCS: 'enum.chargerType.EV_CHARGER_CCS',
    SCHUKO: 'enum.chargerType.EV_CHARGER_SCHUKO',
    TYPE1: 'enum.chargerType.EV_CHARGER_TYPE1',
    TYPE2: 'enum.chargerType.EV_CHARGER_TYPE2'
  }
}

export const ChargingStatusEnum = {
  PREPARING_CHARGING: 'PREPARING_CHARGING',
  CHARGING: 'CHARGING',
  CHARGING_SUSPENDED: 'CHARGING_SUSPENDED',
  CHARGING_ENDED: 'CHARGING_ENDED',
  CHARGING_ENDED_WITH_TIMEOUT: 'CHARGING_ENDED_WITH_TIMEOUT',
  CHARGING_LIMIT_REACHED: 'CHARGING_LIMIT_REACHED',
  PREPARING_TERMINATION: 'PREPARING_TERMINATION',
  ERROR: 'ERROR',
  UNKNOWN: 'UNKNOWN',

  localizationKeys: {
    PREPARING_CHARGING: 'enum.chargingStatus.PREPARING_CHARGING',
    CHARGING: 'enum.chargingStatus.CHARGING',
    CHARGING_SUSPENDED: 'enum.chargingStatus.CHARGING_SUSPENDED',
    CHARGING_ENDED: 'enum.chargingStatus.CHARGING_ENDED',
    CHARGING_ENDED_WITH_TIMEOUT:
      'enum.chargingStatus.CHARGING_ENDED_WITH_TIMEOUT',
    CHARGING_LIMIT_REACHED: 'enum.chargingStatus.CHARGING_LIMIT_REACHED',
    PREPARING_TERMINATION: 'enum.chargingStatus.PREPARING_TERMINATION',
    ERROR: 'enum.chargingStatus.ERROR',
    UNKNOWN: 'enum.chargingStatus.UNKNOWN'
  }
}

export const moneyTransferTypesEnum = {
  DEBIT_MARKETPLACE_FEE: 'DEBIT_MARKETPLACE_FEE',
  DEBIT_SERVICE_FEE: 'DEBIT_SERVICE_FEE',
  DEBIT_SALE_CANCELLATION: 'DEBIT_SALE_CANCELLATION',
  DEBIT_CHARGEBACK: 'DEBIT_CHARGEBACK',
  DEBIT_CHARGEBACK_FEE: 'DEBIT_CHARGEBACK_FEE',
  DEBIT_TOPPED_UP_FUNDS_BACK: 'DEBIT_TOPPED_UP_FUNDS_BACK',
  DEBIT_REFUNDS_FUNDS_TRANSFER: 'DEBIT_REFUNDS_FUNDS_TRANSFER',
  DEBIT_BACK_EARLY_PAYOUTED_FUNDS: 'DEBIT_BACK_EARLY_PAYOUTED_FUNDS',
  DEBIT_REFUNDS_DONE_PH: 'DEBIT_REFUNDS_DONE_PH',
  DEBIT_CREDIT_LOSS_RETURN: 'DEBIT_CREDIT_LOSS_RETURN',
  CREDIT_SUB_MERCHANT_BONUS: 'CREDIT_SUB_MERCHANT_BONUS',
  CREDIT_COVER_CREDIT_LOSS: 'CREDIT_COVER_CREDIT_LOSS',
  CREDIT_AFTER_REACTIVATION: 'CREDIT_AFTER_REACTIVATION',
  CREDIT_DISCOUNT: 'CREDIT_DISCOUNT',
  CREDIT_ACCOUNT_CREATION: 'CREDIT_ACCOUNT_CREATION',
  CREDIT_PAYMENT_FAILURE: 'CREDIT_PAYMENT_FAILURE',
  CREDIT_EARLY_PAYOUT_FUNDS: 'CREDIT_EARLY_PAYOUT_FUNDS',
  CREDIT_PAYOUT_FAILURE: 'CREDIT_PAYOUT_FAILURE',
  CREDIT_TOPPED_UP_FUNDS: 'CREDIT_TOPPED_UP_FUNDS',

  localizationKeys: {
    DEBIT_MARKETPLACE_FEE: 'enum.moneyTransferType.DEBIT_MARKETPLACE_FEE',
    DEBIT_SERVICE_FEE: 'enum.moneyTransferType.DEBIT_SERVICE_FEE',
    DEBIT_SALE_CANCELLATION: 'enum.moneyTransferType.DEBIT_SALE_CANCELLATION',
    DEBIT_CHARGEBACK: 'enum.moneyTransferType.DEBIT_CHARGEBACK',
    DEBIT_CHARGEBACK_FEE: 'enum.moneyTransferType.DEBIT_CHARGEBACK_FEE',
    DEBIT_TOPPED_UP_FUNDS_BACK:
      'enum.moneyTransferType.DEBIT_TOPPED_UP_FUNDS_BACK',
    DEBIT_REFUNDS_FUNDS_TRANSFER:
      'enum.moneyTransferType.DEBIT_REFUNDS_FUNDS_TRANSFER',
    DEBIT_BACK_EARLY_PAYOUTED_FUNDS:
      'enum.moneyTransferType.DEBIT_BACK_EARLY_PAYOUTED_FUNDS',
    DEBIT_REFUNDS_DONE_PH: 'enum.moneyTransferType.DEBIT_REFUNDS_DONE_PH',
    DEBIT_CREDIT_LOSS_RETURN: 'enum.moneyTransferType.DEBIT_CREDIT_LOSS_RETURN',
    CREDIT_SUB_MERCHANT_BONUS:
      'enum.moneyTransferType.CREDIT_SUB_MERCHANT_BONUS',
    CREDIT_COVER_CREDIT_LOSS: 'enum.moneyTransferType.CREDIT_COVER_CREDIT_LOSS',
    CREDIT_AFTER_REACTIVATION:
      'enum.moneyTransferType.CREDIT_AFTER_REACTIVATION',
    CREDIT_DISCOUNT: 'enum.moneyTransferType.CREDIT_DISCOUNT',
    CREDIT_ACCOUNT_CREATION: 'enum.moneyTransferType.CREDIT_ACCOUNT_CREATION',
    CREDIT_PAYMENT_FAILURE: 'enum.moneyTransferType.CREDIT_PAYMENT_FAILURE',
    CREDIT_EARLY_PAYOUT_FUNDS:
      'enum.moneyTransferType.CREDIT_EARLY_PAYOUT_FUNDS',
    CREDIT_PAYOUT_FAILURE: 'enum.moneyTransferType.CREDIT_PAYOUT_FAILURE',
    CREDIT_TOPPED_UP_FUNDS: 'enum.moneyTransferType.CREDIT_TOPPED_UP_FUNDS'
  }
}

export const InvoiceOperationLogTypeEnum = {
  WAIVED: 'WAIVED',
  REFUNDED: 'REFUNDED',
  CHARGEBACK: 'CHARGEBACK'
}

export const UserBlockingHistoryTypeEnum = {
  BLOCKED: 'BLOCKED',
  BLOCKING_PREVENTED: 'BLOCKING_PREVENTED',

  localizationKeys: {
    BLOCKED: 'enum.userBlockingHistoryType.BLOCKED',
    BLOCKING_PREVENTED: 'enum.userBlockingHistoryType.BLOCKING_PREVENTED'
  }
}

export const searchEntityTypeEnum = {
  USER: 'USER',
  ENTERPRISE_USER: 'ENTERPRISE_USER',
  VEHICLE: 'VEHICLE',
  LOCATION: 'LOCATION',
  OPERATOR: 'OPERATOR'
}

export const commonEnum = {
  ALL: 'ALL'
}

export const AdyenAccountBpStatusEnum = {
  INITIALIZED: 'INITIALIZED',
  KYC_DONE: 'KYC_DONE',
  ACTIVE: 'ACTIVE',

  localizationKeys: {
    INITIALIZED: 'enum.adyenAccountBpStatus.INITIALIZED',
    KYC_DONE: 'enum.adyenAccountBpStatus.KYC_DONE',
    ACTIVE: 'enum.adyenAccountBpStatus.ACTIVE'
  }
}

export const getEnumKeys = (enumObject) =>
  Object.keys(enumObject)
    .map((key) => (key !== 'localizationKeys' && key) || false)
    .filter((item) => item != false)

export const adyenReportProfileTypeEnum = {
  NORMAL: 'NORMAL',
  ONLY_CLOSING: 'ONLY_CLOSING',
  localizationKeys: {
    NORMAL: 'enum.adyenReportProfileTypeEnum.NORMAL',
    ONLY_CLOSING: 'enum.adyenReportProfileTypeEnum.ONLY_CLOSING'
  }
}

export const getStringValue = (stringValues, key) => stringValues[key] ?? key
