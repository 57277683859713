import { set as updateFormValue } from 'lodash'
import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  Confirm,
  Divider,
  Grid,
  Header,
  Icon,
  Step
} from 'semantic-ui-react'

import { Toolbar } from '../../../components'
import MoovyLink from '../../../components/MoovyLink'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import messageCenterService from '../../../services/MessageCenter'
import { messageCenterEnums } from '../../../services/utils/DTOEnums'
import { parseMessageToBackend, parseMessageToFrontend } from './MessageParser'
import StepMessageContent from './StepMessageContent'
import StepSendingDetails from './StepSendingDetails'
import StepSummary from './StepSummary'
import StepTargetGroup from './StepTargetGroup'

export const STEP_KEYS = {
  TARGETGROUP: 'TARGETGROUP',
  MESSAGECONTENT: 'MESSAGECONTENT',
  SENDINGDETAILS: 'SENDINGDETAILS',
  UPLOADMESSAGE: 'UPLOADMESSAGE'
}

export const initialMessageValues = Object.freeze({
  targetRefs: [],
  messageContent: {
    fi: {
      title: undefined,
      description: undefined,
      imageUrl: undefined,
      videoUrl: undefined,
      callToAction: {
        description: undefined,
        type: undefined,
        internal: undefined,
        external: undefined
      }
    },
    en: {
      title: undefined,
      description: undefined,
      imageUrl: undefined,
      videoUrl: undefined,
      callToAction: {
        description: undefined,
        type: undefined,
        internal: undefined,
        external: undefined
      }
    },
    sv: {
      title: undefined,
      description: undefined,
      imageUrl: undefined,
      videoUrl: undefined,
      callToAction: {
        description: undefined,
        type: undefined,
        internal: undefined,
        external: undefined
      }
    }
  },
  sendingDetails: {
    publishTime: undefined,
    expirationTime: undefined,
    status: undefined,
    pushMessage: {
      send: false,
      content: {
        fi: { title: undefined, description: undefined },
        en: { title: undefined, description: undefined },
        sv: { title: undefined, description: undefined }
      }
    }
  }
})

export const CreateMessage = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [activeStep, setActiveStep] = useState(STEP_KEYS.TARGETGROUP)
  const [formValues, setFormValues] = useState(cloneDeep(initialMessageValues))
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showStepConfirmation, setShowStepConfirmation] = useState('')
  const [stepConfirmationContent, setStepConfirmationContent] = useState('')
  const [messageRef, setMessageRef] = useState(useParams().messageRef)

  const messageQuery = useQuery({
    queryKey: ['fetchMessage', messageRef],
    queryFn: () => messageCenterService.fetchMessage(messageRef),
    refetchOnWindowFocus: false,
    enabled: !!messageRef
  })

  useEffect(() => {
    if (messageQuery.data) {
      setFormValues(
        parseMessageToFrontend(
          messageQuery.data,
          cloneDeep(initialMessageValues)
        )
      )
    } else {
      setFormValues(cloneDeep(initialMessageValues))
    }
  }, [messageQuery.data])

  const { mutate: saveDraftMessage, ...saveDraftMutation } = useMutation({
    mutationFn: (message) =>
      messageCenterService.saveMessage(messageRef, message),
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'messageCenter.createMessage.toast.draft.title',
        description: 'messageCenter.createMessage.toast.draft.body'
      })
      navigate(`/admin/messageCenter`)
    },
    onError: () => {
      showLocalizedMoovyToast(intl, {
        title: 'messageCenter.createMessage.toast.error.title',
        description: 'messageCenter.createMessage.toast.error.description',
        type: 'error'
      })
    }
  })

  const { mutate: saveCompletedMessage, ...saveCompletedMutation } =
    useMutation({
      mutationFn: (message) =>
        messageCenterService.saveMessage(messageRef, message),
      onSuccess: () => {
        showLocalizedMoovyToast(intl, {
          title: 'messageCenter.createMessage.toast.save.title',
          description: 'messageCenter.createMessage.toast.save.body'
        })
        navigate(`/admin/messageCenter`)
      },
      onError: () => {
        showLocalizedMoovyToast(intl, {
          title: 'messageCenter.createMessage.toast.error.title',
          description: 'messageCenter.createMessage.toast.error.description',
          type: 'error'
        })
      }
    })

  const { mutate: saveAndContinueMessage, ...saveAndContinueMutation } =
    useMutation({
      mutationFn: (message) =>
        messageCenterService.saveMessage(messageRef, message),
      onSuccess: (result) => {
        setMessageRef(result.ref)
      },
      onError: () => {
        showLocalizedMoovyToast(intl, {
          title: 'messageCenter.createMessage.toast.error.title',
          description: 'messageCenter.createMessage.toast.error.description',
          type: 'error'
        })
      }
    })

  const { mutate: deleteMessage, ...deleteMutation } = useMutation({
    mutationFn: () => messageCenterService.deleteMessage(messageRef),
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'messageCenter.createMessage.toast.delete.title',
        description: 'messageCenter.createMessage.toast.delete.body'
      })
      navigate(`/admin/messageCenter`)
    },
    onError: () => {
      showLocalizedMoovyToast(intl, {
        title: 'messageCenter.createMessage.toast.error.title',
        description: 'messageCenter.createMessage.toast.error.description',
        type: 'error'
      })
    }
  })

  const breadcrumbsItems = [
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.messageCenter'
      }),
      href: '/messageCenter'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.createMessages'
      }),
      active: true
    }
  ]

  const breadcrumbs = createBreadcrumbs(breadcrumbsItems)

  const onStepClicked = (e, { name }) => {
    setActiveStep(name)
  }

  const steps = [
    {
      name: STEP_KEYS.TARGETGROUP,
      icon: 'group',
      title: intl.formatMessage({
        id: 'messageCenter.createMessage.step.targetGroup.title'
      }),
      description: intl.formatMessage({
        id: 'messageCenter.createMessage.step.targetGroup.description'
      })
    },
    {
      name: STEP_KEYS.MESSAGECONTENT,
      icon: 'envelope',
      title: intl.formatMessage({
        id: 'messageCenter.createMessage.step.messageContent.title'
      }),
      description: intl.formatMessage({
        id: 'messageCenter.createMessage.step.messageContent.description'
      })
    },
    {
      name: STEP_KEYS.SENDINGDETAILS,
      icon: 'send',
      title: intl.formatMessage({
        id: 'messageCenter.createMessage.step.sendingDetails.title'
      }),
      description: intl.formatMessage({
        id: 'messageCenter.createMessage.step.sendingDetails.description'
      })
    },
    {
      name: STEP_KEYS.UPLOADMESSAGE,
      icon: 'upload',
      title: intl.formatMessage({
        id: 'messageCenter.createMessage.step.summary.title'
      }),
      description: intl.formatMessage({
        id: 'messageCenter.createMessage.step.summary.description'
      })
    }
  ]

  const [completedSteps, setCompletedSteps] = useState([])

  const onDataCompleted = (stepName) => {
    switch (stepName) {
      case STEP_KEYS.TARGETGROUP:
        updateCompleted(stepName)
        saveAndContinueMessage(
          parseMessageToBackend(
            messageCenterEnums.messageStatus.DRAFT,
            formValues
          )
        )
        break
      case STEP_KEYS.MESSAGECONTENT:
        if (checkTabLocalizations(stepName, formValues.messageContent)) {
          updateCompleted(stepName)
          saveAndContinueMessage(
            parseMessageToBackend(
              messageCenterEnums.messageStatus.DRAFT,
              formValues
            )
          )
        }
        break
      case STEP_KEYS.SENDINGDETAILS:
        if (
          !formValues.sendingDetails.pushMessage.send ||
          checkTabLocalizations(
            stepName,
            formValues.sendingDetails.pushMessage.content
          )
        ) {
          updateCompleted(stepName)
          saveAndContinueMessage(
            parseMessageToBackend(
              messageCenterEnums.messageStatus.DRAFT,
              formValues
            )
          )
        }
        break
      case STEP_KEYS.UPLOADMESSAGE:
        break
      default:
        break
    }
  }

  const updateCompleted = (stepName) => {
    const newCompletedSteps = [...completedSteps]
    if (!newCompletedSteps.includes(stepName)) {
      newCompletedSteps.push(stepName)
      setCompletedSteps([...newCompletedSteps])
    }

    const nextStep = steps.find(
      (item) => !newCompletedSteps.includes(item.name)
    )
    if (nextStep) {
      setActiveStep(nextStep.name)
    }
  }

  const onChangeValue = (stepKey, name, value) => {
    switch (stepKey) {
      case STEP_KEYS.TARGETGROUP:
        // In case of target groups, the value is always an array
        setFormValues({
          ...formValues,
          targetRefs: [...value]
        })
        break
      case STEP_KEYS.MESSAGECONTENT:
        updateFormValues(formValues, 'messageContent', name, value)
        break
      case STEP_KEYS.SENDINGDETAILS:
        updateFormValues(formValues, 'sendingDetails', name, value)
        break
      case STEP_KEYS.UPLOADMESSAGE:
        break
      default:
        break
    }
  }

  const updateFormValues = (
    targetRootObject,
    targetObjectName,
    sourcePath,
    value
  ) => {
    let newValues = { ...targetRootObject[targetObjectName] }
    updateFormValue(newValues, sourcePath, value)
    setFormValues({
      ...targetRootObject,
      [targetObjectName]: { ...newValues }
    })
  }

  const copyToAllLanguages = (sourceLang, propertyKey) => {
    let newValues = { ...formValues.messageContent }

    Object.keys(newValues).forEach((key) => {
      if (key !== sourceLang) {
        const item = newValues[sourceLang][propertyKey]
        if (typeof item === 'object' && item !== null) {
          newValues[key][propertyKey] = {
            ...newValues[sourceLang][propertyKey]
          }
        } else {
          newValues[key][propertyKey] = newValues[sourceLang][propertyKey]
        }
      }
    })

    setFormValues({
      ...formValues,
      messageContent: { ...formValues.messageContent, ...newValues }
    })
  }

  const showContent = (stepName) => {
    switch (stepName) {
      case STEP_KEYS.TARGETGROUP:
        return (
          <StepTargetGroup
            formValues={formValues.targetRefs}
            loading={
              saveAndContinueMutation.isPending ||
              saveCompletedMutation.isPending ||
              saveDraftMutation.isPending ||
              deleteMutation.isPending
            }
            onDataCompleted={onDataCompleted}
            onChangeValue={onChangeValue}
          />
        )
      case STEP_KEYS.MESSAGECONTENT:
        return (
          <StepMessageContent
            formValues={formValues.messageContent}
            loading={
              saveAndContinueMutation.isPending ||
              saveCompletedMutation.isPending ||
              saveDraftMutation.isPending ||
              deleteMutation.isPending
            }
            onDataCompleted={onDataCompleted}
            onChangeValue={onChangeValue}
            copyToAllLanguages={copyToAllLanguages}
          />
        )
      case STEP_KEYS.SENDINGDETAILS:
        return (
          <StepSendingDetails
            formValues={formValues.sendingDetails}
            loading={
              saveAndContinueMutation.isPending ||
              saveCompletedMutation.isPending ||
              saveDraftMutation.isPending ||
              deleteMutation.isPending
            }
            onDataCompleted={onDataCompleted}
            onChangeValue={onChangeValue}
          />
        )
      case STEP_KEYS.UPLOADMESSAGE:
        return (
          <>
            <Header>
              <FormattedMessage id="messageCenter.createMessage.step.summary.header" />
            </Header>
            <StepSummary formValues={formValues} />
          </>
        )
      default:
        return <div />
    }
  }

  const checkTabLocalizations = (stepName, values) => {
    if (!values.fi) return true

    const englishMissing = isLanguageMissing(values, 'en')
    const swedishMissing = isLanguageMissing(values, 'sv')

    let missingLangText = ''
    if (englishMissing && swedishMissing) {
      missingLangText = intl.formatMessage({
        id: 'messageCenter.createMessage.step.tab.confirmation.missingBothLanguage'
      })
    } else if (englishMissing) {
      missingLangText = intl.formatMessage(
        {
          id: 'messageCenter.createMessage.step.tab.confirmation.missingLanguage'
        },
        {
          language: intl.formatMessage({
            id: 'messageCenter.createMessage.step.tab.title.english'
          })
        }
      )
    } else if (swedishMissing) {
      missingLangText = intl.formatMessage(
        {
          id: 'messageCenter.createMessage.step.tab.confirmation.missingLanguage'
        },
        {
          language: intl.formatMessage({
            id: 'messageCenter.createMessage.step.tab.title.swedish'
          })
        }
      )
    }

    if (missingLangText) {
      setStepConfirmationContent(missingLangText)
      setShowStepConfirmation(stepName)
      return false
    }

    return true
  }

  const isLanguageMissing = (values, lang) => {
    if (!values[lang]) {
      return true
    }

    let translationMissing = false
    Object.keys(values.fi).every((key) => {
      if (values.fi[key]) {
        translationMissing = values[lang][key] ? false : true
        if (translationMissing) {
          return false
        }

        return true
      }
    })

    return translationMissing
  }

  const deleteDraftMessage = () => {
    setShowDeleteConfirmation(false)
    messageRef ? deleteMessage() : navigate(`/admin/messageCenter`)
  }

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'messageCenter.toolbar.title'
        })}
        breadcrumbs={breadcrumbs}
      />

      <div className="Admin--Page--Content">
        <Grid>
          <Grid.Column widescreen={3} computer={4} tablet={5}>
            <Step.Group vertical>
              {steps.map((item) => (
                <Step
                  key={item.name}
                  name={item.name}
                  active={activeStep === item.name}
                  completed={completedSteps.includes(item.name)}
                  onClick={onStepClicked}
                >
                  <Icon name={item.icon} />
                  <Step.Content>
                    <Step.Title>{item.title}</Step.Title>
                    <Step.Description>{item.description}</Step.Description>
                  </Step.Content>
                </Step>
              ))}
            </Step.Group>
          </Grid.Column>
          <Grid.Column widescreen={11} computer={12} tablet={11}>
            {showContent(activeStep)}
            <br />
            <Divider />
            <Grid>
              <Grid.Column floated="left" width={8}>
                <MoovyLink
                  deleteLink
                  bold
                  onClick={() =>
                    !saveAndContinueMutation.isPending &&
                    !saveCompletedMutation.isPending &&
                    !saveDraftMutation.isPending &&
                    !deleteMutation.isPending &&
                    setShowDeleteConfirmation(true)
                  }
                >
                  <FormattedMessage id="common.button.delete" />
                </MoovyLink>
              </Grid.Column>
              <Grid.Column floated="right" width={8} textAlign="right">
                <MoovyLink
                  bold
                  onClick={() =>
                    !saveAndContinueMutation.isPending &&
                    !saveCompletedMutation.isPending &&
                    !saveDraftMutation.isPending &&
                    !deleteMutation.isPending &&
                    saveDraftMessage(
                      parseMessageToBackend(
                        messageCenterEnums.messageStatus.DRAFT,
                        formValues
                      )
                    )
                  }
                >
                  <FormattedMessage id="messageCenter.createMessage.action.saveDraft" />
                </MoovyLink>
                <Button
                  primary
                  style={{ marginLeft: '15px' }}
                  disabled={completedSteps.length < steps.length - 1}
                  loading={
                    saveAndContinueMutation.isPending ||
                    saveCompletedMutation.isPending ||
                    saveDraftMutation.isPending ||
                    deleteMutation.isPending
                  }
                  onClick={() =>
                    saveCompletedMessage(
                      parseMessageToBackend(
                        messageCenterEnums.messageStatus.PUBLISHED,
                        formValues
                      )
                    )
                  }
                >
                  <FormattedMessage id="messageCenter.createMessage.action.sendMessage" />
                </Button>
              </Grid.Column>
            </Grid>
          </Grid.Column>
        </Grid>
        <Confirm
          open={showDeleteConfirmation}
          content={intl.formatMessage({
            id: 'messageCenter.createMessage.confirmation.deleteMessage'
          })}
          cancelButton={intl.formatMessage({ id: 'common.button.cancel' })}
          confirmButton={intl.formatMessage({ id: 'common.button.confirm' })}
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={deleteDraftMessage}
        />
        <Confirm
          open={showStepConfirmation ? true : false}
          content={stepConfirmationContent}
          cancelButton={intl.formatMessage({ id: 'common.button.cancel' })}
          confirmButton={intl.formatMessage({ id: 'common.button.confirm' })}
          onCancel={() => setShowStepConfirmation('')}
          onConfirm={() => {
            updateCompleted(showStepConfirmation)
            setShowStepConfirmation('')
            saveAndContinueMessage(
              parseMessageToBackend(
                messageCenterEnums.messageStatus.DRAFT,
                formValues
              )
            )
          }}
        />
      </div>
    </>
  )
}

export default CreateMessage
