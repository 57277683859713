import { Image, Popup } from 'semantic-ui-react'
import {
  frontendParkingTypeEnum,
  parkingTypeEnum,
  sale
} from '../../services/utils'

import { LocalizedFrontendParkingType } from '../../components/MoovyLocalizedEnum'
import PropTypes from 'prop-types'
import React from 'react'
import parkingAnonymous from '../../resources/parkingAnonymous.svg'
import parkingMoovy from '../../resources/parkingMoovy.svg'
import parkingParkman from '../../resources/parkingParkman.svg'
import parkingEasypark from '../../resources/parkingEasypark.svg'

const ParkingTypeLabel = ({ type, paymentMethodType }) => {
  switch (type) {
    case parkingTypeEnum.MOOVY_PARKING:
      return (
        <Popup
          trigger={<Image src={parkingMoovy} avatar />}
          content={<LocalizedFrontendParkingType value={type} />}
        />
      )
    case parkingTypeEnum.ANONYMOUS_PARKING:
      if (paymentMethodType === sale.paymentMethodTypes.PARKMAN) {
        return (
          <Popup
            trigger={<Image src={parkingParkman} avatar />}
            content={
              <LocalizedFrontendParkingType
                value={frontendParkingTypeEnum.PARKMAN_PARKING}
              />
            }
          />
        )
      }
      if (paymentMethodType === sale.paymentMethodTypes.EASYPARK) {
        return (
          <Popup
            trigger={<Image src={parkingEasypark} avatar />}
            content={
              <LocalizedFrontendParkingType
                value={frontendParkingTypeEnum.EASYPARK_PARKING}
              />
            }
          />
        )
      }
      return (
        <Popup
          trigger={<Image src={parkingAnonymous} avatar />}
          content={<LocalizedFrontendParkingType value={type} />}
        />
      )
    default:
      return (
        <Popup
          trigger={<Image src={parkingAnonymous} avatar />}
          content={<LocalizedFrontendParkingType value={type} />}
        />
      )
  }
}
ParkingTypeLabel.propTypes = {
  type: PropTypes.string.isRequired
}

export default ParkingTypeLabel
