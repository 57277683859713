import { Grid, Header, Icon, Segment } from 'semantic-ui-react'
import { MoovyButton, MoovyHtmlPreview } from '../../../components'
import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import ModalEditLocationDescription from './modalEditLocationDescription'
import QueryContainer from '../../../components/QueryContainer'
import { ROLE_MANAGEMENT } from '../../../services/utils'
import locationService from '../../../services/Locations'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'

const DescriptionTab = ({ queryLocation, refresh }) => {
  const { isSuperOperator, roles } = useAuthInfo()
  const [editModalOpen, setEditModalOpen] = useState(false)
  const intl = useIntl()

  const { mutate: updateDescription, ...updateDescriptionMutation } =
    useMutation({
      mutationFn: (description) =>
        locationService.updateLocationData(null, queryLocation.data.ref, {
          description
        }),
      onSuccess: () => {
        setEditModalOpen(false)
        refresh()
        showLocalizedMoovyToast(intl, {
          title: 'locations.tab.description.toast.success.edit.title',
          description: 'locations.tab.description.toast.success.edit.body'
        })
      },
      onError: () => {
        showLocalizedMoovyToast(intl, {
          title: 'locations.tab.description.toast.failed.edit.title',
          description: 'locations.tab.description.toast.failed.edit.body',
          type: 'error'
        })
      }
    })

  return (
    <>
      <br />
      <Grid>
        <Grid.Column floated="left" width={8}>
          <Header>
            <FormattedMessage id="locations.tab.description.title" />
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          {roles.includes(ROLE_MANAGEMENT) && isSuperOperator && (
            <MoovyButton onClick={() => setEditModalOpen(true)}>
              <Icon name="pencil" />
              <FormattedMessage id="common.button.modify" />
            </MoovyButton>
          )}
        </Grid.Column>
      </Grid>
      <Grid columns={2}>
        <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16}>
          <QueryContainer query={queryLocation}>
            {(queryLocation.data && queryLocation.data.description && (
              <Segment>
                <MoovyHtmlPreview html={queryLocation.data.description} />
              </Segment>
            )) || (
              <FormattedMessage id="locations.tab.description.descriptionIsMissing" />
            )}
          </QueryContainer>
        </Grid.Column>
      </Grid>

      {editModalOpen && (
        <ModalEditLocationDescription
          onClose={() => {
            updateDescriptionMutation.reset()
            setEditModalOpen(false)
          }}
          locationDescription={queryLocation.data?.description}
          onSubmit={updateDescription}
          mutation={updateDescriptionMutation}
        />
      )}
    </>
  )
}

export default DescriptionTab
