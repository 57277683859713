import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Grid, Segment } from 'semantic-ui-react'

const EndParkingBanner = ({
  selectAllAnonymous,
  endAnonymousParkings,
  hideBanner,
  disableEndButton
}) => {
  return (
    <>
      {!hideBanner ? (
        <Segment attached="top" secondary>
          <Grid>
            <Grid.Column floated="left" width={4}>
              <Button primary onClick={selectAllAnonymous}>
                <FormattedMessage id="parkingTable.endParking.banner.button.markAnonymous" />
              </Button>
            </Grid.Column>
            <Grid.Column width={8} textAlign="center" verticalAlign="middle">
              <FormattedMessage id="parkingTable.endParking.banner.description" />
            </Grid.Column>
            <Grid.Column floated="right" width={4}>
              <Button
                primary
                onClick={endAnonymousParkings}
                floated="right"
                disabled={disableEndButton}
              >
                <FormattedMessage id="parkingTable.endParking.banner.button.endParking" />
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      ) : (
        ''
      )}
    </>
  )
}

export default EndParkingBanner
