import React, { useEffect, useState } from 'react'
import { filterByOperator, filterBySearchTerm, sortLocations } from './helpers'
import {
  getDataForPage,
  getTablePaginationInitialValues,
  getTotalPages,
  getUpdatedPaginationSort
} from '../../../services/utils/tablePagination'
import { useNavigate, useParams } from 'react-router-dom'

import OperatorDetailsToolbar from './OperatorDetailsToolbar'
import OperatorLocationsTable from './OperatorLocationsTable'
import Toolbar from '../../../components/Toolbar'
import locationService from '../../../services/Locations'
import operatorServices from '../../../services/Operators'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

const OperatorDetails = () => {
  const { operatorRef } = useParams()
  const navigate = useNavigate()
  const { operatorRealm } = useAuthInfo()
  const pageLimit = 20

  const [searchTerm, setSearchTerm] = useState('')
  const [pagination, setPagination] = useState({})

  const locationsQuery = useQuery({
    queryKey: ['locations', operatorRealm],
    queryFn: () => locationService.fetchLocations(operatorRealm)
  })

  useEffect(() => {
    setSearchTerm('')

    const {
      totalPages,
      ...withoutTotalPages // totalPages is calculated later from filtered data
    } = getTablePaginationInitialValues('sortByLocation')

    setPagination(withoutTotalPages)
  }, [operatorRef])

  const operatorData = useQuery({
    queryKey: ['fetchOperatorData', operatorRef],
    queryFn: () => operatorServices.getOperatorByRef(operatorRef)
  })

  const toolbarTitle =
    (operatorData && operatorData.data && operatorData.data.name) || operatorRef

  const onChangeSearch = (searchInputValue) => {
    setSearchTerm(searchInputValue)
    setPagination((previousPagination) => ({
      ...previousPagination,
      activePage: 1
    }))
  }

  const onHandleSort = (sortColumn) => () => {
    const updatedPagination = getUpdatedPaginationSort(sortColumn, pagination)
    setPagination(updatedPagination)
  }

  const onPageChange = (e, { activePage }) => {
    setPagination((previousPagination) => ({
      ...previousPagination,
      activePage
    }))
  }

  const onSelectedLocation = (location) => {
    navigate('/admin/locations/' + location.ref)
  }

  const locations = locationsQuery.data?.content ?? []
  const filtered = locations
    .filter(filterByOperator(operatorRef))
    .filter(filterBySearchTerm(searchTerm))
    .sort(sortLocations(pagination))

  return (
    <>
      <Toolbar
        title={toolbarTitle}
        content={
          <OperatorDetailsToolbar
            searchValue={searchTerm}
            onChangeSearch={onChangeSearch}
          />
        }
      />
      <div className="Admin--Page--Content">
        <OperatorLocationsTable
          locations={getDataForPage(filtered, pagination.activePage, pageLimit)}
          onSelectedLocation={onSelectedLocation}
          onHandleSort={onHandleSort}
          onPageChange={onPageChange}
          pagination={{
            ...pagination,
            totalPages: getTotalPages(filtered.length, pageLimit)
          }}
        />
      </div>
    </>
  )
}

export default OperatorDetails
