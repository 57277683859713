import 'react-semantic-toasts/styles/react-semantic-alert.css'
import './grantedVouchers.scss'

import {
  GrantedVoucherManageModal,
  GrantedVouchersTable,
  MoovyButton,
  Toolbar
} from '../../components'
import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VoucherSearchToolbar from './VoucherSearchToolbar'
import { showToastGrantedVoucherCreated } from '../../components/MoovyToast/GrantedVoucherToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useNavigate } from 'react-router-dom'
import { userVoucherService } from '../../services'
import { FormattedMessage } from 'react-intl'

const GrantedVouchers = () => {
  const vouchersInitialState = {
    loading: false,
    error: false,
    data: null
  }

  const directions = {
    DESC: 'descending',
    ASC: 'ascending'
  }

  const navigate = useNavigate()

  const pageLimit = 20
  const { operatorRealm } = useAuthInfo()

  const [searchFilterData, setSearchFilterData] = useState({
    voucherDescription: '',
    selectedVoucherTemplateRef: '',
    selectedOperatorRef: '',
    selectedLocationRef: ''
  })

  const [vouchers, setVouchers] = useState(vouchersInitialState)
  const [grantVoucherModal, setGrantVoucherModal] = useState(false)
  const [paginationProps, setPagination] = useState({
    activePage: 1,
    totalPages: 1,
    sortColumn: 'sortByValidFrom',
    direction: 'DESC'
  })

  const searchVouchers = (
    {
      voucherDescription,
      selectedVoucherTemplateRef,
      selectedOperatorRef,
      selectedLocationRef,
      hideExpiredVouchers
    },
    pagination
  ) => {
    setVouchers({ ...vouchers, loading: true, error: false })

    const queryParams = {
      page: pagination.activePage > 0 ? pagination.activePage - 1 : 0,
      limit: pageLimit,
      sort: pagination.sortColumn,
      direction: pagination.direction,
      voucherTemplateRef: selectedVoucherTemplateRef,
      licensePlateNumber: voucherDescription,
      operatorRef: selectedOperatorRef,
      locationRef: selectedLocationRef,
      hideExpiredVouchers
    }

    userVoucherService
      .fetchVouchersByFilter(operatorRealm, queryParams)
      .then((response) => {
        const data = response ? response.data : []

        setVouchers({
          ...vouchers,
          data,
          loading: false
        })

        setPagination({
          ...pagination,
          totalPages: response.totalPages
        })
      })
      .catch((error) => {
        console.warn(error)
        setVouchers({
          ...vouchers,
          data: [],
          loading: false
        })
      })
  }

  const onSaveGrantedVoucher = (voucher) => {
    setGrantVoucherModal(false)
    showToastGrantedVoucherCreated(voucher)
  }

  const onHandleSort = (sortColumn) => () => {
    if (sortColumn === paginationProps.sortColumn) {
      const direction = paginationProps.direction === 'DESC' ? 'ASC' : 'DESC'

      const updatedSearchFilterData = {
        ...paginationProps,
        activePage: 1,
        direction
      }
      searchVouchers(searchFilterData, updatedSearchFilterData)
    } else {
      const updatedSearchFilterData = {
        ...paginationProps,
        activePage: 1,
        direction: 'DESC',
        sortColumn
      }
      searchVouchers(searchFilterData, updatedSearchFilterData)
    }
  }

  const onSelectedVoucher = (voucher) => {
    if (!voucher) {
      return
    }

    navigate(
      `/${!operatorRealm ? 'admin' : 'operator'}/voucherTemplates/${
        voucher.voucherTemplate.ref
      }`
    )
  }

  return (
    <>
      <Toolbar
        title="Myönnetyt pysäköintiedut"
        content={
          <VoucherSearchToolbar
            voucherDescriptionPlaceHolder="Rekisteritunnus"
            operatorRealm={operatorRealm}
            onSearch={(searchData) => {
              setSearchFilterData(searchData)
              const newPagination = { ...paginationProps, activePage: 1 }
              searchVouchers(searchData, newPagination)
            }}
            queryLoading={vouchers.loading}
          />
        }
      />
      <div className="Admin--Page--Content">
        <div className="grant-new-voucher-container">
          <div>
            {!operatorRealm ? (
              <MoovyButton onClick={() => setGrantVoucherModal(true)}>
                <FontAwesomeIcon
                  icon={['fa', 'hand-holding-dollar']}
                  style={{ marginRight: '6px' }}
                />
                Myönnä uusi pysäköintietu
              </MoovyButton>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="granted-voucher-table-container">
          <GrantedVouchersTable
            activePage={paginationProps.activePage}
            totalPages={paginationProps.totalPages}
            onPageChange={(e, { activePage }) => {
              searchVouchers(searchFilterData, {
                ...paginationProps,
                activePage
              })
            }}
            vouchers={vouchers.data}
            direction={directions[paginationProps.direction]}
            sortColumn={paginationProps.sortColumn}
            handleSort={onHandleSort}
            onSelectedVoucher={onSelectedVoucher}
            onRefresh={() => searchVouchers(searchFilterData, paginationProps)}
            emptyTextId={
              vouchers.data === null ? (
                <FormattedMessage id="moovyTable.action.fetchPending" />
              ) : null
            }
            isLoading={vouchers.loading}
          />
        </div>
      </div>
      {grantVoucherModal && (
        <GrantedVoucherManageModal
          onSaveGrantedVoucher={onSaveGrantedVoucher}
          onClose={() => {
            setGrantVoucherModal(false)
          }}
        />
      )}
    </>
  )
}

export default GrantedVouchers
