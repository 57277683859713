import { Checkbox, Table } from 'semantic-ui-react'
import { MoovyLink, MoovyTable, TablePagination } from '../../components'
import {
  currentPageHasMultiSelectedRows,
  formatDateAndTime,
  formatPrice,
  multiSelectPage
} from '../../services/utils'

import { FormattedMessage } from 'react-intl'
import { LocalizedWebpaymentStatus } from '../../components/MoovyLocalizedEnum'
import React from 'react'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import useGlobalUI from '../../hooks/useGlobalUI'
import useAuthInfo from '../../hooks/useAuthInfo'

export default function WebpaymentSalesTable({
  data = [],
  itemsQuery,
  selectedSales,
  sortedColumn,
  sortDirection,
  onSort,
  activePage,
  totalPages,
  onPageChange,
  onClickInvoice,
  onClickSale,
  onMultiSelect
}) {
  const { isSuperOperator } = useAuthInfo()
  const selectedSaleRefs = selectedSales.map((row) => row.ref)
  const currentPageHasCheckedRows = currentPageHasMultiSelectedRows(
    data,
    selectedSales
  )

  const [state] = useGlobalUI({
    key: 'Sales'
  })

  return (
    <MoovyTable
      compact="very"
      celled
      items={data || []}
      query={itemsQuery}
      sortable
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Checkbox
              onClick={() =>
                onMultiSelect(multiSelectPage(data, selectedSales))
              }
              checked={currentPageHasCheckedRows}
              style={{ verticalAlign: 'middle' }}
            />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortedColumn === 'sortByCreationTime' ? sortDirection : null
            }
            onClick={() => onSort('sortByCreationTime')}
          >
            <FormattedMessage id="sales.table.header.creationTime" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortedColumn === 'sortByPrice' ? sortDirection : null}
            onClick={() => onSort('sortByPrice')}
          >
            <FormattedMessage id="sales.table.header.price" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortedColumn === 'sortByLicensePlate' ? sortDirection : null
            }
            onClick={() => onSort('sortByLicensePlate')}
          >
            <FormattedMessage id="sales.table.header.licensePlate" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortedColumn === 'sortByWebpaymentSendTime' ? sortDirection : null
            }
            onClick={() => onSort('sortByWebpaymentSendTime')}
          >
            <FormattedMessage id="sales.table.header.sendTime" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortedColumn === 'sortByWebpaymentStatus' ? sortDirection : null
            }
            onClick={() => onSort('sortByWebpaymentStatus')}
          >
            <FormattedMessage id="sales.table.header.status" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortedColumn === 'sortByWebpaymentResolveTime'
                ? sortDirection
                : null
            }
            onClick={() => onSort('sortByWebpaymentResolveTime')}
          >
            <FormattedMessage id="sales.table.header.resolveTime" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={
              sortedColumn === 'sortByInvoiceNumber' ? sortDirection : null
            }
            onClick={() => onSort('sortByInvoiceNumber')}
          >
            <FormattedMessage id="sales.table.header.invoiceNumber" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data.map((sale) => (
          <Table.Row key={sale.ref}>
            <Table.Cell collapsing onClick={(event) => event.stopPropagation()}>
              <Checkbox
                checked={selectedSaleRefs?.includes(sale.ref)}
                value={sale.ref}
                onClick={() => onMultiSelect([sale])}
                style={{ verticalAlign: 'middle' }}
              />
            </Table.Cell>
            <Table.Cell>{formatDateAndTime(sale.creationTime)}</Table.Cell>
            <Table.Cell>{formatPrice(sale.price)}</Table.Cell>
            <Table.Cell>
              <RelativeLinkForCurrentUser
                to={`parking/${sale.parking.ref}`}
                state={state}
              >
                {sale.parking.licensePlate}
              </RelativeLinkForCurrentUser>
            </Table.Cell>
            <Table.Cell>
              {formatDateAndTime(sale.webpayment?.sendTime)}
            </Table.Cell>
            <Table.Cell>
              <MoovyLink onClick={() => onClickSale(sale)}>
                <LocalizedWebpaymentStatus value={sale.webpayment?.status} />
              </MoovyLink>
            </Table.Cell>
            <Table.Cell>
              {formatDateAndTime(sale.webpayment?.resolveTime)}
            </Table.Cell>
            <Table.Cell>
              {isSuperOperator ? (
                <MoovyLink onClick={() => onClickInvoice(sale.invoice?.ref)}>
                  {sale.invoice?.invoiceNumber}
                </MoovyLink>
              ) : (
                sale.invoice?.invoiceNumber
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TablePagination
        colSpan={9}
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </MoovyTable>
  )
}
