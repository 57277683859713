import { MoovyTable, TablePagination } from '../../components'
import { convertMinutes, formatPrice } from '../../services/utils'

import PropTypes from 'prop-types'
import React from 'react'
import { Table } from 'semantic-ui-react'
import { getVoucherTemplateLocationDescription } from '../../services/VoucherTemplates'
import { voucherTemplateShape } from '../../services/utils/shapes'

const getBenefitText = (template) =>
  ({
    BENEFIT_TIME: convertMinutes(template.grantedDurationInMinutes || 0),
    BENEFIT_MONEY: formatPrice(template.grantedValue || 0),
    BENEFIT_PERIOD: convertMinutes(template.validityInMinutes || 0),
    BENEFIT_PERCENT: `${template.grantedDiscountPercent} %`
  }[template.benefitType])

const VoucherTemplateTable = ({
  query,
  voucherTemplates,
  renderActions,
  onSelectedVoucherTemplate,
  activePage,
  totalPages,
  onPageChange
}) => (
  <MoovyTable selectable items={voucherTemplates} query={query}>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Operaattori</Table.HeaderCell>
        <Table.HeaderCell>Kohteiden kuvaus</Table.HeaderCell>
        <Table.HeaderCell>Pysäköintiedun kuvaus</Table.HeaderCell>
        <Table.HeaderCell>Kustannus edun antajalle</Table.HeaderCell>
        <Table.HeaderCell>Myönnetty etu/oikeus</Table.HeaderCell>
        {renderActions && <Table.HeaderCell />}
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {voucherTemplates.map((template) => (
        <Table.Row
          key={template.ref}
          onClick={() => {
            onSelectedVoucherTemplate(template)
          }}
        >
          <Table.Cell>{template.operator.name}</Table.Cell>
          <Table.Cell>
            {getVoucherTemplateLocationDescription(template)}
          </Table.Cell>
          <Table.Cell>
            {template.customDescription || template.description}
          </Table.Cell>
          <Table.Cell collapsing>{formatPrice(template.salePrice)}</Table.Cell>
          <Table.Cell collapsing>{getBenefitText(template)}</Table.Cell>
          {renderActions && (
            <Table.Cell
              singleLine
              textAlign="right"
              onClick={(event) => event.stopPropagation()}
            >
              {renderActions(template)}
            </Table.Cell>
          )}
        </Table.Row>
      ))}
    </Table.Body>
    <TablePagination
      colSpan={6}
      activePage={activePage}
      totalPages={totalPages}
      onPageChange={onPageChange}
    />
  </MoovyTable>
)

VoucherTemplateTable.propTypes = {
  query: PropTypes.object.isRequired,
  voucherTemplates: PropTypes.arrayOf(PropTypes.shape(voucherTemplateShape))
    .isRequired,
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
}

export default VoucherTemplateTable
