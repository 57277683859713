import React from 'react'
import { Form, Header } from 'semantic-ui-react'

import JSONView from './JSONView'
import StepNextButton from './StepNextButton'

export const ConclusionForm = ({ formValues, onDataCompleted }) => {
  const onNextClicked = () => {
    onDataCompleted(formValues, 'conclusion')
  }

  return (
    <>
      <Form onSubmit={onNextClicked}>
        <StepNextButton name={'Lisää järjestelmään'} />
        <Header>Yhteenveto</Header>
        <Form.Field>
          <JSONView formValues={formValues} readonly={true}></JSONView>
        </Form.Field>
      </Form>
    </>
  )
}

export default ConclusionForm
