import React from 'react'
import useAuthInfo from '../hooks/useAuthInfo'
import RedirectUser from './RedirectUser'

function RequireOperator({ children }) {
  const { isSuperOperator } = useAuthInfo()

  if (isSuperOperator) {
    return <RedirectUser />
  }
  return children
}

export default RequireOperator
