{
  "common.button.close": "Close",
  "common.button.cancel": "Cancel",
  "common.button.confirm": "Yes",
  "common.button.save": "Save",
  "common.button.accept": "Accept",
  "common.button.delete": "Delete",
  "common.button.modify": "Modify",
  "common.button.copy": "Copy",
  "common.button.OK": "OK",
  "common.button.search": "Search",
  "common.button.add": "Add",
  "common.button.change": "Change",
  "common.button.continue": "Continue",
  "common.button.back": "Back",
  "common.button.clear": "Clear",

  "common.breadcrumbs.home": "Home",
  "common.breadcrumbs.userGroups": "User groups",
  "common.breadcrumbs.applicationUsers": "Application users",
  "common.breadcrumbs.parkingServices": "Parking services",
  "common.breadcrumbs.messageCenter": "Message center",
  "common.breadcrumbs.tariff": "Tariff",
  "common.breadcrumbs.createMessages": "Create and send the new message",
  "common.breadcrumbs.settlementRecipients": "Settlement recipients",
  "common.breadcrumbs.moneyTransfer": "Inter account transfer",
  "common.breadcrumbs.creditCoverCreditLoss": "Covering credit losses",
  "common.breadcrumbs.blocklist": "Listing of failed payments",
  "common.breadcrumbs.chargings": "Chargings",
  "common.breadcrumbs.potentiallyIncorrectLicensePlates": "License Plate Recognition",

  "common.mutation.error": "An error occurred",

  "common.vehicles.licensePlateNumber": "License plate number",
  "common.vehicles.licensePlateRecognition": "Camera recognition",
  "common.vehicles.licensePlateRecognitionActive": "In use",
  "common.vehicles.licensePlateRecognitionInActive": "Not in use",

  "common.location": "Location",
  "common.phoneNumber": "Phone number",
  "common.filter.filterSearch": "FILTER SEARCH",
  "common.noResultsMessage": "No results",
  "common.user": "User",
  "common.key": "Key",
  "common.value": "Value",
  "common.yes": "Yes",
  "common.no": "No",
  "common.all": "All",
  "common.private": "Private",
  "common.public": "Public",
  "common.unlimited": "Unlimited",
  "common.pcs": "pcs",
  "common.pcsValue": "{pcs} pcs",
  "common.percentageValue": "{percentage} %",

  "common.month.january": "January",
  "common.month.february": "February",
  "common.month.march": "March",
  "common.month.april": "April",
  "common.month.may": "May",
  "common.month.june": "June",
  "common.month.july": "July",
  "common.month.august": "August",
  "common.month.september": "September",
  "common.month.october": "October",
  "common.month.november": "November",
  "common.month.december": "December",

  "common.abbreviation.years": "y",
  "common.abbreviation.months": "m",
  "common.abbreviation.days": "d",
  "common.abbreviation.hours": "h",
  "common.abbreviation.minutes": "min",
  "common.abbreviation.seconds": "s",

  "errorBoundary.fallbackComponent.content": "Something went wrong.",
  "errorBoundary.dialog.title": "Oops!",
  "errorBoundary.dialog.subtitle": "Something went wrong and the development team has been notified.",
  "errorBoundary.dialog.subtitle2": "If you wish to help us, please send an error report.",
  "errorBoundary.dialog.successMessage": "Thank you for your feedback!",
  "errorBoundary.dialog.labelClose": "Close",
  "errorBoundary.dialog.labelSubmit": "Submit",

  "menu.header.operator": "Operator",
  "menu.header.superoperator": "Superoperator",
  "menu.locations": "Locations",
  "menu.locations.locations": "All locations",
  "menu.locations.potentiallyIncorrect": "License plate recognitions",
  "menu.customers": "Customers",
  "menu.customers.userGroups": "User groups",
  "menu.customers.licensePlateRecognition": "License plate recognition",
  "menu.services": "Parking services",
  "menu.vouchers": "Vouchers",
  "menu.vouchers.voucherTemplates": "Vouchers",
  "menu.vouchers.grantedVouchers": "Granted vouchers",
  "menu.finance": "Finance",
  "menu.finance.webPayment": "Web payments",
  "menu.finance.invoices": "Invoices",
  "menu.finance.reports": "Reports",
  "menu.finance.settlementRecipients": "Settlement recipients",
  "menu.finance.moneyTransfer": "Inter account transfer",
  "menu.finance.blocklistCandidates": "Blocklist candidates",
  "menu.finance.creditCoverCreditLoss": "Covering credit losses",
  "menu.messageCenter": "Message center",
  "menu.management": "Management",
  "menu.management.newLocation": "Create a new location",
  "menu.management.newService": "Create a new service",
  "menu.charging": "Charging",

  "enum.messageCenter.targetType.groups": "Groups",
  "enum.messageCenter.targetType.phonenumbers": "Phone numbers",
  "enum.messageCenter.messageStatus.draft": "Draft",
  "enum.messageCenter.messageStatus.published": "Published",
  "enum.messageCenter.callToActionType.internal": "Internal",
  "enum.messageCenter.callToActionType.external": "External",
  "enum.messageCenter.callToActionInternalType.subscription": "Subscription",
  "enum.messageCenter.callToActionInternalType.shop": "Shop",
  "enum.messageCenter.callToActionInternalType.vouchers": "Vouchers",
  "enum.messageCenter.targetGroup.category.type.parking": "Parking",
  "enum.messageCenter.targetGroup.category.type.parkingLocationNameV1": "Location",
  "enum.messageCenter.targetGroup.category.type.subscription": "Subscription parking",
  "enum.messageCenter.targetGroup.category.type.personalSubscriptionOnLocationNameV1": "Personal subscription in a location",
  "enum.messageCenter.targetGroup.category.type.enterpriseSubscriptionOnLocationNameV1": "Enterprise subscription in a location",

  "enum.webpaymentStatus.NONE": "-",
  "enum.webpaymentStatus.WEBPAYMENT_PENDING": "Payment pending",
  "enum.webpaymentStatus.WEBPAYMENT_SUCCESSFUL": "Payment successful",
  "enum.webpaymentStatus.WEBPAYMENT_FAILED": "Web payment not received",
  "enum.webpaymentStatus.WEBPAYMENT_CANCELLED": "Payment cancelled",
  "enum.webpaymentStatus.MOVED_TO_DEBT_COLLECTION": "Debt collection",
  "enum.webpaymentStatus.DEBT_COLLECTION_DONE": "Debt collection done",
  "enum.webpaymentStatus.DEBT_COLLECTION_FAILED": "Debt collection failed",
  "enum.webpaymentStatus.DEBT_COLLECTION_REVOKED": "Debt collection revoked",
  "enum.webpaymentStatus.DEBT_COLLECTION_REQUIRES_MANUAL_STEP": "Manual checking before debt collection",
  "enum.webpaymentStatus.WEBPAYMENT_ERROR": "Webpayment error",

  "enum.location.zone": "Zone",
  "enum.location.facility": "Facility",
  "enum.location.lot": "Area",

  "enum.parkingServiceType.SUBSCRIPTION": "Subscription",
  "enum.parkingServiceType.PAY_AS_YOU_GO": "Pay as you go",

  "enum.paymentMethodType.NO_PAYMENT_EXPECTED": "No payment expected",

  "enum.paymentCardCategory.PRIVATE": "Private",
  "enum.paymentCardCategory.BUSINESS": "Business",

  "enum.invoicingStatus.INVOICED": "Invoiced",
  "enum.invoicingStatus.NOT_INVOICED": "Not invoiced",
  "enum.invoicingStatus.PAYMENT_FAILED": "Invoicing failed",
  "enum.invoicingStatus.PERMANENTLY_FAILED": "Permanently failed",
  "enum.invoicingStatus.IN_PROGRESS": "In progress",
  "enum.invoicingStatus.PAUSED": "Paused",
  "enum.invoicingStatus.REFUNDED": "Refunded",
  "enum.invoicingStatus.WAIVED": "Waived",
  "enum.invoicingStatus.CHARGEBACK": "Chargeback",

  "enum.invoicingVismaStatus.INVOICED": "Invoiced",
  "enum.invoicingVismaStatus.NOT_INVOICED": "Not paid",
  "enum.invoicingVismaStatus.PAYMENT_FAILED": "Invoicing failed",
  "enum.invoicingVismaStatus.PERMANENTLY_FAILED": "Invoicing permanently failed",
  "enum.invoicingVismaStatus.IN_PROGRESS": "Invoiced",
  "enum.invoicingVismaStatus.PAUSED": "Paused",
  "enum.invoicingVismaStatus.REFUNDED": "Refunded",

  "enum.invoicingCardPaymentStatus.INVOICED": "Invoiced",
  "enum.invoicingCardPaymentStatus.NOT_INVOICED": "Not charged",
  "enum.invoicingCardPaymentStatus.PAYMENT_FAILED": "Charge failed",
  "enum.invoicingCardPaymentStatus.PERMANENTLY_FAILED": "Permanently failed",
  "enum.invoicingCardPaymentStatus.IN_PROGRESS": "In progress",
  "enum.invoicingCardPaymentStatus.REFUNDED": "Refunded",
  "enum.invoicingCardPaymentStatus.WAIVED": "Waived",
  "enum.invoicingCardPaymentStatus.CHARGEBACK": "Chargeback",

  "enum.ultimateBeneficialOwnerReason.DUE_TO_OWNERSHIP": "Main ownership",
  "enum.ultimateBeneficialOwnerReason.DUE_TO_ACTUAL_CONTROL": "Actual control",
  "enum.ultimateBeneficialOwnerReason.DUE_TO_SIGNATORY": "Signatory",

  "enum.parkingTypeEnum.MOOVY_PARKING": "Moovy parking",
  "enum.parkingTypeEnum.ANONYMOUS_PARKING": "Anonymous parking",

  "enum.frontendParkingTypeEnum.ALL": "All",
  "enum.frontendParkingTypeEnum.MOOVY_PARKING": "Moovy parking",
  "enum.frontendParkingTypeEnum.ANONYMOUS_PARKING": "Anonymous parking",
  "enum.frontendParkingTypeEnum.PARKMAN_PARKING": "ParkMan parking",
  "enum.frontendParkingTypeEnum.EASYPARK_PARKING": "EasyPark parking",

  "enum.settlementRecipientCompanyTypeEnum.BUSINESS": "Business",
  "enum.settlementRecipientCompanyTypeEnum.PUBLIC_COMPANY": "Public company",
  "enum.settlementRecipientCompanyTypeEnum.NON_PROFIT_COMPANY": "Nonprofit company",
  "enum.settlementRecipientCompanyTypeEnum.NOT_DEFINED": "-",

  "enum.adyenAccountHolderTopMainStatusEnum.NONE": "-",
  "enum.adyenAccountHolderTopMainStatusEnum.PROGRESS": "In progress",
  "enum.adyenAccountHolderTopMainStatusEnum.FINISHED": "Finished",
  "enum.adyenAccountHolderTopMainStatusEnum.FAILURE": "Failure",

  "enum.vismaRoleEnum.DEBT_COLLECTION": "Debt collection",
  "enum.vismaRoleEnum.INVOICING": "Invoicing",

  "enum.adyenAccountHolderMainStatusEnum.NOT_CREATED": "Not sent to Adyen",
  "enum.adyenAccountHolderMainStatusEnum.CREATION_IN_PROGRESS": "Creation request has been sent to Adyen",
  "enum.adyenAccountHolderMainStatusEnum.CREATION_FAILED": "Creation failed. Try to create account holder again.",
  "enum.adyenAccountHolderMainStatusEnum.CREATED": "Creation request acknowledged by Adyen",
  "enum.adyenAccountHolderMainStatusEnum.ACTIVE": "Account holder and it's account are active but payments are not yet allowed",
  "enum.adyenAccountHolderMainStatusEnum.PAYOUTS_ALLOWED": "Account holder and it's account are ready for payments.",
  "enum.adyenAccountHolderMainStatusEnum.INACTIVE": "KYC process has not been finished. Complete it on Adyen page.",
  "enum.adyenAccountHolderMainStatusEnum.SUSPENDED": "KYC prosessi has been suspended. Check and fix the situation on Adyen page.",
  "enum.adyenAccountHolderMainStatusEnum.CLOSED": "Account holder has been closed and it cannot be no longer used. Create a new account holder.",

  "enum.chargerType.EV_CHARGER_CHADEMO": "CHAdeMO",
  "enum.chargerType.EV_CHARGER_CCS": "CCS",
  "enum.chargerType.EV_CHARGER_SCHUKO": "Schuko",
  "enum.chargerType.EV_CHARGER_TYPE1": "Type 1",
  "enum.chargerType.EV_CHARGER_TYPE2": "Type 2",

  "enum.chargingStatus.PREPARING_CHARGING": "Preparing",
  "enum.chargingStatus.CHARGING": "Charging",
  "enum.chargingStatus.CHARGING_SUSPENDED": "Suspended",
  "enum.chargingStatus.CHARGING_ENDED": "Ended",
  "enum.chargingStatus.CHARGING_ENDED_WITH_TIMEOUT": "Charging finished because of the unplugged cable",
  "enum.chargingStatus.CHARGING_LIMIT_REACHED": "Charging limit reached",
  "enum.chargingStatus.PREPARING_TERMINATION": "Charging is being finished, unplug the cable",
  "enum.chargingStatus.ERROR": "Error",
  "enum.chargingStatus.UNKNOWN": "Unknown status",

  "enum.paymentMethod.VISMA": "Visma",
  "enum.paymentMethod.PAYMENT_HIGHWAY": "Payment Highway",
  "enum.paymentMethod.WEB_PAYMENT": "Verkkomaksu",
  "enum.paymentMethod.ADYEN": "Adyen",
  "enum.paymentMethod.INFODATA": "Infodata",
  "enum.paymentMethod.NO_PAYMENT_EXPECTED": "No payment expected",
  "enum.paymentMethod.PARKMAN": "ParkMan",
  "enum.paymentMethod.EASYPARK": "EasyPark",

  "enum.moneyTransferType.DEBIT_MARKETPLACE_FEE": "Fee that is collected from submerchants from using MarketPlace service.",
  "enum.moneyTransferType.DEBIT_SERVICE_FEE": "Debit from submerchant for a specific MarketPlace service fee.",
  "enum.moneyTransferType.DEBIT_SALE_CANCELLATION": "Debiting from submerchant amout of cancelled sale, which has been already covered them as credit loss covering.",
  "enum.moneyTransferType.DEBIT_CHARGEBACK": "Debit from submerchant to cover a chargeback.",
  "enum.moneyTransferType.DEBIT_CHARGEBACK_FEE": "Debit from submerchant to cover a chargeback fee.",
  "enum.moneyTransferType.DEBIT_TOPPED_UP_FUNDS_BACK": "Debiting in case money from liable account has been used to cover negative balance of AH.",
  "enum.moneyTransferType.DEBIT_REFUNDS_FUNDS_TRANSFER": "In case of error in funds transfer (in e.g. credit loss covering), refunds funds transfer is used.",
  "enum.moneyTransferType.DEBIT_BACK_EARLY_PAYOUTED_FUNDS": "This is reverse operation to transfer code: CREDIT_EARLY_PAYOUT_FUNDS after funds received from shopper (customer).",
  "enum.moneyTransferType.DEBIT_REFUNDS_DONE_PH": "This is used to transfer money from submerchant account to liable account, when refunds has been done with earlier PSP (Payment Service Provider).",
  "enum.moneyTransferType.DEBIT_CREDIT_LOSS_RETURN": "Transfer code for covering a payment in the case we have earlier covered the credit loss but payment has been charged after that",
  "enum.moneyTransferType.CREDIT_SUB_MERCHANT_BONUS": "Crediting to submerchant for a bonus.",
  "enum.moneyTransferType.CREDIT_COVER_CREDIT_LOSS": "Crediting to submerchant credit loss (sale, which wasn’t able to debiting from customer).",
  "enum.moneyTransferType.CREDIT_AFTER_REACTIVATION": "Credit to AH, when funds automatically went to the liable account, because of the AH being inactive.",
  "enum.moneyTransferType.CREDIT_DISCOUNT": "Credit to submerchant to compensate discounted payment.",
  "enum.moneyTransferType.CREDIT_ACCOUNT_CREATION": "Could not be credited earlier, because account was not yet created.",
  "enum.moneyTransferType.CREDIT_PAYMENT_FAILURE": "Credit to submerchant to correct payment failure.",
  "enum.moneyTransferType.CREDIT_EARLY_PAYOUT_FUNDS": "Used only by submerchants whose payout date can be before the third and final invoice debit attempt. E.g. Helsinki. In this case still up coming funds are transfered to submerchant’s account and will be debited back after received funds from shopper.",
  "enum.moneyTransferType.CREDIT_PAYOUT_FAILURE": "Used when the payout was more than expected and submerchant is required to return exceeding sum. Submerchant is asked to return over paid amount to Finnpark Pysäköinti’s bank account and it is wire transfered to liable account and with this transfer it is returned to submerchant’s account.",
  "enum.moneyTransferType.CREDIT_TOPPED_UP_FUNDS": "Covered transfer / loan of submerchant's negative balance.",

  "enum.userBlockingHistoryType.BLOCKED": "Blocklist",
  "enum.userBlockingHistoryType.BLOCKING_PREVENTED": "Blocklist prevention",

  "enum.adyenReportProfileTypeEnum.NORMAL": "Normal reports",
  "enum.adyenReportProfileTypeEnum.ONLY_CLOSING": "Only CLOC-report",

  "enum.adyenAccountBpStatus.INITIALIZED": "Onboarding in progress",
  "enum.adyenAccountBpStatus.KYC_DONE": "KYC done",
  "enum.adyenAccountBpStatus.ACTIVE": "Finished",

  "enum.vismaStatus.NOT_SENT": "Waiting to be sent",
  "enum.vismaStatus.COLLECTING": "Invoiced (sent to Visma for invoicing successfully)",
  "enum.vismaStatus.SUCCESSFUL": "Paid",
  "enum.vismaStatus.FAILED": "Error status",
  "enum.vismaStatus.CANCELLED": "Cancelled",
  "enum.vismaStatus.SUSPENDED": "Suspended",
  "enum.vismaStatus.COLLECTION_PROHIBITION_ON": "Debt collection prohibition",
  "enum.vismaStatus.PENDING_CREDIT_NOTE_ORIGINAL_REFUND": "Creating a refund invoice (1/3 phase)",
  "enum.vismaStatus.PENDING_CREDIT_NOTE": "Calling Visma (2/3 phase)",
  "enum.vismaStatus.PENDING_CREDIT_NOTE_REFUND": "Refund invoice sent to Visma successfully (3/3 phase)",
  "enum.vismaStatus.WEB_PAYMENT_DEBT_COLLECTION_INITIATED": "Visma's invoice is created from unpaid parking in Moovy's web payment, which has not yet been sent to Visma for debt collection. Sending to Visma should happen right after, so this status should practically never appear in the user interface.",

  "customers.detail.toolbar.contact.title": "Contact",
  "customers.detail.toolbar.contact.userIsBlocked": "User is on the blocklist ({reason})",
  "customers.detail.toolbar.contact.userIsNotBlocked": "User is not in the blocklist",
  "customers.detail.toolbar.user.userDeleted": "The user has been removed from the system",
  "customers.detail.toolbar.user.requestedUserDeletion": "User account deletion requested",
  "customers.detail.toolbar.user.blockingPrevented": "Prevented to move to the blocking list",
  "customers.detail.toolbar.user.cancelUserDeletion": "Cancel the deletion request",

  "customers.detail.toolbar.vehicles.title": "Vehicles",
  "customers.detail.toolbar.vehicles.link": "Show all vehicles",
  "customers.detail.toolbar.button.removeFromBlocklist": "Remove from the blocklist",
  "customers.detail.toolbar.button.setToBlocklist": "Set to the blocklist",
  "customers.detail.toolbar.button.editUser": "Edit customer data",
  "customers.detail.toolbar.toast.edited.title": "Customer data edited",
  "customers.detail.toolbar.toast.edited.body": "Data of {name} user has been edited.",

  "customers.detail.toolbar.cancelDeletionRequest.action.success.title": "Deletion request cancelled",
  "customers.detail.toolbar.cancelDeletionRequest.action.success.description": "User account deletion request has been cancelled.",
  "customers.detail.toolbar.cancelDeletionRequest.action.error": "Failed to cancel user's deletion request.",
  "customers.detail.toolbar.cancelDeletionRequest.confirmation.title": "Cancel user {name}'s removal request?",
  "customers.detail.toolbar.cancelDeletionRequest.confirmation.body": "Are you sure you want to cancel a deletion request of the user?",

  "customers.detail.tab.parkings": "Parkings",
  "customers.detail.tab.vouchers": "Vouchers",
  "customers.detail.tab.invoiceData": "Invoice data",
  "customers.detail.tab.subscribedServices": "Subscribed services",
  "customers.detail.tab.userAssignments": "User assignments",
  "customers.detail.tab.paymentMethods": "Payment methods",
  "customers.detail.tab.userGroups": "User groups",
  "customers.detail.tab.vehicles": "Vehicles",
  "customers.detail.tab.conversationHistory": "Conversation history",
  "customers.detail.tab.blockingList": "Blocking list",
  "customers.detail.tab.vehicles.filter.showDeletedVehicles": "Show removed vehicles too",
  "customers.detail.tab.vehicles.filter.licensePlateNumber": "License plate number",
  "customers.detail.tab.vehicles.filter.licensePlateNumber.placeholder": "Search by license plate number",
  "customers.detail.tab.vehicles.table.creationTime": "License plate added",
  "customers.detail.tab.vehicles.table.deletionTime": "License plate removed",
  "customers.detail.tab.vehicles.table.link.history": "History",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.title": "Camera recognition for the license plate {licensePlate}",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.body.inactive": "Are you sure you want to disable the camera recognition?",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.body.active": "Are you sure you want to enable the camera recognition?",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.success.title": "State changed",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.success.body": "The state of the camera recognition has been changed.",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.error": "Failed to change the state of the camera recognition.",

  "customers.detail.tab.paymentMethods.popup.paymentCardMissing": "Payment card is missing.",
  "customers.detail.tab.paymentMethods.popup.paymentCardExpired": "The payment card has expired.",
  "customers.detail.tab.paymentMethods.popup.defaultCardMissing": "Default payment card is not defined.",

  "modalConfirmDeletePaymentCard.modal.title": "Deletion of the user's payment card",
  "modalConfirmDeletePaymentCard.action.error.common": "Failed to delete the card",
  "modalConfirmDeletePaymentCard.delete.confirm": "Are you sure you want to delete payment card {card} from user {name}?",
  "modalConfirmDeletePaymentCard.form.note": "Note!",
  "modalConfirmDeletePaymentCard.form.defaultPaymentCard": "This is the default payment card.",
  "modalConfirmDeletePaymentCard.form.defaultPaymentCard.addCard": "Parking cannot be started with a Moovy account without a default card. Ask the customer to add a new default payment card.",
  "modalConfirmDeletePaymentCard.form.defaultPaymentCard.addOrSetCard": "Parking cannot be started with a Moovy account without a default card. Ask the customer to set the remaining payment card as the default card or add a new default payment card.",
  "modalConfirmDeletePaymentCard.form.lastPaymentCard.title": "You are removing the last payment card.",
  "modalConfirmDeletePaymentCard.form.lastPaymentCard.desc": "Ask the customer to add a new payment card. The new payment card automatically becomes the default payment card.",
  "modalConfirmDeletePaymentCard.form.forceRemoving": "The payment card can be removed as forced. Please note in this case that the customer and the settlement recipient are aware of possible side effects.",
  "modalConfirmDeletePaymentCard.form.checkbox.forceRemoving": "Forcefully remove the payment card",

  "customers.detail.tab.userBlockingPreventedList.title": "History of user's blocking list",
  "customers.detail.tab.userBlockingPreventedList.button.unblock": "Allow to move user to the blocking list",
  "customers.detail.tab.userBlockingPreventedList.button.block": "Prevent to move user to the blocking",

  "parkingModal.fetchError.title": "Error",
  "parkingModal.fetchError.description": "Parking information could not be loaded",

  "parkingModal.label.location": "Location",
  "parkingModal.label.customer": "Customer",
  "parkingModal.label.licensePlateNumber": "License plate number",
  "parkingModal.label.started": "Started",
  "parkingModal.label.ended": "Ended",
  "parkingModal.label.ongoing": "Ongoing",
  "parkingModal.label.duration": "Duration",
  "parkingModal.label.price": "Paid",
  "parkingModal.label.priceLeft": "Remaining to be paid",
  "parkingModal.label.priceLeft.popup.title": "The amount may be incorrect for the following reasons:",
  "parkingModal.label.priceLeft.popup.bullet1": "The calculation does not take into account the free exit time at the payment terminal after payment.",
  "parkingModal.label.priceLeft.popup.bullet2": "The calculation does not take into account the one-time parking benefit",
  "parkingModal.label.sessionId": "SessionId",
  "parkingModal.label.exitBefore": "Exit before",

  "parkingModal.parkingSales.filter.title": "Show",
  "parkingModal.parkingSales.filter.all": "All",
  "parkingModal.parkingSales.filter.onlyValid": "Only valid",
  "parkingModal.parkingSales.table.header.time": "Time",
  "parkingModal.parkingSales.table.header.price": "Price",
  "parkingModal.parkingSales.table.header.reductions": "Reductions",
  "parkingModal.parkingSales.table.header.totalPrice": "Total price",
  "parkingModal.parkingSales.table.header.type": "Type",
  "parkingModal.parkingSales.table.header.paymentMethod": "Payment method",
  "parkingModal.parkingSales.table.header.final": "Final",
  "parkingModal.parkingSales.table.header.valid": "Valid",

  "modalWebPaymentSaleStatus.cancelPayment.comment": "Cancellation reason",
  "modalWebPaymentSaleStatus.title": "Web payment",
  "modalWebPaymentSaleStatus.header.status": "Status",
  "modalWebPaymentSaleStatus.header.cancellationReason": "Cancellation reason",
  "modalWebPaymentSaleStatus.item.notSent": "Not sent",
  "modalWebPaymentSaleStatus.item.notProcessed": "Not processed",
  "modalWebPaymentSaleStatus.header.sentToService": "Sent to web payment service",
  "modalWebPaymentSaleStatus.header.handledInService": "Processed in web payment service",
  "modalWebPaymentSaleStatus.header.invoiceNumber": "Invoice number",
  "modalWebPaymentSaleStatus.button.cancelPayment": "Cancel web payment",
  "modalWebPaymentSaleStatus.operation.cancel.successfull": "Web payment cancelled successfully.",
  "modalWebPaymentSaleStatus.operation.cancel.failed": "Web payment cancellation failed.",
  "modalWebPaymentSaleStatus.notFound": "Webpayment not yet created",

  "parkingTab.button.startParking": "Start parking",

  "parkingFilters.form.label.type": "Type",
  "parkingFilters.form.label.type.options.MOOVY_PARKING": "Moovy parking",
  "parkingFilters.form.label.type.options.ANONYMOUS_PARKING": "Anonymous parking",
  "parkingFilters.form.label.status": "Status",
  "parkingFilters.form.label.status.options.ALL": "All",
  "parkingFilters.form.label.status.options.ONGOING": "Ongoing",
  "parkingFilters.form.label.status.options.ENDED": "Ended",
  "parkingFilters.form.label.parkingMethod": "Parking method",
  "parkingFilters.form.label.parkingMethod.options.ALL": "All",
  "parkingFilters.form.label.parkingMethod.options.SUBSCRIPTION": "Subscription",
  "parkingFilters.form.label.parkingMethod.options.SHORT_TERM": "Pay as you go",
  "parkingFilters.form.label.licensePlate": "License Plate",

  "listParkings.header.allEvents": "ALL EVENTS:",
  "listParkings.header.countAllEvent": "event",
  "listParkings.header.countAllEvents": "events",
  "parkingTable.header.type": "Type",
  "parkingTable.header.location": "Location",
  "parkingTable.header.city": "City",
  "parkingTable.header.startTime": "Start time",
  "parkingTable.header.endTime": "End time",
  "parkingTable.header.exitAllowedBefore": "Exit must be at least",
  "parkingTable.header.exitTimeExceeded": "Exit time exceeded",
  "parkingTable.header.duration": "Duration",
  "parkingTable.header.totalDuration": "Total duration",
  "parkingTable.header.licensePlateNumber": "Identification",
  "parkingTable.header.customer": "Customer",
  "parkingTable.header.price": "Price",
  "parkingTable.header.discount": "Discount",
  "parkingTable.header.total": "Total",
  "parkingTable.header.paymentMethod": "Payment method",
  "parkingTable.header.parkingProduct": "Parking product",
  "parkingTable.parkingProduct.subscription": "Subscription",
  "parkingTable.parkingProduct.shortParking": "Short term parking",
  "parkingTable.parkingProduct.specialParking": "Special parking",
  "parkingTable.endTime.ongoing": "Ongoing",

  "parkingTable.endParking.toast.title": "Parkings has been ended",
  "parkingTable.endParking.toast.body": "Marked parkings have been ended successfully",
  "parkingTable.endParking.banner.description": "You can end ongoing anonymous parkings by marking them on the table.",
  "parkingTable.endParking.banner.button.markAnonymous": "Mark anonymous parkings",
  "parkingTable.endParking.banner.button.endParking": "End marked anonymous parkings",
  "parkingTable.endParking.modal.error.notValidParking": "Parkings cannot be ended because some of them are not anonymous parking or they are not ongoing.",
  "parkingTable.endParking.modal.error.notValidParking.header": "Failure while ending parkings",
  "parkingTable.endParking.modal.button.endParking": "End parkings",

  "endParkingModal.header": "End marked parkings ({total} pcs)",
  "endParkingModal.form.comment": "Comment",
  "endParkingModal.form.comment.placeholder": "You can add a specific reason for parking ending.",
  "endParkingModal.action.error.ending": "Something went wrong while ending parkings.",

  "parkingTable.editLicensePlate.toast.title": "Updated",
  "parkingTable.editLicensePlate.toast.body": "License plate updated",
  "parkingTable.editLicensePlate.modal.header": "Change license plate",
  "parkingTable.editLicensePlate.modal.error.editing": "Failed to change of the license plate",
  "parkingTable.editLicensePlate.modal.licensePlate.label": "License plate",
  "parkingTable.editLicensePlate.modal.comment.label": "Comment",
  "parkingTable.confirm.moovyParking": "A Moovy account found by the given license plate. Parking is not changed to Moovy parking but it continues anonymously. Do you want to continue?",

  "conversationHistory.action.addComment": "Add comment",
  "conversationHistory.action.addComment.error.title": "Adding failed",
  "conversationHistory.action.addComment.error.description": "The comment adding has been failed.",
  "conversationHistory.action.remove.error.title": "Deletion failed",
  "conversationHistory.action.remove.error.description": "The comment deletion has been failed.",
  "conversationHistory.confirm.remove": "Are you sure you want to remove the comment?",
  "conversationHistory.conversation.today": "Today",
  "conversationHistory.conversation.yesterday": "Yesterday",

  "moovyActiveItem.labels.active": "Active",
  "moovyActiveItem.labels.inactive": "Inactive",
  "moovyActiveItem.confirmContent.active": "Are you certain you want to disable {name}?",
  "moovyActiveItem.confirmContent.inactive": "Are you certain you want to activate {name}?",
  "moovyActiveItem.confirmContent.title": "Status change",
  "moovyActiveItem.confirmContent.action.error": "Failed to change status.",

  "invoiceTable.header.invoiceNumber": "Invoice number",
  "invoiceTable.header.creationTime": "Creation time",
  "invoiceTable.header.status": "Status",
  "invoiceTable.header.invoicingAttemptCount": "Invoicing attempt count",
  "invoiceTable.header.invoicingAttemptTime": "Latest invoicing attempt",
  "invoiceTable.header.invoiceCount": "Invoice count",
  "invoiceTable.header.totalPrice": "Total",
  "invoiceTable.header.paymentMethod": "Payment method",
  "invoiceTable.header.expired": "Expired",
  "invoiceTable.header.additionalInfo": "Additional info",

  "customerInvoicing.operation.getInvoices.failed": "Invoices cannot be loaded because of an error",
  "customerInvoicing.card.header": "Expired, uninvoiced invoices",
  "customerInvoicing.card.body": "{failedInvoicesCount} pcs, total ",

  "customerInvoicing.toolbarFilter.label.invoiceNumber": "Invoice number",
  "customerInvoicing.toolbarFilter.label.invoiceSum": "Invoice sum",
  "customerInvoicing.toolbarFilter.label.invoiceStatus": "Invoice status",
  "customerInvoicing.toolbarFilter.label.invoiceMethodType": "Invoice method type",
  "customerInvoicing.toolbarFilter.button.createInvoice": "Create new invoice",

  "compensationInvoiceColumnInfo.compensationVisma": "Compensation for the invoice {invoiceName}",
  "compensationInvoiceColumnInfo.compensationOther": "Refund for the invoice {invoiceName}",

  "modalChangeUserBlockList.body.unsetFromBlocklist": "Are you sure you want to unset the user {name} from the blocking list?",
  "modalChangeUserBlockList.confirmation.title": "Status change of the blocking list",
  "modalChangeUserBlockList.action.error.default": "Failed to change status of the blocking list.",
  "modalChangeUserBlockList.toast.title.blocklisted": "Blocking list",
  "modalChangeUserBlockList.toast.description.blocklisted": "User has been set to the blocking list",
  "modalChangeUserBlockList.toast.title.unblocklisted": "Blocking list",
  "modalChangeUserBlockList.toast.description.unblocklisted": "User has been unset from the blocking list",
  "modalChangeUserBlockList.addToBlocklist.header": "Set user {name} to blocking list",
  "modalChangeUserBlockList.addToBlocklist.reason": "Other reason",
  "modalChangeUserBlockList.addToBlocklist.reason.invoicing": "Failed invoicing",
  "modalChangeUserBlockList.addToBlocklist.reason.other": "Other reason",
  "modalChangeUserBlockList.addToBlocklist.comment": "Comment",
  "modalChangeUserBlockList.addToBlocklist.comment.required": "Comment is required",
  "modalChangeUserBlockList.removeBlocklist.title": "Remove user {name} from blocking list",
  "modalChangeUserBlockList.removeBlocklist.warningMessage": "User is on the blocking list because of another reason:",
  "modalChangeUserBlockList.removeBlocklist.confirmMessage": "I have confirmed that the user can be removed from the blocking list",

  "userGroups.toolbar.title": "User groups",
  "userGroups.toolbar.input.placeholder": "User group or operator",
  "userGroups.create.button": "Create new user group",
  "userGroups.table.header.userGroup": "User group",
  "userGroups.table.header.operator": "Operator",
  "userGroups.table.header.modificationTime": "Modification time",
  "userGroups.table.body.operator.undefined": "Undefined",

  "userGroup.query.error": "Error loading user group",
  "userGroup.toolbar.rename": "Change user group name",
  "userGroup.toolbar.delete": "Delete user group",
  "userGroup.toolbar.ref": "Identifier",
  "userGroup.toolbar.operator": "Operator",
  "userGroup.toolbar.operator.undefined": "Undefined",
  "userGroup.panes.user": "Users",
  "userGroup.panes.serviceAgreements": "Service agreements",
  "userGroup.panes.voucherGrantAgreements": "Voucher grant agreements",

  "createUserGroup.message.success": "User group {groupName} created",
  "createUserGroup.message.error": "User group could not be created",
  "createUserGroup.modal.header": "Create new user group",
  "createUserGroup.modal.form.operator": "Operator",
  "createUserGroup.modal.form.operator.dropdown.placeholder": "Choose operator",
  "createUserGroup.modal.form.groupName": "User group name",

  "userGroupRenameModal.header": "Change user group name",
  "userGroupRenameModal.instructions": "Set new name for user group and press accept",
  "userGroupRenameModal.input.placeholder": "User group name",
  "userGroupRenameModal.action.failed": "Failed to edit user group name.",
  "userGroupRenameModal.action.title": "User group name renamed",
  "userGroupRenameModal.action.description": "The name {name} has been renamed.",

  "userGroupDeleteModal.header": "Delete \"{groupName}\" user group?",
  "userGroupDeleteModal.instructions": "Once deleted, user group can't be restored",
  "userGroupDeleteModal.error": "User group deletion was not successful",
  "userGroupDeleteModal.notAllowed.header": "User group \"{groupName}\" can't be deleted",
  "userGroupDeleteModal.notAllowed.instructions": "First delete the following dependencies:",
  "userGroupDeleteModal.notAllowed.users": "Users: {count}",
  "userGroupDeleteModal.notAllowed.serviceAgreements": "Service agreements: {count}",
  "userGroupDeleteModal.notAllowed.voucherGrantAgreements": "Voucher grant agreements: {count}",

  "userGroupAddUserModal.header": "Add user to group \"{name}\"",
  "userGroupAddUserModal.search.placeholder": "Input user",
  "userGroupAddUserModal.search.fetching": "Loading...",
  "userGroupAddUserModal.error": "Error adding user to user group",

  "userGroupUsersTab.button.add": "Add new user",
  "userGroupUsersTab.success": "New user added",

  "userGroupMembershipsTable.header.name": "Name",
  "userGroupMembershipsTable.header.ref": "User identifier",

  "editServiceAgreement.allServiceAgreements": "All service agreements",
  "editServiceAgreement.query.error": "Error loading service agreement",
  "editServiceAgreement.unsavedChanges": "Press Submit for discount changes to be applied",
  "editServiceAgreement.form.maxCount": "Maximum subscriptions",
  "editServiceAgreement.form.maxCount.placeholder": "Unlimited",
  "editServiceAgreement.form.capacity": "Orders",
  "editServiceAgreement.form.percentDiscount": "Discount percent",
  "editServiceAgreement.form.percentDiscount.notValid.message": "Input value between 0 - 100",
  "editServiceAgreement.submit.success": "Service agreement updated",
  "editServiceAgreement.submit.invalid": "Service agreement is invalid, changes not saved",
  "editServiceAgreement.submit.error": "Error updating service agreement",
  "editServiceAgreement.table.header.capacity": "Orders",
  "editServiceAgreement.table.header.percentDiscount": "Discount",
  "editServiceAgreement.table.header.type": "Type",
  "editServiceAgreement.table.body.capacity.undefined": "Unlimited",
  "editServiceAgreement.table.footer.new.discount": "New discount",

  "serviceAgreementsTab.button.new": "Add new service agreement",
  "serviceAgreementsTab.query.error": "Error loading service agreements",
  "serviceAgreementsTab.delete.error": "Error deleting service agreement",

  "serviceAgreementTable.header.product": "Product",
  "serviceAgreementTable.header.maxCount": "Maximum subscriptions",
  "serviceAgreementTable.header.discounts": "Discounts",
  "serviceAgreementTable.table.maxCount.undefined": "Unlimited",
  "serviceAgreementTable.table.discount.undefined": "No discounts",
  "serviceAgreementTable.table.delete.confirm": "Are you certain that you want to delete service agreement for {name}?",

  "addAgreementModal.header.serviceAgreement": "Add new service agreement",
  "addAgreementModal.header.voucherAgreement": "Add new voucher grant agreement",
  "addAgreementModal.errorMessage.serviceAgreement": "Error adding service agreement",
  "addAgreementModal.errorMessage.voucherAgreement": "Error adding voucher grant agreement",
  "addAgreementModal.placeholder.serviceAgreement": "Search products",
  "addAgreementModal.placeholder.voucherAgreement": "Search vouchers",

  "voucherGrantAgreementsTab.button.add": "Add new voucher grant agreement",
  "voucherGrantAgreementsTab.query.error": "Error loading voucher grant agreements",
  "voucherGrantAgreementsTab.delete.confirm": "Are you sure you want to remove voucher grant agreement \"{name}\"?",
  "voucherGrantAgreementsTab.delete.error": "Error deleting voucher grant agreement",
  "voucherGrantAgreementsTab.table.header.voucher": "Voucher",

  "subscriptionPricingProfile.itemTypes.CONSTANT_PERCENTAGE_DISCOUNT": "Constant",
  "subscriptionPricingProfile.itemTypes.MASS_PERCENTAGE_DISCOUNT": "Amount",

  "enterpriseCustomer.toolbar.button.editUser": "Edit customer data",
  "enterpriseCustomer.toolbar.button.editInvoicing": "Edit invoicing data",
  "enterpriseCustomer.toolbar.title": "Enterprise customer details",
  "enterpriseCustomer.toolbar.inactive": "User is inactive",
  "enterpriseCustomer.toolbar.keycloak.username": "Username",
  "enterpriseCustomer.toolbar.keycloak.email": "Registered email address",
  "enterpriseCustomer.toolbar.contactPerson": "Contact person",
  "enterpriseCustomer.toolbar.invoicing": "Invoicing",

  "enterpriseCustomer.detail.tab.userAssignments": "User assignments",
  "enterpriseCustomer.detail.tab.orderedProducts": "Ordered products",
  "enterpriseCustomer.detail.tab.invoiceData": "Invoice data",
  "enterpriseCustomer.detail.tab.grantedVouchers": "Granted vouchers",
  "enterpriseCustomer.detail.tab.userGroups": "User groups",
  "enterpriseCustomer.detail.tab.conversationHistory": "Conversation history",

  "enterpriseCustomer.invoicing.ovt": "OVT",
  "enterpriseCustomer.invoicing.operator": "Operator",
  "enterpriseCustomer.invoicing.email": "Email",
  "enterpriseCustomer.invoicing.address": "Invoicing address",
  "enterpriseCustomer.invoicing.type": "Type",
  "enterpriseCustomer.invoicing.type.PAPER": "Paper invoicing",
  "enterpriseCustomer.invoicing.type.EMAIL": "Email invoicing",
  "enterpriseCustomer.invoicing.type.DIRECT": "Electronic invoicing",
  "enterpriseCustomer.invoicing.customReference": "Custom reference",

  "enterpriseCustomer.editInvoicing.edit.header": "Modifying invoice info for business user {company}",
  "enterpriseCustomer.editInvoicing.paper.info": "The billing address is compiled from the company account user's information. The billing address can be changed by the business user via Moovy Business.",
  "enterpriseCustomer.editInvoicing.email.info": "The email address is the email address of the business account user. The e-mail address can be changed by the business account user himself through Moovy Business.",
  "enterpriseCustomer.editInvoicing.mutation.error": "Updating invoicing info failed",
  "enterpriseCustomer.editInvoicing.toast.success.title": "Invoicing info updated",
  "enterpriseCustomer.editInvoicing.toast.success.body": "Business user invoicing info changes saved",

  "grantedVouchers.query.error": "Error while loading data",
  "grantedVouchers.filter.vouchers": "Select voucher",
  "grantedVouchers.header": "Granted vouchers",
  "grantedVouchers.table.header.date": "Date",
  "grantedVouchers.table.header.licensePlateNumber": "License plate number",
  "grantedVouchers.table.header.phoneNumber": "Phonenumber",
  "grantedVouchers.table.header.voucher": "Voucher",
  "grantedVouchers.table.header.price": "Price",
  "grantedVouchers.card.header.altogether": "Altogether",
  "grantedVouchers.card.item.givenVoucher": "Granted vouchers",
  "grantedVouchers.card.item.pcs": "pcs",
  "grantedVouchers.card.item.totalCost": "Costs for granter",

  "blockList.toolbar.title": "Blocklist",
  "blockList.toolbar.input.placeholder": "Name or phonenumber",
  "blockList.table.header.status": "Blocklisted",
  "blockList.mutation.error": "Error setting blocklist status",
  "blockList.modal.userIsBlocked": "User is in the blocklist",
  "blockList.modal.userIsNotBlocked": "User is not in the blocklist",

  "parkingModal.tab.parkingSequence": "Parking sequence",
  "parkingModal.tab.sales": "Sale events",
  "parkingModal.tab.vouchers": "Vouchers",
  "parkingModal.tab.paymentEvents": "Payment events",
  "parkingModal.tab.auditLogs": "Audit logs",
  "parkingModal.tab.externalFacilityData": "Camera recognition",
  "parkingModal.tab.externalFacilityDataContainer.imageLoadingError": "Error loading camera recognition data",

  "parkingService.toolbar.title": "Parking services",
  "parkingService.toolbar.filter.search.placeholder": "Search services",
  "parkingService.toolbar.filter.operator.placeholder": "Select operator",
  "parkingService.toolbar.filter.service.placeholder": "Select service",
  "parkingService.toolbar.filter.error": "Please, fill at least three characters.",
  "parkingService.header.service": "Service",
  "parkingService.header.type": "Type",
  "parkingService.header.vehicleAccessPriority": "Priority",
  "parkingService.header.popup.vehicleAccessPriority": "Parking with subscription will be started with the highest priority service if the customer has more than one valid service at the same location.",
  "parkingService.header.operator": "Operator",
  "parkingService.location.button.editPriority": "Edit priority",
  "parkingService.location.action.updateServicePriority.success.title": "Priority updated",
  "parkingService.location.action.updateServicePriority.success.desc": "Product priority updated",
  "parkingService.location.action.updateServicePriority.failed.title": "Failed to update",
  "parkingService.location.action.updateServicePriority.failed.desc": "Failed to update product priority.",

  "parkingServiceDetails.toolbar.button.editCapacity.tooltip": "Edit service",
  "parkingServiceDetails.toolbar.button.editName.tooltip": "Edit service name",
  "parkingServiceDetails.toolbar.button.editVisibility.tooltip": "Edit service visibility",
  "parkingServiceDetails.toolbar.button.editOverbooking.tooltip": "Edit overbooking coefficient",

  "parkingServiceDetails.subscriptionServiceToolbar.label.type": "Service type:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.visibility": "Service visibility:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.overbookingCoefficient": "Overbooking coefficient:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.serviceOrderedCount": "Service ordered:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.parkingCapacity": "Parking capacity for simultaneous parking:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.allowParkingOvercapacity": "Allow parking capacity to be exceeded:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.description": "Service description:",
  "parkingServiceDetails.subscriptionServiceToolbar.link.description": "Show description",
  "parkingServiceDetails.subscriptionServiceToolbar.label.parkingFeeSettlementRecipient": "Settlement recipient of parking fee:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.periodFeeSettlementRecipient": "Settlement recipient of month fee:",

  "parkingServiceDetails.payAsYouGoServiceToolbar.label.type": "Service type:",
  "parkingServiceDetails.payAsYouGoServiceToolbar.label.visibility": "Service visibility:",
  "parkingServiceDetails.payAsYouGoServiceToolbar.label.parkingCapacity": "Parking capacity for simultaneous parking:",
  "parkingServiceDetails.payAsYouGoServiceToolbar.label.allowParkingOvercapacity": "Allow parking capacity to be exceeded:",

  "parkingServiceDetails.tab.subscribedServices.title": "Active subscriptions",
  "parkingServiceDetails.tab.subscribedServices.table.filter.search.placeholder": "Search subscriptions...",
  "parkingServiceDetails.tab.subscribedServices.table.header.owner": "Owner",
  "parkingServiceDetails.tab.subscribedServices.table.header.type": "Type",
  "parkingServiceDetails.tab.subscribedServices.table.header.phone": "Phone",
  "parkingServiceDetails.tab.subscribedServices.table.header.email": "Email",
  "parkingServiceDetails.tab.subscribedServices.table.header.vehicles": "Active vehicles",
  "parkingServiceDetails.tab.subscribedServices.table.header.subscriptions": "Subscriptions",
  "parkingServiceDetails.tab.subscribedServices.table.header.endTime": "Ending subscriptions",

  "parkingServiceDetails.tab.pricing.title": "Pricing",
  "parkingServiceDetails.tab.pricing.table.header.startTime": "Start date",
  "parkingServiceDetails.tab.pricing.table.header.endTime": "End date",
  "parkingServiceDetails.tab.pricing.table.header.periodicFee": "Monthly fee",
  "parkingServiceDetails.tab.pricing.table.header.price": "Service price as a text",
  "parkingServiceDetails.tab.pricing.table.button.show": "Show",
  "parkingServiceDetails.tab.pricing.button.addPricing": "Add pricing",

  "parkingServiceDetails.tab.locations.title": "Locations",
  "parkingServiceDetails.tab.locations.button.addLocation": "Add location",
  "parkingServiceDetails.tab.locations.table.header.name": "Name",
  "parkingServiceDetails.tab.locations.table.header.internalName": "Internal name",
  "parkingServiceDetails.tab.locations.table.header.city": "City",
  "parkingServiceDetails.tab.locations.table.header.anonymousParking": "Anonymous parking",
  "parkingServiceDetails.tab.locations.table.confirm.removeLocation": "Unlink the location {locationName} from the service {serviceName}?",
  "parkingServiceDetails.tab.locations.toast.title.locationRemoved": "Removed the location linking",
  "parkingServiceDetails.tab.locations.toast.description.locationRemoved": "The linking has been removed from service.",
  "parkingServiceDetails.tab.locations.toast.error.title": "Failed to link removing",
  "parkingServiceDetails.tab.locations.toast.error.description": "Failed to remove the linking of the location.",

  "parkingServiceDetails.tab.description.title": "Description",
  "parkingServiceDetails.tab.description.description.notAvailable": "No description",

  "parkingServiceDetails.tab.description.toast.edit.title": "Description has been edited",
  "parkingServiceDetails.tab.description.toast.edit.body": "Description has been successfully saved.",

  "parkingServiceDetails.tab.validityTimes.title": "Validity periods",
  "parkingServiceValidityTimesTab.validityTimes": "Validity periods of the subscription",
  "parkingServiceValidityTimesTab.validityTimesNotDefined": "There is no validity periods defined in the subscription.",
  "parkingServiceValidityTimesTab.toast.title.validityTimesUpdated": "Update done",
  "parkingServiceValidityTimesTab.toast.description.validityTimesUpdated": "Schedules have been updated.",

  "modalEditValidityTimes.title": "Edit schedules",
  "modalEditValidityTimes.edit.error": "The schedules update failed.",
  "modalEditValidityTimes.diff.header": "Changes",
  "modalEditValidityTimes.validityTest.header": "Test service validity",

  "modalEditDescription.title": "Edit description",
  "modalEditDescription.edit.error": "Failed to save the description.",

  "serviceTariff.toolbar.title": "Tariff data",
  "serviceTariff.toolbar.label.startTime": "Start date:",
  "serviceTariff.toolbar.label.endTime": "End date:",
  "serviceTariff.toolbar.label.periodicFeed": "Montly fee:",
  "serviceTariff.toolbar.label.serviceName": "Service name:",
  "serviceTariff.toolbar.label.serviceRef": "Service reference:",
  "serviceTariff.tariffStructure": "Tariff structure",
  "serviceTariff.tariffStructureMissing": "Tariff structure missing",

  "externalFacilityDataTable.header.createdTime": "Time",
  "externalFacilityDataTable.header.state": "State",
  "externalFacilityModal.imageMissing": "Camera recognition image was not found",

  "operatorsList.toolbar.search.placeholder": "Search operators",

  "operatorDetails.toolbar.search.placeholder": "Search locations",

  "locations.tab.parkingNow": "Parkings now",
  "locations.tab.parkingEnded": "Ended parkings",
  "locations.tab.parkings": "Parkings",
  "locations.tab.vouchers": "Vouchers",
  "locations.tab.services": "Parking services",
  "locations.tab.services.title": "Parking services",
  "locations.tab.description": "Description",
  "locations.tab.description.title": "Description",
  "locations.tab.description.descriptionIsMissing": "Description is missing",
  "locations.tab.description.toast.success.edit.title": "Description has been edited",
  "locations.tab.description.toast.success.edit.body": "Description has been successfully saved.",
  "locations.tab.description.toast.failed.edit.title": "Operation failed",
  "locations.tab.description.toast.failed.edit.body": "Description has not been saved.",
  "locations.tab.vehicleAccessLogs": "Vehicles access logs",
  "locations.tab.vehicleAccessLogs.title": "Vehicles access logs",
  "locations.tab.vehicleAccessLogs.table.creationTime": "Creation time",
  "locations.tab.vehicleAccessLogs.table.licensePlate": "License plate",
  "locations.tab.vehicleAccessLogs.table.customer": "Customer",
  "locations.tab.vehicleAccessLogs.table.location": "Location",
  "locations.tab.vehicleAccessLogs.table.eventType": "Event type",
  "locations.tab.vehicleAccessLogs.table.decision": "Decision",
  "locations.tab.vehicleAccessLogs.table.decisionResponse": "Decision response",
  "locations.tab.vehicleAccessLogs.table.link.details": "Details",

  "locations.toolbar.toast.actived.title": "Kohde aktivoitu",
  "locations.toolbar.toast.actived.body": "{name} aktivoitu.",
  "locations.toolbar.toast.deactived.title": "Kohde ei käytössä",
  "locations.toolbar.toast.deactived.body": "{name} poistettu käytöstä.",
  "locations.toolbar.toast.statusChanged.title": "Kohteen tilaa vaihdettu",
  "locations.toolbar.toast.statusChanged.body": "Tilan vaihto onnistui.",

  "locations.toolbar.label.operator": "Operator:",
  "locations.toolbar.label.address": "Address:",
  "locations.toolbar.label.city": "City:",
  "locations.toolbar.label.type": "Type:",
  "locations.toolbar.checkbox.anonymousParking": "Anonymous parking",
  "locations.toolbar.checkbox.licensePlateRecognition": "License plate recognition",
  "locations.toolbar.checkbox.barrierlessFacility": "Barrierless facility (web payment)",
  "locations.toolbar.checkbox.parkingControlled": "Parking controlled",
  "locations.toolbar.label.doorCode": "Doorcode:",
  "locations.toolbar.label.notSet": "Not set",
  "locations.toolbar.label.maximumParkingTime": "Maximum parking time:",
  "locations.toolbar.label.parkingGracePeriod": "Grace Period:",
  "locations.toolbar.label.allowedExitTime": "Exit time after payment:",
  "locations.toolbar.label.parkingControlId": "Parking control identifier:",
  "locations.toolbar.label.customActionName": "Integrations:",
  "locations.toolbar.label.locationCard": "Location card:",
  "locations.toolbar.link.editLocationCard": "Open location card",
  "locations.toolbar.button.editLocationCard": "Edit location card",
  "locations.toolbar.label.automaticEnding": "Automatic ending:",
  "locations.toolbar.label.autoCleanThresholdInDays": "{days} days",
  "locations.toolbar.label.automaticEnding.info": "Anonymous parking at the location is automatically ended if it has been ongoing for more than the time specified here.",
  "locations.toolbar.button.editAutomaticEnding": "Edit the time of automatic end parking.",
  "locations.toolbar.label.mainLocation": "Main location:",
  "locations.toolbar.label.subLocation": "Sub location:",
  "locations.toolbar.button.editLocation": "Edit location",
  "locations.toolbar.button.editArea": "Edit area",
  "locations.toolbar.button.editName": "Edit location name",
  "locations.toolbar.button.editDoorCode": "Edit door code",
  "locations.toolbar.info.type.facility": "The facilities have parking infrastructure included. Parking is automatically started when driving to the destination. The facility is shown in blue on mobile and in yellow for subscription users.",
  "locations.toolbar.info.type.lot": "This is used in certain parking areas and the parking must be started yourself. There are usually lights in the area that indicate free places. The area is shown in dark green on mobile.",
  "locations.toolbar.info.type.zone": "This is used in towns for on-street parking where there is a larger area the size of several blocks. The zone is shown in light green on mobile.",

  "locationEditModal.title": "Edit {name} location",
  "locationEditModal.label.parkingGracePeriod": "Parking grace period (minutes):",
  "locationEditModal.label.allowedExitTime": "Exit time after payment (minutes):",
  "locationEditModal.action.error.update": "Failed to save data.",
  "locationEditModal.toast.update.title": "The location has been updated.",
  "locationEditModal.toast.update.body": "The location {name} has been updated.",

  "modalEditAutoCleanParking.title": "Automatic ending of the parking at {name}",
  "modalEditAutoCleanParking.label.autoCleanThreshold": "Maximum time of anonymous parking as days before automatic parking end",
  "modalEditAutoCleanParking.action.error.update": "Failed to save time.",
  "modalEditAutoCleanParking.toast.update.title": "Automatic ending time updated.",
  "modalEditAutoCleanParking.toast.update.body": "The time for the automatic end of parking has been updated.",

  "modalEditDoorCode.title": "Edit the door code of {name}",
  "modalEditDoorCode.label.doorCode": "Door code",
  "modalEditDoorCode.action.error.update": "Failed to save the door code.",
  "modalEditDoorCode.toast.update.title": "The location door code has been updated.",
  "modalEditDoorCode.toast.update.body": "The door code of the location {name} has been updated.",

  "modalEditExtensionInfo.title": "Edit card for the location {name}",
  "modalEditExtensionInfo.label.url": "The location card url",
  "modalEditExtensionInfo.action.error.update": "Failed to save url.",
  "modalEditExtensionInfo.toast.update.title": "The card url has been updated.",
  "modalEditExtensionInfo.toast.update.body": "The location card url has been updated for the location {name}.",

  "modalEditLocationCapacity.title": "Edit amount of places of the location {name}",
  "modalEditLocationCapacity.label.locationCapacity": "Total places of of the location",
  "modalEditLocationCapacity.action.error.update": "Failed to edit places count of the location.",
  "modalEditLocationCapacity.toast.update.title": "Places count updated.",
  "modalEditLocationCapacity.toast.update.body": "The places count of the location has been updated.",

  "locationEditNameModal.title": "Edit the name of {name}",
  "locationEditNameModal.label.name": "Location name",
  "locationEditNameModal.action.error.update": "Failed to save the name.",
  "locationEditNameModal.toast.updateName.title": "The name has been updated.",
  "locationEditNameModal.toast.updateName.body": "The new name {name} has been updated.",

  "locationGeoJSONModal.title": "Edit location area",
  "locationGeoJSONModal.query.error": "Error loading location area data",
  "locationGeoJSONModal.confirm.title": "Updating area",
  "locationGeoJSONModal.confirm.content": "Area changes are applied immediately after saving and the old area date is lost. Are you sure?",
  "locationGeoJSONModal.toast.success.title": "Area updated",
  "locationGeoJSONModal.toast.success.body": "Changes to the area have been saved.",
  "locationGeoJSONModal.toast.failed.title": "Operation failed",
  "locationGeoJSONModal.toast.failed.body": "Changes to the area weren't saved.",

  "locationDescriptionEditModal.title": "Edit description",
  "locationDescriptionEditModal.edit.error": "Failed to save the description.",
  "locationDescriptionEditModal.label.description": "Description as a HTML format",
  "locationDescriptionEditModal.label.description.info": "The description is displayed in the map of the mobile application when you select the destination.",
  "locationDescriptionEditModal.label.descriptionPreview": "Description as a HTML format, preview",

  "messageCenter.toolbar.title": "Create and send a new message to the message center",
  "messageCenter.table.header": "Sent messages",
  "messageCenter.table.header.status": "Status",
  "messageCenter.table.header.updated": "Updated",
  "messageCenter.table.header.publishTime": "Publish time",
  "messageCenter.table.header.expiredTime": "Publish end time",
  "messageCenter.table.header.deletionTime": "Publish deletion time",
  "messageCenter.table.header.pushMessage": "Push message",
  "messageCenter.table.header.targetGroup": "Target group",
  "messageCenter.table.label.multipleGroups": "Multiple groups",
  "messageCenter.table.column.link.show": "Show",
  "messageCenter.table.toast.copy.title": "The message copied",
  "messageCenter.table.toast.copy.body": "The message content has been copied for a new message.",

  "messageCenter.action.createMessage": "Create a new message",
  "messageCenter.action.results": "Results",

  "messageCenter.createMessage.action.saveAndContinue": "Save and continue",
  "messageCenter.createMessage.action.sendMessage": "Send message",
  "messageCenter.createMessage.action.saveDraft": "Save draft",
  "messageCenter.createMessage.step.tab.title.finnish": "Finnish",
  "messageCenter.createMessage.step.tab.title.english": "English",
  "messageCenter.createMessage.step.tab.title.swedish": "Swedish",
  "messageCenter.createMessage.step.tab.confirmation.missingLanguage": "Are you sure you want to continue without complete translations? The {language} tab does not contain all the information compared to the Finnish tab.",
  "messageCenter.createMessage.step.tab.confirmation.missingBothLanguage": "Are you sure you want to continue without translations? Both English and Swedish tabs do not contain all the information compared to the Finnish tab.",

  "messageCenter.createMessage.step.targetGroup.title": "Target group",
  "messageCenter.createMessage.step.targetGroup.description": "Decide a target group for the message",
  "messageCenter.createMessage.step.targetGroup.pageDescription": "Select an existing target group or create a brand new one.",
  "messageCenter.createMessage.step.targetGroup.tab.header.target": "Select target group",
  "messageCenter.createMessage.step.targetGroup.tab.header.createTarget": "Create target group",
  "messageCenter.createMessage.step.targetGroup.tab.header.createNumberGroup": "Create group by phone numbers",

  "messageCenter.createMessage.step.messageContent.title": "Message",
  "messageCenter.createMessage.step.messageContent.description": "Create a message",
  "messageCenter.createMessage.step.messageContent.label.messageTitle": "Message title",
  "messageCenter.createMessage.step.messageContent.label.messageContent": "Message content (optional)",
  "messageCenter.createMessage.step.messageContent.label.imageLink": "Image link (optional)",
  "messageCenter.createMessage.step.messageContent.label.videoLink": "Video link (optional)",
  "messageCenter.createMessage.step.messageContent.label.ctaLink": "Call-to-action link (optional)",
  "messageCenter.createMessage.step.messageContent.label.ctaLinkOutside": "Link ouside application",
  "messageCenter.createMessage.step.messageContent.label.ctaLinkInside": "Link inside application",
  "messageCenter.createMessage.step.messageContent.label.ctaLinkText": "Call-to-action link text (optional)",
  "messageCenter.createMessage.step.messageContent.link.copyToAllLanguages": "+ Copy the link to all languages",
  "messageCenter.createMessage.step.messageContent.placeholder.target": "Select a target",

  "messageCenter.createMessage.step.sendingDetails.title": "Send method",
  "messageCenter.createMessage.step.sendingDetails.description": "Sending time and method",
  "messageCenter.createMessage.step.sendingDetails.header": "Decide sending time and method",
  "messageCenter.createMessage.step.sendingDetails.label.publishTime": "Publish time",
  "messageCenter.createMessage.step.sendingDetails.label.expirationTime": "Publish expiration",
  "messageCenter.createMessage.step.sendingDetails.label.publishOnlyInMsgCenter": "Publish the message only on Message center",
  "messageCenter.createMessage.step.sendingDetails.label.publishBothMsgCenterAndPush": "Publish the message on Message center and send Push message",
  "messageCenter.createMessage.step.sendingDetails.label.publishMethod": "Publish method",
  "messageCenter.createMessage.step.sendingDetails.push.label.title": "Push message title",
  "messageCenter.createMessage.step.sendingDetails.push.label.message": "Push message",

  "messageCenter.createMessage.step.summary.title": "Summary",
  "messageCenter.createMessage.step.summary.description": "Verify and send the message",
  "messageCenter.createMessage.step.summary.header": "Check the content of the message and the target group and accept the message sending",

  "messageCenter.createMessage.toast.draft.title": "Draft has been saved",
  "messageCenter.createMessage.toast.draft.body": "The draft message has been saved successfully.",
  "messageCenter.createMessage.toast.delete.title": "Message has been removed",
  "messageCenter.createMessage.toast.delete.body": "The message has been removed successfully.",
  "messageCenter.createMessage.toast.save.title": "Message has been sent",
  "messageCenter.createMessage.toast.save.body": "The message has been send successfully.",
  "messageCenter.createMessage.toast.error.title": "The message saving failed",
  "messageCenter.createMessage.toast.error.description": "The message cannot be saved. Please, check the filled values.",
  "messageCenter.createMessage.confirmation.deleteMessage": "Are you sure you want to remove the message?",

  "messageCenter.targetGroup.operator.not.equal": "is",
  "messageCenter.targetGroup.operator.not.notEqual": "is not",
  "messageCenter.targetGroup.action.success.create.title": "The group created",
  "messageCenter.targetGroup.action.success.create.body": "New target group {name} has been created.",
  "messageCenter.targetGroup.action.error.create.title": "The creation failed",
  "messageCenter.targetGroup.action.error.create.body": "The target group creation has been failed.",
  "messageCenter.targetGroup.action.error.create.targetExist.body": "The target group with the same name exists.",
  "messageCenter.targetGroup.action.copy.title": "Group copied",
  "messageCenter.targetGroup.action.copy.description": "Edit the copied content and create a new group.",
  "messageCenter.targetGroup.filter.and": "AND",
  "messageCenter.targetGroup.filter.or": "OR",
  "messageCenter.targetGroup.filter.button.and": "AND",
  "messageCenter.targetGroup.filter.button.or": "OR",
  "messageCenter.targetGroup.filter.button.addFilter": "+ Add filter",
  "messageCenter.targetGroup.filter.dateType.days": "days",
  "messageCenter.targetGroup.filter.dateType.weeks": "weeks",
  "messageCenter.targetGroup.filter.dateType.months": "months",
  "messageCenter.targetGroup.button.createTargetGroup": "Create target group",
  "messageCenter.targetGroup.label.targetGroupName": "Target group name",
  "messageCenter.targetGroup.label.filterScope": "Filter scope",
  "messageCenter.targetGroup.label.timeIntervalValue": "Find the target from this day behind:",
  "messageCenter.targetGroup.label.timeIntervalDisabled": "Switch the filter scope off",

  "messageCenter.targetGroup.phoneNumbers.message.success.title": "The target group created",
  "messageCenter.targetGroup.phoneNumbers.message.success.description": "The new target group {targetGroupName} has been created.",
  "messageCenter.targetGroup.phoneNumbers.label.fillPhoneNumbers": "Fill phone numbers separated by comma",
  "messageCenter.targetGroup.phoneNumbers.label.targetGroupName": "Target group name",

  "messageCenter.targetGroup.selector.action.remove.success.toast.title": "Target group removed",
  "messageCenter.targetGroup.selector.action.remove.success.toast.body": "The group {name} has been removed.",
  "messageCenter.targetGroup.selector.action.remove.error.toast.title": "Deletion failed",
  "messageCenter.targetGroup.selector.action.remove.error.toast.body": "Failed to delete the target group.",
  "messageCenter.targetGroup.selector.action.remove.loading": "The target group is being deleted...",
  "messageCenter.targetGroup.selector.targetTable.title.groupName": "Target group name",
  "messageCenter.targetGroup.selector.targetTable.title.created": "Created",
  "messageCenter.targetGroup.selector.targetTable.link.selected": "Selected",
  "messageCenter.targetGroup.selector.targetTable.link.select": "Select",
  "messageCenter.targetGroup.selector.targetTable.confirm.remove": "Are you sure you want to remove the target group?",
  "messageCenter.targetGroup.selector.segment.label.selectedTargetGroups": "The selected target groups",
  "messageCenter.targetGroup.selector.segment.label.potentialCustomers": "of customers will be potentially see this message.",
  "messageCenter.targetGroup.selector.segment.link.unselect": "Unselect selection",
  "messageCenter.targetGroup.selector.segment.link.updateUserCount": "Update customer count",
  "messageCenter.targetGroup.selector.action.add.groupMissing.title": "The target group is missing",
  "messageCenter.targetGroup.selector.action.add.groupMissing.body": "Please, select at least one target group",

  "modalMessagePreview.header": "Content of the message",
  "modalMessagePreview.button.copy": "Create a copy of the message",
  "modalMessagePreview.error.readingMessage": "Failed to show the message. Please open the message again.",
  "modalMessagePreview.error.copyMessage": "Failed to copy the message. Please open the message again and try again.",

  "paymentCards.checkbox.label.showDeletedCardsToo": "Show removed payment cards too",
  "paymentCards.table.header.cardCategory": "Private / Enterprise",
  "paymentCards.table.header.type": "Type",
  "paymentCards.table.header.cardDigits": "Digits",
  "paymentCards.table.header.expireYear": "Expire",
  "paymentCards.table.header.paymentMethodType": "Payment type",
  "paymentCards.table.header.defaultCard": "Default card",
  "paymentCards.table.header.creationTime": "Card added",
  "paymentCards.table.header.deletionTime": "Card removed",

  "paymentCards.action.remove.success.title": "Card deleted",
  "paymentCards.action.remove.success.description": "The card has been successfully deleted.",

  "locationsList.locationToolbar.title": "Locations",
  "locationsList.locationToolbar.filter.placeholder": "Search locations",
  "locationsList.locationToolbar.operatorSelect.placeholder": "Choose operator",

  "sales.toolbar.title": "Web payments",
  "sales.toolbar.licensePlate.placeholder": "License plate",
  "sales.toolbar.invoiceNumber.placeholder": "Invoice number",
  "sales.toolbar.status.placeholder": "Status",
  "sales.toolbar.startTime.placeholder": "Creation time starting",
  "sales.toolbar.endTime.placeholder": "Creation time ending",
  "sales.toolbar.settlementRecipient.placeholder": "Choose settlement recipient",
  "sales.toolbar.product.placeholder": "Choose product",
  "sales.table.header.creationTime": "Creation time",
  "sales.table.header.price": "Price before discounts",
  "sales.table.header.invoiceNumber": "Invoice number",
  "sales.table.header.licensePlate": "License plate number",
  "sales.table.header.status": "Status",
  "sales.table.header.sendTime": "Sent",
  "sales.table.header.resolveTime": "Resolved",
  "sales.cancel.selected.button": "Cancel selected webpayments",
  "sales.cancel.selected.info": "Selected {count} webpayments",

  "financesFilterDateRange.label.startDate": "Start date",
  "financesFilterDateRange.label.endDate": "End date",
  "financesFilterSingleDate.label.date": "Date",
  "financesFilterYearMonth.label.year": "Year",
  "financesFilterYearMonth.label.month": "Month",
  "financesFilterOperator.label.operator": "Choose operator",
  "financesFilterOperator.label.settlementRecipient": "Settlement recipient",
  "finances.title.reports": "Finances report",
  "finances.tab.button.download": "Download CSV",
  "finances.tab.reportSales.tabLabel": "Sales",
  "finances.tab.reportSales.header": "Download sales as a CSV report by month",
  "finances.tab.reportVismaMandators.tabLabel": "Settlement recipient Visma report",
  "finances.tab.reportVismaMandators.header": "Download Visma report as a CSV report between",
  "finances.tab.reportVismaMandators.label.operator": "Select operator",
  "finances.tab.reportVismaMandators.label.mandator": "Select Visma settlement recipient",
  "finances.tab.reportInfoKiosk.tabLabel": "Info kiosk paid parkings",
  "finances.tab.reportInfoKiosk.header": "Download info kiosks paid parkings as a CSV-report between",
  "finances.tab.reportInfoKiosk.label.operator": "Select operator",

  "finances.tab.reportOpenBarrierlessFacilityDebtCollectionCases.tabLabel": "Download open debt collection cases",
  "finances.tab.reportCompletedBarrierlessFacilityDebtCollectionCases.tabLabel": "Download completed debt collections",
  "finances.tab.reportOverdueWebpayments.tabLabel": "Overdue web payments",
  "finances.tab.reportCancelledBarrierlessFacilityDebtCollectionCases.tabLabel": "Download revoked debt collections",
  "finances.tab.reportAdyendPaymentReconciliation.tabLabel": "Adyen-payments reconciliation",
  "finances.tab.reportAdyenMoovyCreditLossByMonth.tabLabel": "Moovy credit loss",
  "finances.tab.reportAdyenMoovyReturnedCreditLoss.tabLabel": "Moovy returned credit loss",
  "finances.tab.reportAdyenSuccessfulPaymentsForMoovy.tabLabel": "Moovy successful payments",
  "finances.tab.reportAdyenSuccessfulRefunds.tabLabel": "Adyen successful refunds",
  "finances.tab.reportAdyenSuccessfulPayments.tabLabel": "Adyen successful payments by products",
  "finances.tab.reportCancelledWebPayments.tabLabel": "Download cancelled web payments",

  "finances.tab.reportOpenBarrierlessFacilityDebtCollectionCases.header": "Download report of open debt collection cases in barrierless facilities. The report contains issues regarding the selected month. Because of the delay of batch job updating invoice statuses also the first day of next month is included in results.",
  "finances.tab.reportCompletedBarrierlessFacilityDebtCollectionCases.header": "Download report of paid debt collection cases in barrierless facilities. The report contains issues between the second day of the selected month until the second day of the next month.",
  "finances.tab.reportOverdueWebpayments.header": "Download report of overdue web payments. The report contains web payments that became due during the selected month.",
  "finances.tab.reportCancelledBarrierlessFacilityDebtCollectionCases.header": "Download report of revoked debt collections in barrierless facilities. The report contains issues between the first day of the selected month until the first day of the next month.",
  "finances.tab.reportAdyendPaymentReconciliation.header": "Download settlement recipient-specific Adyen payments to support virtual account balance reconciliation. Enter the month of sale in the year and month fields.",
  "finances.tab.reportAdyendPaymentReconciliation.paymentStatus": "Payment status",
  "finances.tab.reportAdyendPaymentReconciliation.settledBefore": "Include events before the given date",
  "finances.tab.reportAdyenMoovyCreditLossByMonth.header": "Permanently failed invoices sum for the settlement recipient Moovy together with products of other settlement recipient with credit loss coverage. Enter the month of sale in the year and month fields below.",
  "finances.tab.reportAdyenMoovyReturnedCreditLoss.header": "Returned creditloss to Moovy or to the products of other settlement recipients with credit loss coverage. Set the date range of the settled timestamp or the post payments to include.",
  "finances.tab.reportAdyenSuccessfulPaymentsForMoovy.header": "Successful payments for settlement recipient Moovy. Post payments excluded. Parameters: year+month is the sale month and date range is for the settled timestamp.",
  "finances.tab.reportAdyenSuccessfulRefunds.header": "Successful refunds with Adyen. Post payments excluded. Parameters: year+month is the sale month and date range is for the refund confirmed timestamp.",
  "finances.tab.reportAdyenSuccessfulPayments.header": "Report on successful Adyen card payments. Search criteria: settlement recipient (mandatory), settled period,  sales month for annual and monthly fields (optional), filtering of products for credit loss coverage and post payment. There may be a large number of results, so the search is restricted as follows: settled time interval can be max 7 days, unless there is a stricter restriction on credit loss and post payment.",
  "finances.tab.reportAdyenSuccessfulPayments.requiredMissing": "Check required fields",
  "finances.tab.reportAdyenSuccessfulPayments.coverCreditLoss": "Cover credit loss",
  "finances.tab.reportAdyenSuccessfulPayments.postPayment": "Post payment",
  "finances.tab.reportAdyenSuccessfulPayments.fetchError": "Unexpected error happened",
  "finances.tab.reportCancelledWebPayments.header": "Download report of cancelled web payments. The report contains web payment issues cancelled between the given dates.",

  "invoices.toolbar.title": "Invoices",
  "invoices.toolbar.invoiceNumberOrPspRef.placeholder": "Invoice number or PSP ref",
  "invoices.toolbar.paymentMethod.placeholder": "Payment method",
  "invoices.toolbar.hideZeroPrice": "Hide zero priced",
  "invoices.toolbar.status.placeholder": "Invoice status",
  "invoices.toolbar.createdFromTime.placeholder": "Start date",
  "invoices.toolbar.createdUntilTime.placeholder": "End date",

  "invoiceModal.invoiceSummary.total.header": "Total",
  "invoiceModal.invoiceSummary.invoiceNumber.header": "Invoice number",
  "invoiceModal.invoiceSummary.paymentMethod.header": "Payment method",
  "invoiceModal.invoiceSummary.paymentCardCategory.header": "Payment card category",
  "invoiceModal.invoiceSummary.paymentCardCategory.adyenLatestPspRef": "Adyen PSP reference",
  "invoiceModal.invoiceSummary.status.header": "Status",
  "invoiceModal.invoiceSummary.vismaDueDate.header": "Due date",
  "invoiceModal.invoiceSummary.vismaStatus.header": "Visma status",
  "invoiceModal.invoiceSummary.compensatingInvoiceNumber.header": "Compensating invoice",
  "invoiceModal.invoiceSummary.compensatedInvoice.header": "Compensated invoice",
  "invoiceModal.invoiceSummary.invoicingAttemptCount.header": "Invoice attempt count",
  "invoiceModal.invoiceSummary.user.header": "User",

  "subscriptionsTable.title": "Orders",
  "subscriptionsTable.header.orderDate": "Order date",
  "subscriptionsTable.header.cancelDate": "Cancel date",
  "subscriptionsTable.header.endDate": "End date",
  "subscriptionsTable.button.activate": "Activate",
  "subscriptionsTable.button.cancel": "Cancel",
  "subscriptionsTable.button.orderEnded": "Order ended",

  "modalCancelWebpayments.header": "Cancel webpayments",
  "modalCancelWebpayments.cancel.status.success": "Canceling succeeded",
  "modalCancelWebpayments.cancel.status.failure": "Canceling failed",
  "modalCancelWebpayments.webpayment.cancelable.info": "Cancelable webpayments {count}",
  "modalCancelWebpayments.webpayment.table.creationTime": "Creation time",
  "modalCancelWebpayments.webpayment.table.price": "Price",
  "modalCancelWebpayments.webpayment.table.licensePlateNumber": "License plate number",
  "modalCancelWebpayments.webpayment.table.status": "Webpayment status",
  "modalCancelWebpayments.webpayment.progress.info": "Done {done} / {total}",
  "modalCancelWebpayments.webpayment.progress.info.failed": " Failed: {count}",
  "modalCancelWebpayments.webpayment.progress.done.success": "Webpayment canceling succeeded",
  "modalCancelWebpayments.webpayment.progress.done.failure": "All webpayments could not be canceled. Please try again or contact support.",
  "modalCancelWebpayments.webpayment.cancel": "Cancel webpayments",
  "modalCancelWebpayments.cancelModal.header": "Canceling webpayment",
  "modalCancelWebpayments.cancelModal.comment": "Reason of cancellation",
  "modalCancelWebpayments.webpayment.cancel.confirm": "Are you sure you want to cancel webpayments?",

  "tariffTestPricing.button.calculateTariff": "Calculate tariff",
  "tariffTestPricing.label.parkingRole": "Parking role",

  "modalAddTariff.toast.title.tariffAdded": "Tariff added",
  "modalAddTariff.toast.description.tariffAdded": "The tariff has been added for the service.",
  "modalAddTariff.form.header": "Add pricing",
  "modalAddTariff.form.dropdown.text": "{serviceName}, starting {tariffStartTime}",
  "modalAddTariff.form.label.pricing": "Prefill the form by an existing pricing",
  "modalAddTariff.form.option.pricing.placeholder": "Choose pricing",
  "modalAddTariff.form.label.startTime": "The tariff start time",
  "modalAddTariff.form.option.startTime.placeholder": "Start time",
  "modalAddTariff.form.label.periodicFee": "Periodic fee of the subscription €/month)",
  "modalAddTariff.form.label.description": "Description as a HTML format",
  "modalAddTariff.form.label.description.info": "The description is displayed to the customer with a possible payment terminal.",
  "modalAddTariff.form.placeholder.description": "Description (HTML)",
  "modalAddTariff.form.label.preview": "Description as a HTML format, preview",
  "modalAddTariff.form.label.tariffStructure": "Tariff structure",
  "modalAddTariff.form.placeholder.tariffStructure": "Tariff structure (JSON)",
  "modalAddTariff.form.action.error": "Fix the tariff and try again.",
  "modalAddTariff.form.label.testTariff": "Test the tariff structure by setting start and end time and pressing the calculate tariff button.",
  "modalAddTariff.form.label.calculatedTariffs": "Calculated prices of above set time ranges.",

  "modalAddLocation.toast.title.locationAdded": "Added location",
  "modalAddLocation.toast.description.locationAdded": "The location has been added to the service.",
  "modalAddLocation.form.header": "Add a new location for the service",
  "modalAddLocation.form.description": "Add a new location for the service {name}",
  "modalAddLocation.form.label.operators": "Choose operator",
  "modalAddLocation.form.option.operators.placeholder": "Choose operator",
  "modalAddLocation.form.label.locations": "Choose location",
  "modalAddLocation.form.option.locations.placeholder": "Choose location",
  "modalAddLocation.operation.addLocation.failed": "Failed to add the location to the service.",

  "parking.toast.operation.createDebtCollection.success.title": "Moving successful",
  "parking.toast.operation.createDebtCollection.success.description": "Web payment has been moved to debt collection.",
  "parking.toast.operation.createDebtCollection.failed.title": "Moving failed",
  "parking.toast.operation.createDebtCollection.failed.description": "Web payment has not been moved to debt collection.",
  "parking.toast.operation.revokeDebtCollection.success.title": "Revoking successful",
  "parking.toast.operation.revokeDebtCollection.success.description": "Revoking of the debt collection process is successful.",
  "parking.toast.operation.revokeDebtCollection.failed.title": "Revoking failed",
  "parking.toast.operation.revokeDebtCollection.failed.description": "Revoking of the debt collection has been failed.",
  "parking.toast.operation.cancelWebpayment.success.title": "Cancelling successful",
  "parking.toast.operation.cancelWebpayment.success.description": "Web payment has been cancelled.",
  "parking.toast.operation.cancelWebpayment.failed.title": "Cancelling failed",
  "parking.toast.operation.cancelWebpayment.failed.description": "Cancelling of the web payment has been failed.",

  "parkingContent.label.cameraRecognation": "Camera recognation",
  "parkingContent.placeHolderCard.cameraRecognationLoading": "Camera recognation data is being loading",
  "parkingContent.placeHolderCard.cameraRecognationLoadingFailed": "Failed to read camera recognation data",
  "parkingContent.placeHolderCard.cameraRecognationDataMissing": "There is no camera recognation data in the parking",
  "parkingContent.error.metadata": "Failed to read metadata",
  "parkingContent.popup.metadata": "Show metadata",
  "parkingContent.card.content.noImage": "Parking image is missing",
  "parkingContent.grid.label.parking": "Parking",
  "parkingContent.label.parkStarted": "Started",
  "parkingContent.label.parkEnded": "Ended",
  "parkingContent.label.parkOngoing": "Ongoing",
  "parkingContent.label.parkDuration": "Duration",
  "parkingContent.label.parkTotalDuration": "Total duration",
  "parkingContent.tab.sales": "Sale",
  "parkingContent.tab.vouchers": "Vouchers",
  "parkingContent.tab.parkingSequence": "Parking sequence",
  "parkingContent.tab.auditLogs": "Change log",

  "parkingSequence.label.anonymousParking": "Anonymous parking",
  "parkingSequence.label.moovyParking": "Moovy parking",
  "parkingSequence.label.parkmanParking": "ParkMan parking",
  "parkingSequence.label.easyparkParking": "EasyPark parking",
  "parkingSequence.table.header.location": "Location",
  "parkingSequence.table.header.started": "Started",
  "parkingSequence.table.header.ended": "Ended",
  "parkingSequence.table.header.duration": "Duration",
  "parkingSequence.table.header.type": "Type",
  "parkingSequence.table.header.parkingProduct": "Parking product",
  "parkingSequence.label.subscription": "Subscription {name}",
  "parkingSequence.label.payg": "Pay as you go",
  "parkingSequence.label.ongoing": "Ongoing",
  "parkingSequence.table.column.service.subscription": "Subscription {name}",
  "parkingSequence.table.column.service.payg": "Pay as you go",

  "parkingToolbar.loading.error": "Failed to read parking data",
  "parkingToolbar.title.parking": "Parking {licencePlateNumber}",
  "parkingToolbar.button.previous": "Previous",
  "parkingToolbar.button.next": "Next",
  "parkingToolbar.button.revoke": "Revoke debt collection",
  "parkingToolbar.button.takeDebtCollection": "Take debt collection",
  "parkingToolbar.button.cancelPayment": "Cancel web payment",
  "parkingToolbar.popup.backToWebPayment": "Back to web payments",

  "parkingToolbar.label.location": "Location",
  "parkingToolbar.label.paymentStatus": "Web payment status",
  "parkingToolbar.status.sentToPayment": "Sent to web payment",
  "parkingToolbar.status.handledPayment": "Handled in web payment",

  "moovyHtmlPreview.error.contentInvalid": "HTML content is invalid and it cannot be shown.",
  "moovyHtmlPreview.link.updateView": "Update view",

  "settlementRecipients.toolbar.label.settlementRecipientName": "Name of company",
  "settlementRecipients.toolbar.filter.settlementRecipientName.placeholder": "Search",

  "settlementRecipients.settlementRecipientTable.header.companyName": "Company name",
  "settlementRecipients.settlementRecipientTable.header.extensionName": "Name extension",
  "settlementRecipients.settlementRecipientTable.header.companyType": "Company type",
  "settlementRecipients.settlementRecipientTable.header.operator": "Operator",
  "settlementRecipients.settlementRecipientTable.header.contact": "Contact",
  "settlementRecipients.settlementRecipientTable.header.businessId": "VAT number",
  "settlementRecipients.settlementRecipientTable.header.city": "City",
  "settlementRecipients.settlementRecipientTable.header.vismaRoles": "Visma",
  "settlementRecipients.settlementRecipientTable.header.vismaStatus": "Visma status",
  "settlementRecipients.settlementRecipientTable.header.adyenStatus": "Adyen status",

  "settlementRecipients.settlementRecipientTable.vismaMandatorsActive": "Finished",
  "settlementRecipients.settlementRecipientTable.vismaMandatorsInActive": "In progress",

  "settlementRecipient.toolbar.label.contact": "Contact",
  "settlementRecipient.toolbar.label.businessId": "VAT number",
  "settlementRecipient.toolbar.label.companyType": "Company type",
  "settlementRecipient.toolbar.label.operator": "Operator",
  "settlementRecipient.toolbar.label.extensionName": "Name extension",

  "settlementRecipient.action.create.success.title": "Creation success",
  "settlementRecipient.action.create.success.description": "Settlement recipient has been created",
  "settlementRecipient.action.create.failed": "Creation failed",
  "settlementRecipient.action.update.success.title": "Update success",
  "settlementRecipient.action.update.success.description": "Settlement recipient has been updated",
  "settlementRecipient.action.update.failed": "Update failed",

  "ultimateBeneficialOwnerTab.button.addUltimateBeneficialOwner": "Add beneficial owner",

  "ultimateBeneficialOwnerTable.header.firstNames": "First names",
  "ultimateBeneficialOwnerTable.header.lastName": "Last name",
  "ultimateBeneficialOwnerTable.header.countryOfResidence": "Country of Residence",
  "ultimateBeneficialOwnerTable.header.ownershipPercentage": "Ownership %",
  "ultimateBeneficialOwnerTable.header.jobTitle": "Job title",
  "ultimateBeneficialOwnerTable.header.reason": "Type",

  "modalOwner.form.label.firstNames": "First names",
  "modalOwner.form.label.lastName": "Last name",
  "modalOwner.form.label.countryCodeOfResidence": "Country of Residence",
  "modalOwner.form.placeholder.countryOfResidence": "Choose country",
  "modalOwner.form.label.ownershipPercentage": "Ownership of the company as percentage",
  "modalOwner.form.label.jobTitle": "Job title",
  "modalOwner.form.dropdown.jobTitle.add": "Add a new job title:",
  "modalOwner.form.label.reason": "Settlement recipient type",
  "modalOwner.form.add.error": "Adding of the ultimate beneficial owner has been failed.",
  "modalOwner.toast.title.ultimateBeneficialOwnerAdded": "Ultimate beneficial owner",
  "modalOwner.toast.description.ultimateBeneficialOwnerAdded": "Ultimate beneficial owner has been added",
  "modalOwner.toast.title.signatoryAdded": "Adding success",
  "modalOwner.toast.description.signatoryAdded": "Added signatory",
  "modalOwner.button.addSignatory": "Add signatory",

  "modalRemoveUser.toast.title.userRemoved": "User removed",
  "modalRemoveUser.toast.description.userRemove": "The user has been removed successfully.",
  "modalRemoveUser.title.removeUser": "Remove the user {userName}",
  "modalRemoveUser.title.removeEnterpriseUser": "Remove enterprise account of {userName}",
  "modalRemoveUser.description.removeUser": "Are you sure you want to remove the user {userName}? Please note that deletion is permanent and cannot be undone.",
  "modalRemoveUser.description.removeEnterpriseUser": "Are you sure you want to remove the business account of {userName}? Please note that the deletion is final and cannot be undone.",
  "modalRemoveUser.action.default.error": "Failed to remove the user",

  "modalRemovingRequest.toast.title.userRemoved": "Deletion request created.",
  "modalRemovingRequest.toast.description.userRemove": "User has been deleted succesfully.",
  "modalRemovingRequest.title.removeUser": "Create a deletion request to user {userName}",
  "modalRemovingRequest.description.removeUser": "If you add a deletion request, the user will be deleted from Moovy according to the normal removal cycle.",
  "modalRemovingRequest.action.default.error": " Failed to create user delete request",
  "modalRemovingRequest.button.removingRequest": "Create deletion request",
  "modalRemovingRequest.label.email": "Application user's email address",

  "modalEditUser.title.editUser": "Edit customer data",
  "modalEditUser.form.firstName": "First name",
  "modalEditUser.form.lastName": "Last name",
  "modalEditUser.form.email": "Email",
  "modalEditUser.form.phoneNumber": "Phone number",
  "modalEditUser.action.edit.default.error": "Failed to save customer data",
  "modalEditUser.button.tooltip.deleteUser": "Delete the customer",
  "modalEditUser.button.tooltip.createDeletionRequest": "Request the deletion of the application user from the system",
  "modalEditUser.button.createDeletionRequest": "Create deletion request",

  "modalUserPermission.title.editUser": "Blocking status of the user's block list",
  "modalUserPermission.action.setPrevented.default.error": "Failed to save block list.",
  "modalUserPermission.action.removePrevented.default.error": "Failed to remove block list.",
  "modalUserPermission.label.pausedUser": "User to the blocking list",
  "modalUserPermission.toast.title.setBlockingPrevented": "Blocking list prevented",
  "modalUserPermission.toast.description.setBlockingPrevented": "Blocklist prevent for user is enabled.",
  "modalUserPermission.toast.title.removeBlockingPrevented": "Blocking list allowed",
  "modalUserPermission.toast.description.removeBlockingPrevented": "The user can be blocked in the future.",

  "modalUserPermission.automaticHandlingEnabled": "Moving to the blocking list is prevented",
  "modalUserPermission.automaticHandlingDisabled": "Allowed to move to the blocking list",
  "modalUserPermission.automaticHandlingReason": "Fill the reason for blocking list prevent",

  "moovyUser.userAssignments.checkbox.showNotActiveAssignments": "Show also not actives",
  "moovyUser.userAssignments.table.header.service": "Service",
  "moovyUser.userAssignments.table.header.owner": "Owner",
  "moovyUser.userAssignments.table.header.active": "Active",
  "moovyUser.userAssignments.acceptAssignment.error": "Accepting assignment has been failed.",
  "moovyUser.userAssignments.revokeAssignment.error": "Revoking assignment has been failed.",

  "modalManage.settlementRecipient.form.header.addNewRecipient": "Add new settlement recipient",
  "modalManage.settlementRecipient.form.header.editRecipient": "Edit settlement recipient",
  "modalManage.settlementRecipient.form.operator": "Operator",
  "modalManage.settlementRecipient.form.operator.placeholder": "Choose operator",
  "modalManage.settlementRecipient.form.companyName": "Company name",
  "modalManage.settlementRecipient.form.nameExtension": "Name extension",
  "modalManage.settlementRecipient.form.info.nameExtension": "Use the name extension wisely, because this is visible in the reports sent to the customer as settlement recipient is active. E.g. The same name of settlement recipients can be separated by this.",
  "modalManage.settlementRecipient.form.businessId": "VAT number",
  "modalManage.settlementRecipient.form.companyType": "Company type",
  "modalManage.settlementRecipient.form.companyType.placeholder": "Choose company type",
  "modalManage.settlementRecipient.form.country": "Country",
  "modalManage.settlementRecipient.form.streetAddress": "Street address",
  "modalManage.settlementRecipient.form.postalCode": "Postal code",
  "modalManage.settlementRecipient.form.city": "City",
  "modalManage.settlementRecipient.form.adyenReportProfileType": "Adyen report profile",
  "modalManage.settlementRecipient.form.contact.details": "Contact details",
  "modalManage.settlementRecipient.form.contact.name": "Name",
  "modalManage.settlementRecipient.form.contact.phone": "Phone",
  "modalManage.settlementRecipient.form.contact.email": "Email",
  "modalManage.settlementRecipient.button.addRecipient": "Add settlement recipient",
  "modalManage.settlementRecipient.button.updateRecipient": "Save changes",

  "moovyTable.action.failed": "A problem in an operation. Refresh the page and try again.",
  "moovyTable.action.emptySet": "Content empty",
  "moovyTable.action.fetchPending": "Enter your search criteria and press Search",

  "tabAdyen.companyType.error": "Adyen definition cannot be executed because there is no companyt type set by settlement recipient. Define company type first.",

  "tabAdyen.progress.label.proceeding": "Proceeding",
  "tabAdyen.progress.label.beneficialOwner": "Add beneficial owner",
  "tabAdyen.progress.label.signatory": "Add signatory",
  "tabAdyen.progress.label.accountHolder": "Luo tilinhaltija",
  "tabAdyen.progress.label.onboardingLink": "Luo kutsulinkki",

  "stepSignatoryOwner.button.signatory": "Add signatory",

  "onboardingSteps.step.beneficialOwners": "Beneficial owners",
  "onboardingSteps.step.signatories": "Signatories",
  "onboardingSteps.step.accountHolder": "Account holder",
  "onboardingSteps.step.onboardingLink": "Onboarding link",

  "stepAccountHolder.label.mainStatus": "Account holder status:",
  "stepAccountHolder.label.accountHolderCode": "Account holder code:",
  "stepAccountHolder.description.accountHolder": "Adyen account holder is created by pressing the button below. Info of settlement recipient and owner are used as a data when account holder is created. The process automatically generated the account too. Account holder status and its change can be followed here, on the settlement recipient list or on Adyen Customer Area.",
  "stepAccountHolder.button.createAndContinue": "Create account holder and continue",
  "stepAccountHolder.header.accountHolder": "Account holder",
  "stepAccountHolder.toast.action.accountHolderCreated.title": "Creation success",
  "stepAccountHolder.toast.action.accountHolderCreated.description": "Account holder has been created",
  "stepAccountHolder.accountHolderCreated.failed": "Failed to create account holder for unknown reason. Check that the data of the first phase is filled correctly.",

  "stepOnboardingLink.fetchAccountHolder.failed": "Failed to fetch account holder data. Refresh the page.",
  "stepOnboardingLink.toast.copiedToClipboard.text": "Link",
  "stepOnboardingLink.toast.action.onboardingTokenCreate.title": "Created success",
  "stepOnboardingLink.toast.action.onboardingTokenCreate.description": "Onboarding link has been created",
  "stepOnboardingLink.toast.action.onboardingTokenInvalidate.title": "The link is inactive",
  "stepOnboardingLink.toast.action.onboardingTokenInvalidate.description": "The onboardin link has been inactivated. Create a new one again.",
  "stepOnboardingLink.toast.action.onboardingToken.valid": "Ongoard link is valid",
  "stepOnboardingLink.description.onboardingToken": "Create an onboarding link for the account holder where Adyen KYC prosess can be continued on the Adyen own page. The link is valid only a certain time and the validity is visible after the link creation. The account holder can fill company details of KYC prosess via the link. The session is open 30 min. If the session will expire or the account holder won't fill all required details, create a new link here. KYC prosess is continued from the latest unsaved session.",
  "stepAccountHolder.header.onboardingToken": "Onboarding link",
  "stepOnboardingLink.button.copyLink": "Copy the link",
  "stepOnboardingLink.button.createAndContinue": "Create onboarding link",
  "stepOnboardingLink.link.invalidateToken": "Inactivate the onboarding link",
  "stepOnboardingLink.onboardingTokenCreated.failed": "Failed to create the onboarding link because of unknown reason. Check that data of the previous steps are filled correctly.",
  "stepOnboardingLink.fetchOnboardingToken.failed": "Failed to fetch onboarding data. Make sure, the account holder exists in the previous step.",

  "accountHolder.header.accountHolder": "Account holder",
  "accountHolder.label.accountHolderCode": "Account holder:",
  "accountHolder.label.accountHolderId": "Account holder ID:",
  "accountHolder.label.adyenAccountBpStatus": "Status:",
  "accountHolder.button.createAndContinue": "Create account holder and continue",
  "accountHolder.description.accountHolder": "Adyen account holder is created by pressing the button below. Info of settlement recipient and owner are used as a data when account holder is created. The process automatically generated the account too. Account holder status and its change can be followed here, on the settlement recipient list or on Adyen Customer Area.",
  "accountHolder.accountHolderCreated.failed": "Failed to create the onboarding link because of unknown reason.",
  "accountHolder.toast.action.accountHolderCreated.title": "Creation success",
  "accountHolder.toast.action.accountHolderCreated.description": "Adyenin account holder has been created.",
  "onboardingLink.button.create": "Create onboarding link",
  "onboardingLink.description.create": "The account holder can fill company details of KYC prosess via the link. The session is open 30 min. If the session will expire or the account holder won't fill all required details, create a new link here. KYC prosess is continued from the latest unsaved session.",

  "tabVisma.button.addMandator": "Add mandator",
  "tabVisma.action.createMandator.success.title": "Create successful",
  "tabVisma.action.createMandator.success.description": "Mandator has been created successfully.",
  "tabVisma.action.updateMandator.success.title": "Update successful",
  "tabVisma.action.updateMandator.success.description": "Mandator data has been updated.",
  "tableMandator.header.bankAccountBic": "Bank BIC",
  "tableMandator.header.bankAccountIban": "Bank IBAN",
  "tableMandator.header.customerSupportPhone": "Customer support phone",
  "tableMandator.header.customerSupportEmail": "Customer support email",
  "tableMandator.header.mandatorRole": "Visma",
  "tableMandator.header.vismaStatus": "Visma KYC status",

  "modalManageMandator.header.create": "Add new mandator",
  "modalManageMandator.header.update": "Edit mandator",
  "modalManageMandator.action.create.failed": "Failed to create mandator.",
  "modalManageMandator.action.update.failed": "Failed to update mandator.",
  "modalManageMandator.form.vismaRole": "Visma-role",
  "modalManageMandator.form.vismaRole.placeholder": "Choose role",
  "modalManageMandator.message.addNotAllowed": "There are already {mandatorsCount} invoicing roles ({mandatorRoles}) in the settlement recipient. Remove one or more roles until adding a new one.",
  "modalManageMandator.form.bankAccountIban": "Bank IBAN",
  "modalManageMandator.form.bankAccountBic": "Bank BIC",
  "modalManageMandator.form.customerSupportPhone": "Customer support phone",
  "modalManageMandator.form.customerSupportEmail": "Customer support email",
  "modalManageMandator.button.addMandator": "Add mandator",
  "modalManageMandator.button.updateMandator": "Update mandator",

  "chargingSessionToolbar.header": "Electric vehicle charging",
  "chargingSessionToolbar.placeholder.pluginId": "Charging Charging status",
  "chargingSessionToolbar.placeholder.chargingStatus": "Charging status",
  "chargingSessionToolbar.placeholder.chargerType": "Charging type",
  "chargingSessionToolbar.label.pluginId": "Charging point",
  "chargingSessionToolbar.label.customer": "Customer",
  "chargingSessionToolbar.label.chargingStatus": "Charging status",
  "chargingSessionToolbar.label.chargerType": "Charging type",
  "chargingSessionToolbar.label.location": "Location",
  "chargingSessionToolbar.label.chargings": "Chargings",
  "chargingSessionToolbar.label.showAlsoCompletedChargings": "Show completed chargings too",
  "chargingSessionToolbar.label.moreFilters": "More filters",
  "chargings.chargingsTable.header.plugin": "Charging point",
  "chargings.chargingsTable.header.status": "Charging status",
  "chargings.chargingsTable.header.location": "Location",
  "chargings.chargingsTable.header.city": "City",
  "chargings.chargingsTable.header.customer": "Customer",
  "chargings.chargingsTable.header.type": "Type",
  "chargings.chargingsTable.header.startTime": "Start time",
  "chargings.chargingsTable.header.endTime": "End time",
  "chargings.chargingsTable.header.duration": "Duration",
  "chargings.chargingsTable.header.energy": "Energy",
  "chargings.chargingsTable.header.price": "Price",
  "chargings.chargingsTable.link.stop": "Stop",
  "chargings.chargingSessionsContent.confirm.stopSession": "Are you sure you want to stop the charging session of the plugin {pluginId} for the customer {customer}?",
  "chargings.chargingSessionsContent.action.stopSession.success.title": "Stop success",
  "chargings.chargingSessionsContent.action.stopSession.success.description": "Charging session has been successfully stopped.",
  "chargings.chargingSessionsContent.action.stopSession.error.title": "Stop failed",
  "chargings.chargingSessionsContent.action.stopSession.error.description": "Failed to stop the charging session.",

  "management.create.service.action.description.failed": "Please check the filled data",
  "management.create.location.action.description.failed": "Please check the filled data",
  "management.create.service.action.title.failed": "Failed to create the product",
  "management.create.location.action.title.failed": "Failed to create the location",

  "moneyTransfersContent.form.label.moneySource": "Payer",
  "moneyTransfersContent.form.input.platform": "Moovy Liable Account",
  "moneyTransfersContent.form.label.switch": "Switch",
  "moneyTransfersContent.form.label.moneyTarget": "Settlement recipient",
  "moneyTransfersContent.form.settlementRecipient.placeholder": "Fill name of settlement recipient",
  "moneyTransfersContent.form.payer.placeholder": "Fill name of payer",
  "moneyTransfersContent.form.settlementRecipient.fetching": "Searching...",
  "moneyTransfersContent.form.settlementRecipient.noSearchResults": "No results",
  "moneyTransfersContent.form.label.amount": "Amount",
  "moneyTransfersContent.form.label.transferCode": "Transfer code",
  "moneyTransfersContent.form.label.transferCodeDescription": "Description of the chosen transfer code",
  "moneyTransfersContent.form.label.comment": "Comment",
  "moneyTransfersContent.form.button.transfer": "Transfer",
  "moneyTransfersContent.action.transfer.failed": "Failed to transfer",
  "moneyTransfersContent.action.transferMoneyToAccount.success.title": "Transfer done",
  "moneyTransfersContent.action.transferMoneyToAccount.success.description": "The transfer between accounts done.",

  "voucherTemplateManageModal.header.createTemplate": "Create a new voucher template",
  "voucherTemplateManageModal.header.editTemplate": "Edit the voucher template",
  "voucherTemplateManageModal.info.editWarning": "Modifying the base may affect parking benefits already granted.",
  "voucherTemplateManageModal.form.label.customDescription": "Voucher description",
  "voucherTemplateManageModal.form.label.categoryType": "Voucher category",
  "voucherTemplateManageModal.form.placeholder.categoryType": "Choose the category",
  "voucherTemplateManageModal.form.label.benefitType": "Voucher benefit type",
  "voucherTemplateManageModal.form.placeholder.chooseType": "Choose voucher benefit type",
  "voucherTemplateManageModal.form.placeholder.chooseCategoryFirst": "Choose category first",
  "voucherTemplateManageModal.form.label.allowedGrantType": "Voucher grant type",
  "voucherTemplateManageModal.form.placeholder.allowedGrantType": "Voucher grant type",
  "voucherTemplateManageModal.form.label.operator": "Operator",
  "voucherTemplateManageModal.form.placeholder.chooseOperator": "Choose operator",
  "voucherTemplateManageModal.form.label.locationsDescription": "Locations description",
  "voucherTemplateManageModal.form.label.locations": "Locations",
  "voucherTemplateManageModal.form.placeholder.chooseLocations": "Choose locations",
  "voucherTemplateManageModal.form.placeholder.chooseOperatorFirst": "Choose operator first",
  "voucherTemplateManageModal.form.label.grantedValue": "Granted value",
  "voucherTemplateManageModal.form.label.grantedDurationInMinutes": "Granted duration of the voucher",
  "voucherTemplateManageModal.form.label.durationInMinutes": "Duration (minute)",
  "voucherTemplateManageModal.form.label.grantedDiscountPercent": "Discount percent",
  "voucherTemplateManageModal.form.label.validityFromGranting": "Parking permit valid from granting",
  "voucherTemplateManageModal.form.label.valid": "Valid (minute)",
  "voucherTemplateManageModal.form.label.salePrice": "Cost to the beneficiary (euros)",

  "modalAdyenInvoice.tab.waiving.title": "Waived payment",
  "modalAdyenInvoice.tab.cardPayments.title": "Card payments",
  "modalAdyenInvoice.tab.cardRefunds.title": "Card refunds",
  "modalAdyenInvoice.tab.cardRefunds.title.popup.abnormality": "Abnormality in card refunds",
  "modalAdyenInvoice.tab.cardRefunds.title.popup.receivedRefund": "Card refund ongoing",
  "modalAdyenInvoice.tab.cardRefunds.title.popup.errorRefund": "Card refund failure",

  "modalRefundConfirmation.adyen.refundTotal": "Refund totally {totalPrice}e",
  "modalRefundConfirmation.adyen.title": "Refund of card charge",
  "modalRefundConfirmation.adyen.button.refund": "Refund",
  "modalRefundConfirmation.adyen.operation.refund.failed": "Refund failed.",

  "modalRetryCardPaymentConfirmation.tryToChargeTotal": "Retry to charge the sum {totalPrice}e",
  "modalRetryCardPaymentConfirmation.title": "Retry card payment",
  "modalRetryCardPaymentConfirmation.button.attemptCharge": "Retry payment",
  "modalRetryCardPaymentConfirmation.operation.attemptCharge.failed": "Failed to charge the card.",

  "modalSendEmailReceipt.title": "Send the receipt to your email",
  "modalSendEmailReceipt.label.email": "Email",
  "modalSendEmailReceipt.button.send": "Send",
  "modalSendEmailReceipt.operation.emailSending.failed": "Failed to send the receipt to your email",

  "tabAdyenInvoice.table.header.product": "Product",
  "tabAdyenInvoice.table.header.charged": "Charged",
  "tabAdyenInvoice.table.header.refund": "Refund",
  "tabAdyenInvoice.table.header.refundable": "Refundable",
  "tabAdyenInvoice.table.header.alv": "VAT %",
  "tabAdyenInvoice.table.header.description": "Description",
  "tabAdyenInvoice.table.header.invoiceRow": "Invoice row",
  "tabAdyenInvoice.toast.title.invoiceSentByEmail": "Receipt sent",
  "tabAdyenInvoice.toast.description.invoiceSentByEmail": "The receipt has been successfully sent to your email",
  "tabAdyenInvoice.toast.title.refundPayment": "Card charge refunded",
  "tabAdyenInvoice.toast.description.refundPayment": "The card charge has been successfully refunded",
  "tabAdyenInvoice.toast.title.retryCardPayment": "Retry done",
  "tabAdyenInvoice.toast.description.retryCardPayment": "The card charging has been successfully",
  "tabAdyenInvoice.toast.title.userWasRemovedFromBlocklist": "User has been removed from the block list.",
  "tabAdyenInvoice.toast.description.userWasRemovedFromBlocklist": "All permanently failed chargings have been processed.",
  "tabAdyenInvoice.toast.title.waivedInvoice": "Changed the invoice status",
  "tabAdyenInvoice.toast.description.waivedInvoice": "The invoice status set as waived",

  "tabAdyenInvoice.button.sendReceipt": "Send receipt",
  "tabAdyenInvoice.button.refundPayment": "Refund the card payment",
  "tabAdyenInvoice.button.retryCardPayment": "Manual card charge",
  "tabAdyenInvoice.waived.popup.label": "The invoice can only be set as waived when there are 3 charging attempts.",

  "tabAdyenInvoice.label.notChosenInvoiceRows": "Not chosen invoice rows",
  "tabAdyenInvoice.label.chosenInvoiceRows": "Chosen {rowCount} invoice rows, total {totalPrice}",
  "tabAdyenInvoice.label.error.chosenInvoiceRows": "Chosen {rowCount} invoice rows, invalid refund sum!",
  "tabAdyenInvoice.label.maxRefund": "The invoice can be refunded at a maximum of {price}.",
  "tabAdyenInvoice.label.refundForInvoice": "Refund made from card invoice number {invoiceNumber}.",

  "serviceInfo.popup.content": "Refresh by clicking.",
  "serviceInfo.popup.notRefreshedContent": "Refresh data",
  "serviceInfo.popup.notFetched": "Space info not fetched",
  "serviceInfo.popup.notDefined": "Not defined",
  "serviceInfo.popup.limitReached": "The site is getting full. The total occupancy rate is now {percentage} %.",
  "serviceInfo.label.specialServiceFeeConfig": "Service fee: ",
  "serviceInfo.label.shortParkingPlaces": "Short-term parking:",
  "serviceInfo.label.prebookingParkingPlaces": "Subscriptions/prebookings:",
  "serviceInfo.label.locationPlaces": "Location places:",
  "serviceInfo.button.editLocationCapacity": "Edit total occupancy rate of the location.",
  "serviceInfo.link.refreshPlaces": "Refresh space info",

  "modalWaivedInvoice.title": "Set the invoice {invoiceNumber} as waived",
  "modalWaivedInvoice.action.error": "Failed to set the invoice as waived.",
  "modalWaivedInvoice.button.setAsWaived": "Set as waived",
  "modalWaivedInvoice.action.info": "You can set the invoice status as waived. Please note that manual charging cannot be attempted after this, even if, for example, the customer's payment card is changed.",

  "modalRefundConfirmation.title": "Are invoice rows refunded?",
  "modalRefundConfirmation.body": "Are you sure you want to refund {selectedCount} invoice rows, total {revertSum}?",
  "modalRefundConfirmation.form.label.revertReceiverPhoneNumber": "The phone number of the refund recipient",
  "modalRefundConfirmation.form.label.revertReceiver": "The name of the refund recipient",
  "modalRefundConfirmation.form.label.revertReason": "Reason for refund",
  "modalRefundConfirmation.checkbox.copyToClipboard": "Copy the generated refund invoice number automatically to the clipboard.",

  "copyToClipboard.toast.copiedToClipboard.description": "{clipboardData} copied to clipboard",
  "copyToClipboard.toast.copiedToClipboard.title": "Copied to clipboard",

  "blocklistCandidates.content.action.addUsersToBlocklist.success.title": "Blocklist",
  "blocklistCandidates.content.action.addUsersToBlocklist.success.description": "Set users to the blocklist",
  "blocklistCandidates.content.action.error.default": "Failed to set users to the blocklist.",
  "blocklistCandidates.content.label.hideBlockPreventedUsers": "Hide blocked users from the list.",
  "blocklistCandidates.content.button.setChosenToBlocklist": "Set the selected ones to the block list",
  "blocklistCandidates.content.label.chosenUsers": "Selected {count} users",
  "blocklistCandidates.content.confirmation.title": "Set selected users to the block list",
  "blocklistCandidates.content.confirmation.body": "You are setting {count} users to the block list. Are you sure?",
  "blocklistCandidates.content.action.success.title": "Block list",
  "blocklistCandidates.content.action.success.description": "Users set to the block list",

  "blocklistCandidates.table.header.name": "Name",
  "blocklistCandidates.table.header.phone": "Phone",
  "blocklistCandidates.table.header.totalFailedPrice": "Total prices",
  "blocklistCandidates.table.header.totalFailedInvoices": "Total invoices count",
  "blocklistCandidates.table.header.lastFailedInvoiceNumber": "Last invoice number",
  "blocklistCandidates.table.header.adyenFinalFailureTime": "Adyen 3rd failed",
  "blocklistCandidates.table.header.userBlockingPreventedStart": "Block list prevent set",
  "blocklistCandidates.table.header.userBlockingPreventedComment": "Block list prevent comment",

  "tabAdyenRefundInvoice.table.header.invoiceRow": "Invoice row",
  "tabAdyenRefundInvoice.table.header.created": "Created",
  "tabAdyenRefundInvoice.table.header.status": "Status",
  "tabAdyenRefundInvoice.table.header.service": "Service",
  "tabAdyenRefundInvoice.table.header.refundNumber": "Refund invoice number",
  "tabAdyenRefundInvoice.table.header.comment": "Refund comment",
  "tabAdyenRefundInvoice.table.header.price": "Price",
  "tabAdyenRefundInvoice.table.footer.total": "Total",

  "pauseAndBlocklistTable.header.blockBegin": "Started",
  "pauseAndBlocklistTable.header.blockEnd": "Ended",
  "pauseAndBlocklistTable.header.blockCreatedByUserEmail": "Creator",
  "pauseAndBlocklistTable.header.blockModifiedByUserEmail": "Finisher",
  "pauseAndBlocklistTable.header.blockType": "Block type",
  "pauseAndBlocklistTable.header.comment": "Comment",

  "creditCoverCreditLossTable.table.title": "Tapahtumat {dateRange}",
  "creditCoverCreditLossTable.table.header.settlementRecipientName": "Settlement recipient name",
  "creditCoverCreditLossTable.table.header.accountHolderCode": "Account holder code",
  "creditCoverCreditLossTable.table.header.creditLoss": "Credit loss",
  "creditCoverCreditLossTable.table.header.isReadyToBeCovered": "Ready to be covered",

  "modalCoverCreditLoss.title": "Covering credit losses",
  "modalCoverCreditLoss.button.cover": "Cover credit loss",
  "modalCoverCreditLoss.content.label.settlementRecipient": "Settlement recipient",
  "modalCoverCreditLoss.content.label.accountHolderCode": "Account holder code",
  "modalCoverCreditLoss.content.label.creditLoss": "Credit loss (failed debits)",
  "modalCoverCreditLoss.content.label.isReadyToBeCovered": "Ready for covering",
  "modalCoverCreditLoss.content.label.description": "Comment",
  "modalCoverCreditLoss.content.label.sale": "Sale",
  "modalCoverCreditLoss.content.label.receivedPayments": "Received payments",
  "modalCoverCreditLoss.content.label.countedCreditLoss": "Calculated credit loss",
  "modalCoverCreditLoss.content.info.noMatchingCreditLoss": "The calculated credit loss does not match the credit loss of failed debits. The difference is {difference}. Credit loss cannot be covered.",

  "modalCoverCreditLoss.confirmation.body": "Are you sure you want to cover credit losses {amountOfcreditLoss} of settlement recipient {name} for {dateRange}?",
  "modalCoverCreditLoss.coverCreditLoss.action.error": "Failed to cover credit losses.",
  "modalCoverCreditLoss.operation.description": "Cover credit loss({dateRange})",
  "modalCoverCreditLoss.action.coverCreditLoss.success.title": "Covering credit losses",
  "modalCoverCreditLoss.action.coverCreditLoss.success.description": "Coverage of the settlement recipient credit losses completed.",

  "createInvoice.action.success": "New invoice created",
  "createInvoice.action.error": "Failed to create a new invoice",
  "createInvoiceModal.header": "New Visma invoice",
  "createInvoiceModal.button.sendInvoice": "Send invoice",
  "createInvoiceModal.form.label.settlementRecipient": "Settlement recipient",
  "createInvoiceModal.form.placholder.settlementRecipient": "Choose recipient",
  "createInvoiceModal.form.label.product": "Product",
  "createInvoiceModal.form.placeholder": "Choose product",
  "createInvoiceModal.form.label.sum": "Sum",
  "createInvoiceModal.form.label.detail": "Detail",
  "createInvoiceModal.form.label.incurredAt": "Incurred at",
  "createInvoiceModal.form.button.addRow": "Add row",
  "createInvoiceModal.invoiceRowTable.header.product": "Product",
  "createInvoiceModal.invoiceRowTable.header.operator": "Operator",
  "createInvoiceModal.invoiceRowTable.header.recipient": "Recipient",
  "createInvoiceModal.invoiceRowTable.header.total": "Sum",
  "createInvoiceModal.invoiceRowTable.header.detail": "Detail",
  "createInvoiceModal.invoiceRowTable.header.incurredAt": "Incurred at",

  "invoiceAdministration.info.header": "Success",
  "invoiceAdministration.error.header": "Error",
  "invoiceAdministration.invoiceRows.error.content": "Error loading invoice rows",

  "vismaInvoiceAdministration.creditNote.button": "Create credit note",
  "vismaInvoiceAdministration.creditNote.modal.content": "Are you sure you want to create a credit note for the invoice?",
  "vismaInvoiceAdministration.creditNote.modal.accept": "Accept",
  "vismaInvoiceAdministration.creditNote.success": "Credit note has been created",
  "vismaInvoiceAdministration.creditNote.error": "Error creating credit note",
  "vismaInvoiceAdministration.revokeDebtCollection.button": "Revoke debt collection",
  "vismaInvoiceAdministration.revokeDebtCollection.confirm": "Are you sure you want to revoke debt collection?",
  "vismaInvoiceAdministration.createCustomInvoice.confirm": "Are you sure you want to create a new invoice?",
  "vismaInvoiceAdministration.createCustomInvoice.button": "Create new invoice",
  "vismaInvoiceAdministration.createCustomInvoice.submit": "Create new invoice from selected",
  "vismaInvoiceAdministration.createCustomInvoice.error": "Error creating new invoice",
  "vismaInvoiceAdministration.createCustomInvoice.noSelectedRows": "No selected rows",
  "vismaInvoiceAdministration.createCustomInvoice.selectedRows": "{count} rows selected, total {totalPrice}.",
  "vismaInvoiceAdministration.maxRevert": "The invoice can be chosen at most {sum}.",
  "vismaInvoiceAdministration.revokeDebtCollection.info": "Revoking debt collection cancels all invoice rows. Choose invoice rows for the new created invoice",
  "vismaInvoiceAdministration.revokeDebtCollection.comment": "Reason of cancellation",
  "vismaInvoiceAdministration.revokeDebtCollection.success": "Debt collection revoked",
  "vismaInvoiceAdministration.revokeDebtCollection.error": "Revoking debt collection failed",
  "vismaInvoiceAdministration.createCustomInvoice.toast.success.title": "Invoice created",
  "vismaInvoiceAdministration.createCustomInvoice.toast.success.body": "New invoice saved",
  "vismaInvoiceAdministration.clearAll": "Clear",

  "invoiceRowTable.product": "Product",
  "invoiceRowTable.totalPrice": "Total price",
  "invoiceRowTable.maxRevert": "Max reimburse amount",
  "invoiceRowTable.VAT": "VAT%",
  "invoiceRowTable.description": "Description",

  "moovyTableFilter.filter.shortcut.today": "Today",
  "moovyTableFilter.filter.shortcut.week": "Week",
  "moovyTableFilter.filter.shortcut.chooseTimeRange": "Choose time range",
  "moovyTableFilter.filter.placeholder.chooseTimeRange": "From - to",

  "moovyDateRangePicker.button.today": "Today",
  "moovyDateRangePicker.error.empty": "Fill date range.",
  "moovyDateRangePicker.error.invalidValue": "Invalid date range.",
  "moovyDateRangePicker.error.overMaxDateRange": "Chosen date range is over {days} day(s).",
  "moovyDateRangePicker.error.MinIsOverMaxDate": "Start date is bigger than end date.",

  "voucherTemplates.statusOption.active": "Active",
  "voucherTemplates.statusOption.inactive": "Inactive",
  "voucherTemplates.toolbar.title": "Vouchers",
  "voucherTemplates.toolbar.search": "Search",
  "voucherTemplates.toolbar.search.placeholder": "Search vouchers",
  "voucherTemplates.toolbar.search.invalid": "Input at least three characters.",
  "voucherTemplates.toolbar.operator.placeholder": "Choose operator",
  "voucherTemplates.toolbar.location.placeholder": "Choose location",
  "voucherTemplates.toolbar.location.disabled.placeholder": "Choose operator first",
  "voucherTemplates.createVoucherTemplate": "Create a new voucher template",
  "voucherTemplates.createVoucherTemplate.success": "Voucher template created",
  "voucherTemplates.updateVoucherTemplate.success": "Voucher template updated",
  "voucherTemplates.createVoucherTemplate.error": "Voucher template creation failed.",
  "voucherTemplates.updateVoucherTemplate.error": "Voucher template modification failed.",
  "voucherTemplates.button.popup.grantVoucher": "Grant voucher",
  "voucherTemplates.button.popup.edit": "Edit voucher",
  "voucherTemplates.grantVoucher.error": "Granting voucher failed.",

  "modalInvoice.tab.waived.title": "Waived invoice",
  "modalInvoice.tab.invoiceRows.title": "Invoice rows",
  "modalInvoice.tab.refunds.title": "Card refunds",
  "modalInvoice.tab.refundsComments.title": "Comments of refunds",
  "modalInvoice.toast.title.paymentPostChargeSuccess": "Payment post charge",
  "modalInvoice.toast.description.paymentPostChargeSuccess": "Payment post charge success",

  "invoiceRefundComments.table.header.comments": "Comments of refunds",
  "invoiceRefundComments.table.body.noContent": "No content",

  "testServiceValidity.button.addRow": "Add row",
  "testServiceValidity.button.test": "Test service validity",
  "testServiceValidity.query.error": "Error testing service validity",
  "testServiceValidity.serviceValid": "Service is valid",
  "testServiceValidity.serviceInvalid": "Service is not valid",

  "moovySearchInput.search.placeholder": "Search",

  "GeoJSONEditor.value.label": "Area in GeoJSON format",
  "GeoJSONEditor.value.placeholder": "Area (GeoJSON)",
  "GeoJSONEditor.preview.label": "Area in GeoJSON format, preview",

  "modalEditCapacities.title": "Edit service {name} capacity",
  "modalEditCapacities.label.totalCapacity": "Total capacity",
  "modalEditCapacities.label.parkingCapacity": "Parking capacity for simultaneous parking",
  "modalEditCapacities.label.parkingCapacity.info.header": "The value entered here can be used to limit the number of simultaneous parkings at barrier locations.",
  "modalEditCapacities.label.parkingCapacity.info.bullet1": "The restriction does not work and is not intended to be used on barrierless locations.",
  "modalEditCapacities.label.parkingCapacity.info.bullet2": "The restriction can be bypassed if the customer starts parking from the mobile app or an operator starts parking from the user interface.",
  "modalEditCapacities.label.exceedingParkingCapacityAllowed": "Allow parking capacity to be exceeded",
  "modalEditCapacities.label.exceedingParkingCapacityAllowedForSubscription": "Simultaneous subscription parking may exceed the specified parking capacity.",
  "modalEditCapacities.label.exceedingParkingCapacityAllowedForPAYG": "Simultaneous pay as you go parking may exceed the specified parking capacity.",
  "modalEditCapacities.prebookingVoucherPrediction.header": "Prebooking forecast for the next {days, plural, one {# day} other {# days}}",
  "modalEditCapacities.prebookingVoucherPrediction.prediction": "{prediction} pcs",
  "modalEditCapacities.prebookingVoucherPrediction.forecast": "Forecasted prebooking for today is <b>{prebookedToday} pcs</b>, recommendation for parking capacity is <span>{capacityRecommendation} pcs</span>",
  "modalEditCapacities.action.error.update": "Failed to update data.",
  "modalEditCapacities.toast.title": "The service capacity has been updated",
  "modalEditCapacities.toast.body": "The service {name} capacity has been updated.",

  "modalEditOverBooking.title": "Edit service {name} overbooking coefficient",
  "modalEditOverBooking.label.userAssignmentOverbookingCoefficient": "Overbooking coefficient",
  "modalEditOverBooking.action.error.update": "Failed to update overbooking coefficient.",
  "modalEditOverBooking.toast.title": "The service overbooking coefficient has been updated.",
  "modalEditOverBooking.toast.body": "The service {name} overbooking coefficient has been updated.",

  "modalEditVisibility.title": "Edit service {name} visibility",
  "modalEditVisibility.label.visibility": "Service visibility",
  "modalEditVisibility.action.error.update": "Failed to update visibility.",
  "modalEditVisibility.toast.title": "The service visibility has been updated.",
  "modalEditVisibility.toast.body": "The service {name} visibility has been updated.",
  "modalEditVisibility.visibility.PUBLIC": "Public",
  "modalEditVisibility.visibility.PRIVATE": "Private",

  "modalEditName.title": "Edit service {name} name",
  "modalEditName.label.name": "Service name",
  "modalEditName.action.error.update": "Failed to update service name.",
  "modalEditName.toast.title": "The service name has been updated.",
  "modalEditName.toast.body": "The service name {name} has been updated.",

  "parkingNowTab.button.startAnonymousParking": "Start anonymous parking",
  "parkingNowTab.toast.title.parkingStarted": "Parking started",
  "parkingNowTab.toast.body.parkingStarted": "Parking started for the vehicle {licensePlate}",

  "parkingNowContent.ongoingParking": "Ongoing parkings {pcs} pcs",

  "parkingNowFilters.label.exitTimeExceeded": "Exit itme exceeded",
  "parkingNowFilters.label.checkbox.exitTimeExceeded": "Show parkings who have exceeded time",
  "parkingNowFilters.form.label.parkingType": "Type",
  "parkingNowFilters.form.label.serviceType": "Service type",
  "parkingNowFilters.form.label.licensePlate": "License plate number",
  "parkingNowFilters.form.label.refreshView": "Refresh view",

  "parkingEndedContent.ongoingParking": "Ended parkings {pcs} pcs",

  "parkingEndedFilters.popup.content.car": "Search by license plate number",
  "parkingEndedFilters.popup.content.calendar": "Search by time range",
  "parkingEndedFilters.button.label.or": "or",
  "parkingEndedFilters.form.label.parkingType": "Type",
  "parkingEndedFilters.form.label.licensePlate": "License plate number (min 2 chars)",
  "parkingEndedFilters.form.label.dataRange": "Choose time range (max {days} day(s))",
  "parkingEndedFilters.form.label.dataRange.info": "Searching parkings that have been ended during the search period.",

  "moovyRefreshTime.label.refresh.notRefreshed": "Data has not been refreshed yet.",
  "moovyRefreshTime.label.refresh.oldData": "The data is out of date. Refresh the view.",
  "moovyRefreshTime.label.refresh.refreshedInSeconds": "Refreshed less than {seconds} s ago.",
  "moovyRefreshTime.label.refresh.refreshedInMinutes": "Refreshed less than {minutes} min ago.",
  "moovyRefreshTime.label.refresh.refreshedInMinutesAndSeconds": "Refreshed less than {minutes} min {seconds} s ago.",

  "modalEditEnterpriseUser.toast.title.userRemoved": "Enterprise user removed",
  "modalEditEnterpriseUser.toast.description.userRemove": "Enterprise user has been successfully deleted.",
  "modalEditEnterpriseUser.title.editUser": "Enterprise user info",
  "modalEditEnterpriseUser.action.edit.default.error": "Failed to save company account changes.",
  "modalEditEnterpriseUser.label.company": "Company",
  "modalEditEnterpriseUser.label.businessCode": "Business code",
  "modalEditEnterpriseUser.label.contact": "Contact",
  "modalEditEnterpriseUser.button.tooltip.deleteUser": "Remove enterprise account",

  "accessLogFilters.form.label.licensePlate": "Rekisteritunnus (väh. 2 merkkiä)",
  "accessLogFilters.form.label.dataRange": "Valitse aikaväli (max {days} pv)",

  "modalLicensePlateRecognitionStateHistory.modal.header": "Camera recognition history",
  "modalLicensePlateRecognitionStateHistory.label.licensePlate": "License plate:",
  "modalLicensePlateRecognitionStateHistory.header.edited": "Edited",
  "modalLicensePlateRecognitionStateHistory.header.editor": "Editor",
  "modalLicensePlateRecognitionStateHistory.header.cameraRecognition": "Camera recognition",
  "modalLicensePlateRecognitionStateHistory.header.owner": "Owner",

  "grantedVouchersTable.table.header.licensePlateNumber": "License plate number",
  "grantedVouchersTable.table.header.voucherOwner": "Voucher owner",
  "grantedVouchersTable.table.header.operator": "Operator",
  "grantedVouchersTable.table.header.locationsDescription": "Description of locations",
  "grantedVouchersTable.table.header.granted": "Granted",
  "grantedVouchersTable.table.header.validFrom": "Valid from",
  "grantedVouchersTable.table.header.validUntil": "Valid until",
  "grantedVouchersTable.table.header.status": "Status",
  "grantedVouchersTable.enum.status.expired": "Expired",
  "grantedVouchersTable.enum.status.notValidYet": "Not valid yet",
  "grantedVouchersTable.enum.status.valid": "Valid",
  "grantedVouchersTable.button.deleteVoucher": "Delete unused voucher",
  "grantedVouchersTable.deleteVoucher.confirm.title": "Will the granted benefit be removed from the voucher owner {name}?",
  "grantedVouchersTable.deleteVoucher.confirm.body": "Are you sure you want to remove the parking voucher?",
  "grantedVouchersTable.deleteVoucher.action.error": "Failed to remove parking voucher. Please note that unused parking voucher can be only deleted.",
  "grantedVouchersTable.deleteVoucher.action.success.title": "Voucher removed",
  "grantedVouchersTable.deleteVoucher.action.success.description": "Parking voucher has been removed.",

  "blocklist.allowAutomaticUnblock.true": "Failed invoicing",
  "blocklist.allowAutomaticUnblock.false": "Other",

  "modalStartParking.header": "Start a new parking",
  "modalStartParking.button.start": "Start parking",
  "modalStartParking.action.error": "Failed to start parking.",
  "modalStartParking.toast.success.title.moovyParking": "Started Moovy-parking",
  "modalStartParking.toast.success.title.anonymousParking": "Started anonymous parking",
  "modalStartParking.toast.success.body.moovyParking": "Moovy-parking has been started for the vehicle {licensePlate}.",
  "modalStartParking.toast.success.body.anonymousParking": "Anonymous parking has been started for the vehicle {licensePlate}.",
  "modalStartParking.toast.success.body.anonymousParkingWithLocation": "Anonymous parking has been started for the vehicle {licensePlate} in the location {location}.",
  "modalStartParking.warning.noActivePayment": "User has no active payment card.",
  "modalStartParking.warning.noCameraRecognition": "The license plate does not have the camera recognition on. Parking starts anonymously, unless the vehicle has a subscription at the location. The type of parking is displayed at startup.",
  "modalStartParking.warning.servicesError": "Fetching location products failed",
  "modalStartParking.form.label.startTime": "Starting time",
  "modalStartParking.form.label.location": "Location",
  "modalStartParking.form.label.service": "Start parking with product",
  "modalStartParking.confirm.moovyAccount": "Moovy account has been found by the given license plate. Do you want to continue?",

  "modalCancelWebPayment.sale.header": "Cancel webpayment",
  "modalCancelWebPayment.debtCollection.header": "Revoke debt collection",
  "modalCancelWebPayment.sale.comment": "Cancellation reason",
  "modalCancelWebPayment.debtCollection.comment": "Revoking reason",
  "modalCancelWebPayment.sale.submitButton": "Cancel webpayment",
  "modalCancelWebPayment.debtCollection.submitButton": "Revoke debt collection",

  "potentiallyIncorrectLicensePlates.operator.placeholder": "Choose operator",
  "potentiallyIncorrectLicensePlates.location.placeholder.noOperator": "Choose operator first",
  "potentiallyIncorrectLicensePlates.location.placeholder": "Choose location",
  "potentiallyIncorrectLicensePlates.table.location": "Location",
  "potentiallyIncorrectLicensePlates.table.start": "Start",
  "potentiallyIncorrectLicensePlates.table.duration": "Duration",
  "potentiallyIncorrectLicensePlates.table.licensePlate": "License plate",
  "potentiallyIncorrectLicensePlates.table.suspectReason": "Suspect reason",

  "potentiallyIncorrectLicensePlate.breadcrumb.home": "Home",
  "potentiallyIncorrectLicensePlate.breadcrumb.potentiallyIncorrectLicensePlates": "License plate recognition check",
  "potentiallyIncorrectLicensePlate.breadcrumb.potentiallyIncorrectLicensePlate": "License plate recognition",
  "potentiallyIncorrectLicensePlate.navigation.previous": "Previous",
  "potentiallyIncorrectLicensePlate.navigation.next": "Next",
  "potentiallyIncorrectLicensePlate.navigation.back": "Back to results",
  "potentiallyIncorrectLicensePlate.queryError": "Error fetching license plate recognition information",
  "potentiallyIncorrectLicensePlate.parkingInfo.header": "Parking",
  "potentiallyIncorrectLicensePlate.parkingInfo.start": "Started",
  "potentiallyIncorrectLicensePlate.parkingInfo.end": "Ended",
  "potentiallyIncorrectLicensePlate.parkingInfo.duration": "Duration",
  "potentiallyIncorrectLicensePlate.parkingInfo.location": "Location",
  "potentiallyIncorrectLicensePlate.suspectReason.UNEXPECTED_LENGTH": "Unexpected length",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.DIRTY": "Snow/dirt/slush",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.DAMAGED": "Damaged plate",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.EXTERNAL_OBSTACLE": "External obstacle",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.SIMILAR_ALPHANUMERICS": "Similar alphanumerics",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.OBSCURING_ACCESSORY": "Obscured vision",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.PLATE_OK": "Identified correctly",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.OTHER": "Other recognition error",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.SPECIAL": "Foreign/special plate",
  "potentiallyIncorrectLicensePlate.status.OPEN": "Open",
  "potentiallyIncorrectLicensePlate.status.FIXED": "Fixed",
  "potentiallyIncorrectLicensePlate.status.CONFIRMED": "Confirmed",
  "potentiallyIncorrectLicensePlate.status.INCORRECT": "Incorrect",
  "potentiallyIncorrectLicensePlate.status.UNIDENTIFIED": "Unidentified",
  "potentiallyIncorrectLicensePlate.status.CLOSED": "Closed",
  "potentiallyIncorrectLicensePlate.editSegment.header": "Check license plate",
  "potentiallyIncorrectLicensePlate.editSegment.status": "Status",
  "potentiallyIncorrectLicensePlate.editSegment.suspectReason": "Suspect reason",
  "potentiallyIncorrectLicensePlate.editSegment.parkingEnded": "Parking has ended no updates allowed",
  "potentiallyIncorrectLicensePlate.editSegment.chosenReasonCategory": "Selected reason category",
  "potentiallyIncorrectLicensePlate.editSegment.confirmButton": "Correct",
  "potentiallyIncorrectLicensePlate.editSegment.unidentifiedButton": "Unreadable",
  "potentiallyIncorrectLicensePlate.editSegment.fixButton": "Fix",
  "potentiallyIncorrectLicensePlate.editSegment.selectReviewedReasonCategory": "Choose reason category",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal": "Fix license plate",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.licensePlate": "License plate",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.licensePlateMissing": "Input license plate",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.checkButton": "Check license plate",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.fixButton": "Fix license plate",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.usersFound": "Following Moovy accounts were found",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.usersFoundInfo": "Ongoing parking will be ended without payment and new parking will be started with the original start time and fixed license plate.",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.usersNotFound": " No Moovy accounts found",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.usersNotFoundInfo": "Ongoing parking license plate will be fixed."
}
