import BlocklistCandidatesContent from './blocklistCandidatesContent'
import BlocklistCandidatesToolbar from './blocklistCandidatesToolbar'
import React from 'react'
import Toolbar from '../../components/Toolbar'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import { useIntl } from 'react-intl'

const BlockListCandidates = () => {
  const intl = useIntl()

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.blocklist'
      }),
      active: true
    }
  ])

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'common.breadcrumbs.blocklist'
        })}
        breadcrumbs={breadcrumbs}
        content={<BlocklistCandidatesToolbar />}
      />
      <div className="Admin--Page--Content">
        <BlocklistCandidatesContent />
      </div>
    </>
  )
}

export default BlockListCandidates
