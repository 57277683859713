import { tablePaginationDirections } from '../../services/utils/tablePagination'

export function getFilteredSubscriptionServiceData(
  originalData,
  filterValues,
  pagination
) {
  const { searchInputValue, operatorRef } = filterValues

  if (!originalData) {
    return []
  }

  let filteredData = originalData

  if (operatorRef) {
    filteredData = filteredData.filter(
      (item) => item.operator && item.operator.ref === operatorRef
    )
  }

  switch (pagination.sortColumn) {
    case 'sortByName':
      if (pagination.direction === tablePaginationDirections.ASC) {
        filteredData = filteredData.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        filteredData = filteredData.sort((a, b) => b.name.localeCompare(a.name))
      }
      break
    case 'sortByOperator':
      if (pagination.direction === tablePaginationDirections.ASC) {
        filteredData = filteredData.sort(
          (a, b) =>
            a.operator &&
            b.operator &&
            a.operator.name.localeCompare(b.operator.name)
        )
      } else {
        filteredData = filteredData.sort(
          (a, b) =>
            a.operator &&
            b.operator &&
            b.operator.name.localeCompare(a.operator.name)
        )
      }
      break
    default:
      filteredData = filteredData
      break
  }

  if (searchInputValue) {
    const filterValue =
      (searchInputValue && searchInputValue.toLocaleLowerCase()) || ''

    filteredData = filteredData.filter(
      (item) =>
        item.name.toLocaleLowerCase().includes(filterValue) ||
        (item.operator &&
          item.operator.name.toLocaleLowerCase().includes(filterValue))
    )
  }

  return filteredData
}
