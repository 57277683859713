import React from 'react'
import { FormattedMessage } from 'react-intl'
import { List } from 'semantic-ui-react'

const UserContact = ({ user }) => {
  return (
    <>
      <List>
        <List.Item>
          <b>
            <FormattedMessage id="customers.detail.toolbar.contact.title" />
          </b>
        </List.Item>
        <List.Item>
          <List.Icon name="phone" />{' '}
          <List.Content>
            {user.userDetails ? user.userDetails.phone : user.phone}
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name="at" />{' '}
          <List.Content>
            {user.userDetails ? user.userDetails.email : user.email}
          </List.Content>
        </List.Item>
      </List>
    </>
  )
}

export default UserContact
