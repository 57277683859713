import { get, put, remove } from '../utils/api-renewed'

const createUserBlocklistFullPath = (relativePath, userRef, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/user/${userRef}${relativePath}`
    : `/administration/userblocklist/user/${userRef}${relativePath}`

const createBlocklistFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}${relativePath}`
    : `/administration/userblocklist${relativePath}`

const addToBlocklist = async (userRef, allowAutomaticUnblock, comment) => {
  return await put(createUserBlocklistFullPath('/blocklistUser', userRef), {
    allowAutomaticUnblock,
    comment
  })
}

const removeFromBlocklist = async (userRef) => {
  return await put(createUserBlocklistFullPath('/removeBlocklistUser', userRef))
}

const fetchBlockingPrevented = async (userRef, operatorRealm) => {
  return await get(
    createUserBlocklistFullPath(`/blockingPrevented`, userRef, operatorRealm)
  )
}

const setBlockingPrevented = async (userRef, description) => {
  return await put(createUserBlocklistFullPath(`/blockingPrevented`, userRef), {
    description
  })
}

const removeBlockingPrevented = async (userRef) => {
  return await remove(
    createUserBlocklistFullPath(`/blockingPrevented`, userRef)
  )
}

const fetchPauseAndBlocklistHistory = async (userRef, queryParams) => {
  return await get(createUserBlocklistFullPath('/history', userRef), {
    ...queryParams
  })
}

const fetchBlocklistCandidates = async (queryParams) => {
  return await get(createBlocklistFullPath('/candidates'), {
    ...queryParams
  })
}

const setUsersToBlocklist = async (userRefs) => {
  return await put(createBlocklistFullPath('/candidates/block'), userRefs)
}

export default {
  addToBlocklist,
  removeFromBlocklist,
  fetchBlockingPrevented,
  setBlockingPrevented,
  removeBlockingPrevented,
  fetchPauseAndBlocklistHistory,
  fetchBlocklistCandidates,
  setUsersToBlocklist
}
