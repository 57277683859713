import { Form, Icon, List } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyDateRangePicker, MoovyLink } from '../../components'
import React, { useEffect, useState } from 'react'

import { CALENDAR_DATE_FORMAT } from '../../components/MoovyDateRangePicker'
import { DateTime } from 'luxon'

const MoovyTableFilter = ({
  defaultFilterRange,
  children,
  maxRangeAsDays = undefined,
  tableFilterRange,
  initialDateRange,
  onSelectedDateTimeRange
}) => {
  const intl = useIntl()

  const TableFilterDataRange = {
    TODAY: 'TODAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    PREVIOUS_MONTH: 'PREVIOUS_MONTH',
    MONTH_BEFORE_PREVIOUS_MONTH: 'MONTH_BEFORE_PREVIOUS_MONTH',
    CUSTOM: 'CUSTOM'
  }

  const initialDateTime = DateTime.local().setLocale('fi')

  const [selectedDateRange, setSelectedDateRange] = useState(
    tableFilterRange || TableFilterDataRange.TODAY
  )

  const [customDateTime, setCustomDateTime] = useState({
    startTime:
      initialDateRange?.startTime || initialDateTime.startOf('day').toJSDate(),
    endTime:
      initialDateRange?.endTime || initialDateTime.endOf('day').toJSDate()
  })

  useEffect(() => {
    defaultFilterRange && setDateTimeRange(defaultFilterRange)
  }, [])

  useEffect(() => {
    if (!tableFilterRange) return
    setDateTimeRange(tableFilterRange)
    if (tableFilterRange === TableFilterDataRange.CUSTOM) {
      selectionChanged()
    }
  }, [tableFilterRange])

  const selectionChanged = () => {
    const startTime = DateTime.fromJSDate(customDateTime.startTime)
    const endTime = DateTime.fromJSDate(customDateTime.endTime)
    if (startTime.isValid && endTime.isValid && startTime <= endTime) {
      onSelectedDateTimeRange({
        startTime,
        endTime,
        tableFilterRange: selectedDateRange
      })
    }
  }

  useEffect(() => {
    customDateTime.valid && selectionChanged()
  }, [customDateTime.startTime, customDateTime.endTime])

  const getMonthName = (dataRange) => {
    const monthNumber = getMonthNumber(dataRange)
    switch (monthNumber) {
      case 1:
        return intl.formatMessage({
          id: 'common.month.january'
        })
      case 2:
        return intl.formatMessage({
          id: 'common.month.february'
        })
      case 3:
        return intl.formatMessage({
          id: 'common.month.march'
        })
      case 4:
        return intl.formatMessage({
          id: 'common.month.april'
        })
      case 5:
        return intl.formatMessage({
          id: 'common.month.may'
        })
      case 6:
        return intl.formatMessage({
          id: 'common.month.june'
        })
      case 7:
        return intl.formatMessage({
          id: 'common.month.july'
        })
      case 8:
        return intl.formatMessage({
          id: 'common.month.august'
        })
      case 9:
        return intl.formatMessage({
          id: 'common.month.september'
        })
      case 10:
        return intl.formatMessage({
          id: 'common.month.october'
        })
      case 11:
        return intl.formatMessage({
          id: 'common.month.november'
        })
      case 12:
        return intl.formatMessage({
          id: 'common.month.december'
        })
      default:
        return ''
    }
  }

  const getMonthNumber = (dataRange) => {
    const nowDateTime = DateTime.local().setLocale('fi')

    switch (dataRange) {
      case TableFilterDataRange.MONTH:
        return nowDateTime.startOf('month').month
      case TableFilterDataRange.PREVIOUS_MONTH:
        return nowDateTime.plus({ month: -1 }).startOf('month').month
      case TableFilterDataRange.MONTH_BEFORE_PREVIOUS_MONTH:
        return nowDateTime.plus({ month: -2 }).startOf('month').month
      default:
        return nowDateTime.startOf('month').month
    }
  }

  const setDateTimeRange = (dataRange) => {
    setSelectedDateRange(dataRange)

    const nowDateTime = DateTime.local().setLocale('fi')

    let startTime = ''
    let endTime = ''
    let updateDate = true
    switch (dataRange) {
      case TableFilterDataRange.TODAY:
        startTime = nowDateTime.startOf('day').toJSDate()
        endTime = nowDateTime.endOf('day').toJSDate()
        break
      case TableFilterDataRange.WEEK:
        startTime = nowDateTime.startOf('week').toJSDate()
        endTime = nowDateTime.endOf('week').toJSDate()
        break
      case TableFilterDataRange.MONTH:
        startTime = nowDateTime.startOf('month').toJSDate()
        endTime = nowDateTime.endOf('month').toJSDate()
        break
      case TableFilterDataRange.PREVIOUS_MONTH:
        startTime = nowDateTime
          .plus({ month: -1 })
          .startOf('month')
          .toJSDate()
        endTime = nowDateTime
          .plus({ month: -1 })
          .endOf('month')
          .toJSDate()
        break
      case TableFilterDataRange.MONTH_BEFORE_PREVIOUS_MONTH:
        startTime = nowDateTime
          .plus({ month: -2 })
          .startOf('month')
          .toJSDate()
        endTime = nowDateTime
          .plus({ month: -2 })
          .endOf('month')
          .toJSDate()
        break
      default:
        updateDate = false
    }

    if (updateDate) {
      setCustomDateTime({
        startTime,
        endTime,
        valid: true
      })
    }
  }

  return (
    <>
      <div>
        <Form>{children}</Form>
      </div>
      <List horizontal style={{ marginBottom: '5px' }}>
        <List.Item style={{ margin: '0px' }}>
          <MoovyLink
            toggled={selectedDateRange == TableFilterDataRange.TODAY}
            bold={selectedDateRange == TableFilterDataRange.TODAY}
            onClick={() => setDateTimeRange(TableFilterDataRange.TODAY)}
          >
            <FormattedMessage id="moovyTableFilter.filter.shortcut.today" />
          </MoovyLink>
        </List.Item>
        <List.Item style={{ margin: '0px' }}>
          <MoovyLink
            toggled={selectedDateRange == TableFilterDataRange.WEEK}
            bold={selectedDateRange == TableFilterDataRange.WEEK}
            onClick={() => setDateTimeRange(TableFilterDataRange.WEEK)}
          >
            <FormattedMessage id="moovyTableFilter.filter.shortcut.week" />
          </MoovyLink>
        </List.Item>
        <List.Item style={{ margin: '0px' }}>
          <MoovyLink
            toggled={selectedDateRange == TableFilterDataRange.MONTH}
            bold={selectedDateRange == TableFilterDataRange.MONTH}
            onClick={() => setDateTimeRange(TableFilterDataRange.MONTH)}
          >
            {getMonthName(TableFilterDataRange.MONTH)}
          </MoovyLink>
        </List.Item>
        <List.Item style={{ margin: '0px' }}>
          <MoovyLink
            toggled={selectedDateRange == TableFilterDataRange.PREVIOUS_MONTH}
            bold={selectedDateRange == TableFilterDataRange.PREVIOUS_MONTH}
            onClick={() =>
              setDateTimeRange(TableFilterDataRange.PREVIOUS_MONTH)
            }
          >
            {getMonthName(TableFilterDataRange.PREVIOUS_MONTH)}
          </MoovyLink>
        </List.Item>
        <List.Item style={{ margin: '0px' }}>
          <MoovyLink
            toggled={
              selectedDateRange ==
              TableFilterDataRange.MONTH_BEFORE_PREVIOUS_MONTH
            }
            bold={
              selectedDateRange ==
              TableFilterDataRange.MONTH_BEFORE_PREVIOUS_MONTH
            }
            onClick={() =>
              setDateTimeRange(TableFilterDataRange.MONTH_BEFORE_PREVIOUS_MONTH)
            }
          >
            {getMonthName(TableFilterDataRange.MONTH_BEFORE_PREVIOUS_MONTH)}
          </MoovyLink>
        </List.Item>
        <List.Item style={{ margin: '0px' }}>
          <MoovyLink
            toggled={selectedDateRange == TableFilterDataRange.CUSTOM}
            bold={selectedDateRange == TableFilterDataRange.CUSTOM}
            onClick={() => setDateTimeRange(TableFilterDataRange.CUSTOM)}
          >
            <Icon name="calendar alternate" color="blue" />
            <FormattedMessage id="moovyTableFilter.filter.shortcut.chooseTimeRange" />
          </MoovyLink>
        </List.Item>
        {(selectedDateRange == TableFilterDataRange.CUSTOM && (
          <List.Item>
            <Form id="CustomDataRange" autoComplete="off">
              <MoovyDateRangePicker
                name="moovyTableDateRange"
                placeholderText={intl.formatMessage({
                  id: 'moovyTableFilter.filter.placeholder.chooseTimeRange'
                })}
                maxRangeAsDays={maxRangeAsDays}
                startDate={customDateTime.startTime}
                endDate={customDateTime.endTime}
                onChange={(dates, event) => {
                  const [start, end, valid] = dates
                  setCustomDateTime({
                    startTime: start,
                    endTime: end,
                    valid
                  })
                }}
              />
            </Form>
          </List.Item>
        )) || (
          <>
            <List.Item style={{ margin: '0px' }}>
              <b>
                {`${DateTime.fromJSDate(customDateTime.startTime).toFormat(
                  CALENDAR_DATE_FORMAT
                )} - ${DateTime.fromJSDate(customDateTime.endTime).toFormat(
                  CALENDAR_DATE_FORMAT
                )}`}
              </b>
            </List.Item>
          </>
        )}
      </List>
    </>
  )
}

export default MoovyTableFilter
