import React, { useEffect, useState } from 'react'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

import ParkingContent from './ParkingContent'
import ParkingToolbar from './ParkingToolbar'
import { PermissionWebPayment } from '../../services/utils'
import Routing from '../../routing/Routing'
import invoiceService from '../../services/Invoicing'
import moovyDateTime from '../../services/utils/moovyDateTime'
import parkingService from '../../services/Parking'
import salesService from '../../services/Sales'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useIntl } from 'react-intl'

export default function ParkingPage() {
  const { ref } = useParams()
  const { operatorRealm, isSuperOperator, roles } = useAuthInfo()
  const intl = useIntl()
  const queryClient = useQueryClient()
  const location = useLocation()
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [previousIndex, setPreviousIndex] = useState(-1)
  const navigate = useNavigate()

  const parkingQuery = useQuery({
    queryKey: ['parking', ref],
    queryFn: () => parkingService.fetchParking(ref, operatorRealm)
  })

  const allSalesQuery = useQuery({
    queryKey: ['sales', location.state],
    queryFn: () => {
      const params = { ...location.state, limit: 1000, page: undefined }
      params.createdFromTime = moovyDateTime.calendarDateInputToBeginOfDayISO(
        params.createdFromTime
      )
      params.createdUntilTime = moovyDateTime.calendarDateInputToEndOfDayISO(
        params.createdUntilTime
      )

      return salesService.fetchSales(operatorRealm, params)
    },
    staleTime: 1000 * 60 * 5,
    enabled: !!location.state
  })

  const allSales = allSalesQuery?.data?.data || []

  const setParkingCarouselData = (allSales) => {
    if (allSales.length === 0) return undefined

    let parkingData = {
      currentParkingRef: '',
      currentIndex: -1,
      previousParkingRef: '',
      nextParkingRef: '',
      invoice: {
        ref: ''
      },
      sale: {
        saleRef: ''
      },
      webPaymentSaleState: {
        ref: '',
        status: '',
        resolveTime: '',
        sendTime: ''
      },
      totalResults: 0
    }

    let currentSaleIndex = allSales
      .map((item) => item.parking?.ref)
      .indexOf(ref)

    parkingData.currentParkingRef = ref
    parkingData.currentIndex = currentSaleIndex
    parkingData.previousParkingRef =
      (allSales[currentSaleIndex - 1] &&
        allSales[currentSaleIndex - 1].parking?.ref) ||
      allSales.at(-1)?.parking?.ref
    parkingData.nextParkingRef =
      (allSales[currentSaleIndex + 1] &&
        allSales[currentSaleIndex + 1].parking?.ref) ||
      allSales[0]?.parking?.ref
    parkingData.invoice.ref = allSales[currentSaleIndex]?.invoice?.ref
    parkingData.sale.ref = allSales[currentSaleIndex]?.ref
    parkingData.webPaymentSaleState.ref =
      allSales[currentSaleIndex]?.webpayment?.ref
    parkingData.webPaymentSaleState.status =
      allSales[currentSaleIndex]?.webpayment?.status
    parkingData.totalResults = allSalesQuery.data.totalResults
    parkingData.webPaymentSaleState.resolveTime =
      allSales[currentSaleIndex]?.webpayment?.resolveTime
    parkingData.webPaymentSaleState.sendTime =
      allSales[currentSaleIndex]?.webpayment?.sendTime

    if (currentIndex != currentSaleIndex) {
      setPreviousIndex(currentIndex)
      setCurrentIndex(currentSaleIndex)
    }

    return parkingData
  }

  useEffect(() => {
    // If allSales does not contain the ref given by url parameter,
    // next sale item will be tried to find so that user is able to continue
    // his/her work in the same position of the carousel.
    if (
      previousIndex !== -1 &&
      currentIndex === -1 &&
      allSales.map((item) => item.parking?.ref).indexOf(ref) === -1 &&
      allSales.length >= currentIndex
    ) {
      const parkingRef =
        allSales[previousIndex]?.parking?.ref ||
        allSales[previousIndex - 1]?.parking?.ref
      if (parkingRef) {
        navigate(Routing.getParkingUrl(parkingRef, isSuperOperator), {
          state: location.state
        })
      }
    }
  }, [currentIndex, allSales])

  const parkingCarouselData = setParkingCarouselData(allSales)

  const {
    mutate: createDebtCollectionInvoice,
    ...createDebtCollectionInvoiceMutation
  } = useMutation({
    mutationFn: (data) =>
      salesService.createDebtCollectionInvoice(operatorRealm, data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sales', location.state] })
      showLocalizedMoovyToast(intl, {
        title: 'parking.toast.operation.createDebtCollection.success.title',
        description:
          'parking.toast.operation.createDebtCollection.success.description'
      })
    },
    onError: () => {
      showLocalizedMoovyToast(intl, {
        title: 'parking.toast.operation.createDebtCollection.failed.title',
        description:
          'parking.toast.operation.createDebtCollection.failed.description',
        type: 'error'
      })
    }
  })

  const {
    mutate: revokeInvoiceDebtCollection,
    ...revokeInvoiceDebtCollectionMutation
  } = useMutation({
    mutationFn: ({ invoiceRef, reason }) =>
      invoiceService.revokeDebtCollection(invoiceRef, reason, operatorRealm),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sales', location.state] })
      showLocalizedMoovyToast(intl, {
        title: 'parking.toast.operation.revokeDebtCollection.success.title',
        description:
          'parking.toast.operation.revokeDebtCollection.success.description'
      })
    },
    onError: () => {
      showLocalizedMoovyToast(intl, {
        title: 'parking.toast.operation.revokeDebtCollection.failed.title',
        description:
          'parking.toast.operation.revokeDebtCollection.failed.description',
        type: 'error'
      })
    }
  })

  const { mutate: cancelWebpayment, ...cancelWebpaymentMutation } = useMutation(
    {
      mutationFn: (data) =>
        parkingService.cancelSaleWebPayment(
          data.ref,
          data.reason,
          operatorRealm
        ),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['sales', location.state] })
        showLocalizedMoovyToast(intl, {
          title: 'parking.toast.operation.cancelWebpayment.success.title',
          description:
            'parking.toast.operation.cancelWebpayment.success.description'
        })
      },
      onError: () => {
        showLocalizedMoovyToast(intl, {
          title: 'parking.toast.operation.cancelWebpayment.failed.title',
          description:
            'parking.toast.operation.cancelWebpayment.failed.description',
          type: 'error'
        })
      }
    }
  )

  const moveToDebtCollection = (webPaymentSaleStateRef) => {
    createDebtCollectionInvoice({
      createVismaInvoice: true,
      sendVismaInvoice: true,
      webPaymentRefs: [webPaymentSaleStateRef]
    })
  }

  const revokeDebtCollection = (data) => {
    revokeInvoiceDebtCollection(data)
  }

  const cancelNotPaidWebpayment = (data) => {
    cancelWebpayment(data)
  }

  return (
    <>
      <ParkingToolbar
        query={parkingQuery}
        state={location.state}
        parkingCarouselData={parkingCarouselData}
        isSuperOperator={isSuperOperator}
        hasWebpaymentEdit={PermissionWebPayment.HasWebPaymentEdit(roles)}
        hasDebtCollectionEdit={PermissionWebPayment.HasDebtCollectionEdit(
          roles
        )}
        moveToDebtCollection={moveToDebtCollection}
        revokeDebtCollection={revokeDebtCollection}
        cancelNotPaidWebpayment={cancelNotPaidWebpayment}
        loading={
          createDebtCollectionInvoiceMutation.isPending ||
          revokeInvoiceDebtCollectionMutation.isPending ||
          cancelWebpaymentMutation.isPending
        }
      />
      <ParkingContent parkingRef={ref} parkingQuery={parkingQuery} />
    </>
  )
}
