import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Grid, Icon, Loader, Tab } from 'semantic-ui-react'

import { MoovyButton } from '../../components'
import { showMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import UserGroupAddUserModal from './UserGroupAddUserModal'
import UserGroupMembershipsTable from './UserGroupMembershipsTable'

export default function UserGroupUsersTab({
  userGroup,
  userGroupMemberships,
  refreshUserGroup,
  loading
}) {
  const { isSuperOperator } = useAuthInfo()
  const navigate = useNavigate()
  const [addNewUserModal, setAddNewUserModal] = useState(false)
  const intl = useIntl()

  return (
    <Tab.Pane>
      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <Grid>
          <Grid.Row textAlign="right">
            <Grid.Column>
              <MoovyButton onClick={() => setAddNewUserModal(true)}>
                <Icon name="plus" />
                <FormattedMessage id="userGroupUsersTab.button.add" />
              </MoovyButton>
            </Grid.Column>
            {addNewUserModal && (
              <UserGroupAddUserModal
                userGroup={userGroup}
                userGroupMemberships={userGroupMemberships}
                onSubmit={(description) => {
                  showMoovyToast(
                    intl.formatMessage({ id: 'userGroupUsersTab.success' }),
                    description
                  )
                  setAddNewUserModal(false)
                  refreshUserGroup()
                }}
                onClose={() => setAddNewUserModal(false)}
              />
            )}
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <UserGroupMembershipsTable
                userGroupMemberships={userGroupMemberships}
                onRowClick={(userRef) =>
                  navigate(
                    `/${
                      isSuperOperator ? 'admin' : 'operator'
                    }/customers/${userRef}`
                  )
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Tab.Pane>
  )
}
