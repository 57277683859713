import { get, post, remove } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/user/${relativePath}`
    : `/administration/user/${relativePath}`

export const fetchConversationsByUserRef = async (operatorRealm, userRef) => {
  try {
    const result = await get(
      createFullPath(`${userRef}/conversation`, operatorRealm)
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

export const addConversationToUser = async (
  operatorRealm,
  userRef,
  comment
) => {
  try {
    const result = await post(
      createFullPath(`${userRef}/conversation`, operatorRealm),
      { comment }
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

export const removeConversation = async (operatorRealm, userRef, conversationRef) => {
  try {
    const result = await remove(
      createFullPath(
        `${userRef}/conversation/${conversationRef}`,
        operatorRealm
      )
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}
