import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'
import { Link } from 'react-router-dom'
import { Grid, Header, Icon } from 'semantic-ui-react'

import { MoovyButton, Toolbar } from '../../components'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import useGlobalUI from '../../hooks/useGlobalUI'
import messageCenterService from '../../services/MessageCenter'
import MessageCenterTable from './MessageCenterTable'

const MessageCenter = () => {
  const intl = useIntl()

  const breadcrumbsItems = [
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.messageCenter'
      }),
      active: true
    }
  ]

  const breadcrumbs = createBreadcrumbs(breadcrumbsItems)

  const [state, setState] = useGlobalUI({
    key: 'MessageCenter',
    initialValue: {
      page: 0,
      limit: 20,
      sort: 'sortByModificationTime',
      direction: 'DESC',
      includeHistory: false,
      partialSearch: ''
    }
  })

  const messagesQuery = useQuery({
    queryKey: ['messageServices', state],
    queryFn: () =>
      messageCenterService.fetchMessages({
        page: state.page,
        limit: state.limit,
        sort: state.sort,
        direction: state.direction,
        includeHistory: state.includeHistory,
        partialSearch: state.partialSearch
      })
  })

  const setSort = (column, direction) => {
    setState({
      ...state,
      sort: column,
      direction: direction
    })
  }

  const setActivePage = (newActivePage) => {
    setState({ ...state, page: newActivePage - 1 })
  }

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'messageCenter.toolbar.title'
        })}
        breadcrumbs={breadcrumbs}
      />

      <div className="Admin--Page--Content">
        <Grid>
          <Grid.Column floated="left" width={8}>
            <Header>
              <FormattedMessage id="messageCenter.table.header" />
            </Header>
          </Grid.Column>
          <Grid.Column floated="right" width={8} textAlign="right">
            <Link to="edit">
              <MoovyButton>
                <Icon name="comment outline" />
                <FormattedMessage id="messageCenter.action.createMessage" />
              </MoovyButton>
            </Link>
          </Grid.Column>
        </Grid>
        <MessageCenterTable
          messages={messagesQuery.data}
          sorting={{
            column: state.sort,
            direction: state.direction
          }}
          activePage={state.page + 1}
          onSort={setSort}
          onActivePageChanged={setActivePage}
        ></MessageCenterTable>
      </div>
    </>
  )
}

export default MessageCenter
