import React from 'react'
import { useIntl } from 'react-intl'
import { Input } from 'semantic-ui-react'

const OperatorDetailsToolbar = ({ searchValue, onChangeSearch }) => {
  const intl = useIntl()

  return (
    <div>
      <div className="toolbar-filters">
        <Input
          value={searchValue}
          icon="search"
          iconPosition="left"
          placeholder={intl.formatMessage({
            id: 'operatorDetails.toolbar.search.placeholder'
          })}
          onChange={(e) => onChangeSearch(e.target.value)}
        />
      </div>
      <div />
    </div>
  )
}

export default OperatorDetailsToolbar
