import './InvoiceAdministration.scss'

import {
  Button,
  Checkbox,
  Confirm,
  Container,
  Form,
  Modal,
  Segment,
  Table
} from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import {
  formatPrice,
  isDebtCollectionInvoice,
  PermissionWebPayment
} from '../../services/utils'
import { isCreditNotePossible, isVismaPayment } from './helpers'
import { useMutation, useQuery } from '@tanstack/react-query'

import MoovyPlaceholderLoader from '../MoovyPlaceholderLoader'
import invoiceService from '../../services/Invoicing'
import { showLocalizedMoovyToast } from '../MoovyToast'
import useWebPaymentCancelForm from '../../hooks/useWebPaymentCancelForm'
import withSelections from '../../HOC/withSelections'
import useAuthInfo from '../../hooks/useAuthInfo'

const emptyConfirm = {
  open: false,
  content: undefined,
  onConfirm: undefined,
  onCancel: undefined
}

const selectingType = {
  NONE: 'NONE',
  CREDIT_NOTE: 'CREDIT_NOTE',
  DEBT_COLLECTION: 'DEBT_COLLECTION'
}

const VismaInvoiceAdministration = ({
  invoice,
  operatorRealm,
  setMessageCallback,
  updateInvoicesCallback,
  onClose,
  areAllIndeterminate,
  areAllSelected,
  areAnySelected,
  handleClearAll,
  handleSelect,
  handleSelectAll,
  isItemSelected,
  selected
}) => {
  const intl = useIntl()
  const [selecting, setSelecting] = useState(selectingType.NONE)
  const [showCreditNoteModal, setShowCreditNoteModal] = useState(false)
  const [confirm, setConfirm] = useState(emptyConfirm)
  const { roles } = useAuthInfo()
  const canEditDebtCollection =
    PermissionWebPayment.HasDebtCollectionEdit(roles)

  const {
    comment,
    setComment,
    licensePlate,
    setLicensePlate,
    hasErrors,
    errors,
    reason: cancellationReason
  } = useWebPaymentCancelForm('')

  if (!isVismaPayment(invoice)) {
    throw new Error('VismaInvoiceAdministration supports only Visma invoices')
  }

  const createInfoMessage = (content) => ({
    info: true,
    header: intl.formatMessage({ id: 'invoiceAdministration.info.header' }),
    content
  })

  const createErrorMessage = (content) => ({
    error: true,
    header: intl.formatMessage({ id: 'invoiceAdministration.error.header' }),
    content
  })

  const invoiceRowsQuery = useQuery({
    queryKey: ['invoiceRows', invoice.ref],
    queryFn: () => invoiceService.fetchInvoiceRows(invoice.ref, operatorRealm),
    enabled: !!invoice.ref
  })

  useEffect(() => {
    if (invoiceRowsQuery.isError) {
      setMessageCallback(
        createErrorMessage(
          intl.formatMessage({
            id: 'invoiceAdministration.invoiceRows.error.content'
          })
        )
      )
    }
  }, [invoiceRowsQuery.status])

  const showCreditNoteButton = !invoice?.isRefunded
  const isSelecting = selecting !== selectingType.NONE
  const invoiceRows = isSelecting
    ? invoiceRowsQuery.data?.filter((row) => row.totalPrice > 0)
    : invoiceRowsQuery.data ?? []

  const invoiceActionAvailability = {
    creditNote: isCreditNotePossible(invoice),
    debtCollectionInvoice: isDebtCollectionInvoice(invoice)
  }

  const maxRevertSum = invoice.totalPrice

  const revertSum = invoiceRows
    ?.filter((row) => isItemSelected(row.ref))
    .map((row) => Number(row.totalPrice))
    .reduce((total, currentValue) => total + currentValue, 0)

  const selectedInvoiceRows = invoiceRows?.filter((row) =>
    isItemSelected(row.ref)
  )

  const { mutate: createCreditNote, ...createCreditNoteMutation } = useMutation(
    {
      mutationFn: () =>
        invoiceService.createCreditNote(invoice.ref, operatorRealm),
      onSuccess: (data) => {
        setMessageCallback(
          createInfoMessage(
            intl.formatMessage({
              id: 'vismaInvoiceAdministration.creditNote.success'
            })
          )
        )
        updateInvoicesCallback()
      },
      onError: () =>
        setMessageCallback(
          createErrorMessage(
            intl.formatMessage({
              id: 'vismaInvoiceAdministration.creditNote.error'
            })
          )
        ),
      onSettled: () => setShowCreditNoteModal(false)
    }
  )

  const { mutate: createCustomInvoice, ...createCustomInvoiceMutation } =
    useMutation({
      mutationFn: () =>
        invoiceService.createCustomInvoice(
          invoice.user.ref,
          selectedInvoiceRows.map((row) => ({
            productRef: row.product.ref,
            amount: row.totalPrice,
            info: row.description,
            overrideDescriptionWithInfo: true,
            incurredAtDate: row.incurredAtDate
          })),
          operatorRealm
        ),
      onSuccess: () => {
        onClose()
        showLocalizedMoovyToast(intl, {
          title:
            'vismaInvoiceAdministration.createCustomInvoice.toast.success.title',
          description:
            'vismaInvoiceAdministration.createCustomInvoice.toast.success.body'
        })
        updateInvoicesCallback()
      },
      onError: () =>
        setMessageCallback(
          createErrorMessage(
            intl.formatMessage({
              id: 'vismaInvoiceAdministration.createCustomInvoice.error'
            })
          )
        ),
      onSettled: () => setConfirm(emptyConfirm)
    })

  const { mutate: revokeDebtCollection, ...revokeDebtCollectionMutation } =
    useMutation({
      mutationFn: (reason) => {
        if (selectedInvoiceRows.length > 0) {
          return invoiceService.revokeDebtCollectionAndCreateNewInvoice(
            invoice.ref,
            selectedInvoiceRows.map((item) => item.ref),
            reason,
            operatorRealm
          )
        }
        return invoiceService.revokeDebtCollection(
          invoice.ref,
          reason,
          operatorRealm
        )
      },
      onSuccess: () => {
        setMessageCallback(
          createInfoMessage(
            intl.formatMessage({
              id: 'vismaInvoiceAdministration.revokeDebtCollection.success'
            })
          )
        )
        setSelecting(selectingType.NONE)
        updateInvoicesCallback()
      },
      onError: () =>
        setMessageCallback(
          createErrorMessage(
            intl.formatMessage({
              id: 'vismaInvoiceAdministration.revokeDebtCollection.error'
            })
          )
        ),
      onSettled: () => setConfirm(emptyConfirm)
    })

  const confirmModals = {
    REVOKE_DEBT_COLLECTION: {
      open: true,
      content: intl.formatMessage({
        id: 'vismaInvoiceAdministration.revokeDebtCollection.confirm'
      }),
      onConfirm: () => revokeDebtCollection(cancellationReason),
      onCancel: () => setConfirm(emptyConfirm)
    },
    CREATE_CUSTOM_INVOICE: {
      open: true,
      content: intl.formatMessage({
        id: 'vismaInvoiceAdministration.createCustomInvoice.confirm'
      }),
      onConfirm: createCustomInvoice,
      onCancel: () => setConfirm(emptyConfirm)
    }
  }

  const SelectedText = () => {
    const count = selected.length
    if (count === 0) {
      return intl.formatMessage({
        id: 'vismaInvoiceAdministration.createCustomInvoice.noSelectedRows'
      })
    }
    if (count > 0) {
      return intl.formatMessage(
        {
          id: 'vismaInvoiceAdministration.createCustomInvoice.selectedRows'
        },
        {
          count,
          totalPrice: formatPrice(revertSum)
        }
      )
    }
    return ''
  }

  if (invoiceRowsQuery.isLoading) {
    return <MoovyPlaceholderLoader paragraphs={2} />
  }

  const isSubmitting =
    createCreditNoteMutation.isPending ||
    revokeDebtCollectionMutation.isPending ||
    createCustomInvoiceMutation.isPending

  return (
    <>
      {selecting === selectingType.CREDIT_NOTE && (
        <Segment className="invoiceRowTableToolbar">
          <div>
            <FormattedMessage
              id="vismaInvoiceAdministration.maxRevert"
              values={{
                sum: formatPrice(maxRevertSum)
              }}
            />
            <br />
            <SelectedText />
          </div>
          <div className="invoiceRowTableToolbarGrow" />
          <Button onClick={() => handleClearAll()}>
            <FormattedMessage id="vismaInvoiceAdministration.clearAll" />
          </Button>
        </Segment>
      )}
      {selecting === selectingType.DEBT_COLLECTION && (
        <Segment className="invoiceRowTableToolbar">
          <div>
            <FormattedMessage id="vismaInvoiceAdministration.revokeDebtCollection.info" />
          </div>
          <div className="invoiceRowTableToolbarGrow" />
          <Button onClick={() => handleClearAll()}>
            <FormattedMessage id="vismaInvoiceAdministration.clearAll" />
          </Button>
        </Segment>
      )}
      <Container className="invoiceRowTableContainer">
        <Table compact="very" size="small" celled>
          <Table.Header fullWidth>
            <Table.Row>
              {isSelecting && (
                <Table.HeaderCell>
                  <Checkbox
                    checked={areAllSelected(invoiceRows)}
                    indeterminate={areAllIndeterminate(invoiceRows)}
                    onChange={() => handleSelectAll(invoiceRows)}
                  />
                </Table.HeaderCell>
              )}
              <Table.HeaderCell>
                <FormattedMessage id="invoiceRowTable.product" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="invoiceRowTable.totalPrice" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="invoiceRowTable.VAT" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="invoiceRowTable.description" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {invoiceRows.map((row) => (
              <Table.Row key={row.ref}>
                {isSelecting && (
                  <Table.Cell>
                    <Checkbox
                      checked={isItemSelected(row.ref)}
                      onChange={() => handleSelect(row.ref)}
                    />
                  </Table.Cell>
                )}
                <Table.Cell>{row.product.name}</Table.Cell>
                <Table.Cell>{formatPrice(row.totalPrice)}</Table.Cell>
                <Table.Cell>{row.vatPercent}%</Table.Cell>
                <Table.Cell>{row.description}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {selecting === selectingType.DEBT_COLLECTION && (
          <Form style={{ marginTop: '10px' }}>
            <Form.Input
              autoFocus
              label={intl.formatMessage({
                id: 'common.vehicles.licensePlateNumber'
              })}
              value={licensePlate}
              error={errors.licensePlate}
              onChange={(e) => setLicensePlate(e.target.value)}
            />
            <Form.TextArea
              label={intl.formatMessage({
                id: 'vismaInvoiceAdministration.revokeDebtCollection.comment'
              })}
              value={comment}
              error={errors.comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form>
        )}
      </Container>
      <div className="invoiceRowTableToolbar">
        <div className="invoiceRowTableToolbarGrow" />
        {selecting === selectingType.NONE && (
          <>
            {invoiceActionAvailability.creditNote &&
              !invoiceActionAvailability.debtCollectionInvoice && (
                <>
                  {showCreditNoteButton && (
                    <Button
                      primary
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      onClick={() => setShowCreditNoteModal(true)}
                    >
                      <FormattedMessage id="vismaInvoiceAdministration.creditNote.button" />
                    </Button>
                  )}
                  <Button
                    primary
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => setSelecting(selectingType.CREDIT_NOTE)}
                  >
                    <FormattedMessage id="vismaInvoiceAdministration.createCustomInvoice.button" />
                  </Button>
                </>
              )}
            {canEditDebtCollection &&
              invoiceActionAvailability.debtCollectionInvoice && (
                <Button
                  primary
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  onClick={() => {
                    handleClearAll()
                    setSelecting(selectingType.DEBT_COLLECTION)
                  }}
                >
                  <FormattedMessage id="vismaInvoiceAdministration.revokeDebtCollection.button" />
                </Button>
              )}
          </>
        )}

        {selecting === selectingType.DEBT_COLLECTION ||
        selecting === selectingType.CREDIT_NOTE ? (
          <Button
            onClick={() => {
              handleClearAll()
              setSelecting(selectingType.NONE)
            }}
          >
            <FormattedMessage id="common.button.cancel" />
          </Button>
        ) : (
          ''
        )}
        {selecting === selectingType.CREDIT_NOTE && (
          <Button
            primary
            disabled={isSubmitting || !areAnySelected()}
            loading={isSubmitting}
            onClick={() => setConfirm(confirmModals.CREATE_CUSTOM_INVOICE)}
          >
            <FormattedMessage id="vismaInvoiceAdministration.createCustomInvoice.submit" />
          </Button>
        )}
        {selecting === selectingType.DEBT_COLLECTION && (
          <Button
            primary
            disabled={isSubmitting || hasErrors}
            loading={isSubmitting}
            onClick={() => setConfirm(confirmModals.REVOKE_DEBT_COLLECTION)}
          >
            <FormattedMessage id="vismaInvoiceAdministration.revokeDebtCollection.button" />
          </Button>
        )}
      </div>
      <Confirm
        {...confirm}
        cancelButton={intl.formatMessage({ id: 'common.button.cancel' })}
        confirmButton={intl.formatMessage({ id: 'common.button.accept' })}
        size="tiny"
      />
      <Modal
        onClose={() => setShowCreditNoteModal(false)}
        open={showCreditNoteModal}
        size="tiny"
      >
        <Modal.Content>
          <FormattedMessage id="vismaInvoiceAdministration.creditNote.modal.content" />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowCreditNoteModal(false)}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button
            primary
            disabled={isSubmitting}
            loading={isSubmitting}
            onClick={createCreditNote}
          >
            <FormattedMessage id="vismaInvoiceAdministration.creditNote.modal.accept" />
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default withSelections(VismaInvoiceAdministration)
