import * as Sentry from '@sentry/react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Header } from 'semantic-ui-react'

import useAuthInfo from '../../hooks/useAuthInfo'
import ErrorHeader from '../HeaderComponent/ErrorHeader'

function FallbackComponent() {
  return (
    <div className="App">
      <div className="App-Top">
        <ErrorHeader />
      </div>
      <div className="Admin--Page--Content">
        <Header>
          <FormattedMessage id="errorBoundary.fallbackComponent.content" />
        </Header>
      </div>
    </div>
  )
}

export default ({ children }) => {
  const {
    operatorRealm,
    userName,
    preferredUsername,
    roles,
    isSuperOperator
  } = useAuthInfo()
  const intl = useIntl()

  // Add keycloak user information as extra data to Sentry
  Sentry.setContext('keycloakUser', {
    isSuperOperator,
    userName,
    preferredUsername,
    operatorRealm,
    roles
  })
  return (
    <Sentry.ErrorBoundary
      fallback={<FallbackComponent />}
      showDialog
      dialogOptions={{
        title: intl.formatMessage({ id: 'errorBoundary.dialog.title' }),
        subtitle: intl.formatMessage({ id: 'errorBoundary.dialog.subtitle' }),
        subtitle2: intl.formatMessage({ id: 'errorBoundary.dialog.subtitle2' }),
        successMessage: intl.formatMessage({
          id: 'errorBoundary.dialog.successMessage'
        }),
        labelClose: intl.formatMessage({
          id: 'errorBoundary.dialog.labelClose'
        }),
        labelSubmit: intl.formatMessage({
          id: 'errorBoundary.dialog.labelSubmit'
        })
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
