{
  "common.button.close": "Sulje",
  "common.button.cancel": "Peruuta",
  "common.button.confirm": "Kyllä",
  "common.button.save": "Tallenna",
  "common.button.accept": "Hyväksy",
  "common.button.delete": "Poista",
  "common.button.modify": "Muokkaa",
  "common.button.copy": "Kopioi",
  "common.button.OK": "OK",
  "common.button.search": "Hae",
  "common.button.add": "Lisää",
  "common.button.change": "Vaihda",
  "common.button.continue": "Jatka",
  "common.button.back": "Takaisin",
  "common.button.clear": "Tyhjennä",

  "common.breadcrumbs.home": "Koti",
  "common.breadcrumbs.userGroups": "Käyttäjäryhmät",
  "common.breadcrumbs.applicationUsers": "Sovelluskäyttäjät",
  "common.breadcrumbs.parkingServices": "Pysäköintituotteet",
  "common.breadcrumbs.messageCenter": "Viestikeskus",
  "common.breadcrumbs.tariff": "Tariffi",
  "common.breadcrumbs.createMessages": "Luo ja lähetä uusi viesti",
  "common.breadcrumbs.settlementRecipients": "Maksunsaajat",
  "common.breadcrumbs.moneyTransfer": "Tilienväliset siirrot",
  "common.breadcrumbs.creditCoverCreditLoss": "Luottotappioiden kattaminen",
  "common.breadcrumbs.blocklist": "Veloittamattomien lista",
  "common.breadcrumbs.chargings": "Lataukset",
  "common.breadcrumbs.potentiallyIncorrectLicensePlates": "Rekisteritunnusten tarkistus",

  "common.mutation.error": "Toiminnon suorittamisessa tapahtui virhe",

  "common.vehicles.licensePlateNumber": "Rekisteritunnus",
  "common.vehicles.licensePlateRecognition": "Kameratunnistus",
  "common.vehicles.licensePlateRecognitionActive": "Käytössä",
  "common.vehicles.licensePlateRecognitionInActive": "Ei käytössä",

  "common.location": "Kohde",
  "common.phoneNumber": "Puhelinnumero",
  "common.filter.filterSearch": "RAJAA HAKUA",
  "common.noResultsMessage": "Ei tuloksia",
  "common.user": "Käyttäjä",
  "common.key": "Avain",
  "common.value": "Arvo",
  "common.yes": "Kyllä",
  "common.no": "Ei",
  "common.private": "Yksityinen",
  "common.public": "Julkinen",
  "common.unlimited": "Rajoittamaton",
  "common.pcs": "kpl",
  "common.pcsValue": "{pcs} kpl",
  "common.percentageValue": "{percentage} %",
  "common.all": "Kaikki",

  "common.month.january": "Tammikuu",
  "common.month.february": "Helmikuu",
  "common.month.march": "Maaliskuu",
  "common.month.april": "Huhtikuu",
  "common.month.may": "Toukokuu",
  "common.month.june": "Kesäkuu",
  "common.month.july": "Heinäkuu",
  "common.month.august": "Elokuu",
  "common.month.september": "Syyskuu",
  "common.month.october": "Lokakuu",
  "common.month.november": "Marraskuu",
  "common.month.december": "Joulukuu",

  "common.abbreviation.years": "v",
  "common.abbreviation.months": "kk",
  "common.abbreviation.days": "p",
  "common.abbreviation.hours": "h",
  "common.abbreviation.minutes": "min",
  "common.abbreviation.seconds": "s",

  "errorBoundary.fallbackComponent.content": "Jokin meni pieleen ja virhe on kirjattu järjestelmään.",
  "errorBoundary.dialog.title": "Hups!",
  "errorBoundary.dialog.subtitle": "Jokin meni pieleen ja virhe on kirjattu järjestelmään.",
  "errorBoundary.dialog.subtitle2": "Mikäli haluat auttaa meitä parantamaan järjestelmäämme, voit lähettää virheraportin alla olevasta painikkeesta.",
  "errorBoundary.dialog.successMessage": "Kiitos palautteestasi!",
  "errorBoundary.dialog.labelClose": "Sulje",
  "errorBoundary.dialog.labelSubmit": "Lähetä",

  "menu.header.operator": "Operaattori",
  "menu.header.superoperator": "Superoperaattori",
  "menu.locations": "Kohteet",
  "menu.locations.locations": "Kaikki kohteet",
  "menu.locations.potentiallyIncorrect": "Rekisteritunnistuksen tarkistus",
  "menu.customers": "Asiakkaat",
  "menu.customers.userGroups": "Käyttäjäryhmät",
  "menu.customers.licensePlateRecognition": "Hallipysäköinnin tila",
  "menu.services": "Pysäköintituotteet",
  "menu.vouchers": "Pysäköintiedut",
  "menu.vouchers.voucherTemplates": "Pysäköintiedut",
  "menu.vouchers.grantedVouchers": "Myönnetyt pysäköintiedut",
  "menu.finance": "Talous",
  "menu.finance.webPayment": "Verkkomaksut",
  "menu.finance.invoices": "Laskut",
  "menu.finance.reports": "Raportit",
  "menu.finance.settlementRecipients": "Maksunsaajat",
  "menu.finance.moneyTransfer": "Tilienväliset siirrot",
  "menu.finance.blocklistCandidates": "Veloittamattomien lista",
  "menu.finance.creditCoverCreditLoss": "Luottotappioiden kattaminen",
  "menu.messageCenter": "Viestikeskus",
  "menu.management": "Hallinta",
  "menu.management.newLocation": "Luo uusi kohde",
  "menu.management.newService": "Luo uusi tuote",
  "menu.charging": "Lataus",

  "enum.messageCenter.targetType.groups": "Ryhmät",
  "enum.messageCenter.targetType.phonenumbers": "Puhelinnumerot",
  "enum.messageCenter.messageStatus.draft": "Luonnos",
  "enum.messageCenter.messageStatus.published": "Julkaistu",
  "enum.messageCenter.callToActionType.internal": "Sisäinen",
  "enum.messageCenter.callToActionType.external": "Ulkoinen",
  "enum.messageCenter.callToActionInternalType.subscription": "Tilaus",
  "enum.messageCenter.callToActionInternalType.shop": "Kauppa",
  "enum.messageCenter.callToActionInternalType.vouchers": "Etu",
  "enum.messageCenter.targetGroup.category.type.parking": "Pysäköinti",
  "enum.messageCenter.targetGroup.category.type.parkingLocationNameV1": "Kohde",
  "enum.messageCenter.targetGroup.category.type.subscription": "Sopimuspysäköinti kohteessa",
  "enum.messageCenter.targetGroup.category.type.personalSubscriptionOnLocationNameV1": "Henkilökohtainen sopimus kohteessa",
  "enum.messageCenter.targetGroup.category.type.enterpriseSubscriptionOnLocationNameV1": "Yrityssopimus kohteessa",

  "enum.webpaymentStatus.NONE": "-",
  "enum.webpaymentStatus.WEBPAYMENT_PENDING": "Odotetaan maksua",
  "enum.webpaymentStatus.WEBPAYMENT_SUCCESSFUL": "Maksu suoritettu",
  "enum.webpaymentStatus.WEBPAYMENT_FAILED": "Verkkomaksua ei saatu",
  "enum.webpaymentStatus.WEBPAYMENT_CANCELLED": "Maksu peruutettu",
  "enum.webpaymentStatus.MOVED_TO_DEBT_COLLECTION": "Siirretty perintään",
  "enum.webpaymentStatus.DEBT_COLLECTION_DONE": "Perintä valmis",
  "enum.webpaymentStatus.DEBT_COLLECTION_FAILED": "Perintä epäonnistui",
  "enum.webpaymentStatus.DEBT_COLLECTION_REVOKED": "Perintä peruttu",
  "enum.webpaymentStatus.DEBT_COLLECTION_REQUIRES_MANUAL_STEP": "Manuaalitarkastus ennen perintää",
  "enum.webpaymentStatus.WEBPAYMENT_ERROR": "Virhe maksupalvelussa",

  "enum.location.zone": "Vyöhyke",
  "enum.location.facility": "Laitos",
  "enum.location.lot": "Alue",

  "enum.parkingServiceType.SUBSCRIPTION": "Tilaustuote",
  "enum.parkingServiceType.PAY_AS_YOU_GO": "Lyhytaikapysäköintituote",

  "enum.paymentMethodType.NO_PAYMENT_EXPECTED": "Ei maksettavaa",

  "enum.paymentCardCategory.PRIVATE": "Henkilökohtainen",
  "enum.paymentCardCategory.BUSINESS": "Yritys",

  "enum.invoicingStatus.INVOICED": "Maksettu",
  "enum.invoicingStatus.NOT_INVOICED": "Maksamatta",
  "enum.invoicingStatus.PAYMENT_FAILED": "Laskutus epäonnistui",
  "enum.invoicingStatus.PERMANENTLY_FAILED": "Ei pystytty laskuttamaan",
  "enum.invoicingStatus.IN_PROGRESS": "Laskutettu",
  "enum.invoicingStatus.PAUSED": "Pysäytetty",
  "enum.invoicingStatus.REFUNDED": "Palautettu",
  "enum.invoicingStatus.WAIVED": "Aiheeton",
  "enum.invoicingStatus.CHARGEBACK": "Chargeback",

  "enum.invoicingVismaStatus.INVOICED": "Maksettu",
  "enum.invoicingVismaStatus.NOT_INVOICED": "Maksamatta",
  "enum.invoicingVismaStatus.PAYMENT_FAILED": "Laskutus epäonnistui",
  "enum.invoicingVismaStatus.PERMANENTLY_FAILED": "Ei pystytty laskuttamaan",
  "enum.invoicingVismaStatus.IN_PROGRESS": "Laskutettu",
  "enum.invoicingVismaStatus.PAUSED": "Pysäytetty",
  "enum.invoicingVismaStatus.REFUNDED": "Palautettu",

  "enum.invoicingCardPaymentStatus.INVOICED": "Maksettu",
  "enum.invoicingCardPaymentStatus.NOT_INVOICED": "Ei veloitettu",
  "enum.invoicingCardPaymentStatus.PAYMENT_FAILED": "Veloitus epäonnistui",
  "enum.invoicingCardPaymentStatus.PERMANENTLY_FAILED": "Pysyvästi epäonnistunut",
  "enum.invoicingCardPaymentStatus.IN_PROGRESS": "Veloituksessa",
  "enum.invoicingCardPaymentStatus.REFUNDED": "Palautettu",
  "enum.invoicingCardPaymentStatus.WAIVED": "Aiheeton lasku",
  "enum.invoicingCardPaymentStatus.CHARGEBACK": "Chargeback",

  "enum.ultimateBeneficialOwnerReason.DUE_TO_OWNERSHIP": "Pääasiallinen omistaja",
  "enum.ultimateBeneficialOwnerReason.DUE_TO_ACTUAL_CONTROL": "Määräysvalta yrityksessä",
  "enum.ultimateBeneficialOwnerReason.DUE_TO_SIGNATORY": "Nimenkirjoitusoikeudellinen",

  "enum.parkingTypeEnum.MOOVY_PARKING": "Moovy pysäköinti",
  "enum.parkingTypeEnum.ANONYMOUS_PARKING": "Anonyymi pysäköinti",

  "enum.frontendParkingTypeEnum.ALL": "Kaikki",
  "enum.frontendParkingTypeEnum.MOOVY_PARKING": "Moovy pysäköinti",
  "enum.frontendParkingTypeEnum.ANONYMOUS_PARKING": "Anonyymi pysäköinti",
  "enum.frontendParkingTypeEnum.PARKMAN_PARKING": "ParkMan pysäköinti",
  "enum.frontendParkingTypeEnum.EASYPARK_PARKING": "EasyPark pysäköinti",

  "enum.settlementRecipientCompanyTypeEnum.BUSINESS": "Yritys",
  "enum.settlementRecipientCompanyTypeEnum.PUBLIC_COMPANY": "Julkinen yritys",
  "enum.settlementRecipientCompanyTypeEnum.NON_PROFIT_COMPANY": "Voittoa tavoittelematon",
  "enum.settlementRecipientCompanyTypeEnum.NOT_DEFINED": "-",

  "enum.adyenAccountHolderTopMainStatusEnum.NONE": "-",
  "enum.adyenAccountHolderTopMainStatusEnum.PROGRESS": "Käynnissä",
  "enum.adyenAccountHolderTopMainStatusEnum.FINISHED": "Valmis",
  "enum.adyenAccountHolderTopMainStatusEnum.FAILURE": "Virhe",

  "enum.vismaRoleEnum.DEBT_COLLECTION": "Perintä",
  "enum.vismaRoleEnum.INVOICING": "Laskutus",

  "enum.adyenAccountHolderMainStatusEnum.NOT_CREATED": "Ei lähetetty Adyenille",
  "enum.adyenAccountHolderMainStatusEnum.CREATION_IN_PROGRESS": "Luontipyyntö lähetetty Adyenille",
  "enum.adyenAccountHolderMainStatusEnum.CREATION_FAILED": "Luonti epäonnistui. Yritä luoda tilinhaltija uudestaan.",
  "enum.adyenAccountHolderMainStatusEnum.CREATED": "Adyen kuittasi luontipyynnön",
  "enum.adyenAccountHolderMainStatusEnum.ACTIVE": "Tilinhaltija ja tili ovat aktiivisia, mutta maksuja ei voi vielä suorittaa",
  "enum.adyenAccountHolderMainStatusEnum.PAYOUTS_ALLOWED": "Tilihaltija ja tili ovat valmiina maksusuorituksille.",
  "enum.adyenAccountHolderMainStatusEnum.INACTIVE": "KYC prosessi on kesken. Korjaa tilanne Adyenin käyttöliittymästä.",
  "enum.adyenAccountHolderMainStatusEnum.SUSPENDED": "KYC prosessi on keskeytetty. Korjaa tilanne Adyenin käyttöliittymästä.",
  "enum.adyenAccountHolderMainStatusEnum.CLOSED": "Tilinhaltija on suljettu ja sitä ei voi enää käyttää. Luo uusi tilinhaltija.",

  "enum.chargerType.EV_CHARGER_CHADEMO": "CHAdeMO",
  "enum.chargerType.EV_CHARGER_CCS": "CCS",
  "enum.chargerType.EV_CHARGER_SCHUKO": "Schuko",
  "enum.chargerType.EV_CHARGER_TYPE1": "Type 1",
  "enum.chargerType.EV_CHARGER_TYPE2": "Type 2",

  "enum.chargingStatus.PREPARING_CHARGING": "Valmistellaan",
  "enum.chargingStatus.CHARGING": "Ladataan",
  "enum.chargingStatus.CHARGING_SUSPENDED": "Keskeytetty",
  "enum.chargingStatus.CHARGING_ENDED": "Lopetettu",
  "enum.chargingStatus.CHARGING_ENDED_WITH_TIMEOUT": "Lataus loppui, koska kaapeli irti",
  "enum.chargingStatus.CHARGING_LIMIT_REACHED": "Ennakkoon asetettu rahasumma on käytetty",
  "enum.chargingStatus.PREPARING_TERMINATION": "Lataus on loppumassa, irrota kaapeli",
  "enum.chargingStatus.ERROR": "Virhe",
  "enum.chargingStatus.UNKNOWN": "Tuntematon tila",

  "enum.paymentMethod.VISMA": "Visma",
  "enum.paymentMethod.PAYMENT_HIGHWAY": "Payment Highway",
  "enum.paymentMethod.WEB_PAYMENT": "Verkkomaksu",
  "enum.paymentMethod.ADYEN": "Adyen",
  "enum.paymentMethod.INFODATA": "Infodata",
  "enum.paymentMethod.NO_PAYMENT_EXPECTED": "Ei maksettavaa",
  "enum.paymentMethod.PARKMAN": "ParkMan",
  "enum.paymentMethod.EASYPARK": "EasyPark",

  "enum.moneyTransferType.DEBIT_MARKETPLACE_FEE": "Palvelumaksu Moovy Platformin käytöstä",
  "enum.moneyTransferType.DEBIT_SERVICE_FEE": "Tilityspalkkio",
  "enum.moneyTransferType.DEBIT_SALE_CANCELLATION": "Myynnin mitätöintiin liittyvä siirto",
  "enum.moneyTransferType.DEBIT_CHARGEBACK": "Chargebackin veloittaminen maksusaajalta",
  "enum.moneyTransferType.DEBIT_CHARGEBACK_FEE": "Chargebackin liittyvän maksun veloittaminen maksusaajalta",
  "enum.moneyTransferType.DEBIT_TOPPED_UP_FUNDS_BACK": "Maksusaajan negatiivisen saldon kattamiseksi tehdyn siirron takaisin siirto.",
  "enum.moneyTransferType.DEBIT_REFUNDS_FUNDS_TRANSFER": "Mikäli tilien välisessä siirrossa tapahtuu virhe, esim. luottotappion kattamisessa katetaan väärä summa, niin voidaan suorittaa takaisin siirto.",
  "enum.moneyTransferType.DEBIT_BACK_EARLY_PAYOUTED_FUNDS": "Tämä on käänteinen operaatio koodille: CREDIT_EARLY_PAYOUT_FUNDS ja se suoritetaan, kun puuttuneet veloitukset on saatu asiakkaalta.",
  "enum.moneyTransferType.DEBIT_REFUNDS_DONE_PH": "PH palautuksen veloittaminen maksusaajan tililtä",
  "enum.moneyTransferType.DEBIT_CREDIT_LOSS_RETURN": "Palautuneen luottotappion siirto maksunsaajalta platformille",
  "enum.moneyTransferType.CREDIT_SUB_MERCHANT_BONUS": "Rojaltin maksu maksusaajalle",
  "enum.moneyTransferType.CREDIT_COVER_CREDIT_LOSS": "Luottotappion kattaminen maksusaajalle",
  "enum.moneyTransferType.CREDIT_AFTER_REACTIVATION": "Suoritettujen veloitusten siirto maksusaajalle, joka on aktivoitu vasta veloitusten jälkeen.",
  "enum.moneyTransferType.CREDIT_DISCOUNT": "Moovy alennuksen kompensointi maksusaajalle.",
  "enum.moneyTransferType.CREDIT_ACCOUNT_CREATION": "Suoritettujen veloitusten siirto maksusaajalle, joka ei vielä oltu luotu.",
  "enum.moneyTransferType.CREDIT_PAYMENT_FAILURE": "Käytetään silloin, kun saadaan PAYMENT_FAILURE notifikaatti, jossa jostain syystä ei voitu laittaa maksunsaajan osuutta submerchantin tilille ja tällöin siirrettiinkin platformin tilille.",
  "enum.moneyTransferType.CREDIT_EARLY_PAYOUT_FUNDS": "Käytetään silloin, kun rahojen tilitystä vaaditaan ennen kuin 3 kertaa on yritetty veloittaa. Siirrä tulevassa olevia suorituksia vastaava summa alakauppiaan tilille ja veloita takaisin kun rahat on saatu veloitettu asiakkaalta.",
  "enum.moneyTransferType.CREDIT_PAYOUT_FAILURE": "Liikaa maksetun tilityksen palautus",
  "enum.moneyTransferType.CREDIT_TOPPED_UP_FUNDS": "Maksunsaajan negatiivisen saldon kattamiseksi tehty siirto / laina.",

  "enum.userBlockingHistoryType.BLOCKED": "Estolista",
  "enum.userBlockingHistoryType.BLOCKING_PREVENTED": "Estolistan esto",

  "enum.adyenReportProfileTypeEnum.NORMAL": "Normaalit raportit",
  "enum.adyenReportProfileTypeEnum.ONLY_CLOSING": "Pelkkä CLOC-tilitysraportti",

  "enum.adyenAccountBpStatus.INITIALIZED": "Onboardaus kesken",
  "enum.adyenAccountBpStatus.KYC_DONE": "KYC valmis",
  "enum.adyenAccountBpStatus.ACTIVE": "Valmis",

  "enum.vismaStatus.NOT_SENT": "Odottaa lähettämistä",
  "enum.vismaStatus.COLLECTING": "Laskutettu (lähetetty Vismalle laskutukseen onnistuneesti)",
  "enum.vismaStatus.SUCCESSFUL": "Maksettu",
  "enum.vismaStatus.FAILED": "Virhetila",
  "enum.vismaStatus.CANCELLED": "Peruttu",
  "enum.vismaStatus.SUSPENDED": "Pysäytetty",
  "enum.vismaStatus.COLLECTION_PROHIBITION_ON": "Perintäestossa",
  "enum.vismaStatus.PENDING_CREDIT_NOTE_ORIGINAL_REFUND": "Hyvityslaskun luonti (1/3 vaihe)",
  "enum.vismaStatus.PENDING_CREDIT_NOTE": "Kutsutaan Vismaa (2/3 vaihe)",
  "enum.vismaStatus.PENDING_CREDIT_NOTE_REFUND": "Hyvityslasku lähetetty Vismalle onnistuneesti (3/3 vaihe)",
  "enum.vismaStatus.WEB_PAYMENT_DEBT_COLLECTION_INITIATED": "Visman lasku luodaan Moovyn verkkomaksussa olleesta maksamattomasta pysäköinnistä, jota ei ole vielä lähetetty Vismalle perintää varten. Lähetys Vismalle pitäisi tapahtua heti perään, joten tätä tilaa käytännössä ei pitäisi näkyä käyttöliittymässä koskaan.",

  "customers.detail.toolbar.contact.title": "Yhteystiedot",
  "customers.detail.toolbar.contact.userIsBlocked": "Käyttäjä on estolistalla",
  "customers.detail.toolbar.contact.userIsNotBlocked": "Käyttäjä ei ole estolistalla",
  "customers.detail.toolbar.user.userDeleted": "Käyttäjä on poistettu järjestelmästä",
  "customers.detail.toolbar.user.requestedUserDeletion": "Pyydetty käyttäjätilin poistoa",
  "customers.detail.toolbar.user.blockingPrevented": "Estolistalle siirto estetty",
  "customers.detail.toolbar.user.cancelUserDeletion": "Peruuta poistopyyntö",

  "customers.detail.toolbar.vehicles.title": "Ajoneuvot",
  "customers.detail.toolbar.vehicles.link": "Näytä kaikki ajoneuvot",
  "customers.detail.toolbar.button.removeFromBlocklist": "Poista estolistalta",
  "customers.detail.toolbar.button.setToBlocklist": "Aseta estolistalle",
  "customers.detail.toolbar.button.editUser": "Muokkaa asiakastietoja",
  "customers.detail.toolbar.toast.edited.title": "Asiakastiedot muokattu",
  "customers.detail.toolbar.toast.edited.body": "Käyttäjän {name} tiedot on muokattu.",

  "customers.detail.toolbar.cancelDeletionRequest.action.success.title": "Poistopyyntö peruutettu",
  "customers.detail.toolbar.cancelDeletionRequest.action.success.description": "Käyttäjän tilinpoistopyyntö peruttu.",
  "customers.detail.toolbar.cancelDeletionRequest.action.error": "Käyttäjän tilinpoistopyynnön peruutus epäonnistui.",
  "customers.detail.toolbar.cancelDeletionRequest.confirmation.title": "Perutaanko käyttäjän {name} poistopyyntö?",
  "customers.detail.toolbar.cancelDeletionRequest.confirmation.body": "Haluatko varmasti peruuttaa käyttäjän poistopyynnön?",

  "customers.detail.tab.parkings": "Pysäköinnit",
  "customers.detail.tab.vouchers": "Pysäköintiedut",
  "customers.detail.tab.invoiceData": "Laskutustiedot",
  "customers.detail.tab.subscribedServices": "Tilatut tuotteet",
  "customers.detail.tab.userAssignments": "Käyttöoikeudet",
  "customers.detail.tab.paymentMethods": "Maksutavat",
  "customers.detail.tab.userGroups": "Käyttäjäryhmät",
  "customers.detail.tab.vehicles": "Ajoneuvot",
  "customers.detail.tab.conversationHistory": "Keskusteluhistoria",
  "customers.detail.tab.blockingList": "Estolista",
  "customers.detail.tab.vehicles.filter.showDeletedVehicles": "Näytä myös poistetut ajoneuvot",
  "customers.detail.tab.vehicles.filter.licensePlateNumber": "Rekisteritunnus",
  "customers.detail.tab.vehicles.filter.licensePlateNumber.placeholder": "Hae rekisteritunnuksella",
  "customers.detail.tab.vehicles.table.creationTime": "Rekisteritunnus lisätty",
  "customers.detail.tab.vehicles.table.deletionTime": "Rekisteritunnus poistettu",
  "customers.detail.tab.vehicles.table.link.history": "Historia",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.title": "Kameratunnistus rekisteritunnukselle {licensePlate}",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.body.inactive": "Haluatko varmasti poistaa kameratunnistuksen pois käytöstä?",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.body.active": "Haluatko varmasti asettaa kameratunnistuksen käyttöön?",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.success.title": "Tila vaihdettu",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.success.body": "Kameratunnistuksen tila vaihdettu.",
  "customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.error": "Kameratunnistuksen tilan vaihtaminen ei onnistunut",

  "customers.detail.tab.paymentMethods.popup.paymentCardMissing": "Maksukortti puuttuu.",
  "customers.detail.tab.paymentMethods.popup.paymentCardExpired": "Maksukortti on vanhentunut.",
  "customers.detail.tab.paymentMethods.popup.defaultCardMissing": "Oletuskorttia ei ole määritetty.",

  "modalConfirmDeletePaymentCard.modal.title": "Käyttäjän maksukortin poisto",
  "modalConfirmDeletePaymentCard.action.error.common": "Maksukortin poisto epäonnistui",
  "modalConfirmDeletePaymentCard.delete.confirm": "Haluatko varmasti poistaa maksukortin {card} käyttäjältä {name}?",
  "modalConfirmDeletePaymentCard.form.note": "Huom!",
  "modalConfirmDeletePaymentCard.form.defaultPaymentCard": "Tämä on oletusmaksukortti.",
  "modalConfirmDeletePaymentCard.form.defaultPaymentCard.addCard": "Pysäköintejä ei voi aloittaa Moovy-tilillä ilman oletuskorttia. Pyydä asiakasta lisäämään uusi oletusmaksukortti.",
  "modalConfirmDeletePaymentCard.form.defaultPaymentCard.addOrSetCard": "Pysäköintejä ei voi aloittaa Moovy-tilillä ilman oletuskorttia. Pyydä asiakasta asettamaan jäljelle jäävä maksukortti oletuskortiksi tai lisäämään uusi oletusmaksukortti.",
  "modalConfirmDeletePaymentCard.form.lastPaymentCard.title": "Olet poistamassa viimeistä maksukorttia.",
  "modalConfirmDeletePaymentCard.form.lastPaymentCard.desc": "Pyydä asiakasta lisäämään uusi maksukortti. Uusi maksukortti tulee automaattisesti oletusmaksukortiksi.",
  "modalConfirmDeletePaymentCard.form.forceRemoving": "Maksukortin voi poistaa pakotetusti. Huomioi tässä tapauksessa, että asiakas ja maksunsaaja ovat tietoisia mahdollisista sivuvaikutuksista.",
  "modalConfirmDeletePaymentCard.form.checkbox.forceRemoving": "Poista maksukortti pakotetusti",

  "customers.detail.tab.userBlockingPreventedList.title": "Käyttäjän estolistan historia",
  "customers.detail.tab.userBlockingPreventedList.button.unblock": "Salli estolistalle siirto",
  "customers.detail.tab.userBlockingPreventedList.button.block": "Estä käyttäjän siirto estolistalle",

  "parkingModal.fetchError.title": "Virhe",
  "parkingModal.fetchError.description": "Pysäköinnin lataamisessa tapahtui virhe",

  "parkingModal.label.location": "Kohde",
  "parkingModal.label.customer": "Asiakas",
  "parkingModal.label.licensePlateNumber": "Rekisteritunnus",
  "parkingModal.label.started": "Alkoi",
  "parkingModal.label.ended": "Loppui",
  "parkingModal.label.ongoing": "Käynnissä",
  "parkingModal.label.duration": "Kesto",
  "parkingModal.label.price": "Maksettu",
  "parkingModal.label.priceLeft": "Maksettavaa jäljellä",
  "parkingModal.label.priceLeft.popup.title": "Summa voi olla virheellinen seuraavista syistä:",
  "parkingModal.label.priceLeft.popup.bullet1": "Laskenta ei ota huomioon ilmaista poistumisaikaa maksupäätteellä maksun jälkeen.",
  "parkingModal.label.priceLeft.popup.bullet2": "Laskenta ei ota huomioon kertakäyttöistä pysäköintietua.",
  "parkingModal.label.sessionId": "TapahtumaId",
  "parkingModal.label.exitBefore": "Poistuttava ennen",

  "parkingModal.parkingSales.filter.title": "Näytä",
  "parkingModal.parkingSales.filter.all": "Kaikki",
  "parkingModal.parkingSales.filter.onlyValid": "Vain validit",
  "parkingModal.parkingSales.table.header.time": "Aika",
  "parkingModal.parkingSales.table.header.price": "Hinta",
  "parkingModal.parkingSales.table.header.totalPrice": "Yhteensä",
  "parkingModal.parkingSales.table.header.reductions": "Alennukset",
  "parkingModal.parkingSales.table.header.type": "Tyyppi",
  "parkingModal.parkingSales.table.header.paymentMethod": "Maksutapa",
  "parkingModal.parkingSales.table.header.final": "Lopullinen",
  "parkingModal.parkingSales.table.header.valid": "Validi",

  "modalWebPaymentSaleStatus.button.cancelPayment": "Peruuta verkkomaksu",
  "modalWebPaymentSaleStatus.cancelPayment.comment": "Peruutuksen syy",
  "modalWebPaymentSaleStatus.title": "Verkkomaksu",
  "modalWebPaymentSaleStatus.header.status": "Tila",
  "modalWebPaymentSaleStatus.header.cancellationReason": "Peruutuksen syy",
  "modalWebPaymentSaleStatus.item.notSent": "Ei lähetetty",
  "modalWebPaymentSaleStatus.item.notProcessed": "Ei käsitelty",
  "modalWebPaymentSaleStatus.header.sentToService": "Lähetetty verkkomaksupalveluun",
  "modalWebPaymentSaleStatus.header.handledInService": "Käsitelty verkkopalvelussa",
  "modalWebPaymentSaleStatus.header.invoiceNumber": "Laskun numero",
  "modalWebPaymentSaleStatus.operation.cancel.successfull": "Verkkomaksu peruutettu onnistuneesti",
  "modalWebPaymentSaleStatus.operation.cancel.failed": "Verkkomaksun peruuttaminen epäonnistui.",
  "modalWebPaymentSaleStatus.notFound": "Verkkomaksutapahtumaa ei ole vielä luotu",

  "parkingTab.button.startParking": "Aloita pysäköinti",

  "parkingFilters.form.label.type": "Tyyppi",
  "parkingFilters.form.label.type.options.MOOVY_PARKING": "Moovy pysäköinti",
  "parkingFilters.form.label.type.options.ANONYMOUS_PARKING": "Anonyymi pysäköinti",
  "parkingFilters.form.label.status": "Status",
  "parkingFilters.form.label.status.options.ALL": "Kaikki",
  "parkingFilters.form.label.status.options.ONGOING": "Käynnissä",
  "parkingFilters.form.label.status.options.ENDED": "Päättyneet",
  "parkingFilters.form.label.parkingMethod": "Pysäköintitapa",
  "parkingFilters.form.label.parkingMethod.options.ALL": "Kaikki",
  "parkingFilters.form.label.parkingMethod.options.SUBSCRIPTION": "Sopimus",
  "parkingFilters.form.label.parkingMethod.options.SHORT_TERM": "Lyhytaikainen",
  "parkingFilters.form.label.licensePlate": "Rekisteritunnus",

  "listParkings.header.allEvents": "KAIKKI TAPAHTUMAT:",
  "listParkings.header.countAllEvent": "tapahtuma",
  "listParkings.header.countAllEvents": "tapahtumaa",
  "parkingTable.header.type": "Tyyppi",
  "parkingTable.header.location": "Kohde",
  "parkingTable.header.city": "Kaupunki",
  "parkingTable.header.startTime": "Alkamisaika",
  "parkingTable.header.endTime": "Päättymisaika",
  "parkingTable.header.exitAllowedBefore": "Poistuttava viimeistään",
  "parkingTable.header.exitTimeExceeded": "Poistumisaika ylitetty",
  "parkingTable.header.duration": "Kesto",
  "parkingTable.header.totalDuration": "Kokonaiskesto",
  "parkingTable.header.licensePlateNumber": "Tunnus",
  "parkingTable.header.customer": "Asiakas",
  "parkingTable.header.price": "Hinta",
  "parkingTable.header.discount": "Alennus",
  "parkingTable.header.total": "Yhteensä",
  "parkingTable.header.paymentMethod": "Maksutapa",
  "parkingTable.header.parkingProduct": "Pysäköintitapa",
  "parkingTable.parkingProduct.subscription": "Sopimus",
  "parkingTable.parkingProduct.shortParking": "Lyhytaikainen",
  "parkingTable.parkingProduct.specialParking": "Erikoistuote",
  "parkingTable.endTime.ongoing": "Käynnissä",

  "parkingTable.endParking.toast.title": "Pysäköinnit päätetty",
  "parkingTable.endParking.toast.body": "Valitut pysäköinnity päätetty onnistuneesti",
  "parkingTable.endParking.banner.description": "Voit lopettaa käynnissä olevia anonyymipysäköintejä valitsemalla ne taulukosta.",
  "parkingTable.endParking.banner.button.markAnonymous": "Valitse anonyymipysäköinnit",
  "parkingTable.endParking.banner.button.endParking": "Lopeta valitut anonyymipysäköinnit",
  "parkingTable.endParking.modal.error.notValidParking": "Pysäköintejä ei voi päättää, koska osa valituista pysäköintitapahtumista ei ole anonyymipysäköintejä tai käynnissä olevia pysäköintejä.",
  "parkingTable.endParking.modal.error.notValidParking.header": "Virhe pysäköinnin lopettamisessa",
  "parkingTable.endParking.modal.button.endParking": "Lopeta pysäköinnit",

  "endParkingModal.header": "Lopeta valitut pysäköinnit ({total} kpl)",
  "endParkingModal.form.comment": "Kommentti",
  "endParkingModal.form.comment.placeholder": "Voit lisätä muutokseen liittyvän kommentin",
  "endParkingModal.action.error.ending": "Pysäköintien lopettamisessa tapahtui virhe.",

  "parkingTable.editLicensePlate.toast.title": "Päivitetty",
  "parkingTable.editLicensePlate.toast.body": "Rekisteritunnus päivitetty",
  "parkingTable.editLicensePlate.modal.header": "Vaihda rekisteritunnusta",
  "parkingTable.editLicensePlate.modal.error.editing": "Rekisteritunnuksen vaihto epäonnistui.",
  "parkingTable.editLicensePlate.modal.licensePlate.label": "Rekisteritunnus",
  "parkingTable.editLicensePlate.modal.comment.label": "Kommentti",
  "parkingTable.confirm.moovyParking": "Rekisteritunnuksella löytyy Moovy-tili. Pysäköintiä ei vaihdeta Moovy-pysäköinniksi vaan se jatkuu anonyyminä. Haluatko jatkaa?",

  "conversationHistory.action.addComment": "Lisää kommentti",
  "conversationHistory.action.addComment.error.title": "Lisäys epäonnistui",
  "conversationHistory.action.addComment.error.description": "Kommentin lisäys epäonnistui.",
  "conversationHistory.action.remove.error.title": "Poisto epäonnistui",
  "conversationHistory.action.remove.error.description": "Kommentin poistaminen epäonnistui.",
  "conversationHistory.confirm.remove": "Haluatko varmasti poistaa kommentin?",
  "conversationHistory.conversation.today": "Tänään",
  "conversationHistory.conversation.yesterday": "Eilen",

  "moovyActiveItem.labels.active": "Aktiivinen",
  "moovyActiveItem.labels.inactive": "Ei käytössä",
  "moovyActiveItem.confirmContent.active": "Oletko varma, että {name} asetetaan pois käytöstä?",
  "moovyActiveItem.confirmContent.inactive": "Oletko varma, että {name} vaihdetaan aktiiviseksi?",
  "moovyActiveItem.confirmContent.title": "Tilanvaihto",
  "moovyActiveItem.confirmContent.action.error": "Tilanvaihto epäonnistui.",

  "invoiceTable.header.invoiceNumber": "Laskun numero",
  "invoiceTable.header.creationTime": "Luontipäivä",
  "invoiceTable.header.status": "Tila",
  "invoiceTable.header.invoicingAttemptCount": "Veloitusyritykset",
  "invoiceTable.header.invoicingAttemptTime": "Viimeinen veloitusyritys",
  "invoiceTable.header.invoiceCount": "Laskurivit",
  "invoiceTable.header.totalPrice": "Summa",
  "invoiceTable.header.paymentMethod": "Maksutapa",
  "invoiceTable.header.expired": "Eräpäivä",
  "invoiceTable.header.additionalInfo": "Lisätieto",

  "customerInvoicing.operation.getInvoices.failed": "Laskujen lataamisessa tapahtui virhe",
  "customerInvoicing.card.header": "Erääntyneet, veloittamattomat laskut",
  "customerInvoicing.card.body": "{failedInvoicesCount} kpl, yhteensä ",

  "customerInvoicing.toolbarFilter.label.invoiceNumber": "Laskun numero",
  "customerInvoicing.toolbarFilter.label.invoiceSum": "Laskun summa",
  "customerInvoicing.toolbarFilter.label.invoiceStatus": "Laskun tila",
  "customerInvoicing.toolbarFilter.label.invoiceMethodType": "Laskutustapa",
  "customerInvoicing.toolbarFilter.button.createInvoice": "Luo uusi lasku",

  "compensationInvoiceColumnInfo.compensationVisma": "Hyvitys laskulle {invoiceName}",
  "compensationInvoiceColumnInfo.compensationOther": "Palautus laskulle {invoiceName}",

  "modalChangeUserBlockList.body.unsetFromBlocklist": "Oletko varma, että haluat poistaa käyttäjän {name} estolistalta?",
  "modalChangeUserBlockList.confirmation.title": "Estolistan tilanmuutos",
  "modalChangeUserBlockList.action.error.default": "Estolistan tilanmuutos käyttäjälle epäonnistui.",
  "modalChangeUserBlockList.toast.title.blocklisted": "Estolista",
  "modalChangeUserBlockList.toast.description.blocklisted": "Käyttäjä asetettu estolistalle",
  "modalChangeUserBlockList.toast.title.unblocklisted": "Estolista",
  "modalChangeUserBlockList.toast.description.unblocklisted": "Käyttäjä poistettu estolistalta",
  "modalChangeUserBlockList.addToBlocklist.header": "Aseta käyttäjä {name} estolistalle",
  "modalChangeUserBlockList.addToBlocklist.reason": "Syy",
  "modalChangeUserBlockList.addToBlocklist.reason.invoicing": "Epäonnistuneet veloitukset",
  "modalChangeUserBlockList.addToBlocklist.reason.other": "Muu syy",
  "modalChangeUserBlockList.addToBlocklist.comment": "Kommentti",
  "modalChangeUserBlockList.addToBlocklist.comment.required": "Kommentti on pakollinen kenttä",
  "modalChangeUserBlockList.removeBlocklist.title": "Poista käyttäjä {name} estolistalta",
  "modalChangeUserBlockList.removeBlocklist.warningMessage": "Käyttäjä on estolistalla muun syyn takia:",
  "modalChangeUserBlockList.removeBlocklist.confirmMessage": "Olen varmistanut, että käyttäjä voidaan poistaa estolistalta",

  "userGroups.toolbar.title": "Käyttäjäryhmät",
  "userGroups.toolbar.input.placeholder": "Käyttäjäryhmä tai operaattori",
  "userGroups.create.button": "Luo uusi käyttäjäryhmä",
  "userGroups.table.header.userGroup": "Käyttäjäryhmä",
  "userGroups.table.header.operator": "Operaattori",
  "userGroups.table.header.modificationTime": "Muokattu",
  "userGroups.table.body.operator.undefined": "Ei määritelty",

  "userGroup.query.error": "Käyttäjäryhmän lataamisessa tapahtui virhe",
  "userGroup.toolbar.ref": "Tunniste",
  "userGroup.toolbar.operator": "Operaattori",
  "userGroup.toolbar.operator.undefined": "Ei määritelty",
  "userGroup.toolbar.rename": "Muokkaa käyttäjäryhmän nimeä",
  "userGroup.toolbar.delete": "Poista käyttäjäryhmä",
  "userGroup.panes.user": "Käyttäjät",
  "userGroup.panes.serviceAgreements": "Palvelusopimukset",
  "userGroup.panes.voucherGrantAgreements": "Etusopimukset",

  "createUserGroup.message.success": "Käyttäjäryhmä {groupName} luotu",
  "createUserGroup.message.error": "Käyttäjäryhmän luomisessa tapahtui virhe.",
  "createUserGroup.modal.header": "Luo uusi käyttäjäryhmä",
  "createUserGroup.modal.form.operator": "Operaattori",
  "createUserGroup.modal.form.operator.dropdown.placeholder": "Valitse operaattori",
  "createUserGroup.modal.form.groupName": "Käyttäjäryhmän nimi",

  "userGroupRenameModal.header": "Muokkaa käyttäjäryhmän nimeä",
  "userGroupRenameModal.instructions": "Aseta uusi nimi käyttäjäryhmälle ja paina Hyväksy.",
  "userGroupRenameModal.input.placeholder": "Käyttäjäryhmän nimi",
  "userGroupRenameModal.action.failed": "Käyttäjäryhmän nimen muokkaus epäonnistui.",
  "userGroupRenameModal.action.title": "Käyttäjäryhmän nimi vaihdettu",
  "userGroupRenameModal.action.description": "Nimi {name} vaihdettu.",

  "userGroupDeleteModal.header": "Poista \"{groupName}\" käyttäjäryhmä?",
  "userGroupDeleteModal.instructions": "Jos poistat ryhmän, sitä ei voi enää palauttaa takaisin.",
  "userGroupDeleteModal.error": "Käyttäjäryhmän poisto ei onnistunut",
  "userGroupDeleteModal.notAllowed.header": "Käyttäjäryhmän \"{groupName}\" poistoa ei voi tehdä",
  "userGroupDeleteModal.notAllowed.instructions": "Poista ryhmästä ensin riippuvuudet:",
  "userGroupDeleteModal.notAllowed.users": "Käyttäjät: {count} kpl",
  "userGroupDeleteModal.notAllowed.serviceAgreements": "Palvelusopimukset: {count} kpl",
  "userGroupDeleteModal.notAllowed.voucherGrantAgreements": "Etusopimukset: {count} kpl",

  "userGroupAddUserModal.header": "Lisää käyttäjä ryhmään \"{name}\"",
  "userGroupAddUserModal.search.placeholder": "Syötä käyttäjä",
  "userGroupAddUserModal.search.fetching": "Hetkinen...",
  "userGroupAddUserModal.error": "Käyttäjäryhmään lisäys ei onnistunut",

  "userGroupUsersTab.button.add": "Lisää uusi käyttäjä",
  "userGroupUsersTab.success": "Lisätty uusi käyttäjä",

  "userGroupMembershipsTable.header.name": "Nimi",
  "userGroupMembershipsTable.header.ref": "Käyttäjän tunniste",

  "editServiceAgreement.allServiceAgreements": "Kaikki palvelusopimukset",
  "editServiceAgreement.query.error": "Palvelusopimuksen lataamisessa tapahtui virhe",
  "editServiceAgreement.unsavedChanges": "Paina tallenna, jotta alennusten muutokset astuvat voimaan",
  "editServiceAgreement.form.capacity": "Tilauksia",
  "editServiceAgreement.form.percentDiscount": "Alennusprosentti",
  "editServiceAgreement.form.percentDiscount.notValid.message": "Syötä arvo väliltä 0 - 100",
  "editServiceAgreement.form.maxCount": "Käyttäjäkohtainen kiintiö",
  "editServiceAgreement.form.maxCount.placeholder": "Ei kiintiötä",
  "editServiceAgreement.submit.success": "Palvelusopimus päivitetty",
  "editServiceAgreement.submit.invalid": "Palvelusopimuksen validointi ei onnistunut, muutoksia ei tallennettu",
  "editServiceAgreement.submit.error": "Palvelusopimuksen päivitys ei onnistunut",
  "editServiceAgreement.table.header.capacity": "Tilauksia",
  "editServiceAgreement.table.header.percentDiscount": "Alennus",
  "editServiceAgreement.table.header.type": "Tyyppi",
  "editServiceAgreement.table.body.capacity.undefined": "Rajoittamaton",
  "editServiceAgreement.table.footer.new.discount": "Uusi alennus",

  "serviceAgreementsTab.button.new": "Lisää uusi palvelusopimus",
  "serviceAgreementsTab.query.error": "Palvelusopimusten lataamisessa tapahtui virhe",
  "serviceAgreementsTab.delete.error": "Palvelusopimuksen poistamisessa tapahtui virhe",

  "serviceAgreementTable.header.product": "Tuote",
  "serviceAgreementTable.header.maxCount": "Käyttäjäkohtainen kiintiö",
  "serviceAgreementTable.header.discounts": "Alennukset",
  "serviceAgreementTable.table.maxCount.undefined": "Ei kiintiötä",
  "serviceAgreementTable.table.discount.undefined": "Ei alennuksia",
  "serviceAgreementTable.table.delete.confirm": "Oletko varma, että haluat poistaa palvelusopimuksen tuotteelle {name}?",

  "addAgreementModal.header.serviceAgreement": "Lisää uusi palvelusopimus",
  "addAgreementModal.header.voucherAgreement": "Lisää uusi etusopimus",
  "addAgreementModal.errorMessage.serviceAgreement": "Palvelusopimuksen lisäämisessä tapahtui virhe",
  "addAgreementModal.errorMessage.voucherAgreement": "Pysäköintietusopimuksen lisäämisessä tapahtui virhe",
  "addAgreementModal.placeholder.serviceAgreement": "Kirjoita hakeaksesi tuotteita",
  "addAgreementModal.placeholder.voucherAgreement": "Kirjoita hakeaksesi pysäköintietuja",

  "voucherGrantAgreementsTab.button.add": "Lisää uusi pysäköintietusopimus",
  "voucherGrantAgreementsTab.query.error": "Pysäköintietusopimusten lataamisessa tapahtui virhe",
  "voucherGrantAgreementsTab.delete.confirm": "Oletko varma, että haluat poistaa pysäköintietusopimuksen \"{name}\"?",
  "voucherGrantAgreementsTab.delete.error": "Pysäköintietusopimuksen poistamisessa tapahtui virhe",
  "voucherGrantAgreementsTab.table.header.voucher": "Pysäköintietu",

  "subscriptionPricingProfile.itemTypes.CONSTANT_PERCENTAGE_DISCOUNT": "Vakio",
  "subscriptionPricingProfile.itemTypes.MASS_PERCENTAGE_DISCOUNT": "Määrä",

  "enterpriseCustomer.toolbar.button.editUser": "Muokkaa asiakastietoja",
  "enterpriseCustomer.toolbar.button.editInvoicing": "Muokkaa laskutustietoja",
  "enterpriseCustomer.toolbar.title": "Yritysasiakastiedot",
  "enterpriseCustomer.toolbar.inactive": "Käyttäjä on poistettu järjestelmästä",
  "enterpriseCustomer.toolbar.keycloak.username": "Käyttäjätunnus",
  "enterpriseCustomer.toolbar.keycloak.email": "Rekisteröity sähköpostiosoite",
  "enterpriseCustomer.toolbar.contactPerson": "Yhteyshenkilö",
  "enterpriseCustomer.toolbar.invoicing": "Laskutustiedot",

  "enterpriseCustomer.detail.tab.userAssignments": "Käyttöoikeudet",
  "enterpriseCustomer.detail.tab.orderedProducts": "Tilatut tuotteet",
  "enterpriseCustomer.detail.tab.invoiceData": "Laskutustiedot",
  "enterpriseCustomer.detail.tab.grantedVouchers": "Pysäköintiedut",
  "enterpriseCustomer.detail.tab.userGroups": "Käyttäjäryhmät",
  "enterpriseCustomer.detail.tab.conversationHistory": "Keskusteluhistoria",

  "enterpriseCustomer.invoicing.ovt": "OVT-tunnus",
  "enterpriseCustomer.invoicing.operator": "Välittäjätunnus",
  "enterpriseCustomer.invoicing.email": "Sähköpostiosoite",
  "enterpriseCustomer.invoicing.address": "Laskutusosoite",
  "enterpriseCustomer.invoicing.type": "Tyyppi",
  "enterpriseCustomer.invoicing.type.PAPER": "Paperilasku",
  "enterpriseCustomer.invoicing.type.EMAIL": "Sähköpostilasku",
  "enterpriseCustomer.invoicing.type.DIRECT": "Sähköinen verkkolasku",
  "enterpriseCustomer.invoicing.customReference": "Viite",

  "enterpriseCustomer.editInvoicing.header": "Yritysasiakkaan {company} laskutustietojen muokkaus",
  "enterpriseCustomer.editInvoicing.paper.info": "Laskutusosoite koostetaan yritystilikäyttäjän tiedoista. Laskutusosoitetta voi yrityskäyttäjä itse muuttaa yritystilin kautta.",
  "enterpriseCustomer.editInvoicing.email.info": "Sähköpostiosoite on yritystilikäyttäjän sähköpostiosoite. Sähköpostiosoitetta voi yritystilikäyttäjä itse muuttaa yritystilin kautta.",
  "enterpriseCustomer.editInvoicing.mutation.error": "Laskutustietojen päivitys epäonnistui",
  "enterpriseCustomer.editInvoicing.toast.success.title": "Laskutustiedot päivitetty",
  "enterpriseCustomer.editInvoicing.toast.success.body": "Yritysasiakkaan laskutustietojen muutokset tallennettu.",

  "grantedVouchers.query.error": "Virhe tietojen lataamisessa",
  "grantedVouchers.filter.vouchers": "Valitse pysäköintietu",
  "grantedVouchers.header": "Annetut pysäköintiedut",
  "grantedVouchers.table.header.date": "Päivämäärä",
  "grantedVouchers.table.header.licensePlateNumber": "Rekisteritunnus",
  "grantedVouchers.table.header.phoneNumber": "Puhelinnumero",
  "grantedVouchers.table.header.voucher": "Etu",
  "grantedVouchers.table.header.price": "Hinta",
  "grantedVouchers.card.header.altogether": "Yhteensä",
  "grantedVouchers.card.item.givenVoucher": "Etuja annettu",
  "grantedVouchers.card.item.pcs": "kpl",
  "grantedVouchers.card.item.totalCost": "Kustannus edun antajalle",

  "blockList.toolbar.title": "Estolista",
  "blockList.toolbar.input.placeholder": "Nimi tai puhelinnumero",
  "blockList.table.header.status": "Estolistalla",
  "blockList.mutation.error": "Estolistauksen asettamisessa tapahtui virhe",
  "blockList.modal.userIsBlocked": "Käyttäjä on estolistalla",
  "blockList.modal.userIsNotBlocked": "Käyttäjä ei ole estolistalla",

  "parkingModal.tab.parkingSequence": "Kokonaispysäköinti",
  "parkingModal.tab.sales": "Myyntitapahtumat",
  "parkingModal.tab.vouchers": "Käytetyt pysäköintiedut",
  "parkingModal.tab.paymentEvents": "Maksutapahtumat",
  "parkingModal.tab.auditLogs": "Muutosloki",
  "parkingModal.tab.externalFacilityData": "Kameratunnistus",
  "parkingModal.tab.externalFacilityDataContainer.imageLoadingError": "Kameratunnistuksen tietojen hakeminen ei onnistunut",

  "parkingService.toolbar.title": "Pysäköintituotteet",
  "parkingService.toolbar.filter.search.placeholder": "Hae pysäköintituotteita",
  "parkingService.toolbar.filter.operator.placeholder": "Valitse operaattori",
  "parkingService.toolbar.filter.service.placeholder": "Valitse pysäköintituote",
  "parkingService.toolbar.filter.error": "Syötä vähintään kolme merkkiä.",
  "parkingService.header.service": "Tuote",
  "parkingService.header.type": "Tyyppi",
  "parkingService.header.vehicleAccessPriority": "Prioriteetti",
  "parkingService.header.popup.vehicleAccessPriority": "Sopimuspysäköinti aloitetaan tuotteella, jolla on korkein prioriteetti, jos asiakkaalla on useampi tuote voimassa samassa kohteessa.",
  "parkingService.header.operator": "Operaattori",
  "parkingService.location.button.editPriority": "Muokkaa prioriteettia",
  "parkingService.location.action.updateServicePriority.success.title": "Prioriteetti päivitetty",
  "parkingService.location.action.updateServicePriority.success.desc": "Tuotteen prioriteetti päivitetty",
  "parkingService.location.action.updateServicePriority.failed.title": "Päivitys epäonnistui",
  "parkingService.location.action.updateServicePriority.failed.desc": "Tuotteen prioriteetin päivitys epäonnistui.",

  "parkingServiceDetails.toolbar.button.editCapacity.tooltip": "Muokkaa pysäköintituotteen kapasiteetteja",
  "parkingServiceDetails.toolbar.button.editName.tooltip": "Muokkaa pysäköintituotteen nimeä",
  "parkingServiceDetails.toolbar.button.editVisibility.tooltip": "Muokkaa pysäköintituotteen näkyvyyttä",
  "parkingServiceDetails.toolbar.button.editOverbooking.tooltip": "Muokkaa ylibuukkauskerrointa",

  "parkingServiceDetails.subscriptionServiceToolbar.label.type": "Tuotteen tyyppi:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.visibility": "Tuotteen näkyvyys:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.overbookingCoefficient": "Ylibuukkauskerroin:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.serviceOrderedCount": "Tuotetta tilattu:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.parkingCapacity": "Pysäköintikapasiteetti yhtäaikaisille pysäköinneille:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.allowParkingOvercapacity": "Salli pysäköintikapasiteetin ylittäminen:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.description": "Tuotteen kuvaus:",
  "parkingServiceDetails.subscriptionServiceToolbar.link.description": "Näytä kuvaus",
  "parkingServiceDetails.subscriptionServiceToolbar.label.parkingFeeSettlementRecipient": "Pysäköintimaksujen maksunsaaja:",
  "parkingServiceDetails.subscriptionServiceToolbar.label.periodFeeSettlementRecipient": "Kuukausimaksujen maksunsaaja:",

  "parkingServiceDetails.payAsYouGoServiceToolbar.label.type": "Tuotteen tyyppi:",
  "parkingServiceDetails.payAsYouGoServiceToolbar.label.visibility": "Tuotteen näkyvyys:",
  "parkingServiceDetails.payAsYouGoServiceToolbar.label.parkingCapacity": "Pysäköintikapasiteetti yhtäaikaisille pysäköinneille:",
  "parkingServiceDetails.payAsYouGoServiceToolbar.label.allowParkingOvercapacity": "Salli pysäköintikapasiteetin ylittäminen:",

  "parkingServiceDetails.tab.subscribedServices.title": "Aktiiviset tilausasiakkaat",
  "parkingServiceDetails.tab.subscribedServices.table.filter.search.placeholder": "Hae tilaajia...",
  "parkingServiceDetails.tab.subscribedServices.table.header.owner": "Tilaaja",
  "parkingServiceDetails.tab.subscribedServices.table.header.type": "Tyyppi",
  "parkingServiceDetails.tab.subscribedServices.table.header.phone": "Puhelinnumero",
  "parkingServiceDetails.tab.subscribedServices.table.header.email": "Sähköpostiosoite",
  "parkingServiceDetails.tab.subscribedServices.table.header.vehicles": "Aktiiviset ajoneuvot",
  "parkingServiceDetails.tab.subscribedServices.table.header.subscriptions": "Tilauksia",
  "parkingServiceDetails.tab.subscribedServices.table.header.endTime": "Päättyviä tilauksia",

  "parkingServiceDetails.tab.pricing.title": "Hinnoittelu",
  "parkingServiceDetails.tab.pricing.table.header.startTime": "Alkamispäivä",
  "parkingServiceDetails.tab.pricing.table.header.endTime": "Päättymispäivä",
  "parkingServiceDetails.tab.pricing.table.header.periodicFee": "Kuukausimaksu",
  "parkingServiceDetails.tab.pricing.table.header.price": "Tuotteen hinta tekstinä",
  "parkingServiceDetails.tab.pricing.table.button.show": "Näytä",
  "parkingServiceDetails.tab.pricing.button.addPricing": "Lisää hinnoittelu",

  "parkingServiceDetails.tab.locations.title": "Kohteet",
  "parkingServiceDetails.tab.locations.button.addLocation": "Lisää kohde",
  "parkingServiceDetails.tab.locations.table.header.name": "Nimi",
  "parkingServiceDetails.tab.locations.table.header.internalName": "Sisäinen nimi",
  "parkingServiceDetails.tab.locations.table.header.city": "Kaupunki",
  "parkingServiceDetails.tab.locations.table.header.anonymousParking": "Anonyymi pysäköinti",
  "parkingServiceDetails.tab.locations.table.confirm.removeLocation": "Poistetaanko kohteen {locationName} yhteys tuotteesta {serviceName}?",
  "parkingServiceDetails.tab.locations.toast.title.locationRemoved": "Yhteys poistettu",
  "parkingServiceDetails.tab.locations.toast.description.locationRemoved": "Kohteen yhteys tuotteesta poistettu.",
  "parkingServiceDetails.tab.locations.toast.error.title": "Yhteyden poisto epäonnistui",
  "parkingServiceDetails.tab.locations.toast.error.description": "Kohteen yhteyden poistossa tuli virhe.",

  "parkingServiceDetails.tab.description.title": "Kuvaus",
  "parkingServiceDetails.tab.description.description.notAvailable": "Ei kuvausta",

  "parkingServiceDetails.tab.description.toast.edit.title": "Kuvaus muokattu",
  "parkingServiceDetails.tab.description.toast.edit.body": "Kuvauksen muutokset tallennettu.",

  "parkingServiceDetails.tab.validityTimes.title": "Voimassaoloajat",
  "parkingServiceValidityTimesTab.validityTimes": "Sopimuksen voimassaoloajat",
  "parkingServiceValidityTimesTab.validityTimesNotDefined": "Sopimuksella ei ole rajattuja voimassaoloaikoja.",
  "parkingServiceValidityTimesTab.toast.title.validityTimesUpdated": "Päivitys valmis",
  "parkingServiceValidityTimesTab.toast.description.validityTimesUpdated": "Voimassaoloajat on päivitetty.",

  "modalEditValidityTimes.title": "Muokkaa voimassaoloaikoja",
  "modalEditValidityTimes.edit.error": "Voimassaoloaikojen muokkaus epäonnistui.",
  "modalEditValidityTimes.diff.header": "Muutokset",
  "modalEditValidityTimes.validityTest.header": "Testaa voimassaoloaikoja",

  "modalEditDescription.title": "Kuvauksen muokkaus",
  "modalEditDescription.edit.error": "Kuvauksen tallennus epäonnistui.",

  "serviceTariff.toolbar.title": "Tariffin tiedot",
  "serviceTariff.toolbar.label.startTime": "Alkamispäivä:",
  "serviceTariff.toolbar.label.endTime": "Päättymispäivä:",
  "serviceTariff.toolbar.label.periodicFeed": "Kuukausimaksu:",
  "serviceTariff.toolbar.label.serviceName": "Pysäköintituotteen nimi:",
  "serviceTariff.toolbar.label.serviceRef": "Pysäköintituotteen viite:",
  "serviceTariff.tariffStructure": "Tariffin rakenne",
  "serviceTariff.tariffStructureMissing": "Tariffin rakenne puuttuu",

  "externalFacilityDataTable.header.createdTime": "Aika",
  "externalFacilityDataTable.header.state": "Tila",
  "externalFacilityModal.imageMissing": "Kameratunnistukseen liittyvää kuvaa ei löytynyt",

  "operatorsList.toolbar.search.placeholder": "Hae operaattoria",

  "operatorDetails.toolbar.search.placeholder": "Hae kohteita",

  "locations.tab.parkingNow": "Pysäköinnit nyt",
  "locations.tab.parkingEnded": "Päättyneet pysäköinnit",
  "locations.tab.parkings": "Pysäköinnit",
  "locations.tab.vouchers": "Pysäköintiedut",
  "locations.tab.services": "Pysäköintituotteet",
  "locations.tab.services.title": "Kohteen pysäköintituotteet",
  "locations.tab.description": "Kuvaus",
  "locations.tab.description.title": "Kuvaus",
  "locations.tab.description.descriptionIsMissing": "Kuvaus puuttuu",
  "locations.tab.description.toast.success.edit.title": "Kuvaus muokattu",
  "locations.tab.description.toast.success.edit.body": "Kuvauksen muutokset tallennettu.",
  "locations.tab.description.toast.failed.edit.title": "Toiminto epäonnistui",
  "locations.tab.description.toast.failed.edit.body": "Kuvauksen muutoksia ei tallennettu.",
  "locations.tab.vehicleAccessLogs": "Ajoneuvojen pääsynhallintatiedot",
  "locations.tab.vehicleAccessLogs.title": "Ajoneuvojen pääsynhallintatiedot",
  "locations.tab.vehicleAccessLogs.table.creationTime": "Luontiaika",
  "locations.tab.vehicleAccessLogs.table.licensePlate": "Rekisteritunnus",
  "locations.tab.vehicleAccessLogs.table.customer": "Asiakas",
  "locations.tab.vehicleAccessLogs.table.location": "Kohde",
  "locations.tab.vehicleAccessLogs.table.eventType": "Tapahtumatyyppi",
  "locations.tab.vehicleAccessLogs.table.decision": "Päätös",
  "locations.tab.vehicleAccessLogs.table.decisionResponse": "Päätöksen tulkinta",
  "locations.tab.vehicleAccessLogs.table.link.details": "Tiedot",

  "locations.toolbar.toast.actived.title": "Kohde aktivoitu",
  "locations.toolbar.toast.actived.body": "{name} aktivoitu.",
  "locations.toolbar.toast.deactived.title": "Kohde ei käytössä",
  "locations.toolbar.toast.deactived.body": "{name} poistettu käytöstä.",
  "locations.toolbar.toast.statusChanged.title": "Kohteen tilaa vaihdettu",
  "locations.toolbar.toast.statusChanged.body": "Tilan vaihto onnistui.",

  "locations.toolbar.label.operator": "Operaattori:",
  "locations.toolbar.label.address": "Osoite:",
  "locations.toolbar.label.city": "Kaupunki:",
  "locations.toolbar.label.type": "Tyyppi:",
  "locations.toolbar.checkbox.anonymousParking": "Anonyymipysäköinti",
  "locations.toolbar.checkbox.licensePlateRecognition": "Rekisterikilven tunnistus",
  "locations.toolbar.checkbox.barrierlessFacility": "Puomiton kohde (verkkomaksu)",
  "locations.toolbar.checkbox.parkingControlled": "Pysäköinninvalvonta",
  "locations.toolbar.label.doorCode": "Ovikoodi:",
  "locations.toolbar.label.notSet": "Ei asetettu",
  "locations.toolbar.label.maximumParkingTime": "Pisin sallittu pysäköintiaika:",
  "locations.toolbar.label.parkingGracePeriod": "Vapaa läpiajoaika:",
  "locations.toolbar.label.allowedExitTime": "Poistumisaika maksamisen jälkeen:",
  "locations.toolbar.label.parkingControlId": "Pysäköintivalvontatunniste:",
  "locations.toolbar.label.customActionName": "Integraatiot:",
  "locations.toolbar.label.locationCard": "Kohdekortti:",
  "locations.toolbar.link.editLocationCard": "Avaa kohdekortti",
  "locations.toolbar.button.editLocationCard": "Muokkaa kohdekorttia",
  "locations.toolbar.label.automaticEnding": "Automaattinen lopettaminen:",
  "locations.toolbar.label.autoCleanThresholdInDays": "{days} pv",
  "locations.toolbar.label.automaticEnding.info": "Kohteessa oleva anonyymipysäköinti lopetetaan automaattisesti jos se on ollut käynnissä yli tässä määritellyn ajan.",
  "locations.toolbar.button.editAutomaticEnding": "Muokkaa automaattisen pysäköinnin lopetuksen aikaa.",
  "locations.toolbar.label.mainLocation": "Pääkohde:",
  "locations.toolbar.label.subLocation": "Alikohde:",
  "locations.toolbar.button.editLocation": "Muokkaa kohdetta",
  "locations.toolbar.button.editArea": "Muokkaa aluetta",
  "locations.toolbar.button.editName": "Muokkaa kohteen nimeä",
  "locations.toolbar.button.editDoorCode": "Muokkaa ovikoodia",
  "locations.toolbar.info.type.facility": "Laitoksissa on pysäköinti-infra mukana. Pysäköinti käynnistetään automaattisesti kohteeseen ajettaessa. Laitos näkyy mobiilissa sinisellä ja sopimuskäyttäjille keltaisella.",
  "locations.toolbar.info.type.lot": "Käytetään tietyillä P-alueilla ja pysäköinti on itse käynnistettävä. Alueella on yleensä valot, jotka kertovat vapaista paikoista. Alue näkyy mobiilissa tumman vihreällä.",
  "locations.toolbar.info.type.zone": "Käytetään kaupunkien katupysäköinnissä joissa on isompi useiden korttelien kokoinen alue. Vyöhyke näkyy mobiilissa vaalean vihreällä.",

  "locationEditModal.title": "Kohteen {name} muokkaus",
  "locationEditModal.label.parkingGracePeriod": "Vapaa läpiajoaika (minuuttia)",
  "locationEditModal.label.allowedExitTime": "Poistumisaika maksamisen jälkeen (minuuttia)",
  "locationEditModal.action.error.update": "Tietojen tallennus epäonnistui.",
  "locationEditModal.toast.update.title": "Kohde päivitetty.",
  "locationEditModal.toast.update.body": "Kohteen {name} tiedot päivitetty.",

  "modalEditDoorCode.title": "Kohteen {name} ovikoodin muokkaus",
  "modalEditDoorCode.label.doorCode": "Ovikoodi",
  "modalEditDoorCode.action.error.update": "Ovikoodin tallennus epäonnistui.",
  "modalEditDoorCode.toast.update.title": "Kohteen ovikoodi päivitetty.",
  "modalEditDoorCode.toast.update.body": "Kohteen {name} ovikoodi päivitetty.",

  "modalEditExtensionInfo.title": "Muokkaa kohteen {name} kohdekorttia",
  "modalEditExtensionInfo.label.url": "Kohdekortin verkko-osoite",
  "modalEditExtensionInfo.action.error.update": "Verkko-osoitteen tallennus epäonnistui.",
  "modalEditExtensionInfo.toast.update.title": "Kohteen verkko-osoite päivitetty.",
  "modalEditExtensionInfo.toast.update.body": "Kohteen {name} verkko-osoite päivitetty.",

  "modalEditAutoCleanParking.title": "Kohteen {name} pysäköinnin automaattinen lopettaminen",
  "modalEditAutoCleanParking.label.autoCleanThreshold": "Anonyymipysäköinnin minimiaika päivinä ennen automaattista lopettamista",
  "modalEditAutoCleanParking.action.error.update": "Ajan tallennus epäonnistui.",
  "modalEditAutoCleanParking.toast.update.title": "Automaattisen lopetuksen aika päivitetty.",
  "modalEditAutoCleanParking.toast.update.body": "Pysäköinnin automaattisen lopetuksen aika päivitetty.",

  "modalEditLocationCapacity.title": "Kohteen {name} paikkamäärän muokkaus",
  "modalEditLocationCapacity.label.locationCapacity": "Kohteen kokonaispaikkamäärä",
  "modalEditLocationCapacity.action.error.update": "Paikkamäärän muokkaus epäonnistui.",
  "modalEditLocationCapacity.toast.update.title": "Paikkamäärää päivitetty.",
  "modalEditLocationCapacity.toast.update.body": "Kohteen kokonaispaikkamäärää päivitetty.",

  "locationEditNameModal.title": "Kohteen {name} nimen muokkaus",
  "locationEditNameModal.label.name": "Kohteen nimi",
  "locationEditNameModal.action.error.update": "Kohteen nimen tallennus epäonnistui.",
  "locationEditNameModal.toast.updateName.title": "Kohteen nimi päivitetty.",
  "locationEditNameModal.toast.updateName.body": "Uusi kohteen nimi {name} päivitetty.",

  "locationGeoJSONModal.title": "Kohteen {name} alueen muokkaus",
  "locationGeoJSONModal.query.error": "Kohteen aluetietojen hakemisessa tapahtui virhe",
  "locationGeoJSONModal.confirm.title": "Kartta-alueen päivitys",
  "locationGeoJSONModal.confirm.content": "Kartta-alueen muutokset tulevat voimaan heti tallennuksen jälkeen ja vanhaan versioon ei ole mahdollisuutta palata. Oletko varma?",
  "locationGeoJSONModal.toast.success.title": "Alue päivitetty",
  "locationGeoJSONModal.toast.success.body": "Alueen muutokset tallennettu.",
  "locationGeoJSONModal.toast.failed.title": "Toiminto epäonnistui",
  "locationGeoJSONModal.toast.failed.body": "Alueen muutoksia ei tallennettu.",

  "locationDescriptionEditModal.title": "Kuvauksen muokkaus",
  "locationDescriptionEditModal.edit.error": "Kuvauksen tallennus epäonnistui.",
  "locationDescriptionEditModal.label.description": "Kuvaus HTML-muodossa",
  "locationDescriptionEditModal.label.description.info": "Kuvaus näytetään mobiilisovelluksen karttanäkymässä, kun kohteen valitsee.",
  "locationDescriptionEditModal.label.descriptionPreview": "Kuvaus HTML-muodossa, esikatselu",

  "messageCenter.toolbar.title": "Luo ja lähetä uusi viesti viestikeskukseen",
  "messageCenter.table.header": "Lähetetyt viestit",
  "messageCenter.table.header.status": "Tila",
  "messageCenter.table.header.updated": "Päivitetty",
  "messageCenter.table.header.publishTime": "Julkaisuaika",
  "messageCenter.table.header.expiredTime": "Julkaisu päättyy",
  "messageCenter.table.header.deletionTime": "Julkaisun poistoaika",
  "messageCenter.table.header.pushMessage": "Push -viesti",
  "messageCenter.table.header.targetGroup": "Kohderyhmä",
  "messageCenter.table.label.multipleGroups": "Useita ryhmiä",
  "messageCenter.table.column.link.show": "Näytä",
  "messageCenter.table.toast.copy.title": "Viesti kopioitu",
  "messageCenter.table.toast.copy.body": "Viestin sisältö kopioitu uuden viestin pohjaksi.",

  "messageCenter.action.createMessage": "Luo uusi viesti",
  "messageCenter.action.results": "Tulokset",

  "messageCenter.createMessage.action.saveAndContinue": "Tallenna ja jatka",
  "messageCenter.createMessage.action.sendMessage": "Lähetä viesti",
  "messageCenter.createMessage.action.saveDraft": "Tallenna luonnos",
  "messageCenter.createMessage.step.tab.title.finnish": "Suomi",
  "messageCenter.createMessage.step.tab.title.english": "Englanti",
  "messageCenter.createMessage.step.tab.title.swedish": "Ruotsi",
  "messageCenter.createMessage.step.tab.confirmation.missingLanguage": "Haluatko varmasti jatkaa ilman täydellisiä käännöksiä? {language} välilehti ei sisällä kaikkia tietoja verrattuna suomen välilehteen.",
  "messageCenter.createMessage.step.tab.confirmation.missingBothLanguage": "Haluatko varmasti jatkaa ilman käännöksiä? Sekä englanti että ruotsi välilehdet eivät sisällä kaikkia tietoja verrattuna suomen välilehteen.",

  "messageCenter.createMessage.step.targetGroup.title": "Kohderyhmä",
  "messageCenter.createMessage.step.targetGroup.description": "Päätä viestille kohderyhmä",
  "messageCenter.createMessage.step.targetGroup.pageDescription": "Valitse olemassa oleva kohderyhmä tai luo täysin uusi.",
  "messageCenter.createMessage.step.targetGroup.tab.header.target": "Valitse kohderyhmä",
  "messageCenter.createMessage.step.targetGroup.tab.header.createTarget": "Luo kohderyhmä",
  "messageCenter.createMessage.step.targetGroup.tab.header.createNumberGroup": "Luo ryhmä puhelinnumeroilla",

  "messageCenter.createMessage.step.messageContent.title": "Viesti",
  "messageCenter.createMessage.step.messageContent.description": "Luo viesti",
  "messageCenter.createMessage.step.messageContent.label.messageTitle": "Viestin otsikko",
  "messageCenter.createMessage.step.messageContent.label.messageContent": "Viestin sisältöteksti (valinnainen)",
  "messageCenter.createMessage.step.messageContent.label.imageLink": "Kuvan linkki (valinnainen)",
  "messageCenter.createMessage.step.messageContent.label.videoLink": "Videon linkki (valinnainen)",
  "messageCenter.createMessage.step.messageContent.label.ctaLink": "Call-to-action linkki (valinnainen)",
  "messageCenter.createMessage.step.messageContent.label.ctaLinkOutside": "Linkki sovelluksen ulkopuolelle",
  "messageCenter.createMessage.step.messageContent.label.ctaLinkInside": "Linkki sovelluksen näkymään",
  "messageCenter.createMessage.step.messageContent.label.ctaLinkText": "Call-to-action linkin teksti (valinnainen)",
  "messageCenter.createMessage.step.messageContent.link.copyToAllLanguages": "+ Kopioi linkki kaikille kielille",
  "messageCenter.createMessage.step.messageContent.placeholder.target": "Valitse kohde",

  "messageCenter.createMessage.step.sendingDetails.title": "Lähetystapa",
  "messageCenter.createMessage.step.sendingDetails.description": "Lähetysajankohta- ja tapa",
  "messageCenter.createMessage.step.sendingDetails.header": "Päätä lähetysajankohta- ja tapa",
  "messageCenter.createMessage.step.sendingDetails.label.publishTime": "Julkaisuaika",
  "messageCenter.createMessage.step.sendingDetails.label.expirationTime": "Julkaisun päättymisaika",
  "messageCenter.createMessage.step.sendingDetails.label.publishOnlyInMsgCenter": "Julkaise viesti vain viestikeskuksessa",
  "messageCenter.createMessage.step.sendingDetails.label.publishBothMsgCenterAndPush": "Julkaise viesti viestikeskuksessa ja lähetä Push-viestinä",
  "messageCenter.createMessage.step.sendingDetails.label.publishMethod": "Julkaisutapa",
  "messageCenter.createMessage.step.sendingDetails.push.label.title": "Push-viestin otsikko",
  "messageCenter.createMessage.step.sendingDetails.push.label.message": "Push-viesti",

  "messageCenter.createMessage.step.summary.title": "Yhteenveto",
  "messageCenter.createMessage.step.summary.description": "Yhteenveto ja lähetys",
  "messageCenter.createMessage.step.summary.header": "Tarkista viestin sisältö sekä kohderyhmä ja hyväksy viestin lähetys",

  "messageCenter.createMessage.toast.draft.title": "Luonnos tallennettu",
  "messageCenter.createMessage.toast.draft.body": "Viestin luonnos tallennettu onnistuneesti.",
  "messageCenter.createMessage.toast.delete.title": "Viesti poistettu",
  "messageCenter.createMessage.toast.delete.body": "Viesti poistettu onnistuneesti.",
  "messageCenter.createMessage.toast.save.title": "Viesti lähetetty",
  "messageCenter.createMessage.toast.save.body": "Viesti lähetetty kohderyhmälle.",
  "messageCenter.createMessage.toast.error.title": "Viestin tallennus epäonnistui",
  "messageCenter.createMessage.toast.error.description": "Viestiä ei voitu tallentaan. Tarkista syötetyt arvot.",
  "messageCenter.createMessage.confirmation.deleteMessage": "Haluatko varmasti poistaa viestin?",

  "messageCenter.targetGroup.operator.not.equal": "on",
  "messageCenter.targetGroup.operator.not.notEqual": "ei ole",
  "messageCenter.targetGroup.action.success.create.title": "Kohderyhmä luotu",
  "messageCenter.targetGroup.action.success.create.body": "Uusi kohderyhmä {name} luotu.",
  "messageCenter.targetGroup.action.error.create.title": "Luonti epäonnistui",
  "messageCenter.targetGroup.action.error.create.body": "Kohderyhmän luonti epäonnistui.",
  "messageCenter.targetGroup.action.error.create.targetExist.body": "Saman niminen kohderyhmä on jo olemassa.",
  "messageCenter.targetGroup.action.copy.title": "Ryhmä kopioitu",
  "messageCenter.targetGroup.action.copy.description": "Muokkaa kopioitua sisältöä ja luo uusi ryhmä.",
  "messageCenter.targetGroup.filter.and": "JA",
  "messageCenter.targetGroup.filter.or": "TAI",
  "messageCenter.targetGroup.filter.button.and": "JA",
  "messageCenter.targetGroup.filter.button.or": "TAI",
  "messageCenter.targetGroup.filter.button.addFilter": "+ Lisää suodatin",
  "messageCenter.targetGroup.filter.dateType.days": "päivää",
  "messageCenter.targetGroup.filter.dateType.weeks": "viikkoa",
  "messageCenter.targetGroup.filter.dateType.months": "kuukautta",
  "messageCenter.targetGroup.button.createTargetGroup": "Luo kohderyhmä",
  "messageCenter.targetGroup.label.targetGroupName": "Kohderyhmän nimi",
  "messageCenter.targetGroup.label.filterScope": "Suodatuksen aikajänne",
  "messageCenter.targetGroup.label.timeIntervalValue": "Hae kohderyhmä tästä päivästä taaksepäin:",
  "messageCenter.targetGroup.label.timeIntervalDisabled": "Ota aikajänne pois käytöstä",

  "messageCenter.targetGroup.phoneNumbers.message.success.title": "Kohderyhmä luotu",
  "messageCenter.targetGroup.phoneNumbers.message.success.description": "Uusi kohderyhmä {targetGroupName} luotu.",
  "messageCenter.targetGroup.phoneNumbers.label.fillPhoneNumbers": "Syötä puhelinnumerot pilkulla eroteltuna",
  "messageCenter.targetGroup.phoneNumbers.label.targetGroupName": "Kohderyhmän nimi",

  "messageCenter.targetGroup.selector.action.remove.success.toast.title": "Kohderyhmä poistettu",
  "messageCenter.targetGroup.selector.action.remove.success.toast.body": "Kohderyhmä {name} poistettu.",
  "messageCenter.targetGroup.selector.action.remove.error.toast.title": "Poisto epäonnistui",
  "messageCenter.targetGroup.selector.action.remove.error.toast.body": "Kohderyhmän poisto epäonnistui.",
  "messageCenter.targetGroup.selector.action.remove.loading": "Poistetaan käyttäjäryhmää...",
  "messageCenter.targetGroup.selector.targetTable.title.groupName": "Kohderyhmän nimi",
  "messageCenter.targetGroup.selector.targetTable.title.created": "Luotu",
  "messageCenter.targetGroup.selector.targetTable.link.selected": "Valittu",
  "messageCenter.targetGroup.selector.targetTable.link.select": "Valitse",
  "messageCenter.targetGroup.selector.targetTable.confirm.remove": "Haluatko varmasti poistaa kohderyhmän?",
  "messageCenter.targetGroup.selector.segment.label.selectedTargetGroups": "Valitut kohderyhmät",
  "messageCenter.targetGroup.selector.segment.label.potentialCustomers": "asiakasta tulee potentiaalisesti näkemään tämän viestin.",
  "messageCenter.targetGroup.selector.segment.link.unselect": "Poista valinta",
  "messageCenter.targetGroup.selector.segment.link.updateUserCount": "Päivitä asiakasmäärä",
  "messageCenter.targetGroup.selector.action.add.groupMissing.title": "Kohderyhmä puuttuu",
  "messageCenter.targetGroup.selector.action.add.groupMissing.body": "Valitse ensin kohderyhmä",

  "modalMessagePreview.header": "Viestin sisältö",
  "modalMessagePreview.button.copy": "Luo viestistä kopio",
  "modalMessagePreview.error.readingMessage": "Viestin näyttäminen epäonnistui. Avaa viesti uudestaan.",
  "modalMessagePreview.error.copyMessage": "Viestin kopiointi epäonnistui. Avaa viesti uudestaan ja yritä uudelleen.",

  "paymentCards.checkbox.label.showDeletedCardsToo": "Näytä myös poistetut maksukortit",
  "paymentCards.table.header.cardCategory": "Henkilökohtainen / Yritys",
  "paymentCards.table.header.type": "Tyyppi",
  "paymentCards.table.header.cardDigits": "Numero",
  "paymentCards.table.header.expireYear": "Voimassa",
  "paymentCards.table.header.paymentMethodType": "Veloitustapa",
  "paymentCards.table.header.defaultCard": "Oletuskortti",
  "paymentCards.table.header.creationTime": "Kortti lisätty",
  "paymentCards.table.header.deletionTime": "Kortti poistettu",

  "paymentCards.action.remove.success.title": "Kortti poistettu",
  "paymentCards.action.remove.success.description": "Kortin poisto onnistui.",

  "locationsList.locationToolbar.title": "Kohteet",
  "locationsList.locationToolbar.filter.placeholder": "Hae kohteita",
  "locationsList.locationToolbar.operatorSelect.placeholder": "Valitse operaattori",

  "sales.toolbar.title": "Verkkomaksut",
  "sales.toolbar.licensePlate.placeholder": "Rekisteritunnus",
  "sales.toolbar.invoiceNumber.placeholder": "Laskun numero",
  "sales.toolbar.status.placeholder": "Tila",
  "sales.toolbar.startTime.placeholder": "Luontiaika alkaen",
  "sales.toolbar.endTime.placeholder": "Luontiaika päättyen",
  "sales.toolbar.settlementRecipient.placeholder": "Valitse maksunsaaja",
  "sales.toolbar.product.placeholder": "Valitse tuote",
  "sales.table.header.creationTime": "Luontiaika",
  "sales.table.header.price": "Hinta ilman alennuksia",
  "sales.table.header.invoiceNumber": "Laskun numero",
  "sales.table.header.licensePlate": "Rekisteritunnus",
  "sales.table.header.status": "Tila",
  "sales.table.header.sendTime": "Lähetetty",
  "sales.table.header.resolveTime": "Käsitelty",
  "sales.cancel.selected.button": "Peruuta valitut verkkomaksut",
  "sales.cancel.selected.info": "Valittuna {count} verkkomaksua",

  "financesFilterDateRange.label.startDate": "Alkaen päivästä",
  "financesFilterDateRange.label.endDate": "Päivään asti",
  "financesFilterSingleDate.label.date": "Päivämäärä",
  "financesFilterYearMonth.label.year": "Vuosi",
  "financesFilterYearMonth.label.month": "Kuukausi",
  "financesFilterOperator.label.operator": "Valitse operaattori",
  "financesFilterOperator.label.settlementRecipient": "Maksunsaaja",
  "finances.title.reports": "Talouden raportit",
  "finances.tab.button.download": "Lataa CSV",
  "finances.tab.reportSales.tabLabel": "Myynnit",
  "finances.tab.reportSales.header": "Lataa myyntien CSV-raportti kuukaudelta",
  "finances.tab.reportVismaMandators.tabLabel": "Saajan Visma-laskutusraportti",
  "finances.tab.reportVismaMandators.header": "Lataa Visma-laskutuksen CSV-raportti aikaväliltä",
  "finances.tab.reportVismaMandators.label.operator": "Valitse operaattori",
  "finances.tab.reportVismaMandators.label.mandator": "Valitse Visma-laskujen saaja",
  "finances.tab.reportInfoKiosk.tabLabel": "Maksupäätteellä suoritetut pysäköinnit",
  "finances.tab.reportInfoKiosk.header": "Lataa kaikista maksupäätteellä suoritetuista tapahtumista CSV-raportti aikaväliltä",
  "finances.tab.reportInfoKiosk.label.operator": "Valitse operaattori",

  "finances.tab.reportOpenBarrierlessFacilityDebtCollectionCases.tabLabel": "Lataa avoimet perittävät",
  "finances.tab.reportCompletedBarrierlessFacilityDebtCollectionCases.tabLabel": "Lataa maksetut perintälaskut",
  "finances.tab.reportOverdueWebpayments.tabLabel": "Lataa erääntyneet verkkomaksut",
  "finances.tab.reportCancelledBarrierlessFacilityDebtCollectionCases.tabLabel": "Lataa perutut perintälaskut",
  "finances.tab.reportAdyendPaymentReconciliation.tabLabel": "Adyen-suoritusten täsmäytys",
  "finances.tab.reportAdyenMoovyCreditLossByMonth.tabLabel": "Moovyn luottotappiot",
  "finances.tab.reportAdyenMoovyReturnedCreditLoss.tabLabel": "Moovyn palautunut luottotappio",
  "finances.tab.reportAdyenSuccessfulPaymentsForMoovy.tabLabel": "Moovyn onnistuneet maksut",
  "finances.tab.reportAdyenSuccessfulRefunds.tabLabel": "Adyen onnistuneet palautukset",
  "finances.tab.reportAdyenSuccessfulPayments.tabLabel": "Adyen onnistuneet maksut tuotteittain",
  "finances.tab.reportCancelledWebPayments.tabLabel": "Lataa perutut verkkomaksut",

  "finances.tab.reportOpenBarrierlessFacilityDebtCollectionCases.header": "Raportti avoinna olevista erääntyneistä verkkomaksuista ja perintälaskuista puomittomissa kohteissa. Raportti sisältää kaikki valitun kuukauden avoimet erääntyneet maksut ja laskut. Erääntyneisiin verkkomaksuihin liittyen otetaan huomioon 2 päivän puskuri, joka on Vismalla käytössä. Visma-laskuihin liittyen raporttiin sisällytetään myös seuraavan kuun ensimmäinen päivä, sillä yöllinen eräajo muokkaa laskujen tiloja vielä seuraavan vuorokauden puolella.",
  "finances.tab.reportCompletedBarrierlessFacilityDebtCollectionCases.header": "Raportti maksetuista perintälaskuista puomittomissa kohteista. Raportti sisältää tapahtumat valitun kuukauden toisesta päivästä seuraavan kuukauden toiseen päivään saakka.",
  "finances.tab.reportOverdueWebpayments.header": "Raportti valitun kuun aikana erääntyneistä verkkomaksuista. ",
  "finances.tab.reportCancelledBarrierlessFacilityDebtCollectionCases.header": "Raportti perutuista perintälaskuista puomittomissa kohteista. Raportti sisältää tapahtumat valitun kuukauden ensimmäisestä päivästä seuraavan kuukauden ensimäiseen päivään saakka.",
  "finances.tab.reportAdyendPaymentReconciliation.header": "Lataa maksunsaajakohtaiset Adyen-suoritukset virtuaalitilien saldon täsmäytyksen tueksi. Syötä myyntikuukausi vuosi- ja kuukausikenttiin.",
  "finances.tab.reportAdyendPaymentReconciliation.paymentStatus": "Maksun tila",
  "finances.tab.reportAdyendPaymentReconciliation.settledBefore": "Tilanne ennen annettua päivämäärää",
  "finances.tab.reportAdyenMoovyCreditLossByMonth.header": "Pysyvästi epäonnistuneet myynnit Moovy-maksunsaajalle sekä muiden maksunsaajien tuotteille, joilta katetaan luottotappiot. Syötä myyntikuukausi vuosi- ja kuukausikenttiin.",
  "finances.tab.reportAdyenMoovyReturnedCreditLoss.header": "Palautunut luottotappio suoraan Moovylle tai muiden maksunsaajien tuotteille, joilta luottotappio katetaan. Valitse settled-aikaväli kyseisille jälkimaksuille.",
  "finances.tab.reportAdyenSuccessfulPaymentsForMoovy.header": "Moovy-maksunsaajan onnistuneet maksut, ei sisällä jälkimaksuja. Syötä myyntikuukausi vuosi- ja kuukausikenttiin. Aikaväli puolestaan rajaa settled-ajan suhteen.",
  "finances.tab.reportAdyenSuccessfulRefunds.header": "Onnistuneet palautukset, ei sisällä jälkiveloitusten palautuksia. Syötä myyntikuukausi vuosi- ja kuukausikenttiin. Aikaväli puolestaan rajaa palautuksen kirjautumispäivämäärän suhteen.",
  "finances.tab.reportAdyenSuccessfulPayments.header": "Raportti onnistuneista Adyen-korttimaksuista. Hakuehdot: Maksunsaaja (pakollinen), Settled-aikaväli (pakollinen),  Myyntikuukausi vuosi- ja kuukausikenttiin (vapaaehtoinen), Tuotteiden suodatus luottotappion kattamisen suhteen sekä Jälkimaksu. Tuloksia voi olla suuri määrä, joten hakua rajoitetaan seuraavanlaisesti: Settled-aikaväli voi olla max 7 vrk, ellei rajata tiukemmin luottotappion ja jälkimaksun suhteen.",
  "finances.tab.reportAdyenSuccessfulPayments.requiredMissing": "Tarkista pakolliset kentät",
  "finances.tab.reportAdyenSuccessfulPayments.coverCreditLoss": "Luottotappio katetaan",
  "finances.tab.reportAdyenSuccessfulPayments.postPayment": "Jälkimaksu",
  "finances.tab.reportAdyenSuccessfulPayments.fetchError": "Raportin teossa tapahtui virhe",
  "finances.tab.reportCancelledWebPayments.header": "Raportti verkkomaksuista, jotka on peruttu maksun erääntymisen jälkeen, ennen perintään siirtoa. Raportti listaa valitulla aikavälillä perutut maksut.",

  "invoices.toolbar.title": "Laskut",
  "invoices.toolbar.invoiceNumberOrPspRef.placeholder": "Laskun numero tai PSP ref",
  "invoices.toolbar.paymentMethod.placeholder": "Laskutustapa",
  "invoices.toolbar.hideZeroPrice": "Piilota nollahintaiset",
  "invoices.toolbar.status.placeholder": "Laskun tila",
  "invoices.toolbar.createdFromTime.placeholder": "Alkaen päivästä",
  "invoices.toolbar.createdUntilTime.placeholder": "Päivään asti",

  "invoiceModal.invoiceSummary.total.header": "Yhteensä",
  "invoiceModal.invoiceSummary.invoiceNumber.header": "Laskun numero",
  "invoiceModal.invoiceSummary.paymentMethod.header": "Laskutustapa",
  "invoiceModal.invoiceSummary.paymentCardCategory.header": "Maksukortti",
  "invoiceModal.invoiceSummary.paymentCardCategory.adyenLatestPspRef": "Adyen PSP reference",
  "invoiceModal.invoiceSummary.status.header": "Tila",
  "invoiceModal.invoiceSummary.vismaDueDate.header": "Eräpäivä",
  "invoiceModal.invoiceSummary.vismaStatus.header": "Visma tila",
  "invoiceModal.invoiceSummary.compensatingInvoiceNumber.header": "Hyvitetty laskulla",
  "invoiceModal.invoiceSummary.compensatedInvoice.header": "Hyvittää laskun",
  "invoiceModal.invoiceSummary.invoicingAttemptCount.header": "Veloitusyritykset",
  "invoiceModal.invoiceSummary.user.header": "Käyttäjä",

  "subscriptionsTable.title": "Tilaukset",
  "subscriptionsTable.header.orderDate": "Tilauspäivä",
  "subscriptionsTable.header.cancelDate": "Irtisanomispäivä",
  "subscriptionsTable.header.endDate": "Päättymispäivä",
  "subscriptionsTable.button.activate": "Aktivoi",
  "subscriptionsTable.button.cancel": "Irtisano",
  "subscriptionsTable.button.orderEnded": "Tilaus päättynyt",

  "modalCancelWebpayments.header": "Verkkomaksujen peruutus",
  "modalCancelWebpayments.cancel.status.success": "Peruutus onnistui",
  "modalCancelWebpayments.cancel.status.failure": "Peruutus epäonnistui",
  "modalCancelWebpayments.webpayment.cancelable.info": "Peruutettavia verkkomaksuja löytyi {count} kappaletta",
  "modalCancelWebpayments.webpayment.table.creationTime": "Luontiaika",
  "modalCancelWebpayments.webpayment.table.price": "Hinta",
  "modalCancelWebpayments.webpayment.table.licensePlateNumber": "Rekisteritunnus",
  "modalCancelWebpayments.webpayment.table.status": "Verkkomaksun tila",
  "modalCancelWebpayments.webpayment.progress.info": "Käsitelty {done} / {total}",
  "modalCancelWebpayments.webpayment.progress.info.failed": " Epäonnistuneita: {count}",
  "modalCancelWebpayments.webpayment.progress.done.success": "Verkkomaksujen peruuttaminen onnistui",
  "modalCancelWebpayments.webpayment.progress.done.failure": "Kaikkia verkkomaksuja ei pystytty peruuttamaan. Yritä myöhemmin uudelleen tai ota yhteyttä tukeen.",
  "modalCancelWebpayments.webpayment.cancel": "Peruuta verkkomaksut",
  "modalCancelWebpayments.cancelModal.header": "Verkkomaksujen peruutus",
  "modalCancelWebpayments.cancelModal.comment": "Peruutuksen syy",
  "modalCancelWebpayments.webpayment.cancel.confirm": "Oletko varma, että haluat peruuttaa verkkomaksut?",

  "tariffTestPricing.button.calculateTariff": "Laske tariffi",
  "tariffTestPricing.label.parkingRole": "Pysäköijän rooli",

  "modalAddTariff.toast.title.tariffAdded": "Tariffi lisätty",
  "modalAddTariff.toast.description.tariffAdded": "Uusi tariffi lisätty tuotteelle.",
  "modalAddTariff.form.header": "Lisää hinnoittelu",
  "modalAddTariff.form.dropdown.text": "{serviceName}, alkaen {tariffStartTime}",
  "modalAddTariff.form.label.pricing": "Esitäytä lomake olemassa olevalla hinnoittelulla",
  "modalAddTariff.form.option.pricing.placeholder": "Valitse hinnoittelu",
  "modalAddTariff.form.label.startTime": "Voimassaolo alkaa",
  "modalAddTariff.form.option.startTime.placeholder": "Alkamisaika",
  "modalAddTariff.form.label.periodicFee": "Tilaustuotteen kuukausimaksu (€/kk)",
  "modalAddTariff.form.label.description": "Kuvaus HTML-muodossa",
  "modalAddTariff.form.label.description.info": "Kuvaus näytetään mahdollisella maksupäätteellä asiakkaalle.",
  "modalAddTariff.form.placeholder.description": "Kuvaus (HTML)",
  "modalAddTariff.form.label.preview": "Kuvaus HTML-muodossa, esikatselu",
  "modalAddTariff.form.label.tariffStructure": "Tariffin rakenne",
  "modalAddTariff.form.placeholder.tariffStructure": "Tariffin rakenne (JSON)",
  "modalAddTariff.form.action.error": "Korjaa tariffia ja yritä uudelleen.",
  "modalAddTariff.form.label.testTariff": "Testaa tariffin rakenne määrittämällä aloitus- ja lopetusaikoja ja painamalla Laske tariffi nappia.",
  "modalAddTariff.form.label.calculatedTariffs": "Lasketut hinnat yllä määritetyistä ajoista",

  "modalAddLocation.toast.title.locationAdded": "Kohde lisätty",
  "modalAddLocation.toast.description.locationAdded": "Kohde lisätty tuotteelle.",
  "modalAddLocation.form.header": "Yhdistä tuote kohteeseen",
  "modalAddLocation.form.description": "Yhdistä tuote {name} kohteeseen.",
  "modalAddLocation.form.label.operators": "Valitse operaattori",
  "modalAddLocation.form.option.operators.placeholder": "Valitse operaattori",
  "modalAddLocation.form.label.locations": "Valitse kohde",
  "modalAddLocation.form.option.locations.placeholder": "Valitse kohde",
  "modalAddLocation.operation.addLocation.failed": "Kohteen lisääminen tuotteeseen epäonnistui.",

  "parking.toast.operation.createDebtCollection.success.title": "Siirto valmis",
  "parking.toast.operation.createDebtCollection.success.description": "Verkkomaksu siirretty perintään.",
  "parking.toast.operation.createDebtCollection.failed.title": "Siirto epäonnistui",
  "parking.toast.operation.createDebtCollection.failed.description": "Verkkomaksun siirto perintään epäonnistui.",
  "parking.toast.operation.revokeDebtCollection.success.title": "Hylkäys valmis",
  "parking.toast.operation.revokeDebtCollection.success.description": "Perintäprosessi on peruttu.",
  "parking.toast.operation.revokeDebtCollection.failed.title": "Hylkäys epäonnistui",
  "parking.toast.operation.revokeDebtCollection.failed.description": "Perintäprosessin hylkääminen epäonnistui.",
  "parking.toast.operation.cancelWebpayment.success.title": "Peruutus valmis",
  "parking.toast.operation.cancelWebpayment.success.description": "Verkkomaksu on peruttu.",
  "parking.toast.operation.cancelWebpayment.failed.title": "Peruutus epäonnistui",
  "parking.toast.operation.cancelWebpayment.failed.description": "Verkkomaksun peruutus epäonnistui.",

  "parkingContent.label.cameraRecognation": "Kameratunnistus",
  "parkingContent.placeHolderCard.cameraRecognationLoading": "Kameratunnistuksen tietoja ladataan",
  "parkingContent.placeHolderCard.cameraRecognationLoadingFailed": "Kameratunnistuksen tietojen lataamisessa tapahtui virhe",
  "parkingContent.placeHolderCard.cameraRecognationDataMissing": "Pysäköinnistä ei ole kameratunnistuksen tietoja",
  "parkingContent.error.metadata": "Metadatan lataamisessa tapahtui virhe",
  "parkingContent.popup.metadata": "Näytä metadata",
  "parkingContent.card.content.noImage": "Pysäköinnistä ei ole kuvaa",
  "parkingContent.grid.label.parking": "Pysäköinti",
  "parkingContent.label.parkStarted": "Alkoi",
  "parkingContent.label.parkEnded": "Päättyi",
  "parkingContent.label.parkOngoing": "Käynnissä",
  "parkingContent.label.parkDuration": "Kesto",
  "parkingContent.label.parkTotalDuration": "Kokonaiskesto",
  "parkingContent.tab.sales": "Myyntitapahtumat",
  "parkingContent.tab.vouchers": "Käytetyt pysäköintiedut",
  "parkingContent.tab.parkingSequence": "Kokonaispysäköinti",
  "parkingContent.tab.auditLogs": "Muutosloki",

  "parkingSequence.label.anonymousParking": "Anonyymi pysäköinti",
  "parkingSequence.label.moovyParking": "Moovy pysäköinti",
  "parkingSequence.label.parkmanParking": "ParkMan pysäköinti",
  "parkingSequence.label.easyparkParking": "EasyPark pysäköinti",
  "parkingSequence.table.header.location": "Kohde",
  "parkingSequence.table.header.started": "Alkoi",
  "parkingSequence.table.header.ended": "Päättyi",
  "parkingSequence.table.header.duration": "Kesto",
  "parkingSequence.table.header.type": "Tyyppi",
  "parkingSequence.table.header.parkingProduct": "Pysäköintitapa",
  "parkingSequence.label.subscription": "Sopimus {name}",
  "parkingSequence.label.payg": "Lyhytaikainen",
  "parkingSequence.label.ongoing": "Käynnissä",
  "parkingSequence.table.column.service.subscription": "Sopimus {name}",
  "parkingSequence.table.column.service.payg": "Lyhytaikainen",

  "parkingToolbar.loading.error": "Pysäköinnin lataamisessa tapahtui virhe",
  "parkingToolbar.title.parking": "Pysäköinti {licencePlateNumber}",
  "parkingToolbar.button.previous": "Edellinen",
  "parkingToolbar.button.next": "Seuraava",
  "parkingToolbar.button.revoke": "Hylkää perintäprosessi",
  "parkingToolbar.button.takeDebtCollection": "Vie maksu perintään",
  "parkingToolbar.button.cancelPayment": "Peruuta verkkomaksu",
  "parkingToolbar.popup.backToWebPayment": "Takaisin verkkomaksuihin",

  "parkingToolbar.label.location": "Kohde",
  "parkingToolbar.label.paymentStatus": "Verkkomaksun tila",
  "parkingToolbar.status.sentToPayment": "Lähetetty verkkomaksupalveluun",
  "parkingToolbar.status.handledPayment": "Käsitelty verkkomaksupalvelussa",

  "moovyHtmlPreview.error.contentInvalid": "HTML sisältö on virheellinen ja sitä ei voi näyttää.",
  "moovyHtmlPreview.link.updateView": "Päivitä näkymä",

  "settlementRecipients.toolbar.label.settlementRecipientName": "Yrityksen nimi",
  "settlementRecipients.toolbar.filter.settlementRecipientName.placeholder": "Hae",

  "settlementRecipients.settlementRecipientTable.header.companyName": "Yrityksen nimi",
  "settlementRecipients.settlementRecipientTable.header.extensionName": "Nimen tarkenne",
  "settlementRecipients.settlementRecipientTable.header.companyType": "Yritystyyppi",
  "settlementRecipients.settlementRecipientTable.header.operator": "Operaattori",
  "settlementRecipients.settlementRecipientTable.header.contact": "Yhteyshenkilö",
  "settlementRecipients.settlementRecipientTable.header.businessId": "Y-tunnus",
  "settlementRecipients.settlementRecipientTable.header.city": "Kaupunki",
  "settlementRecipients.settlementRecipientTable.header.vismaRoles": "Visma",
  "settlementRecipients.settlementRecipientTable.header.vismaStatus": "Visma-tila",
  "settlementRecipients.settlementRecipientTable.header.adyenStatus": "Adyen-tila",

  "settlementRecipients.settlementRecipientTable.vismaMandatorsActive": "Valmis",
  "settlementRecipients.settlementRecipientTable.vismaMandatorsInActive": "Kesken",

  "settlementRecipient.toolbar.label.contact": "Yhteyshenkilö",
  "settlementRecipient.toolbar.label.businessId": "Y-tunnus",
  "settlementRecipient.toolbar.label.companyType": "Yritystyyppi",
  "settlementRecipient.toolbar.label.operator": "Operaattori",
  "settlementRecipient.toolbar.label.extensionName": "Nimen tarkenne",

  "settlementRecipient.action.create.success.title": "Luonti onnistui",
  "settlementRecipient.action.create.success.description": "Uusi maksunsaaja luotu",
  "settlementRecipient.action.create.failed": "Luonti epäonnistui",
  "settlementRecipient.action.update.success.title": "Päivitys onnistui",
  "settlementRecipient.action.update.success.description": "Maksunsaajan tiedot päivitetty",
  "settlementRecipient.action.update.failed": "Päivitys epäonnistui",

  "ultimateBeneficialOwnerTab.button.addUltimateBeneficialOwner": "Lisää tosiasiallinen edunsaaja",

  "ultimateBeneficialOwnerTable.header.firstNames": "Etunimet",
  "ultimateBeneficialOwnerTable.header.lastName": "Sukunimi",
  "ultimateBeneficialOwnerTable.header.countryOfResidence": "Asuinmaa",
  "ultimateBeneficialOwnerTable.header.ownershipPercentage": "Omistusosuus %",
  "ultimateBeneficialOwnerTable.header.jobTitle": "Toimenkuva",
  "ultimateBeneficialOwnerTable.header.reason": "Tyyppi",

  "modalOwner.form.label.firstNames": "Etunimet",
  "modalOwner.form.label.lastName": "Sukunimi",
  "modalOwner.form.label.countryCodeOfResidence": "Asuinmaa",
  "modalOwner.form.placeholder.countryOfResidence": "Valitse maa",
  "modalOwner.form.label.ownershipPercentage": "Yrityksen omistusosuus prosentteina",
  "modalOwner.form.label.jobTitle": "Toimenkuva",
  "modalOwner.form.dropdown.jobTitle.add": "Lisää uusi toimenkuva:",
  "modalOwner.form.label.reason": "Edunsaajan määritelmä",
  "modalOwner.form.add.error": "Tosiasiallisen maksunsaajan lisäys epäonnistui.",
  "modalOwner.toast.title.ultimateBeneficialOwnerAdded": "Tosiasiallinen maksunsaaja",
  "modalOwner.toast.description.ultimateBeneficialOwnerAdded": "Lisätty uusi tosiasiallinen maksunsaaja",
  "modalOwner.toast.title.signatoryAdded": "Lisäys onnistui",
  "modalOwner.toast.description.signatoryAdded": "Lisätty nimenkirjoitusoikeus",
  "modalOwner.button.addSignatory": "Lisää nimenkirjoitusoikeus",

  "modalRemoveUser.toast.title.userRemoved": "Käyttäjä poistettu",
  "modalRemoveUser.toast.description.userRemove": "Käyttäjä poistettu onnistuneesti.",
  "modalRemoveUser.title.removeUser": "Poista käyttäjä {userName}",
  "modalRemoveUser.title.removeEnterpriseUser": "Poista yritystili {userName}",
  "modalRemoveUser.description.removeUser": "Haluatko varmasti poistaa käyttäjän {userName}? Huomioi, että poisto on lopullinen ja sitä ei voi peruuttaa.",
  "modalRemoveUser.description.removeEnterpriseUser": "Haluatko varmasti poistaa yritystilin {userName}? Huomioi, että poisto on lopullinen ja sitä ei voi peruuttaa.",
  "modalRemoveUser.action.default.error": "Käyttäjän poisto epäonnistui",

  "modalRemovingRequest.toast.title.userRemoved": "Poistopyyntö luotu.",
  "modalRemovingRequest.toast.description.userRemove": "Käyttäjä poistettu onnistuneesti.",
  "modalRemovingRequest.title.removeUser": "Luo poistopyyntö käyttäjälle {userName}",
  "modalRemovingRequest.description.removeUser": "Jos lisäät poistopyynnön, käyttäjä tullaan poistamaan Moovysta normaalin poistosyklin mukaisesti.",
  "modalRemovingRequest.action.default.error": "Käyttäjän poistopyynnön luonti epäonnistui",
  "modalRemovingRequest.button.removingRequest": "Luo poistopyyntö",
  "modalRemovingRequest.label.email": "Sovellusasiakkaan sähköpostiosoite",

  "modalEditUser.title.editUser": "Asiakastietojen muokkaus",
  "modalEditUser.form.firstName": "Etunimi",
  "modalEditUser.form.lastName": "Sukunimi",
  "modalEditUser.form.email": "Sähköposti",
  "modalEditUser.form.phoneNumber": "Puhelinnumero",
  "modalEditUser.action.edit.default.error": "Asiakastietojen tallennus epäonnistui",
  "modalEditUser.button.tooltip.deleteUser": "Poista sovellusasiakas",
  "modalEditUser.button.tooltip.createDeletionRequest": "Pyydä sovellusasiakkaan poistamista järjestelmästä",
  "modalEditUser.button.createDeletionRequest": "Luo poistopyyntö",

  "modalUserPermission.title.editUser": "Käyttäjän estolistan eston tila",
  "modalUserPermission.action.setPrevented.default.error": "Estolistan eston tallennus epäonnistui.",
  "modalUserPermission.action.removePrevented.default.error": "Estolistan eston poisto epäonnistui.",
  "modalUserPermission.label.pausedUser": "Käyttäjä estolistalle",
  "modalUserPermission.toast.title.setBlockingPrevented": "Estolista estetty",
  "modalUserPermission.toast.description.setBlockingPrevented": "Estolista esto käyttäjälle on käytössä.",
  "modalUserPermission.toast.title.removeBlockingPrevented": "Estolista sallittu",
  "modalUserPermission.toast.description.removeBlockingPrevented": "Käyttäjä voidaan jatkossa asettaa estolistalle.",

  "modalUserPermission.automaticHandlingEnabled": "Estolistalle siirto estetty",
  "modalUserPermission.automaticHandlingDisabled": "Estolistalle siirto sallittu",
  "modalUserPermission.automaticHandlingReason": "Syötä estolistalle eston syy",

  "moovyUser.userAssignments.checkbox.showNotActiveAssignments": "Näytä myös päättyneet",
  "moovyUser.userAssignments.table.header.service": "Tuote",
  "moovyUser.userAssignments.table.header.owner": "Tilaaja",
  "moovyUser.userAssignments.table.header.active": "Voimassa",
  "moovyUser.userAssignments.acceptAssignment.error": "Käyttöoikeuden hyväksymisessä tapahtui virhe.",
  "moovyUser.userAssignments.revokeAssignment.error": "Käyttöoikeuden lopettamisessa tapahtui virhe.",

  "modalManage.settlementRecipient.form.header.addNewRecipient": "Lisää uusi maksunsaaja",
  "modalManage.settlementRecipient.form.header.editRecipient": "Muokkaa maksunsaajaa",
  "modalManage.settlementRecipient.form.operator": "Operaattori",
  "modalManage.settlementRecipient.form.operator.placeholder": "Valitse maksunsaajan operaattori",
  "modalManage.settlementRecipient.form.companyName": "Yrityksen nimi",
  "modalManage.settlementRecipient.form.nameExtension": "Nimen tarkenne",
  "modalManage.settlementRecipient.form.info.nameExtension": "Käytä tarkennetta harkiten, koska tämä näkyy aktiivisella maksunsaajalla myös asiakkaalle lähetettävissä raporteissa. Tarkenteella voidaan erotella esim. samannimiset maksunsaajat.",
  "modalManage.settlementRecipient.form.businessId": "Y-tunnus",
  "modalManage.settlementRecipient.form.companyType": "Yritystyyppi",
  "modalManage.settlementRecipient.form.companyType.placeholder": "Valitse yritystyyppi",
  "modalManage.settlementRecipient.form.country": "Maa",
  "modalManage.settlementRecipient.form.streetAddress": "Katuosoite",
  "modalManage.settlementRecipient.form.postalCode": "Postinumero",
  "modalManage.settlementRecipient.form.city": "Postitoimipaikka",
  "modalManage.settlementRecipient.form.adyenReportProfileType": "Adyen raporttiprofiili",
  "modalManage.settlementRecipient.form.contact.details": "Yhteyshenkilön tiedot",
  "modalManage.settlementRecipient.form.contact.name": "Nimi",
  "modalManage.settlementRecipient.form.contact.phone": "Puhelinnumero",
  "modalManage.settlementRecipient.form.contact.email": "Sähköpostiosoite",
  "modalManage.settlementRecipient.button.addRecipient": "Lisää maksunsaaja",
  "modalManage.settlementRecipient.button.updateRecipient": "Tallenna muutokset",

  "moovyTable.action.failed": "Ongelmia toiminnassa. Päivitä sivu ja yritä uudestaan.",
  "moovyTable.action.emptySet": "Sisältö tyhjä",
  "moovyTable.action.fetchPending": "Syötä hakuehdot ja paina Hae",

  "tabAdyen.companyType.error": "Adyen määritystä ei voi tehdä, koska maksunsaajalle ei ole määritetty yritystyyppiä. Aseta yritystyyppi ensin.",

  "tabAdyen.progress.label.proceeding": "Edistyminen",
  "tabAdyen.progress.label.beneficialOwner": "Lisää tosiasialliset edunsaajat",
  "tabAdyen.progress.label.signatory": "Lisää nimenkirjoitusoikeus",
  "tabAdyen.progress.label.accountHolder": "Luo tilinhaltija",
  "tabAdyen.progress.label.onboardingLink": "Luo kutsulinkki",

  "stepSignatoryOwner.button.signatory": "Lisää nimenkirjoitusoikeus",

  "onboardingSteps.step.beneficialOwners": "Tosiasialliset edunsaajat",
  "onboardingSteps.step.signatories": "Nimenkirjoitusoikeudet",
  "onboardingSteps.step.accountHolder": "Tilinhaltija",
  "onboardingSteps.step.onboardingLink": "Kutsulinkki",

  "stepAccountHolder.label.mainStatus": "Tilinhaltijan tila:",
  "stepAccountHolder.label.accountHolderCode": "Tilinhaltijan koodi:",
  "stepAccountHolder.description.accountHolder": "Adyenin tilinhaltija luodaan painamalla alla olevaa nappia. Tilinhaltijan luontiin käytetään maksunsaajan ja edunsaajan tietoja. Haltijan luonti luo Adyen tilin automaattisesti. Tilinhaltijan tilaa tai sen muuttumista voi seurata tästä näkymästä, maksunsaajien listauksesta tai Adyen Customer Arealta.",
  "stepAccountHolder.button.createAndContinue": "Luo tilinhaltija ja jatka",
  "stepAccountHolder.header.accountHolder": "Tilinhaltija",
  "stepAccountHolder.toast.action.accountHolderCreated.title": "Luonti onnistui",
  "stepAccountHolder.toast.action.accountHolderCreated.description": "Adyenin tilinhaltija on luotu",
  "stepAccountHolder.accountHolderCreated.failed": "Tilinhaltijan luonti epäonnistui tuntemattomasta syystä. Tarkista, että ensimmäisen vaiheen tiedot on syötetty oikein.",

  "stepOnboardingLink.fetchAccountHolder.failed": "Tilinhaltijan tietojen haku epäonnistui. Päivitä sivu uudestaan.",
  "stepOnboardingLink.toast.copiedToClipboard.text": "Linkki",
  "stepOnboardingLink.toast.action.onboardingTokenCreate.title": "Luonti onnistui",
  "stepOnboardingLink.toast.action.onboardingTokenCreate.description": "Kutsulinkin luonti onnistui",
  "stepOnboardingLink.toast.action.onboardingTokenInvalidate.title": "Linkki poistettu",
  "stepOnboardingLink.toast.action.onboardingTokenInvalidate.description": "Kutsulinkki poistettu. Voit luoda linkin nyt uudestaan.",
  "stepOnboardingLink.toast.action.onboardingToken.valid": "Kutsulinkki on voimassa",
  "stepOnboardingLink.description.onboardingToken": "Luo kutsulinkki tilinhaltijalle jonka kautta asiakas voi jatkaa Adyen KYC -prosessia Adyenin omalla sivustolla. Kutsulinkki on voimassa vain tietyn ajan ja se on nähtävissä linkin luonnin jälkeen. Kutsulinkin kautta tilinhaltija pääsee syöttämään yrityksen tiedot KYC prosessissa. Syöttösessio on auki 30 min. Jos tilinhaltijan session aika umpeutuu tai hän ei täytä kaikkia vaadittavia tietoja, luo uusi linkki tilinhaltijalle. KYC prosessi jatkuu siitä sessiosta, joka jäi viimeksi tallettamatta.",
  "stepAccountHolder.header.onboardingToken": "Kutsulinkki",
  "stepOnboardingLink.button.copyLink": "Kopioi linkki",
  "stepOnboardingLink.button.createAndContinue": "Luo kutsulinkki",
  "stepOnboardingLink.link.invalidateToken": "Poista kutsulinkki",
  "stepOnboardingLink.onboardingTokenCreated.failed": "Kutsulinkin luonti epäonnistui tuntemattomasta syystä. Tarkista, että aiempien vaiheiden tiedot on syötetty oikein.",
  "stepOnboardingLink.fetchOnboardingToken.failed": "Kutsulinkin tietojen haku epäonnistui. Varmista, että tilinhaltija on luotu aiemmassa vaiheessa.",

  "accountHolder.header.accountHolder": "Tilinhaltija",
  "accountHolder.label.accountHolderCode": "Tilinhaltija:",
  "accountHolder.label.accountHolderId": "Tilinhaltijan ID:",
  "accountHolder.label.adyenAccountBpStatus": "Tila:",
  "accountHolder.button.createAndContinue": "Luo tilinhaltija ja jatka",
  "accountHolder.description.accountHolder": "Adyenin tilinhaltija luodaan painamalla alla olevaa nappia. Tilinhaltijan luontiin käytetään maksunsaajan ja edunsaajan tietoja. Haltijan luonti luo Adyen tilin automaattisesti. Tilinhaltijan tilaa tai sen muuttumista voi seurata tästä näkymästä, maksunsaajien listauksesta tai Adyen Customer Arealta.",
  "accountHolder.accountHolderCreated.failed": "Tilinhaltijan luonti epäonnistui tuntemattomasta syystä.",
  "accountHolder.toast.action.accountHolderCreated.title": "Luonti onnistui",
  "accountHolder.toast.action.accountHolderCreated.description": "Adyenin tilinhaltija on luotu",
  "onboardingLink.button.create": "Luo kutsulinkki",
  "onboardingLink.description.create": "Kutsulinkin kautta tilinhaltija pääsee syöttämään yrityksen tiedot KYC prosessissa. Syöttösessio on auki 30 min. Jos tilinhaltijan session aika umpeutuu tai hän ei täytä kaikkia vaadittavia tietoja, luo uusi linkki tilinhaltijalle. KYC prosessi jatkuu siitä sessiosta, joka jäi viimeksi tallettamatta.",

  "tabVisma.button.addMandator": "Lisää mandaattori",
  "tabVisma.action.createMandator.success.title": "Luonti onnistui",
  "tabVisma.action.createMandator.success.description": "Mandaattorin luonti onnistui.",
  "tabVisma.action.updateMandator.success.title": "Päivitys onnistui",
  "tabVisma.action.updateMandator.success.description": "Mandaattorin tiedot päivitetty.",
  "tableMandator.header.bankAccountBic": "Pankin BIC-koodi",
  "tableMandator.header.bankAccountIban": "Pankin IBAN-tilinumero",
  "tableMandator.header.customerSupportPhone": "Asiakaspalvelun puhelinnumero",
  "tableMandator.header.customerSupportEmail": "Asiakaspalvelun sähköpostiosoite",
  "tableMandator.header.mandatorRole": "Visma",
  "tableMandator.header.vismaStatus": "Visman KYC -tila",

  "modalManageMandator.header.create": "Lisää uusi mandaattori",
  "modalManageMandator.header.update": "Muokkaa mandaattoria",
  "modalManageMandator.action.create.failed": "Mandaattorin luonti epäonnistui.",
  "modalManageMandator.action.update.failed": "Mandaattorin muokkaus epäonnistui.",
  "modalManageMandator.form.vismaRole": "Visma-rooli",
  "modalManageMandator.form.vismaRole.placeholder": "Valitse rooli",
  "modalManageMandator.message.addNotAllowed": "Maksunsaajalla on jo {mandatorsCount} laskutusroolia ({mandatorRoles}). Poista yksi tai useampi laskutusrooli ennen uuden lisäämistä.",
  "modalManageMandator.form.bankAccountIban": "Pankin IBAN-tilinumero",
  "modalManageMandator.form.bankAccountBic": "Pankin BIC-koodi",
  "modalManageMandator.form.customerSupportPhone": "Asiakaspalvelun puhelinnumero",
  "modalManageMandator.form.customerSupportEmail": "Asiakaspalvelun sähköpostiosoite",
  "modalManageMandator.button.addMandator": "Lisää mandaattori",
  "modalManageMandator.button.updateMandator": "Päivitä mandaattori",

  "chargingSessionToolbar.header": "Sähköauton lataus",
  "chargingSessionToolbar.placeholder.pluginId": "Latauspiste",
  "chargingSessionToolbar.placeholder.chargingStatus": "Lataustila",
  "chargingSessionToolbar.placeholder.chargerType": "Laturityyppi",
  "chargingSessionToolbar.label.pluginId": "Latauspiste",
  "chargingSessionToolbar.label.customer": "Asiakas",
  "chargingSessionToolbar.label.chargingStatus": "Lataustila",
  "chargingSessionToolbar.label.chargerType": "Laturityyppi",
  "chargingSessionToolbar.label.location": "Kohde",
  "chargingSessionToolbar.label.chargings": "Lataukset",
  "chargingSessionToolbar.label.showAlsoCompletedChargings": "Näytä myös päättyneet lataukset",
  "chargingSessionToolbar.label.moreFilters": "Lisää hakuehtoja",
  "chargings.chargingsTable.header.plugin": "Latauspiste",
  "chargings.chargingsTable.header.status": "Lataustila",
  "chargings.chargingsTable.header.location": "Kohde",
  "chargings.chargingsTable.header.city": "Kaupunki",
  "chargings.chargingsTable.header.customer": "Asiakas",
  "chargings.chargingsTable.header.type": "Tyyppi",
  "chargings.chargingsTable.header.startTime": "Aloitusaika",
  "chargings.chargingsTable.header.endTime": "Lopetusaika",
  "chargings.chargingsTable.header.duration": "Kesto",
  "chargings.chargingsTable.header.energy": "Energia",
  "chargings.chargingsTable.header.price": "Hinta",
  "chargings.chargingsTable.link.stop": "Lopeta",
  "chargings.chargingSessionsContent.confirm.stopSession": "Haluatko varmasti lopettaa asiakkaan {customer} lataustapahtuman pisteellä {pluginId}?",
  "chargings.chargingSessionsContent.action.stopSession.success.title": "Lopetus onnistui",
  "chargings.chargingSessionsContent.action.stopSession.success.description": "Lataustapahtuman lopetus onnistui.",
  "chargings.chargingSessionsContent.action.stopSession.error.title": "Lopetus epäonnistui",
  "chargings.chargingSessionsContent.action.stopSession.error.description": "Lataustapahtuman lopetus epäonnistui.",

  "management.create.service.action.description.failed": "Tarkista syöttämäsi tiedot",
  "management.create.location.action.description.failed": "Tarkista syöttämäsi tiedot",
  "management.create.service.action.title.failed": "Tuotteen lisäys epäonnistui",
  "management.create.location.action.title.failed": "Kohdekokonaisuuden lisäys epäonnistui",

  "moneyTransfersContent.form.label.moneySource": "Maksaja",
  "moneyTransfersContent.form.input.platform": "Moovy Liable Account",
  "moneyTransfersContent.form.label.switch": "Vaihda",
  "moneyTransfersContent.form.label.moneyTarget": "Maksunsaaja",
  "moneyTransfersContent.form.settlementRecipient.placeholder": "Syötä maksunsaajan nimi",
  "moneyTransfersContent.form.payer.placeholder": "Syötä maksajan nimi",
  "moneyTransfersContent.form.settlementRecipient.fetching": "Haetaan...",
  "moneyTransfersContent.form.settlementRecipient.noSearchResults": "Ei hakutuloksia",
  "moneyTransfersContent.form.label.amount": "Maksun määrä",
  "moneyTransfersContent.form.label.transferCode": "Siirtokoodi",
  "moneyTransfersContent.form.label.transferCodeDescription": "Valitun siirtokoodin kuvaus",
  "moneyTransfersContent.form.label.comment": "Selite",
  "moneyTransfersContent.form.button.transfer": "Tee siirto",
  "moneyTransfersContent.action.transfer.failed": "Siirto epäonnistui",
  "moneyTransfersContent.action.transferMoneyToAccount.success.title": "Siirto onnistui",
  "moneyTransfersContent.action.transferMoneyToAccount.success.description": "Siirto tilien välillä onnistui.",

  "voucherTemplateManageModal.header.createTemplate": "Luo uusi pysäköintietu",
  "voucherTemplateManageModal.header.editTemplate": "Muokkaa pysäköintietua",
  "voucherTemplateManageModal.info.editWarning": "Pohjan muokkaaminen voi vaikuttaa jo myönnettyihin pysäköintietuihin.",
  "voucherTemplateManageModal.form.label.customDescription": "Pysäköintiedun kuvaus",
  "voucherTemplateManageModal.form.label.categoryType": "Pysäköintiedun kategoria",
  "voucherTemplateManageModal.form.placeholder.categoryType": "Valitse pysäköintiedun kategoria",
  "voucherTemplateManageModal.form.label.benefitType": "Pysäköintiedun tyyppi",
  "voucherTemplateManageModal.form.placeholder.chooseType": "Valitse pysäköintiedun tyyppi",
  "voucherTemplateManageModal.form.placeholder.chooseCategoryFirst": "Valitse ensin kategoria",
  "voucherTemplateManageModal.form.label.allowedGrantType": "Pysäköintiedun myöntämistyyppi",
  "voucherTemplateManageModal.form.placeholder.allowedGrantType": "Pysäköintiedun myöntämistyyppi",
  "voucherTemplateManageModal.form.label.operator": "Operaattori",
  "voucherTemplateManageModal.form.placeholder.chooseOperator": "Valitse operaattori",
  "voucherTemplateManageModal.form.label.locationsDescription": "Kohteiden kuvaus",
  "voucherTemplateManageModal.form.label.locations": "Kohteet",
  "voucherTemplateManageModal.form.placeholder.chooseLocations": "Valitse kohteet",
  "voucherTemplateManageModal.form.placeholder.chooseOperatorFirst": "Valitse ensin operaattori",
  "voucherTemplateManageModal.form.label.grantedValue": "Myönnetty arvo",
  "voucherTemplateManageModal.form.label.grantedDurationInMinutes": "Pysäköintiedun pituus",
  "voucherTemplateManageModal.form.label.durationInMinutes": "Pituus (minuuttia)",
  "voucherTemplateManageModal.form.label.grantedDiscountPercent": "Alennusprosentti",
  "voucherTemplateManageModal.form.label.validityFromGranting": "Pysäköintietu voimassa myöntämisestä",
  "voucherTemplateManageModal.form.label.valid": "Voimassa (minuuttia)",
  "voucherTemplateManageModal.form.label.salePrice": "Kustannus edun antajalle (euroa)",

  "modalAdyenInvoice.tab.waiving.title": "Aiheeton veloitus",
  "modalAdyenInvoice.tab.cardPayments.title": "Korttiveloitukset",
  "modalAdyenInvoice.tab.cardRefunds.title": "Korttipalautukset",
  "modalAdyenInvoice.tab.cardRefunds.title.popup.abnormality": "Poikkeavuutta korttipalautuksissa",
  "modalAdyenInvoice.tab.cardRefunds.title.popup.receivedRefund": "Korttipalautus käynnissä",
  "modalAdyenInvoice.tab.cardRefunds.title.popup.errorRefund": "Korttipalautuksessa virhe",

  "modalRefundConfirmation.adyen.refundTotal": "Palautetaan yhteensä {totalPrice}e",
  "modalRefundConfirmation.adyen.title": "Korttiveloituksen palautus",
  "modalRefundConfirmation.adyen.button.refund": "Palauta",
  "modalRefundConfirmation.adyen.operation.refund.failed": "Palautus epäonnistui.",

  "modalRetryCardPaymentConfirmation.tryToChargeTotal": "Yritetään uudelleenveloittaa summaa {totalPrice}e",
  "modalRetryCardPaymentConfirmation.title": "Uudelleenveloitus korttimaksusta",
  "modalRetryCardPaymentConfirmation.button.attemptCharge": "Uudelleenveloita",
  "modalRetryCardPaymentConfirmation.operation.attemptCharge.failed": "Uudelleenveloitus epäonnistui.",

  "modalSendEmailReceipt.title": "Lähetä veloituksen kuitti sähköpostiin",
  "modalSendEmailReceipt.label.email": "Sähköpostiosoite",
  "modalSendEmailReceipt.button.send": "Lähetä",
  "modalSendEmailReceipt.operation.emailSending.failed": "Kuitin lähetys sähköpostiin epäonnistui",

  "tabAdyenInvoice.table.header.product": "Tuote",
  "tabAdyenInvoice.table.header.charged": "Veloitettu",
  "tabAdyenInvoice.table.header.refund": "Palauta",
  "tabAdyenInvoice.table.header.refundable": "Palautettavissa",
  "tabAdyenInvoice.table.header.alv": "ALV %",
  "tabAdyenInvoice.table.header.description": "Rivin kuvaus",
  "tabAdyenInvoice.table.header.invoiceRow": "Laskurivi",
  "tabAdyenInvoice.toast.title.invoiceSentByEmail": "Kuitti lähetetty",
  "tabAdyenInvoice.toast.description.invoiceSentByEmail": "Kuitti lähetetty sähköpostiin",
  "tabAdyenInvoice.toast.title.refundPayment": "Veloitus palautettu",
  "tabAdyenInvoice.toast.description.refundPayment": "Korttiveloitus palautettu",
  "tabAdyenInvoice.toast.title.retryCardPayment": "Uudelleenveloitus valmis",
  "tabAdyenInvoice.toast.description.retryCardPayment": "Kortin uudelleenveloitus onnistui",
  "tabAdyenInvoice.toast.title.userWasRemovedFromBlocklist": "Käyttäjä on poistettu estolistalta.",
  "tabAdyenInvoice.toast.description.userWasRemovedFromBlocklist": "Kaikki pysyvästi epäonnistuneet veloitukset on kuitattu.",
  "tabAdyenInvoice.toast.title.waivedInvoice": "Laskun tila muutettu",
  "tabAdyenInvoice.toast.description.waivedInvoice": "Lasku asetettu aiheettomaksi",

  "tabAdyenInvoice.button.sendReceipt": "Lähetä kuitti",
  "tabAdyenInvoice.button.refundPayment": "Palauta korttimaksua",
  "tabAdyenInvoice.button.retryCardPayment": "Uudelleenveloitus",
  "tabAdyenInvoice.waived.popup.label": "Laskun voi asettaa aiheettomaksi vasta kun veloitusyrityksiä on 3kpl.",

  "tabAdyenInvoice.label.notChosenInvoiceRows": "Ei valittuja laskurivejä",
  "tabAdyenInvoice.label.chosenInvoiceRows": "{rowCount} laskuriviä valittu, yhteensä {totalPrice}",
  "tabAdyenInvoice.label.error.chosenInvoiceRows": "{rowCount} laskuriviä valittu, virheellinen palautusmäärä!",
  "tabAdyenInvoice.label.maxRefund": "Laskusta on palautettavissa maksimissaan {price}.",
  "tabAdyenInvoice.label.refundForInvoice": "Palautus tehty korttiveloituksen laskun numerosta {invoiceNumber}.",

  "serviceInfo.popup.content": "Päivitä klikkaamalla.",
  "serviceInfo.popup.notFetched": "Paikkatietoja ei haettuna",
  "serviceInfo.popup.notDefined": "Ei määritelty",
  "serviceInfo.popup.limitReached": "Kohde alkaa olla täynnä. Täyttöaste on nyt {percentage} %.",
  "serviceInfo.label.specialServiceFeeConfig": "Palvelumaksu: ",
  "serviceInfo.label.shortParkingPlaces": "Lyhytaikapysäköinti:",
  "serviceInfo.label.prebookingParkingPlaces": "Sopimukset/ennakot:",
  "serviceInfo.label.locationPlaces": "Kohteen paikat:",
  "serviceInfo.button.editLocationCapacity": "Muokkaa paikkamäärää.",
  "serviceInfo.link.refreshPlaces": "Hae paikkatiedot",

  "modalWaivedInvoice.title": "Aseta lasku {invoiceNumber} aiheettomaksi",
  "modalWaivedInvoice.action.error": "Laskun asetus aiheettomaksi epäonnistui.",
  "modalWaivedInvoice.button.setAsWaived": "Aseta aiheettomaksi",
  "modalWaivedInvoice.action.info": "Voit asettaa laskun tilan aiheettomaksi. Huomioi, että uudelleenveloitusta ei voi enää tämän jälkeen yrittää vaikka esim. asiakkaan maksukortti vaihdettaisiin.",

  "modalRefundConfirmation.title": "Palautetaanko laskurivejä?",
  "modalRefundConfirmation.body": "Oletko varma, että haluat palauttaa {selectedCount} laskuriviä, yhteensä {revertSum}?",
  "modalRefundConfirmation.form.label.revertReceiverPhoneNumber": "Palautuksen saajan puhelinnumero",
  "modalRefundConfirmation.form.label.revertReceiver": "Palautuksen saaja",
  "modalRefundConfirmation.form.label.revertReason": "Palautuksen syy",
  "modalRefundConfirmation.checkbox.copyToClipboard": "Kopioi luotavan palautuslaskun numero automaattisesti leikepöydälle",

  "copyToClipboard.toast.copiedToClipboard.description": "{clipboardData} kopioitu leikepöydälle",
  "copyToClipboard.toast.copiedToClipboard.title": "Kopioitu leikepöydälle",

  "blocklistCandidates.content.action.addUsersToBlocklist.success.title": "Estolista",
  "blocklistCandidates.content.action.addUsersToBlocklist.success.description": "Käyttäjät asetettu estolistalle",
  "blocklistCandidates.content.action.error.default": "Käyttäjien siirto estolistalle epäonnistui.",
  "blocklistCandidates.content.label.hideBlockPreventedUsers": "Piilota estolistan estossa olevat käyttäjät",
  "blocklistCandidates.content.button.setChosenToBlocklist": "Aseta valitut estolistalle",
  "blocklistCandidates.content.label.chosenUsers": "Valittuna {count} käyttäjää",
  "blocklistCandidates.content.confirmation.title": "Aseta valitut käyttäjät estolistalle",
  "blocklistCandidates.content.confirmation.body": "Olet asettamassa {count} käyttäjää estolistalle. Oletko varma?",
  "blocklistCandidates.content.action.success.title": "Estolista",
  "blocklistCandidates.content.action.success.description": "Käyttäjät asetettu estolistalle",

  "blocklistCandidates.table.header.name": "Nimi",
  "blocklistCandidates.table.header.phone": "Puhelinnumero",
  "blocklistCandidates.table.header.totalFailedPrice": "Saatavat yhteensä",
  "blocklistCandidates.table.header.totalFailedInvoices": "Laskujen määrä",
  "blocklistCandidates.table.header.lastFailedInvoiceNumber": "Viimeinen laskun nro",
  "blocklistCandidates.table.header.adyenFinalFailureTime": "Adyen 3. epäonnistunut",
  "blocklistCandidates.table.header.userBlockingPreventedStart": "Estolistan esto asetettu",
  "blocklistCandidates.table.header.userBlockingPreventedComment": "Estolistan esto kommentti",

  "tabAdyenRefundInvoice.table.header.invoiceRow": "Laskurivi",
  "tabAdyenRefundInvoice.table.header.created": "Luotu",
  "tabAdyenRefundInvoice.table.header.status": "Tila",
  "tabAdyenRefundInvoice.table.header.service": "Tuote",
  "tabAdyenRefundInvoice.table.header.refundNumber": "Palautuslaskun numero",
  "tabAdyenRefundInvoice.table.header.comment": "Palautuksen kommentti",
  "tabAdyenRefundInvoice.table.header.price": "Hinta",
  "tabAdyenRefundInvoice.table.footer.total": "Yhteensä",

  "pauseAndBlocklistTable.header.blockBegin": "Aloitettu",
  "pauseAndBlocklistTable.header.blockEnd": "Lopetettu",
  "pauseAndBlocklistTable.header.blockCreatedByUserEmail": "Aloittaja",
  "pauseAndBlocklistTable.header.blockModifiedByUserEmail": "Lopettaja",
  "pauseAndBlocklistTable.header.blockType": "Tyyppi",
  "pauseAndBlocklistTable.header.comment": "Kommentti",

  "creditCoverCreditLossTable.table.title": "Tapahtumat {dateRange}",
  "creditCoverCreditLossTable.table.header.settlementRecipientName": "Maksunsaajan nimi",
  "creditCoverCreditLossTable.table.header.accountHolderCode": "Tilinhaltijan koodi",
  "creditCoverCreditLossTable.table.header.creditLoss": "Luottotappio",
  "creditCoverCreditLossTable.table.header.isReadyToBeCovered": "Valmis katettavaksi",

  "modalCoverCreditLoss.title": "Luottotappioiden kattaminen",
  "modalCoverCreditLoss.button.cover": "Kata luottotappio",
  "modalCoverCreditLoss.content.label.settlementRecipient": "Maksunsaaja",
  "modalCoverCreditLoss.content.label.accountHolderCode": "Tilinhaltijan koodi",
  "modalCoverCreditLoss.content.label.creditLoss": "Luottotappio (epäonnistuneet veloitukset)",
  "modalCoverCreditLoss.content.label.isReadyToBeCovered": "Valmis katettavaksi",
  "modalCoverCreditLoss.content.label.description": "Kommentti",
  "modalCoverCreditLoss.content.label.sale": "Myynti",
  "modalCoverCreditLoss.content.label.receivedPayments": "Saadut maksut",
  "modalCoverCreditLoss.content.label.countedCreditLoss": "Laskettu luottotappio",
  "modalCoverCreditLoss.content.info.noMatchingCreditLoss": "Laskennallinen luottotappio ei täsmää epäonnistuneiden veloitusten luottotappioon. Erotus on {difference}. Luottotappiota ei voi kattaa.",

  "modalCoverCreditLoss.confirmation.body": "Haluatko varmasti kattaa maksunsaajan {name} luottotappion {amountOfcreditLoss} ajalta {dateRange}?",
  "modalCoverCreditLoss.coverCreditLoss.action.error": "Luottotappion kattaminen epäonnistui.",
  "modalCoverCreditLoss.operation.description": "Katetaan luottotappio ({dateRange})",
  "modalCoverCreditLoss.action.coverCreditLoss.success.title": "Luottotappioiden kattaminen",
  "modalCoverCreditLoss.action.coverCreditLoss.success.description": "Luottotappioiden kattaminen onnistui.",

  "createInvoice.action.success": "Uusi lasku on luotu",
  "createInvoice.action.error": "Laskun luonnissa tapahtui virhe",
  "createInvoiceModal.header": "Uusi Visma-lasku",
  "createInvoiceModal.button.sendInvoice": "Lähetä lasku",
  "createInvoiceModal.form.label.settlementRecipient": "Maksunsaaja",
  "createInvoiceModal.form.placholder.settlementRecipient": "Valitse maksunsaaja",
  "createInvoiceModal.form.label.product": "Tuote",
  "createInvoiceModal.form.placeholder": "Valitse tuote",
  "createInvoiceModal.form.label.sum": "Summa",
  "createInvoiceModal.form.label.detail": "Lisätieto",
  "createInvoiceModal.form.label.incurredAt": "Kirjauspäivä",
  "createInvoiceModal.form.button.addRow": "Lisää rivi",
  "createInvoiceModal.invoiceRowTable.header.product": "Tuote",
  "createInvoiceModal.invoiceRowTable.header.operator": "Operaattori",
  "createInvoiceModal.invoiceRowTable.header.recipient": "Saaja",
  "createInvoiceModal.invoiceRowTable.header.total": "Summa",
  "createInvoiceModal.invoiceRowTable.header.detail": "Lisätieto",
  "createInvoiceModal.invoiceRowTable.header.incurredAt": "Kirjauspäivä",

  "invoiceAdministration.info.header": "Onnistui",
  "invoiceAdministration.error.header": "Virhe",
  "invoiceAdministration.invoiceRows.error.content": "Laskurivien lataamisessa tapahtui virhe",

  "vismaInvoiceAdministration.creditNote.button": "Tee hyvityslasku",
  "vismaInvoiceAdministration.creditNote.modal.content": "Oletko varma, että haluat luoda laskulle hyvityslaskun?",
  "vismaInvoiceAdministration.creditNote.modal.accept": "Hyväksy",
  "vismaInvoiceAdministration.creditNote.success": "Laskusta on luotu hyvityslasku",
  "vismaInvoiceAdministration.creditNote.error": "Hyvityslaskun luonnissa tapahtui virhe",
  "vismaInvoiceAdministration.revokeDebtCollection.button": "Peruuta perintä",
  "vismaInvoiceAdministration.revokeDebtCollection.confirm": "Oletko varma, että haluat peruuttaa perintälaskun?",
  "vismaInvoiceAdministration.createCustomInvoice.confirm": "Oletko varma, että haluat luoda uuden laskun?",
  "vismaInvoiceAdministration.createCustomInvoice.button": "Tee uusi lasku",
  "vismaInvoiceAdministration.createCustomInvoice.submit": "Luo uusi lasku valituista",
  "vismaInvoiceAdministration.createCustomInvoice.error": "Uuden laskun luonnissa tapahtui virhe",
  "vismaInvoiceAdministration.createCustomInvoice.noSelectedRows": "Ei valittuja laskurivejä",
  "vismaInvoiceAdministration.createCustomInvoice.selectedRows": "{count} laskuriviä valittu, yhteensä {totalPrice}.",
  "vismaInvoiceAdministration.maxRevert": "Laskusta on valittavissa maksimissaan {sum}.",
  "vismaInvoiceAdministration.revokeDebtCollection.info": "Perinnän peruutus peruuttaa kaikki laskurivit. Valitse laskurivit joista luodaan peruutuksen yhteydessä uudet verkkomaksut.",
  "vismaInvoiceAdministration.revokeDebtCollection.comment": "Peruutuksen syy",
  "vismaInvoiceAdministration.revokeDebtCollection.success": "Perintä peruutettu",
  "vismaInvoiceAdministration.revokeDebtCollection.error": "Perinnän peruutus epäonnistui",
  "vismaInvoiceAdministration.createCustomInvoice.toast.success.title": "Lasku luotu",
  "vismaInvoiceAdministration.createCustomInvoice.toast.success.body": "Uusi lasku tallennettu",
  "vismaInvoiceAdministration.clearAll": "Tyhjennä",

  "invoiceRowTable.product": "Tuote",
  "invoiceRowTable.totalPrice": "Kokonaishinta",
  "invoiceRowTable.maxRevert": "Hyvitettävissä",
  "invoiceRowTable.VAT": "ALV%",
  "invoiceRowTable.description": "Rivin kuvaus",

  "moovyTableFilter.filter.shortcut.today": "Tänään",
  "moovyTableFilter.filter.shortcut.week": "Viikko",
  "moovyTableFilter.filter.shortcut.chooseTimeRange": "Valitse aikaväli",
  "moovyTableFilter.filter.placeholder.chooseTimeRange": "Alku - loppu",

  "moovyDateRangePicker.button.today": "Tänään",
  "moovyDateRangePicker.error.empty": "Syötä aikaväli.",
  "moovyDateRangePicker.error.invalidValue": "Virheellinen aikaväli.",
  "moovyDateRangePicker.error.overMaxDateRange": "Valittu aikaväli on yli {days} pv.",
  "moovyDateRangePicker.error.MinIsOverMaxDate": "Aloituspäivä on suurempi kuin lopetuspäivä.",

  "voucherTemplates.statusOption.active": "Aktiiviset",
  "voucherTemplates.statusOption.inactive": "Käytöstä poistetut",
  "voucherTemplates.toolbar.title": "Pysäköintiedut",
  "voucherTemplates.toolbar.search": "Hae",
  "voucherTemplates.toolbar.search.placeholder": "Hae pysäköintietua",
  "voucherTemplates.toolbar.search.invalid": "Syötä vähintään kolme merkkiä.",
  "voucherTemplates.toolbar.operator.placeholder": "Valitse operaattori",
  "voucherTemplates.toolbar.location.placeholder": "Valitse kohde",
  "voucherTemplates.toolbar.location.disabled.placeholder": "Valitse ensin operaattori",
  "voucherTemplates.createVoucherTemplate": "Luo uusi pysäköintietu",
  "voucherTemplates.createVoucherTemplate.success": "Etu lisätty",
  "voucherTemplates.updateVoucherTemplate.success": "Etu päivitetty",
  "voucherTemplates.createVoucherTemplate.error": "Uuden pysäköintiedun luonti epäonnistui.",
  "voucherTemplates.updateVoucherTemplate.error": "Pysäköintiedun päivitys epäonnistui.",
  "voucherTemplates.button.popup.grantVoucher": "Myönnä etu",
  "voucherTemplates.button.popup.edit": "Muokkaa etua",
  "voucherTemplates.grantVoucher.error": "Pysäköintiedun myöntäminen epäonnistui.",

  "modalInvoice.tab.waived.title": "Aiheeton veloitus",
  "modalInvoice.tab.invoiceRows.title": "Laskurivit",
  "modalInvoice.tab.refunds.title": "Korttihyvitykset",
  "modalInvoice.tab.refundsComments.title": "Hyvitysten kommentit",
  "modalInvoice.toast.title.paymentPostChargeSuccess": "Jälkiveloitus",
  "modalInvoice.toast.description.paymentPostChargeSuccess": "Jälkiveloitus onnistui",

  "invoiceRefundComments.table.header.comments": "Hyvitysten kommentit",
  "invoiceRefundComments.table.body.noContent": "Ei sisältöä",

  "testServiceValidity.button.addRow": "Lisää rivi",
  "testServiceValidity.button.test": "Testaa voimassaoloajat",
  "testServiceValidity.query.error": "Voimassaolon testaamisessa tapahtui virhe",
  "testServiceValidity.serviceValid": "Tuote on voimassa",
  "testServiceValidity.serviceInvalid": "Tuote ei ole voimassa",

  "moovySearchInput.search.placeholder": "Hae",

  "GeoJSONEditor.value.label": "Alue GeoJSON-muodossa",
  "GeoJSONEditor.value.placeholder": "Alue (GeoJSON)",
  "GeoJSONEditor.preview.label": "Alue GeoJSON-muodossa, esikatselu",

  "modalEditCapacities.title": "Muokkaa tuotteen {name} kapasiteetteja",
  "modalEditCapacities.label.totalCapacity": "Tuotteen maksimitilausmäärä",
  "modalEditCapacities.label.parkingCapacity": "Pysäköintikapasiteetti yhtäaikaisille pysäköinneille",
  "modalEditCapacities.label.parkingCapacity.info.header": "Tähän syötetyllä arvolla voidaan rajoittaa yhtäaikaisten pysäköintien määrää puomillisissa kameratunnistuskohteissa.",
  "modalEditCapacities.label.parkingCapacity.info.bullet1": "Rajoitus ei toimi, eikä se ole tarkoitettu käytettäväksi puomittomissa kohteissa.",
  "modalEditCapacities.label.parkingCapacity.info.bullet2": "Rajoitus voidaan ohittaa, jos asiakas käynnistää pysäköinnin mobiilisovelluksesta tai operaattori käynnistää pysäköinnin käyttöliittymästä.",
  "modalEditCapacities.label.exceedingParkingCapacityAllowed": "Salli pysäköintikapasiteetin ylittäminen",
  "modalEditCapacities.label.exceedingParkingCapacityAllowedForSubscription": "Yhtäaikaiset sopimuspysäköinnit saavat ylittää määritetyn pysäköintikapasiteetin.",
  "modalEditCapacities.label.exceedingParkingCapacityAllowedForPAYG": "Yhtäaikaiset lyhytaikapysäköinnit saavat ylittää määritetyn pysäköintikapasiteetin.",
  "modalEditCapacities.prebookingVoucherPrediction.header": "{days, plural, one {Seuraavan yhden} other {Seuraavien #}} päivän ennakkovaraukset",
  "modalEditCapacities.prebookingVoucherPrediction.prediction": "{prediction} kpl",
  "modalEditCapacities.prebookingVoucherPrediction.forecast": "Arvioitu ennakkovarauksien määrä tälle päivälle <b>{prebookedToday} kpl</b>, suosittelemme pysäköinnin kapasiteetiksi <span>{capacityRecommendation} kpl</span>",
  "modalEditCapacities.action.error.update": "Tietojen tallennus epäonnistui.",
  "modalEditCapacities.toast.title": "Tuotteen kapasiteettia päivitetty",
  "modalEditCapacities.toast.body": "Tuotteen {name} kapasiteettia päivitetty.",

  "modalEditOverBooking.title": "Muokkaa tuotteen {name} ylibuukkkauskerroilta",
  "modalEditOverBooking.label.userAssignmentOverbookingCoefficient": "Ylibuukkauskerroin",
  "modalEditOverBooking.action.error.update": "Ylibuukkauskertoimen tallennus epäonnistui.",
  "modalEditOverBooking.toast.title": "Tuotteen ylibuukkauskerroin päivitetty.",
  "modalEditOverBooking.toast.body": "Tuotteen {name} ylibuukkauskerroin päivitetty.",

  "modalEditVisibility.title": "Muokkaa tuotteen {name} näkyvyyttä",
  "modalEditVisibility.label.visibility": "Tuotteen näkyvyys",
  "modalEditVisibility.action.error.update": "Näkyvyyden tallennus epäonnistui.",
  "modalEditVisibility.toast.title": "Tuotteen näkyvyyttä päivitetty.",
  "modalEditVisibility.toast.body": "Tuotteen {name} näkyvyyttä päivitetty.",
  "modalEditVisibility.visibility.PUBLIC": "Julkinen",
  "modalEditVisibility.visibility.PRIVATE": "Yksityinen",

  "modalEditName.title": "Muokkaa tuotteen {name} nimeä",
  "modalEditName.label.name": "Tuotteen nimi",
  "modalEditName.action.error.update": "Nimen tallennus epäonnistui.",
  "modalEditName.toast.title": "Tuotteen nimi päivitetty.",
  "modalEditName.toast.body": "Tuotteen {name} nimi päivitetty.",

  "parkingNowTab.button.startAnonymousParking": "Aloita anonyymi pysäköinti",
  "parkingNowTab.toast.title.parkingStarted": "Pysäköinti aloitettu",
  "parkingNowTab.toast.body.parkingStarted": "Pysäköinti alkoi ajoneuvolle {licensePlate}",

  "parkingNowContent.ongoingParking": "Käynnissä olevat pysäköinnit {pcs} kpl",

  "parkingNowFilters.label.exitTimeExceeded": "Poistumisaika ylittynyt",
  "parkingNowFilters.label.checkbox.exitTimeExceeded": "Näytä vain ajan ylittäneet",
  "parkingNowFilters.form.label.parkingType": "Tyyppi",
  "parkingNowFilters.form.label.serviceType": "Pysäköintitapa",
  "parkingNowFilters.form.label.licensePlate": "Rekisteritunnus",
  "parkingNowFilters.form.label.refreshView": "Päivitä näkymä",

  "parkingEndedContent.ongoingParking": "Päättyneet pysäköinnit {pcs} kpl",

  "parkingEndedFilters.popup.content.car": "Haku rekisteritunnuksella",
  "parkingEndedFilters.popup.content.calendar": "Haku aikavälillä",
  "parkingEndedFilters.button.label.or": "tai",
  "parkingEndedFilters.form.label.parkingType": "Tyyppi",
  "parkingEndedFilters.form.label.licensePlate": "Rekisteritunnus (väh. 2 merkkiä)",
  "parkingEndedFilters.form.label.dataRange": "Valitse aikaväli (max {days} pv)",
  "parkingEndedFilters.form.label.dataRange.info": "Haetaan pysäköintejä jotka ovat loppuneet hakuaikavälillä.",

  "moovyRefreshTime.label.refresh.notRefreshed": "Tietoja ei ole vielä päivitetty.",
  "moovyRefreshTime.label.refresh.oldData": "Tiedot vanhentuneet, päivitä näkymä.",
  "moovyRefreshTime.label.refresh.refreshedInSeconds": "Päivitetty alle {seconds} s sitten.",
  "moovyRefreshTime.label.refresh.refreshedInMinutes": "Päivitetty alle {minutes} min sitten.",
  "moovyRefreshTime.label.refresh.refreshedInMinutesAndSeconds": "Päivitetty alle {minutes} min {seconds} s sitten.",

  "modalEditEnterpriseUser.toast.title.userRemoved": "Yrityskäyttäjä poistettu",
  "modalEditEnterpriseUser.toast.description.userRemove": "Yrityskäyttäjä on poistettu onnistuneesti.",
  "modalEditEnterpriseUser.title.editUser": "Yrityskäyttäjän tiedot",
  "modalEditEnterpriseUser.action.edit.default.error": "Yritystilimuutosten tallennus epäonnistui.",
  "modalEditEnterpriseUser.label.company": "Yritys",
  "modalEditEnterpriseUser.label.businessCode": "Y-tunnus",
  "modalEditEnterpriseUser.label.contact": "Yhteyshenkilö",
  "modalEditEnterpriseUser.button.tooltip.deleteUser": "Poista yritystili",

  "accessLogFilters.form.label.licensePlate": "Rekisteritunnus (väh. 2 merkkiä)",
  "accessLogFilters.form.label.dataRange": "Valitse aikaväli (max {days} pv)",

  "modalLicensePlateRecognitionStateHistory.modal.header": "Kameratunnistuksen historia",
  "modalLicensePlateRecognitionStateHistory.label.licensePlate": "Rekisteritunnus:",
  "modalLicensePlateRecognitionStateHistory.header.edited": "Muokattu",
  "modalLicensePlateRecognitionStateHistory.header.editor": "Muokkaaja",
  "modalLicensePlateRecognitionStateHistory.header.cameraRecognition": "Kameratunnistus",
  "modalLicensePlateRecognitionStateHistory.header.owner": "Omistaja",

  "grantedVouchersTable.table.header.licensePlateNumber": "Rekisteritunnus",
  "grantedVouchersTable.table.header.voucherOwner": "Edunsaaja",
  "grantedVouchersTable.table.header.operator": "Operaattori",
  "grantedVouchersTable.table.header.locationsDescription": "Kohteiden kuvaus",
  "grantedVouchersTable.table.header.granted": "Myönnetty",
  "grantedVouchersTable.table.header.validFrom": "Voimassa alkaen",
  "grantedVouchersTable.table.header.validUntil": "Voimassa asti",
  "grantedVouchersTable.table.header.status": "Tila",
  "grantedVouchersTable.enum.status.expired": "Päättynyt",
  "grantedVouchersTable.enum.status.notValidYet": "Ei vielä voimassa",
  "grantedVouchersTable.enum.status.valid": "Voimassa",
  "grantedVouchersTable.button.deleteVoucher": "Poista käyttämätön etu",
  "grantedVouchersTable.deleteVoucher.confirm.title": "Poistetaanko myönnetty etu saajalta {name}?",
  "grantedVouchersTable.deleteVoucher.confirm.body": "Haluatko varmasti poistaa pysäköintiedun?",
  "grantedVouchersTable.deleteVoucher.action.error": "Pysäköintiedun poistaminen epäonnistui. Huomioi, että vain käyttämättömän pysäköintiedun voi poistaa.",
  "grantedVouchersTable.deleteVoucher.action.success.title": "Pysäköintietu poistettu",
  "grantedVouchersTable.deleteVoucher.action.success.description": "Pysäköintiedun poistaminen onnistui.",

  "blocklist.allowAutomaticUnblock.true": "Epäonnistuneet veloitukset",
  "blocklist.allowAutomaticUnblock.false": "Muu syy",

  "modalStartParking.header": "Aloita uusi pysäköinti",
  "modalStartParking.button.start": "Aloita pysäköinti",
  "modalStartParking.action.error": "Pysäköinnin aloittamisessa tapahtui virhe",
  "modalStartParking.toast.success.title.moovyParking": "Moovy-pysäköinti aloitettu",
  "modalStartParking.toast.success.title.anonymousParking": "Anonyymipysäköinti aloitettu",
  "modalStartParking.toast.success.body.moovyParking": "Moovy-pysäköinti aloitettu ajoneuvolle {licensePlate}.",
  "modalStartParking.toast.success.body.anonymousParking": "Anonyymipysäköinti aloitettu ajoneuvolle {licensePlate}.",
  "modalStartParking.toast.success.body.anonymousParkingWithLocation": "Anonyymipysäköinti aloitettu ajoneuvolle {licensePlate} kohteessa {location}.",
  "modalStartParking.warning.noActivePayment": "Käyttäjällä ei ole voimassaolevaa maksutapaa",
  "modalStartParking.warning.noCameraRecognition": "Rekisteritunnuksella ei ole kameratunnistus päällä. Pysäköinti käynnistyy anonyyminä, ellei ajoneuvolla ole kohteessa sopimusta. Pysäköinnin tyyppi näkyy käynnistettäessä.",
  "modalStartParking.warning.servicesError": "Kohteen pysäköintituotteiden haku epäonnistui",
  "modalStartParking.form.label.startTime": "Aloitusaika",
  "modalStartParking.form.label.location": "Kohde",
  "modalStartParking.form.label.service": "Aloita pysäköinti tuotteella",
  "modalStartParking.confirm.moovyAccount": "Rekisteritunnuksella löytyy Moovy-tili. Haluatko jatkaa?",

  "modalCancelWebPayment.sale.header": "Verkkomaksun peruutus",
  "modalCancelWebPayment.debtCollection.header": "Perintäprosessin hylkäys",
  "modalCancelWebPayment.sale.comment": "Peruutuksen syy",
  "modalCancelWebPayment.debtCollection.comment": "Hylkäyksen syy",
  "modalCancelWebPayment.sale.submitButton": "Peruuta verkkomaksu",
  "modalCancelWebPayment.debtCollection.submitButton": "Hylkää perintäprosessi",

  "potentiallyIncorrectLicensePlates.operator.placeholder": "Valitse operaattori",
  "potentiallyIncorrectLicensePlates.location.placeholder.noOperator": "Valitse ensin operaattori",
  "potentiallyIncorrectLicensePlates.location.placeholder": "Valitse kohde",
  "potentiallyIncorrectLicensePlates.table.location": "Kohde",
  "potentiallyIncorrectLicensePlates.table.start": "Alkamisaika",
  "potentiallyIncorrectLicensePlates.table.duration": "Kesto",
  "potentiallyIncorrectLicensePlates.table.licensePlate": "Rekisteritunnus",
  "potentiallyIncorrectLicensePlates.table.suspectReason": "Syy",

  "potentiallyIncorrectLicensePlate.breadcrumb.home": "Koti",
  "potentiallyIncorrectLicensePlate.breadcrumb.potentiallyIncorrectLicensePlates": "Rekisteritunnistuksen tarkistus",
  "potentiallyIncorrectLicensePlate.breadcrumb.potentiallyIncorrectLicensePlate": "Rekisteritunnistus",
  "potentiallyIncorrectLicensePlate.navigation.previous": "Edellinen",
  "potentiallyIncorrectLicensePlate.navigation.next": "Seuraava",
  "potentiallyIncorrectLicensePlate.navigation.back": "Takaisin tuloksiin",
  "potentiallyIncorrectLicensePlate.queryError": "Tunnistuksen tietojen lataamisessa tapahtui virhe",
  "potentiallyIncorrectLicensePlate.parkingInfo.header": "Pysäköinti",
  "potentiallyIncorrectLicensePlate.parkingInfo.start": "Alkoi",
  "potentiallyIncorrectLicensePlate.parkingInfo.end": "Päättyi",
  "potentiallyIncorrectLicensePlate.parkingInfo.duration": "Kesto",
  "potentiallyIncorrectLicensePlate.parkingInfo.location": "Kohde",
  "potentiallyIncorrectLicensePlate.suspectReason.UNEXPECTED_LENGTH": "Epätavallinen pituus",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.DIRTY": "Lumi/lika/loska",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.DAMAGED": "Vioittunut kilpi",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.EXTERNAL_OBSTACLE": "Kameran näköeste",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.SIMILAR_ALPHANUMERICS": "Samankaltainen merkki",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.OBSCURING_ACCESSORY": "Auton lisälaite",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.PLATE_OK": "Tunnistunut oikein",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.OTHER": "Muu tunnistusvirhe",
  "potentiallyIncorrectLicensePlate.reviewedReasonCategory.SPECIAL": "Ulkomainen/erikoiskilpi",
  "potentiallyIncorrectLicensePlate.status.OPEN": "Tarkistamaton",
  "potentiallyIncorrectLicensePlate.status.FIXED": "Korjattu",
  "potentiallyIncorrectLicensePlate.status.CONFIRMED": "Vahvistettu oikeaksi",
  "potentiallyIncorrectLicensePlate.status.INCORRECT": "Vahvistettu vääräksi",
  "potentiallyIncorrectLicensePlate.status.UNIDENTIFIED": "Epäselvä rekisteritunnus",
  "potentiallyIncorrectLicensePlate.status.CLOSED": "Siivottu",
  "potentiallyIncorrectLicensePlate.editSegment.header": "Tarkista rekisteritunnus",
  "potentiallyIncorrectLicensePlate.editSegment.status": "Tila",
  "potentiallyIncorrectLicensePlate.editSegment.suspectReason": "Syy",
  "potentiallyIncorrectLicensePlate.editSegment.parkingEnded": "Pysäköinti on päättynyt, tunnistuksen tietoja ei voi enää päivittää",
  "potentiallyIncorrectLicensePlate.editSegment.chosenReasonCategory": "Valittu syykategoria",
  "potentiallyIncorrectLicensePlate.editSegment.confirmButton": "Oikein",
  "potentiallyIncorrectLicensePlate.editSegment.unidentifiedButton": "Ei luettavissa",
  "potentiallyIncorrectLicensePlate.editSegment.fixButton": "Korjaa",
  "potentiallyIncorrectLicensePlate.editSegment.selectReviewedReasonCategory": "Valitse syykategoria",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal": "Korjaa rekisteritunnus",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.licensePlate": "Rekisteritunnus",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.licensePlateMissing": "Syötä rekisteritunnus",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.checkButton": "Tarkista rekisteritunnus",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.fixButton": "Korjaa rekisteritunnus",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.usersFound": "Rekisteritunnuksella löytyi seuraavat Moovy -tilit",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.usersFoundInfo": "Käynnissä oleva pysäköinti lopetetaan ilman veloitusta ja uusi pysäköinti käynnistetään oikealla rekisteritunnuksella ja alkuperäisellä aloitusajalla",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.usersNotFound": " Rekisteritunnukselle ei löytynyt Moovy -tilejä",
  "potentiallyIncorrectLicensePlate.editSegment.fixModal.usersNotFoundInfo": "Korjataan käynnissä olevan pysäköinnin rekisteritunnus."
}
