import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Dropdown, Message, Modal } from 'semantic-ui-react'

export const SERVICE_AGREEMENT = 'sa'
export const VOUCHER_AGREEMENT = 'va'
const AddAgreementModal = ({
  open,
  onClose,
  onSubmit,
  options,
  mutation,
  type
}) => {
  const [selected, setSelected] = useState(null)
  const intl = useIntl()

  const noResultsMessage = intl.formatMessage({ id: 'common.noResultsMessage' })
  const headerId =
    type === SERVICE_AGREEMENT
      ? 'addAgreementModal.header.serviceAgreement'
      : 'addAgreementModal.header.voucherAgreement'
  const errorId =
    type === SERVICE_AGREEMENT
      ? 'addAgreementModal.errorMessage.serviceAgreement'
      : 'addAgreementModal.errorMessage.voucherAgreement'

  const placeholderId =
    type === SERVICE_AGREEMENT
      ? 'addAgreementModal.placeholder.serviceAgreement'
      : 'addAgreementModal.placeholder.voucherAgreement'
  return (
    <Modal onClose={onClose} open={open}>
      <Modal.Header>
        <FormattedMessage id={headerId} />
      </Modal.Header>
      <Modal.Content>
        {mutation.isError && (
          <Message error>
            <FormattedMessage id={errorId} />
          </Message>
        )}
        <Dropdown
          fluid
          value={selected}
          onChange={(e, { value }) => setSelected(value)}
          options={options}
          search
          selection
          scrolling
          placeholder={intl.formatMessage({ id: placeholderId })}
          noResultsMessage={noResultsMessage}
          selectOnBlur={false}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          primary
          disabled={!selected}
          loading={mutation.isPending}
          onClick={() => onSubmit(selected)}
        >
          <FormattedMessage id="common.button.accept" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default AddAgreementModal
