import moment from 'moment'
import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { DateInput } from 'semantic-ui-calendar-react'
import { Button, Form, Header, Message, Modal, Radio } from 'semantic-ui-react'

import useAuthInfo from '../../../hooks/useAuthInfo'
import subscriptionsService from '../../../services/Subscriptions'
import { formatDate } from '../../../services/utils'

export default function CancelSubscriptionModal({
  subscription,
  service,
  onClose,
  onUpdate
}) {
  const [endDate, setEndDate] = useState('')
  const [endMode, setEndMode] = useState('')
  const { operatorRealm } = useAuthInfo()

  const endModes = {
    NOW: 'now',
    LATER: 'later'
  }

  const { mutate: cancelSubscription, ...cancelSubscriptiontMutation } =
    useMutation({
      mutationFn: () =>
        subscriptionsService.cancelSubscription(
          subscription.ref,
          endMode === endModes.NOW
            ? moment.utc(moment().subtract(1, 'days'))
            : moment.utc(endDate, 'DD.MM.YYYY'),
          operatorRealm
        ),

      onSuccess: () => {
        onClose()
        onUpdate()
      }
    })

  if (!subscription) {
    return null
  }

  const utcDate = moment.utc(endDate, 'DD.MM.YYYY', true)
  const endDateValid =
    endMode === endModes.NOW ||
    (utcDate.isValid() && utcDate.isSameOrAfter(moment().startOf('day')))

  return (
    <Modal open onClose={onClose}>
      <Modal.Header>Tilauksen irtisanominen</Modal.Header>
      <Modal.Content>
        {cancelSubscriptiontMutation.isError && (
          <Message error>Tilauksen irtisanomisessa tapahtui virhe</Message>
        )}
        <Header size="small" content="Tuote" subheader={service.name} />
        <Header
          size="small"
          content="Tilaus alkoi"
          subheader={formatDate(subscription.startDate)}
        />
        <Form>
          <Form.Group inline>
            <label>Irtisano tilaus</label>
            <Form.Field
              control={Radio}
              label="Heti"
              value={endModes.NOW}
              checked={endMode === endModes.NOW}
              onChange={(e, { value }) => setEndMode(value)}
            />
            <Form.Field
              control={Radio}
              label="Myöhemmin"
              value={endModes.LATER}
              checked={endMode === endModes.LATER}
              onChange={(e, { value }) => setEndMode(value)}
            />
          </Form.Group>

          {endMode === endModes.LATER && (
            <Form.Field>
              <DateInput
                dateFormat="DD.MM.YYYY"
                name="endDate"
                label="Aseta päättymispäivä"
                iconPosition="left"
                value={endDate}
                onChange={(event, { value }) => setEndDate(value)}
                animation="none"
                closable
                error={!endDateValid}
              />
            </Form.Field>
          )}

          <Button
            fluid
            primary
            style={{ marginBottom: '1em' }}
            onClick={cancelSubscription}
            loading={cancelSubscriptiontMutation.isPending}
            disabled={!endMode || !endDateValid}
          >
            Irtisano tilaus
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  )
}
