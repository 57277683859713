import { get, post, put, remove } from '../utils/api-renewed'

import { getFormattedLocationName } from '../utils'

const fetchVoucherTemplates = async (operatorRealm) => {
  try {
    const path = operatorRealm
      ? `/operator/${operatorRealm}/voucherTemplate`
      : `/administration/voucherTemplate`

    const response = await get(path)
    return response
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchVoucherTemplatesPaginated = (operatorRealm, queryParams) => {
  const path = operatorRealm
    ? `/operator/${operatorRealm}/voucherTemplatePagination`
    : `/administration/voucherTemplatePagination`

  return get(path, queryParams)
}

const fetchVoucherTemplateByRef = async (operatorRealm, voucherTemplateRef) => {
  try {
    const path = operatorRealm
      ? `/operator/${operatorRealm}/voucherTemplate/${voucherTemplateRef}`
      : `/administration/voucherTemplate/${voucherTemplateRef}`

    const response = await get(path)
    return response
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchVoucherTemplateByUserRef = async (operatorRealm, userRef) => {
  try {
    const path = operatorRealm
      ? `/operator/${operatorRealm}/voucherTemplate/user/${userRef}`
      : `/administration/voucherTemplate/user/${userRef}`

    const response = await get(path)
    return response
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const createVoucherTemplate = async (operatorRealm, voucherTemplate) => {
  if (voucherTemplate) {
    try {
      const response = await post(
        `/administration/${operatorRealm}/voucherTemplate`,
        voucherTemplate
      )
      return response
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('No voucher template specified'))
}

const updateVoucherTemplate = async (
  operatorRealm,
  voucherTemplateRef,
  voucherTemplate
) => {
  if (voucherTemplate) {
    try {
      const response = await put(
        `/administration/${operatorRealm}/voucherTemplate/${voucherTemplateRef}`,
        voucherTemplate
      )
      return response
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('No voucher template specified'))
}

const addVoucherGrantAgreement = async (
  voucherTemplateRef,
  userGroupRef,
  operatorRealm
) => {
  const prefix = operatorRealm
    ? `/operator/${operatorRealm}/voucherTemplate`
    : `/administration/voucherTemplate`
  try {
    const result = await post(
      `${prefix}/${voucherTemplateRef}/grant/${userGroupRef}`
    )
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

const deleteVoucherGrantAgreement = async (ref, operatorRealm) => {
  const prefix = operatorRealm
    ? `/operator/${operatorRealm}`
    : `/administration`
  try {
    const result = await remove(`${prefix}/voucherGrantAgreements/${ref}`)
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export default {
  fetchVoucherTemplates,
  fetchVoucherTemplatesPaginated,
  fetchVoucherTemplateByRef,
  fetchVoucherTemplateByUserRef,
  createVoucherTemplate,
  updateVoucherTemplate,
  addVoucherGrantAgreement,
  deleteVoucherGrantAgreement
}

export const getVoucherTemplateDescription = (template) => {
  if (!template) {
    return ''
  }
  const locationDesc = getVoucherTemplateLocationDescription(template)
  if (template.customDescription) {
    return `${locationDesc} (${template.customDescription})`
  }
  if (template.description) {
    return `${locationDesc} (${template.description})`
  }

  return locationDesc
}

export const getVoucherTemplateLocationDescription = (template) => {
  if (!template) {
    return ''
  }
  const locationDesc = template.validLocationsDescription
  if (locationDesc) {
    return locationDesc
  }
  if (
    template.validityLocationRefs &&
    template.validityLocationRefs.length > 0
  ) {
    const content = template.validityLocationRefs.map((location) => {
      return getFormattedLocationName(location)
    })
    return content.join(', ')
  }

  return ''
}
