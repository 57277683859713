import { ModalAdyenInvoice, ModalInvoice } from '../../components'
import React, { useEffect, useState } from 'react'
import { sale, tablePaginationDirections } from '../../services/utils'

import { Grid } from 'semantic-ui-react'
import InvoiceTable from './InvoiceTable'
import InvoicesToolbar from './InvoicesToolbar'
import invoiceService from '../../services/Invoicing'
import moovyDateTime from '../../services/utils/moovyDateTime'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useQuery } from '@tanstack/react-query'

export default function Invoices() {
  const [state, setState] = useGlobalUI({
    key: 'Invoices',
    initialValue: {
      createdFromTime: moovyDateTime.dateNowToCalendarInput(),
      createdUntilTime: moovyDateTime.dateNowToCalendarInput(),
      tableFilterRange: '',
      tableCustomStartTime: '',
      tableCustomEndTime: '',
      paymentMethod: '',
      invoiceStatus: '',
      invoiceNumberOrPspRef: '',
      hideZeroPrice: false,
      page: 0,
      limit: 20,
      sort: 'sortByInvoiceNumber',
      direction: tablePaginationDirections.DESC
    }
  })

  // Cache data for 1 minute
  const QUERY_CACHE_TIME = 1000 * 60

  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [queryStaleTime, setQueryStaleTime] = useState(QUERY_CACHE_TIME)

  const onChangeFilter = (name, value) => {
    if (name === 'dateRange') {
      const newValues = {
        ...state,
        page: 0,
        createdFromTime: value.createdFromTime,
        createdUntilTime: value.createdUntilTime,
        tableFilterRange: value.tableFilterRange,
        tableCustomStartTime: moovyDateTime.jsDateToISOString(
          value.tableCustomStartTime
        ),
        tableCustomEndTime: moovyDateTime.jsDateToISOString(
          value.tableCustomEndTime
        )
      }
      setState({
        ...newValues
      })
    } else {
      if (name === 'paymentMethod') {
        const newValues = {
          ...state,
          page: 0,
          [name]: value,
          invoiceStatus: ''
        }
        setState({
          ...newValues
        })
      } else {
        let newValue = value
        if (name === 'invoiceNumberOrPspRef') {
          newValue = value?.trim()
        }
        const newValues = { ...state, page: 0, [name]: newValue }
        setState({
          ...newValues
        })
      }
    }
  }

  const invoicesQuery = useQuery({
    queryKey: [
      'invoicesQuery',
      state.createdFromTime,
      state.createdUntilTime,
      state.paymentMethod,
      state.invoiceStatus,
      state.invoiceNumberOrPspRef,
      state.hideZeroPrice,
      state.page,
      state.sort,
      state.direction
    ],
    queryFn: () => {
      const params = { ...state }
      params.createdFromTime = moovyDateTime.calendarDateInputToBeginOfDayISO(
        params.createdFromTime
      )
      params.createdUntilTime = moovyDateTime.calendarDateInputToEndOfDayISO(
        params.createdUntilTime
      )
      params.paymentMethod =
        state.invoiceNumberOrPspRef.length > 2
          ? undefined
          : params.paymentMethod
      params.invoiceStatus =
        state.invoiceNumberOrPspRef.length > 2
          ? undefined
          : params.invoiceStatus
      params.hideZeroPrice = state.hideZeroPrice || undefined

      return invoiceService.fetchInvoices({ ...params })
    },
    staleTime: queryStaleTime,
    enabled: Boolean(
      (state.paymentMethod && state.invoiceNumberOrPspRef.length === 0) ||
        state.invoiceNumberOrPspRef.length > 2
    )
  })

  useEffect(() => {
    if (invoicesQuery.isSuccess) {
      queryStaleTime !== QUERY_CACHE_TIME && setQueryStaleTime(QUERY_CACHE_TIME)
    }
  }, [invoicesQuery.isSuccess])
  const setActivePage = (newActivePage) => {
    setState({
      ...state,
      page: newActivePage - 1
    })
  }

  const setSort = (column, direction) => {
    setState({
      ...state,
      sort: column,
      direction
    })
  }

  const refetchInvoices = () => {
    if (
      (state.paymentMethod && state.invoiceNumberOrPspRef.length === 0) ||
      state.invoiceNumberOrPspRef.length > 2
    ) {
      setQueryStaleTime(0)
    }
  }

  useEffect(() => {
    if (queryStaleTime !== QUERY_CACHE_TIME) {
      // If the query refetch is wanted without cache,
      // queryStaleTime will be set to zero and then the function below will trigger the backend call.
      invoicesQuery.refetch()
    }
  }, [queryStaleTime])

  return (
    <>
      <InvoicesToolbar state={state} onChangeFilter={onChangeFilter} />
      <div className="Admin--Page--Content">
        <Grid stackable>
          <Grid.Row columns="1">
            <Grid.Column />
          </Grid.Row>
          <Grid.Row columns="1">
            <Grid.Column>
              <InvoiceTable
                invoicesQuery={invoicesQuery}
                activePage={state.page + 1}
                sorting={{
                  column: state.sort,
                  direction: state.direction
                }}
                onSort={setSort}
                setActivePage={setActivePage}
                onClickedInvoice={(invoice) => setSelectedInvoice(invoice)}
              />
              {(selectedInvoice &&
                selectedInvoice.paymentMethodType ===
                  sale.paymentMethodTypes.ADYEN_TRANSACTION && (
                  <ModalAdyenInvoice
                    onClose={() => {
                      setSelectedInvoice(null)
                      refetchInvoices()
                    }}
                    invoiceRef={selectedInvoice.ref}
                    operatorRealm={null}
                    updateInvoicesCallback={refetchInvoices}
                  />
                )) ||
                (selectedInvoice && (
                  <ModalInvoice
                    onClose={() => {
                      setSelectedInvoice(null)
                      refetchInvoices()
                    }}
                    invoiceRef={selectedInvoice.ref}
                    operatorRealm={null}
                    updateInvoicesCallback={refetchInvoices}
                    onOpenAdyenModal={(adyenInvoice) => {
                      setSelectedInvoice(adyenInvoice)
                    }}
                  />
                ))}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </>
  )
}
