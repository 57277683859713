import { Button, Form, Item, Message, Modal } from 'semantic-ui-react'
import { MoovyHtmlPreview, MoovyIconInfo } from '../../../components'
import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'

const ModalEditLocationDescription = ({
  onClose,
  locationDescription,
  onSubmit,
  mutation
}) => {
  const [description, setDescription] = useState(locationDescription)

  const handleSubmit = () => {
    onSubmit(description)
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        content={<FormattedMessage id="locationDescriptionEditModal.title" />}
      />
      <Modal.Content scrolling>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>
              <MoovyIconInfo
                iconLabel={
                  <FormattedMessage id="locationDescriptionEditModal.label.description" />
                }
              >
                <FormattedMessage id="locationDescriptionEditModal.label.description.info" />
              </MoovyIconInfo>
            </label>
            <Form.TextArea
              value={description}
              onChange={(e, { value }) => setDescription(value)}
              name="description"
              rows={15}
            />
          </Form.Field>
          <Form.Field>
            <label>
              <FormattedMessage id="locationDescriptionEditModal.label.descriptionPreview" />
            </label>
            <Item>
              <Item.Content>
                <MoovyHtmlPreview html={description} />
              </Item.Content>
            </Item>
          </Form.Field>
          {mutation.isError ? (
            <Message color="red">
              <FormattedMessage id="locationDescriptionEditModal.edit.error" />
            </Message>
          ) : (
            ''
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          autoFocus={true}
          type="submit"
          primary
          onClick={handleSubmit}
          disabled={mutation.isPending || locationDescription === description}
          loading={mutation.isPending}
        >
          <FormattedMessage id="common.button.save" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalEditLocationDescription
