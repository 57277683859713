import { Form, Item, TextArea } from 'semantic-ui-react'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import GeoJSONPreview from './GeoJSONPreview'

const GeoJSONEditor = ({ value, onChange, error, onError }) => {
  const intl = useIntl()
  return (
    <Form.Group widths="equal">
      <Form.Field error={error}>
        <label>
          <FormattedMessage id="GeoJSONEditor.value.label" />
        </label>
        <TextArea
          placeholder={intl.formatMessage({
            id: 'GeoJSONEditor.value.placeholder'
          })}
          name="location.locationAreas"
          value={value}
          onChange={onChange}
          rows="28"
        />
      </Form.Field>
      <Form.Field>
        <label>
          <FormattedMessage id="GeoJSONEditor.preview.label" />
        </label>
        <Item>
          <Item.Content>
            <GeoJSONPreview geoJson={value} onError={onError} />
          </Item.Content>
        </Item>
      </Form.Field>
    </Form.Group>
  )
}

export default GeoJSONEditor
