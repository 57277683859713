import React, { useState } from 'react'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import service from '../../services/potentiallyIncorrectLicensePlates'
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  List,
  Message,
  Modal,
  Radio,
  Segment
} from 'semantic-ui-react'
import { MutationErrorMessage } from '../../components'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'

const PotentiallyIncorrectSegment = ({ suspected }) => {
  const [operation, setOperation] = useState()
  const [reviewedReasonCategory, setReviewedReasonCategory] = useState()
  const { operatorRealm } = useAuthInfo()
  const queryClient = useQueryClient()
  const intl = useIntl()

  const { mutate: update, ...updateMutation } = useMutation({
    mutationFn: (status) =>
      service.update(operatorRealm, suspected.ref, {
        status: status,
        reviewedReasonCategory
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['potentiallyIncorrectLicensePlate', suspected.ref]
      })
      setReviewedReasonCategory(null)
    }
  })

  const checkPlate = useMutation({
    mutationFn: (newLicensePlate) =>
      service.checkPlate(operatorRealm, suspected.ref, {
        newLicensePlate
      })
  })

  const fixPlate = useMutation({
    mutationFn: (newLicensePlate) =>
      service.fixPlate(operatorRealm, suspected.ref, {
        newLicensePlate,
        reviewedReasonCategory
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['potentiallyIncorrectLicensePlate', suspected.ref]
      })
      setOperation(null)
    }
  })

  const reasonCategoryChangeHandler = (e, { value }) =>
    setReviewedReasonCategory(value)

  const isOpen = suspected.status === 'OPEN'
  const isParkingEnded = suspected.parking.end

  const submitDisabled = !reviewedReasonCategory || !!isParkingEnded

  return (
    <div>
      <Header attached="top">
        <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.header" />
      </Header>
      <Segment attached>
        {isOpen && isParkingEnded && (
          <Message warning>
            <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.parkingEnded" />
          </Message>
        )}
        <MutationErrorMessage
          mutation={updateMutation}
          defaultErrorTextLangId="common.mutation.error"
          messageNegative
        />
        <List>
          <List.Item>
            <List.Header>
              <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.status" />
            </List.Header>
            <FormattedMessage
              id={`potentiallyIncorrectLicensePlate.status.${suspected.status}`}
            />
          </List.Item>
          <List.Item>
            <List.Header>
              <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.suspectReason" />
            </List.Header>
            <FormattedMessage
              id={`potentiallyIncorrectLicensePlate.suspectReason.${suspected.suspectReason}`}
            />
          </List.Item>
          {!isOpen && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.chosenReasonCategory" />
              </List.Header>
              <FormattedMessage
                id={`potentiallyIncorrectLicensePlate.reviewedReasonCategory.${suspected.reviewedReasonCategory}`}
              />
            </List.Item>
          )}
        </List>

        {isOpen && (
          <>
            <Form>
              <Form.Group grouped={true}>
                <Form.Field
                  label={intl.formatMessage({
                    id: 'potentiallyIncorrectLicensePlate.editSegment.selectReviewedReasonCategory'
                  })}
                />
                <Form.Field>
                  <Radio
                    label={intl.formatMessage({
                      id: 'potentiallyIncorrectLicensePlate.reviewedReasonCategory.DIRTY'
                    })}
                    name="radioGroup"
                    value="DIRTY"
                    checked={reviewedReasonCategory === 'DIRTY'}
                    onChange={reasonCategoryChangeHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={intl.formatMessage({
                      id: 'potentiallyIncorrectLicensePlate.reviewedReasonCategory.DAMAGED'
                    })}
                    name="radioGroup"
                    value="DAMAGED"
                    checked={reviewedReasonCategory === 'DAMAGED'}
                    onChange={reasonCategoryChangeHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={intl.formatMessage({
                      id: 'potentiallyIncorrectLicensePlate.reviewedReasonCategory.OBSCURING_ACCESSORY'
                    })}
                    name="radioGroup"
                    value="OBSCURING_ACCESSORY"
                    checked={reviewedReasonCategory === 'OBSCURING_ACCESSORY'}
                    onChange={reasonCategoryChangeHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={intl.formatMessage({
                      id: 'potentiallyIncorrectLicensePlate.reviewedReasonCategory.EXTERNAL_OBSTACLE'
                    })}
                    name="radioGroup"
                    value="EXTERNAL_OBSTACLE"
                    checked={reviewedReasonCategory === 'EXTERNAL_OBSTACLE'}
                    onChange={reasonCategoryChangeHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={intl.formatMessage({
                      id: 'potentiallyIncorrectLicensePlate.reviewedReasonCategory.SIMILAR_ALPHANUMERICS'
                    })}
                    name="radioGroup"
                    value="SIMILAR_ALPHANUMERICS"
                    checked={reviewedReasonCategory === 'SIMILAR_ALPHANUMERICS'}
                    onChange={reasonCategoryChangeHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={intl.formatMessage({
                      id: 'potentiallyIncorrectLicensePlate.reviewedReasonCategory.SPECIAL'
                    })}
                    name="radioGroup"
                    value="SPECIAL"
                    checked={reviewedReasonCategory === 'SPECIAL'}
                    onChange={reasonCategoryChangeHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={intl.formatMessage({
                      id: 'potentiallyIncorrectLicensePlate.reviewedReasonCategory.OTHER'
                    })}
                    name="radioGroup"
                    value="OTHER"
                    checked={reviewedReasonCategory === 'OTHER'}
                    onChange={reasonCategoryChangeHandler}
                  />
                </Form.Field>
                <Form.Field>
                  <Radio
                    label={intl.formatMessage({
                      id: 'potentiallyIncorrectLicensePlate.reviewedReasonCategory.PLATE_OK'
                    })}
                    name="radioGroup"
                    value="PLATE_OK"
                    checked={reviewedReasonCategory === 'PLATE_OK'}
                    onChange={reasonCategoryChangeHandler}
                  />
                </Form.Field>
              </Form.Group>
            </Form>

            <Grid columns={3} stackable style={{ marginTop: '1rem' }}>
              <Grid.Column>
                <Button
                  disabled={submitDisabled}
                  onClick={() => update('CONFIRMED')}
                  loading={updateMutation.isLoading}
                  fluid
                >
                  <Icon name="checkmark" color="green" />
                  <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.confirmButton" />
                </Button>
              </Grid.Column>

              <Grid.Column>
                <Button
                  disabled={submitDisabled}
                  onClick={() => update('UNIDENTIFIED')}
                  loading={updateMutation.isLoading}
                  fluid
                >
                  <Icon name="question" color="orange" />
                  <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.unidentifiedButton" />
                </Button>
              </Grid.Column>

              <Grid.Column>
                <Button
                  disabled={submitDisabled}
                  onClick={() => setOperation('fix')}
                  loading={updateMutation.isLoading}
                  fluid
                >
                  <Icon name="pencil" color="red" />
                  <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixButton" />
                </Button>
              </Grid.Column>
            </Grid>
          </>
        )}
      </Segment>

      <Modal
        open={operation === 'fix'}
        onClose={() => setOperation(null)}
        size="tiny"
      >
        <Modal.Header>
          <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixModal" />
        </Modal.Header>
        <Modal.Content>
          <FixParkingForm
            checkPlateMutationResult={checkPlate}
            fixPlateMutationResult={fixPlate}
          />
        </Modal.Content>
      </Modal>
    </div>
  )
}

const FixParkingForm = ({
  checkPlateMutationResult,
  fixPlateMutationResult
}) => {
  const intl = useIntl()
  const [isDirty, setIsDirty] = useState(false)
  const [licensePlateMissing, setLicensePlateMissing] = useState()

  const { mutate: checkPlate, ...checkPlateMutation } = checkPlateMutationResult
  const { mutate: fixPlate, ...fixPlateMutation } = fixPlateMutationResult

  return (
    <Form
      onSubmit={(e) => {
        const submittedLicensePlate = e.target.elements['licensePlate'].value
        if (!submittedLicensePlate) {
          setLicensePlateMissing(true)
        } else {
          if (isDirty) {
            setIsDirty(false)
            checkPlate(submittedLicensePlate)
          } else {
            fixPlate(submittedLicensePlate)
          }
        }
      }}
    >
      <MutationErrorMessage
        mutation={checkPlateMutation}
        defaultErrorTextLangId="common.mutation.error"
        messageNegative
      />
      <MutationErrorMessage
        mutation={fixPlateMutation}
        defaultErrorTextLangId="common.mutation.error"
        messageNegative
      />

      {licensePlateMissing && (
        <Message negative>
          <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixModal.licensePlateMissing" />
        </Message>
      )}
      <Form.Input
        name="licensePlate"
        label={intl.formatMessage({
          id: 'potentiallyIncorrectLicensePlate.editSegment.fixModal.licensePlate'
        })}
        onChange={() => {
          setIsDirty(true)
          setLicensePlateMissing(false)
          checkPlateMutation.reset()
          fixPlateMutation.reset()
        }}
        autoFocus
      />

      {checkPlateMutation.isSuccess && checkPlateMutation.data.length > 0 && (
        <div>
          <Message info icon style={{ marginBottom: '1.5 rem' }}>
            <Icon name="info circle" color="orange" />
            <Message.Content>
              <Message.Header>
                <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixModal.usersFound" />
              </Message.Header>
              <List>
                {checkPlateMutation.data.map((row) => (
                  <List.Item key={row.ref}>
                    <RelativeLinkForCurrentUser to={'customers/' + row.ref}>
                      {row.name}
                    </RelativeLinkForCurrentUser>
                  </List.Item>
                ))}
              </List>
              <Header size="tiny">
                <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixModal.usersFoundInfo" />
              </Header>
            </Message.Content>
          </Message>
        </div>
      )}
      {checkPlateMutation.isSuccess && checkPlateMutation.data.length === 0 && (
        <div>
          <Message info icon style={{ marginBottom: '1.5 rem' }}>
            <Icon name="info circle" color="orange" />
            <Message.Content>
              <Message.Header>
                <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixModal.usersNotFound" />
              </Message.Header>
              <Header size="tiny">
                <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixModal.usersNotFoundInfo" />
              </Header>
            </Message.Content>
          </Message>
        </div>
      )}

      <div style={{ marginTop: '2rem' }}>
        <Form.Button
          primary
          loading={checkPlateMutation.isPending || fixPlateMutation.isPending}
          type="submit"
          floated="right"
          style={{ marginBottom: '1rem' }}
        >
          {checkPlateMutation.isSuccess && !isDirty ? (
            <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixModal.fixButton" />
          ) : (
            <FormattedMessage id="potentiallyIncorrectLicensePlate.editSegment.fixModal.checkButton" />
          )}
        </Form.Button>
      </div>
    </Form>
  )
}

export default PotentiallyIncorrectSegment
