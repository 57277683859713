import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import React from 'react'
const MoovyHtmlPreview = ({ html }) => {
  return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }} />
}

MoovyHtmlPreview.propTypes = {
  html: PropTypes.string.isRequired
}

export default MoovyHtmlPreview
