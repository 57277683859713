import React from 'react'
import { Table } from 'semantic-ui-react'
import TablePagination from '../../../components/TablePagination'
import { tablePaginationDirections } from '../../../services/utils/tablePagination'

const OperatorTable = ({
  operators,
  onSelectedOperator,
  onHandleSort,
  onPageChange,
  pagination
}) => (
  <Table selectable sortable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={
            pagination.sortColumn === 'sortByName'
              ? tablePaginationDirections.stringValues[pagination.direction]
              : null
          }
          onClick={onHandleSort('sortByName')}
        >
          Operaattori
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {operators &&
        operators.map((operatorItem) => (
          <Table.Row
            key={operatorItem.ref}
            onClick={() => {
              onSelectedOperator(operatorItem.ref)
            }}
          >
            <Table.Cell>{operatorItem.name}</Table.Cell>
          </Table.Row>
        ))}
    </Table.Body>
    <TablePagination
      colSpan={1}
      activePage={pagination.activePage}
      totalPages={pagination.totalPages}
      onPageChange={onPageChange}
    />
  </Table>
)

export default OperatorTable
