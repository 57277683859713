import { Checkbox, Form, Select } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyTableFilter, MoovyTextInput, Toolbar } from '../../components'
import {
  getStringValue,
  invoicingCardPaymentStatus,
  invoicingStatus,
  invoicingVismaStatus,
  paymentMethod as paymentMethodEnum,
  sale
} from '../../services/utils/DTOEnums'

import { LocalizedInvoicingStatus } from '../../components/MoovyLocalizedEnum'
import React from 'react'
import moovyDateTime from '../../services/utils/moovyDateTime'

const InvoicesToolbar = ({ state, onChangeFilter }) => {
  const intl = useIntl()

  const {
    invoiceNumberOrPspRef,
    paymentMethod,
    invoiceStatus,
    hideZeroPrice,
    tableFilterRange,
    tableCustomStartTime,
    tableCustomEndTime
  } = state

  const getOptions = (statuses, paymentMethodType) => {
    const { localizationKeys, ...keys } = statuses

    return Object.keys(keys)
      .sort((a, b) =>
        intl
          .formatMessage({
            id: getStringValue(invoicingStatus.localizationKeys, a)
          })
          .localeCompare(
            intl.formatMessage({
              id: getStringValue(invoicingStatus.localizationKeys, b)
            })
          )
      )
      .map((item) => {
        return {
          key: item,
          value: item,
          text: (
            <LocalizedInvoicingStatus
              value={item}
              paymentMethodType={paymentMethodType}
            />
          )
        }
      })
  }

  const vismaStatusOptions = getOptions(
    invoicingVismaStatus,
    sale.paymentMethodTypes.VISMA
  )
  const cardPaymentStatusOptions = getOptions(
    invoicingCardPaymentStatus,
    sale.paymentMethodTypes.ADYEN_TRANSACTION
  )
  const allStatusOptions = getOptions(invoicingStatus)

  const paymentMethods = [
    paymentMethodEnum.ADYEN,
    paymentMethodEnum.PAYMENT_HIGHWAY,
    paymentMethodEnum.VISMA,
    paymentMethodEnum.WEB_PAYMENT
  ]

  const paymentOptions = paymentMethods.map((item) => {
    return {
      key: item,
      value: item,
      text: (
        <FormattedMessage
          id={getStringValue(paymentMethodEnum.localizationKeys, item)}
          defaultMessage={item}
        />
      )
    }
  })

  const getStatusOptions = (paymentMethod) => {
    if (!paymentMethod) return []

    switch (paymentMethod) {
      case paymentMethodEnum.VISMA:
        return vismaStatusOptions
      case paymentMethodEnum.ADYEN:
      case paymentMethodEnum.PAYMENT_HIGHWAY:
        return cardPaymentStatusOptions
      default:
        return allStatusOptions
    }
  }

  return (
    <Toolbar
      title={intl.formatMessage({ id: 'invoices.toolbar.title' })}
      content={
        <>
          <div>
            <MoovyTableFilter
              tableFilterRange={tableFilterRange}
              initialDateRange={{
                startTime: moovyDateTime.isoToJSDate(tableCustomStartTime),
                endTime: moovyDateTime.isoToJSDate(tableCustomEndTime)
              }}
              onSelectedDateTimeRange={(range) => {
                const createdFromTimeAsCalenderFormat =
                  moovyDateTime.dateToCalendarInput(range.startTime)
                const createdUntilTimeAsCalenderFormat =
                  moovyDateTime.dateToCalendarInput(range.endTime)

                onChangeFilter('dateRange', {
                  createdFromTime: createdFromTimeAsCalenderFormat,
                  createdUntilTime: createdUntilTimeAsCalenderFormat,
                  tableFilterRange: range.tableFilterRange,
                  tableCustomStartTime: range?.startTime?.toJSDate(),
                  tableCustomEndTime: range?.endTime?.toJSDate()
                })
              }}
            >
              <Form.Group>
                <Form.Field>
                  <MoovyTextInput
                    value={invoiceNumberOrPspRef}
                    name="invoiceNumberOrPspRef"
                    onChange={(e) =>
                      onChangeFilter('invoiceNumberOrPspRef', e.target.value)
                    }
                    onClear={() => onChangeFilter('invoiceNumberOrPspRef', '')}
                    placeholder={intl.formatMessage({
                      id: 'invoices.toolbar.invoiceNumberOrPspRef.placeholder'
                    })}
                  />
                </Form.Field>
                <Form.Field error={!!!paymentMethod}>
                  <Select
                    value={paymentMethod}
                    clearable
                    options={paymentOptions}
                    onChange={(e, { value }) => {
                      onChangeFilter('paymentMethod', value)
                    }}
                    placeholder={intl.formatMessage({
                      id: 'invoices.toolbar.paymentMethod.placeholder'
                    })}
                    selectOnBlur={false}
                  />
                </Form.Field>
                <Form.Field>
                  <Select
                    value={invoiceStatus}
                    clearable
                    options={getStatusOptions(paymentMethod)}
                    onChange={(e, { value }) =>
                      onChangeFilter('invoiceStatus', value)
                    }
                    placeholder={intl.formatMessage({
                      id: 'invoices.toolbar.status.placeholder'
                    })}
                    selectOnBlur={false}
                  />
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    className="checkbox-margin"
                    label={intl.formatMessage({
                      id: 'invoices.toolbar.hideZeroPrice'
                    })}
                    checked={hideZeroPrice}
                    onChange={(e, { checked }) =>
                      onChangeFilter('hideZeroPrice', checked)
                    }
                  />
                </Form.Field>
              </Form.Group>
            </MoovyTableFilter>
          </div>
        </>
      }
    />
  )
}

export default InvoicesToolbar
