import { SemanticToastContainer, toast } from 'react-semantic-toasts'

import React from 'react'
import { localizedErrorString } from '../../services/utils'

export const showMoovyToast = (
  title,
  description,
  type = 'success',
  time = 5000
) => {
  toast({
    title,
    description,
    type,
    icon: null,
    time: time
  })
}

export const showLocalizedMoovyToast = (
  intl,
  options = { type: 'success', time: 5000 }
) => {
  const { title, description, values, type, time } = options

  if (options.error) {
    // NOTE: HARD CODED TO FINNISH BECAUSE LANGUAGE SWITCH IS NOT SUPPORTED YET.
    const defaultLang = 'fi'

    const localizedErrorMessage = localizedErrorString(
      options.error.body,
      defaultLang
    )

    showMoovyToast(
      intl.formatMessage({ id: title }, values),
      localizedErrorMessage || intl.formatMessage({ id: description }, values),
      type,
      time
    )
  } else {
    showMoovyToast(
      intl.formatMessage({ id: title }, values),
      intl.formatMessage({ id: description }, values),
      type,
      time
    )
  }
}

export default function MoovyToast() {
  return <SemanticToastContainer position="top-right" />
}
