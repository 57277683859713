import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { Button, Container, Form, Message, TextArea } from 'semantic-ui-react'

export default ({ onSubmit, mutation, ended }) => {
  const [comment, setComment] = useState('')

  return (
    <Form error={mutation.isError}>
      <Container className="padding">
        <Form.Field>
          <label>Kommentti</label>
          <TextArea
            placeholder="Voit lisätä muutokseen liittyvän kommentin"
            value={comment}
            onChange={(ev) => setComment(ev.target.value)}
          />
        </Form.Field>

        <Message error>Pysäköinnin tallentamisessa tapahtui virhe</Message>
        <Button
          primary
          fluid
          onClick={() =>
            onSubmit({ comment, endTime: DateTime.local().toISO() })
          }
          loading={mutation.isPending}
          disabled={ended}
        >
          Tallenna ja lopeta pysäköinti
        </Button>
      </Container>
    </Form>
  )
}
