import * as yup from 'yup'

import { Form, Select } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import { useFormik } from 'formik'
import { useQuery } from '@tanstack/react-query'
import {
  dateTimeFromFormat,
  dateTimeInputStringToISO,
  dateTimeNowToCalendarInput
} from '../../services/utils'

import useAuthInfo from '../../hooks/useAuthInfo'
import { useOperatorRealmLocations } from '../../hooks/useLocations'
import userService from '../../services/User/UserServices'

const FormMoovyParking = ({
  customerRef,
  formReference,
  onAllowedPlateRecognitionVehicle,
  services,
  loading,
  onChangeLocation,
  onSubmit
}) => {
  const { operatorRealm } = useAuthInfo()
  const locations = useOperatorRealmLocations(operatorRealm)
  const intl = useIntl()
  const [currentTime] = useState(dateTimeNowToCalendarInput())

  const formik = useFormik({
    initialValues: {
      startTime: currentTime,
      locationRef: '',
      licensePlateNumber: ''
    },
    validationSchema: yup.object().shape({
      startTime: yup
        .string()
        .test('startTime', 'Invalid start time', function (startTime) {
          return startTime && dateTimeFromFormat(startTime).isValid
        }),
      locationRef: yup.string().required('Cannot be empty'),
      licensePlateNumber: yup
        .string()
        .test(
          'licensePlateNumber',
          'Invalid license plate',
          function (licensePlateNumber) {
            return licensePlateNumber && licensePlateNumber.trim()
          }
        )
    }),
    onSubmit: (values) => {
      // The parking will be started at the same time when the modal is closed in case of user does not change the time
      // TODO Antti: This is not a perfect solution because seconds are missing in the value. This can be fixed when
      // a new datetime component will be taken into use.
      onSubmit({
        startTime:
          values.startTime === currentTime
            ? dateTimeInputStringToISO(dateTimeNowToCalendarInput())
            : dateTimeInputStringToISO(values.startTime),
        licensePlateNumber: values.licensePlateNumber
          .trim()
          .toLocaleUpperCase(),
        location: values.locationRef,
        serviceRef: values.serviceRef
      })
    }
  })

  useEffect(() => {
    if (!formik.values.serviceRef) {
      formik.setFieldValue(
        'serviceRef',
        services?.length > 1 ? services[0].ref : null,
        false
      )
    }
  }, [services])

  const { handleSubmit, values, errors, setFieldValue } = formik

  const serviceOptions =
    services?.map((service) => ({
      key: service.ref,
      text: service.name,
      value: service.ref
    })) ?? []

  const vehicles = useQuery({
    queryKey: ['vehicles', customerRef],
    queryFn: () => userService.fetchVehicles(customerRef, operatorRealm),
    enabled: !!customerRef
  })

  const getLocationOptionText = ({ name, city = '', internalName = '' }) => {
    return `${name}, ${city.trim()} ${
      internalName ? `(${internalName.trim()})` : ''
    }`
  }

  const locationOptions =
    locations.data && locations.data.content
      ? locations.data.content
          .filter((loc) => loc.licensePlateRecognitionSupported)
          .map((loc) => ({
            key: loc.ref,
            text: getLocationOptionText(loc),
            value: loc.ref
          }))
      : []

  const vehicleOptions = vehicles.data
    ? vehicles.data.map((vehicle) => ({
        key: vehicle.ref,
        text: vehicle.licensePlateNumber,
        value: vehicle.licensePlateNumber
      }))
    : []

  const licensePlateWithoutPlateRecognition = vehicles.data
    ? vehicles.data
        .filter(
          (item) =>
            !item?.licensePlateRecognitionPermissions
              ?.allowLicensePlateBasedParkingFacilityAccess
        )
        .map((vehicle) => vehicle.licensePlateNumber)
    : []

  const licensePlateSelected = (licensePlate) => {
    onAllowedPlateRecognitionVehicle(
      !licensePlateWithoutPlateRecognition.includes(licensePlate)
    )
  }

  return (
    <Form onSubmit={handleSubmit} id={formReference} loading={loading}>
      <Form.Field
        control={Select}
        label={intl.formatMessage({ id: 'common.location' })}
        name="locationRef"
        value={values.locationRef}
        search
        onChange={(ev, { value }) => {
          setFieldValue('locationRef', value)
          setFieldValue('serviceRef', null)
          onChangeLocation(value)
        }}
        options={locationOptions}
        error={!!errors.locationRef}
        selectOnBlur={false}
      />
      <Form.Field error={!!errors.licensePlateNumber}>
        <label>
          <FormattedMessage id="common.vehicles.licensePlateNumber" />
        </label>
        <Form.Select
          name="licensePlateNumber"
          fluid
          options={vehicleOptions}
          value={values.licensePlateNumber}
          search
          onChange={(e, { value }) => {
            setFieldValue('licensePlateNumber', value)
            licensePlateSelected(value)
          }}
          selectOnBlur={false}
        />
      </Form.Field>
      <Form.Field error={!!errors.startTime}>
        <label>
          <FormattedMessage id="modalStartParking.form.label.startTime" />
        </label>
        <DateTimeInput
          name="startTime"
          value={values.startTime}
          onChange={(ev, { value }) => {
            setFieldValue('startTime', value)
          }}
          dateFormat="DD.MM.YYYY"
          startMode="month"
          iconPosition="left"
          animation="none"
          closable
        />
      </Form.Field>
      {services?.length > 1 && (
        <Form.Field
          control={Select}
          label={intl.formatMessage({
            id: 'modalStartParking.form.label.service'
          })}
          name="serviceRef"
          value={values.serviceRef}
          onChange={(ev, { value }) => {
            setFieldValue('serviceRef', value)
          }}
          options={serviceOptions}
        />
      )}
    </Form>
  )
}

export default FormMoovyParking
