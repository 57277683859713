import { useQuery } from '@tanstack/react-query'

import operatorService from '../services/Operators'
import useAuthInfo from './useAuthInfo'

export default function useOperators() {
  const { isSuperOperator } = useAuthInfo()
  return isSuperOperator
    ? useQuery({
        queryKey: ['operators'],
        queryFn: operatorService.getAllOperators,
        staleTime: 1000 * 60 * 60 // Operators shouldn't change that often, cache for one hour
      })
    : {}
}
