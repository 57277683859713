import { Button, Form, Icon } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import { DateTime } from 'luxon'
import { MoovyDateRangePicker } from '../../../components'
import moovyDateTime from '../../../services/utils/moovyDateTime'

const AccessLogFilters = ({ loading, onSearch, values, setValues }) => {
  const intl = useIntl()
  const [validDate, setValidDate] = useState(true)

  const maxSearchRangeThreeDays = 3

  const setFilterValue = (filterType, filterValue) => {
    let updateValues = { ...values }
    updateValues[filterType] = filterValue
    updateValues.activePage = 1
    setValues({ ...updateValues })
  }

  const setDateFilterValue = (filterValue) => {
    let updateValues = { ...values }

    updateValues['startTime'] = moovyDateTime.jsDateToISOString(
      filterValue.startTime
    )
    updateValues['endTime'] = moovyDateTime.jsDateToISOString(
      filterValue.endTime
    )
    updateValues.activePage = 1
    setValues({ ...updateValues })
  }

  const isValidLicensePlateNumber = () => {
    return values?.partialLicensePlateNumber?.length > 1
  }

  const onSubmit = () => {
    isValidLicensePlateNumber() && validDate && onSearch()
  }

  return (
    <Form autoComplete="off" onSubmit={onSubmit}>
      <Form.Group>
        <Form.Input
          label={intl.formatMessage({
            id: 'accessLogFilters.form.label.licensePlate'
          })}
          name="partialLicensePlateNumber"
          value={values.partialLicensePlateNumber}
          onChange={(e, { name, value }) => setFilterValue(name, value?.trim())}
          error={!isValidLicensePlateNumber()}
          icon={
            <Icon
              name={values.partialLicensePlateNumber ? 'delete' : ''}
              link={!!values.partialLicensePlateNumber}
              onClick={() =>
                setValues({ ...values, partialLicensePlateNumber: '' })
              }
            />
          }
        />
        <Form.Field>
          <label>
            <FormattedMessage
              id={'accessLogFilters.form.label.dataRange'}
              values={{ days: maxSearchRangeThreeDays }}
            />
          </label>
          <MoovyDateRangePicker
            name="accessLogDateRange"
            placeholderText={intl.formatMessage({
              id: 'moovyTableFilter.filter.placeholder.chooseTimeRange'
            })}
            maxRangeAsDays={maxSearchRangeThreeDays}
            startDate={moovyDateTime.isoToJSDate(values.startTime)}
            endDate={moovyDateTime.isoToJSDate(values.endTime)}
            onChange={(dates, event) => {
              const [start, end, valid] = dates
              setValidDate(valid)
              setDateFilterValue({
                startTime: start,
                endTime: end
              })
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>
            <FormattedMessage id={'common.button.search'} />
          </label>
          <Button type="submit" primary loading={loading}>
            <FormattedMessage id={'common.button.search'} />
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  )
}

export default AccessLogFilters
