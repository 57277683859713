import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { Table } from 'semantic-ui-react'

import {
  convertMinutes,
  formatDateAndTime,
  formatPrice
} from '../../services/utils'
import { voucher as voucherEnums } from '../../services/utils/DTOEnums'
import { voucherShape } from '../../services/utils/shapes'
import TablePagination from '../TablePagination'
import { MoovyTable } from '../index'
import { FormattedMessage } from 'react-intl'

const getBenefit = (voucher) => {
  const { benefitTypes } = voucherEnums

  if (voucher.benefitType === benefitTypes.MONEY) {
    return `Raha (${formatPrice(voucher.amount)})`
  }
  if (voucher.benefitType === benefitTypes.TIME) {
    return `Aika (${convertMinutes(voucher.grantedDurationInMinutes)})`
  }
  if (voucher.benefitType === benefitTypes.PERIOD) {
    return 'Kesto'
  }
  if (voucher.benefitType === benefitTypes.PERCENT) {
    return `Prosentti (${voucher.grantedDiscountPercent} %)`
  }
  return ''
}

const getStatus = (voucher) => {
  if (moment().isAfter(voucher.validUntil)) {
    return 'Päättynyt'
  }
  if (moment().isBefore(voucher.validFrom)) {
    return 'Ei vielä voimassa'
  }
  return 'Voimassa'
}

const VouchersTable = ({
  vouchers,
  onClickVoucher,
  activePage,
  pageSize = 20,
  totalResults,
  changePage,
  compact = false,
  query,
  fetchPending = false
}) => {
  const totalPages = Math.ceil(totalResults / pageSize)
  return (
    <MoovyTable
      items={vouchers}
      query={query}
      selectable={!fetchPending && !!onClickVoucher}
      emptyTextId={
        fetchPending ? (
          <FormattedMessage id="moovyTable.action.fetchPending" />
        ) : null
      }
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Myönnetty</Table.HeaderCell>
          <Table.HeaderCell>Voimassa alkaen</Table.HeaderCell>
          <Table.HeaderCell>Voimassa asti</Table.HeaderCell>
          <Table.HeaderCell>Tila</Table.HeaderCell>
          <Table.HeaderCell>Kategoria</Table.HeaderCell>
          {!compact && (
            <>
              <Table.HeaderCell>Etu</Table.HeaderCell>
              <Table.HeaderCell>Myönnetty</Table.HeaderCell>
              <Table.HeaderCell>Ajoneuvo</Table.HeaderCell>
              <Table.HeaderCell>Edun tyyppi</Table.HeaderCell>
            </>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {vouchers.map((v) => (
          <Table.Row
            key={v.ref}
            onClick={onClickVoucher ? () => onClickVoucher(v) : undefined}
          >
            <Table.Cell>{formatDateAndTime(v.grantedDate)}</Table.Cell>
            <Table.Cell>{formatDateAndTime(v.validFrom)}</Table.Cell>
            <Table.Cell>{formatDateAndTime(v.validUntil)}</Table.Cell>
            <Table.Cell>{getStatus(v)}</Table.Cell>
            <Table.Cell>
              {voucherEnums.categoryTypes.stringValues[v.categoryType]}
            </Table.Cell>
            {!compact && (
              <>
                <Table.Cell>{getBenefit(v)}</Table.Cell>
                <Table.Cell>{v.grantedTo}</Table.Cell>
                <Table.Cell>{v.licensePlateNumber}</Table.Cell>
                <Table.Cell>
                  {voucherEnums.voucherTypes.stringValues[v.voucherType]}
                </Table.Cell>
              </>
            )}
          </Table.Row>
        ))}
      </Table.Body>
      <TablePagination
        colSpan={compact ? 5 : 9}
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={changePage}
      />
    </MoovyTable>
  )
}
VouchersTable.propTypes = {
  vouchers: PropTypes.arrayOf(PropTypes.shape(voucherShape)).isRequired,
  onClickVoucher: PropTypes.func,
  activePage: PropTypes.number,
  pageSize: PropTypes.number,
  totalResults: PropTypes.number,
  changePage: PropTypes.func,
  compact: PropTypes.bool,
  query: PropTypes.shape({}),
  fetchPending: PropTypes.bool
}

export default VouchersTable
