import React, { useState } from 'react'
import { Button, Container, Form, Message, TextArea } from 'semantic-ui-react'

export default ({ onSubmit, mutation, ended }) => {
  const [comment, setComment] = useState('')
  return (
    <Container className="padding">
      <Form error={mutation.isError} success={mutation.isSuccess}>
        <Form.Field>
          <label>Kommentti</label>
          <TextArea
            placeholder="Voit lisätä muutokseen liittyvän kommentin"
            value={comment}
            onChange={(ev) => setComment(ev.target.value)}
          />
        </Form.Field>
        <Message error>Pysäköinnin tallentamisessa tapahtui virhe</Message>
        <Message success>Poistumisaika asetettu</Message>
        <Button
          primary
          fluid
          onClick={() => onSubmit({ comment })}
          loading={mutation.isPending}
          disabled={ended}
        >
          Salli poistuminen
        </Button>
      </Form>
    </Container>
  )
}
