import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button, Confirm, Icon } from 'semantic-ui-react'

export default function MoovyDeleteButton({
  onConfirm,
  confirmText = 'Poistetaanko?',
  loading = false
}) {
  const [open, setOpen] = useState(false)
  const intl = useIntl()

  return (
    <>
      <Button
        basic
        icon
        onClick={() => setOpen(true)}
        disabled={loading}
        loading={loading}
      >
        <Icon name="trash alternate" color="red" />
      </Button>
      <Confirm
        open={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => {
          setOpen(false)
          onConfirm()
        }}
        content={confirmText}
        cancelButton={intl.formatMessage({ id: 'common.button.cancel' })}
        confirmButton={intl.formatMessage({ id: 'common.button.accept' })}
      />
    </>
  )
}
