import React from 'react'
import { Navigate } from 'react-router-dom'

import useAuthInfo from '../hooks/useAuthInfo'

/**
 * Redirect logged in user to admin or operator section
 */
const RedirectUser = () => {
  const { isSuperOperator } = useAuthInfo()
  if (isSuperOperator) {
    return <Navigate to="/admin" replace />
  }
  return <Navigate to="/operator" replace />
}

export default RedirectUser
