import { Button, Container, Form, Modal, TextArea } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import { DateTime } from 'luxon'
import { MutationErrorMessage } from '../../components'

const EndParkingModal = ({
  open,
  endParkingItems,
  onSubmit,
  onClose,
  mutation
}) => {
  const intl = useIntl()
  const [comment, setComment] = useState('')

  return (
    <Modal onClose={onClose} open={open} size="tiny">
      <Modal.Header>
        {intl.formatMessage(
          { id: 'endParkingModal.header' },
          { total: endParkingItems.length }
        )}
      </Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId={'endParkingModal.action.error.ending'}
          messageNegative
        />
        <Modal.Description>
          <Form>
            <Container className="padding">
              <Form.Field>
                <label>
                  <FormattedMessage id="endParkingModal.form.comment" />
                </label>
                <TextArea
                  placeholder={intl.formatMessage({
                    id: 'endParkingModal.form.comment.placeholder'
                  })}
                  value={comment}
                  onChange={(ev) => setComment(ev.target.value)}
                />
              </Form.Field>
            </Container>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          primary
          onClick={() =>
            onSubmit({
              comment,
              endTime: DateTime.local().toISO(),
              parkingRefs: endParkingItems.map((item) => item.ref)
            })
          }
          loading={mutation.isPending}
          disabled={mutation.isPending}
        >
          <FormattedMessage id="parkingTable.endParking.modal.button.endParking" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default EndParkingModal
