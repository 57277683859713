import { get, post } from '../../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/adyen/settlementRecipients/creditLosses${relativePath}`
    : `/administration/adyen/settlementRecipients/creditLosses${relativePath}`

const createCoverLossFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/adyen/settlementRecipients${relativePath}`
    : `/administration/adyen/settlementRecipients${relativePath}`

const getSettlementRecipientCreditLosses = async (queryParams) => {
  const result = await get(createFullPath(``, null), {
    ...queryParams
  })
  return result
}

const getSettlementRecipientCreditLossValidationData = async (
  settlementRecipientRef
) => {
  const result = await get(
    `/administration/adyen/settlementRecipients/${settlementRecipientRef}/creditLosses/validationData`,
    { lastMonth: true }
  )
  return result
}

const coverSettlementRecipientCreditLoss = async (
  settlementRecipientRef,
  data
) => {
  const result = await post(
    createCoverLossFullPath(`/${settlementRecipientRef}/coverCreditLoss`, null),
    data
  )
  return result
}

export default {
  getSettlementRecipientCreditLosses,
  getSettlementRecipientCreditLossValidationData,
  coverSettlementRecipientCreditLoss
}
