import React from 'react'
import { Segment } from 'semantic-ui-react'

import Row from './Row'

export const Tree = ({
  treeData,
  parentId,
  level,
  rowButtonClick,
  rowButtonDeleteClick,
  onChangeValue,
  onOperatorSelected,
  onMenuItemClick
}) => {
  const items = treeData.filter((item) => item.parentId === parentId)
  if (!items.length) return null
  return (
    <>
      {items.map((item) => {
        if (level === 0) {
          if (item.operator) {
            return (
              <Segment key={'segment' + item.id}>
                <Tree
                  key={'tree' + item.id}
                  treeData={treeData}
                  parentId={item.id}
                  level={level + 1}
                  rowButtonClick={rowButtonClick}
                  rowButtonDeleteClick={rowButtonDeleteClick}
                  onChangeValue={onChangeValue}
                  onOperatorSelected={onOperatorSelected}
                  onMenuItemClick={onMenuItemClick}
                />
              </Segment>
            )
          } else {
            return (
              <Segment key={'segment' + item.id}>
                <Row
                  key={'row' + item.id}
                  item={item}
                  parentItem={treeData.find(
                    (rootItem) => rootItem.id === item.parentId
                  )}
                  rowButtonClick={rowButtonClick}
                  rowButtonDeleteClick={rowButtonDeleteClick}
                  onChangeValue={onChangeValue}
                  onOperatorSelected={onOperatorSelected}
                  onMenuItemClick={onMenuItemClick}
                >
                  <Tree
                    key={'tree' + item.id}
                    treeData={treeData}
                    parentId={item.id}
                    level={level + 1}
                    rowButtonClick={rowButtonClick}
                    rowButtonDeleteClick={rowButtonDeleteClick}
                    onChangeValue={onChangeValue}
                    onOperatorSelected={onOperatorSelected}
                    onMenuItemClick={onMenuItemClick}
                  />
                </Row>
              </Segment>
            )
          }
        } else {
          return (
            <Row
              key={'row' + item.id}
              item={item}
              parentItem={treeData.find(
                (rootItem) => rootItem.id === item.parentId
              )}
              rowButtonClick={rowButtonClick}
              rowButtonDeleteClick={rowButtonDeleteClick}
              onChangeValue={onChangeValue}
              onOperatorSelected={onOperatorSelected}
              onMenuItemClick={onMenuItemClick}
            >
              <Tree
                key={'tree' + item.id}
                treeData={treeData}
                parentId={item.id}
                level={level + 1}
                rowButtonClick={rowButtonClick}
                rowButtonDeleteClick={rowButtonDeleteClick}
                onChangeValue={onChangeValue}
                onOperatorSelected={onOperatorSelected}
                onMenuItemClick={onMenuItemClick}
              />
            </Row>
          )
        }
      })}
    </>
  )
}

export default Tree
