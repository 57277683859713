import { Button, Form, Grid, Modal, Popup } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ModalRemoveUser, MutationErrorMessage } from '../../../components'
import {
  ROLE_USER_ADMINISTRATION,
  ROLE_USER_MANAGEMENT
} from '../../../services/utils'
import React, { useState } from 'react'

import { Formik } from 'formik'
import ModalRemovingRequest from './ModalRemovingRequest'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'
import userService from '../../../services/User/UserServices'
import { validationSchema } from './formValidationSchema'

const UserEditModal = ({
  onClose,
  user,
  onSubmit,
  onRefreshUser,
  mutation
}) => {
  const intl = useIntl()

  const initialFormData = (user) => {
    const { firstName, lastName, email, phone } = user && user.userDetails
    return { firstName, lastName, email, phone }
  }

  const [formData] = useState(initialFormData(user))
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false)
  const [removingRequestModalOpen, setRemovingRequestModalOpen] =
    useState(false)

  const initialFormikData = () => {
    const { firstName, lastName, email, phone } = formData
    return { firstName, lastName, email, phone }
  }

  const handleFormitSubmit = (values) => {
    onSubmit({ ...values, userRef: user.ref })
  }

  const { roles } = useAuthInfo()

  const { mutate: removeUser, ...removeUserMutation } = useMutation({
    mutationFn: () => {
      return userService.removeUser(user?.ref)
    },
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'modalRemoveUser.toast.title.userRemoved',
        description: 'modalRemoveUser.toast.description.userRemove'
      })
      setRemoveUserModalOpen(false)
      onRefreshUser()
    }
  })

  return (
    <>
      <Formik
        initialValues={initialFormikData()}
        validationSchema={validationSchema()}
        onSubmit={handleFormitSubmit}
        render={({
          values,
          errors,
          handleChange,
          handleSubmit,
          handleBlur
        }) => (
          <Modal open onClose={onClose}>
            <Modal.Header
              content={<FormattedMessage id="modalEditUser.title.editUser" />}
            />
            <Modal.Content>
              <MutationErrorMessage
                mutation={mutation}
                defaultErrorTextLangId="modalEditUser.action.edit.default.error"
                messageNegative
              />
              <Form onSubmit={handleSubmit}>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        fluid
                        label={
                          <FormattedMessage id="modalEditUser.form.firstName" />
                        }
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="firstName"
                        error={errors.firstName !== undefined}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        fluid
                        label={
                          <FormattedMessage id="modalEditUser.form.lastName" />
                        }
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="lastName"
                        error={errors.lastName !== undefined}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form.Input
                        fluid
                        label={
                          <FormattedMessage id="modalEditUser.form.email" />
                        }
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="email"
                        error={errors.email !== undefined}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Form.Input
                        fluid
                        label={
                          <FormattedMessage id="modalEditUser.form.phoneNumber" />
                        }
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="phone"
                        error={errors.phone !== undefined}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              {roles.includes(ROLE_USER_ADMINISTRATION) && (
                <Popup
                  content={
                    <FormattedMessage id="modalEditUser.button.tooltip.deleteUser" />
                  }
                  trigger={
                    <Button
                      color="red"
                      floated="left"
                      onClick={() => setRemoveUserModalOpen(true)}
                    >
                      <FormattedMessage id="common.button.delete" />
                    </Button>
                  }
                />
              )}
              {!user.userDeletionRequested &&
                (roles.includes(ROLE_USER_ADMINISTRATION) ||
                  roles.includes(ROLE_USER_MANAGEMENT)) && (
                  <Popup
                    content={
                      <FormattedMessage id="modalEditUser.button.tooltip.createDeletionRequest" />
                    }
                    trigger={
                      <Button
                        basic
                        color="red"
                        floated="left"
                        onClick={() => setRemovingRequestModalOpen(true)}
                      >
                        <FormattedMessage id="modalEditUser.button.createDeletionRequest" />
                      </Button>
                    }
                  />
                )}
              <Button onClick={onClose}>
                <FormattedMessage id="common.button.cancel" />
              </Button>
              <Button
                type="submit"
                primary
                onClick={handleSubmit}
                disabled={mutation.isPending}
                loading={mutation.isPending}
              >
                <FormattedMessage id="common.button.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      />
      <ModalRemoveUser
        user={user}
        open={removeUserModalOpen}
        onClose={() => setRemoveUserModalOpen(false)}
        removeUserMutation={removeUserMutation}
        onRemove={removeUser}
      />
      <ModalRemovingRequest
        user={user}
        open={removingRequestModalOpen}
        onClose={() => setRemovingRequestModalOpen(false)}
        onRemovingRequest={() => {
          setRemovingRequestModalOpen(false)
          onRefreshUser()
        }}
      />
    </>
  )
}

export default UserEditModal
