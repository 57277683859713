import { get } from '../utils/api-renewed'

const getOperatorByRef = async (operatorRef) => {
  try {
    const result = await get(`/administration/operator/${operatorRef}`)
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const getAllOperators = async () => {
  // todo: Pagination object is static but should be an argument
  const result = await get('/administration/operator', {
    page: 0,
    size: 500,
    sort: 'name'
  })
  return result
}

export default {
  getAllOperators,
  getOperatorByRef
}
