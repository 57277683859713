import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQueryClient } from '@tanstack/react-query'
import { Button, Grid, Icon } from 'semantic-ui-react'

import { MoovyButton } from '../../../../components'
import ModalAddUltimateBeneficialOwner from './modalAddUltimateBeneficialOwner'
import { accountHolderUIStatusEnum } from './tabAdyen'
import TableUltimateBeneficialOwner from './tableUltimateBeneficialOwner'

const StepUltimateBeneficialOwner = ({
  settlementRecipient,
  ownersQuery,
  onNextClick,
  accountHolderUIStatus
}) => {
  const queryClient = useQueryClient()
  const [addBeneficialOwner, setBeneficialOwner] = useState(false)

  const ultimateBeneficialOwners = (ownersQuery.data && ownersQuery.data) || []

  return (
    <>
      <Grid>
        <Grid.Column floated="left" width={8}></Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          {accountHolderUIStatus === accountHolderUIStatusEnum.NONE && (
            <MoovyButton onClick={() => setBeneficialOwner(true)}>
              <Icon name="user plus" />
              <FormattedMessage id="ultimateBeneficialOwnerTab.button.addUltimateBeneficialOwner" />
            </MoovyButton>
          )}
        </Grid.Column>
      </Grid>
      <TableUltimateBeneficialOwner
        ultimateBeneficialOwners={ultimateBeneficialOwners}
        query={ownersQuery}
      />
      <Button
        primary
        loading={ownersQuery.isLoading}
        onClick={onNextClick}
        disabled={
          ultimateBeneficialOwners.length <= 0 ||
          accountHolderUIStatus !== accountHolderUIStatusEnum.NONE
        }
      >
        <FormattedMessage id="common.button.continue" />
      </Button>
      {addBeneficialOwner && (
        <ModalAddUltimateBeneficialOwner
          settlementRecipientRef={settlementRecipient.ref}
          onAdded={() => {
            queryClient.invalidateQueries({ queryKey: ['fetchAllRecipients'] })
            setBeneficialOwner(false)
          }}
          onClose={() => {
            setBeneficialOwner(false)
          }}
        />
      )}
    </>
  )
}

export default StepUltimateBeneficialOwner
