import './styles.scss'

import React from 'react'
import { Loader } from 'semantic-ui-react'

export default function MoovyLink({
  deleteLink,
  bold,
  children,
  onClick,
  style,
  loading,
  toggled,
  disabled
}) {
  return (
    <>
      {toggled ? (
        <div style={style} className="moovy-link">
          {(bold && <b className="moovy-link-underline">{children}</b>) ||
            children}
        </div>
      ) : (
        <>
          <a
            style={style}
            className={(deleteLink && 'moovy-link-delete') || 'moovy-link'}
            onClick={(event) => !(disabled || loading) && onClick(event)}
          >
            <>{(bold && <b>{children}</b>) || children}</>
          </a>
          {loading && <Loader active inline size="mini" />}
        </>
      )}
    </>
  )
}
