import React, { useState } from 'react'

import CreateInvoiceModal from './CreateInvoiceModal'
import PropTypes from 'prop-types'
import invoiceService from '../../services/Invoicing'
import { useIntl } from 'react-intl'
import {
  calendarDateInputToBackendFormat,
  dateNowToCalendarInput
} from '../../services/utils'

const rowInitialValues = {
  amount: '',
  info: '',
  incurredAt: dateNowToCalendarInput()
}

const CreateInvoice = ({
  customerRef,
  open,
  newInvoiceCallback,
  operatorRealm,
  onClose
}) => {
  const intl = useIntl()
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [values, setValues] = useState(rowInitialValues)
  const [invoiceRows, setInvoiceRows] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState()

  const rowCreationDisabled =
    !values.amount || !selectedProduct || !values.incurredAt

  const removeInvoiceRow = (ref) => {
    const rowRemovedInvoices = invoiceRows.filter((row) => row.ref !== ref)
    setInvoiceRows(rowRemovedInvoices)
  }

  const addInvoiceRow = () => {
    const newRow = {
      ref: new Date().getTime(),
      product: { ref: selectedProduct.ref, name: selectedProduct.name },
      operator: {
        ref: selectedProduct.operator.ref,
        name: selectedProduct.operator.name
      },
      settlementRecipient: {
        ref: selectedProduct.settlementRecipient.ref,
        name: selectedProduct.settlementRecipient.name
      },
      vatPercent: selectedProduct.vatPercent,
      amount: values.amount,
      info: values.info,
      incurredAt: values.incurredAt
    }
    setInvoiceRows(invoiceRows.concat([newRow]))
  }

  const createInvoice = () => {
    setIsSubmitting(true)

    const rows = invoiceRows.map((row) => ({
      productRef: row.product.ref,
      amount: row.amount,
      info: row.info,
      incurredAtDate: calendarDateInputToBackendFormat(row.incurredAt)
    }))
    invoiceService
      .createCustomInvoice(customerRef, rows, operatorRealm)
      .then(() => {
        setSelectedProduct(null)
        setValues(rowInitialValues)
        setInvoiceRows([])
        setMessage({
          message: intl.formatMessage({
            id: 'createInvoice.action.success'
          }),
          type: 'info'
        })
        newInvoiceCallback()
      })
      .catch(() =>
        setMessage({
          message: intl.formatMessage({
            id: 'createInvoice.action.error'
          }),
          type: 'error'
        })
      )
      .finally(() => setIsSubmitting(false))
  }

  const handleRowChange = (e, { name, value }) =>
    setValues({ ...values, [name]: value })

  return (
    <CreateInvoiceModal
      open={open}
      invoiceRows={invoiceRows}
      onRowDelete={removeInvoiceRow}
      selectedProduct={selectedProduct}
      onSelectedProduct={(product) => setSelectedProduct(product)}
      rowValues={values}
      onChangeRowValue={handleRowChange}
      onAddRow={addInvoiceRow}
      rowCreationDisabled={rowCreationDisabled}
      message={message}
      onCreate={createInvoice}
      isSubmitting={isSubmitting}
      onClose={onClose}
    />
  )
}

CreateInvoice.propTypes = {
  customerRef: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  newInvoiceCallback: PropTypes.func.isRequired,
  operatorRealm: PropTypes.string
}

export default CreateInvoice
