import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../components'
import React from 'react'

const validationSchema = () => {
  return yup.object().shape({
    parkingCancelGracePeriodMinutes: yup
      .number()
      .min(0)
      .required('Cannot be empty')
  })
}

const ModalLocationEdit = ({ onClose, location, onSubmit, mutation }) => {
  const intl = useIntl()

  const getMinutes = (seconds) => {
    const minutes = seconds / 60
    return Math.round(minutes)
  }

  const initialFormikData = (location) => {
    const { parkingCancelGracePeriodSeconds, allowedExitTimeSeconds } = location
    return {
      parkingCancelGracePeriodMinutes: getMinutes(
        parkingCancelGracePeriodSeconds
      ),
      allowedExitTimeMinutes: getMinutes(allowedExitTimeSeconds)
    }
  }

  const handleFormitSubmit = (values) => {
    const { parkingCancelGracePeriodMinutes, allowedExitTimeMinutes } = values

    onSubmit({
      parkingCancelGracePeriodSeconds: parkingCancelGracePeriodMinutes * 60,
      allowedExitTimeSeconds: allowedExitTimeMinutes * 60,
      locationRef: location.ref
    })
  }

  return (
    <Formik
      initialValues={initialFormikData(location)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'locationEditModal.title'
              },
              {
                name: location.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="locationEditModal.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Group widths={'equal'}>
                <Form.Input
                  fluid
                  label={intl.formatMessage({
                    id: 'locationEditModal.label.parkingGracePeriod'
                  })}
                  value={values.parkingCancelGracePeriodMinutes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="parkingCancelGracePeriodMinutes"
                  type="number"
                  min={0}
                  error={errors.parkingCancelGracePeriodMinutes !== undefined}
                />
                <Form.Input
                  fluid
                  label={intl.formatMessage({
                    id: 'locationEditModal.label.allowedExitTime'
                  })}
                  value={values.allowedExitTimeMinutes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="allowedExitTimeMinutes"
                  type="number"
                  min={0}
                  error={errors.allowedExitTimeMinutes !== undefined}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={mutation.isPending}
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalLocationEdit
