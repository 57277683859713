import { useQuery } from '@tanstack/react-query'

import locationsService from '../services/Locations'

const getOperatorRealmLocations = async (operatorRealm) => {
  return locationsService.fetchLocations(operatorRealm)
}

const getOperatorLocations = async (operatorRef) =>
  locationsService.fetchLocationsForOperator(operatorRef)

/*
Returns locations by operatorRealm. If operatorRealm is falsy, will use superoperator endpoint (all locations)
 */
export function useOperatorRealmLocations(operatorRealm, options = {}) {
  return useQuery({
    queryKey: ['operator-realm-locations', operatorRealm],
    queryFn: () => getOperatorRealmLocations(operatorRealm),
    staleTime: 1000 * 60 * 15,
    ...options
  })
}

export function useOperatorLocations(operatorRef, options = {}) {
  return useQuery({
    queryKey: ['operator-locations', operatorRef],
    queryFn: () => getOperatorLocations(operatorRef),
    staleTime: 1000 * 60 * 15,
    ...options
  })
}
