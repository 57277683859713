import React from 'react'
import { MoovyTable, TablePagination, Toolbar } from '../../components'
import { FormattedMessage, useIntl } from 'react-intl'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import { Select, Table, Form } from 'semantic-ui-react'
import useAuthInfo from '../../hooks/useAuthInfo'
import useOperators from '../../hooks/useOperators'
import { useQuery } from '@tanstack/react-query'
import service from '../../services/potentiallyIncorrectLicensePlates'
import moovyDateTime from '../../services/utils/moovyDateTime'
import { formatSeconds } from '../../services/utils'

import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  useOperatorLocations,
  useOperatorRealmLocations
} from '../../hooks/useLocations'

const PotentiallyIncorrectLicensePlates = () => {
  const intl = useIntl()
  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const pageLimit = 20
  const [searchParams, setSearchParams] = useSearchParams()
  const operator = searchParams.get('operator')
  const location = searchParams.get('location')
  const activePage = searchParams.get('activePage') ?? 1
  const navigate = useNavigate()

  const query = useQuery({
    queryKey: [
      'potentiallyIncorrectLicensePlates',
      operator,
      location,
      activePage
    ],
    queryFn: () => {
      return service.fetchPaginated({
        operatorRef: operator,
        operatorRealm,
        locationRef: location,
        limit: pageLimit,
        page: activePage - 1
      })
    },
    enabled: !isSuperOperator || !!operator
  })

  const operatorRealmLocations = useOperatorRealmLocations(operatorRealm, {
    enabled: !!operatorRealm
  })

  const selectedOperatorLocations = useOperatorLocations(operator, {
    enabled: isSuperOperator && !!operator
  })

  const locationsData = operatorRealm
    ? operatorRealmLocations.data?.content ?? []
    : selectedOperatorLocations.data?.content ?? []

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.potentiallyIncorrectLicensePlates'
      }),
      active: true
    }
  ])

  const operators = useOperators()

  const operatorOptions =
    isSuperOperator && operators.data
      ? operators.data.content
          .sort((a, b) => `${a.name}`.localeCompare(b.name))
          .map((op) => ({
            key: op.ref,
            value: op.ref,
            text: op.name
          }))
      : []

  const ToolbarContent = () => (
    <Form style={{ maxWidth: '700px' }}>
      <Form.Group widths="equal">
        {isSuperOperator && (
          <Form.Field>
            <Select
              clearable
              placeholder={intl.formatMessage({
                id: 'potentiallyIncorrectLicensePlates.operator.placeholder'
              })}
              fluid
              search
              options={operatorOptions}
              value={operator}
              onChange={(ev, { value }) => {
                setParams({ operator: value, location: null, activePage: 1 })
              }}
            />
          </Form.Field>
        )}
        <Form.Field>
          <Select
            clearable
            placeholder={intl.formatMessage({
              id:
                operatorRealm || operator
                  ? 'potentiallyIncorrectLicensePlates.location.placeholder'
                  : 'potentiallyIncorrectLicensePlates.location.placeholder.noOperator'
            })}
            loading={operatorRealmLocations.isInitialLoading}
            disabled={
              query.isInitialLoading || operatorRealmLocations.isInitialLoading
            }
            fluid
            search
            options={locationsData.map((location) => ({
              key: location.ref,
              value: location.ref,
              text: location.name
            }))}
            value={location}
            onChange={(e, data) => {
              setParams({ location: data.value, activePage: 1 })
            }}
          />
        </Form.Field>
      </Form.Group>
    </Form>
  )

  const setParams = (params) => {
    let object = { operator, location, activePage }
    // Iterate over the keys in the params object and update the main object
    for (const [key, value] of Object.entries(params)) {
      if (isSuperOperator || key !== 'operator') {
        if (value === null) {
          delete object[key] // Remove the attribute if value is null
        } else {
          object[key] = value
        }
      }
    }
    setSearchParams(object)
  }

  const data = query.data?.data ?? []

  const onPageChange = (e, { activePage }) => {
    setParams({ activePage: activePage })
  }

  const navigateToRow = (ref) => {
    const searchParams = new URLSearchParams()

    searchParams.set('activePage', activePage)
    searchParams.set('location', location ?? '')
    if (isSuperOperator) {
      searchParams.set('operator', operator)
    }

    navigate(
      `../potentiallyIncorrectLicensePlate/${ref}?${searchParams.toString()}`
    )
  }
  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'common.breadcrumbs.potentiallyIncorrectLicensePlates'
        })}
        content={<ToolbarContent />}
        breadcrumbs={breadcrumbs}
      />
      <div className="Admin--Page--Content">
        <MoovyTable items={data} query={query} selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="potentiallyIncorrectLicensePlates.table.location" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="potentiallyIncorrectLicensePlates.table.start" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="potentiallyIncorrectLicensePlates.table.duration" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="potentiallyIncorrectLicensePlates.table.licensePlate" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="potentiallyIncorrectLicensePlates.table.suspectReason" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((row) => (
              <Table.Row key={row.ref} onClick={() => navigateToRow(row.ref)}>
                <Table.Cell>{row.parking?.location?.name}</Table.Cell>
                <Table.Cell>
                  {moovyDateTime.backendDateTimeToUI(row.parking?.start)}
                </Table.Cell>
                <Table.Cell>
                  {formatSeconds(row.parking?.parkingDurationInSeconds)}
                </Table.Cell>
                <Table.Cell>
                  {row.potentiallyIncorrectLicensePlateNumber}
                </Table.Cell>
                <Table.Cell>
                  <FormattedMessage
                    id={`potentiallyIncorrectLicensePlate.suspectReason.${row.suspectReason}`}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TablePagination
            colSpan={5}
            activePage={Number(activePage)}
            totalPages={query.data?.totalPages ?? 0}
            onPageChange={onPageChange}
          />
        </MoovyTable>
      </div>
    </>
  )
}

export default PotentiallyIncorrectLicensePlates
