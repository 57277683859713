import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../components'
import React from 'react'

const validationSchema = () => {
  return yup.object().shape({
    userAssignmentOverbookingCoefficient: yup
      .number()
      .min(0)
      .required('Cannot be empty')
  })
}

const ModalEditOverBooking = ({ onClose, service, onSubmit, mutation }) => {
  const intl = useIntl()

  const initialFormikData = ({ userAssignmentOverbookingCoefficient }) => {
    return { userAssignmentOverbookingCoefficient }
  }

  const hasValueChanged = (userAssignmentOverbookingCoefficient) => {
    return (
      service.userAssignmentOverbookingCoefficient !==
      userAssignmentOverbookingCoefficient
    )
  }

  const handleFormitSubmit = ({ userAssignmentOverbookingCoefficient }) => {
    if (hasValueChanged(userAssignmentOverbookingCoefficient)) {
      onSubmit({
        serviceRef: service.ref,
        userAssignmentOverbookingCoefficient
      })
    } else {
      onClose()
    }
  }

  return (
    <Formik
      initialValues={initialFormikData(service)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'modalEditOverBooking.title'
              },
              {
                name: service.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="modalEditOverBooking.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'modalEditOverBooking.label.userAssignmentOverbookingCoefficient'
                })}
                value={values.userAssignmentOverbookingCoefficient}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                min={0}
                name="userAssignmentOverbookingCoefficient"
                error={!!errors.userAssignmentOverbookingCoefficient}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={
                mutation.isPending ||
                !hasValueChanged(values.userAssignmentOverbookingCoefficient)
              }
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditOverBooking
