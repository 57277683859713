import PropTypes from 'prop-types'
import React from 'react'
import { Message } from 'semantic-ui-react'

export const createErrorMessage = (text) => ({
  type: 'error',
  text
})

export const createInfoMessage = (text) => ({
  type: 'info',
  text
})

const GenericMessage = ({ message, onDismiss }) => {
  if (!message) {
    return null
  }

  return (
    <Message
      info={message.type === 'info'}
      warning={message.type === 'warning'}
      error={message.type === 'error'}
      onDismiss={onDismiss}
    >
      {message.text}
    </Message>
  )
}
GenericMessage.propTypes = {
  message: PropTypes.shape({
    type: PropTypes.oneOf(['info', 'warning', 'error']),
    text: PropTypes.string
  })
}

export default GenericMessage
