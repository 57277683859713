import { get } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/subscribedService${relativePath}`
    : `/administration/subscribedService${relativePath}`

const fetchSubscribedService = async (ref, operatorRealm) => {
  try {
    const result = await get(createFullPath(`/${ref}`, operatorRealm))
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchSubscriptionServices = async (operatorRealm) => {
  const url = operatorRealm
    ? `/operator/${operatorRealm}/subscriptionService`
    : '/administration/administration/subscriptionService'
  const result = await get(url)
  return result
}
export default {
  fetchSubscribedService,
  fetchSubscriptionServices
}
