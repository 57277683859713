export const tablePaginationDirections = {
  DESC: 'DESC',
  ASC: 'ASC',
  stringValues: {
    DESC: 'descending',
    ASC: 'ascending'
  }
}

export const getTablePaginationInitialValues = (sortColumn) => {
  return {
    activePage: 1,
    totalPages: 1,
    sortColumn,
    direction: tablePaginationDirections.ASC
  }
}

export const getUpdatedPaginationSort = (sortColumn, pagination) => {
  if (sortColumn === pagination.sortColumn) {
    const direction =
      pagination.direction === tablePaginationDirections.DESC
        ? tablePaginationDirections.ASC
        : tablePaginationDirections.DESC
    return { ...pagination, direction }
  }
  return {
    ...pagination,
    direction: tablePaginationDirections.DESC,
    sortColumn
  }
}

export const getTotalPages = (rowCount, pageLimit) => {
  return Math.ceil(rowCount / pageLimit)
}

export const getDataForPage = (data, activePage, pageLimit) => {
  const startIndex = (activePage - 1) * pageLimit
  const endIndex = startIndex + pageLimit
  return data.slice(startIndex, endIndex)
}

export const getSortDirection = (clickedColumnName, { column, direction }) => {
  return clickedColumnName === column
    ? tablePaginationDirections.stringValues[direction]
    : null
}

export const sortColumn = (
  clickedColumnName,
  { column, direction },
  onSort
) => {
  if (clickedColumnName === column) {
    onSort(
      clickedColumnName,
      direction === tablePaginationDirections.DESC
        ? tablePaginationDirections.ASC
        : tablePaginationDirections.DESC
    )
  } else {
    onSort(clickedColumnName, tablePaginationDirections.DESC)
  }
}

export const currentPageHasMultiSelectedRows = (page, selected) =>
  page.some((row) =>
    selected.map((selectedRow) => selectedRow.ref).includes(row.ref)
  )

export const multiSelectPage = (page, selected) =>
  /* Copied the behaviour from gmail.
  If even one item on page is selected,selecting the whole page will clear page selections.
  If nothing selected, will select the whole page.

  Assumes that both page and selected contains objects that have a ref attribute.
  Maybe do support for primitives later? Or pass the filter function from caller? */
  currentPageHasMultiSelectedRows(page, selected)
    ? page.map((row) => row).filter((ref) => selected.includes(ref))
    : page
