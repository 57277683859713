import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyModalConfirmation,
  MoovyPlaceholderLoader,
  MutationErrorMessage
} from '../../components'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import GeoJSONEditor from '../../components/GeoJSON/GeoJSONEditor'
import locationService from '../../services/Locations'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'

const ModalGeoJSON = ({ location, onClose }) => {
  const [geoJSON, setGeoJSON] = useState('')
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [error, setError] = useState(false)
  const { operatorRealm, isSuperOperator } = useAuthInfo()
  const intl = useIntl()
  const { ref: locationRef } = location

  const geoJsonQuery = useQuery({
    queryKey: ['locationGeoJSON', locationRef],
    queryFn: () => locationService.fetchGeoJSON(locationRef, operatorRealm),
    enabled: Boolean(locationRef && isSuperOperator),
    select: (data) => JSON.stringify(data.locationAreas, null, 4) ?? ''
  })

  useEffect(() => {
    if (!geoJSON) {
      setGeoJSON(geoJsonQuery.data)
    }
  }, [geoJsonQuery.isSuccess])

  const originalGeoJSON = geoJsonQuery.data

  const { mutate: updateGeoJSON, ...mutation } = useMutation({
    mutationFn: () =>
      locationService.updateLocationData(null, locationRef, {
        geojson: { locationAreas: JSON.parse(geoJSON) }
      }),
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'locationGeoJSONModal.toast.success.title',
        description: 'locationGeoJSONModal.toast.success.body'
      })
      onClose()
    },
    onError: () =>
      showLocalizedMoovyToast(intl, {
        title: 'locationGeoJSONModal.toast.failed.title',
        description: 'locationGeoJSONModal.toast.failed.body',
        type: 'error'
      }),
    onSettled: () => setConfirmOpen(false)
  })

  const submitHandler = (event) => {
    event.preventDefault()
    setConfirmOpen(true)
  }

  const submitDisabled =
    geoJsonQuery.isError ||
    geoJsonQuery.isFetching ||
    mutation.isPending ||
    error ||
    originalGeoJSON === geoJSON

  return (
    <Modal open size="large" onClose={onClose}>
      <Modal.Header
        content={intl.formatMessage(
          {
            id: 'locationGeoJSONModal.title'
          },
          {
            name: location.name
          }
        )}
      />
      <Modal.Content>
        <MutationErrorMessage
          mutation={geoJsonQuery}
          defaultErrorTextLangId="locationGeoJSONModal.query.error"
          messageNegative
        />
        {geoJsonQuery.isFetching ? (
          <MoovyPlaceholderLoader paragraphs={2} fluid />
        ) : (
          <Form onSubmit={submitHandler}>
            <GeoJSONEditor
              value={geoJSON}
              onChange={(event, data) => {
                setGeoJSON(data.value)
                setError(false)
              }}
              error={error}
              onError={(e) => setError(e)}
            />
          </Form>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          type="submit"
          primary
          onClick={submitHandler}
          disabled={submitDisabled}
          loading={mutation.isPending}
        >
          <FormattedMessage id="common.button.save" />
        </Button>

        <MoovyModalConfirmation
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onSubmit={updateGeoJSON}
          mutation={mutation}
          lang={{
            titleElement: (
              <FormattedMessage id="locationGeoJSONModal.confirm.title" />
            ),
            bodyElement: (
              <FormattedMessage id="locationGeoJSONModal.confirm.content" />
            ),
            buttonConfirmKey: 'common.button.confirm'
          }}
        />
      </Modal.Actions>
    </Modal>
  )
}

export default ModalGeoJSON
