import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  realm: localStorage.getItem('moovy-admin-realm'),
  roles: [],
  knownRealms: [process.env.REACT_APP_SUPEROPERATOR_REALM],
  isInitialized: false,
  isInitializing: false
}

export const key = 'auth'

const slice = createSlice({
  name: key,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    // Redux toolkit allows modification of state directly and handles immutability automatically.
    setAuthentication(state, action) {
      sessionStorage.setItem('authToken', action.payload.token)
      state.token = action.payload.token
      state.roles = action.payload.roles
      state.isInitialized = true
      state.isInitializing = false
    },
    reset() {
      return initialState
    },
    setInfo(state, action) {
      state.info = action.payload
    },
    setToken(state, action) {
      const token = action.payload
      sessionStorage.setItem('authToken', token)
      state.token = token
    },
    setRealm(state, action) {
      localStorage.setItem('moovy-admin-realm', action.payload)
      state.realm = action.payload
    }
  }
})

export default slice.reducer

export const {
  setAuthentication,
  reset,
  setInfo,
  setToken,
  setRealm
} = slice.actions

export const authSelectors = {
  getToken: (state) => state[key].token,
  getRoles: (state) => state[key].roles,
  isAuthenticated: (state) => !!state[key].token,
  isInitialized: (state) => state[key].isInitialized,
  isInitializing: (state) => state[key].isInitializing,
  getInfo: (state) => state[key].info,
  getUserName: (state) =>
    state[key].info && state[key].info.name ? state[key].info.name : undefined,
  getPreferredUsername: (state) =>
    state[key].info && state[key].info.preferred_username
      ? state[key].info.preferred_username
      : undefined,
  getRealm: (state) => state[key].realm,
  getOperatorRealm: (state) =>
    state[key].realm !== process.env.REACT_APP_SUPEROPERATOR_REALM
      ? state[key].realm
      : undefined,
  isSuperOperator: (state) =>
    !!state[key].token &&
    state[key].realm === process.env.REACT_APP_SUPEROPERATOR_REALM
}
