export const isEmptyObject = (obj) => {
  return obj === undefined ||
    !obj ||
    (Object.keys(obj).length === 0 && obj.constructor === Object)
    ? true
    : false
}

export const isObject = (item) => {
  return typeof item === 'object' && item !== null
}

export const addMissingValues = (existingProperties, initialProperties) => {
  let allProperties = { ...existingProperties }

  Object.keys(initialProperties).forEach(function(key) {
    if (existingProperties[key] === undefined) {
      allProperties[key] = initialProperties[key]
    }
  })

  return allProperties
}

export const findChangedValues = (newValues, values) => {
  let changedObject = {}
  Object.keys(newValues).forEach(function(key) {
    if (newValues[key] !== values[key]) {
      changedObject[key] = newValues[key]
    }
  })

  if (isEmptyObject(changedObject)) {
    return null
  } else {
    return changedObject
  }
}

export const resetFormValues = (values, setFieldValue) => {
  Object.keys(values).forEach(function(key) {
    setFieldValue(`${key}`, `${values[key]}`)
  })
}

export const valuesToFormValues = (valuesToBeConverted, prefix) => {
  let convertedObject = {}

  Object.keys(valuesToBeConverted).forEach(function(key) {
    convertedObject[key.slice(prefix.length)] = valuesToBeConverted[key]
  })

  if (convertedObject.locationAreas) {
    convertedObject.locationAreas = JSON.parse(convertedObject.locationAreas)
  }
  if (convertedObject.tariffStructure) {
    convertedObject.tariffStructure = JSON.parse(
      convertedObject.tariffStructure
    )
  }

  return convertedObject
}

export const valuesFromFormValues = (valuesToBeConverted, prefix) => {
  if (!valuesToBeConverted) return {}
  let convertedObject = {}

  Object.keys(valuesToBeConverted).forEach(function(key) {
    convertedObject[prefix + key] = valuesToBeConverted[key]
  })

  return convertedObject
}
