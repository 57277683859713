import React from 'react'
import { Accordion } from 'semantic-ui-react'

// Turn this into a more general component if we start having more instructions?
const UserGroupInstructions = () => {
  const panels = [
    {
      key: 'usergroups',
      title: {
        content: 'Mitä ovat käyttäjäryhmät?',
        icon: 'question circle outline'
      },
      content: {
        content: (
          <>
            <p>
              Käyttäjäryhmien kautta käyttäjille voidaan jakaa erilaisia
              oikeuksia. Käyttäjäryhmällä voi olla palvelusopimuksia sekä
              etusopimuksia.
            </p>
            <p>
              Käyttäjäryhmään kuuluvat käyttäjät saavat oikeuden käyttäjäryhmän
              palvelu- sekä etusopimuksiin.
            </p>
          </>
        )
      }
    },
    {
      key: 'agreements',
      title: {
        content: 'Mitä ovat palvelusopimukset ja etusopimukset?',
        icon: 'question circle outline'
      },
      content: {
        content: (
          <>
            <p>
              Palvelusopimukset antavat käyttäjäryhmän jäsenille oikeuden tilata
              palvelusopimuksen tuotetta.
            </p>
            <p>
              Etusopimus antaa oikeuden yritystiliasiakkaille myöntää
              etusopimuksen pysäköintietua.
            </p>
          </>
        )
      }
    }
  ]

  return <Accordion panels={panels} exclusive={false} styled />
}

export default UserGroupInstructions
