import 'react-datepicker/dist/react-datepicker.css'

import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import { Form, Input, Label } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { forwardRef, useState } from 'react'

import { DateTime } from 'luxon'
// React-datepicker doesn't support date-fns v3 as of version 5.0.0 https://github.com/Hacker0x01/react-datepicker/issues/4433
import fi from 'date-fns/locale/fi'

registerLocale('fi', fi)
setDefaultLocale('fi')

export const CALENDAR_DATE_FORMAT = 'dd.MM.yyyy'

const CalendarInput = forwardRef((props, ref) => (
  <>
    <Input
      icon="calendar"
      iconPosition="left"
      ref={ref}
      {...props}
      placeholder={props.placeholder}
    />
    {props.errorlabel}
  </>
))

const MoovyDateRangePicker = ({
  name = undefined,
  placeholderText = undefined,
  maxRangeAsDays = undefined,
  allowEmptyValue = undefined,
  startDate,
  endDate,
  onChange
}) => {
  const CALENDAR_ERROR_ENUM = {
    EMPTY_VALUE: 'EMPTY_VALUE',
    INVALID_VALUE: 'INVALID_VALUE',
    OVER_MAX_RANGE: 'OVER_MAX_RANGE',
    MIN_IS_OVER_MAX: 'MIN_IS_OVER_MAX'
  }

  const intl = useIntl()
  const [calendarOpen, setCalendarOpen] = useState(false)

  const hasInvalidDateValue = (startDate, endDate) => {
    if (!startDate && !endDate) {
      if (allowEmptyValue) {
        return false
      }
      return CALENDAR_ERROR_ENUM.EMPTY_VALUE
    }

    const startTime = DateTime.fromJSDate(startDate).startOf('day')
    const endTime = DateTime.fromJSDate(endDate).endOf('day')

    if (!startTime.isValid || !endTime.isValid) {
      if (calendarOpen) {
        return ''
      }
      return CALENDAR_ERROR_ENUM.INVALID_VALUE
    }
    if (startTime > endTime) {
      return CALENDAR_ERROR_ENUM.MIN_IS_OVER_MAX
    }

    if (
      maxRangeAsDays &&
      endTime.diff(startTime, ['days']).days > maxRangeAsDays
    ) {
      return CALENDAR_ERROR_ENUM.OVER_MAX_RANGE
    }

    return false
  }

  const RenderValueError = ({ errorType }) => {
    if (!errorType) {
      return ''
    }

    let errorLangId = ''
    switch (errorType) {
      case CALENDAR_ERROR_ENUM.EMPTY_VALUE:
        errorLangId = 'moovyDateRangePicker.error.empty'
        break
      case CALENDAR_ERROR_ENUM.INVALID_VALUE:
        errorLangId = 'moovyDateRangePicker.error.invalidValue'
        break
      case CALENDAR_ERROR_ENUM.OVER_MAX_RANGE:
        errorLangId = 'moovyDateRangePicker.error.overMaxDateRange'
        break
      case CALENDAR_ERROR_ENUM.MIN_IS_OVER_MAX:
        errorLangId = 'moovyDateRangePicker.error.MinIsOverMaxDate'
        break
      default:
        return 'moovyDateRangePicker.error.invalidValue'
    }

    return (
      <Label
        pointing="left"
        floating
        style={{
          background: '#fff6f6',
          color: '#9f3a38',
          textAlign: 'center',
          padding: '10px',
          border: '1px solid #e0b4b4',
          minWidth: '150px'
        }}
      >
        <b>
          <FormattedMessage
            id={errorLangId}
            values={{ days: maxRangeAsDays }}
          />
        </b>
      </Label>
    )
  }

  const handleChangeRaw = (event) => {
    const startDateAsString = event?.target?.value?.split('-')[0]?.trim()
    const endDateAsString = event?.target?.value?.split('-')[1]?.trim()

    if (!startDateAsString || !endDateAsString) return

    const startDateAsDatetime = DateTime.fromFormat(
      startDateAsString,
      CALENDAR_DATE_FORMAT
    )
    const endDateAsDatetime = DateTime.fromFormat(
      endDateAsString,
      CALENDAR_DATE_FORMAT
    )

    if (startDateAsDatetime.isValid && endDateAsDatetime.isValid) {
      onChange(
        [
          startDateAsDatetime.startOf('day').toJSDate(),
          endDateAsDatetime.endOf('day').toJSDate(),
          !hasInvalidDateValue(
            startDateAsDatetime.startOf('day').toJSDate(),
            endDateAsDatetime.endOf('day').toJSDate()
          )
        ],
        event
      )
    }
  }

  return (
    <Form.Field error={!!hasInvalidDateValue(startDate, endDate)}>
      <DatePicker
        name={name}
        selected={startDate}
        customInput={
          <CalendarInput
            placeholder={placeholderText}
            errorlabel={
              <RenderValueError
                errorType={hasInvalidDateValue(startDate, endDate)}
              />
            }
          />
        }
        startDate={startDate}
        endDate={endDate}
        onChange={(dates, event) => {
          const [start, end] = dates
          const startAsDatetime =
            start && DateTime.fromJSDate(start).startOf('day')
          const endAsDatetime = end && DateTime.fromJSDate(end).endOf('day')
          const changedStartDate = startAsDatetime
            ? startAsDatetime.toJSDate()
            : start
          const changedEndDate = endAsDatetime ? endAsDatetime.toJSDate() : end
          onChange(
            [
              changedStartDate,
              changedEndDate,
              !hasInvalidDateValue(changedStartDate, changedEndDate)
            ],
            event
          )
        }}
        onChangeRaw={handleChangeRaw}
        allowSameDay
        onCalendarOpen={() => setCalendarOpen(true)}
        onCalendarClose={() => setCalendarOpen(false)}
        selectsRange
        dateFormat={CALENDAR_DATE_FORMAT}
        todayButton={intl.formatMessage({
          id: 'moovyDateRangePicker.button.today'
        })}
      />
    </Form.Field>
  )
}

export default MoovyDateRangePicker
