import React, { useState } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { Grid, Header, Segment } from 'semantic-ui-react'

import useAuthInfo from '../../../hooks/useAuthInfo'
import pricingService from '../../../services/Pricing'
import servicesService from '../../../services/Services'
import subscriptionsService from '../../../services/Subscriptions'
import CancelSubscriptionModal from './CancelSubscriptionModal'
import {
  ServiceCard,
  ServiceLocationCard,
  ServicePricingCard
} from './ServiceInformation'
import SubscriptionsTable from './SubscriptionsTable'

export default function SubscribedService({ subscribedService }) {
  const [selected, setSelected] = useState(null)
  const queryClient = useQueryClient()
  const { operatorRealm } = useAuthInfo()

  const subscriptionsQuery = useQuery({
    queryKey: ['subscriptions', subscribedService.ref],
    queryFn: () =>
      subscriptionsService.fetchSubscriptionsForSubscribedService(
        subscribedService.ref,
        operatorRealm
      ),
    enabled: !!subscribedService
  })

  const serviceQuery = useQuery({
    queryKey: [
      'service',
      subscribedService ? subscribedService.service.ref : null
    ],
    queryFn: () =>
      servicesService.fetchService(
        subscribedService.service.ref,
        operatorRealm
      ),
    enabled: !!subscribedService
  })

  const pricingQuery = useQuery({
    queryKey: [
      'servicePricing',
      subscribedService ? subscribedService.service.ref : null
    ],
    queryFn: () =>
      pricingService.fetchServicePricing(
        subscribedService.service.ref,
        operatorRealm
      ),
    enabled: !!subscribedService
  })

  const pricing = pricingQuery.data ? pricingQuery.data : []

  const service = serviceQuery.data ? serviceQuery.data : {}
  const locations = service && service.locations ? service.locations : []
  const subscriptions = subscriptionsQuery.data ? subscriptionsQuery.data : []

  const invalidateSubscriptions = () =>
    queryClient.invalidateQueries({
      queryKey: ['subscriptions', subscribedService.ref]
    })

  return (
    <Segment basic>
      <Grid stackable columns="2" divided>
        <Grid.Column width="7">
          <Segment basic loading={subscriptionsQuery.isLoading}>
            <SubscriptionsTable
              subscriptions={subscriptions}
              onClick={setSelected}
              onUpdate={invalidateSubscriptions}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column width="9">
          <Segment basic loading={subscriptionsQuery.isLoading}>
            <Header>{service.name}</Header>
            <ServiceCard service={service} loading={serviceQuery.isLoading} />
            <ServicePricingCard
              pricing={pricing}
              loading={pricingQuery.isLoading}
            />
            <ServiceLocationCard
              locations={locations}
              loading={serviceQuery.isLoading}
            />
          </Segment>
        </Grid.Column>
      </Grid>
      <CancelSubscriptionModal
        subscription={selected}
        service={serviceQuery.data ? serviceQuery.data : null}
        onClose={() => setSelected(null)}
        onUpdate={invalidateSubscriptions}
      />
    </Segment>
  )
}
