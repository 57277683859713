import { FormattedMessage, useIntl } from 'react-intl'
import { Label, Menu, Popup, Segment, Tab } from 'semantic-ui-react'
import React, { useState } from 'react'
import { creditCardExpired, paymentMethod } from '../../../services/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { ConversationHistory } from '../../../components'
import CustomerInvoicing from '../CustomerInvoicing/CustomerInvoicing'
import CustomerToolbar from '../CustomerToolbar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Parkings from './tabParkings/parkings'
import PaymentCards from './tabPaymentCards/paymentCards'
import SubscribedServices from '../CustomerSubscribedServices'
import UserAssignments from './tabUserAssignments/userAssignments'
import UserBlockingHistory from './tabBlocklist/userBlockingHistory'
import UserGroupView from '../../../containers/UserGroupView'
import UserVehicles from './tabVehicles'
import UserVouchers from './tabVouchers/userVouchers'
import { getUserData } from './moovyUserTabMenuCount'
import { paymentCardFetchType } from '../../../services/utils/DTOEnums'
import useAuthInfo from '../../../hooks/useAuthInfo'
import userService from '../../../services/User/UserServices'

// PH: Default card is interpreted from card type of user details and payment cards
// Adyen: Default card is directly available in card object
export const containsDefaultPaymentCard = (customer, cards) => {
  return (
    cards &&
    cards.filter((card) =>
      card?.paymentMethodType === paymentMethod.ADYEN
        ? card?.defaultCard
        : customer?.userDetails?.defaultPaymentCard ===
          card?.paymentCardCategory
    ).length > 0
  )
}

const MoovyCustomer = ({ customer, userBlockingPrevented, onRefreshUser }) => {
  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const intl = useIntl()
  const queryClient = useQueryClient()

  const [paymentFetchType, setPaymentFetchType] = useState(
    paymentCardFetchType.ACTIVE
  )
  const [showConversationCount, setShowConversationCount] = useState(true)

  const {
    subscribedServices,
    vouchers,
    assignments,
    vehicles,
    userGroups,
    conversations,
    refetchConversation,
    refetchUserGroups,
    refetchVehicles,
    isDataLoading
  } = getUserData(operatorRealm, customer.ref)

  const cardsQuery = useQuery({
    queryKey: ['paymentCards', customer.ref, paymentFetchType],
    queryFn: () =>
      userService.fetchPaymentCards(
        customer.ref,
        paymentFetchType,
        operatorRealm
      )
  })

  const cards =
    (cardsQuery.data &&
      cardsQuery.data.sort(
        (a, b) => new Date(b.creationTime) - new Date(a.creationTime)
      )) ||
    []

  const noCard = cards.length <= 0

  const activePayment = containsDefaultPaymentCard(
    customer,
    cards.filter((card) => !creditCardExpired(card))
  )

  const defaultPaymentCard = containsDefaultPaymentCard(customer, cards)

  const ShowErrorIcon = ({ errorLocalizationId }) => {
    return (
      <>
        {(errorLocalizationId && (
          <Popup
            content={intl.formatMessage({
              id: errorLocalizationId
            })}
            trigger={
              <Label
                floating
                style={{ backgroundColor: 'transparent', fontSize: '1.5em' }}
              >
                <FontAwesomeIcon
                  icon={['far', 'exclamation-circle']}
                  color="red"
                />
              </Label>
            }
          />
        )) || (
          <Label
            floating
            style={{ backgroundColor: 'transparent', fontSize: '1.5em' }}
          >
            <FontAwesomeIcon icon={['far', 'exclamation-circle']} color="red" />
          </Label>
        )}
      </>
    )
  }

  const StyledMenuItem = ({
    textLocalizationId,
    errorLocalizationId,
    showError,
    showEmpty
  }) => {
    return (
      <>
        {showError ? (
          <span style={{ color: 'red' }}>
            <FormattedMessage id={textLocalizationId} />
          </span>
        ) : showEmpty ? (
          <span style={{ color: '#ababab' }}>
            <FormattedMessage id={textLocalizationId} />
          </span>
        ) : (
          <FormattedMessage id={textLocalizationId} />
        )}
        {showError && (
          <ShowErrorIcon errorLocalizationId={errorLocalizationId} />
        )}
      </>
    )
  }

  const allPanes = {
    parkings: {
      menuItem: intl.formatMessage({
        id: 'customers.detail.tab.parkings'
      }),
      pane: {
        key: 'parkingsTab',
        content: (
          <Parkings customer={customer} noActivePayment={!activePayment} />
        )
      }
    },
    userSubscribedServices: {
      menuItem: (
        <Menu.Item key="subscribedServicesItem">
          <StyledMenuItem
            textLocalizationId={'customers.detail.tab.subscribedServices'}
            showEmpty={subscribedServices.length === 0 && !isDataLoading}
          />
        </Menu.Item>
      ),
      pane: {
        key: 'userSubscribedServices',
        content: (
          <Segment basic>
            <SubscribedServices customer={customer} />
          </Segment>
        )
      }
    },
    userAssignments: {
      menuItem: (
        <Menu.Item key="userAssignments">
          <StyledMenuItem
            textLocalizationId={'customers.detail.tab.userAssignments'}
            showEmpty={assignments.length === 0 && !isDataLoading}
          />
        </Menu.Item>
      ),
      pane: {
        key: 'userAssignmentsTab',
        content: (
          <Segment basic>
            <UserAssignments
              customer={customer}
              operatorRealm={operatorRealm}
            />
          </Segment>
        )
      }
    },
    vouchers: {
      menuItem: (
        <Menu.Item key="vouchersItem">
          <StyledMenuItem
            textLocalizationId={'customers.detail.tab.vouchers'}
            showEmpty={vouchers.length === 0 && !isDataLoading}
          />
        </Menu.Item>
      ),
      pane: {
        key: 'vouchersTab',
        content: (
          <Segment basic>
            <UserVouchers
              operatorRealm={operatorRealm}
              userRef={customer.ref}
            />
          </Segment>
        )
      }
    },
    paymentCards: {
      menuItem: (
        <Menu.Item key="paymentMenuItem">
          <StyledMenuItem
            textLocalizationId={'customers.detail.tab.paymentMethods'}
            showError={
              isDataLoading || cardsQuery.isLoading
                ? false
                : !activePayment || !defaultPaymentCard
            }
            errorLocalizationId={
              noCard
                ? 'customers.detail.tab.paymentMethods.popup.paymentCardMissing'
                : !defaultPaymentCard
                  ? 'customers.detail.tab.paymentMethods.popup.defaultCardMissing'
                  : !activePayment &&
                    'customers.detail.tab.paymentMethods.popup.paymentCardExpired'
            }
          />
        </Menu.Item>
      ),
      pane: {
        key: 'paymentTab',
        content: (
          <Segment basic>
            <PaymentCards
              cards={cards}
              customer={customer}
              operatorRealm={operatorRealm}
              onChangeCardFilterType={(fetchType) =>
                setPaymentFetchType(fetchType)
              }
              onRefresh={() =>
                queryClient.invalidateQueries({ queryKey: ['paymentCards'] })
              }
            />
          </Segment>
        )
      }
    },
    invoicing: {
      menuItem: (
        <Menu.Item key="invoiceItem">
          <StyledMenuItem
            textLocalizationId={'customers.detail.tab.invoiceData'}
          />
        </Menu.Item>
      ),
      pane: {
        key: 'invoicingTab',
        content: (
          <Segment basic>
            <CustomerInvoicing
              customer={customer}
              onRefreshUser={onRefreshUser}
            />
          </Segment>
        )
      }
    },
    userGroups: {
      menuItem: (
        <Menu.Item key="userGroupsItem">
          <StyledMenuItem
            textLocalizationId={'customers.detail.tab.userGroups'}
            showEmpty={userGroups.length === 0 && !isDataLoading}
          />
        </Menu.Item>
      ),
      pane: {
        key: 'userGroupTab',
        content: (
          <Segment basic>
            <UserGroupView
              customer={customer}
              onRefetchUserGroups={() => refetchUserGroups()}
            />
          </Segment>
        )
      }
    },
    vehicles: {
      menuItem: (
        <Menu.Item key="vehiclesMenuItem">
          <StyledMenuItem
            textLocalizationId={'customers.detail.tab.vehicles'}
            showError={vehicles.length === 0 && !isDataLoading}
          />
        </Menu.Item>
      ),
      pane: {
        key: 'vehicles',
        content: (
          <Segment basic>
            <UserVehicles
              customer={customer}
              onRefetchVehicles={() => refetchVehicles()}
            />
          </Segment>
        )
      }
    },
    comments: {
      menuItem: (
        <Menu.Item key="conversationMenuItem">
          <FormattedMessage id="customers.detail.tab.conversationHistory" />
          {showConversationCount && conversations.length > 0 && (
            <Label circular floating>
              {conversations.length}
            </Label>
          )}
        </Menu.Item>
      ),
      pane: {
        key: 'conversationHistory',
        content: (
          <Segment basic>
            <ConversationHistory
              customerRef={customer.ref}
              conversations={conversations}
              refetchConversations={() => refetchConversation()}
            />
          </Segment>
        )
      }
    },
    blockingList: {
      menuItem: (
        <Menu.Item key="blockingListMenuItem">
          <FormattedMessage id="customers.detail.tab.blockingList" />
        </Menu.Item>
      ),
      pane: {
        key: 'blockingList',
        content: (
          <Segment basic>
            <UserBlockingHistory
              userRef={customer.ref}
              activeBlockListEntry={customer?.activeBlockListEntry}
              userBlockingPrevented={userBlockingPrevented}
              userBlockListStatus={customer.blockListStatus}
              updateCustomer={onRefreshUser}
            />
          </Segment>
        )
      }
    }
  }

  const [activeIndex, setActiveIndex] = useState(0)

  const onShowAllVehicles = () => {
    setActiveIndex(
      Object.values(allPanes).findIndex((item) => item.pane.key === 'vehicles')
    )
  }
  return (
    <div>
      <CustomerToolbar
        customer={customer || null}
        vehicles={vehicles}
        onShowAllVehicles={onShowAllVehicles}
        onRefreshUser={onRefreshUser}
      />
      <div className="Admin--Page--Content">
        <Tab
          activeIndex={activeIndex}
          onTabChange={(e, { activeIndex }) => {
            if (
              Object.values(allPanes).findIndex(
                (item) => item.pane.key === 'conversationHistory'
              ) == activeIndex
            ) {
              setShowConversationCount(false)
            }
            setActiveIndex(activeIndex)
          }}
          panes={
            isSuperOperator
              ? Object.values(allPanes)
              : [
                  allPanes.parkings,
                  allPanes.userSubscribedServices,
                  allPanes.userAssignments,
                  allPanes.vouchers,
                  allPanes.paymentCards,
                  allPanes.userGroups,
                  allPanes.vehicles
                ]
          }
          renderActiveOnly={false}
        />
      </div>
    </div>
  )
}

export default MoovyCustomer
