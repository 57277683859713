import { get, post, put } from '../utils/api-renewed'

const fetchPaginated = (options) => {
  const { operatorRef, locationRef, limit, page, operatorRealm } = options
  return get(
    !operatorRealm
      ? '/administration/licensePlatePotentiallyIncorrect'
      : `/operator/${operatorRealm}/licensePlatePotentiallyIncorrect`,
    {
      operatorRef,
      locationRef,
      page,
      limit
    }
  )
}

const fetchByRef = (ref, operatorRealm) => {
  return get(
    !operatorRealm
      ? '/administration/licensePlatePotentiallyIncorrect/' + ref
      : `/operator/${operatorRealm}/licensePlatePotentiallyIncorrect/${ref}`
  )
}

const update = (operatorRealm, ref, { status, reviewedReasonCategory }) => {
  return put(
    !operatorRealm
      ? '/administration/licensePlatePotentiallyIncorrect/' + ref + '/status'
      : `/operator/${operatorRealm}/licensePlatePotentiallyIncorrect/${ref}/status`,
    {
      status,
      reviewedReasonCategory
    }
  )
}

const checkPlate = (operatorRealm, ref, { newLicensePlate }) => {
  return post(
    !operatorRealm
      ? '/administration/licensePlatePotentiallyIncorrect/' +
          ref +
          '/checkLicensePlate'
      : `/operator/${operatorRealm}/licensePlatePotentiallyIncorrect/${ref}/checkLicensePlate`,
    {
      newLicensePlate
    }
  )
}

const fixPlate = (
  operatorRealm,
  ref,
  { newLicensePlate, reviewedReasonCategory }
) => {
  return post(
    !operatorRealm
      ? '/administration/licensePlatePotentiallyIncorrect/' +
          ref +
          '/fixLicensePlate'
      : `/operator/${operatorRealm}/licensePlatePotentiallyIncorrect/${ref}/fixLicensePlate`,
    {
      newLicensePlate,
      reviewedReasonCategory
    }
  )
}

export default {
  fetchPaginated,
  fetchByRef,
  update,
  checkPlate,
  fixPlate
}
