import { Button, Icon, Table } from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import React from 'react'
import {
  dateInputFormat,
  dateTimeFromFormat,
  formatPrice
} from '../../../services/utils'
import { DateTime } from 'luxon'

const getIncurredAtClassName = (incurredAt) => {
  const dateTime = dateTimeFromFormat(incurredAt, dateInputFormat)
  if (dateTime.isValid && dateTime.month !== DateTime.local().month) {
    return 'redColor'
  }
  return null
}

const CreateCustomInvoiceRowTable = ({ rows, onRowDelete, isSubmitting }) => (
  <Table>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <FormattedMessage id="createInvoiceModal.invoiceRowTable.header.product" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="createInvoiceModal.invoiceRowTable.header.operator" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="createInvoiceModal.invoiceRowTable.header.recipient" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="createInvoiceModal.invoiceRowTable.header.total" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="createInvoiceModal.invoiceRowTable.header.incurredAt" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="createInvoiceModal.invoiceRowTable.header.detail" />
        </Table.HeaderCell>
        <Table.HeaderCell></Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {rows.map((row) => (
        <Table.Row key={row.ref}>
          <Table.Cell>{row.product.name}</Table.Cell>
          <Table.Cell>{row.operator.name}</Table.Cell>
          <Table.Cell>{row.settlementRecipient.name}</Table.Cell>
          <Table.Cell>{formatPrice(row.amount)}</Table.Cell>
          <Table.Cell>
            <span className={getIncurredAtClassName(row.incurredAt)}>
              {row.incurredAt}
            </span>
          </Table.Cell>
          <Table.Cell>{row.info}</Table.Cell>
          <Table.Cell>
            <Button
              basic
              icon
              floated="right"
              onClick={() => onRowDelete(row.ref)}
              disabled={isSubmitting}
            >
              <Icon name="trash alternate" color="red" />
            </Button>
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell colSpan="3" />
        <Table.HeaderCell>
          {formatPrice(
            rows
              .map((r) => Number.parseFloat(r.amount))
              .reduce((a, b) => a + b, 0)
          )}
        </Table.HeaderCell>
        <Table.HeaderCell colSpan="3" />
      </Table.Row>
    </Table.Footer>
  </Table>
)

CreateCustomInvoiceRowTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  onRowDelete: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool
}

export default CreateCustomInvoiceRowTable
