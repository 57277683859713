import PropTypes from 'prop-types'
import React from 'react'
import { List } from 'semantic-ui-react'

import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import { formatDateAndTime, formatPrice } from '../../services/utils'
import { voucher as voucherEnums } from '../../services/utils/DTOEnums'
import { voucherInfoShape, voucherShape } from '../../services/utils/shapes'

const VoucherFields = ({ voucher, voucherInfo }) => (
  <List>
    <List.Item>
      <List.Header>Tunniste</List.Header>
      {voucher.ref}
    </List.Item>

    <List.Item>
      <List.Header>Myöntämisaika</List.Header>
      {formatDateAndTime(voucher.grantedDate)}
    </List.Item>

    <List.Item>
      <List.Header>Myönnetty puhelinnumerolle</List.Header>
      {voucher.grantedTo}
    </List.Item>

    {voucher.user && (
      <List.Item>
        <List.Header>Käyttäjä</List.Header>
        <RelativeLinkForCurrentUser to={`customers/${voucher.user.ref}`}>
          {voucher.user.name}
        </RelativeLinkForCurrentUser>
      </List.Item>
    )}

    {voucher.categoryType === voucherEnums.categoryTypes.PREBOOKING && (
      <>
        <List.Item>
          <List.Header>Varauksen kesto</List.Header>
          {formatDateAndTime(voucher.validFrom)} -{' '}
          {formatDateAndTime(voucher.validUntil)}
        </List.Item>

        {voucher.visibleValidityFrom && (
          <List.Item>
            <List.Header>Käyttäjälle näkyvä varauksen kesto</List.Header>
            {formatDateAndTime(voucher.visibleValidityFrom)} -{' '}
            {formatDateAndTime(voucher.visibleValidityUntil)}
          </List.Item>
        )}
        <List.Item>
          <List.Header>Ajoneuvo</List.Header>
          {voucher.licensePlateNumber}
        </List.Item>
      </>
    )}

    {voucher.categoryType === voucherEnums.categoryTypes.BENEFIT && (
      <>
        <List.Item>
          <List.Header>Kelpoisuusaika</List.Header>
          {formatDateAndTime(voucher.validFrom)} -{' '}
          {formatDateAndTime(voucher.validUntil)}
        </List.Item>
        <List.Item>
          <List.Header>Etu</List.Header>
          {voucherEnums.benefitTypes.stringValues[voucher.benefitType]}
        </List.Item>
      </>
    )}

    {voucher.categoryType === voucherEnums.categoryTypes.BENEFIT &&
      voucher.benefitType === voucherEnums.benefitTypes.MONEY && (
        <List.Item>
          <List.Header>Määrä</List.Header>
          {formatPrice(voucher.amount)}
        </List.Item>
      )}

    {voucher.categoryType === voucherEnums.categoryTypes.BENEFIT &&
      voucher.benefitType === voucherEnums.benefitTypes.TIME && (
        <List.Item>
          <List.Header>Kesto</List.Header>
          {voucher.grantedDurationInMinutes} min
        </List.Item>
      )}

    {(voucherInfo.grantedByCustomName !== undefined && (
      <List.Item>
        <List.Header>Myöntäjä</List.Header>
        {voucherInfo.grantedByCustomName}
      </List.Item>
    )) ||
      ((voucherInfo.grantedBy !== undefined ||
        voucherInfo.grantedByOperator !== undefined) && (
        <List.Item>
          <List.Header>Myöntäjä</List.Header>
          {voucherInfo.grantedByOperator ? (
            <RelativeLinkForCurrentUser
              to={`operators/${voucherInfo.grantedByOperator.ref}`}
            >
              {voucherInfo.grantedByOperator.name}
            </RelativeLinkForCurrentUser>
          ) : (
            <RelativeLinkForCurrentUser
              to={`customers/${voucherInfo.grantedBy.ref}`}
            >
              {voucherInfo.grantedBy.name}
            </RelativeLinkForCurrentUser>
          )}
        </List.Item>
      ))}

    <List.Item>
      <List.Header>Tyyppi</List.Header>
      {voucherEnums.voucherTypes.stringValues[voucher.voucherType]}
    </List.Item>
    {voucherInfo.validLocationDescription && (
      <List.Item>
        <List.Header>Käyttöpaikka</List.Header>
        {voucherInfo.validLocationDescription}
      </List.Item>
    )}
  </List>
)

VoucherFields.propTypes = {
  voucher: PropTypes.shape(voucherShape),
  voucherInfo: PropTypes.shape(voucherInfoShape)
}

export default VoucherFields
