import './ServiceBreakInfo.scss'

import React, { useEffect, useState } from 'react'
import { Message } from 'semantic-ui-react'

import { useQuery } from '@tanstack/react-query'
import serviceBreakService from '../../services/ServiceBreak/ServiceBreakService'

const ServiceBreakInfo = () => {
  const [visible, setVisible] = useState(false)
  const query = useQuery({
    queryKey: ['serviceBreakQuery'],
    queryFn: () => serviceBreakService.fetchServiceBreakMessage(),
    retry: false,
    refetchOnWindowFocus: false
  })
  useEffect(() => {
    if (query.data && query.data.showMessage) {
      setVisible(true)
    }
  }, [query.data])

  if (!visible || !query.data) {
    return null
  }

  return (
    <div className="infoMsg">
      <Message color="red" onDismiss={() => setVisible(false)}>
        {query.data.fi || query.data.default}
      </Message>
    </div>
  )
}

export default ServiceBreakInfo
