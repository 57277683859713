import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import React from 'react'

const LicensePlateRecognitionIcon = ({ cameraRecognitionAllowed }) => {
  return (
    <>
      {cameraRecognitionAllowed ? (
        <>
          <FontAwesomeIcon icon="cctv" color="green" />{' '}
          <FormattedMessage id="common.vehicles.licensePlateRecognitionActive" />
        </>
      ) : (
        <>
          <FontAwesomeIcon icon="cctv" color="red" />{' '}
          <FormattedMessage id="common.vehicles.licensePlateRecognitionInActive" />
        </>
      )}
    </>
  )
}

export default LicensePlateRecognitionIcon
