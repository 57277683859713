import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Button, Form, List, Message, Modal } from 'semantic-ui-react'

import useAuthInfo from '../../hooks/useAuthInfo'
import parkingService from '../../services/Parking'
import { formatDateAndTime, PermissionWebPayment } from '../../services/utils'
import {
  getStringValue,
  webpayment as webpaymentEnums
} from '../../services/utils/DTOEnums'
import QueryContainer from '../QueryContainer'
import { MutationErrorMessage } from '..'
import useWebPaymentCancelForm from '../../hooks/useWebPaymentCancelForm'

const ModalWebPaymentSaleStatus = ({ licensePlate, sale, onClose }) => {
  const [showCancellationForm, setShowCancellationForm] = useState(false)
  const [saleCancelledSuccesfully, setSaleCancelledSuccesfully] =
    useState(false)
  const { operatorRealm, roles } = useAuthInfo()
  const queryClient = useQueryClient()
  const queryKey = 'saleWebPaymentData'
  const hasEditRole = PermissionWebPayment.HasWebPaymentEdit(roles)

  const webPaymentData = useQuery({
    queryKey: [queryKey, sale.ref],
    queryFn: () => parkingService.fetchSaleWebPayment(sale.ref, operatorRealm),
    enabled: Boolean(sale && hasEditRole)
  })

  const { mutate: cancelWebpayment, ...cancelWebpaymentMutation } = useMutation(
    {
      mutationFn: (reason) =>
        parkingService.cancelSaleWebPayment(sale.ref, reason, operatorRealm),
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [queryKey, sale.ref] })
        setSaleCancelledSuccesfully(true)
        setShowCancellationForm(false)
      }
    }
  )

  if (!hasEditRole) {
    return null
  }

  const webpaymentFound = webPaymentData.data && webPaymentData.data.ref

  const canBeCancelled =
    hasEditRole &&
    webpaymentFound &&
    [
      webpaymentEnums.webpaymentStatus.WEBPAYMENT_PENDING,
      webpaymentEnums.webpaymentStatus.WEBPAYMENT_FAILED
    ].includes(webPaymentData.data.status)

  return (
    <Modal open onClose={onClose} size="mini">
      <Modal.Header>
        <FormattedMessage id="modalWebPaymentSaleStatus.title" />
      </Modal.Header>
      <Modal.Content>
        {webpaymentFound ? (
          <QueryContainer query={webPaymentData}>
            <List>
              <List.Item>
                <List.Header>
                  <FormattedMessage id="modalWebPaymentSaleStatus.header.status" />
                </List.Header>
                {webPaymentData.data && (
                  <FormattedMessage
                    id={getStringValue(
                      webpaymentEnums.webpaymentStatus.localizationKeys,
                      webpaymentEnums.webpaymentStatus.localizationKeys[
                        webPaymentData.data.status
                      ]
                    )}
                    defaultMessage={webPaymentData.data.status}
                  />
                )}
              </List.Item>
              {webPaymentData.data?.cancellationReason && (
                <List.Item>
                  <List.Header>
                    <FormattedMessage id="modalWebPaymentSaleStatus.header.cancellationReason" />
                  </List.Header>
                  {webPaymentData.data.cancellationReason}
                </List.Item>
              )}

              <List.Item />
              <List.Item>
                <List.Header>
                  <FormattedMessage id="modalWebPaymentSaleStatus.header.sentToService" />
                </List.Header>
                {webPaymentData.data &&
                webPaymentData.data.webpaymentSendTime ? (
                  formatDateAndTime(webPaymentData.data.webpaymentSendTime)
                ) : (
                  <FormattedMessage id="modalWebPaymentSaleStatus.item.notSent" />
                )}
              </List.Item>
              <List.Item>
                <List.Header>
                  <FormattedMessage id="modalWebPaymentSaleStatus.header.handledInService" />
                </List.Header>
                {webPaymentData.data &&
                webPaymentData.data.webpaymentResolveTime ? (
                  formatDateAndTime(webPaymentData.data.webpaymentResolveTime)
                ) : (
                  <FormattedMessage id="modalWebPaymentSaleStatus.item.notProcessed" />
                )}
              </List.Item>
              <List.Item>
                <List.Header>
                  <FormattedMessage id="common.vehicles.licensePlateNumber" />
                </List.Header>
                {licensePlate}
              </List.Item>

              {sale.invoice && (
                <List.Item>
                  <List.Header>
                    <FormattedMessage id="modalWebPaymentSaleStatus.header.invoiceNumber" />
                  </List.Header>
                  {sale.invoice?.invoiceNumber}
                </List.Item>
              )}
            </List>
          </QueryContainer>
        ) : (
          <Message info>
            <FormattedMessage id="modalWebPaymentSaleStatus.notFound" />
          </Message>
        )}
        {showCancellationForm && (
          <WebPaymentCancellationForm
            initialLicensePlate={licensePlate}
            loading={cancelWebpaymentMutation.isPending}
            disabled={cancelWebpaymentMutation.isPending}
            onSubmit={cancelWebpayment}
          />
        )}
        {canBeCancelled && !showCancellationForm && (
          <Button
            style={{ marginTop: '1rem' }}
            negative
            loading={cancelWebpaymentMutation.isPending}
            disabled={cancelWebpaymentMutation.isPending}
            onClick={() => setShowCancellationForm(true)}
          >
            <FormattedMessage id="modalWebPaymentSaleStatus.button.cancelPayment" />
          </Button>
        )}
        {saleCancelledSuccesfully ? (
          <Message positive>
            <FormattedMessage id="modalWebPaymentSaleStatus.operation.cancel.successfull" />
          </Message>
        ) : (
          ''
        )}
        <MutationErrorMessage
          mutation={cancelWebpaymentMutation}
          defaultErrorTextLangId="modalWebPaymentSaleStatus.operation.cancel.failed"
          messageNegative
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => onClose(saleCancelledSuccesfully)}
          disabled={cancelWebpaymentMutation.isPending}
        >
          <FormattedMessage id="common.button.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const WebPaymentCancellationForm = ({
  initialLicensePlate,
  onSubmit,
  loading,
  disabled
}) => {
  const intl = useIntl()
  const {
    comment,
    setComment,
    licensePlate,
    setLicensePlate,
    hasErrors,
    errors,
    reason
  } = useWebPaymentCancelForm(initialLicensePlate)

  const submitHandler = (e) => {
    e.preventDefault()
    onSubmit(reason)
  }

  return (
    <Form style={{ marginTop: '10px' }}>
      <Form.Input
        required
        label={intl.formatMessage({
          id: 'common.vehicles.licensePlateNumber'
        })}
        value={licensePlate}
        onChange={(e) => setLicensePlate(e.target.value)}
        error={errors.licensePlate}
      />
      <Form.TextArea
        autoFocus
        required
        label={intl.formatMessage({
          id: 'modalWebPaymentSaleStatus.cancelPayment.comment'
        })}
        value={comment}
        error={errors.comment}
        onChange={(e) => setComment(e.target.value)}
      />
      <Button
        style={{ marginTop: '1rem' }}
        negative
        loading={loading}
        disabled={disabled || hasErrors}
        onClick={submitHandler}
      >
        <FormattedMessage id="modalWebPaymentSaleStatus.button.cancelPayment" />
      </Button>
    </Form>
  )
}
export default ModalWebPaymentSaleStatus
