import { FormattedMessage, useIntl } from 'react-intl'
import { ModalStartParking, MoovyButton } from '../../../../components'
import React, { useState } from 'react'
import {
  tablePaginationDirections as directions,
  parkingTypeEnum
} from '../../../../services/utils'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'

import { DateTime } from 'luxon'
import { Icon } from 'semantic-ui-react'
import { ListParkingsForCustomer } from '../ListParkings'
import ParkingFilters from '../../../../components/ParkingDataTable/ParkingDataTableFilters'
import ParkingModal from '../../../../containers/ParkingModal'
import locationService from '../../../../services/Locations'
import moovyDateTime from '../../../../services/utils/moovyDateTime'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import useAuthInfo from '../../../../hooks/useAuthInfo'
import userService from '../../../../services/User/UserServices'

const queryDefaults = {
  begin: DateTime.local().setLocale('fi').startOf('month'),
  end: DateTime.local().setLocale('fi').endOf('day'),
  includeStatuses: 'ALL',
  parkingMode: 'ALL',
  parkingType: 'MOOVY_PARKING',
  page: 0,
  size: 50,
  sort: 'start',
  direction: directions.DESC
}

const Parkings = ({ customer, noActivePayment }) => {
  const [query, setQuery] = useState(queryDefaults)
  const [selectedParkingRef, setSelectedParkingRef] = useState(null)
  const [initiallySelectedParkingRef, setInitiallySelectedParkingRef] =
    useState(null)
  const [showStartParking, setShowStartParking] = useState(false)
  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const intl = useIntl()
  const queryClient = useQueryClient()

  const queryKey = [
    'parking',
    customer?.ref,
    {
      ...query,
      begin: query.begin.toISO(),
      end: query.end.toISO()
    }
  ]

  const parkings = useQuery({
    queryKey,
    queryFn: () => {
      const begin = moovyDateTime.dateTimeToBackendFormat(query.begin)
      const end = moovyDateTime.dateTimeToBackendFormat(query.end)
      return userService.fetchParkings(customer.ref, operatorRealm, {
        ...query,
        begin,
        end,
        page: query.page - 1
      })
    },
    enabled: !!customer
  })

  const { mutate: startParking, ...startParkingMutation } = useMutation({
    mutationFn: (values) =>
      locationService.startParking(
        {
          ...values,
          userRef: customer.ref
        },
        operatorRealm
      ),
    onSuccess: (data, { licensePlateNumber }) => {
      setShowStartParking(false)

      let titleLocId = 'modalStartParking.toast.success.title.moovyParking'
      let bodyLocId = 'modalStartParking.toast.success.body.moovyParking'

      if (data?.type !== parkingTypeEnum.MOOVY_PARKING) {
        titleLocId = 'modalStartParking.toast.success.title.anonymousParking'
        bodyLocId =
          'modalStartParking.toast.success.body.anonymousParkingWithLocation'
      }

      showLocalizedMoovyToast(intl, {
        title: titleLocId,
        description: bodyLocId,
        values: {
          licensePlate: licensePlateNumber,
          location:
            data?.type !== parkingTypeEnum.MOOVY_PARKING
              ? data?.location?.name
              : undefined
        },
        time: data?.type !== parkingTypeEnum.MOOVY_PARKING ? 10000 : undefined
      })
      queryClient.invalidateQueries({
        queryKey
      })
    }
  })

  return (
    <>
      <ParkingFilters values={query} setValues={setQuery} />
      <ListParkingsForCustomer
        query={query}
        parkings={parkings}
        initiallySelectedParkingRef={initiallySelectedParkingRef}
        onClickParking={(parking) => {
          setSelectedParkingRef(parking.ref)
          setInitiallySelectedParkingRef(parking.ref)
        }}
        setQuery={setQuery}
        onPageChange={(e, { activePage }) =>
          setQuery({ ...query, page: activePage })
        }
        headerButtons={
          <>
            <MoovyButton onClick={() => setShowStartParking(true)}>
              <Icon name="user" />
              <FormattedMessage id="parkingTab.button.startParking" />
            </MoovyButton>
            <ModalStartParking
              modalType={parkingTypeEnum.MOOVY_PARKING}
              open={showStartParking}
              customer={customer}
              userHasActivePayment={!noActivePayment}
              mutation={startParkingMutation}
              onClose={() => setShowStartParking(false)}
              onStartParking={startParking}
            />
          </>
        }
      />
      <ParkingModal
        parkingRef={selectedParkingRef}
        onClose={() => {
          setInitiallySelectedParkingRef(undefined)
          setSelectedParkingRef(undefined)
        }}
        onClickParkingSequence={(ref) => setSelectedParkingRef(ref)}
        isSuperOperator={isSuperOperator}
        whenParkingUpdated={() =>
          queryClient.invalidateQueries({
            queryKey
          })
        }
      />
    </>
  )
}

export default Parkings
