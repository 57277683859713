import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Button, Header } from 'semantic-ui-react'

import { MutationErrorMessage } from '../../../../components'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import {
  getStringValue,
  adyenAccountHolderMainStatusEnum as mainStatusEnum
} from '../../../../services/utils/DTOEnums'
import { accountHolderUIStatusEnum } from './tabAdyen'

const StepAccountHolder = ({
  settlementRecipient,
  queryAccountHolder,
  accountHolder,
  accountHolderUIStatus,
  refetchAccountHolder,
  onNextClick,
  nextDisabled
}) => {
  const intl = useIntl()

  const { mutate: addAccountHolder, ...addAccountHolderMutation } = useMutation(
    {
      mutationFn: () => {
        return settlementRecipientService.addAccountHolder(
          settlementRecipient?.ref
        )
      },
      onSuccess: () => {
        showLocalizedMoovyToast(intl, {
          title: 'stepAccountHolder.toast.action.accountHolderCreated.title',
          description:
            'stepAccountHolder.toast.action.accountHolderCreated.description'
        })
        onNextClick()
        refetchAccountHolder()
      }
    }
  )

  const RenderShowOrCreateAccountHolder = () => {
    if (accountHolderUIStatus !== accountHolderUIStatusEnum.NONE) {
      return (
        <>
          <div>
            <b>
              <FormattedMessage id="stepAccountHolder.label.mainStatus" />{' '}
            </b>
            {accountHolder.mainStatus && (
              <FormattedMessage
                id={getStringValue(
                  mainStatusEnum.localizationKeys,
                  accountHolder.mainStatus
                )}
                defaultMessage={accountHolder.mainStatus}
              />
            )}
          </div>
          <div>
            <b>
              <FormattedMessage id="stepAccountHolder.label.accountHolderCode" />
            </b>
            {` ${accountHolder.accountHolderCode}`}
          </div>
        </>
      )
    } else {
      return (
        <>
          <p>
            <FormattedMessage id="stepAccountHolder.description.accountHolder" />
          </p>
          <Button
            primary
            onClick={() => addAccountHolder()}
            disabled={addAccountHolderMutation.isPending || nextDisabled}
            loading={addAccountHolderMutation.isPending}
          >
            <FormattedMessage id="stepAccountHolder.button.createAndContinue" />
          </Button>
        </>
      )
    }
  }

  return (
    <>
      <Header as="h4">
        <FormattedMessage id="stepAccountHolder.header.accountHolder" />
      </Header>
      <MutationErrorMessage
        mutation={addAccountHolderMutation}
        defaultErrorTextLangId={'stepAccountHolder.accountHolderCreated.failed'}
        messageNegative
      />
      <MutationErrorMessage
        mutation={queryAccountHolder}
        defaultErrorTextLangId={'stepOnboardingLink.fetchAccountHolder.failed'}
        messageNegative
      />
      <RenderShowOrCreateAccountHolder />
    </>
  )
}

export default StepAccountHolder
