import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'

import { LocalizedServiceType } from '../../components/MoovyLocalizedEnum'
import LocationTable from './LocationTable'
import ModalEditCapacities from './ModalEditCapacities'
import ModalEditName from './ModalEditName'
import ModalEditOverBooking from './ModalEditOverBooking'
import ModalEditVisibility from './ModalEditVisibility'
import ParkingServiceDescriptionTab from './ParkingServiceDescriptionTab'
import ParkingServiceValidityTimesTab from './ParkingServiceValidityTimesTab'
import PayAsYouGoServiceToolbar from './ServiceToolbar/PayAsYouGoServiceToolbar'
import { PermissionService } from '../../services/utils'
import PricingTable from './PricingTable'
import SubscribedServicesTable from './SubscribedServicesTable'
import SubscriptionServiceToolbar from './ServiceToolbar/SubscriptionServiceToolbar'
import { Tab } from 'semantic-ui-react'
import { Toolbar } from '../../components'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import { serviceType as parkingType } from '../../services/utils/DTOEnums'
import servicesService from '../../services/Services'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useIntl } from 'react-intl'

const ParkingServiceDetails = () => {
  const { operatorRealm, isSuperOperator, roles } = useAuthInfo()
  const navigate = useNavigate()
  const intl = useIntl()

  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [editCapacitiesModal, setEditCapacitiesModal] = useState(false)
  const [editOverBookingModal, setEditOverBookingModal] = useState(false)
  const [editVisibilityModal, setEditVisibilityModal] = useState(false)
  const [editNameModal, setEditNameModal] = useState(false)

  const { serviceRef } = useParams()

  const serviceQuery = useQuery({
    queryKey: ['service', serviceRef],
    queryFn: () => servicesService.fetchService(serviceRef, operatorRealm),
    refetchOnWindowFocus: false
  })
  const parkingService = serviceQuery.data ? serviceQuery.data : {}

  const refresh = () => {
    serviceQuery.refetch()
  }

  const { mutate: updateOverBooking, ...updateOverBookingMutation } =
    useMutation({
      mutationFn: (updateData) => {
        return servicesService.updateOverBooking(
          updateData.serviceRef,
          updateData.userAssignmentOverbookingCoefficient
        )
      },
      onSuccess: (response) => {
        setEditOverBookingModal(false)
        refresh()
        showLocalizedMoovyToast(intl, {
          title: 'modalEditOverBooking.toast.title',
          description: 'modalEditOverBooking.toast.body',
          values: {
            name: response.name
          }
        })
      }
    })

  const { mutate: updateVisibility, ...updateVisibilityMutation } = useMutation(
    {
      mutationFn: (updateData) => {
        return servicesService.updateVisibility(
          updateData.serviceRef,
          updateData.visibility
        )
      },
      onSuccess: (response) => {
        setEditVisibilityModal(false)
        refresh()
        showLocalizedMoovyToast(intl, {
          title: 'modalEditVisibility.toast.title',
          description: 'modalEditVisibility.toast.body',
          values: {
            name: response.name
          }
        })
      }
    }
  )

  const { mutate: updateName, ...updateNameMutation } = useMutation({
    mutationFn: (updateData) => {
      return servicesService.updateName(updateData.serviceRef, updateData.name)
    },
    onSuccess: (response) => {
      setEditNameModal(false)
      refresh()
      showLocalizedMoovyToast(intl, {
        title: 'modalEditName.toast.title',
        description: 'modalEditName.toast.body',
        values: {
          name: response.name
        }
      })
    }
  })

  const {
    mutate: updateServiceCapacities,
    ...updateServiceCapacitiesMutation
  } = useMutation({
    mutationFn: (updateData) =>
      servicesService
        .updateServiceCapacities(
          operatorRealm,
          updateData.subscriptionServiceRef,
          updateData
        )
        .then((response) => {
          // exceedingParkingCapacityAllowed flag is only updated to the backend in case of
          // it has been changed on UI and user is not a operator user.
          if (
            updateData.operatorRealm ||
            updateData.exceedingParkingCapacityAllowed === undefined
          ) {
            return Promise.resolve(response)
          } else {
            return servicesService.updateExceedingParkingCapacity(
              operatorRealm,
              updateData.subscriptionServiceRef,
              {
                exceedingParkingCapacityAllowed:
                  updateData.exceedingParkingCapacityAllowed
              }
            )
          }
        }),
    onSuccess: (response) => {
      setEditCapacitiesModal(false)
      refresh()

      showLocalizedMoovyToast(intl, {
        title: 'modalEditCapacities.toast.title',
        description: 'modalEditCapacities.toast.body',
        values: {
          name: response.name
        }
      })
    }
  })

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.parkingServices'
      }),
      href: '/services'
    },
    {
      text: <LocalizedServiceType value={parkingService.type} />,
      href: '/services'
    },
    {
      text: (parkingService && parkingService.name) || '',
      active: true
    }
  ])

  const onClickTariff = (tariffRef) => {
    navigate(
      `/${
        !operatorRealm ? 'admin' : 'operator'
      }/services/${serviceRef}/tariff/${tariffRef}`
    )
  }

  let toolbarButtons = []
  if (parkingService) {
    if (PermissionService.HasEditCapacities(isSuperOperator, roles)) {
      toolbarButtons.push({
        icon: 'setting',
        tooltip: intl.formatMessage({
          id: 'parkingServiceDetails.toolbar.button.editCapacity.tooltip'
        }),
        action: () => setEditCapacitiesModal(true)
      })
    }
    if (PermissionService.HasEditName(isSuperOperator, roles)) {
      toolbarButtons.push({
        icon: 'edit',
        tooltip: intl.formatMessage({
          id: 'parkingServiceDetails.toolbar.button.editName.tooltip'
        }),
        action: () => setEditNameModal(true)
      })
    }
  }

  const panes = serviceRef
    ? [
        {
          menuItem: intl.formatMessage({
            id: 'parkingServiceDetails.tab.pricing.title'
          }),
          key: 'pricing',
          render: () => (
            <PricingTable
              serviceRef={serviceRef}
              operatorRealm={operatorRealm}
              onClickTariff={onClickTariff}
            />
          )
        },
        {
          menuItem: intl.formatMessage({
            id: 'parkingServiceDetails.tab.locations.title'
          }),
          key: 'locations',
          render: () => {
            return (
              parkingService && (
                <LocationTable
                  serviceNameRef={
                    parkingService.type !== parkingType.PAY_AS_YOU_GO && {
                      name: parkingService.name,
                      ref: parkingService.ref
                    }
                  }
                  locations={parkingService.locations}
                  refresh={refresh}
                />
              )
            )
          }
        }
      ]
    : null

  if (parkingService && parkingService.type === parkingType.SUBSCRIPTION) {
    panes.unshift({
      menuItem: intl.formatMessage({
        id: 'parkingServiceDetails.tab.subscribedServices.title'
      }),
      key: 'subscribedServices',
      render: () => (
        <SubscribedServicesTable
          subscriptionServiceRef={serviceRef}
          operatorRealm={operatorRealm}
        />
      )
    })
    panes.push({
      menuItem: intl.formatMessage({
        id: 'parkingServiceDetails.tab.description.title'
      }),
      key: 'description',
      render: () => (
        <ParkingServiceDescriptionTab
          parkingService={parkingService}
          refresh={refresh}
        />
      )
    })
    panes.push({
      menuItem: intl.formatMessage({
        id: 'parkingServiceDetails.tab.validityTimes.title'
      }),
      key: 'validityTimes',
      render: () => (
        <ParkingServiceValidityTimesTab
          serviceRef={serviceRef}
          parkingService={parkingService}
          refresh={refresh}
        />
      )
    })
  }

  const showDescription = () => {
    setActiveTabIndex(panes.findIndex((item) => item.key === 'description'))
  }

  return (
    <>
      <Toolbar
        breadcrumbs={breadcrumbs}
        title={(parkingService && parkingService.name) || ''}
        buttons={toolbarButtons}
        content={
          (parkingService &&
            parkingService.type === parkingType.SUBSCRIPTION && (
              <SubscriptionServiceToolbar
                subscriptionService={parkingService}
                onShowDescription={showDescription}
                onEditOverBooking={() => setEditOverBookingModal(true)}
                onEditVisibility={() => setEditVisibilityModal(true)}
              />
            )) || <PayAsYouGoServiceToolbar paygService={parkingService} />
        }
      />
      <div className="Admin--Page--Content">
        <Tab
          panes={panes}
          activeIndex={activeTabIndex}
          onTabChange={(e, { activeIndex }) => {
            setActiveTabIndex(activeIndex)
          }}
        />
      </div>
      {parkingService && (
        <>
          {editCapacitiesModal ? (
            <ModalEditCapacities
              onClose={() => {
                updateServiceCapacitiesMutation.reset()
                setEditCapacitiesModal(false)
              }}
              parkingService={parkingService}
              onSubmit={updateServiceCapacities}
              mutation={updateServiceCapacitiesMutation}
            />
          ) : (
            ''
          )}
          {editOverBookingModal ? (
            <ModalEditOverBooking
              onClose={() => {
                updateOverBookingMutation.reset()
                setEditOverBookingModal(false)
              }}
              service={parkingService}
              onSubmit={updateOverBooking}
              mutation={updateOverBookingMutation}
            />
          ) : (
            ''
          )}
          {editVisibilityModal ? (
            <ModalEditVisibility
              onClose={() => {
                updateVisibilityMutation.reset()
                setEditVisibilityModal(false)
              }}
              service={parkingService}
              onSubmit={updateVisibility}
              mutation={updateVisibilityMutation}
            />
          ) : (
            ''
          )}
          {editNameModal ? (
            <ModalEditName
              onClose={() => {
                updateNameMutation.reset()
                setEditNameModal(false)
              }}
              service={parkingService}
              onSubmit={updateName}
              mutation={updateNameMutation}
            />
          ) : (
            ''
          )}
        </>
      )}
    </>
  )
}

export default ParkingServiceDetails
