import { Grid, Header } from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import ParkingNowTable from './parkingNowTable'
import React from 'react'

const ParkingNowContent = ({
  parkingsQuery,
  initiallySelectedParkingRef,
  headerButtons,
  showParkingExceededColumns,
  activePage,
  totalPages,
  onPageChange,
  onClickParking,
  onRefresh
}) => {
  const total = parkingsQuery?.data?.pageable?.total || 0
  return (
    <>
      <Grid>
        <Grid.Column floated="left" width={8} verticalAlign="bottom">
          <Header size="small">
            <FormattedMessage
              id="parkingNowContent.ongoingParking"
              values={{ pcs: total }}
            />
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          {headerButtons}
        </Grid.Column>
      </Grid>
      <ParkingNowTable
        parkingQuery={parkingsQuery}
        initiallySelectedParkingRef={initiallySelectedParkingRef}
        activePage={activePage}
        totalPages={totalPages}
        showParkingExceededColumns={showParkingExceededColumns}
        onPageChange={onPageChange}
        onClickParking={onClickParking}
        onRefresh={onRefresh}
      />
    </>
  )
}

export default ParkingNowContent
