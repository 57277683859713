import { sortColumn, tablePaginationDirections } from '../../services/utils'

import ChargingSessionToolbar from './chargingSessionToolbar'
import ChargingSessionsContent from './chargingSessionsContent'
import React from 'react'
import electricVehicleCharging from '../../services/ElectricVehicleCharging'
import { getTablePaginationInitialValues } from '../../services/utils/tablePagination'
import moovyDateTime from '../../services/utils/moovyDateTime'
import useAuthInfo from '../../hooks/useAuthInfo'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useQuery } from '@tanstack/react-query'

const Chargings = () => {
  const { isSuperOperator } = useAuthInfo()

  const pageLimit = 20

  const [state, setState] = useGlobalUI({
    key: 'chargingsList',
    initialValue: {
      pluginId: '',
      chargingStatus: '',
      chargerType: '',
      customerRef: '',
      locationRef: '',
      startDate: '',
      endDate: '',
      tableFilterRange: '',
      tableCustomStartTime: '',
      tableCustomEndTime: '',
      ongoingOnly: true,
      ...getTablePaginationInitialValues('sortByStartTime')
    }
  })

  const sessionsQuery = useQuery({
    queryKey: [
      'fetchAllChargings',
      state.pluginId,
      state.chargingStatus,
      state.chargerType,
      state.customerRef,
      state.locationRef,
      state.startDate,
      state.endDate,
      state.page,
      state.sortColumn,
      state.direction,
      state.activePage,
      state.ongoingOnly
    ],
    queryFn: () => {
      const queryParams = {
        page: state.activePage - 1,
        limit: pageLimit,
        sort: state.sortColumn,
        direction: state.direction,
        includeHistory: false,
        plugIdentifier: state.pluginId,
        chargingStatus: state.pluginId ? undefined : state.chargingStatus,
        chargerType: state.pluginId ? undefined : state.chargerType,
        customerRef: state.pluginId ? undefined : state.customerRef,
        locationRef: state.pluginId ? undefined : state.locationRef,
        ongoingOnly: state.pluginId ? undefined : state.ongoingOnly,
        startDate: moovyDateTime.calendarDateInputToBeginOfDayISO(
          state.startDate
        ),
        endDate: moovyDateTime.calendarDateInputToEndOfDayISO(state.endDate)
      }
      return electricVehicleCharging.fetchAllChargingsByFilter(queryParams)
    },

    staleTime: 1000 * 60,
    retry: 0,
    enabled: Boolean(
      (isSuperOperator && state.pluginId.length === 0) ||
        state.pluginId.length > 3
    )
  })

  const sessions =
    (sessionsQuery.data &&
      sessionsQuery.data.data &&
      sessionsQuery.data.data) ||
    []

  const onPageChange = (e, { activePage }) => {
    setState({
      ...state,
      activePage
    })
  }

  const setSort = (column, direction) => {
    setState({ ...state, sortColumn: column, direction })
  }

  const totalPages = (sessionsQuery.data && sessionsQuery.data.totalPages) || 0

  const onChangeFilter = (name, value) => {
    if (name === 'dateRange') {
      const newValues = {
        ...state,
        page: 0,
        startDate: value.startDate,
        endDate: value.endDate,
        tableFilterRange: value.tableFilterRange,
        tableCustomStartTime: moovyDateTime.jsDateToISOString(
          value.tableCustomStartTime
        ),
        tableCustomEndTime: moovyDateTime.jsDateToISOString(
          value.tableCustomEndTime
        )
      }
      setState({
        ...newValues
      })
    } else {
      const newValues = { ...state, page: 0, [name]: value }
      setState({
        ...newValues
      })
    }
  }

  return (
    <>
      <ChargingSessionToolbar state={state} onChangeFilter={onChangeFilter} />
      <div className="Admin--Page--Content">
        <ChargingSessionsContent
          query={sessionsQuery}
          sessions={sessions}
          onPageChange={onPageChange}
          totalPages={totalPages}
          activePage={state.activePage}
          sortColumn={state.sortColumn}
          sortDirection={
            tablePaginationDirections.stringValues[state.direction]
          }
          onSort={(clickedColumn) =>
            sortColumn(
              clickedColumn,
              { column: state.sortColumn, direction: state.direction },
              setSort
            )
          }
          refresh={() => sessionsQuery.refetch()}
        />
      </div>
    </>
  )
}

export default Chargings
