import { get, post } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}${relativePath}`
    : `/administration${relativePath}`

const cancelSubscription = async (ref, endTime, operatorRealm) => {
  try {
    const result = await post(
      createFullPath(`/subscription/${ref}/end`, operatorRealm),
      { endTime }
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const restartSubscription = async (ref, operatorRealm) => {
  try {
    const result = await post(
      createFullPath(`/subscription/${ref}/restart`, operatorRealm)
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

export default {
  fetchSubscriptionsForSubscribedService: async (
    subscribedServiceRef,
    operatorRealm
  ) => {
    const url = operatorRealm
      ? `/operator/${operatorRealm}/subscribedServices/${subscribedServiceRef}/subscriptions?includeHistory=true`
      : `/administration/subscribedServices/${subscribedServiceRef}/subscriptions?includeHistory=true`

    try {
      const result = await get(url)
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  },
  cancelSubscription,
  restartSubscription
}
