import { Formik } from 'formik'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Form, Modal } from 'semantic-ui-react'
import * as yup from 'yup'

import { MutationErrorMessage } from '../../components'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useOperators from '../../hooks/useOperators'
import locationsService from '../../services/Locations'
import servicesService from '../../services/Services'

const ModalAddLocation = ({ serviceNameRef, open, onClose, onAdded }) => {
  const intl = useIntl()
  const operators = useOperators()
  const [selectedOperatorRef, setSelectedOperatorRef] = useState('')
  const operatorsData =
    operators.data != null && operators.data.content != null
      ? operators.data.content
      : []

  const getOperatorOptions = () => {
    return operatorsData.length > 0
      ? operators.data.content.map((item) => ({
          key: item.ref,
          text: item.name,
          value: item.ref
        }))
      : []
  }

  const locationsQuery = useQuery({
    queryKey: ['fetchOperatorLocations', selectedOperatorRef],
    queryFn: () =>
      locationsService.fetchLocationsForOperator(selectedOperatorRef),
    enabled: !!selectedOperatorRef
  })

  const locations = locationsQuery?.data?.content || []
  const getLocationOptions = () => {
    return locations.length > 0
      ? locations.map((item) => ({
          key: item.ref,
          text: item.name,
          value: item.ref
        }))
      : []
  }

  const validationSchema = () => {
    return yup.object().shape({
      operatorRef: yup.string().required('Cannot be empty'),
      locationRef: yup.string().required('Cannot be empty')
    })
  }

  const { mutate: addLocation, ...addLocationMutation } = useMutation({
    mutationFn: (submitContent) =>
      servicesService.addLocationToService(
        serviceNameRef.ref,
        submitContent.values.locationRef
      ),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'modalAddLocation.toast.title.locationAdded',
        description: 'modalAddLocation.toast.description.locationAdded'
      })
      const { resetForm } = variables
      resetForm({
        values: {
          targetGroupName: '',
          phoneNumbers: ''
        }
      })
      onAdded()
    }
  })

  const onSubmitForm = (values, { resetForm }) => {
    const submitContent = {
      values: { ...values },
      resetForm: resetForm
    }

    addLocation(submitContent)
  }

  const closeAndReset = (handleReset) => {
    addLocationMutation.reset()
    setSelectedOperatorRef('')
    handleReset()
    onClose()
  }

  return (
    <Formik
      initialValues={{
        operatorRef: '',
        locationRef: ''
      }}
      validationSchema={validationSchema()}
      onSubmit={onSubmitForm}
      enableReinitialize={true}
    >
      {({ values, errors, handleSubmit, setFieldValue, handleReset }) => (
        <Modal
          open={open}
          onClose={() => closeAndReset(handleReset)}
          size="tiny"
        >
          <Modal.Header>
            <FormattedMessage
              id="modalAddLocation.form.header"
              values={{
                name: serviceNameRef?.name ?? ''
              }}
            />
          </Modal.Header>
          <Modal.Content>
            <MutationErrorMessage
              mutation={addLocationMutation}
              defaultErrorTextLangId="modalAddLocation.operation.addLocation.failed"
              messageNegative
            />
            <Modal.Description style={{ marginBottom: '10px' }}>
              <p>
                <FormattedMessage
                  id="modalAddLocation.form.description"
                  values={{
                    name: serviceNameRef?.name ?? ''
                  }}
                />
              </p>
            </Modal.Description>
            <Form onSubmit={handleSubmit}>
              <Form.Dropdown
                name="operatorRef"
                label={intl.formatMessage({
                  id: 'modalAddLocation.form.label.operators'
                })}
                placeholder={intl.formatMessage({
                  id: 'modalAddLocation.form.option.operators.placeholder'
                })}
                options={getOperatorOptions()}
                fluid
                selection
                value={values.operatorRef}
                onChange={(e, value) => {
                  setSelectedOperatorRef(value.value)
                  setFieldValue(value.name, value.value)
                }}
                error={!!errors.operatorRef}
                selectOnBlur={false}
              />
              <Form.Dropdown
                name="locationRef"
                label={intl.formatMessage({
                  id: 'modalAddLocation.form.label.locations'
                })}
                placeholder={intl.formatMessage({
                  id: 'modalAddLocation.form.option.locations.placeholder'
                })}
                options={getLocationOptions()}
                fluid
                selection
                value={values.locationRef}
                onChange={(e, value) => {
                  setFieldValue(value.name, value.value)
                }}
                error={!!errors.locationRef}
                selectOnBlur={false}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                closeAndReset(handleReset)
              }}
            >
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              loading={addLocationMutation.isPending}
              disabled={addLocationMutation.isPending}
            >
              <FormattedMessage id="common.button.add" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalAddLocation
