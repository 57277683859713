import { FormattedMessage, useIntl } from 'react-intl'
import { List, Message, Tab } from 'semantic-ui-react'
import React, { useState } from 'react'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { ROLE_OPERATOR_USERGROUP_MANAGEMENT } from '../../services/utils/roles'
import ServiceAgreementsTab from './ServiceAgreementsTab'
import { Toolbar } from '../../components'
import UserGroupDeleteModal from './UserGroupDeleteModal'
import UserGroupRenameModal from './UserGroupRenameModal'
import UserGroupUsersTab from './UserGroupUsersTab'
import VoucherGrantAgreementsTab from './VoucherGrantAgreementsTab'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import useAuthInfo from '../../hooks/useAuthInfo'
import userGroupService from '../../services/UserGroups'

const UserGroup = () => {
  const [renameModalOpen, setRenameModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const { isSuperOperator, operatorRealm, roles } = useAuthInfo()
  const queryClient = useQueryClient()
  const params = useParams()
  const ref = params.userGroupRef
  const intl = useIntl()
  const navigate = useNavigate()

  const userGroupQuery = useQuery({
    queryKey: ['userGroup', ref],
    queryFn: () => userGroupService.fetchUserGroup(ref, operatorRealm)
  })

  const membershipsQuery = useQuery({
    queryKey: ['memberships', ref],
    queryFn: () =>
      userGroupService.fetchUserGroupMemberships(ref, operatorRealm),
    enabled: !!userGroupQuery.data
  })

  if (userGroupQuery.isLoading) return null

  if (userGroupQuery.isError)
    return (
      <div className="Admin--Page--Content">
        <Message error>
          <FormattedMessage id="userGroup.query.error" />
        </Message>
      </div>
    )

  const userGroup = userGroupQuery.data
  const userGroupMemberships = membershipsQuery.data
    ? membershipsQuery.data
    : []

  const breadcrumbs = createBreadcrumbs([
    { text: 'Koti', href: '/' },
    { text: 'Käyttäjäryhmät', href: '/usergroups' },
    { text: userGroup.name, active: true }
  ])

  const allowEdit =
    isSuperOperator || roles.includes(ROLE_OPERATOR_USERGROUP_MANAGEMENT)

  let toolbarButtons = [
    {
      icon: 'setting',
      tooltip: intl.formatMessage({ id: 'userGroup.toolbar.rename' }),
      action: () => setRenameModalOpen(true)
    }
  ]

  if (allowEdit) {
    toolbarButtons.push({
      icon: 'trash',
      tooltip: intl.formatMessage({ id: 'userGroup.toolbar.delete' }),
      action: () => setDeleteModalOpen(true)
    })
  }

  const getPanes = () => {
    return [
      {
        menuItem: intl.formatMessage({ id: 'userGroup.panes.user' }),
        render: () => (
          <UserGroupUsersTab
            userGroup={userGroup}
            userGroupMemberships={userGroupMemberships}
            refreshUserGroup={() =>
              queryClient.invalidateQueries({ queryKey: ['memberships', ref] })
            }
            loading={userGroupQuery.isLoading}
          />
        )
      },
      {
        menuItem: intl.formatMessage({
          id: 'userGroup.panes.serviceAgreements'
        }),
        render: () => (
          <ServiceAgreementsTab userGroup={userGroup} allowEdit={allowEdit} />
        )
      },
      {
        menuItem: intl.formatMessage({
          id: 'userGroup.panes.voucherGrantAgreements'
        }),
        render: () => (
          <VoucherGrantAgreementsTab
            userGroup={userGroup}
            allowEdit={allowEdit}
          />
        )
      }
    ]
  }

  return (
    <>
      <Toolbar
        breadcrumbs={breadcrumbs}
        title={userGroup.name}
        buttons={toolbarButtons}
        content={
          <List>
            <List.Item>
              <List.Header>
                <FormattedMessage id="userGroup.toolbar.ref" />
              </List.Header>
              {ref}
            </List.Item>
            <List.Item>
              <List.Header>
                <FormattedMessage id="userGroup.toolbar.operator" />
              </List.Header>
              {userGroup.operator
                ? userGroup.operator.name
                : intl.formatMessage({
                    id: 'userGroup.toolbar.operator.undefined'
                  })}
            </List.Item>
          </List>
        }
      />
      <div className="Admin--Page--Content">
        <Tab
          activeIndex={activeTabIndex}
          onTabChange={(e, { activeIndex }) => {
            setActiveTabIndex(activeIndex)
          }}
          panes={getPanes()}
        />
      </div>
      {renameModalOpen && (
        <UserGroupRenameModal
          userGroup={userGroup}
          onClose={() => setRenameModalOpen(false)}
          updateUserGroupCallback={() =>
            queryClient.invalidateQueries({ queryKey: ['userGroup', ref] })
          }
        />
      )}
      {deleteModalOpen && (
        <UserGroupDeleteModal
          userGroup={userGroup}
          userGroupMemberships={userGroupMemberships}
          onClose={() => setDeleteModalOpen(false)}
          onSubmit={() => {
            setDeleteModalOpen(false)
            navigate(`/${!operatorRealm ? 'admin' : 'operator'}/usergroups`)
          }}
        />
      )}
    </>
  )
}

export default UserGroup
