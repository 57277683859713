import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown, Header, Modal, Segment } from 'semantic-ui-react'

import GenericMessage, {
  createErrorMessage,
  createInfoMessage
} from '../../components/GenericMessage'
import UserGroupAgreements from '../../components/UserGroupAgreements'
import useAuthInfo from '../../hooks/useAuthInfo'
import useUserGroups from '../../hooks/useUserGroups'
import userGroupsService from '../../services/UserGroups'

const initialData = {
  isLoading: false,
  data: []
}

const AddUserToUserGroupModal = ({ userRef, memberOf, onClose, onAddUser }) => {
  const [selected, setSelected] = useState()
  const [serviceAgreements, setServiceAgreements] = useState(initialData)
  const [voucherGrants, setVoucherGrants] = useState(initialData)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState()

  const { operatorRealm } = useAuthInfo()

  const [userGroupsQuery] = useUserGroups(operatorRealm)

  const userGroups = userGroupsQuery.data ? userGroupsQuery.data : []
  useEffect(() => {
    if (selected) {
      setServiceAgreements({ ...initialData, isLoading: true })
      setVoucherGrants({ ...initialData, isLoading: true })
      userGroupsService
        .fetchServiceAgreements(selected, operatorRealm)
        .then((data) => setServiceAgreements({ ...initialData, data }))
        .catch(() => setServiceAgreements(initialData))
      userGroupsService
        .fetchVoucherGrantAgreements(selected, operatorRealm)
        .then((data) => setVoucherGrants({ ...initialData, data }))
        .catch(() => setVoucherGrants(initialData))
    }
  }, [selected])

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage()
    }, 3000)
    return () => clearTimeout(timer)
  }, [message])

  const addUser = () => {
    setIsSubmitting(true)
    userGroupsService
      .addUserToGroup(userRef, selected, operatorRealm)
      .then(() => {
        const group = userGroups.find((g) => g.ref === selected)
        onAddUser(group)
        setMessage(createInfoMessage(`Käyttäjä lisätty ryhmään ${group.name}`))
        setSelected()
        setServiceAgreements(initialData)
        setVoucherGrants(initialData)
      })
      .catch(() => {
        setMessage(createErrorMessage('Käyttäjäryhmään lisäys ei onnistunut'))
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  const groupOptions = userGroups
    .filter((group) => !memberOf.includes(group.ref))
    .map((group) => ({ key: group.ref, value: group.ref, text: group.name }))
  return (
    <Modal open onClose={onClose} closeIcon>
      <Modal.Header>Lisää käyttäjä uuteen käyttäjäryhmään</Modal.Header>
      <Modal.Content>
        <Header size="small">Valitse käyttäjäryhmä</Header>
        <Dropdown
          fluid
          value={selected}
          onChange={(e, { value }) => setSelected(value)}
          options={groupOptions}
          search
          selection
          scrolling
          selectOnBlur={false}
        />

        <UserGroupAgreements
          serviceAgreements={serviceAgreements}
          voucherGrantAgreements={voucherGrants}
        />

        <Segment textAlign="center" basic>
          <GenericMessage message={message} />
          <Button
            primary
            onClick={addUser}
            disabled={!selected || isSubmitting}
            loading={isSubmitting}
          >
            Lisää
          </Button>
        </Segment>
      </Modal.Content>
    </Modal>
  )
}

AddUserToUserGroupModal.propTypes = {
  userRef: PropTypes.string.isRequired,
  memberOf: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func,
  onAddUser: PropTypes.func
}

export default AddUserToUserGroupModal
