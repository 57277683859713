import {
  ChargingStatusEnum,
  getStringValue
} from '../../services/utils/DTOEnums'
import { Confirm, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyLink, MoovyTable, TablePagination } from '../../components'
import React, { useState } from 'react'
import { formatEnergy, formatSeconds } from '../../services/utils'

import ChargerTypeIcon from './chargerTypeIcon'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import moovyDateTime from '../../services/utils/moovyDateTime'

const ChargingSessionsTable = ({
  query,
  sessions,
  sortedColumn,
  sortDirection,
  onSort,
  activePage,
  totalPages,
  onPageChange,
  onStopSession,
  stopSessionMutation
}) => {
  const intl = useIntl()
  const [confirmSessionStop, setConfirmSessionStop] = useState(null)

  const getDuration = (startTime, endTime) => {
    if (!startTime || !endTime) {
      return ''
    }

    return formatSeconds(
      Math.round(endTime.diff(startTime, ['seconds']).seconds)
    )
  }

  return (
    <>
      <MoovyTable sortable selectable items={sessions} query={query}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortedColumn === 'sortByPluginId' ? sortDirection : null}
              onClick={() => onSort('sortByPluginId')}
            >
              <FormattedMessage id="chargings.chargingsTable.header.plugin" />
            </Table.HeaderCell>
            <Table.HeaderCell
              compact={'very'}
              sorted={sortedColumn === 'sortByStatus' ? sortDirection : null}
              onClick={() => onSort('sortByStatus')}
            >
              <FormattedMessage id="chargings.chargingsTable.header.status" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === 'sortByLocation' ? sortDirection : null}
              onClick={() => onSort('sortByLocation')}
            >
              <FormattedMessage id="chargings.chargingsTable.header.location" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === 'sortByCity' ? sortDirection : null}
              onClick={() => onSort('sortByCity')}
            >
              <FormattedMessage id="chargings.chargingsTable.header.city" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="chargings.chargingsTable.header.customer" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === 'sortByType' ? sortDirection : null}
              onClick={() => onSort('sortByType')}
            >
              <FormattedMessage id="chargings.chargingsTable.header.type" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === 'sortByStartTime' ? sortDirection : null}
              onClick={() => onSort('sortByStartTime')}
            >
              <FormattedMessage id="chargings.chargingsTable.header.startTime" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === 'sortByEndTime' ? sortDirection : null}
              onClick={() => onSort('sortByEndTime')}
            >
              <FormattedMessage id="chargings.chargingsTable.header.endTime" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="chargings.chargingsTable.header.duration" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortedColumn === 'sortByEnergy' ? sortDirection : null}
              onClick={() => onSort('sortByEnergy')}
            >
              <FormattedMessage id="chargings.chargingsTable.header.energy" />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sessions &&
            sessions.map((session) => (
              <Table.Row key={session.ref}>
                <Table.Cell>{session.pluginId}</Table.Cell>
                <Table.Cell>
                  <FormattedMessage
                    id={getStringValue(
                      ChargingStatusEnum.localizationKeys,
                      session.chargingStatus
                    )}
                    defaultMessage={session.chargingStatus}
                  />
                </Table.Cell>
                <Table.Cell onClick={(event) => event.stopPropagation()}>
                  {session?.location?.name && session?.location?.ref ? (
                    <RelativeLinkForCurrentUser
                      to={`locations/${session?.location?.ref}`}
                    >
                      {session?.location?.name}
                    </RelativeLinkForCurrentUser>
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell>{session.city}</Table.Cell>
                <Table.Cell onClick={(event) => event.stopPropagation()}>
                  <RelativeLinkForCurrentUser
                    to={`customers/${session?.customer?.ref}`}
                  >
                    {session?.customer?.name}
                  </RelativeLinkForCurrentUser>
                </Table.Cell>
                <Table.Cell>
                  <ChargerTypeIcon chargerType={session.chargerType} />
                </Table.Cell>
                <Table.Cell>
                  {moovyDateTime.backendDateTimeToUI(session.startTime)}
                </Table.Cell>
                <Table.Cell>
                  {moovyDateTime.backendDateTimeToUI(session.endTime)}
                </Table.Cell>
                <Table.Cell>
                  {getDuration(
                    moovyDateTime.backendDateTimeToDateTime(session.startTime),
                    moovyDateTime.backendDateTimeToDateTime(session.endTime)
                  )}
                </Table.Cell>
                <Table.Cell>{formatEnergy(session.energy)}</Table.Cell>
                <Table.Cell
                  collapsing
                  onClick={(event) => event.stopPropagation()}
                >
                  {!session.endTime ? (
                    <MoovyLink
                      deleteLink
                      loading={stopSessionMutation.isPending}
                      disabled={stopSessionMutation.isPending}
                      onClick={() => setConfirmSessionStop(session)}
                    >
                      <FormattedMessage id="chargings.chargingsTable.link.stop" />
                    </MoovyLink>
                  ) : (
                    ''
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <TablePagination
          colSpan={12}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </MoovyTable>
      <Confirm
        size="tiny"
        open={!!confirmSessionStop}
        onCancel={() => setConfirmSessionStop(null)}
        onConfirm={() => {
          onStopSession(confirmSessionStop.ref)
          setConfirmSessionStop(null)
        }}
        content={intl.formatMessage(
          {
            id: 'chargings.chargingSessionsContent.confirm.stopSession'
          },
          {
            customer: confirmSessionStop?.customer?.name || '',
            pluginId: confirmSessionStop?.pluginId || ''
          }
        )}
        cancelButton={intl.formatMessage({
          id: 'common.button.cancel'
        })}
        confirmButton={intl.formatMessage({
          id: 'common.button.confirm'
        })}
      />
    </>
  )
}

export default ChargingSessionsTable
