import FinishPage, { finishePageType } from '../Management/FinishPage'
import { Icon, Message, Step } from 'semantic-ui-react'
import {
  LocationFeatureList,
  MoovyModalConfirmation,
  MutationErrorMessage,
  Toolbar
} from '../../../components'
import React, { useState } from 'react'
import { getStringValue, locationEnums } from '../../../services/utils'

import ConclusionForm from '../Management/ConclusionForm'
import { FormattedMessage } from 'react-intl'
import LocationForm from '../Management/LocationForm'
import OperatorForm from '../Management/OperatorForm'
import ServiceForm from '../Management/ServiceForm'
import ServicePricingForm from '../Management/ServicePricingForm'
import StartingForm from '../Management/StartingForm'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import locationService from '../../../services/Locations'
import { showMoovyToast } from '../../../components/MoovyToast'
import { useMutation } from '@tanstack/react-query'

export const ManagementNewLocationService = ({}) => {
  const breadcrumbs = createBreadcrumbs([
    { text: 'Koti', href: '/admin' },
    { text: 'Hallinta' },
    { text: 'Kohdekokonaisuus' }
  ])

  const STEP_KEYS = {
    STARTING: 'starting',
    OPERATOR: 'operator',
    LOCATION: 'location',
    SERVICE: 'service',
    TARIFF: 'tariff',
    CONCLUSION: 'conclusion'
  }

  const [importConfirmOpen, setImportConfirmOpen] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [activeStep, setActiveStep] = useState(STEP_KEYS.STARTING)
  const [finishPageContent, setFinishPageContent] = useState(null)
  const [stepsStatus, setStepsStatus] = useState([
    {
      name: STEP_KEYS.STARTING,
      icon: 'copy',
      title: 'Kohdepohja',
      description: 'Tuo valmis pohja',
      completed: false
    },
    {
      name: STEP_KEYS.OPERATOR,
      icon: 'user',
      title: 'Operaattori',
      description: 'Operaattorin tiedot',
      completed: false
    },
    {
      name: STEP_KEYS.LOCATION,
      icon: 'map',
      title: 'Kohde',
      description: 'Kohteen tiedot',
      completed: false
    },
    {
      name: STEP_KEYS.SERVICE,
      icon: 'shopping cart',
      title: 'Tuote',
      description: 'Tuotteen tiedot',
      completed: false
    },
    {
      name: STEP_KEYS.TARIFF,
      completed: false,
      icon: 'credit card',
      title: 'Tariffi',
      description: 'Tuotteen hinnoittelu'
    },
    {
      name: STEP_KEYS.CONCLUSION,
      completed: false,
      icon: 'info',
      title: 'Yhteenveto',
      description: 'Lisää kokonaisuus'
    }
  ])

  const { mutate: importLocationService, ...importLocationServiceMutation } =
    useMutation({
      mutationFn: () =>
        locationService.importLocation({
          ...formValues
        }),
      onSuccess: (response) => {
        showMoovyToast(
          'Uusi kohde luotu',
          'Uusi kohde luotu annetuilla tiedoilla'
        )
        updateCompleted(STEP_KEYS.CONCLUSION)
        clearFormData()
        setFinishPageContent({ ref: response.ref, name: response.name })
      }
    })

  const onStepClicked = (e, { name }) => {
    setActiveStep(name)
  }

  const resetCompleted = (stepName) => {
    const newStatusArray = stepsStatus.map((item) => ({
      ...item,
      completed: false
    }))
    if (stepName) {
      const item = newStatusArray.findIndex((item) => item.name === stepName)
      newStatusArray[item].completed = true
    }
    activeNextStep(newStatusArray)
    setStepsStatus(newStatusArray)
  }

  const updateCompleted = (stepName) => {
    const newStatusArray = [...stepsStatus]
    const item = newStatusArray.findIndex((item) => item.name === stepName)
    newStatusArray[item].completed = true
    const startingItem = newStatusArray.findIndex(
      (item) => item.name === STEP_KEYS.STARTING
    )
    newStatusArray[startingItem].completed = true
    activeNextStep(newStatusArray)
    setStepsStatus(newStatusArray)
  }

  const activeNextStep = (statusArray) => {
    const nextStep = statusArray.find((item) => item.completed === false)
    if (nextStep) {
      setActiveStep(nextStep.name)
    }
  }

  const clearFormData = () => {
    setFormValues({})
    resetCompleted()
  }

  const isAllCompleted = (statusArray, stepName) => {
    return !statusArray.find(
      (item) => item.completed === false && item.name !== stepName
    )
  }

  const onDataCompleted = (data, stepName) => {
    const bindToFormData = { ...formValues }
    switch (stepName) {
      case STEP_KEYS.STARTING:
        resetCompleted(stepName)
        setFormValues({ ...data })
        importLocationServiceMutation.isError &&
          importLocationServiceMutation.reset()
        break
      case STEP_KEYS.OPERATOR:
        updateCompleted(stepName)
        setFormValues({ ...data })
        importLocationServiceMutation.isError &&
          importLocationServiceMutation.reset()
        break
      case STEP_KEYS.LOCATION:
        updateCompleted(stepName)
        bindToFormData.location = data
        setFormValues(bindToFormData)
        importLocationServiceMutation.isError &&
          importLocationServiceMutation.reset()
        break
      case STEP_KEYS.SERVICE:
        updateCompleted(stepName)
        bindToFormData.service = data
        setFormValues(bindToFormData)
        importLocationServiceMutation.isError &&
          importLocationServiceMutation.reset()
        break
      case STEP_KEYS.TARIFF:
        updateCompleted(stepName)
        bindToFormData.servicePricing = data
        setFormValues(bindToFormData)
        importLocationServiceMutation.isError &&
          importLocationServiceMutation.reset()
        break
      case STEP_KEYS.CONCLUSION:
        if (isAllCompleted(stepsStatus, stepName)) {
          setImportConfirmOpen(true)
        } else {
          activeNextStep(stepsStatus)
        }
        break
      default:
        break
    }
  }

  const showContent = (stepName) => {
    switch (stepName) {
      case STEP_KEYS.STARTING:
        return <StartingForm onDataCompleted={onDataCompleted} />
      case STEP_KEYS.OPERATOR:
        return (
          <OperatorForm
            onDataCompleted={onDataCompleted}
            formValues={formValues}
          />
        )
      case STEP_KEYS.LOCATION:
        return (
          <LocationForm
            onDataCompleted={onDataCompleted}
            formValues={formValues.location}
          />
        )
      case STEP_KEYS.SERVICE:
        return (
          <ServiceForm
            onDataCompleted={onDataCompleted}
            formValues={formValues.service}
          />
        )
      case STEP_KEYS.TARIFF:
        return (
          <ServicePricingForm
            onDataCompleted={onDataCompleted}
            formValues={formValues.servicePricing}
          />
        )
      case STEP_KEYS.CONCLUSION:
        return (
          <ConclusionForm
            onDataCompleted={onDataCompleted}
            formValues={formValues}
          />
        )

      default:
        return <div />
    }
  }

  const RenderBarrierLessMessage = () => {
    if (formValues?.location?.barrierlessFacility) {
      return (
        <Message color="blue">
          <Message.Header>
            Kohde on puomiton ja sitä ominaisuutta ei voi muuttaa. Huomioi
            seuraavat asiat:
          </Message.Header>
          <Message.List
            items={[
              'Visma-perintä tulee määrittää maksunsaajalle',
              'Moovy-verkkomaksusivulle tulee täydentää kohteen tiedot',
              'Ylläpidolle tulee ilmoittaa automaattisen perinnän raja-arvot',
              'Ylläpidolle tulee ilmoittaa verkkomaksun alakauppiastunnukset'
            ]}
          />
        </Message>
      )
    }
    return ''
  }

  const RenderParkingControlledMessage = () => {
    if (formValues?.location?.parkingControlled) {
      let items = [
        'Ylläpidolta tulee pyytää pysäköinninvalvonnan integraatiotunnukset'
      ]
      if (!formValues?.location?.parkingControlId) {
        items.push(
          'Pysäköinninvalvontatunniste pitää määrittää kohteelle ja välittää pysäköinnin valvontaan'
        )
      } else {
        items.push(
          'Pysäköinninvalvontatunniste pitää välittää pysäköinnin valvontaan erikseen'
        )
      }
      return (
        <Message color="blue">
          <Message.Header>
            Kohteeseen on lisätty pysäköinninvalvonta. Huomioi seuraavat asiat:
          </Message.Header>
          <Message.List items={items} />
        </Message>
      )
    }
    return ''
  }

  const RenderWarningMessage = () => {
    return (
      <>
        <RenderBarrierLessMessage />
        <RenderParkingControlledMessage />
      </>
    )
  }

  const RenderBasicLocationContent = () => {
    return (
      <>
        {' '}
        <div>
          <b>Kohteen nimi: </b>
          {formValues?.location?.name}
        </div>
        <div>
          <b>Kohdetyyppi: </b>
          <FormattedMessage
            id={getStringValue(
              locationEnums.locationType.localizationKeys,
              formValues?.location?.type
            )}
            defaultMessage={formValues?.location?.type}
          />
        </div>
        <br />
        <b>Kohteen ominaisuuksia</b>
        <LocationFeatureList location={formValues?.location} />
      </>
    )
  }

  return (
    <>
      <Toolbar breadcrumbs={breadcrumbs} title="Lisää uusi kohdekokonaisuus" />
      <div className="Admin--Page--Content">
        <MutationErrorMessage
          mutation={importLocationServiceMutation}
          errorTitleTextLangId="management.create.location.action.title.failed"
          defaultErrorTextLangId="management.create.location.action.description.failed"
          messageNegative
          showRawErrorMessage
        />
        {finishPageContent ? (
          <FinishPage
            finishPageContent={finishPageContent}
            pageType={finishePageType.LOCATION}
            onCreateNew={() => setFinishPageContent(null)}
          />
        ) : (
          <Step.Group>
            {stepsStatus.map((item) => (
              <Step
                key={item.name}
                name={item.name}
                active={activeStep === item.name}
                completed={item.completed}
                onClick={onStepClicked}
              >
                <Icon name={item.icon} />
                <Step.Content>
                  <Step.Title>{item.title}</Step.Title>
                  <Step.Description>{item.description}</Step.Description>
                </Step.Content>
              </Step>
            ))}
          </Step.Group>
        )}
        {!finishPageContent && showContent(activeStep)}
      </div>
      <MoovyModalConfirmation
        open={importConfirmOpen}
        onClose={() => setImportConfirmOpen(false)}
        lang={{
          titleElement: <>Kohteen luonti</>,
          bodyElement: (
            <>
              <RenderWarningMessage />
              <RenderBasicLocationContent />
              <br />
              Luodaanko uusi kohde annetuilla tiedoilla?
            </>
          ),
          buttonConfirmKey: 'common.button.confirm',
          defaultErroKey: 'modalCoverCreditLoss.coverCreditLoss.action.error'
        }}
        onSubmit={() => {
          setImportConfirmOpen(false)
          importLocationService()
        }}
        mutation={importLocationServiceMutation}
      />
    </>
  )
}

export default ManagementNewLocationService
