import './EnterpriseCustomer.scss'

import { Label, Menu, Segment, Tab } from 'semantic-ui-react'
import React, { useState } from 'react'

import { ConversationHistory } from '../../../components'
import CustomerInvoicing from '../CustomerInvoicing/CustomerInvoicing'
import EnterpriseAssignments from './EnterpriseAssignments'
import EnterpriseCustomerToolbar from './EnterpriseCustomerToolbar'
import { FormattedMessage } from 'react-intl'
import GrantedVouchers from './GrantedVouchers'
import PropTypes from 'prop-types'
import SubscribedServices from '../CustomerSubscribedServices'
import UserGroupView from '../../../containers/UserGroupView'
import { get } from '../../../services/utils/api-renewed'
import { getUserData } from './EnterpriseUserTabCountData'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'
import userService from '../../../services/User/UserServices'

const EnterpriseCustomer = ({
  customer,
  parentState,
  updateParentState,
  onRefreshUser
}) => {
  const { operatorRealm, isSuperOperator } = useAuthInfo()

  const { selectedRef, includeHistory, partialSearch, pagination } = parentState
  const [showConversationCount, setShowConversationCount] = useState(true)
  const [activeIndex, setActiveIndex] = useState(0)

  const {
    userGroups,
    conversations,
    refetchConversation,
    refetchUserGroups,
    isDataLoading
  } = getUserData(operatorRealm, customer.ref)

  const assignments = useQuery({
    queryKey: [
      'enterpriseAssignments',
      selectedRef,
      includeHistory,
      partialSearch,
      pagination
    ],
    queryFn: () => {
      const path = operatorRealm
        ? `/operator/${operatorRealm}/subscribedService/${selectedRef}/assignments`
        : `/administration/subscribedService/${selectedRef}/assignments`

      return get(path, {
        includeHistory,
        partialSearch: partialSearch?.trim(),
        page: pagination.activePage - 1,
        limit: 10,
        sort: pagination.column,
        direction: pagination.direction
      })
    },

    enabled: Boolean(
      (selectedRef && !partialSearch) ||
        (selectedRef && partialSearch?.trim()?.length > 2)
    )
  })

  const handleSort = (sortColumn) => () => {
    if (sortColumn === pagination.column) {
      const direction = pagination.direction === 'DESC' ? 'ASC' : 'DESC'
      updateParentState.setPagination({ ...pagination, direction })
    } else {
      updateParentState.setPagination({
        ...pagination,
        direction: 'DESC',
        column: sortColumn
      })
    }
  }

  const subscribedServicesQuery = useQuery({
    queryKey: ['subscribedServices', customer.ref, includeHistory],
    queryFn: () =>
      userService.fetchSubscribedServices(
        customer.ref,
        operatorRealm,
        includeHistory
      )
  })

  const subscribedServices = subscribedServicesQuery.data
    ? subscribedServicesQuery.data
    : []

  const StyledMenuItem = ({ textLocalizationId, showEmpty }) => {
    return (
      <>
        {showEmpty ? (
          <span style={{ color: '#ababab' }}>
            <FormattedMessage id={textLocalizationId} />
          </span>
        ) : (
          <FormattedMessage id={textLocalizationId} />
        )}
      </>
    )
  }

  const allPanes = {
    enterpriseAssignments: {
      menuItem: (
        <Menu.Item key="userAssignments">
          <FormattedMessage id="enterpriseCustomer.detail.tab.userAssignments" />
        </Menu.Item>
      ),
      pane: {
        key: 'enterpriseAssignments',
        content: (
          <Segment basic>
            <EnterpriseAssignments
              subscribedServices={subscribedServices}
              state={parentState}
              updateState={updateParentState}
              assignments={assignments}
              handleSort={handleSort}
            />
          </Segment>
        )
      }
    },

    orderedProducts: {
      menuItem: (
        <Menu.Item key="orderedProducts">
          <StyledMenuItem
            textLocalizationId={'enterpriseCustomer.detail.tab.orderedProducts'}
            showEmpty={subscribedServices.length === 0 && !isDataLoading}
          />
        </Menu.Item>
      ),
      pane: {
        key: 'orderedProducts',
        content: (
          <Segment basic>
            <SubscribedServices customer={customer} />
          </Segment>
        )
      }
    },
    invoiceData: {
      menuItem: (
        <Menu.Item key="invoiceData">
          <StyledMenuItem
            textLocalizationId={'enterpriseCustomer.detail.tab.invoiceData'}
          />
        </Menu.Item>
      ),

      pane: {
        key: 'invoiceData',
        content: (
          <Segment basic>
            <CustomerInvoicing
              customer={customer}
              operatorRealm={operatorRealm}
              onRefreshUser={onRefreshUser}
            />
          </Segment>
        )
      }
    },
    grantedVouchers: {
      menuItem: (
        <Menu.Item key="grantedVouchers">
          <FormattedMessage id="enterpriseCustomer.detail.tab.grantedVouchers" />
        </Menu.Item>
      ),
      pane: {
        key: 'grantedVouchers',
        content: (
          <Segment basic>
            <GrantedVouchers userRef={customer.ref} operator={operatorRealm} />
          </Segment>
        )
      }
    },
    userGroups: {
      menuItem: (
        <Menu.Item key="userGroups">
          <StyledMenuItem
            textLocalizationId={'enterpriseCustomer.detail.tab.userGroups'}
            showEmpty={userGroups.length === 0 && !isDataLoading}
          />
        </Menu.Item>
      ),
      pane: {
        key: 'userGroups',
        content: (
          <Segment basic>
            <UserGroupView
              customer={customer}
              onRefetchUserGroups={() => refetchUserGroups()}
            />
          </Segment>
        )
      }
    },
    conversations: {
      menuItem: (
        <Menu.Item key="conversationMenuItem">
          <FormattedMessage id="enterpriseCustomer.detail.tab.conversationHistory" />
          {showConversationCount && conversations.length > 0 && (
            <Label circular floating>
              {conversations.length}
            </Label>
          )}
        </Menu.Item>
      ),
      pane: {
        key: 'conversations',
        content: (
          <Segment basic>
            <ConversationHistory
              customerRef={customer.ref}
              conversations={conversations}
              refetchConversations={() => refetchConversation()}
            />
          </Segment>
        )
      }
    }
  }

  return (
    <>
      <EnterpriseCustomerToolbar customer={customer} />
      <div className="Admin--Page--Content">
        <Tab
          activeIndex={activeIndex}
          onTabChange={(e, { activeIndex }) => {
            if (
              Object.values(allPanes).findIndex(
                (item) => item.pane.key === 'conversations'
              ) == activeIndex
            ) {
              setShowConversationCount(false)
            }
            setActiveIndex(activeIndex)
          }}
          panes={
            isSuperOperator
              ? Object.values(allPanes)
              : [
                  allPanes.enterpriseAssignments,
                  allPanes.orderedProducts,
                  allPanes.grantedVouchers,
                  allPanes.invoiceData,
                  allPanes.userGroups
                ]
          }
          renderActiveOnly={false}
        />
      </div>
    </>
  )
}

export default EnterpriseCustomer

EnterpriseCustomer.propTypes = {
  customer: PropTypes.shape()
}

EnterpriseCustomer.defaultProps = {
  customer: {}
}
