import { DateTime } from 'luxon'

const dateInputFormat = 'dd.MM.yyyy'
const dateTimeInputFormat = 'd.M.yyyy HH:mm'
const dateTimeUIFormat = 'd.M.yyyy HH:mm:ss'

const dateNowToCalendarInput = () => DateTime.local().toFormat(dateInputFormat)
const dateTimeNowToCalendarInput = () =>
  DateTime.local().toFormat(dateTimeInputFormat)
const dateToCalendarInput = (dateTime) => dateTime.toFormat(dateInputFormat)
const dateTimeToCalendarInput = (dateTime) =>
  dateTime.toFormat(dateTimeInputFormat)

const calendarDateInputToBeginOfDayISO = (calendarValue) => {
  return DateTime.fromFormat(calendarValue, dateInputFormat).toISO()
}

const calendarDateInputToEndOfDayISO = (calendarValue) => {
  return DateTime.fromFormat(calendarValue, dateInputFormat)
    .plus({ days: 1 })
    .toISO()
}

const calendarDateInputToBeginOfDayBackendFormat = (calendarValue) => {
  return DateTime.fromFormat(calendarValue, dateInputFormat)
    .toUTC()
    .toISO()
}

const calendarDateInputToEndOfDayBackendFormat = (calendarValue) => {
  return DateTime.fromFormat(calendarValue, dateInputFormat)
    .plus({ days: 1 })
    .toUTC()
    .toISO()
}

const calendarDateInputToDateTime = (calendarValue) => {
  return DateTime.fromFormat(calendarValue, dateInputFormat)
}

const calendarDateTimeInputToDateTime = (calendarValue) => {
  return DateTime.fromFormat(calendarValue, dateTimeInputFormat)
}

const dateTimeToBackendFormat = (dateTime) => {
  return dateTime.toUTC().toISO()
}

const dateTimeToUI = (dateTime) => {
  return dateTime ? dateTime.toFormat(dateTimeUIFormat) : ''
}

const backendDateTimeToUI = (dateTime) => {
  return dateTime ? DateTime.fromISO(dateTime).toFormat(dateTimeUIFormat) : ''
}

const backendDateTimeToDateTime = (dateTime) => {
  return dateTime ? DateTime.fromISO(dateTime) : null
}

const plusMonthsToCalendarDateInput = (calendarInputValue, months) => {
  let date = calendarDateInputToDateTime(calendarInputValue)
  let plusDate = date.plus({ months: months })
  return dateToCalendarInput(plusDate)
}

const isoToJSDate = (datetime) => {
  return datetime ? DateTime.fromISO(datetime).toJSDate() : undefined
}

const jsDateToISOString = (datetime) => {
  return datetime ? datetime.toISOString() : ''
}

export default {
  dateNowToCalendarInput,
  dateTimeNowToCalendarInput,
  dateToCalendarInput,
  dateTimeToCalendarInput,
  calendarDateInputToBeginOfDayISO,
  calendarDateInputToEndOfDayISO,
  calendarDateInputToBeginOfDayBackendFormat,
  calendarDateInputToEndOfDayBackendFormat,
  calendarDateInputToDateTime,
  calendarDateTimeInputToDateTime,
  dateTimeToBackendFormat,
  dateTimeToUI,
  backendDateTimeToUI,
  backendDateTimeToDateTime,
  plusMonthsToCalendarDateInput,
  isoToJSDate,
  jsDateToISOString
}
