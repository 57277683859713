import * as yup from 'yup'

export const validationSchema = () => {
  return yup.object().shape({
    benefitType: yup.string().required('Cannot be empty'),
    categoryType: yup.string().required('Cannot be empty'),
    operatorRef: yup.string().required('Cannot be empty'),
    allowedGrantType: yup.string().required('Cannot be empty'),
    validityLocationRefs: yup
      .array()
      .required('Cannot be empty')
      .min(1),
    validityInMinutes: yup
      .number()
      .required('Only number allowed')
      .min(0),
    salePrice: yup.number().required('Only number allowed'),
    grantedDiscountPercent: yup.number().required('Only number allowed'),
    grantedDurationInMinutes: yup.number().required('Only number allowed'),
    grantedValue: yup.number().required('Only number allowed')
  })
}
