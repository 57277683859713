import React from 'react'
import { Button, List } from 'semantic-ui-react'

import RelativeLinkForCurrentUser from '../../../containers/RelativeLinkForCurrentUser'

export const finishePageType = {
  LOCATION: 'LOCATION',
  SERVICE: 'SERVICE'
}

export const FinishPage = ({ finishPageContent, pageType, onCreateNew }) => {
  const finishNewLocation = ({ ref, name }) => {
    return (
      <List>
        <List.Item>
          <RelativeLinkForCurrentUser to={`/locations/${ref}`}>
            {`Avaa kohde ${name}`}
          </RelativeLinkForCurrentUser>
        </List.Item>
        <List.Item></List.Item>
        <List.Item>
          <Button onClick={() => onCreateNew()} primary>
            Luo uusi kohdekokonaisuus
          </Button>
        </List.Item>
      </List>
    )
  }

  const finishNewService = ({ ref, name }) => {
    return (
      <List>
        <List.Item>
          <RelativeLinkForCurrentUser to={`/services/${ref}`}>
            {`Avaa tuote ${name}`}
          </RelativeLinkForCurrentUser>
        </List.Item>
        <List.Item></List.Item>
        <List.Item>
          <Button onClick={() => onCreateNew()} primary>
            Luo uusi tuote
          </Button>
        </List.Item>
      </List>
    )
  }

  return (
    <>
      {(pageType === finishePageType.LOCATION &&
        finishNewLocation(finishPageContent)) ||
        finishNewService(finishPageContent)}
    </>
  )
}

export default FinishPage
