import './voucherSearchToolbar.scss'

import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input, Label, Select } from 'semantic-ui-react'

import useOperators from '../../../hooks/useOperators'
import locationsService from '../../../services/Locations'
import voucherTemplateService, {
  getVoucherTemplateDescription
} from '../../../services/VoucherTemplates'

const VoucherSearchToolbar = ({
  voucherDescriptionPlaceHolder,
  operatorRealm,
  onSearch,
  queryLoading
}) => {
  const [toolbarData, setToolbarData] = useState({
    voucherDescription: '',
    voucherDescriptionPlaceHolder,
    invalidVoucherDescription: false,
    selectedVoucherTemplateRef: null,
    selectedOperatorRef: null,
    selectedLocationRef: null,
    hideExpiredVouchers: false,
    loading: false
  })
  const [voucherTemplates, setVoucherTemplates] = useState([])
  const [locations, setLocations] = useState([])

  const operators = useOperators()
  // Fetch all operators when the page is loaded.
  useEffect(() => {
    fetchVoucherTemplates()
  }, [])

  useEffect(() => {
    fetchLocations(toolbarData.selectedOperatorRef)
  }, [toolbarData.selectedOperatorRef])

  const fetchVoucherTemplates = () => {
    voucherTemplateService
      .fetchVoucherTemplates(operatorRealm)
      .then((vouchers) => {
        if (vouchers != null) {
          setVoucherTemplates(
            vouchers.map((template) => ({
              key: template.ref,
              value: template.ref,
              text: getVoucherTemplateDescription(template)
            }))
          )
        } else {
          setVoucherTemplates([])
        }
      })
      .catch((error) => {
        console.warn(error)
        setVoucherTemplates([])
      })
      .finally(() => {
        setToolbarData({ ...toolbarData, loading: false })
      })
  }

  const fetchLocations = (operatorRef) => {
    if (operatorRef) {
      setToolbarData({ ...toolbarData, loading: true })
      locationsService
        .fetchLocationsForOperator(operatorRef)
        .then((res) => {
          setLocations(
            res.content.map((location) => ({
              key: location.ref,
              value: location.ref,
              text: location.name
            }))
          )
        })
        .catch((error) => {
          console.warn(error)
          setLocations([])
        })
        .finally(() => {
          setToolbarData({ ...toolbarData, loading: false })
        })
    } else {
      setLocations([])
    }
  }

  const getOperators = () => {
    return operators.data && operators.data.content != null
      ? operators.data.content.map((item) => ({
          key: item.ref,
          text: item.name,
          value: item.ref
        }))
      : []
  }

  const onChangeInputVoucher = (value) => {
    setToolbarData({ ...toolbarData, voucherDescription: value })
  }

  const onChangeVoucherTemplateValue = (value) => {
    setToolbarData({ ...toolbarData, selectedVoucherTemplateRef: value })
  }

  const onChangeOperatorValue = (dropdownValue) => {
    setToolbarData({ ...toolbarData, selectedOperatorRef: dropdownValue })
  }

  const onChangeLocationValue = (dropdownValue) => {
    setToolbarData({ ...toolbarData, selectedLocationRef: dropdownValue })
  }

  const onChangeHideExpiredVouchers = (selectValue) => {
    setToolbarData({ ...toolbarData, hideExpiredVouchers: selectValue })
  }

  const onSearchContent = () => {
    if (
      toolbarData.voucherDescription &&
      toolbarData.voucherDescription.length < 3
    ) {
      setToolbarData({ ...toolbarData, invalidVoucherDescription: true })
      return
    }
    setToolbarData({ ...toolbarData, invalidVoucherDescription: false })

    const {
      voucherDescription,
      selectedVoucherTemplateRef,
      selectedOperatorRef,
      selectedLocationRef,
      hideExpiredVouchers
    } = toolbarData
    onSearch({
      voucherDescription,
      selectedVoucherTemplateRef,
      selectedOperatorRef,
      selectedLocationRef,
      hideExpiredVouchers
    })
  }

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault()
        if (!queryLoading) {
          onSearchContent()
        }
      }}
    >
      <div className="toolbar-filters">
        <Input
          icon="search"
          iconPosition="left"
          placeholder={toolbarData.voucherDescriptionPlaceHolder}
          disabled={toolbarData.loading}
          onChange={(e) => onChangeInputVoucher(e.target.value)}
        />
        {!operatorRealm && (
          <Select
            clearable
            placeholder="Valitse operaattori"
            fluid
            loading={toolbarData.loading}
            search
            options={getOperators()}
            onChange={(e, data) => onChangeOperatorValue(data.value)}
            selectOnBlur={false}
          />
        )}
        <Select
          clearable
          placeholder="Valitse pysäköintietu"
          fluid
          loading={toolbarData.loading}
          search
          options={voucherTemplates}
          onChange={(e, data) => onChangeVoucherTemplateValue(data.value)}
          selectOnBlur={false}
        />
        {!operatorRealm && (
          <Select
            clearable
            placeholder="Valitse kohde"
            fluid
            loading={toolbarData.loading}
            search
            options={locations}
            onChange={(e, data) => onChangeLocationValue(data.value)}
            selectOnBlur={false}
          />
        )}
        <Button primary loading={queryLoading}>
          Hae
        </Button>
      </div>
      {toolbarData.invalidVoucherDescription === true && (
        <Label basic color="red" pointing>
          Syötä rekisteritunnuksen alusta vähintään kolme merkkiä.
        </Label>
      )}
      <div>
        <Checkbox
          className="checkbox-margin"
          label="Piilota päättyneet pysäköintiedut"
          onChange={(e, data) => onChangeHideExpiredVouchers(data.checked)}
        />
      </div>
      <div />
    </form>
  )
}

export default VoucherSearchToolbar
