import { Checkbox, Icon, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'
import { useQueryClient, useQuery } from '@tanstack/react-query'

import AssignedSubscribedServicesModal from './assignedSubscribedServiceModal'
import { dateAfterNow } from '../../../../services/utils'
import subscribedServicesService from '../../../../services/SubscribedServices'
import userService from '../../../../services/User/UserServices'

export const groupAssignmentsBySubscribedService = (
  assignments,
  showOnlyActive
) => {
  /* Backend returns all of users assignments so we have to group them by subscribedService here.
     Nicer solution would be to have backend return aggregate data suitable for this view.
  */
  return assignments
    ? assignments.reduce((acc, assignment) => {
        // If assignment ended and wasn't accepted at all, don't show it in UI.
        if (assignment.endTime && !assignment.user) {
          return acc
        }

        // Check if assignment is active
        const active = !assignment.endTime || dateAfterNow(assignment.endTime)

        if (showOnlyActive && !active) {
          return acc
        }

        // Is the subscribedService already in the accumulator?
        const { subscribedServiceRef } = assignment
        const bySubscribedService = acc.find(
          (elem) => elem.subscribedServiceRef === subscribedServiceRef
        )

        if (bySubscribedService) {
          bySubscribedService.assignments.push(assignment)
          if (active) {
            bySubscribedService.active = true
          }
        } else {
          acc.push({
            subscribedServiceRef,
            serviceName: assignment.service.name,
            owner: assignment.owner,
            assignments: [assignment],
            active,
            subscribedServiceActive: assignment.subscribedServiceActive
          })
        }
        return acc
      }, [])
    : []
}

const UserAssignments = ({ customer, operatorRealm }) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const [selected, setSelected] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [includeHistory, setIncludeHistory] = useState(false)

  const assignments = useQuery({
    queryKey: ['userAssignments', customer.ref],
    queryFn: () => userService.fetchAssignments(customer.ref, operatorRealm)
  })
  const selectedService = useQuery({
    queryKey: ['userAssignmentsSelectedService'],
    queryFn: () =>
      subscribedServicesService.fetchSubscribedService(
        selected.subscribedServiceRef,
        operatorRealm
      ),
    enabled: !!selected
  })

  const acceptAssignment = (assignment) => {
    setLoading(true)
    setError('')
    userService
      .acceptAssignment(customer.ref, assignment.subscribedServiceRef)
      .then(() => {
        const updated = selected.assignments.map((a) =>
          a.ref === assignment.ref ? { ...a, status: 'ACCEPTED' } : a
        )
        setSelected({ ...selected, assignments: updated })
        queryClient.invalidateQueries({
          queryKey: ['userAssignments', customer.ref]
        })
      })
      .catch(() => {
        setError(
          intl.formatMessage({
            id: 'moovyUser.userAssignments.acceptAssignment.error'
          })
        )
      })
      .finally(setLoading(false))
  }

  const revokeAssignment = (assignment) => {
    setLoading(true)
    setError('')
    userService
      .revokeAssignment(assignment.ref, assignment.subscribedServiceRef)
      .then(() => {
        const updated = selected.assignments.map((a) =>
          a.ref === assignment.ref ? { ...a, endTime: new Date() } : a
        )
        setSelected({ ...selected, assignments: updated })
        queryClient.invalidateQueries({
          queryKey: ['userAssignments', customer.ref]
        })
      })
      .catch(() => {
        setError(
          intl.formatMessage({
            id: 'moovyUser.userAssignments.revokeAssignment.error'
          })
        )
      })
      .finally(setLoading(false))
  }

  const bySubscribedService = groupAssignmentsBySubscribedService(
    assignments.data,
    !includeHistory
  ).sort(
    (a, b) => b.active - a.active || a.serviceName.localeCompare(b.serviceName)
  )

  const renderStatusIcon = (assignment) => {
    if (!assignment.active) {
      return null
    }
    return !assignment.subscribedServiceActive ? (
      <Icon className="red" name="exclamation" />
    ) : (
      <Icon className="primaryColor" name="check" />
    )
  }

  return (
    <>
      <Checkbox
        checked={includeHistory}
        onClick={() => setIncludeHistory((prev) => !prev)}
        label={intl.formatMessage({
          id: 'moovyUser.userAssignments.checkbox.showNotActiveAssignments'
        })}
      />
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="moovyUser.userAssignments.table.header.service" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="moovyUser.userAssignments.table.header.owner" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="moovyUser.userAssignments.table.header.active" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {bySubscribedService.map((assignment) => (
            <Table.Row
              key={assignment.subscribedServiceRef}
              onClick={() => {
                setError('')
                setSelected(assignment)
              }}
            >
              <Table.Cell>{assignment.serviceName}</Table.Cell>
              <Table.Cell>
                {assignment.owner.company} ({assignment.owner.businessCode})
              </Table.Cell>
              <Table.Cell>{renderStatusIcon(assignment)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {selected && (
        <AssignedSubscribedServicesModal
          customer={customer}
          operatorRealm={operatorRealm}
          subscribedServiceWithAssignments={selected}
          subscribedService={selectedService.data}
          isLoading={loading || selectedService.isFetching}
          error={error}
          acceptAssignment={acceptAssignment}
          revokeAssignment={revokeAssignment}
          closeAssignments={() => setSelected(null)}
        />
      )}
    </>
  )
}

export default UserAssignments
