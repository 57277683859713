import { useQuery, useQueryClient } from '@tanstack/react-query'

import userGroupService from '../services/UserGroups'

export default function useUserGroups(operatorRealm) {
  const queryKey = 'userGroup'
  const queryClient = useQueryClient()
  return [
    useQuery({
      queryKey: [queryKey, operatorRealm],
      queryFn: () => userGroupService.fetchAllUserGroups(operatorRealm)
    }),
    () => queryClient.invalidateQueries({ queryKey: [queryKey] })
  ]
}
