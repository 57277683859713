import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Header, Icon, Segment } from 'semantic-ui-react'
import React, { useState } from 'react'

import ModalEditDescription from './ModalEditDescription'
import { MoovyButton } from '../../components'
import { PermissionService } from '../../services/utils'
import servicesService from '../../services/Services'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'

const ParkingServiceDescriptionTab = ({ parkingService, refresh }) => {
  const { isSuperOperator, roles } = useAuthInfo()
  const intl = useIntl()
  const [editModalOpen, setEditModalOpen] = useState(false)

  const { mutate: updateDescription, ...updateDescriptionMutation } =
    useMutation({
      mutationFn: (description) =>
        servicesService.updateServiceDescription(parkingService.ref, {
          description
        }),
      onSuccess: () => {
        setEditModalOpen(false)
        refresh()
        showLocalizedMoovyToast(intl, {
          title: 'parkingServiceDetails.tab.description.toast.edit.title',
          description: 'parkingServiceDetails.tab.description.toast.edit.body'
        })
      }
    })

  const updateServiceDescription = (description) => {
    updateDescription(description)
  }
  return (
    <>
      <br />
      <Grid>
        <Grid.Column floated="left" width={8}>
          <Header>
            <FormattedMessage id="parkingServiceDetails.tab.description.title" />
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          {PermissionService.HasEditDescription(isSuperOperator, roles) && (
            <MoovyButton onClick={() => setEditModalOpen(true)}>
              <Icon name="pencil" />
              <FormattedMessage id="common.button.modify" />
            </MoovyButton>
          )}
        </Grid.Column>
      </Grid>
      <Grid columns={2}>
        <Grid.Column widescreen={8} computer={8} tablet={16} mobile={16}>
          {parkingService.description ? (
            <Segment>
              {parkingService.description.split('\n').map((line, index) => (
                <p key={index}>{line}</p>
              ))}
            </Segment>
          ) : (
            <FormattedMessage id="parkingServiceDetails.tab.description.description.notAvailable" />
          )}
        </Grid.Column>
      </Grid>
      {editModalOpen && (
        <ModalEditDescription
          onClose={() => {
            updateDescriptionMutation.reset()
            setEditModalOpen(false)
          }}
          subscriptionService={parkingService}
          onSubmit={updateServiceDescription}
          mutation={updateDescriptionMutation}
        />
      )}
    </>
  )
}

export default ParkingServiceDescriptionTab
