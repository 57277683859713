import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import { MutationErrorMessage } from '../../components'
import PropTypes from 'prop-types'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'
import userGroupService from '../../services/UserGroups'
import { userGroupShape } from '../../services/utils/shapes'

const UserGroupRenameModal = ({
  userGroup,
  onClose,
  updateUserGroupCallback
}) => {
  const { operatorRealm } = useAuthInfo()
  const intl = useIntl()
  const [value, setValue] = useState(userGroup.name)

  const { mutate: renameUserGroup, ...renameUserGroupMutation } = useMutation({
    mutationFn: () =>
      userGroupService.renameUserGroup(userGroup.ref, value, operatorRealm),
    onSuccess: (res) => {
      updateUserGroupCallback(res.name)
      onClose()
      showLocalizedMoovyToast(intl, {
        title: 'userGroupRenameModal.action.title',
        description: 'userGroupRenameModal.action.description',
        values: { name: res.name }
      })
    }
  })

  return (
    <Modal size="tiny" open onClose={onClose}>
      <Modal.Header>
        <FormattedMessage id="userGroupRenameModal.header" />
      </Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={renameUserGroupMutation}
          defaultErrorTextLangId={'userGroupRenameModal.action.failed'}
          messageNegative
        />
        <p>
          <FormattedMessage id="userGroupRenameModal.instructions" />
        </p>
        <Form onSubmit={renameUserGroup}>
          <Form.Input
            placeholder={intl.formatMessage({
              id: 'userGroupRenameModal.input.placeholder'
            })}
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
            maxLength={100}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          primary
          onClick={renameUserGroup}
          disabled={renameUserGroupMutation.isPending}
          loading={renameUserGroupMutation.isPending}
        >
          <FormattedMessage id="common.button.accept" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

UserGroupRenameModal.propTypes = {
  userGroup: PropTypes.shape(userGroupShape),
  onClose: PropTypes.func,
  updateUserGroupCallback: PropTypes.func
}

export default UserGroupRenameModal
