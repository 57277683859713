import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { Checkbox, Form, Icon, Message } from 'semantic-ui-react'
import userBlocklistService from '../../../services/UserBlocklist'
import {
  MoovyModalConfirmation,
  showLocalizedMoovyToast
} from '../../../components'
import MoovyModal from '../../../components/MoovyModal'

const REASON_INVOICING = 'invoicing'
const REASON_OTHER = 'other'

const AddToBlockListModal = ({ open, onClose, user, onSubmit, mutation }) => {
  const intl = useIntl()
  const [reason, setReason] = useState(undefined)
  const [comment, setComment] = useState('')
  const [submitStarted, setSubmitStarted] = useState(false)

  useEffect(() => {
    setReason(undefined)
    setComment('')
    setSubmitStarted(false)
  }, [open])

  const submitHandler = (e) => {
    e.preventDefault()
    setSubmitStarted(true)
    if (reason === REASON_INVOICING || comment.trim().length > 0) {
      onSubmit({ reason, comment })
    }
  }

  return (
    <MoovyModal
      open={open}
      size="tiny"
      onClose={onClose}
      header={
        <FormattedMessage
          id="modalChangeUserBlockList.addToBlocklist.header"
          values={{ name: user.name }}
        />
      }
      onSubmit={submitHandler}
      submitButtonLabel={<FormattedMessage id="common.button.accept" />}
      submitDisabled={!reason}
      mutation={mutation}
    >
      <Form onSubmit={submitHandler}>
        <Form.Field>
          <label>
            <FormattedMessage id="modalChangeUserBlockList.addToBlocklist.reason" />
          </label>
        </Form.Field>
        <Form.Radio
          label={intl.formatMessage({
            id: 'modalChangeUserBlockList.addToBlocklist.reason.invoicing'
          })}
          checked={reason === REASON_INVOICING}
          onChange={() => {
            setReason(REASON_INVOICING)
            setComment('')
          }}
        />
        <Form.Radio
          label={intl.formatMessage({
            id: 'modalChangeUserBlockList.addToBlocklist.reason.other'
          })}
          checked={reason === REASON_OTHER}
          onChange={() => setReason(REASON_OTHER)}
        />

        {reason === REASON_OTHER && (
          <Form.Input
            error={
              submitStarted && !comment.trim().length > 0
                ? intl.formatMessage({
                    id: 'modalChangeUserBlockList.addToBlocklist.comment.required'
                  })
                : null
            }
            label={intl.formatMessage({
              id: 'modalChangeUserBlockList.addToBlocklist.comment'
            })}
            value={comment}
            maxLength="500"
            onChange={(e, { value }) => {
              setComment(value)
            }}
          />
        )}
      </Form>
    </MoovyModal>
  )
}

const RemoveFromBlockListModal = ({
  open,
  onClose,
  user,
  onConfirm,
  mutation
}) => {
  const [checked, setChecked] = useState(
    user.activeBlockListEntry.allowAutomaticUnblock
  )
  const intl = useIntl()
  return (
    <MoovyModalConfirmation
      open={open}
      onClose={onClose}
      lang={{
        titleElement: (
          <FormattedMessage
            id="modalChangeUserBlockList.removeBlocklist.title"
            values={{ name: user.name }}
          />
        ),
        bodyElement: (
          <>
            {user.activeBlockListEntry.allowAutomaticUnblock ? (
              <p>
                <FormattedMessage
                  id="modalChangeUserBlockList.body.unsetFromBlocklist"
                  values={{ name: user.name }}
                />
              </p>
            ) : (
              <>
                <Message warning>
                  <p>
                    <Icon name="exclamation" />
                    <FormattedMessage id="modalChangeUserBlockList.removeBlocklist.warningMessage" />
                  </p>
                  <p>{user.activeBlockListEntry.comment}</p>
                </Message>
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked((previous) => !previous)}
                  label={intl.formatMessage({
                    id: 'modalChangeUserBlockList.removeBlocklist.confirmMessage'
                  })}
                />
              </>
            )}
          </>
        ),
        buttonConfirmKey: 'common.button.accept',
        defaultErroKey: 'modalChangeUserBlockList.action.error.default'
      }}
      onSubmit={onConfirm}
      mutation={mutation}
      disableSubmit={!checked}
    />
  )
}

const ModalChangeUserBlockList = ({ open, user, onClose, onConfirm }) => {
  const intl = useIntl()

  const beingBlockListed = user.activeBlockListEntry === undefined

  const { mutate: addToBlocklist, ...addToBlocklistMutation } = useMutation({
    mutationFn: ({ reason, comment }) => {
      return userBlocklistService.addToBlocklist(
        user.ref,
        reason === REASON_INVOICING,
        comment.trim()
      )
    },
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'modalChangeUserBlockList.toast.title.blocklisted',
        description: 'modalChangeUserBlockList.toast.description.blocklisted'
      })
      onConfirm()
    }
  })

  const { mutate: removeFromBlocklist, ...removeFromBlocklistMutation } =
    useMutation({
      mutationFn: () => {
        return userBlocklistService.removeFromBlocklist(user.ref)
      },
      onSuccess: () => {
        showLocalizedMoovyToast(intl, {
          title: 'modalChangeUserBlockList.toast.title.unblocklisted',
          description:
            'modalChangeUserBlockList.toast.description.unblocklisted'
        })
        onConfirm()
      }
    })

  return beingBlockListed ? (
    <AddToBlockListModal
      open={open}
      onClose={onClose}
      user={user}
      onSubmit={addToBlocklist}
      mutation={addToBlocklistMutation}
    />
  ) : (
    <RemoveFromBlockListModal
      open={open}
      onClose={onClose}
      user={user}
      onConfirm={removeFromBlocklist}
      mutation={removeFromBlocklistMutation}
    />
  )
}

export default ModalChangeUserBlockList

ModalChangeUserBlockList.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.shape({}),
  onClose: PropTypes.func,
  onConfirm: PropTypes.func
}
