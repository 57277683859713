import PropTypes from 'prop-types'

import { invoicingStatus, paymentCardCategory, paymentMethod } from './DTOEnums'

export const refNamePair = {
  ref: PropTypes.string.isRequired,
  name: PropTypes.string
}

const { localizationKeys, ...invoicingStatusEnums } = invoicingStatus
export const invoiceShape = {
  ref: PropTypes.string.isRequired,
  creationTime: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.number.isRequired,
  status: PropTypes.oneOf(Object.keys(invoicingStatusEnums)).isRequired,
  rowCount: PropTypes.number.isRequired,
  totalPrice: PropTypes.number.isRequired,
  paymentCardCategory: PropTypes.oneOf(Object.keys(paymentCardCategory)),
  vismaDueDate: PropTypes.string,
  compensatedInvoice: PropTypes.shape(refNamePair),
  compensatingInvoiceNumber: PropTypes.number
}

export const invoiceRowShape = {
  ref: PropTypes.string.isRequired,
  rowNumber: PropTypes.number,
  totalPrice: PropTypes.number.isRequired,
  vatPercent: PropTypes.number.isRequired,
  description: PropTypes.string,
  product: PropTypes.shape(refNamePair)
}

export const serviceAgreementShape = {
  ref: PropTypes.string.isRequired,
  maxCount: PropTypes.number,
  service: PropTypes.shape(refNamePair)
}

export const voucherGrantAgreementShape = {
  ref: PropTypes.string.isRequired,
  voucherTemplate: PropTypes.shape(refNamePair).isRequired
}

export const locationShape = {
  active: PropTypes.bool,
  anonymousParkingSupported: PropTypes.bool,
  city: PropTypes.string,
  controlledParkingEndTime: PropTypes.string,
  controlledParkingStartTime: PropTypes.string,
  doorCode: PropTypes.string,
  heatedParkingSpaces: PropTypes.number,
  heating: PropTypes.bool,
  internalName: PropTypes.string,
  licensePlateRecognitionSupported: PropTypes.bool,
  barrierlessFacility: PropTypes.bool,
  name: PropTypes.string,
  notHeatedParkingSpaces: PropTypes.number,
  operator: PropTypes.shape(refNamePair)
}

export const grantedVoucherShape = {
  ref: PropTypes.string.isRequired,
  grantedDate: PropTypes.string,
  phoneNumber: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.number
}

export const voucherShape = {
  amount: PropTypes.number,
  benefitType: PropTypes.string.isRequired,
  categoryType: PropTypes.string.isRequired,
  grantedDate: PropTypes.string,
  grantedTo: PropTypes.string,
  user: PropTypes.shape(refNamePair),
  ref: PropTypes.string.isRequired,
  validFrom: PropTypes.string.isRequired,
  validUntil: PropTypes.string.isRequired,
  visibleValidityFrom: PropTypes.string,
  visibleValidityUntil: PropTypes.string,
  voucherType: PropTypes.string.isRequired
}

export const voucherTemplateShape = {
  ref: PropTypes.string,
  benefitType: PropTypes.string,
  grantedDurationInMinutes: PropTypes.number,
  operator: PropTypes.shape(refNamePair),
  salePrice: PropTypes.number,
  validLocationsDescription: PropTypes.string,
  validityInMinutes: PropTypes.number,
  validityLocationRefs: PropTypes.array
}

export const voucherInfoShape = {
  createdAt: PropTypes.string,
  grantedByOperator: PropTypes.shape({
    name: PropTypes.string,
    ref: PropTypes.string
  }),
  ref: PropTypes.string,
  voucherUsages: PropTypes.arrayOf(
    PropTypes.shape({
      ref: PropTypes.string,
      saleRef: PropTypes.string
    })
  )
}

export const userGroupShape = {
  ref: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  operator: PropTypes.shape(refNamePair).isRequired
}
