import { get } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/user/${relativePath}`
    : `/administration/user/${relativePath}`

const createVehicleFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/vehicles/${relativePath}`
    : `/administration/vehicles/${relativePath}`

export const fetchVehiclesByUserRef = (
  operatorRealm,
  userRef,
  vehicleFetchType
) => {
  return get(createFullPath(`${userRef}/vehicle`, operatorRealm), {
    includeStatuses: vehicleFetchType
  })
}

export const fetchVehiclesAccessLogs = (params) => {
  return get(`/administration/vehicles/accessLogs`, {
    ...params
  })
}

export const getLpnRecognitionHistory = (operatorRealm, vehicleRef) => {
  return get(
    createVehicleFullPath(
      `${vehicleRef}/recognitionAllowedHistory`,
      operatorRealm
    )
  )
}
