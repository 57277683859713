import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Message } from 'semantic-ui-react'
import { useQuery } from '@tanstack/react-query'

import ExternalFacilityDataModal from './ExternalFacilityDataModal'
import ExternalFacilityDataTable from './ExternalFacilityDataTable'
import ParkingAuditLog from './ParkingAuditLog/ParkingAuditLog'
import ParkingSales from './ParkingSales'
import QueryContainer from '../../components/QueryContainer'
import VouchersTable from '../../components/VouchersTable'
import { filterArrayByDistinctProperty } from '../../services/utils'
import parkingService from '../../services/Parking'

const SalesContainer = ({ parkingRef, parking, operatorRealm }) => {
  const sales = useQuery({
    queryKey: ['sales', parkingRef],
    queryFn: () => parkingService.fetchSales(parkingRef, operatorRealm)
  })
  return (
    <QueryContainer query={sales}>
      <ParkingSales sales={sales.data ? sales.data : []} parking={parking} />
    </QueryContainer>
  )
}
const VouchersContainer = ({ parkingRef, operatorRealm, onClickVoucher }) => {
  const vouchers = useQuery({
    queryKey: ['vouchers', parkingRef],
    queryFn: () => parkingService.fetchUsedVouchers(parkingRef, operatorRealm)
  })
  return (
    <QueryContainer query={vouchers}>
      <VouchersTable
        vouchers={
          vouchers.data
            ? filterArrayByDistinctProperty(vouchers.data, 'ref')
            : []
        }
        onClickVoucher={onClickVoucher}
        compact
      />
    </QueryContainer>
  )
}

const ParkingAuditLogContainer = ({ parkingRef, operatorRealm }) => {
  const auditLogs = useQuery({
    queryKey: ['auditLog', parkingRef],
    queryFn: () => parkingService.fetchAuditLog(parkingRef, operatorRealm)
  })

  return (
    <QueryContainer query={auditLogs}>
      <ParkingAuditLog auditLogs={auditLogs.data} />
    </QueryContainer>
  )
}

const ExternalFacilityDataContainer = ({ parkingRef, operatorRealm }) => {
  const query = useQuery({
    queryKey: ['externalFacilityData', parkingRef],
    queryFn: () =>
      parkingService.fetchExternalFacilityData(parkingRef, operatorRealm)
  })

  const [selected, setSelected] = useState(null)
  const [errorLoadingImage, setErrorLoadingImage] = useState(false)

  const onLoadingError = () => {
    setSelected(null)
    setErrorLoadingImage(true)
  }

  return (
    <QueryContainer query={query}>
      <>
        {errorLoadingImage && (
          <Message error>
            <FormattedMessage id="parkingModal.tab.externalFacilityDataContainer.imageLoadingError" />
          </Message>
        )}
        <ExternalFacilityDataTable
          data={query.data ? query.data : []}
          onRowClick={(row) => {
            setSelected(row)
            setErrorLoadingImage(false)
          }}
        />
        <ExternalFacilityDataModal
          externalDataRef={selected ? selected.moovyControlTransactionId : null}
          sessionRef={parkingRef}
          open={Boolean(selected)}
          onClose={() => setSelected(null)}
          onError={onLoadingError}
        />
      </>
    </QueryContainer>
  )
}

export default {
  SalesContainer,
  VouchersContainer,
  ParkingAuditLogContainer,
  ExternalFacilityDataContainer
}
