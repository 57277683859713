import { FormattedMessage } from 'react-intl'
import { List } from 'semantic-ui-react'
import { MoovyLink } from '../../../components'
import { ROLE_FINANCES } from '../../../services/utils'
import React from 'react'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useNavigate } from 'react-router-dom'

const ServiceSettlementRecipient = ({ service }) => {
  const navigate = useNavigate()
  const { isSuperOperator, roles } = useAuthInfo()

  const productTypeEnum = {
    PARKING_FEE: 'PARKING_FEE',
    PARKING_PERIOD_FEE: 'PARKING_PERIOD_FEE'
  }

  const parkingFeeProduct = service?.products?.find(
    (item) => item.type === productTypeEnum.PARKING_FEE
  )
  const parkingPeriodFeeProduct = service?.products?.find(
    (item) => item.type === productTypeEnum.PARKING_PERIOD_FEE
  )

  const RenderProduct = ({ product, labelLocalizationId }) => {
    return (
      <>
        <b>
          <FormattedMessage id={labelLocalizationId} />
        </b>
        {product ? (
          <>
            {/* TODO Antti: This role will be replaced by a new one later when role definitions are in better shape. */}
            {(roles.includes(ROLE_FINANCES) && isSuperOperator && (
              <MoovyLink
                bold
                style={{ padding: '0px' }}
                onClick={() =>
                  navigate(
                    isSuperOperator
                      ? `/admin/settlementRecipients/${product?.settlementRecipient?.ref}`
                      : `/operator/settlementRecipients/${product?.settlementRecipient?.ref}`
                  )
                }
              >
                {product?.settlementRecipient?.name}
              </MoovyLink>
            )) || <>{` ${product?.settlementRecipient?.name} `}</>}
            ({product?.name})
          </>
        ) : (
          '-'
        )}
      </>
    )
  }

  return (
    <List>
      {parkingFeeProduct && (
        <List.Item>
          <RenderProduct
            product={parkingFeeProduct}
            labelLocalizationId="parkingServiceDetails.subscriptionServiceToolbar.label.parkingFeeSettlementRecipient"
          />
        </List.Item>
      )}
      {parkingPeriodFeeProduct && (
        <List.Item>
          <RenderProduct
            product={parkingPeriodFeeProduct}
            labelLocalizationId="parkingServiceDetails.subscriptionServiceToolbar.label.periodFeeSettlementRecipient"
          />
        </List.Item>
      )}
    </List>
  )
}

export default ServiceSettlementRecipient
