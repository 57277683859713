import { fetchConversationsByUserRef } from '../../../services/Conversation'
import { fetchVehiclesByUserRef } from '../../../services/Vehicles'
import { groupAssignmentsBySubscribedService } from './tabUserAssignments/userAssignments'
import { useQuery } from '@tanstack/react-query'
import userService from '../../../services/User/UserServices'
import userVoucherService from '../../../services/UserVouchers'
import { vehicleFetchType } from '../../../services/utils/DTOEnums'

export const getUserData = (operatorRealm, customerRef) => {
  const subscribedServicesQuery = useQuery({
    queryKey: ['subscribedServicesQuery', customerRef],
    queryFn: () =>
      userService.fetchSubscribedServices(customerRef, operatorRealm, false)
  })
  const subscribedServices = subscribedServicesQuery.data || []

  const assignmentsQuery = useQuery({
    queryKey: ['assignmentsQuery', customerRef],
    queryFn: () => userService.fetchAssignments(customerRef, operatorRealm)
  })
  const assignments =
    (assignmentsQuery.data &&
      groupAssignmentsBySubscribedService(assignmentsQuery.data, true)) ||
    []

  const vouchersQuery = useQuery({
    queryKey: ['vouchersQuery', customerRef],
    queryFn: () =>
      userVoucherService.fetchUserVouchers(customerRef, operatorRealm)
  })
  const vouchers = vouchersQuery.data?.data || []

  const {
    data: vehiclesData,
    isLoading: isLoadingVehicles,
    refetch: refetchVehicles
  } = useQuery({
    queryKey: ['vehiclesQuery', customerRef],
    queryFn: () =>
      fetchVehiclesByUserRef(
        operatorRealm,
        customerRef,
        vehicleFetchType.ACTIVE
      )
  })
  const vehicles = vehiclesData || []

  const {
    data: userGroupsData,
    refetch: refetchUserGroups,
    isLoading: isLoadingUserGroups
  } = useQuery({
    queryKey: ['userGroupsQuery', customerRef],
    queryFn: () => userService.fetchUserGroups(customerRef, operatorRealm)
  })
  const userGroups = userGroupsData || []

  const {
    data: conversationData,
    refetch: refetchConversation,
    isLoading: isLoadingConversation
  } = useQuery({
    queryKey: ['conversations', operatorRealm, customerRef],
    queryFn: () => fetchConversationsByUserRef(operatorRealm, customerRef),
    enabled: !operatorRealm
  })
  const conversations = (conversationData && conversationData) || []

  const isDataLoading =
    subscribedServices.isLoading ||
    assignmentsQuery.isLoading ||
    vouchersQuery.isLoading ||
    isLoadingVehicles ||
    isLoadingUserGroups ||
    (isLoadingConversation && !operatorRealm)

  return {
    subscribedServices,
    vouchers,
    assignments,
    vehicles,
    userGroups,
    conversations,
    refetchConversation,
    refetchUserGroups,
    refetchVehicles,
    isDataLoading
  }
}
