import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Header, Tab } from 'semantic-ui-react'

import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import { messageCenterEnums } from '../../../services/utils/DTOEnums'
import PhoneNumberSelector from './PhoneNumberSelector'
import TargetGroupCreator from './TargetGroupCreator'
import TargetGroupSelector from './TargetGroupSelector'

export const StepTargetGroup = ({
  formValues,
  loading,
  onDataCompleted,
  onChangeValue
}) => {
  const intl = useIntl()
  const [targetGroupData, setTargetGroupData] = useState(null)
  const [phoneGroupData, setPhoneGroupData] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const duplicateTargetGroup = (targetGroup) => {
    if (targetGroup.targetType === messageCenterEnums.targetType.GROUPS) {
      setTargetGroupData({
        name: targetGroup.name,
        value: targetGroup.value,
        intervalEnabled: targetGroup.intervalEnabled,
        intervalType: targetGroup.intervalType,
        intervalValue: targetGroup.intervalValue
      })
      setActiveIndex(1)
      showLocalizedMoovyToast(intl, {
        title: 'messageCenter.targetGroup.action.copy.title',
        description: 'messageCenter.targetGroup.action.copy.description'
      })
    } else if (
      targetGroup.targetType === messageCenterEnums.targetType.PHONENUMBERS
    ) {
      setPhoneGroupData({ name: targetGroup.name, value: targetGroup.value })
      setActiveIndex(2)
      showLocalizedMoovyToast(intl, {
        title: 'messageCenter.targetGroup.action.copy.title',
        description: 'messageCenter.targetGroup.action.copy.description'
      })
    }
  }

  const getPanes = () => {
    const tabs = [
      {
        menuItem: intl.formatMessage({
          id: 'messageCenter.createMessage.step.targetGroup.tab.header.target'
        }),
        render: () => (
          <Tab.Pane>
            <TargetGroupSelector
              formValues={formValues}
              loading={loading}
              onDataCompleted={onDataCompleted}
              onChangeValue={onChangeValue}
              duplicateTargetGroup={duplicateTargetGroup}
            />
          </Tab.Pane>
        )
      },
      {
        menuItem: intl.formatMessage({
          id:
            'messageCenter.createMessage.step.targetGroup.tab.header.createTarget'
        }),
        render: () => (
          <Tab.Pane>
            <TargetGroupCreator targetGroupData={targetGroupData} />
          </Tab.Pane>
        )
      },
      {
        menuItem: intl.formatMessage({
          id:
            'messageCenter.createMessage.step.targetGroup.tab.header.createNumberGroup'
        }),
        render: () => (
          <Tab.Pane>
            <PhoneNumberSelector phoneGroupData={phoneGroupData} />
          </Tab.Pane>
        )
      }
    ]

    return tabs
  }

  return (
    <>
      <Header>
        <FormattedMessage id="messageCenter.createMessage.step.targetGroup.title" />
      </Header>
      <p>
        <FormattedMessage id="messageCenter.createMessage.step.targetGroup.pageDescription" />
      </p>
      <Tab
        onTabChange={(event, data) => {
          setTargetGroupData(null)
          setPhoneGroupData(null)
          setActiveIndex(data.activeIndex)
        }}
        activeIndex={activeIndex}
        panes={getPanes()}
      />
    </>
  )
}

export default StepTargetGroup
