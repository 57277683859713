import { fetchConversationsByUserRef } from '../../../services/Conversation'
import { useQuery } from '@tanstack/react-query'
import userService from '../../../services/User/UserServices'

export const getUserData = (operatorRealm, userRef) => {
  const {
    data: userGroupsData,
    refetch: refetchUserGroups,
    isLoading: isLoadingUserGroups
  } = useQuery({
    queryKey: ['userGroupsQuery', userRef],
    queryFn: () => userService.fetchUserGroups(userRef, operatorRealm)
  })
  const userGroups = userGroupsData || []

  const {
    data: conversationData,
    refetch: refetchConversation,
    isLoading: isLoadingConversation
  } = useQuery({
    queryKey: ['conversations', operatorRealm, userRef],
    queryFn: () => fetchConversationsByUserRef(operatorRealm, userRef),
    enabled: !operatorRealm
  })
  const conversations = conversationData || []

  const isDataLoading = isLoadingUserGroups || isLoadingConversation

  return {
    userGroups,
    conversations,
    refetchConversation,
    refetchUserGroups,
    isDataLoading
  }
}
