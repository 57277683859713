import {
  Button,
  Dimmer,
  Header,
  Image,
  Loader,
  Modal,
  Segment,
  Table
} from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import { MoovyPlaceholderLoader } from '../../components'
import React, { useEffect } from 'react'
import externalFacilityDataService from '../../services/ExternalFacilityData'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

export default function ExternalFacilityDataModal({
  open,
  sessionRef,
  onClose,
  onError,
  externalDataRef
}) {
  const { operatorRealm } = useAuthInfo()
  const facilityData = useQuery({
    queryKey: ['imageData', externalDataRef],
    queryFn: () =>
      externalFacilityDataService.fetchExternalFacilityData(
        externalDataRef,
        sessionRef,
        operatorRealm
      ),
    enabled: !!externalDataRef,
    staleTime: 1000 * 60 * 5
  })

  useEffect(() => {
    if (facilityData.error) {
      onError()
    }
  }, [facilityData.error])

  const data = facilityData.data ? facilityData.data : {}
  const metadata = data.metadata ? Object.entries(data.metadata) : []

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Content>
        <Modal.Description>
          {facilityData.isLoading ? (
            <Segment>
              <Dimmer active inverted>
                <Loader />
              </Dimmer>
              <MoovyPlaceholderLoader paragraphs={6} />
            </Segment>
          ) : (
            <>
              {data.imageAsBase64 ? (
                <Image src={`data:image/jpeg;base64,${data.imageAsBase64}`} />
              ) : (
                <Header>
                  <FormattedMessage id="externalFacilityModal.imageMissing" />
                </Header>
              )}
              {metadata.length ? (
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <FormattedMessage id="common.key" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <FormattedMessage id="common.value" />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {metadata.map((entry) => (
                      <Table.Row key={entry[0]}>
                        <Table.Cell>{entry[0]}</Table.Cell>
                        <Table.Cell>
                          {entry[1] === Object(entry[1])
                            ? JSON.stringify(entry[1])
                            : entry[1]}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              ) : null}
            </>
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
