import { Dimmer, Loader, Segment } from 'semantic-ui-react'
import GenericMessage, {
  createErrorMessage
} from '../../components/GenericMessage'
import React, { useEffect, useReducer } from 'react'

import EnterpriseCustomer from './EnterpriseCustomer'
import MoovyCustomer from './MoovyCustomer'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import userBlocklistService from '../../services/UserBlocklist'
import userService from '../../services/User/UserServices'

// State and Reducer for enterprise assignments state. Maybe this could be in Redux since we have it in project...
// But the state is pretty simple.
// This enables that user can navigate from enterprise user assignment to Moovy user and back without the assignments ui state being cleared.
// At some point we might have to look in to a more global ui state management, but this works in this case (MOOV-2842).
const initialEnterpriseAssignmentsState = {
  selectedRef: '',
  includeHistory: false,
  partialSearch: '',
  pagination: {
    activePage: 1,
    totalPages: 1,
    column: 'startTime',
    direction: 'DESC'
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'setCustomerRef':
      return { ...state, customerRef: action.payload }
    case 'setSelectedRef':
      return { ...state, selectedRef: action.payload }
    case 'toggleIncludeHistory':
      return { ...state, includeHistory: !state.includeHistory }
    case 'setPartialSearch':
      return { ...state, partialSearch: action.payload }
    case 'setPagination':
      return { ...state, pagination: action.payload }
    case 'reset':
      return { ...initialEnterpriseAssignmentsState }
    default:
      throw new Error()
  }
}

function Customers() {
  const [enterpriseState, dispatch] = useReducer(
    reducer,
    initialEnterpriseAssignmentsState
  )
  const enterpriseActions = {
    setSelectedRef: (ref) => dispatch({ type: 'setSelectedRef', payload: ref }),
    toggleIncludeHistory: () => dispatch({ type: 'toggleIncludeHistory' }),
    setPartialSearch: (partialSearch) =>
      dispatch({ type: 'setPartialSearch', payload: partialSearch }),
    setPagination: (data) => dispatch({ type: 'setPagination', payload: data })
  }

  const { operatorRealm } = useAuthInfo()
  const { customerRef } = useParams()

  const customer = useQuery({
    queryKey: ['customer', customerRef],
    queryFn: () => userService.fetchUser(customerRef, operatorRealm),
    refetchOnWindowFocus: false
  })

  const userBlockingPreventedQuery = useQuery({
    queryKey: ['blockingPreventedQuery', customerRef],
    queryFn: () =>
      userBlocklistService.fetchBlockingPrevented(customerRef, operatorRealm),
    enabled: !!customerRef,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (
      customer.data &&
      customer.data.role === 'ROLE_ENTERPRISE_USER' &&
      enterpriseState.customerRef !== customer.data.ref
    ) {
      dispatch({ type: 'reset' })
      dispatch({ type: 'setCustomerRef', payload: customer.data.ref })
    }
  }, [customer])

  if (customer.isLoading) {
    return (
      <Segment basic padded="very">
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      </Segment>
    )
  }
  if (customer.isError) {
    return (
      <Segment basic padded="very">
        <GenericMessage
          message={createErrorMessage(
            'Asiakkaan tietojen lataamisessa tapahtui virhe'
          )}
        />
      </Segment>
    )
  }

  const refreshUser = () => {
    customer.refetch()
    userBlockingPreventedQuery.refetch()
  }

  return (
    <>
      {customer.data.role === 'ROLE_ENTERPRISE_USER' ? (
        <EnterpriseCustomer
          customer={customer.data}
          parentState={enterpriseState}
          updateParentState={enterpriseActions}
          onRefreshUser={refreshUser}
        />
      ) : (
        <MoovyCustomer
          customer={customer.data}
          userBlockingPrevented={userBlockingPreventedQuery.data}
          updateCustomer={refreshUser}
          onRefreshUser={refreshUser}
        />
      )}
    </>
  )
}

export default Customers
