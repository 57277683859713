import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popup } from 'semantic-ui-react'
import React from 'react'
import { useIntl } from 'react-intl'

const ServiceWithTags = ({ parkingService }) => {
  const intl = useIntl()

  return (
    <>
      {parkingService?.hasTags && (
        <Popup
          content={intl.formatMessage({
            id: 'parkingTable.parkingProduct.specialParking'
          })}
          position="bottom center"
          trigger={
            <FontAwesomeIcon
              icon={['far', 'asterisk']}
              style={{ marginLeft: '5px' }}
              color="red"
            />
          }
        />
      )}
    </>
  )
}

export default ServiceWithTags
