import React, { useEffect, useState } from 'react'
import {
  adyenAccountHolderMainStatusEnum,
  settlementRecipientCompanyTypeEnum as companyTypeEnum
} from '../../../../services/utils/DTOEnums'

import AccountHolder from './accountHolder'
import { FormattedMessage } from 'react-intl'
import { Message } from 'semantic-ui-react'
import OnboardingLink from './onboardingLink'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import { useQuery } from '@tanstack/react-query'

export const stepAdyenEnum = {
  STEP_ACCOUNT_HOLDER: 'STEP_ACCOUNT_HOLDER',
  STEP_ONBOARDING_LINK: 'STEP_ONBOARDING_LINK'
}

export const accountHolderUIStatusEnum = {
  NONE: 'NONE',
  ACCOUNT_INITIALIZED: 'ACCOUNT_INITIALIZED',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  TOKEN_CREATED: 'TOKEN_CREATED'
}

const TabAdyenNew = ({ settlementRecipient }) => {
  const [accountHolder, setAccountHolder] = useState(null)
  const [accountHolderUIStatus, setAccountHolderUIStatus] = useState(
    accountHolderUIStatusEnum.NONE
  )
  const getAccountHolderUIStatus = (accountHolder) => {
    const accountInitialized =
      !!accountHolder?.accountHolderCode && !!accountHolder?.ref
    const accountCreated =
      accountInitialized &&
      accountHolder.mainStatus != adyenAccountHolderMainStatusEnum.NOT_CREATED
    const tokenCreated =
      accountInitialized && accountCreated && !!accountHolder?.onboardingToken
    if (tokenCreated) {
      return accountHolderUIStatusEnum.TOKEN_CREATED
    } else if (accountCreated) {
      return accountHolderUIStatusEnum.ACCOUNT_CREATED
    } else if (accountInitialized) {
      return accountHolderUIStatusEnum.ACCOUNT_INITIALIZED
    } else {
      return accountHolderUIStatusEnum.NONE
    }
  }

  const fetchAccountHolder = useQuery({
    queryKey: ['fetchAccountHolder', settlementRecipient?.ref || ''],
    queryFn: () =>
      settlementRecipientService.fetchAccountHolder(settlementRecipient?.ref),
    enabled: !!settlementRecipient.ref
  })

  useEffect(() => {
    if (fetchAccountHolder.isSuccess) {
      setAccountHolder(fetchAccountHolder.data)
      const uiStatus = getAccountHolderUIStatus(fetchAccountHolder.data)
      setAccountHolderUIStatus(uiStatus)
    }
  }, [fetchAccountHolder.isFetching])

  const RenderNoAdyenAllowed = () => {
    return (
      <Message error>
        <FormattedMessage id="tabAdyen.companyType.error" />
      </Message>
    )
  }

  return (
    <>
      {settlementRecipient.companyType === companyTypeEnum.NOT_DEFINED ? (
        <RenderNoAdyenAllowed />
      ) : (
        <>
          <AccountHolder
            settlementRecipient={settlementRecipient}
            queryAccountHolder={fetchAccountHolder}
            accountHolder={accountHolder}
            accountHolderUIStatus={accountHolderUIStatus}
            refetchAccountHolder={() => fetchAccountHolder.refetch()}
          />
          <OnboardingLink
            settlementRecipient={settlementRecipient}
            accountHolder={accountHolder}
            refetchAccountHolder={() => fetchAccountHolder.refetch()}
            accountHolderUIStatus={accountHolderUIStatus}
            queryAccountHolder={fetchAccountHolder}
          />
        </>
      )}
    </>
  )
}

export default TabAdyenNew
