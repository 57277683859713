import React from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'

import Toolbar from '../../../components/Toolbar'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import useAuthInfo from '../../../hooks/useAuthInfo'
import useGlobalUI from '../../../hooks/useGlobalUI'
import Routing from '../../../routing/Routing'
import locationService from '../../../services/Locations'
import {
  getTablePaginationInitialValues,
  getUpdatedPaginationSort
} from '../../../services/utils/tablePagination'
import getFilteredLocationData from './LocationFilterContent'
import LocationTable from './LocationTable'
import LocationToolbar from './LocationToolbar'
import { useNavigate } from 'react-router-dom'

const LocationsList = () => {
  const navigate = useNavigate()
  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const intl = useIntl()
  const locationsQuery = useQuery({
    queryKey: ['locations', operatorRealm],
    queryFn: () => locationService.fetchLocations(operatorRealm),
    staleTime: 1000 * 60 * 5
  })

  const [state, setState] = useGlobalUI({
    key: 'LocationsList',
    initialValue: {
      locationFilter: '',
      operatorRef: '',
      ...getTablePaginationInitialValues('sortByLocation')
    }
  })

  const mergeState = (values) => setState({ ...state, ...values })

  const breadcrumbs = isSuperOperator
    ? undefined
    : createBreadcrumbs([
        {
          text: intl.formatMessage({
            id: 'common.breadcrumbs.home'
          }),
          href: '/',
          active: true
        }
      ])

  const pageLimit = 20

  const onHandleSort = (clickedColumn) => () => {
    const { sortColumn, direction } = state
    mergeState(
      getUpdatedPaginationSort(clickedColumn, {
        sortColumn,
        direction
      })
    )
  }

  const onPageChange = (e, { activePage }) => {
    mergeState({ activePage })
  }

  const onSelectedLocation = (location) => {
    navigate(Routing.getLocationUrl(location.ref, isSuperOperator))
  }

  const { activePage, sortColumn, direction } = state
  const filteredAndSorted = getFilteredLocationData(
    locationsQuery.data?.content,
    state.locationFilter,
    state.operatorRef,
    {
      sortColumn,
      direction,
      pageLimit,
      activePage
    }
  )

  const { totalPages, rows } = filteredAndSorted

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'locationsList.locationToolbar.title'
        })}
        breadcrumbs={breadcrumbs}
        content={
          <LocationToolbar
            isSuperOperator={isSuperOperator}
            locationFilter={state.locationFilter}
            operatorFilter={state.operatorRef}
            setLocationFilter={(value) =>
              mergeState({ locationFilter: value, activePage: 1 })
            }
            setOperatorFilter={(value) =>
              mergeState({ operatorRef: value, activePage: 1 })
            }
          />
        }
      />
      <div className="Admin--Page--Content">
        <LocationTable
          locations={rows}
          onSelectedLocation={onSelectedLocation}
          onHandleSort={onHandleSort}
          onPageChange={onPageChange}
          pagination={{
            activePage,
            totalPages,
            sortColumn,
            direction
          }}
        />
      </div>
    </>
  )
}

export default LocationsList
