/* eslint react/no-array-index-key: 0 */
import React from 'react'
import { Table } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const KeyValueTable = ({ data }) => (
  <Table compact>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Avain</Table.HeaderCell>
        <Table.HeaderCell>Arvo</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {data.map(([key, value], index) => (
        <Table.Row key={index}>
          <Table.Cell>{key}</Table.Cell>
          <Table.Cell>{value}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

KeyValueTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.array).isRequired
}

export default KeyValueTable
