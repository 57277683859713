import '../Locations.scss'

import { FormattedMessage, useIntl } from 'react-intl'
import { Icon, List, Popup } from 'semantic-ui-react'
import {
  MoovyIconButton,
  MoovyLink,
  MoovyRefreshTime
} from '../../../components'
import { PermissionLocation, serviceType } from '../../../services/utils'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModalEditLocationCapacity from './ModalEditLocationCapacity'
import locationService from '../../../services/Locations'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import useAuthInfo from '../../../hooks/useAuthInfo'

const ServiceInfo = ({ loc, refresh }) => {
  const locationRef = loc?.ref
  const intl = useIntl()
  const { isSuperOperator, operatorRealm, roles } = useAuthInfo()
  const [editLocationCapacityModalOpen, setEditLocationCapacityModalOpen] =
    useState(false)

  const servicesQuery = useQuery({
    queryKey: ['locationServices', locationRef],
    queryFn: () =>
      locationService.fetchLocationServices(locationRef, operatorRealm),
    enabled: !!locationRef
  })
  const serviceQuotasQuery = useQuery({
    queryKey: ['serviceQuotas', locationRef],
    queryFn: () =>
      locationService.fetchServiceQuotas(locationRef, operatorRealm),
    enabled: false
  })
  const quotasFetched = serviceQuotasQuery.isSuccess
  const serviceQuotas = serviceQuotasQuery.data || []

  const paygService =
    servicesQuery.data &&
    servicesQuery.data.find(
      (service) =>
        service.type === serviceType.PAY_AS_YOU_GO &&
        (!service.tags || service.tags.length <= 0)
    )

  const serviceWithSpecialServiceFee =
    servicesQuery.data &&
    servicesQuery.data.find((service) => service.serviceFeeConfigurationDesc)

  const paygServiceQuota = serviceQuotas.find(
    (quota) => quota.type === 'NORMAL_PAY_AS_YOU_GO'
  )

  const prebookingQuota = serviceQuotas.find(
    (quota) => quota.type === 'SUBSCRIPTIONS_AND_PREBOOKINGS'
  )

  const totalQuota = serviceQuotas.find(
    (quota) => quota.type === 'LOCATION_TOTAL'
  )

  const { mutate: updateLocationCapacity, ...updateLocationCapacityMutation } =
    useMutation({
      mutationFn: (locationCapacityData) => {
        return locationService.updateLocationCapacity(
          locationCapacityData.locationRef,
          locationCapacityData
        )
      },
      onSuccess: () => {
        setEditLocationCapacityModalOpen(false)
        refresh()
        serviceQuotasQuery.refetch()
        showLocalizedMoovyToast(intl, {
          title: 'modalEditLocationCapacity.toast.update.title',
          description: 'modalEditLocationCapacity.toast.update.body'
        })
      }
    })

  const RenderCalculatedCapacity = ({ serviceQuota }) => {
    const quotaUsed = serviceQuota?.used || 0
    const quotaTotal = serviceQuota?.total

    const percentage =
      quotaTotal &&
      Math.round(quotaUsed > 0 ? (quotaUsed / quotaTotal) * 100 : 0)
    const pcs = intl.formatMessage({
      id: 'common.pcs'
    })

    return (
      <>
        {`${quotaUsed}${quotaTotal ? '/' + quotaTotal : ''} ${pcs} `}
        {Boolean(quotaTotal) && (
          <>
            (
            <FormattedMessage
              id="common.percentageValue"
              values={{ percentage }}
            />
            )
          </>
        )}
      </>
    )
  }

  const RenderUpdateAt = () => {
    return (
      <>
        <MoovyRefreshTime
          updatedAtAsMillis={serviceQuotasQuery?.dataUpdatedAt}
          loading={serviceQuotasQuery?.isLoading}
          noPadding
        />{' '}
        <FormattedMessage id="serviceInfo.popup.content" />
      </>
    )
  }

  const RenderSpecialServiceFee = ({ desc }) => {
    return (
      <>
        <List.Item>
          <div>
            <b>
              <FormattedMessage id="serviceInfo.label.specialServiceFeeConfig" />
            </b>
            {desc}
            <FontAwesomeIcon
              style={{ marginLeft: '5px' }}
              icon={['far', 'asterisk']}
              color="red"
            />
          </div>
        </List.Item>
      </>
    )
  }

  const RenderPAYGCapacity = () => {
    return (
      <List.Item>
        <div>
          <b>
            <FormattedMessage id="serviceInfo.label.shortParkingPlaces" />
          </b>{' '}
          <RenderCalculatedCapacity serviceQuota={paygServiceQuota} />
        </div>
      </List.Item>
    )
  }

  const RenderLocationCapacity = ({ total, current, warningIconThreshold }) => {
    if (!total) {
      return <FormattedMessage id="serviceInfo.popup.notDefined" />
    }
    const totalData = `${current}/${total} ${intl.formatMessage({
      id: 'common.pcs'
    })}`
    const percentage = Math.round(current > 0 ? (current / total) * 100 : 0)
    return (
      <>
        {`${totalData} `}
        (
        <FormattedMessage id="common.percentageValue" values={{ percentage }} />
        )
        {percentage >= warningIconThreshold && (
          <Popup
            trigger={
              <Icon
                style={{ paddingLeft: '5px' }}
                color="yellow"
                name="warning sign"
              />
            }
            content={
              <FormattedMessage
                id="serviceInfo.popup.limitReached"
                values={{ percentage }}
              />
            }
            position="bottom right"
          />
        )}
      </>
    )
  }

  const RenderPlaceInfo = () => {
    const warningIconThreshold = 85
    const initialLocationCapacity = loc.locationCapacity ? (
      `${loc.locationCapacity} ${intl.formatMessage({ id: 'common.pcs' })}`
    ) : (
      <FormattedMessage id="serviceInfo.popup.notDefined" />
    )
    return (
      <>
        <List.Item>
          <div>
            <b>
              <FormattedMessage id="serviceInfo.label.locationPlaces" />
            </b>{' '}
            {quotasFetched ? (
              <RenderLocationCapacity
                total={totalQuota?.total}
                current={totalQuota?.used}
                warningIconThreshold={warningIconThreshold}
              />
            ) : (
              <span>{initialLocationCapacity}</span>
            )}
            {PermissionLocation.HasEditLocationCapacity(
              isSuperOperator,
              roles
            ) ? (
              <MoovyIconButton
                iconName={'edit'}
                onClick={() => setEditLocationCapacityModalOpen(true)}
                padding={{ left: '5px' }}
                content={
                  <FormattedMessage
                    id="serviceInfo.button.editLocationCapacity"
                    values={{ percentage: warningIconThreshold }}
                  />
                }
              />
            ) : (
              ''
            )}
          </div>
        </List.Item>
        <List.Item>
          <div>
            <MoovyLink
              style={{ marginLeft: '0px', paddingLeft: '0px' }}
              onClick={() => serviceQuotasQuery.refetch()}
            >
              <MoovyIconButton
                operation={serviceQuotasQuery}
                iconName={'refresh'}
                onClick={() => serviceQuotasQuery.refetch()}
                padding={{ right: '5px' }}
                content={<RenderUpdateAt />}
              />
              <FormattedMessage id="serviceInfo.link.refreshPlaces" />
            </MoovyLink>
          </div>
        </List.Item>
      </>
    )
  }

  const RenderQuotas = () => (
    <>
      {paygService && <RenderPAYGCapacity />}
      {prebookingQuota && (
        <List.Item>
          <div>
            <b>
              <FormattedMessage id="serviceInfo.label.prebookingParkingPlaces" />
            </b>{' '}
            <RenderCalculatedCapacity serviceQuota={prebookingQuota} />
          </div>
        </List.Item>
      )}
    </>
  )

  return (
    <List>
      {serviceWithSpecialServiceFee && (
        <RenderSpecialServiceFee
          desc={serviceWithSpecialServiceFee?.serviceFeeConfigurationDesc}
        />
      )}
      {quotasFetched ? (
        <RenderQuotas />
      ) : (
        <List.Item>
          <b>
            <FormattedMessage id="serviceInfo.popup.notFetched" />
          </b>
        </List.Item>
      )}
      <RenderPlaceInfo />
      {(editLocationCapacityModalOpen && (
        <ModalEditLocationCapacity
          location={loc}
          onClose={() => {
            updateLocationCapacityMutation.reset()
            setEditLocationCapacityModalOpen(false)
          }}
          onSubmit={updateLocationCapacity}
          mutation={updateLocationCapacityMutation}
        />
      )) ||
        ''}
    </List>
  )
}

export default ServiceInfo
