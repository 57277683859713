import PropTypes from 'prop-types'
import React from 'react'
import { Placeholder } from 'semantic-ui-react'

const MoovyPlaceholderLoader = ({ paragraphs, fluid }) => (
  <Placeholder fluid={fluid}>
    {Array.from({ length: paragraphs }, (v, i) => i).map((i) => (
      <Placeholder.Paragraph key={i}>
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Paragraph>
    ))}
  </Placeholder>
)

MoovyPlaceholderLoader.propTypes = {
  paragraphs: PropTypes.number.isRequired,
  fluid: PropTypes.bool
}

export default MoovyPlaceholderLoader
