import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../components'
import React from 'react'

const validationSchema = () => {
  return yup.object().shape({ name: yup.string().required('Cannot be empty') })
}

const ModalEditName = ({ onClose, service, onSubmit, mutation }) => {
  const intl = useIntl()

  const initialFormikData = ({ name }) => {
    return {
      name
    }
  }

  const hasValueChanged = (name) => {
    return service.name !== name
  }

  const handleFormitSubmit = ({ name }) => {
    if (hasValueChanged(name)) {
      onSubmit({
        serviceRef: service.ref,
        name
      })
    } else {
      onClose()
      return
    }
  }

  return (
    <Formik
      initialValues={initialFormikData(service)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'modalEditName.title'
              },
              {
                name: service.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="modalEditName.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'modalEditName.label.name'
                })}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="name"
                error={!!errors.name}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={mutation.isPending || !hasValueChanged(values.name)}
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditName
