import { Button, Grid, GridColumn, Header, Input } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { MutationErrorMessage } from '../../../../components'
import React from 'react'
import { accountHolderUIStatusEnum } from './tabAdyenNew'
import { copyToClipboard } from '../../../../services/utils/clipboard'
import { formatDateAndTime } from '../../../../services/utils'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import { useMutation } from '@tanstack/react-query'

const OnboardingLink = ({
  settlementRecipient,
  accountHolder,
  accountHolderUIStatus,
  refetchAccountHolder,
  queryAccountHolder
}) => {
  const intl = useIntl()

  const { mutate: createOnboardingToken, ...createOnboardingTokenMutation } =
    useMutation({
      mutationFn: () => {
        return settlementRecipientService.createOnboardingToken(
          settlementRecipient?.ref
        )
      },
      onSuccess: () => {
        showLocalizedMoovyToast(intl, {
          title: 'stepOnboardingLink.toast.action.onboardingTokenCreate.title',
          description:
            'stepOnboardingLink.toast.action.onboardingTokenCreate.description'
        })
        refetchAccountHolder()
      }
    })

  const RenderShowLinkOrCreateLink = () => {
    if (accountHolder?.onboardingToken) {
      return (
        <>
          <p>
            <FormattedMessage id="stepOnboardingLink.toast.action.onboardingToken.valid" />
            {` ${formatDateAndTime(
              accountHolder?.onboardingToken?.validUntil
            )}`}
          </p>
          <Input
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'copy',
              content: intl.formatMessage({
                id: 'stepOnboardingLink.button.copyLink'
              }),
              onClick: () =>
                copyToClipboard(
                  intl,
                  accountHolder?.onboardingToken?.onboardingURL,
                  intl.formatMessage({
                    id: 'stepOnboardingLink.toast.copiedToClipboard.text'
                  })
                )
            }}
            defaultValue={accountHolder?.onboardingToken?.onboardingURL}
            fluid
          />
          <br />
          <p>
            <FormattedMessage id="onboardingLink.description.create" />
          </p>
          <Button
            primary
            onClick={() => createOnboardingToken()}
            disabled={
              createOnboardingTokenMutation.isPending ||
              queryAccountHolder.isPending
            }
            loading={
              createOnboardingTokenMutation.isPending ||
              queryAccountHolder.isPending
            }
          >
            <FormattedMessage id="onboardingLink.button.create" />
          </Button>
        </>
      )
    } else if (accountHolderUIStatus !== accountHolderUIStatusEnum.NONE) {
      return (
        <>
          <p>
            <FormattedMessage id="stepOnboardingLink.description.onboardingToken" />
          </p>

          <Button
            primary
            onClick={() => createOnboardingToken()}
            disabled={
              createOnboardingTokenMutation.isPending ||
              queryAccountHolder.isPending
            }
            loading={
              createOnboardingTokenMutation.isPending ||
              queryAccountHolder.isPending
            }
          >
            <FormattedMessage id="onboardingLink.button.create" />
          </Button>
        </>
      )
    }
  }

  return (
    <>
      {accountHolderUIStatus !== accountHolderUIStatusEnum.NONE && (
        <Header as="h4">
          <FormattedMessage id="stepAccountHolder.header.onboardingToken" />
        </Header>
      )}
      <Grid>
        <GridColumn>
          <MutationErrorMessage
            mutation={createOnboardingTokenMutation}
            defaultErrorTextLangId={
              'stepOnboardingLink.onboardingTokenCreated.failed'
            }
            messageNegative
          />
          <MutationErrorMessage
            mutation={queryAccountHolder}
            defaultErrorTextLangId={
              'stepOnboardingLink.fetchOnboardingToken.failed'
            }
            messageNegative
          />
          <RenderShowLinkOrCreateLink />
        </GridColumn>
      </Grid>
    </>
  )
}

export default OnboardingLink
