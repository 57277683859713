import { Checkbox, Form, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  LicensePlateRecognitionIcon,
  MoovyLink,
  MoovyModalConfirmation,
  MoovyTextInput
} from '../../../../components'
import {
  PermissionUser,
  formatDateAndTime,
  sortedVehiclesByCameraRecognition
} from '../../../../services/utils'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import ModalLicensePlateRecognitionStateHistory from './modalLicensePlateRecognitionStateHistory'
import { fetchVehiclesByUserRef } from '../../../../services/Vehicles'
import recognitionService from '../../../../services/utils/license-plate-recognition'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import useAuthInfo from '../../../../hooks/useAuthInfo'
import { vehicleFetchType } from '../../../../services/utils/DTOEnums'

const UserVehicles = ({ customer, onRefetchVehicles }) => {
  if (!customer || !customer.ref) return

  const [selectedVehicle, setSelectedVehicle] = useState(null)
  const intl = useIntl()
  const { operatorRealm, isSuperOperator, roles } = useAuthInfo()
  const [showAllVehicles, setShowAllVehicles] = useState(false)
  const [licensePlateNumberFilter, setLicensePlateNumberFilter] = useState('')
  const [filteredVehicles, setFilteredVehicles] = useState([])
  const [confirmUpdateLpnRecognition, setConfirmUpdateLpnRecognition] =
    useState(null)

  const vehicles = useQuery({
    queryKey: ['vehicles', customer.ref, showAllVehicles],
    queryFn: () => {
      if (showAllVehicles) {
        return fetchVehiclesByUserRef(
          operatorRealm,
          customer.ref,
          vehicleFetchType.ALL
        )
      } else {
        return fetchVehiclesByUserRef(
          operatorRealm,
          customer.ref,
          vehicleFetchType.ACTIVE
        )
      }
    }
  })

  const { mutate: updateLpnRecognition, ...updateLpnRecognitionMutation } =
    useMutation({
      mutationFn: ({ ref, checked }) =>
        recognitionService.toggleRecognition({ ref }, checked),
      onSuccess: () => {
        setConfirmUpdateLpnRecognition(null)
        vehicles.refetch()
        onRefetchVehicles()
        showLocalizedMoovyToast(intl, {
          title:
            'customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.success.title',
          description:
            'customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.success.body'
        })
      }
    })

  useEffect(() => {
    if (licensePlateNumberFilter.length > 0 && vehicles.data) {
      setFilteredVehicles(
        vehicles.data.filter((vehicle) =>
          vehicle.licensePlateNumber
            .toLocaleLowerCase()
            .includes(
              licensePlateNumberFilter.replace('-', '').toLocaleLowerCase()
            )
        )
      )
    } else {
      setFilteredVehicles(vehicles.data || [])
    }
  }, [vehicles.dataUpdatedAt, licensePlateNumberFilter])

  return (
    <>
      <Form>
        <Form.Field width={3}>
          <label>
            <FormattedMessage id="customers.detail.tab.vehicles.filter.licensePlateNumber" />
          </label>
          <MoovyTextInput
            value={licensePlateNumberFilter}
            onChange={(e) => setLicensePlateNumberFilter(e.target.value)}
            onClear={() => setLicensePlateNumberFilter('')}
            placeholder={intl.formatMessage({
              id: 'customers.detail.tab.vehicles.filter.licensePlateNumber.placeholder'
            })}
          />
        </Form.Field>
        <Form.Field>
          <Checkbox
            label={intl.formatMessage({
              id: 'customers.detail.tab.vehicles.filter.showDeletedVehicles'
            })}
            onChange={(e, data) => setShowAllVehicles(data.checked)}
          />
        </Form.Field>
      </Form>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="common.vehicles.licensePlateNumber" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="common.vehicles.licensePlateRecognition" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="customers.detail.tab.vehicles.table.creationTime" />
            </Table.HeaderCell>
            {showAllVehicles && (
              <Table.HeaderCell>
                <FormattedMessage id="customers.detail.tab.vehicles.table.deletionTime" />
              </Table.HeaderCell>
            )}
            {PermissionUser.HasVehicleDetails(roles) && <Table.HeaderCell />}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredVehicles &&
            sortedVehiclesByCameraRecognition(filteredVehicles).map(
              (vehicle) => (
                <Table.Row verticalAlign="middle" key={vehicle.ref}>
                  <Table.Cell>{vehicle.licensePlateNumber}</Table.Cell>
                  <Table.Cell onClick={(event) => event.stopPropagation()}>
                    <LicensePlateRecognitionIcon
                      cameraRecognitionAllowed={
                        vehicle.licensePlateRecognitionPermissions
                          ?.allowLicensePlateBasedParkingFacilityAccess
                      }
                    />
                    {isSuperOperator && !vehicle.deletionTime && (
                      <Checkbox
                        fitted
                        toggle
                        checked={
                          vehicle.licensePlateRecognitionPermissions
                            ?.allowLicensePlateBasedParkingFacilityAccess
                        }
                        onChange={(e, { checked }) => {
                          setConfirmUpdateLpnRecognition({
                            ref: vehicle.ref,
                            checked,
                            licensePlate: vehicle.licensePlateNumber
                          })
                        }}
                        style={{ verticalAlign: 'middle' }}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateAndTime(vehicle.creationTime)}
                  </Table.Cell>
                  {showAllVehicles && (
                    <Table.Cell>
                      {formatDateAndTime(vehicle.deletionTime)}
                    </Table.Cell>
                  )}
                  {PermissionUser.HasVehicleDetails(roles) && (
                    <Table.Cell
                      collapsing
                      onClick={(event) => event.stopPropagation()}
                    >
                      <MoovyLink
                        bold
                        key={vehicle.ref}
                        onClick={() => setSelectedVehicle(vehicle)}
                      >
                        <FormattedMessage id="customers.detail.tab.vehicles.table.link.history" />
                      </MoovyLink>
                    </Table.Cell>
                  )}
                </Table.Row>
              )
            )}
        </Table.Body>
      </Table>
      {selectedVehicle && (
        <ModalLicensePlateRecognitionStateHistory
          userRef={customer.ref}
          onClose={() => setSelectedVehicle(null)}
          vehicleRef={selectedVehicle.ref}
        />
      )}
      <MoovyModalConfirmation
        open={!!confirmUpdateLpnRecognition}
        onClose={() => setConfirmUpdateLpnRecognition(null)}
        lang={{
          titleElement: (
            <FormattedMessage
              id="customers.detail.tab.vehicles.lpnRecognitionConfirmation.title"
              values={{
                licensePlate: confirmUpdateLpnRecognition?.licensePlate
              }}
            />
          ),
          bodyElement: (
            <>
              {confirmUpdateLpnRecognition?.checked ? (
                <FormattedMessage id="customers.detail.tab.vehicles.lpnRecognitionConfirmation.body.active" />
              ) : (
                <FormattedMessage id="customers.detail.tab.vehicles.lpnRecognitionConfirmation.body.inactive" />
              )}
              <div style={{ marginTop: '10px' }}>
                <b style={{ marginRight: '5px' }}>Uusi tila:</b>
                <LicensePlateRecognitionIcon
                  cameraRecognitionAllowed={
                    confirmUpdateLpnRecognition?.checked
                  }
                />
              </div>
            </>
          ),
          buttonConfirmKey: 'common.button.confirm',
          defaultErroKey:
            'customers.detail.tab.vehicles.lpnRecognitionConfirmation.action.error'
        }}
        onSubmit={() => updateLpnRecognition(confirmUpdateLpnRecognition)}
        mutation={updateLpnRecognitionMutation}
      />
    </>
  )
}

export default UserVehicles
