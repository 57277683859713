import { get, post, put, remove } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/${relativePath}`
    : `/administration/${relativePath}`

const fetchUserVouchers = async (userRef, operatorRealm) => {
  if (userRef) {
    try {
      const result = await get(
        createFullPath(`user/${userRef}/vouchers`, operatorRealm)
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing userRef for fetching vouchers'))
}

const fetchVouchersByFilter = async (operatorRealm, queryParams) => {
  try {
    const result = await get(
      createFullPath(`voucherTemplate/grantedVouchers`, operatorRealm),
      queryParams
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchVoucherInfo = async (voucherRef, operatorRealm) => {
  if (voucherRef) {
    try {
      const result = await get(
        createFullPath(`vouchers/${voucherRef}`, operatorRealm)
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing voucherRef for fetching voucher'))
}

const updateVoucher = async (voucherRef, operatorRealm, updateData) => {
  if (voucherRef) {
    try {
      const result = await put(
        createFullPath(`vouchers/${voucherRef}`, operatorRealm),
        updateData
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing voucherRef for updating voucher'))
}

const grantVoucher = (voucherData, templateRef, operatorRealm) => {
  return post(
    createFullPath(`voucherTemplate/${templateRef}/grant`, operatorRealm),
    voucherData
  )
}

const deleteUnusedVoucher = (voucherRef) => {
  return remove(createFullPath(`vouchers/${voucherRef}`, null))
}

export default {
  fetchUserVouchers,
  fetchVoucherInfo,
  updateVoucher,
  grantVoucher,
  deleteUnusedVoucher,
  fetchVouchersByFilter
}
