import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Confirm, Icon, Step } from 'semantic-ui-react'

import { MutationErrorMessage, Toolbar } from '../../../components'
import { showMoovyToast } from '../../../components/MoovyToast'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import serviceService from '../../../services/Services'
import ConclusionForm from '../Management/ConclusionForm'
import FinishPage, { finishePageType } from '../Management/FinishPage'
import OperatorForm from '../Management/OperatorForm'
import ServiceForm from '../Management/ServiceForm'
import ServicePricingForm from '../Management/ServicePricingForm'
import StartingForm from '../Management/StartingForm'
import LocationForm from './LocationForm'

export const ManagementNewService = ({}) => {
  const breadcrumbs = createBreadcrumbs([
    { text: 'Koti', href: '/admin' },
    { text: 'Hallinta' },
    { text: 'Uusi tuote' }
  ])

  const STEP_KEYS = {
    STARTING: 'starting',
    OPERATOR: 'operator',
    LOCATION: 'location',
    SERVICE: 'service',
    TARIFF: 'tariff',
    CONCLUSION: 'conclusion'
  }

  const [importConfirmOpen, setImportConfirmOpen] = useState(false)
  const [formValues, setFormValues] = useState({})
  const [activeStep, setActiveStep] = useState(STEP_KEYS.STARTING)
  const [finishPageContent, setFinishPageContent] = useState(null)
  const [stepsStatus, setStepsStatus] = useState([
    {
      name: STEP_KEYS.STARTING,
      icon: 'copy',
      title: 'Tuotepohja',
      description: 'Tuo valmis pohja',
      completed: false
    },
    {
      name: STEP_KEYS.OPERATOR,
      icon: 'user',
      title: 'Operaattori',
      description: 'Operaattorin tiedot',
      completed: false
    },
    {
      name: STEP_KEYS.LOCATION,
      icon: 'map',
      title: 'Kohde',
      description: 'Kohteen tiedot',
      completed: false
    },
    {
      name: STEP_KEYS.SERVICE,
      icon: 'shopping cart',
      title: 'Tuote',
      description: 'Tuotteen tiedot',
      completed: false
    },
    {
      name: STEP_KEYS.TARIFF,
      completed: false,
      icon: 'credit card',
      title: 'Tariffi',
      description: 'Tuotteen hinnoittelu'
    },
    {
      name: STEP_KEYS.CONCLUSION,
      completed: false,
      icon: 'info',
      title: 'Yhteenveto',
      description: 'Lisää kokonaisuus'
    }
  ])

  const { mutate: importService, ...importServiceMutation } = useMutation({
    mutationFn: () =>
      serviceService.createService({
        ...formValues
      }),
    onSuccess: (response) => {
      showMoovyToast(
        'Uusi tuote luotu',
        'Uusi tuote luotu annetuilla tiedoilla'
      )
      updateCompleted(STEP_KEYS.CONCLUSION, response.ref)
      clearFormData()
      setFinishPageContent({ ref: response.ref, name: response.name })
    }
  })

  const onStepClicked = (e, { name }) => {
    setActiveStep(name)
  }

  const resetCompleted = (stepName) => {
    const newStatusArray = stepsStatus.map((item) => ({
      ...item,
      completed: false
    }))
    if (stepName) {
      const item = newStatusArray.findIndex((item) => item.name === stepName)
      newStatusArray[item].completed = true
    }
    activeNextStep(newStatusArray)
    setStepsStatus(newStatusArray)
  }

  const updateCompleted = (stepName) => {
    const newStatusArray = [...stepsStatus]
    const item = newStatusArray.findIndex((item) => item.name === stepName)
    newStatusArray[item].completed = true
    const startingItem = newStatusArray.findIndex(
      (item) => item.name === STEP_KEYS.STARTING
    )
    newStatusArray[startingItem].completed = true
    activeNextStep(newStatusArray)
    setStepsStatus(newStatusArray)
  }

  const activeNextStep = (statusArray) => {
    const nextStep = statusArray.find((item) => item.completed === false)
    if (nextStep) {
      setActiveStep(nextStep.name)
    }
  }

  const clearFormData = () => {
    setFormValues({})
    resetCompleted()
  }

  const isAllCompleted = (statusArray, stepName) => {
    return statusArray.find(
      (item) => item.completed === false && item.name !== stepName
    )
      ? false
      : true
  }

  const onDataCompleted = (data, stepName) => {
    let bindToFormData = { ...formValues }
    switch (stepName) {
      case STEP_KEYS.STARTING:
        resetCompleted(stepName)
        setFormValues({ ...data })
        break
      case STEP_KEYS.OPERATOR:
        updateCompleted(stepName)
        setFormValues({ ...data })
        break
      case STEP_KEYS.LOCATION:
        updateCompleted(stepName)
        bindToFormData.location = data
        setFormValues(bindToFormData)
        break
      case STEP_KEYS.SERVICE:
        updateCompleted(stepName)
        bindToFormData.service = data
        setFormValues(bindToFormData)
        break
      case STEP_KEYS.TARIFF:
        updateCompleted(stepName)
        bindToFormData.servicePricing = data
        setFormValues(bindToFormData)
        break
      case STEP_KEYS.CONCLUSION:
        if (isAllCompleted(stepsStatus, stepName)) {
          setImportConfirmOpen(true)
        } else {
          activeNextStep(stepsStatus)
        }
        break
      default:
        break
    }
  }

  const showContent = (stepName) => {
    switch (stepName) {
      case STEP_KEYS.STARTING:
        return <StartingForm onDataCompleted={onDataCompleted}></StartingForm>
      case STEP_KEYS.OPERATOR:
        return (
          <OperatorForm
            onDataCompleted={onDataCompleted}
            formValues={formValues}
          ></OperatorForm>
        )
      case STEP_KEYS.LOCATION:
        return (
          <LocationForm
            onDataCompleted={onDataCompleted}
            formValues={formValues.location}
            operatorRef={formValues.operatorRef}
          ></LocationForm>
        )
      case STEP_KEYS.SERVICE:
        return (
          <ServiceForm
            onDataCompleted={onDataCompleted}
            formValues={formValues.service}
          ></ServiceForm>
        )
      case STEP_KEYS.TARIFF:
        return (
          <ServicePricingForm
            onDataCompleted={onDataCompleted}
            formValues={formValues.servicePricing}
          ></ServicePricingForm>
        )
      case STEP_KEYS.CONCLUSION:
        return (
          <ConclusionForm
            onDataCompleted={onDataCompleted}
            formValues={formValues}
          ></ConclusionForm>
        )

      default:
        return <div></div>
    }
  }

  return (
    <>
      <Toolbar breadcrumbs={breadcrumbs} title="Lisää uusi tuote" />
      <div className="Admin--Page--Content">
        <MutationErrorMessage
          mutation={importServiceMutation}
          errorTitleTextLangId="management.create.service.action.title.failed"
          defaultErrorTextLangId="management.create.service.action.description.failed"
          messageNegative
          showRawErrorMessage
        />
        {finishPageContent ? (
          <FinishPage
            finishPageContent={finishPageContent}
            pageType={finishePageType.SERVICE}
            onCreateNew={() => setFinishPageContent(null)}
          ></FinishPage>
        ) : (
          <Step.Group>
            {stepsStatus.map((item) => (
              <Step
                key={item.name}
                name={item.name}
                active={activeStep === item.name}
                completed={item.completed}
                onClick={onStepClicked}
              >
                <Icon name={item.icon} />
                <Step.Content>
                  <Step.Title>{item.title}</Step.Title>
                  <Step.Description>{item.description}</Step.Description>
                </Step.Content>
              </Step>
            ))}
          </Step.Group>
        )}
        {!finishPageContent && showContent(activeStep)}
      </div>
      <Confirm
        open={importConfirmOpen}
        onCancel={() => setImportConfirmOpen(false)}
        onConfirm={() => {
          setImportConfirmOpen(false)
          importService()
        }}
        content="Luodaanko uusi tuote annetuilla tiedoilla?"
        cancelButton="Peruuta"
        confirmButton="Kyllä"
      />
    </>
  )
}

export default ManagementNewService
