import { Grid, Header, Icon } from 'semantic-ui-react'
import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import ModalUserPermission from './modalUserPermission'
import { MoovyButton } from '../../../../components'
import PauseAndBlocklistTable from './pauseAndBlocklistTable'
import moovyPagination from '../../../../components/TablePagination/pagination'
import useAuthInfo from '../../../../hooks/useAuthInfo'
import useGlobalUI from '../../../../hooks/useGlobalUI'
import { useQuery } from '@tanstack/react-query'
import userBlocklistService from '../../../../services/UserBlocklist'

const UserBlockingHistory = ({
  userRef,
  userBlockingPrevented,
  userBlockListStatus,
  updateCustomer,
  activeBlockListEntry
}) => {
  const [userPermissionModalOpen, setUserPermissionModalOpen] = useState(false)
  const { isSuperOperator } = useAuthInfo()

  const pageLimit = 20

  const [state, setState] = useGlobalUI({
    key: 'pauseAndBlocklistItems',
    initialValue: {
      ...moovyPagination.getInitialValues('sortByBegin'),
      direction: moovyPagination.directions.DESC
    }
  })

  const pauseAndBlocklistQuery = useQuery({
    queryKey: [
      'pauseAndBlocklist',
      state.activePage,
      state.sort,
      state.direction,
      userBlockListStatus,
      activeBlockListEntry
    ],
    queryFn: () => {
      const queryParams = {
        page: state.activePage - 1,
        limit: pageLimit,
        sort: state.sort,
        direction: state.direction
      }
      return userBlocklistService.fetchPauseAndBlocklistHistory(
        userRef,
        queryParams
      )
    }
  })

  const pauseAndBlocklistItems =
    pauseAndBlocklistQuery.data?.content?.map((item, index) => {
      return { ...item, index }
    }) || []

  const totalPages = moovyPagination.getTotalPages(
    pauseAndBlocklistQuery.data?.pageable
  )

  const onPageChange = (e, { activePage }) => {
    setState({
      ...state,
      activePage
    })
  }

  const setSort = (sort, direction) => {
    setState({ ...state, sort, direction })
  }

  return (
    <>
      <br />
      <Grid>
        <Grid.Column floated="left" width={8}>
          <Header>
            <FormattedMessage id="customers.detail.tab.userBlockingPreventedList.title" />
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          {isSuperOperator && (
            <MoovyButton onClick={() => setUserPermissionModalOpen(true)}>
              {userBlockingPrevented ? (
                <>
                  <Icon color="yellow" name="exclamation triangle" />
                  <FormattedMessage id="customers.detail.tab.userBlockingPreventedList.button.unblock" />
                </>
              ) : (
                <>
                  <FormattedMessage id="customers.detail.tab.userBlockingPreventedList.button.block" />
                </>
              )}
            </MoovyButton>
          )}
        </Grid.Column>
      </Grid>

      <PauseAndBlocklistTable
        pauseAndBlocklistItems={pauseAndBlocklistItems}
        pauseAndBlocklistQuery={pauseAndBlocklistQuery}
        onPageChange={onPageChange}
        totalPages={totalPages}
        activePage={state.activePage}
        sorting={{
          sort: state.sort,
          direction: moovyPagination.directions.stringValues[state.direction]
        }}
        onSort={setSort}
      />

      <ModalUserPermission
        open={userPermissionModalOpen}
        userRef={userRef}
        userBlockingPrevented={userBlockingPrevented}
        onClose={() => setUserPermissionModalOpen(false)}
        onSubmit={() => {
          setUserPermissionModalOpen(false)
          updateCustomer()
          pauseAndBlocklistQuery.refetch()
        }}
      />
    </>
  )
}

export default UserBlockingHistory
