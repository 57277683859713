import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

import useAuthInfo from '../../hooks/useAuthInfo'

export default function Logout({ keycloak }) {
  const { isAuthenticated } = useAuthInfo()
  const [loggingOut, setLoggingOut] = useState(true)
  useEffect(() => {
    if (isAuthenticated && keycloak) {
      keycloak.logout().then(() => {
        setLoggingOut(false)
        sessionStorage.removeItem('authToken')
      })
    } else {
      setLoggingOut(false)
    }
  }, [keycloak])

  return loggingOut ? null : <Navigate to="/" replace />
}
