import { get, post, put, remove } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/services${relativePath}`
    : `/administration/services${relativePath}`

export default {
  fetchServices: (operatorRealm) => {
    try {
      return get(createFullPath('', operatorRealm))
    } catch (e) {
      return Promise.reject(Error(e))
    }
  },
  fetchService: (serviceRef, operatorRealm) => {
    if (serviceRef) {
      try {
        return get(createFullPath(`/${serviceRef}`, operatorRealm))
      } catch (e) {
        return Promise.reject(Error(e))
      }
    }
    return Promise.reject(Error('no service ref provided'))
  },
  fetchServicesByType: (operatorRealm, serviceType) => {
    try {
      return get(
        `${createFullPath('/serviceType', operatorRealm)}/${serviceType}`
      )
    } catch (e) {
      return Promise.reject(e)
    }
  },
  fetchSubscriptionServices: (operatorRealm) => {
    // This one has a different path
    const urlPrefix = operatorRealm
      ? `/operator/${operatorRealm}`
      : `/administration`

    try {
      return get(`${urlPrefix}/subscriptionServices`)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  fetchActiveSubscribedServices: (serviceRef, operatorRealm, params) => {
    try {
      return get(
        createFullPath(
          `/${serviceRef}/subscribedServices/active`,
          operatorRealm
        ),
        params
      )
    } catch (e) {
      return Promise.reject(Error(e))
    }
  },
  createService: (data) => {
    return post(createFullPath(`/create`, null), data)
  },
  updateServiceValidityTimes: (serviceRef, scheduleJSON) => {
    return post(createFullPath(`/${serviceRef}/validityTimes`, null), {
      ...scheduleJSON
    })
  },
  testServiceValidityTimes: ({ validityTimes, candidateTimes }) => {
    return post(createFullPath(`/validityTimes/test`, null), {
      validityTimes,
      candidateTimes
    })
  },
  addLocationToService: (serviceRef, locationRef) => {
    return post(createFullPath(`/${serviceRef}/locations/${locationRef}`, null))
  },
  removeLocationFromService: (serviceRef, locationRef) => {
    return remove(
      createFullPath(`/${serviceRef}/locations/${locationRef}`, null)
    )
  },
  fetchLinkedEvChargingService: (serviceRef, operatorRealm) => {
    try {
      return get(
        createFullPath(`/${serviceRef}/evChargingService`, operatorRealm)
      )
    } catch (e) {
      return Promise.reject(Error(e))
    }
  },
  updateServiceCapacities: (operatorRealm, subscriptionServiceRef, data) => {
    return put(
      createFullPath(`/${subscriptionServiceRef}/capacities`, operatorRealm),
      data
    )
  },
  updateExceedingParkingCapacity: (operatorRealm, serviceRef, data) => {
    return put(
      createFullPath(`/${serviceRef}/allowOvercapacity`, operatorRealm),
      data
    )
  },
  updateServiceDescription: (subscriptionServiceRef, data) => {
    try {
      return put(
        createFullPath(`/${subscriptionServiceRef}/description`, null),
        data
      )
    } catch (e) {
      return Promise.reject(Error(e))
    }
  },
  updateOverBooking: (serviceRef, overbookingCoefficient) => {
    return put(createFullPath(`/${serviceRef}/overbooking`, null), {
      overbookingCoefficient
    })
  },
  updateVisibility: (serviceRef, visibility) => {
    return put(createFullPath(`/${serviceRef}/visibility`, null), {
      visibility
    })
  },
  updateName: (serviceRef, name) => {
    return put(createFullPath(`/${serviceRef}/name`, null), {
      name
    })
  }
}
