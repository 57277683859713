import { Icon, List } from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import React from 'react'

const LocationFeatureList = ({ location }) => {
  const BoolIcon = ({ enabled }) =>
    enabled ? (
      <Icon color="green" name="check circle" />
    ) : (
      <Icon name="circle outline" />
    )

  return (
    <List>
      <List.Item disabled={!location?.anonymousParkingSupported}>
        <BoolIcon enabled={location?.anonymousParkingSupported} />{' '}
        <FormattedMessage id="locations.toolbar.checkbox.anonymousParking" />
      </List.Item>
      <List.Item disabled={!location?.licensePlateRecognitionSupported}>
        <BoolIcon enabled={location?.licensePlateRecognitionSupported} />{' '}
        <FormattedMessage id="locations.toolbar.checkbox.licensePlateRecognition" />
      </List.Item>
      <List.Item disabled={!location?.barrierlessFacility}>
        <BoolIcon enabled={location?.barrierlessFacility} />{' '}
        <FormattedMessage id="locations.toolbar.checkbox.barrierlessFacility" />
      </List.Item>
      <List.Item disabled={!location?.parkingControlled}>
        <BoolIcon enabled={location?.parkingControlled} />{' '}
        <FormattedMessage id="locations.toolbar.checkbox.parkingControlled" />
      </List.Item>
    </List>
  )
}

export default LocationFeatureList
