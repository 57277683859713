export const parseMessageToFrontend = (messageData, initialValues) => {
  let parsedMessage = {}

  if (messageData.targets) {
    parsedMessage.targetRefs = messageData.targets.map((item) => item.ref)
  } else {
    parsedMessage.targetRefs = []
  }

  if (messageData.messageContent) {
    parsedMessage.messageContent = {}
    messageData.messageContent.forEach((item) => {
      switch (item.language) {
        case 'fi':
          parsedMessage.messageContent.fi = item
          break
        case 'en':
          parsedMessage.messageContent.en = item
          break
        case 'sv':
          parsedMessage.messageContent.sv = item
          break
      }
    })
  } else {
    parsedMessage.messageContent = { ...initialValues.messageContent }
  }

  parsedMessage.sendingDetails = { ...initialValues.sendingDetails }

  if (messageData.sendingDetails) {
    parsedMessage.sendingDetails.expirationTime =
      messageData.sendingDetails.expirationTime
    parsedMessage.sendingDetails.publishTime =
      messageData.sendingDetails.publishTime
    parsedMessage.sendingDetails.pushMessage.send =
      messageData.sendingDetails.pushMessage?.send
    if (
      messageData.sendingDetails.pushMessage &&
      messageData.sendingDetails.pushMessage.content
    ) {
      messageData.sendingDetails.pushMessage.content.forEach((pushItem) => {
        switch (pushItem.language) {
          case 'fi':
            parsedMessage.sendingDetails.pushMessage.content.fi = {
              ...pushItem
            }
            break
          case 'en':
            parsedMessage.sendingDetails.pushMessage.content.en = {
              ...pushItem
            }
            break
          case 'sv':
            parsedMessage.sendingDetails.pushMessage.content.sv = {
              ...pushItem
            }
            break
        }
      })
    }
  }

  return parsedMessage
}

export const parseMessageToBackend = (messageStatus, formValues) => {
  // Change language specific content to the backend supported format.
  return {
    targetRefs: formValues.targetRefs,
    messageContent: [
      {
        title: formValues.messageContent.fi.title || undefined,
        description: formValues.messageContent.fi.description || undefined,
        imageUrl: formValues.messageContent.fi.imageUrl || undefined,
        videoUrl: formValues.messageContent.fi.videoUrl || undefined,
        callToAction: formValues.messageContent.fi.callToAction.type
          ? {
              description:
                formValues.messageContent.fi.callToAction.description ||
                undefined,
              type: formValues.messageContent.fi.callToAction.type || undefined,
              internal:
                formValues.messageContent.fi.callToAction.internal || undefined,
              external:
                formValues.messageContent.fi.callToAction.external || undefined
            }
          : undefined,
        language: 'fi'
      },
      {
        title: formValues.messageContent.en.title || undefined,
        description: formValues.messageContent.en.description || undefined,
        imageUrl: formValues.messageContent.en.imageUrl || undefined,
        videoUrl: formValues.messageContent.en.videoUrl || undefined,
        callToAction: formValues.messageContent.en.callToAction.type
          ? {
              description:
                formValues.messageContent.en.callToAction.description ||
                undefined,
              type: formValues.messageContent.en.callToAction.type || undefined,
              internal:
                formValues.messageContent.en.callToAction.internal || undefined,
              external:
                formValues.messageContent.en.callToAction.external || undefined
            }
          : undefined,
        language: 'en'
      },
      {
        title: formValues.messageContent.sv.title || undefined,
        description: formValues.messageContent.sv.description || undefined,
        imageUrl: formValues.messageContent.sv.imageUrl || undefined,
        videoUrl: formValues.messageContent.sv.videoUrl || undefined,
        callToAction: formValues.messageContent.sv.callToAction.type
          ? {
              description:
                formValues.messageContent.sv.callToAction.description ||
                undefined,
              type: formValues.messageContent.sv.callToAction.type || undefined,
              internal:
                formValues.messageContent.sv.callToAction.internal || undefined,
              external:
                formValues.messageContent.sv.callToAction.external || undefined
            }
          : undefined,
        language: 'sv'
      }
    ],
    sendingDetails: {
      status: messageStatus,
      publishTime: formValues.sendingDetails.publishTime,
      expirationTime: formValues.sendingDetails.expirationTime,
      pushMessage: {
        send: formValues.sendingDetails.pushMessage.send,
        content: [
          {
            ...formValues.sendingDetails.pushMessage.content.fi,
            language: 'fi'
          },
          {
            ...formValues.sendingDetails.pushMessage.content.en,
            language: 'en'
          },
          {
            ...formValues.sendingDetails.pushMessage.content.sv,
            language: 'sv'
          }
        ]
      }
    }
  }
}
