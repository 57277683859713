import { Button, Checkbox, Modal, Popup, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyLink, ParkingTypeLabel, ServiceWithTags } from '../../components'
import React, { useEffect, useState } from 'react'
import {
  tablePaginationDirections as directions,
  formatDateAndTime,
  formatPrice,
  formatSeconds,
  getFormattedLocationName,
  parkingTypeEnum,
  serviceType
} from '../../services/utils'

import EditLicensePlateModal from './EditLicensePlateModal'
import EndParkingBanner from './EndParkingBanner'
import EndParkingModal from './EndParkingModal'
import { LocalizedPaymentCardCategory } from '../MoovyLocalizedEnum'
import PropTypes from 'prop-types'
import Routing from '../../routing/Routing'
import { TablePagination } from '..'
import { locationShape } from '../../services/utils/shapes'
import parkingService from '../../services/Parking'
import { showMoovyToast } from '../MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

const ParkingDataTable = ({
  enableEndParking,
  sort,
  data,
  initiallySelectedParkingRef,
  onClickParking,
  onSorting,
  onPageChange,
  activePage,
  totalPages,
  listForLocation,
  refreshParkingData
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { isSuperOperator } = useAuthInfo()

  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([])
  const [errorDialog, setErrorDialog] = useState(false)
  const [endParkingItems, setEndParkingItems] = useState([])
  const [endParkingDialog, setEndParkingDialog] = useState(false)
  const [showLicensePlateModal, setShowLicensePlateModal] = useState(false)
  const [editableLicensePlateData, setEditableLicensePlateData] = useState(null)

  const { operatorRealm } = useAuthInfo()

  const { mutate: endParking, ...endParkingMutation } = useMutation({
    mutationFn: ({ parkingRefs, endTime, comment }) =>
      parkingService.endMultipleParking(
        parkingRefs,
        endTime,
        comment,
        'END_WITH_ERROR',
        operatorRealm
      ),
    onSuccess: () => {
      setEndParkingItems([])
      setEndParkingDialog(false)
      refreshParkingData()
      showMoovyToast(
        intl.formatMessage({
          id: 'parkingTable.endParking.toast.title'
        }),
        intl.formatMessage({
          id: 'parkingTable.endParking.toast.body'
        })
      )
    }
  })

  const { mutate: editLicensePlate, ...editLicensePlateMutation } = useMutation(
    {
      mutationFn: (licensePlateData) =>
        parkingService.editLicensePlate(
          licensePlateData.ref,
          {
            newLicensePlate: licensePlateData.licensePlate,
            comment: licensePlateData.comment
          },
          operatorRealm
        ),
      onSuccess: () => {
        refreshParkingData()
        setShowLicensePlateModal(false)
        setEditableLicensePlateData(null)
        showMoovyToast(
          intl.formatMessage({
            id: 'parkingTable.editLicensePlate.toast.title'
          }),
          intl.formatMessage({
            id: 'parkingTable.editLicensePlate.toast.body'
          })
        )
      }
    }
  )

  useEffect(() => {
    let resetSelected = true
    if (data) {
      const matchingItems = data.filter(
        (item) => selectedCheckBoxes.indexOf(item.ref) >= 0
      )
      resetSelected = matchingItems.length != selectedCheckBoxes.length
    }
    if (resetSelected) {
      setSelectedCheckBoxes([])
    }
  }, [data])

  const getValidAnonymousRef = (item) => {
    return (
      item.ongoing === true &&
      item.type === parkingTypeEnum.ANONYMOUS_PARKING &&
      item.ref
    )
  }

  const isLicensePlateEditAllowed = (parkingItem) => {
    return (
      parkingItem &&
      parkingItem.type === parkingTypeEnum.ANONYMOUS_PARKING &&
      parkingItem.ongoing
    )
  }

  const SelectAll = (checked) => {
    if (checked) {
      setSelectedCheckBoxes(data.map((item) => item.ref))
    } else {
      setSelectedCheckBoxes([])
    }
  }

  const selectAllAnonymous = () => {
    const anonymousParking = data.filter((item) => getValidAnonymousRef(item))

    if (anonymousParking) {
      setSelectedCheckBoxes(
        anonymousParking.map((item) => getValidAnonymousRef(item))
      )
    } else {
      setSelectedCheckBoxes([])
    }
  }

  const endAnonymousParkings = () => {
    const selectedEndParkingData = data.filter(
      (item) => selectedCheckBoxes.indexOf(item.ref) >= 0 && item
    )

    if (selectedEndParkingData.length > 0) {
      const validEndParkingData = selectedEndParkingData.filter((item) =>
        getValidAnonymousRef(item)
      )

      if (selectedEndParkingData.length != validEndParkingData.length) {
        setErrorDialog(true)
        return
      }

      setEndParkingItems(selectedEndParkingData)
      setEndParkingDialog(true)
    }
  }

  const CheckboxClicked = ({ value, checked }) => {
    if (checked) {
      setSelectedCheckBoxes([...selectedCheckBoxes, value])
    } else {
      const index = selectedCheckBoxes.indexOf(value)
      if (index > -1) {
        selectedCheckBoxes.splice(index, 1)
        setSelectedCheckBoxes([...selectedCheckBoxes])
      }
    }
  }

  const SelectCheckBox = ({ item }) => {
    const checked = !!selectedCheckBoxes.find(
      (selectedItem) => selectedItem === item.ref
    )
    return (
      <Checkbox
        checked={checked}
        value={item.ref}
        onClick={(e, data) => CheckboxClicked(data)}
        style={{ verticalAlign: 'middle' }}
      />
    )
  }

  const RenderServiceType = ({ parking }) => {
    return (
      <>
        <Popup
          trigger={
            <MoovyLink bold key={parking.ref}>
              {parking?.service?.type == serviceType.SUBSCRIPTION
                ? intl.formatMessage({
                    id: 'parkingTable.parkingProduct.subscription'
                  })
                : intl.formatMessage({
                    id: 'parkingTable.parkingProduct.shortParking'
                  })}
            </MoovyLink>
          }
        >
          <MoovyLink
            bold
            key={parking.ref}
            onClick={() =>
              navigate(
                Routing.getServiceUrl(parking.service?.ref, isSuperOperator)
              )
            }
          >
            {parking.service?.name}
          </MoovyLink>
        </Popup>
        <ServiceWithTags parkingService={parking?.service} />
      </>
    )
  }

  return (
    <>
      <EndParkingBanner
        selectAllAnonymous={selectAllAnonymous}
        endAnonymousParkings={endAnonymousParkings}
        disableEndButton={selectedCheckBoxes.length <= 0}
        hideBanner={selectedCheckBoxes.length <= 0}
      />
      <Table selectable sortable>
        <Table.Header>
          <Table.Row>
            {enableEndParking && (
              <Table.HeaderCell>
                <Checkbox
                  onClick={(e, data) => SelectAll(data.checked)}
                  style={{ verticalAlign: 'middle' }}
                />
              </Table.HeaderCell>
            )}
            {listForLocation && (
              <Table.HeaderCell>
                <FormattedMessage id="parkingTable.header.type" />
              </Table.HeaderCell>
            )}
            {!listForLocation && (
              <>
                <Table.HeaderCell>
                  <FormattedMessage id="parkingTable.header.location" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="parkingTable.header.city" />
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell
              sorted={
                sort.column === 'start'
                  ? directions.stringValues[sort.direction]
                  : null
              }
              onClick={onSorting('start')}
            >
              <FormattedMessage id="parkingTable.header.startTime" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.endTime" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.duration" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.totalDuration" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.licensePlateNumber" />
            </Table.HeaderCell>
            {listForLocation && (
              <Table.HeaderCell>
                <FormattedMessage id="parkingTable.header.customer" />
              </Table.HeaderCell>
            )}
            <Table.HeaderCell
              sorted={
                sort.column === 'calculatedPrice'
                  ? directions.stringValues[sort.direction]
                  : null
              }
              onClick={onSorting('calculatedPrice')}
            >
              Hinta
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.discount" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.total" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.paymentMethod" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.parkingProduct" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data &&
            data.map((parking) => (
              <Table.Row
                key={parking.ref}
                active={initiallySelectedParkingRef === parking.ref}
                onClick={() => onClickParking(parking)}
              >
                {enableEndParking && (
                  <Table.Cell
                    collapsing
                    onClick={(event) => event.stopPropagation()}
                  >
                    <SelectCheckBox item={parking} />
                  </Table.Cell>
                )}
                {listForLocation && (
                  <Table.Cell
                    textAlign="center"
                    collapsing
                    style={{ padding: '0px' }}
                  >
                    <ParkingTypeLabel
                      type={parking.type}
                      paymentMethodType={parking.paymentMethodType}
                    />
                  </Table.Cell>
                )}
                {!listForLocation && (
                  <>
                    <Table.Cell>
                      {getFormattedLocationName(parking.location)}
                    </Table.Cell>
                    <Table.Cell>{parking.location.city}</Table.Cell>
                  </>
                )}
                <Table.Cell>{formatDateAndTime(parking.start)}</Table.Cell>
                <Table.Cell collapsing>
                  {parking.ongoing ? (
                    <span className="primaryColor">
                      <FormattedMessage id="parkingTable.endTime.ongoing" />
                    </span>
                  ) : (
                    formatDateAndTime(parking.end)
                  )}
                </Table.Cell>
                <Table.Cell>
                  {formatSeconds(parking.durationSeconds)}
                </Table.Cell>
                <Table.Cell>
                  {parking.parkingSequence
                    ? formatSeconds(parking.parkingSequence.durationSeconds)
                    : ''}
                </Table.Cell>
                <Table.Cell
                  collapsing
                  onClick={(event) => event.stopPropagation()}
                >
                  {isLicensePlateEditAllowed(parking) ? (
                    <MoovyLink
                      style={{
                        margin: '0px',
                        padding: '0px'
                      }}
                      onClick={() => {
                        setShowLicensePlateModal(true)
                        setEditableLicensePlateData({
                          ref: parking.ref,
                          licensePlate: parking.vehicle.name
                        })
                      }}
                    >
                      {parking.vehicle.name}
                    </MoovyLink>
                  ) : (
                    parking.vehicle.name
                  )}
                </Table.Cell>
                {listForLocation && (
                  <Table.Cell>
                    {parking.user ? parking.user.name : ''}
                  </Table.Cell>
                )}
                <Table.Cell>
                  {formatPrice(parking.priceBeforeDiscounts)}
                </Table.Cell>
                <Table.Cell>{formatPrice(parking.discounts)}</Table.Cell>
                <Table.Cell>{formatPrice(parking.price)}</Table.Cell>
                <Table.Cell>
                  <LocalizedPaymentCardCategory
                    value={parking.paymentCardCategory}
                  />
                </Table.Cell>
                <Table.Cell
                  singleLine={true}
                  onClick={(event) => event.stopPropagation()}
                >
                  <RenderServiceType parking={parking} />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <TablePagination
          colSpan={listForLocation ? 15 : 12}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </Table>
      <EditLicensePlateModal
        open={showLicensePlateModal}
        licensePlateData={editableLicensePlateData}
        onChangeLicensePlateData={(licensePlateData) =>
          setEditableLicensePlateData(licensePlateData)
        }
        onClose={() => {
          setShowLicensePlateModal(false)
          setEditableLicensePlateData(null)
          editLicensePlateMutation.reset()
        }}
        onSubmit={() =>
          showLicensePlateModal && editLicensePlate(editableLicensePlateData)
        }
        mutation={editLicensePlateMutation}
      />
      <EndParkingModal
        endParkingItems={endParkingItems}
        mutation={endParkingMutation}
        open={endParkingDialog}
        onClose={() => setEndParkingDialog(false)}
        onSubmit={endParking}
      />
      <Modal onClose={() => setErrorDialog(false)} open={errorDialog}>
        <Modal.Header>
          <FormattedMessage id="parkingTable.endParking.modal.error.notValidParking.header" />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              <FormattedMessage id="parkingTable.endParking.modal.error.notValidParking" />
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setErrorDialog(false)}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

ParkingDataTable.propTypes = {
  fetchSessionsByLocation: PropTypes.func,
  isSuperOperator: PropTypes.bool,
  location: PropTypes.shape(locationShape)
}

export default ParkingDataTable
