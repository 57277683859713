import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import { ROLE_FINANCES, ROLE_MANAGEMENT } from '../services/utils'

import BlocklistCandidates from '../pages/blocklistCandidates'
import Chargings from '../pages/chargings'
import CreditCoverCreditLoss from '../pages/creditCoverCreditLoss'
import Finances from '../pages/Finances'
import Invoices from '../pages/invoices'
import ManagementNewLocationService from '../pages/management/ManagementNewLocationService'
import ManagementNewService from '../pages/management/ManagementNewService'
import MoneyTransfers from '../pages/moneyTransfers'
import OperatorDetails from '../pages/operators/OperatorDetails'
import OperatorsList from '../pages/operators/OperatorsList'
import SettlementRecipient from '../pages/settlementRecipients/single/settlementRecipient'
import SettlementRecipients from '../pages/settlementRecipients'
import RequireRoles from './RequireRoles'
import AppRoutes from './AppRoutes'

const AdminRoutes = () => {
  return (
    <Routes>
      <Route path="operators" element={<OperatorsList />} />
      <Route path="operators/:operatorRef" element={<OperatorDetails />} />
      <Route
        path="finances"
        element={
          <RequireRoles roles={[ROLE_FINANCES]}>
            <Finances />
          </RequireRoles>
        }
      ></Route>
      <Route
        path="management/locationService/new"
        element={
          <RequireRoles roles={[ROLE_MANAGEMENT]}>
            <ManagementNewLocationService />
          </RequireRoles>
        }
      ></Route>
      <Route
        path="management/service/new"
        element={
          <RequireRoles roles={[ROLE_MANAGEMENT]}>
            <ManagementNewService />
          </RequireRoles>
        }
      />
      <Route path="chargings" element={<Chargings />} />
      <Route
        path="settlementRecipients/:settlementRecipientRef"
        element={
          <RequireRoles roles={[ROLE_FINANCES]}>
            <SettlementRecipient />
          </RequireRoles>
        }
      />
      <Route
        path="settlementRecipients"
        element={
          <RequireRoles roles={[ROLE_FINANCES]}>
            <SettlementRecipients />
          </RequireRoles>
        }
      />
      <Route
        path="moneyTransfers"
        element={
          <RequireRoles roles={[ROLE_FINANCES]}>
            <MoneyTransfers />
          </RequireRoles>
        }
      />
      <Route
        path="creditCoverCreditLoss"
        element={
          <RequireRoles roles={[ROLE_FINANCES]}>
            <CreditCoverCreditLoss />
          </RequireRoles>
        }
      />
      <Route path="invoices" element={<Invoices />} />
      <Route path="blocklistCandidates" element={<BlocklistCandidates />} />
      <Route path="/" element={<Navigate to="/admin/operators" replace />} />
      {/* Keep AppRoutes last since 404 redirect is there */}
      <Route path="*" element={<AppRoutes />} />
    </Routes>
  )
}

export default AdminRoutes
