import { Login, Logout } from './containers/Authentication'
import React from 'react'
import { Navigate, Routes, Route } from 'react-router-dom'
import {
  faBullseyeArrow,
  faCctv,
  faHandHoldingDollar
} from '@fortawesome/pro-solid-svg-icons'
import {
  faAsterisk as farAsterisk,
  faChargingStation as farChargingStation
} from '@fortawesome/pro-regular-svg-icons'

import AdminRoutes from './routing/AdminRoutes'
import Header from './containers/Header'
import { Helmet } from 'react-helmet'
import MoovyToast from './components/MoovyToast'
import OperatorRoutes from './routing/OperatorRoutes'
import RedirectUser from './routing/RedirectUser'
import { faPlusCircle as falPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons'
import { faRepeatAlt as farRepeatAlt } from '@fortawesome/pro-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import useKeycloak from './hooks/useKeycloak'
import RequireOperator from './routing/RequireOperator'
import RequireSuperOperator from './routing/RequireSuperOperator'
import RequireAuthentication from './routing/RequireAuthentication'
import { ServiceBreakInfo } from './components'

const App = () => {
  const keycloak = useKeycloak()

  library.add(
    faCctv,
    farChargingStation,
    faBullseyeArrow,
    farExclamationCircle,
    falPlusCircle,
    faHandHoldingDollar,
    farRepeatAlt,
    farAsterisk
  )

  return (
    <div className="App">
      <div className="App-Top">
        <Helmet>
          <title>Moovy</title>
        </Helmet>
        <Header />
        <ServiceBreakInfo />
        <MoovyToast />
        {keycloak && (
          <Routes>
            {/* redirect logged in user from root to op or admin section */}
            <Route
              path="/"
              exact
              element={
                <RequireAuthentication>
                  <RedirectUser />
                </RequireAuthentication>
              }
            ></Route>

            <Route
              path="/login/:realm?"
              element={<Login keycloak={keycloak} />}
            ></Route>
            <Route
              path="/logout"
              element={<Logout keycloak={keycloak} />}
            ></Route>

            <Route
              path="/operator/*"
              element={
                <RequireAuthentication>
                  <RequireOperator>
                    <OperatorRoutes />
                  </RequireOperator>
                </RequireAuthentication>
              }
            ></Route>
            <Route
              path="/admin/*"
              element={
                <RequireAuthentication>
                  <RequireSuperOperator>
                    <AdminRoutes />
                  </RequireSuperOperator>
                </RequireAuthentication>
              }
            ></Route>

            {/* no match: we dont have 404 view so redirect to root */}
            <Route path="*" element={<Navigate to="/" replace />}></Route>
          </Routes>
        )}
      </div>
    </div>
  )
}

export default App
