import { Button, Modal } from 'semantic-ui-react'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MutationErrorMessage } from '../index'

const MoovyModal = ({
  children,
  header,
  open,
  size = 'small',
  onClose,
  mutation,
  onSubmit,
  submitDisabled = false,
  submitButtonLabel = <FormattedMessage id="common.button.save" />
}) => {
  return (
    <Modal open={open} onClose={onClose} size={size}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId="common.mutation.error"
          messageNegative
        />
        {children}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          disabled={mutation.isPending || submitDisabled}
          loading={mutation.isPending}
          primary
          onClick={onSubmit}
        >
          {submitButtonLabel}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default MoovyModal
