import {
  getDataForPage,
  getTablePaginationInitialValues,
  getTotalPages,
  getUpdatedPaginationSort
} from '../../../services/utils/tablePagination'

import OperatorTable from './OperatorTable'
import OperatorToolbar from './OperatorToolbar'
import React from 'react'
import Toolbar from '../../../components/Toolbar'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import { getFilteredOperatorData } from './OperatorFilterContent'
import useGlobalUI from '../../../hooks/useGlobalUI'
import { useIntl } from 'react-intl'
import useOperators from '../../../hooks/useOperators'
import { useNavigate } from 'react-router-dom'

const OperatorsList = () => {
  const intl = useIntl()

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/',
      active: true
    }
  ])
  const pageLimit = 20

  const navigate = useNavigate()
  const results = useOperators()

  const [state, setState] = useGlobalUI({
    key: 'OperatorsList',
    initialValue: {
      filter: '',
      ...getTablePaginationInitialValues('sortByName')
    }
  })

  const setFilter = (filter) => {
    setState({ ...state, filter, activePage: 1 })
  }

  const onHandleSort = (column) => () => {
    const { sortColumn, direction } = state
    const updatedPagination = getUpdatedPaginationSort(column, {
      sortColumn,
      direction
    })
    setState({
      ...state,
      ...updatedPagination
    })
  }

  const onPageChange = (e, { activePage }) => {
    setState({
      ...state,
      activePage
    })
  }

  const { filter, activePage, sortColumn, direction } = state

  const filteredAndSorted = getFilteredOperatorData(
    results.data?.content,
    filter,
    {
      sortColumn,
      direction
    }
  )
  const totalPages = getTotalPages(filteredAndSorted.length, pageLimit)
  const rows = getDataForPage(filteredAndSorted, activePage, pageLimit)

  return (
    <>
      <Toolbar
        title="Operaattorit"
        breadcrumbs={breadcrumbs}
        content={<OperatorToolbar value={filter} onChange={setFilter} />}
      />
      <div className="Admin--Page--Content">
        <OperatorTable
          operators={rows}
          onSelectedOperator={(operatorRef) => navigate(operatorRef)}
          onHandleSort={onHandleSort}
          onPageChange={onPageChange}
          pagination={{
            activePage,
            totalPages,
            sortColumn,
            direction
          }}
        />
      </div>
    </>
  )
}

export default OperatorsList
