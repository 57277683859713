import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'semantic-ui-react'

const UserGroupMembershipsTable = ({ userGroupMemberships, onRowClick }) => (
  <Table selectable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <FormattedMessage id="userGroupMembershipsTable.header.name" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="userGroupMembershipsTable.header.ref" />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {userGroupMemberships.map((m) => (
        <Table.Row key={m.user.ref} onClick={() => onRowClick(m.user.ref)}>
          <Table.Cell>{m.user.name}</Table.Cell>
          <Table.Cell>{m.user.ref}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

export default UserGroupMembershipsTable
