import { createSlice } from '@reduxjs/toolkit'

const stateKey = 'ui'

const slice = createSlice({
  name: stateKey,
  initialState: {},
  reducers: {
    setUI(state, action) {
      const newState = {
        ...state,
        [action.payload.key]: action.payload.value
      }
      return newState
    }
  }
})

export default slice.reducer

export const { setUserGroups, setUI } = slice.actions

export const setUiState = (key, payload) => {
  return setUI({ key, value: payload })
}

export const uiSelectors = {
  keyExists(state, uiKey) {
    return state[stateKey][uiKey] !== undefined
  },
  getUiState(state, uiKey) {
    return state[stateKey][uiKey]
  }
}
