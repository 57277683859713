import MoovyTextInput from '../../../components/MoovyTextInput'
import React from 'react'
import { useIntl } from 'react-intl'

const OperatorToolbar = ({ onChange, value }) => {
  const intl = useIntl()
  return (
    <div>
      <div className="toolbar-filters">
        <MoovyTextInput
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onClear={() => onChange('')}
          placeholder={intl.formatMessage({
            id: 'operatorsList.toolbar.search.placeholder'
          })}
        />
      </div>
      <div />
    </div>
  )
}

export default OperatorToolbar
