import React from 'react'
import { useIntl } from 'react-intl'
import { Select } from 'semantic-ui-react'

import MoovyTextInput from '../../../components/MoovyTextInput'
import useOperators from '../../../hooks/useOperators'

const LocationToolbar = ({
  locationFilter,
  setLocationFilter,
  operatorFilter,
  setOperatorFilter,
  isSuperOperator
}) => {
  const operators = (isSuperOperator && useOperators()) || []
  const intl = useIntl()

  const operatorOptions = operators.data
    ? operators.data.content
        .sort((a, b) => `${a.name}`.localeCompare(b.name))
        .map((op) => ({
          key: op.ref,
          value: op.ref,
          text: op.name
        }))
    : []

  return (
    <div>
      <div className="toolbar-filters">
        <MoovyTextInput
          value={locationFilter}
          placeholder={intl.formatMessage({
            id: 'locationsList.locationToolbar.filter.placeholder'
          })}
          onChange={(e) => setLocationFilter(e.target.value)}
          onClear={() => setLocationFilter('')}
        />
        {isSuperOperator ? (
          <Select
            clearable
            placeholder={intl.formatMessage({
              id: 'locationsList.locationToolbar.operatorSelect.placeholder'
            })}
            search
            options={operatorOptions}
            value={operatorFilter}
            onChange={(e, data) => setOperatorFilter(data.value)}
            selectOnBlur={false}
          />
        ) : (
          ''
        )}
      </div>
      <div />
    </div>
  )
}

export default LocationToolbar
