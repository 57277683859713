import './Finances.scss'

import { FormattedMessage, useIntl } from 'react-intl'
import { MonthInput, YearInput } from 'semantic-ui-calendar-react'

import { DateTime } from 'luxon'
import { Form } from 'semantic-ui-react'
import React from 'react'

const FinancesFilterYearMonth = ({ yearAndMonth, setYearAndMonth }) => {
  const intl = useIntl()

  return (
    <Form style={{ maxWidth: '600px' }}>
      <Form.Group widths={'equal'}>
        <Form.Field>
          <label>
            <FormattedMessage id="financesFilterYearMonth.label.year" />
          </label>
          <YearInput
            value={yearAndMonth.year}
            onChange={(ev, { value }) =>
              setYearAndMonth({ ...yearAndMonth, year: value })
            }
            dateFormat="YYYY"
            name="year"
            placeholder={intl.formatMessage({
              id: 'financesFilterYearMonth.label.year'
            })}
            iconPosition="left"
            animation="none"
            maxDate={DateTime.local().year.toString()}
            closable
          />
        </Form.Field>
        <Form.Field>
          <label>
            <FormattedMessage id="financesFilterYearMonth.label.month" />
          </label>
          <MonthInput
            value={yearAndMonth.month}
            onChange={(ev, { value }) =>
              setYearAndMonth({ ...yearAndMonth, month: value })
            }
            dateFormat="M"
            name="month"
            placeholder={intl.formatMessage({
              id: 'financesFilterYearMonth.label.month'
            })}
            iconPosition="left"
            animation="none"
            closable
          />
        </Form.Field>
      </Form.Group>
    </Form>
  )
}
export default FinancesFilterYearMonth
