import { Header, Popup, Table } from 'semantic-ui-react'
import {
  MoovyLink,
  MoovyTable,
  ParkingTypeLabel,
  TablePagination
} from '../../../components'
import React, { useEffect } from 'react'
import { formatDateAndTime, parkingTypeEnum } from '../../../services/utils'

import AccessLogFilters from './accessLogFilters'
import { DateTime } from 'luxon'
import { FormattedMessage } from 'react-intl'
import RelativeLinkForCurrentUser from '../../../containers/RelativeLinkForCurrentUser'
import { fetchVehiclesAccessLogs } from '../../../services/Vehicles'
import { getTotalPages } from '../../../services/utils/tablePagination'
import moovyDateTime from '../../../services/utils/moovyDateTime'
import useGlobalUI from '../../../hooks/useGlobalUI'
import { useQuery } from '@tanstack/react-query'

const VehicleAccessLogs = ({ locationRef }) => {
  const pageLimit = 20
  const initialDateTime = DateTime.local().setLocale('fi')

  const [globalState, setGlobalState] = useGlobalUI({
    key: 'vehicleAccessLogs',
    initialValue: {
      partialLicensePlateNumber: '',
      startTime: initialDateTime.startOf('day').toJSDate().toISOString(),
      endTime: initialDateTime.endOf('day').toJSDate().toISOString(),
      activePage: 1
    }
  })

  const getQueryParams = () => {
    return {
      locationRef: locationRef,
      licensePlateNumber: globalState.partialLicensePlateNumber,
      startTime: moovyDateTime.dateTimeToBackendFormat(
        DateTime.fromISO(globalState.startTime)
      ),
      endTime: moovyDateTime.dateTimeToBackendFormat(
        DateTime.fromISO(globalState.endTime)
      ),
      page: globalState.activePage - 1,
      limit: pageLimit
    }
  }

  const accessLogsQuery = useQuery({
    queryKey: ['fetchAccessLogs', locationRef],
    queryFn: () => fetchVehiclesAccessLogs(getQueryParams()),
    enabled: false
  })

  useEffect(() => {
    refresh()
  }, [globalState.activePage])

  const accessLogs = accessLogsQuery.data?.content || []

  const refresh = () => {
    if (
      locationRef &&
      globalState.partialLicensePlateNumber &&
      globalState.startTime &&
      globalState.endTime
    ) {
      accessLogsQuery.refetch()
    }
  }

  const totalPages = accessLogsQuery?.data?.pageable
    ? getTotalPages(accessLogsQuery.data.pageable.total, pageLimit)
    : 0

  const RenderControlResponse = ({ responseJson }) => {
    if (responseJson?.allResults) {
      const stringified = JSON.stringify(responseJson.allResults, null, 4) ?? ''
      return <pre>{stringified}</pre>
    }

    return ''
  }

  return (
    <div>
      <Header>
        <FormattedMessage id="locations.tab.vehicleAccessLogs.title" />
      </Header>
      <AccessLogFilters
        loading={accessLogsQuery.isFetching}
        onSearch={() => refresh()}
        values={globalState}
        setValues={(values) => {
          setGlobalState({ ...values })
        }}
      />

      <MoovyTable
        compact="very"
        celled
        items={accessLogs}
        query={accessLogsQuery}
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="locations.tab.vehicleAccessLogs.table.creationTime" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="locations.tab.vehicleAccessLogs.table.licensePlate" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="locations.tab.vehicleAccessLogs.table.customer" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="locations.tab.vehicleAccessLogs.table.location" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="locations.tab.vehicleAccessLogs.table.eventType" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="locations.tab.vehicleAccessLogs.table.decision" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="locations.tab.vehicleAccessLogs.table.decisionResponse" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {accessLogs.map((row) => (
            <Table.Row key={row.ref}>
              <Table.Cell>{formatDateAndTime(row.creationTime)}</Table.Cell>
              <Table.Cell>{row.licensePlateNumber}</Table.Cell>
              <Table.Cell>
                {row.user ? (
                  <RelativeLinkForCurrentUser to={`customers/${row.user.ref}`}>
                    {row.user.name}
                  </RelativeLinkForCurrentUser>
                ) : (
                  <ParkingTypeLabel type={parkingTypeEnum.ANONYMOUS_PARKING} />
                )}
              </Table.Cell>
              <Table.Cell>
                {row.location && row.location.ref === locationRef ? (
                  <>{row.location.name}</>
                ) : (
                  <RelativeLinkForCurrentUser
                    to={`locations/${row.location.ref}`}
                  >
                    {row.location.name}
                  </RelativeLinkForCurrentUser>
                )}
              </Table.Cell>
              <Table.Cell>{row.accessData?.eventType}</Table.Cell>
              <Table.Cell>{row.accessData?.decision}</Table.Cell>
              <Table.Cell>
                <Popup
                  trigger={
                    <MoovyLink bold>
                      <FormattedMessage id="locations.tab.vehicleAccessLogs.table.link.details" />
                    </MoovyLink>
                  }
                  content={
                    <RenderControlResponse
                      responseJson={row.accessData?.controlResponse}
                    />
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TablePagination
          colSpan={7}
          activePage={globalState.activePage}
          totalPages={totalPages}
          onPageChange={(e, { activePage }) =>
            setGlobalState({ ...globalState, activePage })
          }
        />
      </MoovyTable>
    </div>
  )
}

export default VehicleAccessLogs
