import './UserGroups.scss'

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Button, Dropdown, Form, Message, Modal } from 'semantic-ui-react'

import { MutationErrorMessage } from '../../components'
import useAuthInfo from '../../hooks/useAuthInfo'
import useOperators from '../../hooks/useOperators'
import userGroupService from '../../services/UserGroups'

/* Modal for creating a new UserGroup.
   Currently there is a need to create multiple groups with slightly changing names for the same operator.
   Because of this, Modal does not close or clear itself after creating a UserGroup. */
const ModalCreateUserGroup = ({ open, onClose }) => {
  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const [infoMessage, setInfoMessage] = useState('')
  const [operatorRef, setOperatorRef] = useState('')
  const [groupName, setGroupName] = useState('')
  const [errorOperator, setErrorOperator] = useState('')
  const [errorName, setErrorName] = useState('')

  const intl = useIntl()

  const { mutate: createGroup, ...createGroupMutation } = useMutation({
    mutationFn: (groupData) =>
      userGroupService.createUserGroup(
        isSuperOperator,
        operatorRealm,
        groupData
      ),
    onSuccess: () => {
      clearContent()
      setInfoMessage(
        intl.formatMessage(
          { id: 'createUserGroup.message.success' },
          { groupName }
        )
      )
    }
  })

  const handleSubmit = (e) => {
    e.preventDefault()
    setInfoMessage('')
    if (isSuperOperator && !operatorRef) {
      setErrorOperator(true)
    } else if (!groupName) {
      setErrorName(true)
    } else {
      createGroup({ name: groupName, operatorRef })
    }
  }

  const clearContent = () => {
    setErrorOperator(false)
    setErrorName(false)
    setGroupName('')
    setOperatorRef('')
  }

  return (
    <Modal open={open} size="tiny">
      <Modal.Header>
        <FormattedMessage id="createUserGroup.modal.header" />
      </Modal.Header>
      <Modal.Content>
        {infoMessage ? <Message success={true}>{infoMessage}</Message> : null}
        <MutationErrorMessage
          mutation={createGroupMutation}
          defaultErrorTextLangId="createUserGroup.message.error"
          messageNegative
        />
        <Form onSubmit={handleSubmit} id="form-create-user-group">
          {isSuperOperator && (
            <OperatorSelection
              realm={operatorRef}
              onChange={(e, { value }) => {
                setErrorOperator(false)
                setOperatorRef(value)
              }}
              error={!!errorOperator}
            />
          )}
          <Form.Field error={!!errorName}>
            <label>
              <FormattedMessage id="createUserGroup.modal.form.groupName" />
            </label>
            <input
              value={groupName}
              onChange={(e) => {
                setErrorName(false)
                setGroupName(e.target.value)
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            clearContent()
            setInfoMessage('')
            createGroupMutation.reset()
            onClose()
          }}
        >
          <FormattedMessage id="common.button.close" />
        </Button>
        <Button
          form="form-create-user-group"
          type="submit"
          primary
          disabled={createGroupMutation.isPending}
          loading={createGroupMutation.isPending}
        >
          <FormattedMessage id="common.button.add" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

function OperatorSelection({ realm, onChange, error }) {
  const operators = useOperators()
  const intl = useIntl()

  const operatorOptions = operators.data
    ? operators.data.content
        .filter((op) => op.realm)
        .map((op) => ({
          key: op.ref,
          value: op.ref,
          text: op.name
        }))
    : []

  return (
    <Form.Field error={operators.isError || error}>
      <label>
        <FormattedMessage id="createUserGroup.modal.form.operator" />
      </label>
      <Dropdown
        placeholder={intl.formatMessage({
          id: 'createUserGroup.modal.form.operator.dropdown.placeholder'
        })}
        fluid
        search
        selection
        loading={operators.isLoading}
        options={operatorOptions}
        value={realm}
        onChange={onChange}
        selectOnBlur={false}
      />
    </Form.Field>
  )
}

export default ModalCreateUserGroup
