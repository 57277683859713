import React, { useEffect, useState } from 'react'
import { Grid, Icon, Table } from 'semantic-ui-react'

import { MoovyButton } from '../../components'
import useAuthInfo from '../../hooks/useAuthInfo'
import userService from '../../services/User/UserServices'
import AddUserToUserGroupModal from '../AddUserToGroupModal'
import UserGroupModal from './UserGroupModal'

const UserGroupView = ({ customer, onRefetchUserGroups }) => {
  const { operatorRealm } = useAuthInfo()
  const [userGroups, setUserGroups] = useState([])
  const [
    showAddUserToUserGroupModal,
    setShowAddUserToUserGroupModal
  ] = useState(false)
  const [selectedUserGroup, setSelectedUserGroup] = useState(undefined)

  useEffect(() => {
    if (customer && customer.ref) {
      fetchCustomerData(customer)
    }
  }, [customer])

  const handleRemoveUserFromGroup = () => {
    setUserGroups(
      userGroups.filter((userGroup) => userGroup.ref !== selectedUserGroup.ref)
    )
    onRefetchUserGroups()
    setSelectedUserGroup(undefined)
  }

  const fetchCustomerData = (customer) => {
    userService
      .fetchUserGroups(customer.ref, operatorRealm)
      .then((data) =>
        setUserGroups(data.sort((a, b) => a.name.localeCompare(b.name)))
      )
  }

  return (
    <>
      <Grid>
        <Grid.Row textAlign="right">
          <Grid.Column>
            <MoovyButton onClick={() => setShowAddUserToUserGroupModal(true)}>
              <Icon name="ticket" />
              Lisää käyttäjäryhmään
            </MoovyButton>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nimi</Table.HeaderCell>
            <Table.HeaderCell>Operaattori</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {userGroups &&
            userGroups.map((groupData) => (
              <Table.Row
                key={groupData.ref}
                onClick={() => {
                  setSelectedUserGroup(groupData)
                }}
              >
                <Table.Cell>{groupData.name}</Table.Cell>
                <Table.Cell>
                  {groupData.operator && groupData.operator.name}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {showAddUserToUserGroupModal && (
        <AddUserToUserGroupModal
          userRef={customer.ref}
          memberOf={userGroups.map((userGroup) => userGroup.ref)}
          onAddUser={(newGroup) => {
            setUserGroups(userGroups.concat(newGroup))
            onRefetchUserGroups()
          }}
          onClose={() => setShowAddUserToUserGroupModal(false)}
        />
      )}
      {selectedUserGroup && (
        <UserGroupModal
          userRef={customer.ref}
          userGroup={selectedUserGroup}
          onClose={() => setSelectedUserGroup(undefined)}
          onRemoveUser={handleRemoveUserFromGroup}
          operatorRealm={operatorRealm}
        />
      )}
    </>
  )
}

export default UserGroupView
