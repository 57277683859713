import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { TextArea } from 'semantic-ui-react'

import { transform } from '../../../services/utils/JSONTransformer'
import { isEmptyObject } from './ManagementUtils'

export const JSONView = ({
  formValues,
  readonly,
  onChangeJson,
  onJsonError
}) => {
  const [jsonAsString, setJsonAsString] = useState('')
  const numberFields = [
    'location.heatedParkingSpaces',
    'location.notHeatedParkingSpaces',
    'location.maximumParkingTime',
    'location.parkingCancelGracePeriodSeconds',
    'servicePricing.dailyFee',
    'servicePricing.periodicFee',
    'servicePricing.subscriptionFee',
    'servicePricing.vatPercent'
  ]

  useEffect(() => {
    let JSONContent = {}
    if (!isEmptyObject(formValues)) {
      JSONContent = formValues
    }

    setJsonAsString(
      JSON.stringify(transform(JSONContent, numberFields, false), null, 2)
    )
  }, [formValues])

  const onChangeJSONContent = (originalJson, updatedJson) => {
    let originalJsonAsObject = {}
    try {
      originalJsonAsObject = JSON.parse(originalJson)
    } catch (error) {
      originalJsonAsObject = {}
    }

    try {
      onJsonError(false)
      // If text area is empty, it means clear everything.
      if (!updatedJson) {
        onChangeJson({})
      }

      // Empty will not be updated
      if (updatedJson === '') {
        return
      }

      const jsonAsObject = JSON.parse(updatedJson)

      // This ensures that whitespaces and enter can be added in JSONView
      // so that user can easily edit JSON as a string
      if (!isEqual(originalJsonAsObject, jsonAsObject)) {
        onChangeJson(jsonAsObject)
      }
    } catch (error) {
      onJsonError(true)
    }
  }

  const onChange = (event, { value }) => {
    onChangeJSONContent(jsonAsString, value)
    setJsonAsString(value)
  }

  return (
    <>
      {readonly ? (
        <TextArea value={jsonAsString} rows={40} />
      ) : (
        <TextArea value={jsonAsString} onChange={onChange} rows={40} />
      )}
    </>
  )
}

export default JSONView
