import { Menu, Pagination, Table } from 'semantic-ui-react'

import PropTypes from 'prop-types'
import React from 'react'

const TablePagination = ({ activePage, totalPages, onPageChange, colSpan }) => {
  if (!totalPages || totalPages <= 1) {
    return null
  }
  return (
    <Table.Footer>
      <Table.Row>
        <Table.HeaderCell colSpan={colSpan}>
          <Menu floated="right">
            <Pagination
              activePage={activePage}
              totalPages={totalPages}
              onPageChange={onPageChange}
            />
          </Menu>
        </Table.HeaderCell>
      </Table.Row>
    </Table.Footer>
  )
}

TablePagination.propTypes = {
  activePage: PropTypes.number,
  totalPages: PropTypes.number,
  colSpan: PropTypes.number,
  onPageChange: PropTypes.func
}

export default TablePagination
