import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import { MutationErrorMessage } from '../../../../components'
import { containsDefaultPaymentCard } from '../'

const ModalConfirmDeletePaymentCard = ({
  open,
  customer,
  deletableCard,
  cardsTotalCount,
  onClose,
  onSubmit,
  mutation
}) => {
  const intl = useIntl()
  const [forceDelete, setForceDelete] = useState(false)

  const resetView = () => {
    mutation.reset()
    setForceDelete(false)
  }

  const isLocalizedCardErrorType = (mutation) => {
    return !!mutation?.error?.body?.errorDescriptor?.errorDescription
  }

  const isDefaultCard = () => {
    if (deletableCard && customer) {
      return containsDefaultPaymentCard(customer, [deletableCard])
    }
    return false
  }

  const RenderDefaultCardInfoSubElement = ({
    paymentCardTitleKey,
    paymentCardDescKey
  }) => {
    return (
      <>
        <Form.Field>
          <b>
            <FormattedMessage id="modalConfirmDeletePaymentCard.form.note" />
          </b>{' '}
          <FormattedMessage id={paymentCardTitleKey} />
        </Form.Field>
        <Form.Field>
          <FormattedMessage id={paymentCardDescKey} />
        </Form.Field>
      </>
    )
  }

  const RenderDefaultCardInfo = () => {
    if (!isDefaultCard()) {
      if (cardsTotalCount !== 1) {
        return ''
      }
      return (
        <RenderDefaultCardInfoSubElement
          paymentCardTitleKey="modalConfirmDeletePaymentCard.form.lastPaymentCard.title"
          paymentCardDescKey="modalConfirmDeletePaymentCard.form.lastPaymentCard.desc"
        />
      )
    }

    if (cardsTotalCount === 1) {
      return (
        <RenderDefaultCardInfoSubElement
          paymentCardTitleKey="modalConfirmDeletePaymentCard.form.defaultPaymentCard"
          paymentCardDescKey="modalConfirmDeletePaymentCard.form.defaultPaymentCard.addCard"
        />
      )
    } else {
      return (
        <RenderDefaultCardInfoSubElement
          paymentCardTitleKey="modalConfirmDeletePaymentCard.form.defaultPaymentCard"
          paymentCardDescKey="modalConfirmDeletePaymentCard.form.defaultPaymentCard.addOrSetCard"
        />
      )
    }
  }

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => {
        resetView()
        onClose()
      }}
    >
      <Modal.Header>
        <FormattedMessage id="modalConfirmDeletePaymentCard.modal.title" />
      </Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId={
            'modalConfirmDeletePaymentCard.action.error.common'
          }
          messageNegative
        />
        <Form>
          <RenderDefaultCardInfo />
          <Form.Field>
            <FormattedMessage
              id="modalConfirmDeletePaymentCard.delete.confirm"
              values={{
                name: customer?.name,
                card: `${deletableCard?.type} **** **** **** ${deletableCard?.lastDigits}`
              }}
            />
          </Form.Field>

          {isLocalizedCardErrorType(mutation) && (
            <>
              <Form.Field>
                <FormattedMessage id="modalConfirmDeletePaymentCard.form.forceRemoving" />
              </Form.Field>
              <Form.Checkbox
                checked={forceDelete}
                label={intl.formatMessage({
                  id: 'modalConfirmDeletePaymentCard.form.checkbox.forceRemoving'
                })}
                onClick={(e, { checked }) => {
                  setForceDelete(checked)
                }}
              />
            </>
          )}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            resetView()
            onClose()
          }}
        >
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          disabled={mutation.isPending}
          loading={mutation.isPending}
          primary
          onClick={() => {
            onSubmit(forceDelete)
          }}
        >
          <FormattedMessage id="common.button.confirm" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalConfirmDeletePaymentCard
