import * as yup from 'yup'

import { Button, Form, Item, Message, Modal, TextArea } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyHtmlPreview,
  MoovyIconInfo,
  TariffTestPricing,
  TariffTimeRangeForm
} from '../../components'
import React, { useState } from 'react'

import { DateTimeInput } from 'semantic-ui-calendar-react'
import { Formik } from 'formik'
import { MutationErrorMessage } from '../../components'
import { formatDateAndTime } from '../../services/utils'
import moovyDateTime from '../../services/utils/moovyDateTime'
import servicePricing from '../../services/Pricing'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import { transform } from '../../services/utils/JSONTransformer'
import { useMutation } from '@tanstack/react-query'

const ModalAddTariff = ({ pricing, serviceRef, onClose, onAdded }) => {
  const intl = useIntl()

  const getPricingOptions = () => {
    return pricing
      ? pricing.map((item) => ({
          key: item.ref,
          text: intl.formatMessage(
            {
              id: 'modalAddTariff.form.dropdown.text'
            },
            {
              serviceName: item.service.name,
              tariffStartTime: formatDateAndTime(item.startTime)
            }
          ),
          value: item.ref
        }))
      : []
  }

  const numberFields = []
  const [tariffTestError, setTariffTestError] = useState(false)
  const [parkingRanges, setParkingRanges] = useState([])
  const [tariffStructure, setTariffStructure] = useState('')
  const [defaultValues, setDefaultValues] = useState({
    startDateTime: moovyDateTime.dateTimeNowToCalendarInput(),
    periodicFee: 0,
    description: '',
    tariffStructure: ''
  })

  const validationSchema = () => {
    return yup.object().shape({
      periodicFee: yup.number().required('Cannot be empty'),
      startDateTime: yup.string().required('Cannot be empty'),
      tariffStructure: yup
        .object()
        .json()
        .test('tariffStructure', true, function () {
          if (this.parent.tariffStructure) {
            try {
              JSON.stringify(
                transform(this.parent.tariffStructure, numberFields, false),
                null,
                2
              )
              return true
            } catch (error) {}
          }
          return false
        })
    })
  }

  const { mutate: addServiceTariff, ...addServiceTariffMutation } = useMutation(
    {
      mutationFn: (tariffData) =>
        servicePricing.addServicePricing(serviceRef, {
          ...tariffData,
          serviceRef,
          tariffStructure: JSON.parse(tariffData.tariffStructure)
        }),
      onSuccess: () => {
        showLocalizedMoovyToast(intl, {
          title: 'modalAddTariff.toast.title.tariffAdded',
          description: 'modalAddTariff.toast.description.tariffAdded'
        })
        onAdded()
      }
    }
  )

  const onSubmitForm = (values) => {
    // If there is no any tariff in the service, startDateTime must be null so that the tariff will be immediately in use.
    addServiceTariff({
      ...values,
      startDateTime:
        (pricing.length > 0 &&
          moovyDateTime.dateTimeToBackendFormat(
            moovyDateTime.calendarDateTimeInputToDateTime(values.startDateTime)
          )) ||
        undefined
    })
  }

  const updateDefaultValues = (tariffRef) => {
    const item = pricing.find((i) => i.ref === tariffRef)
    setDefaultValues({
      startDateTime: moovyDateTime.dateTimeNowToCalendarInput(),
      periodicFee: item.periodicFee,
      description: item.description || '',
      tariffStructure:
        (item.tariffStructure &&
          JSON.stringify(item.tariffStructure, null, 4)) ||
        ''
    })
    setTariffStructure(
      (item.tariffStructure && JSON.stringify(item.tariffStructure, null, 4)) ||
        ''
    )
  }

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema()}
      onSubmit={(values) => {
        onSubmitForm(values)
      }}
      enableReinitialize={true}
    >
      {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
        <Modal open onClose={onClose}>
          <Modal.Header>
            <FormattedMessage id="modalAddTariff.form.header" />
          </Modal.Header>
          <div
            style={{
              padding:
                tariffTestError || addServiceTariffMutation.isError
                  ? '20px'
                  : undefined
            }}
          >
            {tariffTestError && !addServiceTariffMutation.isError ? (
              <Message color="red">
                <FormattedMessage id="modalAddTariff.form.action.error" />
              </Message>
            ) : (
              <MutationErrorMessage
                mutation={addServiceTariffMutation}
                defaultErrorTextLangId="modalAddTariff.form.action.error"
                messageNegative
              />
            )}
          </div>
          <Modal.Content scrolling>
            <Form onSubmit={handleSubmit}>
              <Form.Dropdown
                label={intl.formatMessage({
                  id: 'modalAddTariff.form.label.pricing'
                })}
                placeholder={intl.formatMessage({
                  id: 'modalAddTariff.form.option.pricing.placeholder'
                })}
                options={getPricingOptions()}
                fluid
                selection
                onChange={(e, value) => {
                  updateDefaultValues(value.value)
                }}
                selectOnBlur={false}
              />
              {(pricing.length > 0 && (
                <Form.Field>
                  <DateTimeInput
                    label={intl.formatMessage({
                      id: 'modalAddTariff.form.label.startTime'
                    })}
                    value={values.startDateTime}
                    onChange={(e, value) => {
                      setFieldValue('startDateTime', value.value)
                    }}
                    dateFormat="DD.MM.YYYY"
                    name="startDateTime"
                    placeholder={intl.formatMessage({
                      id: 'modalAddTariff.form.option.startTime.placeholder'
                    })}
                    iconPosition="left"
                    animation="none"
                    closable
                    error={!!errors.startDateTime}
                  />
                </Form.Field>
              )) ||
                ''}
              <Form.Group widths="equal">
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalAddTariff.form.label.periodicFee'
                  })}
                  name="periodicFee"
                  type="number"
                  value={values.periodicFee}
                  onChange={handleChange}
                  error={!!errors.periodicFee}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>
                    <MoovyIconInfo
                      iconLabel={
                        <FormattedMessage id="modalAddTariff.form.label.description" />
                      }
                    >
                      <FormattedMessage id="modalAddTariff.form.label.description.info" />
                    </MoovyIconInfo>
                  </label>
                  <TextArea
                    placeholder={intl.formatMessage({
                      id: 'modalAddTariff.form.placeholder.description'
                    })}
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    rows={10}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    {intl.formatMessage({
                      id: 'modalAddTariff.form.label.preview'
                    })}
                  </label>
                  <Item>
                    <Item.Content>
                      <MoovyHtmlPreview html={values.description} />
                    </Item.Content>
                  </Item>
                </Form.Field>
              </Form.Group>
              <Form.Field error={errors.tariffStructure}>
                <label>
                  {intl.formatMessage({
                    id: 'modalAddTariff.form.label.tariffStructure'
                  })}
                </label>
                <TextArea
                  placeholder={intl.formatMessage({
                    id: 'modalAddTariff.form.placeholder.tariffStructure'
                  })}
                  name="tariffStructure"
                  value={values.tariffStructure}
                  onChange={(event, data) => {
                    setTariffStructure(data.value)
                    handleChange(event, data)
                  }}
                  rows={15}
                />
              </Form.Field>
              <Form.Field
                style={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  maxHeight: '200px'
                }}
              >
                <label>
                  <FormattedMessage id="modalAddTariff.form.label.testTariff" />
                </label>
                <TariffTimeRangeForm
                  key="tariffTimeRangeForm"
                  onChangeParkingRange={(ranges) => setParkingRanges(ranges)}
                />
                <label>
                  <FormattedMessage id="modalAddTariff.form.label.calculatedTariffs" />
                </label>
                <TariffTestPricing
                  key="tariffTestPricing"
                  parkingRanges={parkingRanges}
                  tariffStructure={tariffStructure}
                  tariffTestError={(error) => setTariffTestError(error)}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              loading={addServiceTariffMutation.isPending}
              disabled={addServiceTariffMutation.isPending}
            >
              <FormattedMessage id="common.button.add" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalAddTariff
