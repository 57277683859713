import './UserInfo.scss'

import PropTypes from 'prop-types'
import React from 'react'
import { Grid, GridColumn } from 'semantic-ui-react'

import UserContact from './UserContact'
import UserStatus from './UserStatus'
import UserVehicles from './UserVehicles'

/** does best effort to render some kind of name for a given user object
 */
export function renderUserName(customer) {
  if (!customer) return null

  const { ref, name } = customer
  const userDetails = !customer.userDetails ? customer : customer.userDetails

  if (
    userDetails &&
    userDetails.firstName &&
    userDetails.firstName.trim().length > 0 &&
    userDetails.lastName &&
    userDetails.lastName.trim().length > 0
  ) {
    return `${userDetails.firstName} ${userDetails.lastName}`
  }

  if (customer.active === false) {
    return null
  }

  if (name && name.trim().length > 0) return name

  if (userDetails && userDetails.phone) return userDetails.phone

  if (userDetails && userDetails.email) return userDetails.email

  if (ref) return ref

  return null
}

const UserInfo = ({
  customer,
  vehicles,
  onShowAllVehicles,
  onRefreshCustomer
}) => {
  return (
    <div className="UserInfo">
      <div className="UserInfo-left">
        {!customer ? (
          ''
        ) : (
          <Grid columns="equal">
            <GridColumn width={3}>
              <UserStatus user={customer} onRefreshUser={onRefreshCustomer} />
            </GridColumn>
            <GridColumn>
              <UserContact user={customer} />
            </GridColumn>
            <GridColumn width={10}>
              <UserVehicles
                vehicles={vehicles}
                onShowAllVehicles={onShowAllVehicles}
              />
            </GridColumn>
          </Grid>
        )}
      </div>
    </div>
  )
}

UserInfo.propTypes = {
  customer: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string
  })
}

export default UserInfo
