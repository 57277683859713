import { useDispatch, useSelector } from 'react-redux'

import { setUiState, uiSelectors } from '../redux/globalUI'

export default function useGlobalUI(options) {
  const dispatch = useDispatch()

  const { key, initialValue } = options
  const updateFunction = (value) => dispatch(setUiState(key, value))
  const exists = useSelector((state) => uiSelectors.keyExists(state, key))
  const selector = useSelector((state) => uiSelectors.getUiState(state, key))

  // Value in Redux, function to update value
  return [exists ? selector : initialValue, updateFunction]
}
