import { get, post, put, remove } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/user${relativePath}`
    : `/administration/user${relativePath}`

const fetchUser = (userRef, operatorRealm) => {
  return get(createFullPath(`/${userRef}`, operatorRealm))
}

const fetchPaymentCards = (userRef, fetchType, operatorRealm) => {
  return get(createFullPath(`/${userRef}/paymentcard`, operatorRealm), {
    includeStatuses: fetchType
  })
}

const removePaymentCard = (userRef, cardRef, forceDelete) => {
  return remove(`/administration/users/${userRef}/paymentcards/${cardRef}`, {
    forceDelete
  })
}

const fetchUserGroups = (userRef, operatorRealm) => {
  return get(createFullPath(`/${userRef}/userGroups`, operatorRealm))
}

const fetchSubscribedServices = (userRef, operatorRealm, includeHistory) => {
  return get(createFullPath(`/${userRef}/subscribedService`, operatorRealm), {
    includeHistory
  })
}

const fetchAssignments = (userRef, operatorRealm) => {
  return get(createFullPath(`/${userRef}/assignments`, operatorRealm))
}

/* Accepting and revoking a SubscriptionUserAssignment defined here. Maybe we should have a service
only for assignments but because of the way the URLs are constructed I guess they fit here.

Only for superoperators.
*/

const acceptAssignment = (userRef, subscribedServiceRef) => {
  if (!userRef || !subscribedServiceRef) {
    throw new Error('Missing required arguments')
  }
  return post(
    `/administration/user/${userRef}/subscribedService/${subscribedServiceRef}/accept`
  )
}

const revokeAssignment = (assignmentRef, subscribedServiceRef) => {
  if (!assignmentRef || !subscribedServiceRef) {
    throw new Error('Missing required arguments')
  }
  return post(
    `/administration/subscribedService/${subscribedServiceRef}/assignment/${assignmentRef}/revoke`
  )
}

const fetchParkings = (userRef, operatorRealm, query) => {
  // We have some extra params in query and sort is in two params, have to combine for backend.
  const queryParams = {
    ...query,
    beginString: null,
    endString: null,
    sort:
      query.sort && query.direction ? `${query.sort},${query.direction}` : null
  }
  return get(createFullPath(`/${userRef}/session`, operatorRealm), queryParams)
}

const fetchVehicles = (userRef, operatorRealm) => {
  return get(createFullPath(`/${userRef}/vehicle`, operatorRealm))
}

const updateUser = (operatorRealm, userRef, data) => {
  if (!userRef || !data) {
    throw new Error('Missing argument')
  }
  return put(createFullPath(`/${userRef}`, operatorRealm), data)
}

const removeUser = (userRef) => {
  return remove(createFullPath(`/${userRef}`, null))
}

const removeEnterpriseUser = (userRef) => {
  return remove(`/administration/enterpriseUsers/${userRef}`, null)
}

const createUserDeletionRequest = (userRef, body) => {
  return post(createFullPath(`/${userRef}/deletionRequest`, null), body)
}

const cancelUserDeletionRequest = (userRef) => {
  return post(createFullPath(`/${userRef}/cancelDeletionRequest`, null))
}

const updateInvoicing = (userRef, data, operatorRealm) => {
  return put(createFullPath(`/${userRef}/invoicing`, operatorRealm), data)
}

export default {
  fetchUser,
  fetchParkings,
  fetchUserGroups,
  fetchPaymentCards,
  fetchAssignments,
  fetchSubscribedServices,
  fetchVehicles,
  acceptAssignment,
  revokeAssignment,
  updateUser,
  updateInvoicing,
  removePaymentCard,
  removeUser,
  removeEnterpriseUser,
  createUserDeletionRequest,
  cancelUserDeletionRequest
}
