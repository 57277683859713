import { Navigate, Routes, Route } from 'react-router-dom'

import React from 'react'
import AppRoutes from './AppRoutes'

const OperatorRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/operator/locations" replace />} />
      {/* Keep AppRoutes last since 404 redirect is there */}
      <Route path="*" element={<AppRoutes />} />
    </Routes>
  )
}

export default OperatorRoutes
