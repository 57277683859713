import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import { Button, Message } from 'semantic-ui-react'

import useGlobalUI from '../../../../hooks/useGlobalUI'
import messageCenterService from '../../../../services/MessageCenter'
import { STEP_KEYS } from '../CreateMessage'
import SelectedTargetGroups from './SelectedTargetGroups'
import TargetTable from './TargetTable'

export const TargetGroupSelector = ({
  formValues,
  loading,
  onDataCompleted,
  onChangeValue,
  duplicateTargetGroup
}) => {
  const [error, setError] = useState(false)
  const queryClient = useQueryClient()

  const [state, setState] = useGlobalUI({
    key: 'TargetGroupSelector',
    initialValue: {
      page: 0,
      limit: 10,
      sort: 'sortByCreationTime',
      direction: 'DESC',
      selectedTargetGroups: [],
      targetGroups: { data: [] }
    }
  })

  const targetsQuery = useQuery({
    queryKey: [
      'messageServiceTargets',
      state.page,
      state.limit,
      state.sort,
      state.direction
    ],
    queryFn: () =>
      messageCenterService.fetchTargets({
        page: state.page,
        limit: state.limit,
        sort: state.sort,
        direction: state.direction
      })
  })

  useEffect(() => {
    if (targetsQuery.data && targetsQuery.data.data) {
      let targetGroupsQueryData = [...targetsQuery.data.data]
      const targetGroupsData = targetGroupsQueryData.map((item) => {
        return {
          ...item,
          selected: formValues.includes(item.ref)
        }
      })
      setState({
        ...state,
        targetGroups: { ...targetsQuery.data, data: targetGroupsData },
        selectedTargetGroups: targetGroupsData.filter((item) =>
          formValues.includes(item.ref)
        )
      })
    } else {
      setState({
        ...state,
        targetGroups: { data: [] }
      })
    }
  }, [targetsQuery.data])

  useEffect(() => {
    const targetGroupsData = state.targetGroups.data.map((item) => {
      return {
        ...item,
        selected: formValues.includes(item.ref)
      }
    })
    setState({
      ...state,
      targetGroups: { ...state.targetGroups, data: [...targetGroupsData] },
      selectedTargetGroups: targetGroupsData.filter((item) =>
        formValues.includes(item.ref)
      )
    })
  }, [formValues])

  const setSort = (column, direction) => {
    setState({
      ...state,
      sort: column,
      direction: direction
    })
  }

  const setActivePage = (newActivePage) => {
    setState({ ...state, page: newActivePage - 1 })
  }

  const addTargetGroup = (ref) => {
    let selectedGroups = [...state.selectedTargetGroups]
    const selectedGroup = state.targetGroups.data.find(
      (item) => item.ref === ref
    )
    if (selectedGroup) {
      selectedGroups.push({ ...selectedGroup })
    }

    let targetGroupsData = [...state.targetGroups.data]
    const index = targetGroupsData.findIndex((item) => item.ref === ref)
    if (index !== -1) {
      targetGroupsData[index] = {
        ...targetGroupsData[index],
        selected: true
      }
    }

    setState({
      ...state,
      selectedTargetGroups: selectedGroups,
      targetGroups: { ...state.targetGroups, data: targetGroupsData }
    })

    onChangeValue(
      STEP_KEYS.TARGETGROUP,
      '',
      selectedGroups.map((item) => item.ref)
    )

    setError(false)
  }

  const duplicateTarget = (target) => {
    duplicateTargetGroup({ ...target })
  }

  const refreshTable = () => {
    queryClient.invalidateQueries({
      queryKey: [
        'messageServiceTargets',
        state.page,
        state.limit,
        state.sort,
        state.direction
      ]
    })
  }

  const unselectTargetGroup = (ref) => {
    let selectedGroups = [...state.selectedTargetGroups]
    const indexRemove = selectedGroups.findIndex((item) => item.ref === ref)
    if (indexRemove !== -1) {
      selectedGroups.splice(indexRemove, 1)
    }

    let targetGroupsData = [...state.targetGroups.data]
    const indexUnselect = targetGroupsData.findIndex((item) => item.ref === ref)
    if (indexUnselect !== -1) {
      targetGroupsData[indexUnselect] = {
        ...targetGroupsData[indexUnselect],
        selected: false
      }
    }

    setState({
      ...state,
      selectedTargetGroups: selectedGroups,
      targetGroups: { ...state.targetGroups, data: targetGroupsData }
    })

    onChangeValue(
      STEP_KEYS.TARGETGROUP,
      '',
      selectedGroups.map((item) => item.ref)
    )

    setError(false)
  }

  const onContinueClicked = () => {
    if (state.selectedTargetGroups.length > 0) {
      onDataCompleted(STEP_KEYS.TARGETGROUP)
    } else {
      setError(true)
    }
  }

  return (
    <>
      <TargetTable
        targets={state.targetGroups}
        sorting={{
          column: state.sort,
          direction: state.direction
        }}
        activePage={state.page + 1}
        onSort={setSort}
        onActivePageChanged={setActivePage}
        onAddTargetGroupClicked={addTargetGroup}
        onDuplicateTarget={duplicateTarget}
        refreshTable={refreshTable}
      ></TargetTable>
      <SelectedTargetGroups
        selectedTargetGroups={state.selectedTargetGroups}
        unselectTargetGroup={unselectTargetGroup}
      />
      {error && (
        <Message negative>
          <Message.Header>
            <FormattedMessage id="messageCenter.targetGroup.selector.action.add.groupMissing.title" />
          </Message.Header>
          <p>
            <FormattedMessage id="messageCenter.targetGroup.selector.action.add.groupMissing.body" />
          </p>
        </Message>
      )}
      <Button
        primary
        onClick={onContinueClicked}
        loading={loading}
        disabled={loading}
      >
        <FormattedMessage id="messageCenter.createMessage.action.saveAndContinue" />
      </Button>
    </>
  )
}

export default TargetGroupSelector
