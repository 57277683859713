import { get, post, put, remove } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/userGroups${relativePath}`
    : `/administration/userGroups${relativePath}`

const getFullPath = (isSuperOperator, operatorRealm, relativePath = '') =>
  !isSuperOperator
    ? `/operator/${operatorRealm}/userGroups${relativePath}`
    : `/administration/userGroups${relativePath}`

const fetchUserGroup = async (userGroupRef, operatorRealm) => {
  if (userGroupRef) {
    try {
      const result = await get(
        createFullPath(`/${userGroupRef}`, operatorRealm)
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('no usergroup ref provided'))
}

const fetchAllUserGroups = async (operatorRealm) => {
  try {
    const result = await get(createFullPath('', operatorRealm))
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const createUserGroup = async (isSuperOperator, operatorRealm, body) => {
  return await post(getFullPath(isSuperOperator, operatorRealm), {
    name: body.name,
    operatorRef: body.operatorRef
  })
}

const fetchUserGroupsForRealm = async (operatorRealm) => {
  try {
    const result = await get(
      `/administration/userGroups?operatorRealm=${operatorRealm}`
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchServiceAgreements = async (userGroupRef, operatorRealm) => {
  try {
    const result = await get(
      createFullPath(`/${userGroupRef}/serviceAgreements`, operatorRealm)
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const setServiceAgreementPricingProfile = async (
  data,
  serviceAgreementRef,
  operatorRealm
) => {
  if (serviceAgreementRef) {
    const rel = `/${serviceAgreementRef}/setPricingProfile`
    const path = operatorRealm
      ? `/operator/${operatorRealm}/serviceAgreements${rel}`
      : `/administration/serviceAgreements${rel}`
    try {
      const result = await post(path, data)
      return result
    } catch (e) {
      return Promise.reject(e)
    }
  }
  return Promise.reject(Error('No serviceAgreement ref provided'))
}

const fetchUserGroupMemberships = async (userGroupRef, operatorRealm) => {
  if (userGroupRef) {
    try {
      const result = get(
        createFullPath(`/${userGroupRef}/memberships`, operatorRealm)
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(Error('No userGroup ref provided'))
  }
}

const fetchVoucherGrantAgreements = async (userGroupRef, operatorRealm) => {
  try {
    const result = await get(
      createFullPath(`/${userGroupRef}/voucherGrantAgreements`, operatorRealm)
    )
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const renameUserGroup = (userGroupRef, name, operatorRealm) => {
  return put(createFullPath(`/${userGroupRef}/rename`, operatorRealm), { name })
}

const addUserToGroup = async (userRef, userGroupRef, operatorRealm) => {
  try {
    await put(
      createFullPath(`/${userGroupRef}/addUser/${userRef}`, operatorRealm)
    )
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const removeUserGroup = async (userGroupRef, operatorRealm) => {
  try {
    await remove(createFullPath(`/${userGroupRef}`, operatorRealm))
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const removeUserFromGroup = async (userRef, userGroupRef, operatorRealm) => {
  try {
    await put(
      createFullPath(`/${userGroupRef}/removeUser/${userRef}`, operatorRealm)
    )
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const addServiceAgreement = async (serviceRef, userGroupRef, operatorRealm) => {
  try {
    const url = operatorRealm
      ? `/operator/${operatorRealm}/serviceAgreements`
      : `/administration/serviceAgreements`
    const result = await post(url, { serviceRef, userGroupRef })
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export default {
  fetchUserGroup,
  fetchAllUserGroups,
  fetchUserGroups: fetchUserGroupsForRealm,
  createUserGroup,
  fetchServiceAgreements,
  setServiceAgreementPricingProfile,
  fetchUserGroupMemberships,
  fetchVoucherGrantAgreements,
  renameUserGroup,
  addUserToGroup,
  removeUserFromGroup,
  addServiceAgreement,
  removeUserGroup
}
