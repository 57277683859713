import * as yup from 'yup'

import { Button, Form, Grid } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ROLE_FINANCES, getStringValue } from '../../services/utils'
import React, { useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import MoneyAccountInput from './moneyAccountInput'
import { MutationErrorMessage } from '../../components'
import { moneyTransferTypesEnum } from '../../services/utils/DTOEnums'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import transferMoneyService from '../../services/Adyen/TransferMoney'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useMutation, useQuery } from '@tanstack/react-query'

const MoneyTransfersContent = () => {
  const intl = useIntl()
  const { isSuperOperator, roles } = useAuthInfo()
  const [moneyFromPlatform, setMoneyFromPlatform] = useState(true)
  const [selectedSettlementRecipient, setSelectedSettlementRecipient] =
    useState('')

  const transferTypeFilters = {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT'
  }

  const validationSchema = () => {
    return yup.object().shape({
      platform: yup.string().required('Cannot be empty'),
      accountRef: yup.string().required('Cannot be empty'),
      amount: yup.string().required('Cannot be empty'),
      transferType: yup.string().required('Cannot be empty'),
      comment: yup.string().required('Cannot be empty')
    })
  }

  const transferTypesQuery = useQuery({
    queryKey: ['transferTypes', moneyFromPlatform],
    queryFn: () =>
      transferMoneyService.getTransferMoneyTypes(
        moneyFromPlatform
          ? transferTypeFilters.CREDIT
          : transferTypeFilters.DEBIT
      )
  })

  const transferOptions =
    transferTypesQuery.data != null
      ? transferTypesQuery.data
          .sort((a, b) => a.localeCompare(b))
          .map((item) => ({
            key: item,
            text: item,
            value: item
          }))
      : []

  const { mutate: transferMoneyToAccount, ...transferMoneyToAccountMutation } =
    useMutation({
      mutationFn: (data) =>
        transferMoneyService.transferMoneyToAccount(
          data.data.settlementRecipientRef,
          data.data,
          null
        ),
      onSuccess: (response, variables) => {
        showLocalizedMoovyToast(intl, {
          title:
            'moneyTransfersContent.action.transferMoneyToAccount.success.title',
          description:
            'moneyTransfersContent.action.transferMoneyToAccount.success.description'
        })
        const { resetForm } = variables
        setSelectedSettlementRecipient('')
        resetForm({})
      }
    })

  const {
    mutate: transferMoneyFromAccount,
    ...transferMoneyFromAccountMutation
  } = useMutation({
    mutationFn: (data) =>
      transferMoneyService.transferMoneyFromAccount(
        data.data.settlementRecipientRef,
        data.data,
        null
      ),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title:
          'moneyTransfersContent.action.transferMoneyToAccount.success.title',
        description:
          'moneyTransfersContent.action.transferMoneyToAccount.success.description'
      })
      const { resetForm } = variables
      setSelectedSettlementRecipient('')
      resetForm({})
    }
  })

  const MoneyPlatformInput = ({
    values,
    handleChange,
    errors,
    moneyFromPlatform
  }) => {
    return (
      <Form.Input
        label={intl.formatMessage(
          (moneyFromPlatform && {
            id: 'moneyTransfersContent.form.label.moneySource'
          }) || {
            id: 'moneyTransfersContent.form.label.moneyTarget'
          }
        )}
        name="platform"
        value={values.platform}
        onChange={handleChange}
        readOnly={true}
        error={!!errors.platform}
      />
    )
  }

  return (
    <>
      {roles.includes(ROLE_FINANCES) && isSuperOperator && (
        <Formik
          initialValues={{
            platform: intl.formatMessage({
              id: 'moneyTransfersContent.form.input.platform'
            }),
            accountRef: '',
            amount: '',
            transferType: '',
            comment: ''
          }}
          onSubmit={(values, { resetForm }) => {
            const filledValues = {
              amount: values.amount,
              transferType: values.transferType,
              description: values.comment,
              settlementRecipientRef: values.accountRef
            }

            transferMoneyToAccountMutation.reset()
            transferMoneyFromAccountMutation.reset()

            if (moneyFromPlatform) {
              transferMoneyToAccount({
                data: filledValues,
                resetForm: resetForm
              })
            } else {
              transferMoneyFromAccount({
                data: filledValues,
                resetForm: resetForm
              })
            }
          }}
          validationSchema={validationSchema()}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <>
              <Grid style={{ maxWidth: '1000px' }}>
                <Grid.Column>
                  <Form onSubmit={handleSubmit}>
                    <Form.Field>
                      <MutationErrorMessage
                        mutation={transferMoneyToAccountMutation}
                        defaultErrorTextLangId="moneyTransfersContent.action.transfer.failed"
                        messageNegative
                        style={{ maxWidth: '1000px' }}
                      />
                      <MutationErrorMessage
                        mutation={transferMoneyFromAccountMutation}
                        defaultErrorTextLangId="moneyTransfersContent.action.transfer.failed"
                        messageNegative
                        style={{ maxWidth: '1000px' }}
                      />
                    </Form.Field>
                    <Form.Group widths="equal">
                      {(moneyFromPlatform && (
                        <MoneyPlatformInput
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          moneyFromPlatform={moneyFromPlatform}
                        />
                      )) || (
                        <MoneyAccountInput
                          setFieldValue={setFieldValue}
                          errors={errors}
                          moneyFromPlatform={moneyFromPlatform}
                          selectedItem={selectedSettlementRecipient}
                          setSelectedItem={setSelectedSettlementRecipient}
                        />
                      )}
                      <Form.Field width={'2'}>
                        <label style={{ textAlign: 'center' }}>
                          <FormattedMessage id="moneyTransfersContent.form.label.switch" />
                        </label>
                        <Form.Button
                          width={'2'}
                          onClick={(e) => {
                            setMoneyFromPlatform(!moneyFromPlatform)
                            setFieldValue('transferType', '')
                            e.preventDefault()
                          }}
                        >
                          <FontAwesomeIcon icon={['far', 'repeat-alt']} />
                        </Form.Button>
                      </Form.Field>
                      {(!moneyFromPlatform && (
                        <MoneyPlatformInput
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          moneyFromPlatform={moneyFromPlatform}
                        />
                      )) || (
                        <MoneyAccountInput
                          setFieldValue={setFieldValue}
                          errors={errors}
                          moneyFromPlatform={moneyFromPlatform}
                          selectedItem={selectedSettlementRecipient}
                          setSelectedItem={setSelectedSettlementRecipient}
                        />
                      )}
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Select
                        label={intl.formatMessage({
                          id: 'moneyTransfersContent.form.label.transferCode'
                        })}
                        placeholder={intl.formatMessage({
                          id: 'moneyTransfersContent.form.label.transferCode'
                        })}
                        value={values.transferType}
                        name="transferType"
                        options={transferOptions}
                        onChange={(e, value) => {
                          setFieldValue(value.name, value.value)
                        }}
                        error={!!errors.transferType}
                        selectOnBlur={false}
                      />
                      <Form.Input
                        label={intl.formatMessage({
                          id: 'moneyTransfersContent.form.label.amount'
                        })}
                        name="amount"
                        value={values.amount}
                        onChange={handleChange}
                        error={!!errors.amount}
                        type={'number'}
                      />
                    </Form.Group>
                    <Form.Field>
                      <label>
                        <FormattedMessage id="moneyTransfersContent.form.label.transferCodeDescription" />
                      </label>
                      {(values.transferType &&
                        intl.formatMessage({
                          id: getStringValue(
                            moneyTransferTypesEnum.localizationKeys,
                            values.transferType
                          )
                        })) ||
                        '-'}
                    </Form.Field>
                    <Form.TextArea
                      onChange={handleChange}
                      name="comment"
                      value={values.comment}
                      label={intl.formatMessage({
                        id: 'moneyTransfersContent.form.label.comment'
                      })}
                      error={!!errors.comment}
                    ></Form.TextArea>
                    <Button
                      primary
                      loading={
                        transferMoneyToAccountMutation.isPending ||
                        transferMoneyFromAccountMutation.isPending
                      }
                      disabled={
                        transferMoneyToAccountMutation.isPending ||
                        transferMoneyFromAccountMutation.isPending
                      }
                      onClick={handleSubmit}
                      type="submit"
                    >
                      <FormattedMessage id="moneyTransfersContent.form.button.transfer" />
                    </Button>
                  </Form>
                </Grid.Column>
              </Grid>
            </>
          )}
        </Formik>
      )}
    </>
  )
}

export default MoneyTransfersContent
