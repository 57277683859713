import './GrantedVouchersList.scss'

import {
  GrantedVoucherManageModal,
  GrantedVouchersTable,
  MoovyButton
} from '../../../components'
import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { showToastGrantedVoucherCreated } from '../../../components/MoovyToast/GrantedVoucherToast'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { userVoucherService } from '../../../services'

const GrantedVouchersList = ({ voucherTemplate }) => {
  const vouchersInitialState = {
    loading: false,
    error: false,
    data: []
  }

  const paginationInitialState = {
    activePage: 1,
    totalPages: 1,
    sortColumn: 'sortByValidFrom',
    direction: 'DESC'
  }

  const directions = {
    DESC: 'descending',
    ASC: 'ascending'
  }

  const { operatorRealm } = useAuthInfo()

  const [paginationProps, setPagination] = useState(paginationInitialState)
  const [vouchers, setVouchers] = useState(vouchersInitialState)
  const [grantVoucherModal, setGrantVoucherModal] = useState(false)

  const pageLimit = 20

  useEffect(() => {
    searchVouchers(paginationProps)
  }, [voucherTemplate])

  useEffect(() => {
    searchVouchers(paginationProps)
  }, [
    paginationProps.activePage,
    paginationProps.sortColumn,
    paginationProps.direction
  ])

  const onSaveGrantedVoucher = (voucher) => {
    setGrantVoucherModal(false)
    showToastGrantedVoucherCreated(voucher)
    searchVouchers(paginationProps)
  }

  const onHandleSort = (sortColumn) => () => {
    if (sortColumn === paginationProps.sortColumn) {
      const direction = paginationProps.direction === 'DESC' ? 'ASC' : 'DESC'

      setPagination({
        ...paginationProps,
        activePage: 1,
        direction
      })
    } else {
      setPagination({
        ...paginationProps,
        activePage: 1,
        direction: 'DESC',
        sortColumn
      })
    }
  }

  const searchVouchers = ({ activePage, sortColumn, direction }) => {
    if (!voucherTemplate || !voucherTemplate.ref) {
      return
    }

    setVouchers({ ...vouchers, loading: true, error: false })

    const queryParams = {
      page: activePage > 0 ? activePage - 1 : 0,
      limit: pageLimit,
      sort: sortColumn,
      direction,
      voucherTemplateRef: voucherTemplate.ref,
      hideExpiredVouchers: false
    }

    userVoucherService
      .fetchVouchersByFilter(operatorRealm, queryParams)
      .then((response) => {
        const data = response ? response.data : []

        setVouchers({
          ...vouchers,
          data,
          loading: false
        })

        setPagination({
          ...paginationProps,
          totalPages: response.totalPages
        })
      })
      .catch((error) => {
        console.warn(error)
        setVouchers({
          ...vouchers,
          data: [],
          loading: false
        })
        setPagination(paginationInitialState)
      })
  }

  return (
    <>
      <div className="grant-new-voucher-container">
        <div>
          {(!operatorRealm && voucherTemplate && voucherTemplate.active && (
            <MoovyButton onClick={() => setGrantVoucherModal(true)}>
              <FontAwesomeIcon
                icon={['fa', 'hand-holding-dollar']}
                style={{ marginRight: '6px' }}
              />
              Myönnä uusi pysäköintietu
            </MoovyButton>
          )) ||
            ''}
        </div>
      </div>

      <GrantedVouchersTable
        activePage={paginationProps.activePage}
        totalPages={paginationProps.totalPages}
        onPageChange={(e, { activePage }) => {
          setPagination((previous) => ({ ...previous, activePage }))
        }}
        vouchers={vouchers.data}
        direction={directions[paginationProps.direction]}
        sortColumn={paginationProps.sortColumn}
        handleSort={onHandleSort}
        onRefresh={() => searchVouchers(paginationProps)}
        isLoading={vouchers.loading}
      />

      {grantVoucherModal && (
        <GrantedVoucherManageModal
          defaultTemplate={voucherTemplate}
          onSaveGrantedVoucher={onSaveGrantedVoucher}
          onClose={() => {
            setGrantVoucherModal(false)
          }}
        />
      )}
    </>
  )
}

export default GrantedVouchersList
