import { Button, Icon, Search } from 'semantic-ui-react'
import { searchLocation, searchUser } from '../../services/Search'

import PropTypes from 'prop-types'
import React from 'react'
import { searchEntityTypeEnum } from '../../services/utils/DTOEnums'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useDebounce } from 'use-debounce'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'

const MoovySearchInput = ({
  inputKey,
  selectedItem,
  setSelectedItem,
  entityType
}) => {
  const intl = useIntl()

  const { operatorRealm, isSuperOperator } = useAuthInfo()
  const [state, setState] = useGlobalUI({
    key: inputKey,
    initialValue: {
      searchValue: ''
    }
  })

  const [searchDebounce] = useDebounce(state.searchValue, 750)

  const searchUserQuery = useQuery({
    queryKey: ['fetchUserSearch', searchDebounce],
    queryFn: () => searchUser(operatorRealm, searchDebounce),
    enabled: Boolean(
      entityType === searchEntityTypeEnum.USER && searchDebounce?.length > 2
    ),
    staleTime: 1000 * 60
  })
  const searchQuery = useQuery({
    queryKey: ['fetchLocationSearch', searchDebounce],
    queryFn: () => searchLocation(searchDebounce, true),
    enabled: Boolean(
      isSuperOperator &&
        entityType === searchEntityTypeEnum.LOCATION &&
        searchDebounce?.length > 2
    ),
    staleTime: 1000 * 60
  })

  const searchData =
    entityType === searchEntityTypeEnum.USER
      ? searchUserQuery.data
      : searchQuery.data

  const results = searchData
    ? searchData
        .filter(
          (item) => (entityType && item.type === entityType) || !entityType
        )
        .map((item) => {
          return {
            title: item.description,
            description: '',
            ref: item.ref,
            key: item.ref
          }
        })
    : []

  const onClear = () => {
    setState({
      ...state,
      searchValue: ''
    })
  }

  const searchIcon = {
    name: 'search',
    color: 'grey'
  }
  const clearIcon = {
    name: 'remove',
    link: true,
    color: 'grey',
    onClick: onClear
  }
  const showClearIcon = onClear && state?.searchValue?.length > 0
  const isLoading = searchUserQuery.isFetching || searchQuery.isFetching

  return (
    <>
      <Search
        minCharacters={3}
        input={{
          fluid: true,
          icon: showClearIcon ? clearIcon : searchIcon,
          iconPosition: showClearIcon ? null : 'left',
          value: state.searchValue,
          placeholder: intl.formatMessage({
            id: 'moovySearchInput.search.placeholder'
          }),
          loading: isLoading
        }}
        showNoResults={!isLoading}
        noResultsMessage={intl.formatMessage({
          id: 'common.noResultsMessage'
        })}
        onSearchChange={(ev, data) => {
          setState({
            ...state,
            searchValue: data.value
          })
        }}
        onResultSelect={(e, data) => {
          setSelectedItem(data.result)
          setState({
            ...state,
            searchValue: data.result.title
          })
        }}
        results={results}
      />
      <div>
        {(selectedItem && (
          <Button
            style={{ marginTop: '1em', marginLeft: '1em' }}
            icon
            size="mini"
            labelPosition="right"
            onClick={() => {
              setSelectedItem('')
              setState({
                ...state,
                searchValue: ''
              })
            }}
          >
            {selectedItem.title}
            <Icon name="close" />
          </Button>
        )) ||
          ''}
      </div>
    </>
  )
}

MoovySearchInput.propTypes = {
  inputKey: PropTypes.string.isRequired,
  selectedItem: PropTypes.string.isRequired,
  setSelectedItem: PropTypes.func.isRequired,
  entityType: PropTypes.string.isRequired
}

export default MoovySearchInput
