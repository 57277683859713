import React, { useEffect, useState } from 'react'

import MoovyPlaceholderLoader from '../../../../components/MoovyPlaceholderLoader'
import VoucherModal from '../../../../components/VoucherModal'
import VouchersTable from '../../../../components/VouchersTable'
import userVoucherService from '../../../../services/UserVouchers'

const pageSize = 20
const UserVouchers = ({ operatorRealm, userRef }) => {
  const [loading, setLoading] = useState(true)
  const [vouchers, setVouchers] = useState([])
  const [selectedVoucher, setSelectedVoucher] = useState(null)
  const [page, setPage] = useState(1)

  useEffect(() => {
    setVouchers([])
    if (userRef) {
      setLoading(true)
      userVoucherService
        .fetchUserVouchers(userRef, operatorRealm)
        .then((res) => {
          setVouchers(
            res.data
              .sort((a, b) => new Date(a.grantedDate) - new Date(b.grantedDate))
              .reverse()
          )
        })
        .finally(() => setLoading(false))
    }
  }, [userRef])

  if (loading) {
    return <MoovyPlaceholderLoader paragraphs={3} />
  }

  const changePage = (e, { activePage }) => {
    setPage(activePage)
  }

  const onVoucherUpdate = (updated) => {
    const index = vouchers.findIndex((voucher) => voucher.ref === updated.ref)
    const updatedVouchers = [...vouchers]
    updatedVouchers[index] = updated
    setSelectedVoucher(updated)
    setVouchers(updatedVouchers)
  }

  const pagedVouchers = vouchers.slice((page - 1) * pageSize, page * pageSize)
  return (
    <>
      <VouchersTable
        vouchers={pagedVouchers}
        onClickVoucher={setSelectedVoucher}
        activePage={page}
        totalResults={vouchers.length}
        changePage={changePage}
      />
      {selectedVoucher && (
        <VoucherModal
          operatorRealm={operatorRealm}
          onClose={() => setSelectedVoucher(null)}
          voucher={selectedVoucher}
          onVoucherUpdate={onVoucherUpdate}
          allowEdit
        />
      )}
    </>
  )
}

export default UserVouchers
