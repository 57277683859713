import './Locations.scss'

import {
  PermissionLocation,
  getFormattedLocationName
} from '../../services/utils'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import DescriptionTab from './tabDescription'
import { Helmet } from 'react-helmet'
import LocationServices from './tabServices/locationServices'
import LocationToolbar from './locationToolbar/LocationToolbar'
import LocationVouchers from './tabVouchers/locationVouchers'
import ModalEditLocationName from './modalEditName'
import ModalGeoJSON from './modalGeoJSON'
import ModalLocationEdit from './modalLocationEdit'
import ParkingEndedTab from './tabParkingEnded'
import ParkingNowTab from './tabParkingNow'
import { Tab } from 'semantic-ui-react'
import { Toolbar } from '../../components'
import VehicleAccessLogs from './tabVehicleAccessLogs/vehicleAccessLogs'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import { locationEnums } from '../../services/utils/DTOEnums'
import locationService from '../../services/Locations'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useIntl } from 'react-intl'

const Locations = () => {
  const TabKeyEnum = {
    PARKING_ONGOING: 'parkingOngoingTab',
    PARKING_ENDED: 'parkingEndedTab',
    VOUCHER: 'vouchersTab',
    DESCRIPTION: 'descriptionTab',
    SERVICES: 'servicesTab',
    VEHICLE_LOGS: 'vehicleLogsTab'
  }

  const { locationRef } = useParams()
  const { operatorRealm, isSuperOperator, roles } = useAuthInfo()
  const intl = useIntl()
  const queryClient = useQueryClient()

  const [activeTabKey, setActiveTabKey] = useState(TabKeyEnum.PARKING_ONGOING)
  const [locationEditModalOpen, setLocationEditModalOpen] = useState(false)
  const [locationNameEditModalOpen, setLocationNameEditModalOpen] =
    useState(false)

  const [updateAreaModalOpen, setUpdateAreaModalOpen] = useState(false)

  const location = useQuery({
    queryKey: ['location', locationRef],
    queryFn: () => locationService.fetchLocation(locationRef, operatorRealm)
  })

  const {
    mutate: updateLocationOperationalData,
    ...updateLocationOperationalDataMutation
  } = useMutation({
    mutationFn: (locationUpdateData) => {
      return locationService.updateLocationOperationalData(
        operatorRealm,
        locationUpdateData.locationRef,
        locationUpdateData
      )
    },
    onSuccess: (updatedLocation) => {
      setLocationEditModalOpen(false)
      queryClient.invalidateQueries({
        queryKey: ['location', updatedLocation.ref]
      })
      showLocalizedMoovyToast(intl, {
        title: 'locationEditModal.toast.update.title',
        description: 'locationEditModal.toast.update.body',
        values: {
          name: updatedLocation.name
        }
      })
    }
  })

  const { mutate: updateLocationData, ...updateLocationDataMutation } =
    useMutation({
      mutationFn: (locationUpdateData) =>
        locationService.updateLocationData(
          null,
          locationUpdateData.locationRef,
          locationUpdateData
        ),
      onSuccess: (updatedLocation) => {
        setLocationNameEditModalOpen(false)
        queryClient.invalidateQueries({
          queryKey: ['location', updatedLocation.ref]
        })
        showLocalizedMoovyToast(intl, {
          title: 'locationEditNameModal.toast.updateName.title',
          description: 'locationEditNameModal.toast.updateName.body',
          values: {
            name: updatedLocation.name
          }
        })
      }
    })

  const breadcrumbs = location.data
    ? isSuperOperator
      ? createBreadcrumbs([
          {
            text: intl.formatMessage({
              id: 'common.breadcrumbs.home'
            }),
            href: '/'
          },
          {
            text: location.data.operator.name,
            href: `operators/${location.data.operator.ref}`
          },
          { text: location.data.name, active: true }
        ])
      : createBreadcrumbs([
          {
            text: intl.formatMessage({
              id: 'common.breadcrumbs.home'
            }),
            href: '/'
          },
          { text: location.data.name, active: true }
        ])
    : createBreadcrumbs([
        {
          text: intl.formatMessage({
            id: 'common.breadcrumbs.home'
          }),
          href: '/'
        }
      ])

  const panes = [
    {
      menuItem: intl.formatMessage({
        id: 'locations.tab.parkingNow'
      }),
      pane: {
        key: TabKeyEnum.PARKING_ONGOING,
        content: (
          <Tab.Pane>
            <ParkingNowTab
              location={location.data}
              activeTab={activeTabKey === TabKeyEnum.PARKING_ONGOING}
            />
          </Tab.Pane>
        )
      }
    },
    {
      menuItem: intl.formatMessage({
        id: 'locations.tab.parkingEnded'
      }),
      pane: {
        key: TabKeyEnum.PARKING_ENDED,
        content: (
          <Tab.Pane>
            <ParkingEndedTab
              location={location.data}
              activeTab={activeTabKey === TabKeyEnum.PARKING_ENDED}
            />
          </Tab.Pane>
        )
      }
    },
    {
      menuItem: intl.formatMessage({
        id: 'locations.tab.vouchers'
      }),
      pane: {
        key: TabKeyEnum.VOUCHER,
        content: (
          <div>
            <LocationVouchers
              operatorRealm={operatorRealm}
              locationRef={locationRef}
            />
          </div>
        )
      }
    },
    {
      menuItem: intl.formatMessage({
        id: 'locations.tab.description'
      }),
      pane: {
        key: TabKeyEnum.DESCRIPTION,
        content: (
          <div>
            <DescriptionTab
              queryLocation={location}
              refresh={() => location.refetch()}
            />
          </div>
        )
      }
    },
    {
      menuItem: intl.formatMessage({
        id: 'locations.tab.services'
      }),
      pane: {
        key: TabKeyEnum.SERVICES,
        content: (
          <div>
            <LocationServices locationRef={location.data?.ref} />
          </div>
        )
      }
    },
    {
      menuItem: intl.formatMessage({
        id: 'locations.tab.vehicleAccessLogs'
      }),
      pane: {
        key: TabKeyEnum.VEHICLE_LOGS,
        content: (
          <div>
            <VehicleAccessLogs locationRef={location.data?.ref} />
          </div>
        )
      },
      hide: !PermissionLocation.HasViewVehicleAccessLogs(isSuperOperator, roles)
    }
  ]

  const visiblePanes = panes.filter((pane) => !pane.hide)

  const getToolbarButtons = (location) => {
    const buttons = []
    if (
      PermissionLocation.HasEditData(isSuperOperator, roles) &&
      (location?.type === locationEnums.locationType.FACILITY ||
        location?.type === locationEnums.locationType.LOT)
    ) {
      buttons.push({
        icon: 'setting',
        tooltip: intl.formatMessage({
          id: 'locations.toolbar.button.editLocation'
        }),
        action: () => setLocationEditModalOpen(true)
      })
    }
    if (PermissionLocation.HasEditGeoJson(isSuperOperator, roles)) {
      buttons.push({
        icon: 'map',
        tooltip: intl.formatMessage({
          id: 'locations.toolbar.button.editArea'
        }),
        action: () => setUpdateAreaModalOpen(true)
      })
    }
    if (PermissionLocation.HasEditName(isSuperOperator, roles)) {
      buttons.push({
        icon: 'edit',
        tooltip: intl.formatMessage({
          id: 'locations.toolbar.button.editName'
        }),
        action: () => setLocationNameEditModalOpen(true)
      })
    }

    return buttons
  }

  return (
    <div style={{ background: 'white', paddingBottom: '3em' }}>
      {location.data && (
        <>
          {location.data && location.data.name && (
            <Helmet>
              <title>{getFormattedLocationName(location.data)}</title>
            </Helmet>
          )}
          <Toolbar
            title={getFormattedLocationName(location.data)}
            breadcrumbs={breadcrumbs}
            buttons={getToolbarButtons(location.data)}
            content={
              <LocationToolbar
                loc={location.data}
                refresh={() =>
                  queryClient.invalidateQueries({
                    queryKey: ['location', locationRef]
                  })
                }
              />
            }
          />
          {locationEditModalOpen && location.data ? (
            <ModalLocationEdit
              onClose={() => {
                updateLocationOperationalDataMutation.reset()
                setLocationEditModalOpen(false)
              }}
              location={location.data}
              onSubmit={updateLocationOperationalData}
              mutation={updateLocationOperationalDataMutation}
            />
          ) : null}
          {locationNameEditModalOpen && (
            <ModalEditLocationName
              onClose={() => {
                updateLocationDataMutation.reset()
                setLocationNameEditModalOpen(false)
              }}
              location={location.data}
              onSubmit={updateLocationData}
              mutation={updateLocationDataMutation}
            />
          )}
          {updateAreaModalOpen && (
            <ModalGeoJSON
              location={location.data}
              onClose={() => setUpdateAreaModalOpen(false)}
            />
          )}
          <Tab
            style={{
              marginLeft: '3em',
              marginRight: '3em',
              background: 'white'
            }}
            renderActiveOnly={false}
            panes={visiblePanes}
            onTabChange={(event, data) =>
              setActiveTabKey(visiblePanes.at(data.activeIndex)?.pane?.key)
            }
          />
        </>
      )}
    </div>
  )
}

export default Locations
