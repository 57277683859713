import './styles.scss'

import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import {
  Button,
  Container,
  Divider,
  Header,
  Message,
  Radio
} from 'semantic-ui-react'

import useAuthInfo from '../../../hooks/useAuthInfo'
import parkingService from '../../../services/Parking'
import AllowExitForm from './AllowExitForm'
import EndAnonymousParkingForm from './EndAnonymousParkingForm'
import EndAnonymousParkingWithErrorForm from './EndAnonymousParkingWithErrorForm'

const endModes = {
  END_WITH_ERROR: 'END_WITH_ERROR',
  END: 'END',
  ALLOW_EXIT: 'ALLOW_EXIT',
  labels: {
    END_WITH_ERROR: [
      'Lopeta pysäköinti ilman veloitusta',
      'Asiakas ei joudu maksamaan pysäköintiä'
    ],
    END: [
      'Lopeta pysäköinti ja määritä päättymisaika',
      'Asiakasta veloitetaan päättymisajan mukaan'
    ],
    ALLOW_EXIT: [
      'Salli poistuminen kohteesta',
      'Asiakas saa ajaa ulos ja pysäköinti päättyy'
    ]
  }
}

const EditAnonymousParking = ({
  parking,
  barrierlessFacility,
  onUpdate,
  onHide,
  onAllowExit
}) => {
  const [selectedEndMode, setSelectedEndMode] = useState()
  const { operatorRealm } = useAuthInfo()

  const { mutate: endParking, ...endParkingMutation } = useMutation({
    mutationFn: ({ endTime, comment }) =>
      parkingService.endParking(
        parking.ref,
        endTime,
        comment,
        selectedEndMode,
        operatorRealm
      ),
    onSuccess: (data) => {
      onUpdate(data)
    }
  })

  const { mutate: allowExit, ...allowExitMutation } = useMutation({
    mutationFn: ({ comment }) =>
      parkingService.anonymousparkingAllowExit(
        parking.ref,
        comment,
        operatorRealm
      ),
    onSuccess: () => {
      onAllowExit()
    }
  })

  const ended = parking.end
  if (ended)
    return (
      <Container className="padding">
        <Message success>Pysäköinti päätetty</Message>
        <Button primary fluid onClick={onHide}>
          Sulje
        </Button>
      </Container>
    )

  // Different endModes and their correspondent form component. Barrierless facility has all three
  // normal facilities only END_WITH_ERROR and ALLOW_EXIT
  const endModeForms = {
    [endModes.END_WITH_ERROR]: (
      <EndAnonymousParkingWithErrorForm
        onSubmit={endParking}
        mutation={endParkingMutation}
        ended={ended}
      />
    ),
    [endModes.END]: (
      <EndAnonymousParkingForm
        parkingRef={parking.ref}
        onSubmit={endParking}
        mutation={endParkingMutation}
        started={parking.start}
        ended={ended}
      />
    ),
    [endModes.ALLOW_EXIT]: (
      <AllowExitForm
        onSubmit={allowExit}
        mutation={allowExitMutation}
        ended={ended}
      />
    )
  }

  const availableEndModes = barrierlessFacility
    ? [endModes.END_WITH_ERROR, endModes.END, endModes.ALLOW_EXIT]
    : [endModes.END_WITH_ERROR, endModes.ALLOW_EXIT]

  const changeEndMode = (value) => {
    endParkingMutation.reset()
    allowExitMutation.reset()
    setSelectedEndMode(value)
  }

  return (
    <Container className="padding">
      <Header size="small">Valitse tapa, jolla pysäköinti päätetään:</Header>
      {availableEndModes.map((endMode, index) => (
        <div key={endMode}>
          <ModeSelector
            endMode={endMode}
            onChange={changeEndMode}
            checked={selectedEndMode === endMode}
          />
          {selectedEndMode === endMode && endModeForms[endMode]}
          {index < availableEndModes.length - 1 && (
            <Divider className="dashed" />
          )}
        </div>
      ))}
    </Container>
  )
}

export default EditAnonymousParking

const ModeSelector = ({ endMode, checked, onChange }) => (
  <Radio
    label={{
      children: endModes.labels[endMode].map((label) => (
        <p key={label}>{label}</p>
      ))
    }}
    checked={checked}
    onChange={() => onChange(endMode)}
  />
)
