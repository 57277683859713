const fetchHeartBeatMessage = async () => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/moovy-health-check/v1`
    return fetch(url)
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchServiceBreakMessage = async () => {
  const url = `${process.env.REACT_APP_API_SERVICE_BREAK_INFO_URL}/servicebreakmessage/operator-message`
  const res = await fetch(url, { cache: 'no-cache' })
  if (!res.ok) {
    throw new Error('Network response was not ok')
  }
  return res.json()
}

export default {
  fetchHeartBeatMessage,
  fetchServiceBreakMessage
}
