import { FormattedMessage, useIntl } from 'react-intl'
import { Header, List } from 'semantic-ui-react'

import EditEnterpriseInvoicingInfo from './EditEnterpriseInvoicingInfo'
import InvoicingInformationByType from './InvoicingInformationByType'
import PropTypes from 'prop-types'
import React from 'react'

const InvoicingToolbar = ({
  customer,
  showEditInvoicingModal,
  setShowEditInvoicing
}) => {
  const intl = useIntl()

  const {
    firstName,
    lastName,
    email,
    streetAddress,
    city,
    postalCode,
    company,
    billingMethod,
    eInvoiceOperator,
    eInvoiceOvtNumber,
    customInvoiceReference
  } = customer.userDetails

  const invoicing = {
    type: billingMethod,
    ovt: eInvoiceOvtNumber,
    operator: eInvoiceOperator,
    firstName,
    lastName,
    email,
    streetAddress,
    city,
    postalCode,
    company,
    customInvoiceReference,
    userRef: customer.ref
  }
  return (
    <div>
      <Header as="h4">
        <FormattedMessage id="enterpriseCustomer.toolbar.invoicing" />
      </Header>
      <List>
        <List.Item
          header={intl.formatMessage({
            id: 'enterpriseCustomer.invoicing.type'
          })}
          content={intl.formatMessage({
            id: `enterpriseCustomer.invoicing.type.${invoicing.type}`
          })}
        />
        {invoicing.customInvoiceReference && (
          <List.Item
            header={intl.formatMessage({
              id: 'enterpriseCustomer.invoicing.customReference'
            })}
            content={invoicing.customInvoiceReference}
          />
        )}
      </List>
      <InvoicingInformationByType invoicingInfo={invoicing} />
      {showEditInvoicingModal && (
        <EditEnterpriseInvoicingInfo
          invoicingInfo={invoicing}
          onClose={() => setShowEditInvoicing(false)}
        />
      )}
    </div>
  )
}

InvoicingToolbar.propTypes = {
  customer: PropTypes.object.isRequired
}
export default InvoicingToolbar
