import { Grid, Header } from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import { MoovyRefreshTime } from '../../../components'
import ParkingEndedTable from './parkingEndedTable'
import React from 'react'

const ParkingEndedContent = ({
  parkingsQuery,
  initiallySelectedParkingRef,
  noContent,
  headerButtons,
  activePage,
  totalPages,
  sorting,
  onClickParking,
  onPageChange,
  onSort
}) => {
  const total = noContent ? 0 : parkingsQuery?.data?.pageable?.total || 0
  const parkings = noContent ? [] : parkingsQuery?.data?.content || []
  const currentActivePage = noContent ? 0 : activePage
  const currentTotalPages = noContent ? 0 : totalPages

  return (
    <>
      <Grid>
        <Grid.Column floated="left" width={8} verticalAlign="bottom">
          <Header size="small">
            <FormattedMessage
              id="parkingEndedContent.ongoingParking"
              values={{ pcs: total }}
            />
            <MoovyRefreshTime
              updatedAtAsMillis={noContent || parkingsQuery?.dataUpdatedAt}
              loading={parkingsQuery?.isLoading}
              parentheses
            />
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          {headerButtons}
        </Grid.Column>
      </Grid>
      <ParkingEndedTable
        parkingQuery={parkingsQuery}
        parkings={parkings}
        initiallySelectedParkingRef={initiallySelectedParkingRef}
        activePage={currentActivePage}
        totalPages={currentTotalPages}
        sorting={sorting}
        onPageChange={onPageChange}
        onClickParking={onClickParking}
        onSort={onSort}
      />
    </>
  )
}

export default ParkingEndedContent
