import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Grid } from 'semantic-ui-react'

import MoovyLink from '../../../components/MoovyLink'
import {
  getStringValue,
  messageCenterEnums
} from '../../../services/utils/DTOEnums'

export const MessageContent = ({
  formValues,
  readOnly,
  onChangeValue,
  onChecked,
  errors,
  prefix,
  copyToAllLanguages
}) => {
  const intl = useIntl()

  const internalCallToActions = [
    {
      key: messageCenterEnums.callToActionInternalType.SHOP,
      text: getStringValue(
        messageCenterEnums.callToActionInternalType.stringValues,
        messageCenterEnums.callToActionInternalType.SHOP
      ),
      value: messageCenterEnums.callToActionInternalType.SHOP
    },
    {
      key: messageCenterEnums.callToActionInternalType.SUBSCRIPTIONS,
      text: getStringValue(
        messageCenterEnums.callToActionInternalType.stringValues,
        messageCenterEnums.callToActionInternalType.SUBSCRIPTIONS
      ),
      value: messageCenterEnums.callToActionInternalType.SUBSCRIPTIONS
    },
    {
      key: messageCenterEnums.callToActionInternalType.VOUCHERS,
      text: getStringValue(
        messageCenterEnums.callToActionInternalType.stringValues,
        messageCenterEnums.callToActionInternalType.VOUCHERS
      ),
      value: messageCenterEnums.callToActionInternalType.VOUCHERS
    }
  ]

  return (
    <>
      <Form.Input
        label={intl.formatMessage({
          id: 'messageCenter.createMessage.step.messageContent.label.messageTitle'
        })}
        type="text"
        name={prefix + '.title'}
        readOnly={readOnly}
        value={formValues[prefix].title}
        onChange={(event, data) => {
          onChangeValue(event, data)
        }}
        error={errors && errors[prefix] && !!errors[prefix].title}
      ></Form.Input>
      <Form.Input
        label={
          <>
            <Grid>
              <Grid.Column floated="left" width={8}>
                <b>
                  {intl.formatMessage({
                    id: 'messageCenter.createMessage.step.messageContent.label.imageLink'
                  })}
                </b>
              </Grid.Column>
              <Grid.Column floated="right" width={8} textAlign="right">
                {!readOnly && (
                  <MoovyLink
                    onClick={() => copyToAllLanguages(prefix, 'imageUrl')}
                  >
                    {intl.formatMessage({
                      id: 'messageCenter.createMessage.step.messageContent.link.copyToAllLanguages'
                    })}
                  </MoovyLink>
                )}
              </Grid.Column>
            </Grid>
          </>
        }
        type="text"
        name={prefix + '.imageUrl'}
        readOnly={readOnly}
        value={formValues[prefix].imageUrl}
        onChange={(event, data) => {
          onChangeValue(event, data)
        }}
        error={errors && errors[prefix] && !!errors[prefix].imageUrl}
      ></Form.Input>
      <Form.Input
        label={
          <>
            <Grid>
              <Grid.Column floated="left" width={8}>
                <b>
                  {intl.formatMessage({
                    id: 'messageCenter.createMessage.step.messageContent.label.videoLink'
                  })}
                </b>
              </Grid.Column>
              <Grid.Column floated="right" width={8} textAlign="right">
                {!readOnly && (
                  <MoovyLink
                    onClick={() => copyToAllLanguages(prefix, 'videoUrl')}
                  >
                    {intl.formatMessage({
                      id: 'messageCenter.createMessage.step.messageContent.link.copyToAllLanguages'
                    })}
                  </MoovyLink>
                )}
              </Grid.Column>
            </Grid>
          </>
        }
        type="text"
        name={prefix + '.videoUrl'}
        readOnly={readOnly}
        value={formValues[prefix].videoUrl}
        onChange={(event, data) => {
          onChangeValue(event, data)
        }}
      ></Form.Input>
      <Form.TextArea
        label={intl.formatMessage({
          id: 'messageCenter.createMessage.step.messageContent.label.messageContent'
        })}
        name={prefix + '.description'}
        readOnly={readOnly}
        value={formValues[prefix].description}
        onChange={(event, data) => {
          onChangeValue(event, data)
        }}
      ></Form.TextArea>
      <Form.Field>
        <label>
          <FormattedMessage id="messageCenter.createMessage.step.messageContent.label.ctaLink" />
        </label>
        <Form.Group>
          <Form.Radio
            width={4}
            label={intl.formatMessage({
              id: 'messageCenter.createMessage.step.messageContent.label.ctaLinkOutside'
            })}
            name={prefix + '.callToAction.external'}
            readOnly={readOnly}
            checked={
              formValues[prefix].callToAction.type !== '' &&
              formValues[prefix].callToAction.type ===
                messageCenterEnums.callToActionType.EXTERNAL
            }
            onChange={(event, data) => {
              onChecked(
                prefix + '.callToAction.type',
                messageCenterEnums.callToActionType.EXTERNAL
              )
            }}
          ></Form.Radio>
          <Form.Radio
            width={4}
            label={intl.formatMessage({
              id: 'messageCenter.createMessage.step.messageContent.label.ctaLinkInside'
            })}
            name={prefix + '.callToAction.internal'}
            readOnly={readOnly}
            checked={
              formValues[prefix].callToAction.type !== '' &&
              formValues[prefix].callToAction.type ===
                messageCenterEnums.callToActionType.INTERNAL
            }
            onChange={(event, data) => {
              onChecked(
                prefix + '.callToAction.type',
                messageCenterEnums.callToActionType.INTERNAL
              )
            }}
          ></Form.Radio>
          <Form.Field width={8}>
            <Grid>
              <Grid.Column width={16} textAlign="right">
                {!readOnly && (
                  <MoovyLink
                    onClick={() => copyToAllLanguages(prefix, 'callToAction')}
                  >
                    {intl.formatMessage({
                      id: 'messageCenter.createMessage.step.messageContent.link.copyToAllLanguages'
                    })}
                  </MoovyLink>
                )}
              </Grid.Column>
            </Grid>
          </Form.Field>
        </Form.Group>
      </Form.Field>
      {formValues[prefix].callToAction.type ===
        messageCenterEnums.callToActionType.INTERNAL && (
        <Form.Dropdown
          placeholder={intl.formatMessage({
            id: 'messageCenter.createMessage.step.messageContent.placeholder.target'
          })}
          name={prefix + '.callToAction.internal'}
          disabled={readOnly}
          fluid
          search
          selection
          options={internalCallToActions}
          value={formValues[prefix].callToAction.internal}
          onChange={(event, data) => {
            onChangeValue(event, data)
          }}
          error={
            errors &&
            errors[prefix] &&
            !!errors[prefix].callToAction &&
            errors[prefix].callToAction.internal
          }
          selectOnBlur={false}
        />
      )}
      {formValues[prefix].callToAction.type ===
        messageCenterEnums.callToActionType.EXTERNAL && (
        <Form.Input
          type="text"
          name={prefix + '.callToAction.external'}
          readOnly={readOnly}
          value={formValues[prefix].callToAction.external}
          onChange={(event, data) => {
            onChangeValue(event, data)
          }}
          error={
            errors &&
            errors[prefix] &&
            !!errors[prefix].callToAction &&
            errors[prefix].callToAction.external
          }
        ></Form.Input>
      )}
      <Form.Input
        label={intl.formatMessage({
          id: 'messageCenter.createMessage.step.messageContent.label.ctaLinkText'
        })}
        type="text"
        name={prefix + '.callToAction.description'}
        readOnly={readOnly}
        value={formValues[prefix].callToAction.description || ''}
        onChange={(event, data) => {
          onChangeValue(event, data)
        }}
        error={
          errors &&
          errors[prefix] &&
          !!errors[prefix].callToAction &&
          errors[prefix].callToAction.description
        }
      ></Form.Input>
    </>
  )
}

export default MessageContent
