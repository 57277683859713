import * as yup from 'yup'

import { Button, Form, Grid, Header, Modal, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyIconInfo,
  MoovyTable,
  MutationErrorMessage
} from '../../components'

import { Formik } from 'formik'
import { formatDate, PermissionService } from '../../services/utils'
import React from 'react'
import { serviceType } from '../../services/utils/DTOEnums'
import useAuthInfo from '../../hooks/useAuthInfo'

const ModalEditCapacities = ({
  onClose,
  parkingService,
  onSubmit,
  mutation
}) => {
  const intl = useIntl()
  const { roles } = useAuthInfo()
  const allowToEditExceedingCapacity =
    PermissionService.HasEditAllowExceedingCapacity(roles)
  const prebookingVoucherPrediction =
    getPrebookingVoucherPrediction(parkingService)

  const validationSchema = () => {
    return yup.object().shape({
      capacity: yup.number().min(0),
      parkingCapacity: yup.number().min(0)
    })
  }

  const initialFormData = {
    capacity: parkingService.capacity,
    parkingCapacity: parkingService.parkingCapacity,
    exceedingParkingCapacityAllowed:
      parkingService.exceedingParkingCapacityAllowed
  }

  const hasValuesChanged = (values) => {
    const { capacity, parkingCapacity, exceedingParkingCapacityAllowed } =
      initialFormData
    return (
      values.parkingCapacity != parkingCapacity ||
      values.capacity != capacity ||
      values.exceedingParkingCapacityAllowed != exceedingParkingCapacityAllowed
    )
  }

  const handleFormitSubmit = (values) => {
    if (hasValuesChanged(values)) {
      const { exceedingParkingCapacityAllowed } = initialFormData
      const exceedingCapacityChanged =
        values.exceedingParkingCapacityAllowed !=
        exceedingParkingCapacityAllowed

      onSubmit({
        subscriptionServiceRef: parkingService.ref,
        capacity: values.capacity,
        parkingCapacity: values.parkingCapacity,
        exceedingParkingCapacityAllowed: exceedingCapacityChanged
          ? values.exceedingParkingCapacityAllowed
          : undefined
      })
    } else {
      onClose()
    }
  }

  const RenderSubscriptionCapacities = ({
    values,
    errors,
    handleChange,
    handleBlur
  }) => {
    return (
      <Form.Input
        fluid
        label={intl.formatMessage({
          id: 'modalEditCapacities.label.totalCapacity'
        })}
        value={values.capacity}
        placeholder={intl.formatMessage({
          id: 'common.unlimited'
        })}
        onChange={handleChange}
        onBlur={handleBlur}
        type="number"
        name="capacity"
        min={0}
        error={!!errors.capacity}
      />
    )
  }

  const RenderParkingCapacities = ({
    type,
    capacityRecommendation,
    values,
    errors,
    handleChange,
    handleBlur,
    setFieldValue
  }) => {
    return (
      <>
        <Form.Field>
          <label>
            <MoovyIconInfo
              iconLabel={
                <FormattedMessage id="modalEditCapacities.label.parkingCapacity" />
              }
            >
              <FormattedMessage id="modalEditCapacities.label.parkingCapacity.info.header" />
              <ul>
                <li>
                  <FormattedMessage id="modalEditCapacities.label.parkingCapacity.info.bullet1" />
                </li>
                <li>
                  <FormattedMessage id="modalEditCapacities.label.parkingCapacity.info.bullet2" />
                </li>
              </ul>
            </MoovyIconInfo>
          </label>
          <Form.Input
            fluid
            value={values.parkingCapacity}
            placeholder={intl.formatMessage({
              id: 'common.unlimited'
            })}
            onChange={(event) => {
              handleChange(event)
              if (
                values.exceedingParkingCapacityAllowed &&
                (!event.target.value || event.target.value <= 0)
              ) {
                setFieldValue('exceedingParkingCapacityAllowed', false)
              }
            }}
            onBlur={handleBlur}
            type="number"
            min={0}
            name="parkingCapacity"
            error={!!errors.parkingCapacity}
          />
        </Form.Field>
        {capacityRecommendation?.paygCapacityRecommendation && (
          <p>
            <FormattedMessage
              id="modalEditCapacities.prebookingVoucherPrediction.forecast"
              values={{
                b: (chunks) => <b>{chunks}</b>,
                span: (chunks) => (
                  <span className="redColor bold">{chunks}</span>
                ),
                prebookedToday:
                  capacityRecommendation.paygCapacityRecommendation
                    .prebookedToday,
                capacityRecommendation:
                  capacityRecommendation.paygCapacityRecommendation
                    .capacityRecommendation
              }}
            />
          </p>
        )}
        {allowToEditExceedingCapacity && (
          <Form.Field>
            <label>
              <FormattedMessage id="modalEditCapacities.label.exceedingParkingCapacityAllowed" />
            </label>
            <Form.Checkbox
              disabled={!values.parkingCapacity || values.parkingCapacity <= 0}
              checked={values.exceedingParkingCapacityAllowed}
              name="exceedingParkingCapacityAllowed"
              label={
                type === serviceType.SUBSCRIPTION
                  ? intl.formatMessage({
                      id: 'modalEditCapacities.label.exceedingParkingCapacityAllowedForSubscription'
                    })
                  : intl.formatMessage({
                      id: 'modalEditCapacities.label.exceedingParkingCapacityAllowedForPAYG'
                    })
              }
              onClick={(e, { name, checked }) => {
                setFieldValue(name, checked)
              }}
            />
          </Form.Field>
        )}
      </>
    )
  }

  return (
    <Formik
      initialValues={{ ...initialFormData }}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue
      }) => (
        <Modal
          open
          onClose={onClose}
          size={prebookingVoucherPrediction ? null : 'small'}
        >
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'modalEditCapacities.title'
              },
              {
                name: parkingService.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="modalEditCapacities.action.error.update"
              messageNegative
            />
            <Grid
              columns={prebookingVoucherPrediction ? 2 : 1}
              divided={!!prebookingVoucherPrediction}
            >
              <Grid.Row>
                <Grid.Column>
                  <Form onSubmit={handleSubmit}>
                    {parkingService.type === serviceType.SUBSCRIPTION && (
                      <RenderSubscriptionCapacities
                        values={values}
                        errors={errors}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        setFieldValue={setFieldValue}
                      />
                    )}
                    <RenderParkingCapacities
                      type={parkingService.type}
                      capacityRecommendation={prebookingVoucherPrediction}
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      setFieldValue={setFieldValue}
                    />
                  </Form>
                </Grid.Column>
                {prebookingVoucherPrediction && (
                  <Grid.Column>
                    <Header as="h5">
                      <FormattedMessage
                        id="modalEditCapacities.prebookingVoucherPrediction.header"
                        values={{
                          days: prebookingVoucherPrediction
                            .prebookedQuotaPredictions.length
                        }}
                      />
                    </Header>
                    <MoovyTable
                      items={
                        prebookingVoucherPrediction.prebookedQuotaPredictions
                      }
                      striped
                      basic="very"
                      collapsing
                    >
                      <Table.Body>
                        {prebookingVoucherPrediction.prebookedQuotaPredictions.map(
                          (row) => (
                            <Table.Row key={row.date}>
                              <Table.Cell>{formatDate(row.date)}</Table.Cell>
                              <Table.Cell>
                                <span className="bold">
                                  <FormattedMessage
                                    id="modalEditCapacities.prebookingVoucherPrediction.prediction"
                                    values={{ prediction: row.prediction }}
                                  />
                                </span>
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                      </Table.Body>
                    </MoovyTable>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              autoFocus={true}
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={mutation.isPending || !hasValuesChanged(values)}
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

function getPrebookingVoucherPrediction(parkingService) {
  if (!parkingService.prebookingVoucherPrediction) {
    return null
  }

  return {
    paygCapacityRecommendation:
      parkingService.prebookingVoucherPrediction.paygCapacityRecommendation,
    prebookedQuotaPredictions: Object.entries(
      parkingService.prebookingVoucherPrediction
        .prebookedQuotaPredictionByDays ?? []
    )
      .map(([key, value]) => ({
        date: key,
        prediction: value
      }))
      .slice(0, 7)
      .reverse()
  }
}

export default ModalEditCapacities
