import './GrantedVouchers.scss'

import { Card, Form, Grid, Header, List, Segment } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import GenericMessage, {
  createErrorMessage
} from '../../../../components/GenericMessage'
import React, { useEffect, useState } from 'react'
import {
  grantedVoucherService,
  voucherTemplateService
} from '../../../../services'

import { DateInput } from 'semantic-ui-calendar-react'
import GrantedVouchersTable from './GrantedVouchersTable'
import PropTypes from 'prop-types'
import { formatPrice } from '../../../../services/utils'
import { getVoucherTemplateDescription } from '../../../../services/VoucherTemplates'
import moment from 'moment'
import useAuthInfo from '../../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

const vouchersInitialState = {
  loading: true,
  data: [],
  totalPages: 0,
  totalResults: 0
}

const GrantedVouchers = ({ userRef }) => {
  const intl = useIntl()
  const { operatorRealm } = useAuthInfo()
  const [activePage, setActivePage] = useState(1)
  const [vouchers, setVouchers] = useState(vouchersInitialState)
  // Initialize with last month start and end
  const lastMonth = moment().subtract(1, 'months')
  // DateInput component wants strings
  const [startDate, setStartDate] = useState(
    lastMonth.startOf('month').format('DD.MM.YYYY')
  )
  const [endDate, setEndDate] = useState(
    lastMonth.endOf('month').format('DD.MM.YYYY')
  )
  const [startValid, setStartValid] = useState(true)
  const [endValid, setEndValid] = useState(true)
  const [selectedVoucherTemplateRef, setSelectedVoucherTemplateRef] =
    useState('')

  const userVoucherTemplates = useQuery({
    queryKey: ['userVoucherTemplates'],
    queryFn: () =>
      voucherTemplateService.fetchVoucherTemplateByUserRef(
        operatorRealm,
        userRef
      )
  })

  const voucherTemplates = userVoucherTemplates.data
    ? userVoucherTemplates.data.map((template) => ({
        key: template.ref,
        value: template.ref,
        text: getVoucherTemplateDescription(template)
      }))
    : []

  useEffect(() => {
    const startMoment = moment(startDate, 'DD.MM.YYYY', true)
    const endMoment = moment(endDate, 'DD.MM.YYYY', true)
    setStartValid(startMoment.isValid())
    setEndValid(endMoment.isValid())
    if (startMoment.isValid() && endMoment.isValid()) {
      const startIsoString = startMoment.utc(true).toISOString()
      // Add one day to endDate so backend includes it in the query
      const endIsoString = endMoment.add(1, 'days').utc(true).toISOString()
      setVouchers({ ...vouchers, loading: true })
      grantedVoucherService
        .fetchGrantedVouchers(
          operatorRealm,
          userRef,
          startIsoString,
          endIsoString,
          selectedVoucherTemplateRef,
          activePage - 1,
          10
        )
        .then((data) => {
          setVouchers(data)
          if (data.totalPages < activePage) {
            setActivePage(1)
          }
        })
        .catch(() =>
          setVouchers({
            ...vouchersInitialState,
            loading: false,
            error: createErrorMessage(
              intl.formatMessage({
                id: 'grantedVouchers.query.error'
              })
            )
          })
        )
    }
  }, [activePage, startDate, endDate, selectedVoucherTemplateRef])

  return (
    <div className="grantedVouchers">
      <Segment basic loading={vouchers.loading}>
        <Form>
          <Form.Group>
            <Form.Field>
              <DateInput
                dateFormat="DD.MM.YYYY"
                value={startDate}
                animation="none"
                closable
                error={!startValid}
                onChange={(ev, { value }) => setStartDate(value)}
              />
            </Form.Field>
            <Form.Field>
              <DateInput
                dateFormat="DD.MM.YYYY"
                value={endDate}
                animation="none"
                closable
                error={!endValid}
                onChange={(ev, { value }) => setEndDate(value)}
              />
            </Form.Field>
            <Form.Field>
              <Form.Select
                clearable
                placeholder={intl.formatMessage({
                  id: 'grantedVouchers.filter.vouchers'
                })}
                loading={userVoucherTemplates.isLoading}
                search
                options={voucherTemplates}
                onChange={(e, data) =>
                  setSelectedVoucherTemplateRef(data.value)
                }
                selectOnBlur={false}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Header>
          <FormattedMessage id="grantedVouchers.header" />
        </Header>
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column width="13">
              {vouchers.error && <GenericMessage message={vouchers.error} />}
              <GrantedVouchersTable
                vouchers={vouchers.data}
                activePage={activePage}
                totalPages={vouchers.totalPages}
                changePage={(e, { activePage: newPage }) =>
                  setActivePage(newPage)
                }
              />
            </Grid.Column>
            <Grid.Column width="3">
              <Card>
                <Card.Content>
                  <Header id="cardHeader" as="h3">
                    <FormattedMessage id="grantedVouchers.card.header.altogether" />
                  </Header>
                  <List>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="grantedVouchers.card.item.givenVoucher" />
                      </List.Header>
                      {vouchers.totalResults}{' '}
                      <FormattedMessage id="grantedVouchers.card.item.pcs" />
                    </List.Item>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="grantedVouchers.card.item.totalCost" />
                      </List.Header>
                      {formatPrice(vouchers.totalCost)}
                    </List.Item>
                  </List>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  )
}

GrantedVouchers.propTypes = {
  userRef: PropTypes.string.isRequired
}

export default GrantedVouchers
