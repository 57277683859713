import './InvoiceAdministration.scss'

import { FormattedMessage } from 'react-intl'
import React from 'react'
import { Table } from 'semantic-ui-react'

const InvoiceRefundComments = ({ comments }) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="invoiceRefundComments.table.header.comments" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {comments ? (
          comments.map((comment, index) => (
            <Table.Row key={index}>
              <Table.Cell>{comment.comment}</Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row textAlign="center">
            <FormattedMessage id="invoiceRefundComments.table.body.noContent" />
          </Table.Row>
        )}
      </Table.Body>
    </Table>
  )
}

export default InvoiceRefundComments
