import React from 'react'

import useAuthInfo from '../../../hooks/useAuthInfo'
import VoucherLocationTable from './VoucherLocationTable'

const VoucherTemplateLocationList = ({ voucherTemplate }) => {
  const { operatorRealm } = useAuthInfo()

  return (
    <VoucherLocationTable
      voucherTemplate={voucherTemplate}
      operatorRealm={operatorRealm}
    ></VoucherLocationTable>
  )
}

export default VoucherTemplateLocationList
