import { Button, Checkbox, Label, Segment } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  ModalAdyenInvoice,
  ModalInvoice,
  MoovyModalConfirmation
} from '../../components'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import BlocklistCandidatesTable from './blocklistCandidatesTable'
import { currentPageHasMultiSelectedRows } from '../../services/utils'
import invoiceService from '../../services/Invoicing'
import moovyPagination from '../../components/TablePagination/pagination'
import { sale } from '../../services/utils'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import useGlobalUI from '../../hooks/useGlobalUI'
import useMultiSelect from '../../hooks/useMultiSelect'
import userBlocklistService from '../../services/UserBlocklist'

const BlocklistCandidatesContent = () => {
  const intl = useIntl()
  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const [confirmModalBlocking, setConfirmModalBlocking] = useState(false)
  const [hideBlockPreventedUsers, setHideBlockPreventedUsers] = useState(false)
  const [clickedInvoiceRef, setClickedInvoiceRef] = useState('')

  const pageLimit = 50

  const [state, setState] = useGlobalUI({
    key: 'blocklistCandidatesList',
    initialValue: {
      ...moovyPagination.getInitialValues('totalFailedPrice'),
      direction: moovyPagination.directions.DESC
    }
  })

  const bloclistCandidatesQuery = useQuery({
    queryKey: [
      'bloclistCandidates',
      hideBlockPreventedUsers,
      state.activePage,
      state.sort,
      state.direction
    ],
    queryFn: () => {
      const queryParams = {
        includeUsersThatCannotBeBlocked: !hideBlockPreventedUsers,
        page: state.activePage - 1,
        limit: pageLimit,
        sort: state.sort,
        direction: state.direction
      }
      return userBlocklistService.fetchBlocklistCandidates(queryParams)
    },
    staleTime: 1000 * 60 // Cache data for 1 minute
  })

  const candidates =
    bloclistCandidatesQuery.data?.content?.map((item) => {
      return { ...item, ref: item.user.ref }
    }) || []

  const totalPages = moovyPagination.getTotalPages(
    bloclistCandidatesQuery.data?.pageable
  )

  const invoiceQuery = useQuery({
    queryKey: ['fetchInvoice', clickedInvoiceRef],
    queryFn: () => {
      return invoiceService.fetchInvoice(clickedInvoiceRef, operatorRealm)
    },
    enabled: !!clickedInvoiceRef
  })

  const invoice = invoiceQuery.data

  const { mutate: addUsersToBlocklist, ...addUsersToBlocklistMutation } =
    useMutation({
      mutationFn: (userRefs) =>
        userBlocklistService.setUsersToBlocklist(userRefs),
      onSuccess: () => {
        setConfirmModalBlocking(false)
        bloclistCandidatesQuery.refetch()
        setMultiSelect([])
        if (state.activePage !== 1) {
          setState({
            ...state,
            activePage: 1
          })
        }
        showLocalizedMoovyToast(intl, {
          title: 'blocklistCandidates.content.action.success.title',
          description: 'blocklistCandidates.content.action.success.description'
        })
      }
    })

  const [multiSelect, setMultiSelect] = useMultiSelect({
    key: 'blocklistCandidates'
  })

  const selectedRefs = multiSelect.map((row) => row.ref)

  const currentPageHasCheckedRows = currentPageHasMultiSelectedRows(
    candidates.filter((item) => !item?.userBlockingPreventedStart),
    multiSelect
  )

  const onPageChange = (e, { activePage }) => {
    setState({
      ...state,
      activePage
    })
    setMultiSelect([])
  }

  const setSort = (sort, direction) => {
    setState({ ...state, sort, direction })
  }

  useEffect(() => {
    bloclistCandidatesQuery.refetch()
  }, [])

  return (
    <>
      {isSuperOperator && (
        <>
          <Checkbox
            label={intl.formatMessage({
              id: 'blocklistCandidates.content.label.hideBlockPreventedUsers'
            })}
            checked={hideBlockPreventedUsers}
            onChange={(e, data) => setHideBlockPreventedUsers(data.checked)}
          />
          <Segment attached="top" secondary>
            <Button
              as="div"
              labelPosition="right"
              primary
              onClick={() => setConfirmModalBlocking(true)}
              disabled={multiSelect.length === 0}
            >
              <Button primary>
                <FormattedMessage id="blocklistCandidates.content.button.setChosenToBlocklist" />
              </Button>
              <Label as="a" basic pointing="left">
                <FormattedMessage
                  id="blocklistCandidates.content.label.chosenUsers"
                  values={{ count: multiSelect.length }}
                />
              </Label>
            </Button>
          </Segment>
          <BlocklistCandidatesTable
            candidates={candidates}
            bloclistCandidatesQuery={bloclistCandidatesQuery}
            currentPageHasCheckedRows={currentPageHasCheckedRows}
            selectedRefs={selectedRefs}
            multiSelect={multiSelect}
            setMultiSelect={setMultiSelect}
            onClickedInvoiceRef={(ref) => setClickedInvoiceRef(ref)}
            onPageChange={onPageChange}
            totalPages={totalPages}
            activePage={state.activePage}
            sorting={{
              sort: state.sort,
              direction:
                moovyPagination.directions.stringValues[state.direction]
            }}
            onSort={setSort}
          />
          <MoovyModalConfirmation
            open={confirmModalBlocking}
            onClose={() => setConfirmModalBlocking(false)}
            lang={{
              titleElement: (
                <FormattedMessage id="blocklistCandidates.content.confirmation.title" />
              ),
              bodyElement: (
                <>
                  <FormattedMessage
                    id="blocklistCandidates.content.confirmation.body"
                    values={{ count: multiSelect.length }}
                  />
                </>
              ),
              buttonConfirmKey: 'common.button.confirm',
              defaultErroKey: 'blocklistCandidates.content.action.error.default'
            }}
            onSubmit={() =>
              addUsersToBlocklist(multiSelect.map((item) => item.user.ref))
            }
            mutation={addUsersToBlocklistMutation}
          />
          {(invoice &&
            clickedInvoiceRef &&
            invoice.paymentMethodType ===
              sale.paymentMethodTypes.ADYEN_TRANSACTION && (
              <ModalAdyenInvoice
                onClose={() => setClickedInvoiceRef('')}
                invoiceRef={invoice.ref}
                operatorRealm={operatorRealm}
                updateInvoicesCallback={() => bloclistCandidatesQuery.refetch()}
              />
            )) ||
            (invoice && clickedInvoiceRef && (
              <ModalInvoice
                onClose={() => setClickedInvoiceRef('')}
                invoiceRef={invoice.ref}
                operatorRealm={operatorRealm}
                updateInvoicesCallback={() => bloclistCandidatesQuery.refetch()}
                onOpenAdyenModal={(adyenInvoice) => {
                  bloclistCandidatesQuery.refetch()
                  invoiceQuery.refetch()
                }}
              />
            ))}
        </>
      )}
    </>
  )
}

export default BlocklistCandidatesContent
