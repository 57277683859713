import React from 'react'
import { Table } from 'semantic-ui-react'

import { LocalizedPaymentCardCategory } from '../../../components/MoovyLocalizedEnum'
import { concatVehicles, paymentCardCategory } from '../../../services/utils'

export default ({ subscribedServices, openSubscriptions }) => (
  <Table selectable className="subscribedServices">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Tuote</Table.HeaderCell>
        <Table.HeaderCell>Aktiivisten tilausten määrä</Table.HeaderCell>
        <Table.HeaderCell>Aktiiviset ajoneuvot</Table.HeaderCell>
        <Table.HeaderCell>Tuotteen tilaaja</Table.HeaderCell>
        <Table.HeaderCell>Maksukortti</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {subscribedServices.map((subscribed) => (
        <Table.Row key={subscribed.ref} onClick={openSubscriptions(subscribed)}>
          <Table.Cell>{subscribed.service.name}</Table.Cell>
          <Table.Cell>{subscribed.subscriptionCount}</Table.Cell>
          <Table.Cell>
            {concatVehicles(subscribed.currentActiveVehicles)}
          </Table.Cell>
          <Table.Cell>{`${subscribed.owner.firstName} ${subscribed.owner.lastName}`}</Table.Cell>
          <Table.Cell>
            <LocalizedPaymentCardCategory
              value={subscribed.paymentCardCategory}
            />
          </Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)
