import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { DateInput, TimeInput } from 'semantic-ui-calendar-react'
import { Button, Container, Form, Message, TextArea } from 'semantic-ui-react'

import useAuthInfo from '../../../hooks/useAuthInfo'
import parkingService from '../../../services/Parking'
import { dateTimeInputStringToISO, formatPrice } from '../../../services/utils'

export default ({ parkingRef, onSubmit, mutation, started, ended }) => {
  const { operatorRealm } = useAuthInfo()
  const [comment, setComment] = useState('')
  const [endTime, setEndTime] = useState(DateTime.local().toFormat('d.M.yyyy'))
  const [endTimeString, setEndTimeString] = useState(
    DateTime.local().toLocaleString(DateTime.TIME_24_SIMPLE)
  )

  const endTimeISO = dateTimeInputStringToISO(`${endTime} ${endTimeString}`)
  const endDateStringValid = /^\d{1,2}\.\d{1,2}\.\d{4}$/.test(endTime)
  const endTimeStringValid = /^\d{2}:\d{2}$/.test(endTimeString)
  const endTimeValid =
    endDateStringValid &&
    endTimeStringValid &&
    DateTime.fromISO(endTimeISO) > DateTime.fromISO(started)

  const remainingPrice = useQuery({
    queryKey: ['estimatedPrice', parkingRef, endTimeISO],
    queryFn: () =>
      parkingService.fetchRemainingPrice(parkingRef, operatorRealm, {
        calculationTime: endTimeISO
      }),
    enabled: !!endTimeValid
  })

  return (
    <Form error={mutation.isError}>
      <Container style={{ padding: '1em' }}>
        <Form.Group widths="equal">
          <Form.Field error={!endDateStringValid}>
            <label>Ulosajo</label>
            <DateInput
              dateFormat="DD.MM.YYYY"
              value={endTime}
              onChange={(event, { value }) => setEndTime(value)}
              iconPosition="left"
              animation="none"
              closable
              name="begin"
              popupPosition="bottom center"
              style={{ maxWidth: '130px' }}
            />
          </Form.Field>
          <Form.Field error={!endTimeStringValid}>
            <label>aika</label>
            <TimeInput
              value={endTimeString}
              onChange={(event, { value }) => setEndTimeString(value)}
              iconPosition="left"
              animation="none"
              name="beginString"
              closable
              popupPosition="bottom center"
              style={{ maxWidth: '130px' }}
            />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Kommentti</label>
          <TextArea
            placeholder="Voit lisätä muutokseen liittyvän kommentin"
            value={comment}
            onChange={(ev) => setComment(ev.target.value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Maksettavaa</label>

          <p>
            {remainingPrice.isLoading && 'Hintaa lasketaan...'}
            {remainingPrice.data &&
              formatPrice(remainingPrice.data.remainingPrice)}
            {remainingPrice.isError && 'Hintaa ei pystytty laskemaan'}
          </p>
        </Form.Field>
        <Message error>Pysäköinnin tallentamisessa tapahtui virhe</Message>
        <Button
          primary
          fluid
          onClick={() =>
            onSubmit({
              comment,
              endTime: dateTimeInputStringToISO(`${endTime} ${endTimeString}`)
            })
          }
          loading={mutation.isPending}
          disabled={ended || !endTimeValid}
        >
          Tallenna ja lopeta pysäköinti
        </Button>
      </Container>
    </Form>
  )
}
