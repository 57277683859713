import { get, post, put, remove } from '../utils/api-renewed'

const fetchMessages = (queryParams) => {
  return get('/administration/messageCenter', queryParams)
}

const fetchMessage = (messageRef) => {
  return get(`/administration/messageCenter/${messageRef}`)
}

const saveMessage = (messageRef, message) => {
  if (!messageRef) {
    return post('/administration/messageCenter', message)
  } else {
    return put(`/administration/messageCenter/${messageRef}`, message)
  }
}

const deleteMessage = (messageRef) => {
  return remove(`/administration/messageCenter/${messageRef}`)
}

const fetchTargets = (queryParams) => {
  return get('/administration/messageCenter/target', queryParams)
}

const fetchTarget = (targetRef) => {
  return get(`/administration/messageCenter/target/${targetRef}`)
}

const saveTarget = (targetRef, target) => {
  if (!targetRef) {
    return post('/administration/messageCenter/target', target)
  } else {
    return put(`/administration/messageCenter/target/${targetRef}`, target)
  }
}

const deleteTarget = (targetRef) => {
  return remove(`/administration/messageCenter/target/${targetRef}`)
}

const fetchTargetUserCount = (targetRefs) => {
  return post('/administration/messageCenter/target/count', { targetRefs })
}

const fetchTargetUserCountData = (data) => {
  return post('/administration/messageCenter/target/countData', data)
}

export default {
  fetchMessages,
  fetchMessage,
  saveMessage,
  deleteMessage,
  fetchTargets,
  fetchTarget,
  saveTarget,
  deleteTarget,
  fetchTargetUserCount,
  fetchTargetUserCountData
}
