import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'semantic-ui-react'

import useAuthInfo from '../../hooks/useAuthInfo'
import { voucherGrantAgreementShape } from '../../services/utils'

const VoucherGrantAgreementTable = ({ voucherGrantAgreements }) => {
  const { isSuperOperator } = useAuthInfo()
  const navigate = useNavigate()

  const onSelectedVoucherTemplate = (voucherTemplate) => {
    navigate(
      `/${isSuperOperator ? 'admin' : 'operator'}/voucherTemplates/${
        voucherTemplate.ref
      }`
    )
  }

  return (
    <Table color="green" selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Etu</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {voucherGrantAgreements.map((agreement) => (
          <Table.Row
            key={agreement.ref}
            onClick={() => {
              onSelectedVoucherTemplate(agreement.voucherTemplate)
            }}
          >
            <Table.Cell>{agreement.voucherTemplate.name}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

VoucherGrantAgreementTable.propTypes = {
  voucherGrantAgreements: PropTypes.arrayOf(
    PropTypes.shape(voucherGrantAgreementShape)
  ).isRequired
}

export default VoucherGrantAgreementTable
