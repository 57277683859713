import { createSlice } from '@reduxjs/toolkit'

import messages from '../messages'

const key = 'localization'

const initialState = {
  locale: 'fi',
  messages: messages.fi
}

const slice = createSlice({
  name: key,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    // Redux toolkit allows modification of state directly and handles immutability automatically.
    setLocale(state, action) {
      state.locale = action.payload
      state.messages = messages[action.payload]
    }
  }
})

export default slice.reducer

export const { setLocale } = slice.actions

export const localizationSelectors = {
  getLocale(state) {
    return state[key].locale
  },
  getMessages(state) {
    return state[key].messages
  }
}
