import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Grid } from 'semantic-ui-react'

import { MoovyButton } from '../../../../components'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import useAuthInfo from '../../../../hooks/useAuthInfo'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import { ROLE_FINANCES } from '../../../../services/utils'
import ModalManageMandator from './modalManageMandator'
import TableMandator from './tableMandator'

const TabVisma = ({ settlementRecipient, refresh }) => {
  const intl = useIntl()
  const { isSuperOperator, roles } = useAuthInfo()
  const [openModal, setOpenModal] = useState(false)
  const [editMandator, setEditMandator] = useState(null)

  const { mutate: createMandator, ...createMandatorMutation } = useMutation({
    mutationFn: (mandator) =>
      settlementRecipientService.createVismaMandator(
        settlementRecipient.ref,
        mandator
      ),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'tabVisma.action.createMandator.success.title',
        description: 'tabVisma.action.createMandator.success.description'
      })
      const { resetForm } = variables
      resetForm({})
      setOpenModal(false)
      setEditMandator(null)
      refresh()
    }
  })

  const { mutate: updateMandator, ...updateMandatorMutation } = useMutation({
    mutationFn: (mandator) =>
      settlementRecipientService.updateVismaMandator(
        settlementRecipient.ref,
        mandator
      ),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'tabVisma.action.updateMandator.success.title',
        description: 'tabVisma.action.updateMandator.success.description'
      })
      const { resetForm } = variables
      resetForm({})
      setOpenModal(false)
      setEditMandator(null)
      refresh()
    }
  })

  const vismaMandators = settlementRecipient?.visma || []

  return (
    <>
      {roles.includes(ROLE_FINANCES) && isSuperOperator && (
        <Grid style={{ paddingTop: '10px' }}>
          <Grid.Column floated="right" width={8} textAlign="right">
            <MoovyButton onClick={() => setOpenModal(true)}>
              <>
                <FontAwesomeIcon
                  icon={['fal', 'plus-circle']}
                  style={{ marginRight: '6px' }}
                />
                <FormattedMessage id="tabVisma.button.addMandator" />
              </>
            </MoovyButton>
          </Grid.Column>
        </Grid>
      )}
      <TableMandator
        query={createMandatorMutation}
        vismaMandators={vismaMandators}
        onEditMandator={(mandator) => {
          setEditMandator(mandator)
          setOpenModal(true)
        }}
      />
      <ModalManageMandator
        open={openModal}
        existingMandators={vismaMandators}
        edit={editMandator}
        onCreateMandator={(data) => createMandator(data)}
        onUpdateMandator={(data) => updateMandator(data)}
        createMutation={createMandatorMutation}
        updateMutation={updateMandatorMutation}
        onClose={() => {
          setEditMandator(null)
          setOpenModal(false)
        }}
      />
    </>
  )
}

export default TabVisma
