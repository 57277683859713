import { get, put } from './api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/vehicles${relativePath}`
    : `/administration/vehicles${relativePath}`

const queryByLicensePlate = (licensePlateNumber, operatorRealm) =>
  get(createFullPath('/search', operatorRealm), { licensePlateNumber })

const toggleRecognition = (vehicle, automaticRecognitionEnabled) =>
  put(createFullPath(`/${vehicle?.ref}/vehicleCameraRecognition`), {
    automaticRecognitionEnabled
  })

export default {
  queryByLicensePlate,
  toggleRecognition
}
