import {
  Checkbox,
  Dropdown,
  Grid,
  Input,
  Label,
  Table
} from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'

import AssignmentsTable from './AssignmentsTable'
import PropTypes from 'prop-types'

const directions = {
  DESC: 'descending',
  ASC: 'ascending'
}

const EnterpriseAssignments = ({
  subscribedServices,
  state,
  updateState,
  assignments,
  handleSort
}) => {
  const MIN_PARTIAL_SEARCH_VALUE = 3
  const FIRST_ACTIVE_PAGE = 1
  const { selectedRef, pagination, includeHistory } = state
  const [typedPartialSearchValue, setTypedPartialSearchValue] = useState('')
  const [errorSelectService, setErrorSelectService] = useState(false)
  const [errorLessThanThreeChars, setErrorLessThanThreeChars] = useState(false)

  const onSearchInputChange = (value) => {
    setTypedPartialSearchValue(value)
  }

  const clearSearchInput = () => {
    onSearchInputChange('')
    setErrorLessThanThreeChars(false)
  }

  // Start searching voucher templates after 1 second delay when user has stopped to type in voucherDescription input.
  useEffect(() => {
    const timer = setTimeout(() => {
      setErrorSelectService(typedPartialSearchValue && !selectedRef)
      setErrorLessThanThreeChars(
        typedPartialSearchValue &&
          typedPartialSearchValue.trim().length < MIN_PARTIAL_SEARCH_VALUE
      )

      updateState.setPartialSearch(typedPartialSearchValue)
      updateState.setPagination({
        ...pagination,
        activePage: FIRST_ACTIVE_PAGE
      })
    }, 1000)

    return () => clearTimeout(timer)
  }, [typedPartialSearchValue])

  const EmptyTable = ({ headerTexts }) => {
    return (
      <Table>
        <Table.Header>
          <Table.Row>
            {headerTexts.map((text, index) => (
              <Table.HeaderCell key={index}>{text}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Ei tuloksia</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }

  return (
    <>
      <Grid verticalAlign="top">
        <Grid.Row columns="3" stretched>
          <Grid.Column width={4}>
            <Dropdown
              fluid
              placeholder="Valitse tilaustuote"
              selection
              value={selectedRef}
              onChange={(e, data) => {
                clearSearchInput()
                if (data) {
                  setErrorSelectService(false)
                  updateState.setSelectedRef(data.value)
                  updateState.setPagination({
                    ...pagination,
                    activePage: FIRST_ACTIVE_PAGE
                  })
                }
              }}
              options={subscribedServices.map((ss) => ({
                key: ss.ref,
                text: ss.service.name,
                value: ss.ref
              }))}
              selectOnBlur={false}
            />
            {errorSelectService && (
              <Label basic color="red" pointing>
                Valitse ensin tilaustuote.
              </Label>
            )}
          </Grid.Column>
          <Grid.Column width={4}>
            <Input
              className="fluid"
              placeholder="Syötä hakusana"
              value={typedPartialSearchValue}
              onChange={(ev, data) => onSearchInputChange(data.value)}
            />
            {!errorSelectService && errorLessThanThreeChars && (
              <Label basic color="red" pointing>
                Syötä vähintään kolme merkkiä.
              </Label>
            )}
          </Grid.Column>
          <Grid.Column width={8} verticalAlign="middle">
            <Checkbox
              checked={includeHistory}
              onClick={() => {
                updateState.toggleIncludeHistory()
                updateState.setPagination({
                  ...pagination,
                  activePage: FIRST_ACTIVE_PAGE
                })
              }}
              label="Näytä myös päättyneet"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {assignments.fetchStatus === 'idle' &&
      assignments.status === 'loading' ? (
        <EmptyTable
          headerTexts={[
            'Alkamispäivä',
            'Päättymispäivä',
            'Pysäköijä',
            'Puhelinnumero',
            'Lisätieto',
            'Tila',
            'Käyttäjä',
            'Aktiiviset ajoneuvot',
            'Ajoneuvoja enintään'
          ]}
        />
      ) : (
        <AssignmentsTable
          assignments={assignments}
          currentActiveVehicleRefs={
            selectedRef && subscribedServices && subscribedServices.length
              ? subscribedServices
                  .find((ss) => ss.ref === selectedRef)
                  .currentActiveVehicles.map((v) => v.ref)
              : []
          }
          activePage={pagination.activePage}
          totalPages={(assignments.data && assignments.data.totalPages) || 1}
          totalResults={
            (assignments.data && assignments.data.totalResults) || 0
          }
          sorted={pagination.column}
          direction={directions[pagination.direction]}
          changePage={(e, { activePage }) => {
            updateState.setPagination({ ...pagination, activePage })
          }}
          handleSort={handleSort}
        />
      )}
    </>
  )
}

EnterpriseAssignments.propTypes = {
  subscribedServices: PropTypes.arrayOf(PropTypes.shape).isRequired,
  state: PropTypes.shape().isRequired,
  updateState: PropTypes.shape({
    setSelectedRef: PropTypes.func.isRequired,
    toggleIncludeHistory: PropTypes.func.isRequired,
    setPartialSearch: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired
  }).isRequired,
  assignments: PropTypes.PropTypes.shape().isRequired,
  handleSort: PropTypes.func.isRequired
}
export default EnterpriseAssignments
