import './VoucherTemplateDetails.scss'

import React, { useEffect, useState } from 'react'
import { Toolbar, showMoovyToast } from '../../../components'

import GrantedVouchersList from './GrantedVouchersList'
import { Tab } from 'semantic-ui-react'
import VoucherDetails from './VoucherDetails'
import VoucherTemplateLocationList from './VoucherTemplateLocationList'
import VoucherTemplateManageModal from '../VoucherTemplateManageModal'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import { getVoucherTemplateDescription } from '../../../services/VoucherTemplates'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import useOperators from '../../../hooks/useOperators'
import { useParams } from 'react-router-dom'
import voucherTemplateService from '../../../services/VoucherTemplates'

const VoucherTemplateDetails = () => {
  const { voucherTemplateRef } = useParams()

  const intl = useIntl()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [editTemplate, setEditTemplate] = useState(null)
  const [voucherTemplate, setVoucherTemplate] = useState(null)
  const { operatorRealm } = useAuthInfo()

  const operators = useOperators()

  // Fetch all operators when the page is loaded.
  useEffect(() => {
    fetchVoucherTemplateByRef(operatorRealm, voucherTemplateRef)
  }, [])

  // Fetch locations according to the value of the operator drowndown.
  const fetchVoucherTemplateByRef = (operatorRealm, voucherTemplateRef) => {
    voucherTemplateService
      .fetchVoucherTemplateByRef(operatorRealm, voucherTemplateRef)
      .then((res) => {
        setVoucherTemplate(res)
      })
  }

  const { mutate: updateVoucherTemplate, ...updateVoucherTemplateMutation } =
    useMutation({
      mutationFn: (voucherTemplate) =>
        voucherTemplateService.updateVoucherTemplate(
          operators.data.content.find(
            (op) => op.ref === voucherTemplate.operatorRef
          ).realm,
          voucherTemplate.ref,
          voucherTemplate
        ),
      onSuccess: (res) => {
        setVoucherTemplate(res)
        setEditTemplate(false)
        setShowConfirmation(false)
        showMoovyToast(
          intl.formatMessage({
            id: 'voucherTemplates.updateVoucherTemplate.success'
          }),
          getVoucherTemplateDescription(res)
        )
      }
    })

  const onActiveFlagChanged = () => {
    const {
      ref,
      benefitType,
      categoryType,
      grantedDurationInMinutes,
      grantedValue,
      grantedDiscountPercent,
      salePrice,
      validLocationsDescription,
      customDescription,
      allowedGrantType,
      validityInMinutes,
      active
    } = voucherTemplate

    const updatedVoucherTemplate = {
      ref,
      benefitType,
      categoryType,
      grantedDurationInMinutes,
      grantedValue,
      grantedDiscountPercent,
      salePrice,
      validLocationsDescription,
      customDescription,
      allowedGrantType,
      validityInMinutes,
      operatorRef: voucherTemplate.operator.ref,
      active: !active,
      validityLocationRefs: voucherTemplate.validityLocationRefs.map(
        (loc) => loc.ref
      )
    }
    updateVoucherTemplate(updatedVoucherTemplate)
  }

  const panes = [
    {
      menuItem: 'Myönnetyt edut',
      render: () => (
        <Tab.Pane>
          <GrantedVouchersList
            voucherTemplateRef={voucherTemplateRef}
            voucherTemplate={voucherTemplate}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'Edun kohteet',
      render: () => (
        <Tab.Pane>
          <VoucherTemplateLocationList voucherTemplate={voucherTemplate} />
        </Tab.Pane>
      )
    }
  ]

  const DetailTabs = () => <Tab panes={panes} />

  const breadcrumbs = createBreadcrumbs([
    { text: intl.formatMessage({ id: 'common.breadcrumbs.home' }), href: '/' },
    {
      text: 'Pysäköintiedut',
      href: `/voucherTemplates`
    },
    { text: 'Tiedot', active: true }
  ])

  const toolbarButtons = [
    {
      icon: 'setting',
      tooltip: 'Muokkaa pysäköintietua',
      action: () => setEditTemplate(true)
    }
  ]

  return (
    <>
      <Toolbar
        title="Pysäköintiedun tiedot"
        breadcrumbs={breadcrumbs}
        buttons={(!operatorRealm && toolbarButtons) || []}
        content={
          <VoucherDetails
            voucherTemplate={voucherTemplate}
            updateVoucherTemplateMutation={updateVoucherTemplateMutation}
            showConfirmation={showConfirmation}
            onShowConfirmation={(show) => setShowConfirmation(show)}
            onActiveFlagChanged={onActiveFlagChanged}
          />
        }
      />

      <div className="Admin--Page--Content">{DetailTabs()}</div>
      {editTemplate && voucherTemplate && (
        <VoucherTemplateManageModal
          mutation={updateVoucherTemplateMutation}
          mutationDefaultErrorMsgId={
            'voucherTemplates.updateVoucherTemplate.error'
          }
          voucherTemplate={voucherTemplate}
          operators={operators.data.content}
          onSaveVoucherTemplate={updateVoucherTemplate}
          onClose={() => {
            updateVoucherTemplateMutation.reset()
            setEditTemplate(false)
          }}
        />
      )}
    </>
  )
}

export default VoucherTemplateDetails
