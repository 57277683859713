import { Grid, GridRow, List } from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import { LocalizedServiceType } from '../../../components/MoovyLocalizedEnum'
import React from 'react'
import ServiceSettlementRecipient from './ServiceSettlementRecipient'

const PayAsYouGoServiceToolbar = ({ paygService }) => {
  return (
    <>
      {paygService && (
        <>
          <Grid columns="2" stackable style={{ maxWidth: '1200px' }}>
            <GridRow>
              <Grid.Column width={5}>
                <List>
                  <List.Item>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.payAsYouGoServiceToolbar.label.type" />
                    </b>{' '}
                    <LocalizedServiceType value={paygService.type} />
                  </List.Item>
                  <List.Item>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.payAsYouGoServiceToolbar.label.visibility" />
                    </b>{' '}
                    {paygService.publicService === true ? (
                      <FormattedMessage id="common.public" />
                    ) : (
                      <FormattedMessage id="common.private" />
                    )}
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={6}>
                <List>
                  <List.Item>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.payAsYouGoServiceToolbar.label.parkingCapacity" />
                    </b>{' '}
                    {Number.isInteger(paygService.parkingCapacity) ? (
                      paygService.parkingCapacity
                    ) : (
                      <FormattedMessage id="common.unlimited" />
                    )}
                  </List.Item>
                  <List.Item>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.payAsYouGoServiceToolbar.label.allowParkingOvercapacity" />
                    </b>{' '}
                    {paygService.exceedingParkingCapacityAllowed ? (
                      <FormattedMessage id="common.yes" />
                    ) : (
                      <FormattedMessage id="common.no" />
                    )}
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={5}>
                <ServiceSettlementRecipient service={paygService} />
              </Grid.Column>
            </GridRow>
          </Grid>
        </>
      )}
    </>
  )
}

export default PayAsYouGoServiceToolbar
