import * as yup from 'yup'

import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

import { DateTimeInput } from 'semantic-ui-calendar-react'
import { Confirm, Form, Select } from 'semantic-ui-react'
import { useFormik } from 'formik'
import { useQuery } from '@tanstack/react-query'
import {
  dateTimeFromFormat,
  dateTimeInputStringToISO,
  dateTimeNowToCalendarInput
} from '../../services/utils'
import useAuthInfo from '../../hooks/useAuthInfo'
import recognitionService from '../../services/utils/license-plate-recognition'

const FormAnonymousParking = ({
  location,
  services,
  formReference,
  onLoadingStateChange,
  onSubmit
}) => {
  const intl = useIntl()
  const { operatorRealm } = useAuthInfo()
  const [currentTime] = useState(dateTimeNowToCalendarInput())
  const [submittedLicensePlate, setSubmittedLicensePlate] = useState('')
  const [confirmStartMoovyParking, setConfirmStartMoovyParking] =
    useState(false)

  const formik = useFormik({
    initialValues: {
      startTime: currentTime,
      licensePlateNumber: ''
    },
    validationSchema: yup.object().shape({
      startTime: yup
        .string()
        .test('startTime', 'Invalid start time', function (startTime) {
          return startTime && dateTimeFromFormat(startTime).isValid
        }),
      licensePlateNumber: yup
        .string()
        .test(
          'licensePlateNumber',
          'Invalid license plate',
          function (licensePlateNumber) {
            return licensePlateNumber && licensePlateNumber.trim()
          }
        )
    }),
    onSubmit: (values) => {
      const licensePlateNumber = values.licensePlateNumber
        .trim()
        .toLocaleUpperCase()
      setSubmittedLicensePlate(licensePlateNumber)
      onLoadingStateChange(true)
    }
  })

  const { values, errors, setFieldValue, handleChange } = formik

  useEffect(() => {
    if (!formik.values.serviceRef) {
      formik.setFieldValue(
        'serviceRef',
        services?.length > 1 ? services[0].ref : null,
        false
      )
    }
  }, [services])

  const startParking = () => {
    // The parking will be started at the same time when the modal is closed in case of user does not change the time
    // TODO Antti: This is not a perfect solution because seconds are missing in the value. This can be fixed when
    // a new datetime component will be taken into use.
    onSubmit({
      startTime:
        values.startTime === currentTime
          ? dateTimeInputStringToISO(dateTimeNowToCalendarInput())
          : dateTimeInputStringToISO(values.startTime),
      licensePlateNumber: values.licensePlateNumber.trim().toLocaleUpperCase(),
      serviceRef: values.serviceRef
    })
  }

  const licensePlateExistsQuery = useQuery({
    queryKey: ['licensePlateExists', submittedLicensePlate],
    queryFn: () => {
      return recognitionService.queryByLicensePlate(
        submittedLicensePlate,
        operatorRealm
      )
    },
    enabled: Boolean(submittedLicensePlate)
  })

  useEffect(() => {
    const { isSuccess, isError, data } = licensePlateExistsQuery
    if (isSuccess) {
      const filledLicensePlate = submittedLicensePlate
      const isMoovyLicensePlate =
        data?.length > 0
          ? data.some(
              (item) =>
                item?.licensePlateNumber
                  ?.toLocaleUpperCase()
                  ?.localeCompare(filledLicensePlate) === 0
            )
          : false
      if (isMoovyLicensePlate) {
        setConfirmStartMoovyParking(true)
      } else {
        startParking()
      }
    }
    if (isError) {
      startParking()
    }
    if (isSuccess || isError) {
      onLoadingStateChange(false)
      setSubmittedLicensePlate('')
    }
  }, [licensePlateExistsQuery.status])

  const serviceOptions = services.map((service) => ({
    key: service.ref,
    text: service.name,
    value: service.ref
  }))

  return (
    <>
      <Form onSubmit={formik.handleSubmit} id={formReference}>
        <Form.Field>
          <label>
            <FormattedMessage id="modalStartParking.form.label.location" />
          </label>
          {location?.name}
        </Form.Field>
        <Form.Input
          focus
          label={intl.formatMessage({
            id: 'common.vehicles.licensePlateNumber'
          })}
          name="licensePlateNumber"
          type="text"
          value={values.licensePlateNumber}
          onChange={handleChange}
          error={!!errors.licensePlateNumber}
        />
        <Form.Field error={!!errors.startTime}>
          <label>
            <FormattedMessage id="modalStartParking.form.label.startTime" />
          </label>
          <DateTimeInput
            name="startTime"
            value={values.startTime}
            onChange={(ev, { value }) => {
              setFieldValue('startTime', value)
            }}
            dateFormat="DD.MM.YYYY"
            startMode="month"
            iconPosition="left"
            animation="none"
            closable
          />
        </Form.Field>
        {services?.length > 1 && (
          <Form.Field
            control={Select}
            label={intl.formatMessage({
              id: 'modalStartParking.form.label.service'
            })}
            name="serviceRef"
            value={values.serviceRef}
            onChange={(ev, { value }) => {
              setFieldValue('serviceRef', value)
            }}
            options={serviceOptions}
          />
        )}
      </Form>
      <Confirm
        open={confirmStartMoovyParking}
        size="tiny"
        content={intl.formatMessage({
          id: 'modalStartParking.confirm.moovyAccount'
        })}
        cancelButton={intl.formatMessage({
          id: 'common.button.cancel'
        })}
        confirmButton={intl.formatMessage({
          id: 'common.button.confirm'
        })}
        onCancel={() => {
          setConfirmStartMoovyParking(false)
          setSubmittedLicensePlate('')
        }}
        onConfirm={() => {
          setConfirmStartMoovyParking(false)
          startParking()
        }}
      />
    </>
  )
}

export default FormAnonymousParking
