import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

import { MutationErrorMessage } from '../../../components'

const ModalWaivedInvoice = ({ open, invoice, onClose, onSubmit, mutation }) => {
  const intl = useIntl()
  const [comment, setComment] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (open) {
      setComment('')
      setError(false)
    }
  }, [open])

  return (
    <Modal open={open} onClose={onClose} size="small">
      <Modal.Header
        content={intl.formatMessage(
          {
            id: 'modalWaivedInvoice.title'
          },
          { invoiceNumber: invoice?.invoiceNumber }
        )}
      />
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId="modalWaivedInvoice.action.error"
          messageNegative
        />
        <Form>
          <Form.Field>
            <FormattedMessage id="modalWaivedInvoice.action.info" />
          </Form.Field>
          <Form.TextArea
            label="Lisää kommentti"
            error={error}
            onChange={(event, data) => {
              setError(false)
              setComment(data.value)
            }}
          ></Form.TextArea>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          type="submit"
          primary
          onClick={() => (comment ? onSubmit(comment) : setError(true))}
          disabled={mutation.isPending}
          loading={mutation.isPending}
        >
          <FormattedMessage id="modalWaivedInvoice.button.setAsWaived" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalWaivedInvoice
