import { get } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}${relativePath}`
    : `/administration${relativePath}`

const fetchGrantedVouchers = async (
  operatorRealm,
  userRef,
  startDate,
  endDate,
  voucherTemplateRef,
  page,
  limit
) => {
  try {
    const result = await get(
      createFullPath(`/user/${userRef}/grantedVouchers`, operatorRealm),
      {
        startDate,
        endDate,
        voucherTemplateRef,
        page,
        limit
      }
    )
    return result
    // return createMocks(page, limit)
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

export const getVoucherLocationDescription = (voucher) => {
  if (!voucher) {
    return ''
  }
  const locationDesc = voucher.locationDescription
  if (locationDesc) {
    return locationDesc
  } else if (voucher.locations && voucher.locations.length > 0) {
    let content = voucher.locations.map((location) => {
      return location.name
    })
    return content.join(', ')
  }

  return ''
}

export default {
  fetchGrantedVouchers
}
