import { ModalAdyenInvoice, ModalInvoice } from '../../../components'
import React, { useEffect, useState } from 'react'
import { paymentMethod, sale } from '../../../services/utils'
import {
  sortColumn,
  tablePaginationDirections
} from '../../../services/utils/tablePagination'

import CreateInvoice from '../../../containers/CreateInvoice'
import { DateTime } from 'luxon'
import InvoiceTable from './InvoiceTable'
import { InvoicingTableFilter } from './InvoicingTableFilter'
import PropTypes from 'prop-types'
import invoiceService from '../../../services/Invoicing'
import moovyDateTime from '../../../services/utils/moovyDateTime'
import { useDebounce } from 'use-debounce'
import useGlobalUI from '../../../hooks/useGlobalUI'
import { useQuery } from '@tanstack/react-query'

const CustomerInvoicing = ({ customer, operatorRealm, onRefreshUser }) => {
  const [selectedInvoice, setSelectedInvoice] = useState(null)
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [debouncedInvoiceNumber] = useDebounce(invoiceNumber, 750)
  const [invoiceSum, setInvoiceSum] = useState('')
  const [debouncedInvoiceSum] = useDebounce(invoiceSum, 750)
  const [invoiceStatuses, setInvoiceStatuses] = useState([])
  const [createdFromTime, setCreatedFromTime] = useState(
    moovyDateTime.dateTimeToBackendFormat(DateTime.local().startOf('day'))
  )
  const [createdUntilTime, setCreatedUntilTime] = useState(
    moovyDateTime.dateTimeToBackendFormat(DateTime.local().endOf('day'))
  )
  const [openCreateInvoiceModal, setOpenCreateInvoiceModal] = useState(false)

  const isEnterpriseCustomer = customer.role === 'ROLE_ENTERPRISE_USER'

  const pageLimit = 20

  const [state, setState] = useGlobalUI({
    key: 'customerInvoicing',
    initialValue: {
      activePage: 1,
      totalPages: 1,
      sortColumn: 'sortByCreationTime',
      direction: tablePaginationDirections.DESC
    }
  })

  useEffect(() => {
    if (state.activePage !== 1) {
      setState({
        ...state,
        activePage: 1
      })
    }
  }, [
    debouncedInvoiceNumber,
    debouncedInvoiceSum,
    createdFromTime,
    createdUntilTime
  ])

  const invoicesQuery = useQuery({
    queryKey: [
      'fetchAllInvoices',
      state.page,
      state.sort,
      state.direction,
      state.activePage,
      debouncedInvoiceNumber,
      debouncedInvoiceSum,
      invoiceStatuses,
      createdFromTime,
      createdUntilTime
    ],
    queryFn: () => {
      const queryParams = {
        page: state.activePage - 1,
        limit: pageLimit,
        sort: state.sort,
        direction: state.direction,
        invoiceNumber: debouncedInvoiceNumber,
        invoiceSum: debouncedInvoiceSum,
        status: invoiceStatuses,
        createdFromTime,
        createdUntilTime
      }
      return invoiceService.fetchUserInvoices(
        customer.ref,
        operatorRealm,
        queryParams
      )
    }
  })

  const invoices = invoicesQuery?.data?.data || []
  const totalPages = invoicesQuery?.data?.totalPages || 0

  const onPageChange = (e, { activePage }) => {
    setState({
      ...state,
      activePage
    })
  }

  const setSort = (column, direction) => {
    setState({ ...state, sort: column, direction })
  }

  const setDateTimeRange = (dateTimeRange) => {
    setCreatedFromTime(dateTimeRange.startTime)
    setCreatedUntilTime(dateTimeRange.endTime)
  }

  return (
    <>
      <InvoicingTableFilter
        isEnterpriseCustomer={isEnterpriseCustomer}
        invoiceNumber={invoiceNumber}
        invoiceSum={invoiceSum}
        invoiceStatuses={invoiceStatuses}
        setDateTimeRange={setDateTimeRange}
        setInvoiceNumber={setInvoiceNumber}
        setInvoiceSum={setInvoiceSum}
        setInvoiceStatuses={setInvoiceStatuses}
        onOpenInvoice={() => setOpenCreateInvoiceModal(true)}
      />
      <InvoiceTable
        invoices={invoices}
        activePage={state.activePage}
        totalPages={totalPages}
        sorting={{
          column: state.sort,
          direction: state.direction
        }}
        invoiceType={(isEnterpriseCustomer && paymentMethod.VISMA) || null}
        invoicesQuery={invoicesQuery}
        onRowClick={(invoice) => () => setSelectedInvoice(invoice)}
        onPageChange={onPageChange}
        onSort={(clickedColumn) =>
          sortColumn(
            clickedColumn,
            { column: state.sort, direction: state.direction },
            setSort
          )
        }
      />
      {(selectedInvoice &&
        selectedInvoice.paymentMethodType ===
          sale.paymentMethodTypes.ADYEN_TRANSACTION && (
          <ModalAdyenInvoice
            onClose={() => setSelectedInvoice(null)}
            invoiceRef={selectedInvoice.ref}
            operatorRealm={operatorRealm}
            updateInvoicesCallback={() => invoicesQuery.refetch()}
            onRefreshUser={onRefreshUser}
          />
        )) ||
        (selectedInvoice && (
          <ModalInvoice
            onClose={() => setSelectedInvoice(null)}
            invoiceRef={selectedInvoice.ref}
            operatorRealm={operatorRealm}
            updateInvoicesCallback={() => invoicesQuery.refetch()}
            onOpenAdyenModal={(adyenInvoice) => {
              setSelectedInvoice(adyenInvoice)
            }}
          />
        ))}

      <CreateInvoice
        open={isEnterpriseCustomer && openCreateInvoiceModal}
        customerRef={customer.ref}
        operatorRealm={operatorRealm}
        newInvoiceCallback={() => {
          setOpenCreateInvoiceModal(false)
          invoicesQuery.refetch()
        }}
        onClose={() => setOpenCreateInvoiceModal(false)}
      />
    </>
  )
}

CustomerInvoicing.propTypes = {
  customer: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    role: PropTypes.oneOf(['ROLE_ENTERPRISE_USER', 'ROLE_USER']).isRequired
  }).isRequired,
  operatorRealm: PropTypes.string
}

export default CustomerInvoicing
