import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Message } from 'semantic-ui-react'

import { localizedErrorString } from '../../services/utils'

const MutationErrorMessage = ({
  mutation,
  defaultErrorTextLangId,
  errorTitleTextLangId,
  messageNegative,
  showRawErrorMessage
}) => {
  const RenderMessage = ({ title, negative, children }) => (
    <Message negative={negative}>
      {title && <Message.Header>{title}</Message.Header>}
      <Message.Content>{children}</Message.Content>
    </Message>
  )

  const localizedErrorText = (
    mutation,
    defaultErrorTextLangId,
    errorTitleTextLangId,
    messageNegative,
    showRawErrorMessage
  ) => {
    if (!mutation || !mutation.isError) {
      return ''
    }

    // NOTE: HARD CODED TO FINNISH BECAUSE LANGUAGE SWITCH IS NOT SUPPORTED YET.
    const defaultLang = 'fi'

    const localizedErrorMessage = localizedErrorString(
      mutation.error.body,
      defaultLang
    )
    const rawMessage = showRawErrorMessage && mutation.error.body?.message
    const localizedTitle = errorTitleTextLangId && (
      <FormattedMessage id={errorTitleTextLangId} />
    )

    if (localizedErrorMessage) {
      return (
        <RenderMessage title={localizedTitle} negative={messageNegative}>
          {localizedErrorMessage}
        </RenderMessage>
      )
    } else {
      return (
        <RenderMessage title={localizedTitle} negative={messageNegative}>
          <FormattedMessage id={defaultErrorTextLangId} />
          <p>{rawMessage}</p>
        </RenderMessage>
      )
    }
  }

  return localizedErrorText(
    mutation,
    defaultErrorTextLangId,
    errorTitleTextLangId,
    messageNegative,
    showRawErrorMessage
  )
}
MutationErrorMessage.propTypes = {
  mutation: PropTypes.shape({}),
  defaultErrorTextLangId: PropTypes.string
}

export default MutationErrorMessage
