import { MoovyTable, TablePagination } from '../../components'
import {
  settlementRecipientCompanyTypeEnum as companyTypeEnum,
  getStringValue,
  adyenAccountHolderTopMainStatusEnum as mainStatus,
  vismaRoleEnum as vismaRole
} from '../../services/utils/DTOEnums'

import { FormattedMessage } from 'react-intl'
import React from 'react'
import { Table } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

const SettlementRecipientsTable = ({
  query,
  recipients,
  sortedColumn,
  sortDirection,
  onSort,
  activePage,
  totalPages,
  onPageChange
}) => {
  const navigate = useNavigate()

  const onRowClicked = (recipientRef) => {
    navigate(`/admin/settlementRecipients/${recipientRef}`)
  }

  const VismaMandatorsActive = ({ visma }) => {
    if (!visma) {
      return ''
    }
    return visma?.allMandatorsActive ? (
      <FormattedMessage id="settlementRecipients.settlementRecipientTable.vismaMandatorsActive" />
    ) : (
      <FormattedMessage id="settlementRecipients.settlementRecipientTable.vismaMandatorsInActive" />
    )
  }

  return (
    <>
      <MoovyTable sortable selectable items={recipients} query={query}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={sortedColumn === 'sortByName' ? sortDirection : null}
              onClick={() => onSort('sortByName')}
            >
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.companyName" />
            </Table.HeaderCell>
            <Table.HeaderCell compact={'very'}>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.extensionName" />
            </Table.HeaderCell>
            <Table.HeaderCell compact={'very'}>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.companyType" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.operator" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.businessId" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.contact" />
            </Table.HeaderCell>
            <Table.HeaderCell compact={'very'}>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.city" />
            </Table.HeaderCell>
            <Table.HeaderCell compact={'very'}>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.vismaRoles" />
            </Table.HeaderCell>
            <Table.HeaderCell compact={'very'}>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.vismaStatus" />
            </Table.HeaderCell>
            <Table.HeaderCell compact={'very'}>
              <FormattedMessage id="settlementRecipients.settlementRecipientTable.header.adyenStatus" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {recipients &&
            recipients.map((recipient) => (
              <Table.Row
                key={recipient.ref}
                onClick={() => onRowClicked(recipient.ref)}
              >
                <Table.Cell>{recipient.companyName}</Table.Cell>
                <Table.Cell>{recipient.nameExtension}</Table.Cell>
                <Table.Cell>
                  {(recipient.companyType && (
                    <FormattedMessage
                      id={getStringValue(
                        companyTypeEnum.localizationKeys,
                        recipient.companyType
                      )}
                      defaultMessage={recipient.companyType}
                    />
                  )) ||
                    '-'}
                </Table.Cell>
                <Table.Cell>{recipient.operator?.name}</Table.Cell>
                <Table.Cell>{recipient.businessId}</Table.Cell>
                <Table.Cell>{recipient.contact?.name}</Table.Cell>
                <Table.Cell>{recipient.city}</Table.Cell>
                <Table.Cell>
                  {recipient?.visma?.roles?.map((role, i, row) => {
                    let comma = ', '
                    if (i + 1 == row.length) {
                      comma = ''
                    }
                    return (
                      <span key={recipient.ref + i}>
                        <FormattedMessage
                          id={getStringValue(vismaRole.localizationKeys, role)}
                          defaultMessage={role}
                        />
                        {comma}
                      </span>
                    )
                  })}
                </Table.Cell>
                <Table.Cell>
                  <VismaMandatorsActive visma={recipient?.visma} />
                </Table.Cell>
                <Table.Cell>
                  <FormattedMessage
                    id={getStringValue(
                      mainStatus.localizationKeys,
                      recipient.adyenMainStatus
                    )}
                    defaultMessage={recipient.adyenMainStatus}
                  />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <TablePagination
          colSpan={10}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </MoovyTable>
    </>
  )
}

export default SettlementRecipientsTable
