import { Button, Tab, Table } from 'semantic-ui-react'

import React from 'react'
import { formatDateAndTime } from '../../../../../services/utils'

const AssignmentStatus = {
  ACCEPTED: 'ACCEPTED',
  PENDING: 'PENDING'
}

const renderEditButton = (assignment, onAccept, onRevoke) => {
  if (assignment.status === AssignmentStatus.PENDING) {
    return (
      <Button positive size="tiny" onClick={() => onAccept(assignment)}>
        Hyväksy
      </Button>
    )
  }

  return (
    <Button negative size="tiny" onClick={() => onRevoke(assignment)}>
      Lopeta
    </Button>
  )
}

const isAccepted = (assignment) =>
  !assignment.endTime && assignment.status === AssignmentStatus.ACCEPTED

const getStatusText = (assignment) => {
  if (assignment.endTime) {
    return 'Päättynyt'
  }
  switch (assignment.status) {
    case AssignmentStatus.ACCEPTED:
      return 'Hyväksytty'
    case AssignmentStatus.PENDING:
      return 'Kutsu hyväksymättä'
    default:
      return ''
  }
}

export default ({ active, ended, onAccept, onRevoke, isSuperOperator }) => [
  {
    menuItem: `Aktiiviset (${active.length})`,
    render: () => (
      <Tab.Pane>
        <Table size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Alkupäivämäärä</Table.HeaderCell>
              <Table.HeaderCell>Loppupäivämäärä</Table.HeaderCell>
              <Table.HeaderCell>Lisätieto</Table.HeaderCell>
              <Table.HeaderCell>Ajoneuvoja enintään</Table.HeaderCell>
              <Table.HeaderCell>Tila</Table.HeaderCell>
              {isSuperOperator && <Table.HeaderCell>Muokkaa</Table.HeaderCell>}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {active.map((assignment) => (
              <Table.Row
                key={assignment.ref}
                positive={isAccepted(assignment)}
                negative={!!assignment.endTime}
              >
                <Table.Cell>
                  {formatDateAndTime(assignment.startTime)}
                </Table.Cell>
                <Table.Cell>{formatDateAndTime(assignment.endTime)}</Table.Cell>
                <Table.Cell>{assignment.additionalInfo}</Table.Cell>
                <Table.Cell>{assignment.maxActiveVehicles}</Table.Cell>
                <Table.Cell>{getStatusText(assignment)}</Table.Cell>
                {isSuperOperator && (
                  <Table.Cell>
                    {renderEditButton(assignment, onAccept, onRevoke)}
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Tab.Pane>
    )
  },
  {
    menuItem: `Päättyneet (${ended.length})`,
    render: () => (
      <Tab.Pane>
        <Table size="small">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Alkupäivämäärä</Table.HeaderCell>
              <Table.HeaderCell>Loppupäivämäärä</Table.HeaderCell>
              <Table.HeaderCell>Lisätieto</Table.HeaderCell>
              <Table.HeaderCell>Tila</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {ended.map((assignment) => (
              <Table.Row key={assignment.ref} negative={!!assignment.endTime}>
                <Table.Cell>
                  {formatDateAndTime(assignment.startTime)}
                </Table.Cell>
                <Table.Cell>{formatDateAndTime(assignment.endTime)}</Table.Cell>
                <Table.Cell>{assignment.additionalInfo}</Table.Cell>
                <Table.Cell>{getStatusText(assignment)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Tab.Pane>
    )
  }
]
