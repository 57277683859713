import { Accordion, Form, Icon, Select } from 'semantic-ui-react'
import {
  ChargerTypeEnum,
  ChargingStatusEnum,
  getEnumKeys,
  getStringValue
} from '../../services/utils/DTOEnums'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovySearchInput,
  MoovyTableFilter,
  MoovyTextInput,
  Toolbar
} from '../../components'
import React, { useState } from 'react'

import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import moovyDateTime from '../../services/utils/moovyDateTime'
import { searchEntityTypeEnum } from '../../services/utils/DTOEnums'

const ChargingSessionToolbar = ({ state, onChangeFilter }) => {
  const intl = useIntl()

  const [selectedUser, setSelectedUser] = useState('')
  const [selectedLocation, setSelectedLocation] = useState('')
  const [additionalFilterOpen, setAdditionalFilterOpen] = useState(false)

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.chargings'
      }),
      active: true
    }
  ])

  const {
    pluginId,
    chargingStatus,
    chargerType,
    tableFilterRange,
    tableCustomStartTime,
    tableCustomEndTime
  } = state

  const getOptions = (enumObject) => {
    return getEnumKeys(enumObject)
      .sort((a, b) =>
        intl
          .formatMessage({
            id: getStringValue(enumObject.localizationKeys, a)
          })
          .localeCompare(
            intl.formatMessage({
              id: getStringValue(enumObject.localizationKeys, b)
            })
          )
      )
      .map((item) => {
        return {
          key: item,
          value: item,
          text: (
            <FormattedMessage
              id={getStringValue(enumObject.localizationKeys, item)}
              defaultMessage={item}
            />
          )
        }
      })
  }

  const chargingStatusOptions = getOptions(ChargingStatusEnum)
  const chargerTypeOptions = getOptions(ChargerTypeEnum)

  return (
    <Toolbar
      title={intl.formatMessage({
        id: 'chargingSessionToolbar.header'
      })}
      breadcrumbs={breadcrumbs}
      content={
        <>
          <div>
            <MoovyTableFilter
              maxRangeAsDays={31}
              tableFilterRange={tableFilterRange}
              initialDateRange={{
                startTime: moovyDateTime.isoToJSDate(tableCustomStartTime),
                endTime: moovyDateTime.isoToJSDate(tableCustomEndTime)
              }}
              onSelectedDateTimeRange={(range) => {
                const startDateAsCalenderFormat =
                  moovyDateTime.dateToCalendarInput(range.startTime)
                const endDateAsCalenderFormat =
                  moovyDateTime.dateToCalendarInput(range.endTime)
                onChangeFilter('dateRange', {
                  startDate: startDateAsCalenderFormat,
                  endDate: endDateAsCalenderFormat,
                  tableFilterRange: range.tableFilterRange,
                  tableCustomStartTime: range?.startTime?.toJSDate(),
                  tableCustomEndTime: range?.endTime?.toJSDate()
                })
              }}
            >
              <Form.Group widths={'equal'}>
                <Form.Field>
                  <label>
                    <FormattedMessage id="chargingSessionToolbar.label.pluginId" />
                  </label>
                  <MoovyTextInput
                    value={pluginId}
                    name="pluginId"
                    onChange={(e) => onChangeFilter('pluginId', e.target.value)}
                    onClear={() => onChangeFilter('pluginId', '')}
                    placeholder={intl.formatMessage({
                      id: 'chargingSessionToolbar.placeholder.pluginId'
                    })}
                    type="number"
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="chargingSessionToolbar.label.customer" />
                  </label>
                  <MoovySearchInput
                    inputKey={'searchCustomer'}
                    selectedItem={selectedUser}
                    setSelectedItem={(user) => {
                      setSelectedUser(user)
                      onChangeFilter('customerRef', user?.ref)
                    }}
                    entityType={searchEntityTypeEnum.USER}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="chargingSessionToolbar.label.location" />
                  </label>
                  <MoovySearchInput
                    inputKey={'searchLocation'}
                    selectedItem={selectedLocation}
                    setSelectedItem={(location) => {
                      setSelectedLocation(location)
                      onChangeFilter('locationRef', location?.ref)
                    }}
                    entityType={searchEntityTypeEnum.LOCATION}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    <FormattedMessage id="chargingSessionToolbar.label.chargings" />
                  </label>
                  <Form.Checkbox
                    style={{ paddingTop: '10px' }}
                    label={intl.formatMessage({
                      id: 'chargingSessionToolbar.label.showAlsoCompletedChargings'
                    })}
                    onChange={(e, data) =>
                      onChangeFilter('ongoingOnly', !data.checked)
                    }
                  />
                </Form.Field>
              </Form.Group>
              <Accordion>
                <Accordion.Title
                  onClick={() => setAdditionalFilterOpen(!additionalFilterOpen)}
                  active={additionalFilterOpen}
                >
                  <Icon name="dropdown" style={{ verticalAlign: 'middle' }} />
                  <FormattedMessage id="chargingSessionToolbar.label.moreFilters" />
                </Accordion.Title>
                <Accordion.Content active={additionalFilterOpen}>
                  <Form.Group widths={'equal'}>
                    <Form.Field>
                      <label>
                        <FormattedMessage id="chargingSessionToolbar.label.chargingStatus" />
                      </label>
                      <Select
                        value={chargingStatus}
                        clearable
                        options={chargingStatusOptions}
                        onChange={(e, { value }) =>
                          onChangeFilter('chargingStatus', value)
                        }
                        placeholder={intl.formatMessage({
                          id: 'chargingSessionToolbar.placeholder.chargingStatus'
                        })}
                        selectOnBlur={false}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>
                        <FormattedMessage id="chargingSessionToolbar.label.chargerType" />
                      </label>
                      <Select
                        value={chargerType}
                        clearable
                        options={chargerTypeOptions}
                        onChange={(e, { value }) =>
                          onChangeFilter('chargerType', value)
                        }
                        placeholder={intl.formatMessage({
                          id: 'chargingSessionToolbar.placeholder.chargerType'
                        })}
                        selectOnBlur={false}
                      />
                    </Form.Field>
                    <Form.Field />
                    <Form.Field />
                  </Form.Group>
                </Accordion.Content>
              </Accordion>
            </MoovyTableFilter>
          </div>
        </>
      }
    />
  )
}

export default ChargingSessionToolbar
