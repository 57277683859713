import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../components'
import React from 'react'

const validationSchema = () => {
  return yup.object().shape({
    locationName: yup.string().required('Cannot be empty').max(60)
  })
}

const ModalEditLocationName = ({ onClose, location, onSubmit, mutation }) => {
  const intl = useIntl()

  const initialFormikData = (location) => {
    const { name } = location
    return {
      locationName: name
    }
  }

  const handleFormitSubmit = (values) => {
    const { locationName } = values

    onSubmit({
      locationRef: location.ref,
      name: locationName
    })
  }

  return (
    <Formik
      initialValues={initialFormikData(location)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'locationEditNameModal.title'
              },
              {
                name: location.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="locationEditNameModal.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'locationEditNameModal.label.name'
                })}
                value={values.locationName}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                maxLength={60}
                name="locationName"
                error={!!errors.locationName}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={mutation.isPending}
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditLocationName
