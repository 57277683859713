import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Header,
  Message,
  Modal,
  Segment,
  Sidebar,
  Table
} from 'semantic-ui-react'

import userVoucherService from '../../services/UserVouchers'
import { voucher as voucherEnums } from '../../services/utils/DTOEnums'
import { voucherShape } from '../../services/utils/shapes'
import { createErrorMessage, createInfoMessage } from '../GenericMessage'
import MoovyPlaceholderLoader from '../MoovyPlaceholderLoader'
import EditVoucherForm from './EditVoucherForm'
import VoucherFields from './VoucherFields'

const VoucherModal = ({
  operatorRealm,
  onClose,
  voucher,
  onVoucherUpdate,
  allowEdit
}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [voucherInfo, setVoucherInfo] = useState(null)
  const [editing, setEditing] = useState(false)
  const [updateMessage, setUpdateMessage] = useState()

  useEffect(() => {
    setLoading(true)
    setError(false)
    if (voucher) {
      userVoucherService
        .fetchVoucherInfo(voucher.ref, operatorRealm)
        .then((res) => setVoucherInfo(res))
        .catch(() => {
          setError(true)
          setVoucherInfo({})
        })
        .finally(() => setLoading(false))
    }
  }, [voucher.ref])

  useEffect(() => {
    const timer = setTimeout(() => {
      setUpdateMessage()
    }, 3000)
    return () => clearTimeout(timer)
  }, [updateMessage])

  const updateVoucher = (licensePlateNumber) => {
    userVoucherService
      .updateVoucher(voucher.ref, operatorRealm, { licensePlateNumber })
      .then(() => {
        onVoucherUpdate({ ...voucher, licensePlateNumber })
        setUpdateMessage(createInfoMessage('Rekisteritunnus muutettu'))
      })
      .catch(() => {
        setUpdateMessage(
          createErrorMessage('Rekisteritunnuksen muuttamisessa tapahtui virhe')
        )
      })
  }

  return (
    <Modal open onClose={onClose} closeIcon>
      <Modal.Header>
        {voucherEnums.categoryTypes.stringValues[voucher.categoryType]}
      </Modal.Header>
      <Modal.Content>
        <Sidebar.Pushable as={Segment} raised>
          <Sidebar
            animation="push"
            visible={editing}
            onHide={() => setEditing(false)}
            width="wide"
          >
            <EditVoucherForm
              voucher={voucher}
              onSubmit={updateVoucher}
              message={updateMessage}
            />
          </Sidebar>
          <Sidebar.Pusher dimmed={editing}>
            <Segment basic>
              {loading && <MoovyPlaceholderLoader paragraphs={2} />}
              {!loading && (
                <>
                  {!error && voucherInfo.voucherUsages === undefined && (
                    <Message info>Etu on käyttämätön.</Message>
                  )}
                  {error && (
                    <Message error>
                      Pysäköintiedun tietojen lataamisessa tapahtui virhe.
                    </Message>
                  )}
                  <VoucherFields voucher={voucher} voucherInfo={voucherInfo} />
                  {allowEdit && voucher.categoryType === 'PREBOOKING' && (
                    <Button
                      type="button"
                      style={{ marginBottom: '1em' }}
                      onClick={() => setEditing(true)}
                    >
                      Muokkaa
                    </Button>
                  )}
                  {voucherInfo.voucherUsages !== undefined && (
                    <>
                      <Header>Edun käyttö</Header>
                      <Table>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell>Käyttötunniste</Table.HeaderCell>
                            <Table.HeaderCell>Myyntitunniste</Table.HeaderCell>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {voucherInfo.voucherUsages.map((u) => (
                            <Table.Row key={u.ref}>
                              <Table.Cell>{u.ref}</Table.Cell>
                              <Table.Cell>{u.saleRef}</Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </>
                  )}
                </>
              )}
            </Segment>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </Modal.Content>
    </Modal>
  )
}

export default VoucherModal

VoucherModal.propTypes = {
  voucher: PropTypes.shape(voucherShape).isRequired,
  operatorRealm: PropTypes.string,
  onClose: PropTypes.func,
  onVoucherUpdate: PropTypes.func,
  allowEdit: PropTypes.bool
}
