import { Button, Checkbox, Icon, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  PermissionUser,
  creditCardExpired,
  formatDateAndTime
} from '../../../../services/utils'
import React, { useState } from 'react'
import {
  getStringValue,
  paymentCardFetchType,
  paymentMethod
} from '../../../../services/utils/DTOEnums'

import { LocalizedPaymentCardCategory } from '../../../../components/MoovyLocalizedEnum'
import ModalConfirmDeletePaymentCard from './modalConfirmDeletePaymentCard'
import { containsDefaultPaymentCard } from '..'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import useAuthInfo from '../../../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'
import userService from '../../../../services/User/UserServices'

const PaymentCards = ({
  cards,
  customer,
  onChangeCardFilterType,
  onRefresh
}) => {
  const intl = useIntl()
  const { isSuperOperator, roles } = useAuthInfo()

  const [deletableCard, setDeletableCard] = useState(null)
  const [showAllCards, setShowAllCards] = useState(false)
  const onChangeShowDeletedPaymentCards = (checked) => {
    if (checked) {
      onChangeCardFilterType(paymentCardFetchType.ALL)
    } else {
      onChangeCardFilterType(paymentCardFetchType.ACTIVE)
    }
    setShowAllCards(checked)
  }

  const cardCandidatesForDeletion =
    (isSuperOperator && cards.filter((item) => !item.deletionTime)) || []

  const deletableCards = cardCandidatesForDeletion.map((item) => item.ref)

  const { mutate: removeCard, ...removeCardMutation } = useMutation({
    mutationFn: (card) =>
      userService.removePaymentCard(
        customer.ref,
        card.cardRef,
        card.forceDelete
      ),
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'paymentCards.action.remove.success.title',
        description: 'paymentCards.action.remove.success.description'
      })
      setDeletableCard(null)
      onRefresh()
    }
  })

  return (
    <>
      <Checkbox
        label={intl.formatMessage({
          id: 'paymentCards.checkbox.label.showDeletedCardsToo'
        })}
        onChange={(e, data) => onChangeShowDeletedPaymentCards(data.checked)}
      />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="paymentCards.table.header.cardCategory" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="paymentCards.table.header.type" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="paymentCards.table.header.cardDigits" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="paymentCards.table.header.expireYear" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="paymentCards.table.header.paymentMethodType" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="paymentCards.table.header.defaultCard" />
            </Table.HeaderCell>
            {showAllCards && (
              <>
                <Table.HeaderCell>
                  <FormattedMessage id="paymentCards.table.header.creationTime" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="paymentCards.table.header.deletionTime" />
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {cards.map((card) => (
            <Table.Row
              key={card.ref}
              error={creditCardExpired(card, new Date())}
            >
              <Table.Cell>
                <LocalizedPaymentCardCategory
                  value={card.paymentCardCategory}
                />
              </Table.Cell>
              <Table.Cell>{card.type}</Table.Cell>
              <Table.Cell>**** **** **** {card.lastDigits}</Table.Cell>
              <Table.Cell>
                {card.expireMonth}/{card.expireYear}
              </Table.Cell>
              <Table.Cell>
                <FormattedMessage
                  id={getStringValue(
                    paymentMethod.localizationKeys,
                    card.paymentMethodType
                  )}
                  defaultMessage={card.paymentMethodType}
                />
              </Table.Cell>
              <Table.Cell>
                {containsDefaultPaymentCard(customer, [{ ...card }]) && (
                  <Icon className="primaryColor" name="check" />
                )}
              </Table.Cell>
              {showAllCards && (
                <>
                  <Table.Cell>
                    {formatDateAndTime(card.creationTime)}
                  </Table.Cell>
                  <Table.Cell>
                    {formatDateAndTime(card.deletionTime)}
                  </Table.Cell>
                </>
              )}
              <Table.Cell textAlign="right">
                {PermissionUser.HasRemovePaymentCard(isSuperOperator, roles) &&
                  deletableCards.includes(card.ref) && (
                    <Button
                      basic
                      icon
                      onClick={() => setDeletableCard(card)}
                      disabled={removeCardMutation.isPending}
                      loading={removeCardMutation.isPending}
                    >
                      <Icon name="trash alternate" color="red" />
                    </Button>
                  )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <ModalConfirmDeletePaymentCard
          open={!!deletableCard}
          customer={customer}
          deletableCard={deletableCard}
          cardsTotalCount={deletableCards.length}
          onClose={() => setDeletableCard(null)}
          onSubmit={(forceDelete) => {
            removeCard({ cardRef: deletableCard?.ref, forceDelete })
          }}
          mutation={removeCardMutation}
        />
      </Table>
    </>
  )
}

export default PaymentCards
