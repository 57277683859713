import { Grid, Header } from 'semantic-ui-react'

import ParkingDataTable from '../../../../components/ParkingDataTable'
import ParkingPriceSummed from '../../../../components/ParkingDataTable/ParkingPriceSummed'
import QueryContainer from '../../../../components/QueryContainer'
import React from 'react'
import { tablePaginationDirections as directions } from '../../../../services/utils'
import moovyDatetime from '../../../../services/utils/moovyDateTime'
import { useIntl } from 'react-intl'

export const ListParkingsForCustomer = (props) => {
  return <ListParkings {...props} listFor="customer" />
}

function ListParkings({
  query,
  parkings,
  initiallySelectedParkingRef,
  onClickParking,
  listFor,
  setQuery,
  refreshParkingData,
  headerButtons
}) {
  const intl = useIntl()

  const handleSort = (sortColumn) => () => {
    if (sortColumn === query.sort) {
      const direction =
        query.direction === directions.DESC ? directions.ASC : directions.DESC
      setQuery({ ...query, direction })
    } else {
      setQuery({ ...query, sort: sortColumn, direction: directions.DESC })
    }
  }

  const listForLocation = listFor === 'location'

  const total = parkings.data ? parkings.data.total : 0
  const countString = `${total} ${
    total === 1
      ? intl.formatMessage({
          id: 'listParkings.header.countAllEvent'
        })
      : intl.formatMessage({
          id: 'listParkings.header.countAllEvents'
        })
  }`
  return (
    <>
      <Grid>
        <Grid.Column floated="left" width={8} verticalAlign="bottom">
          <Header size="small">
            {`${intl.formatMessage({
              id: 'listParkings.header.allEvents'
            })} ${moovyDatetime.dateToCalendarInput(
              query.begin
            )} - ${moovyDatetime.dateToCalendarInput(
              query.end
            )}  (${countString})`}
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          {headerButtons}
        </Grid.Column>
      </Grid>
      <QueryContainer query={parkings}>
        <>
          <ParkingDataTable
            enableEndParking={listFor === 'location'}
            data={parkings.data ? parkings.data.content : []}
            initiallySelectedParkingRef={initiallySelectedParkingRef}
            onClickParking={onClickParking}
            onSorting={handleSort}
            activePage={query.page ? query.page : 1}
            totalPages={
              parkings.data ? Math.ceil(parkings.data.total / query.size) : 0
            }
            onPageChange={(e, { activePage }) =>
              setQuery({ ...query, page: activePage })
            }
            sort={{ column: query.sort, direction: query.direction }}
            listForLocation={listForLocation}
            refreshParkingData={refreshParkingData}
          />

          {parkings.data && parkings.data.content.length > 0 && (
            <ParkingPriceSummed parkings={parkings} />
          )}
        </>
      </QueryContainer>
    </>
  )
}
