import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Modal, Segment } from 'semantic-ui-react'

import GenericMessage, {
  createErrorMessage
} from '../../components/GenericMessage'
import UserGroupAgreements from '../../components/UserGroupAgreements'
import useAuthInfo from '../../hooks/useAuthInfo'
import userGroupsService from '../../services/UserGroups'
import { refNamePair } from '../../services/utils'

const UserGroupModal = ({ userGroup, userRef, onClose, onRemoveUser }) => {
  if (!userGroup) {
    return null
  }
  const [serviceAgreements, setServiceAgreements] = useState({
    isLoading: false,
    data: []
  })
  const [voucherGrantAgreements, setVoucherGrantAgreements] = useState({
    isLoading: false,
    data: []
  })
  const [message, setMessage] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { operatorRealm } = useAuthInfo()

  useEffect(() => {
    if (userGroup.ref) {
      setServiceAgreements({ isLoading: true, data: [] })
      setVoucherGrantAgreements({ isLoading: true, data: [] })

      userGroupsService
        .fetchServiceAgreements(userGroup.ref, operatorRealm)
        .then((data) => setServiceAgreements({ isLoading: false, data }))
        .catch(() => setServiceAgreements({ isLoading: false, data: [] }))

      userGroupsService
        .fetchVoucherGrantAgreements(userGroup.ref, operatorRealm)
        .then((data) => setVoucherGrantAgreements({ isLoading: false, data }))
        .catch(() => setVoucherGrantAgreements({ isLoading: false, data: [] }))
    }
  }, [userGroup.ref])

  const handleRemoveUser = () => {
    setIsSubmitting(true)
    userGroupsService
      .removeUserFromGroup(userRef, userGroup.ref, operatorRealm)
      .then(() => {
        onRemoveUser()
      })
      .catch(() => {
        setMessage(
          createErrorMessage('Käyttäjäryhmästä poistaminen ei onnistunut')
        )
      })
      .finally(() => setIsSubmitting(false))
  }

  return (
    <Modal open onClose={onClose} closeIcon>
      <Modal.Header>
        {userGroup.name} - {userGroup.operator && userGroup.operator.name}
      </Modal.Header>
      <Modal.Content>
        <UserGroupAgreements
          serviceAgreements={serviceAgreements}
          voucherGrantAgreements={voucherGrantAgreements}
        />
        <Segment basic textAlign="center">
          <GenericMessage message={message} />
          <Button
            onClick={handleRemoveUser}
            negative
            content="Poista käyttäjäryhmästä"
            loading={isSubmitting}
          />
        </Segment>
      </Modal.Content>
    </Modal>
  )
}

UserGroupModal.propTypes = {
  userRef: PropTypes.string.isRequired,
  userGroup: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    operator: PropTypes.shape(refNamePair).isRequired
  }),
  onClose: PropTypes.func.isRequired,
  onRemoveUser: PropTypes.func.isRequired
}

export default UserGroupModal
