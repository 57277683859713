import { FormattedMessage, useIntl } from 'react-intl'
import { ModalStartParking, MoovyButton } from '../../../components'
import React, { useState } from 'react'
import {
  frontendParkingTypeEnum,
  parkingTypeEnum,
  commonEnum
} from '../../../services/utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { Icon } from 'semantic-ui-react'
import ParkingModal from '../../../containers/ParkingModal'
import ParkingNowContent from './parkingNowContent'
import ParkingNowFilters from './parkingNowFilters'
import { getTotalPages } from '../../../services/utils/tablePagination'
import locationService from '../../../services/Locations'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import useAuthInfo from '../../../hooks/useAuthInfo'

const ParkingNowTab = ({ activeTab, location }) => {
  const intl = useIntl()
  const queryClient = useQueryClient()
  const { operatorRealm } = useAuthInfo()

  const [anonymousParkingModalOpen, setAnonymousParkingModalOpen] =
    useState(false)
  const [selectedParkingRef, setSelectedParkingRef] = useState()
  const [initiallySelectedParkingRef, setInitiallySelectedParkingRef] =
    useState(null)

  const pageLimit = 50

  const [state, setState] = useState({
    partialLicensePlateNumber: '',
    parkingType: frontendParkingTypeEnum.ALL,
    serviceType: commonEnum.ALL,
    exitTimeExceeded: undefined,
    activePage: 1
  })

  const getQueryParams = () => {
    return {
      licensePlateNumber: state.partialLicensePlateNumber,
      type:
        state.parkingType === frontendParkingTypeEnum.ALL
          ? undefined
          : state.parkingType,
      serviceType:
        state.serviceType === commonEnum.ALL ? undefined : state.serviceType,
      filterByExitTimeExceeded: state.exitTimeExceeded || undefined,
      page: state.activePage - 1,
      limit: pageLimit
    }
  }

  const ongoingParkingsQuery = useQuery({
    queryKey: [
      'ongoingParkingsQuery',
      location?.ref,
      state.partialLicensePlateNumber,
      state.parkingType,
      state.serviceType,
      state.exitTimeExceeded,
      state.activePage
    ],
    queryFn: () => {
      return locationService.fetchOngoingParking(
        location?.ref,
        getQueryParams(),
        operatorRealm
      )
    },
    enabled: Boolean(location && activeTab)
  })

  const ongoingParkingsQueryIsLoading =
    Boolean(location && activeTab) &&
    (ongoingParkingsQuery.isFetching || ongoingParkingsQuery.isLoading)

  const totalPages = ongoingParkingsQuery?.data?.pageable
    ? getTotalPages(ongoingParkingsQuery.data.pageable.total, pageLimit)
    : 0

  const refresh = (force) => {
    if (force) {
      state.activePage !== 1
        ? setState({ ...state, activePage: 1 })
        : ongoingParkingsQuery.refetch()
    } else {
      queryClient.invalidateQueries({
        queryKey: [
          'ongoingParkingsQuery',
          location?.ref,
          state.partialLicensePlateNumber,
          state.parkingType,
          state.serviceType,
          state.exitTimeExceeded,
          state.activePage
        ]
      })
    }
  }

  const { mutate: startParking, ...startParkingMutation } = useMutation({
    mutationFn: ({ licensePlateNumber, startTime, serviceRef }) =>
      locationService.startParking(
        {
          licensePlateNumber,
          startTime,
          location: location.ref,
          serviceRef
        },
        operatorRealm
      ),
    onSuccess: (data, { licensePlateNumber }) => {
      setAnonymousParkingModalOpen(false)

      let titleLocId = 'modalStartParking.toast.success.title.anonymousParking'
      let bodyLocId = 'modalStartParking.toast.success.body.anonymousParking'

      if (data?.type === parkingTypeEnum.MOOVY_PARKING) {
        titleLocId = 'modalStartParking.toast.success.title.moovyParking'
        bodyLocId = 'modalStartParking.toast.success.body.moovyParking'
      }

      showLocalizedMoovyToast(intl, {
        title: titleLocId,
        description: bodyLocId,
        values: {
          licensePlate: licensePlateNumber,
          location: data?.location?.name
        },
        time: data?.type === parkingTypeEnum.MOOVY_PARKING ? 10000 : undefined
      })
      refresh(true)
    }
  })

  const closeParkingModal = () => {
    setInitiallySelectedParkingRef(undefined)
    setSelectedParkingRef(undefined)
  }

  return (
    <>
      <ParkingNowFilters
        showExitTimeFilter={!!location.anonymousParkingSupported}
        updatedAt={ongoingParkingsQuery.dataUpdatedAt}
        loading={ongoingParkingsQueryIsLoading}
        onRefresh={() => refresh()}
        values={state}
        setValues={(values) => setState({ ...state, ...values })}
      />
      <ParkingNowContent
        parkingsQuery={ongoingParkingsQuery}
        activePage={state.activePage}
        onPageChange={(activePage) => setState({ ...state, activePage })}
        totalPages={totalPages}
        initiallySelectedParkingRef={initiallySelectedParkingRef}
        onClickParking={(parking) => {
          setSelectedParkingRef(parking.ref)
          setInitiallySelectedParkingRef(parking.ref)
        }}
        onRefresh={(force) => refresh(force)}
        headerButtons={
          location?.anonymousParkingSupported && (
            <>
              <MoovyButton
                onClick={() => setAnonymousParkingModalOpen(true)}
                disabled={!location || ongoingParkingsQueryIsLoading}
              >
                <Icon name="user icon" />
                <FormattedMessage id="parkingTab.button.startParking" />
              </MoovyButton>
              <ModalStartParking
                modalType={parkingTypeEnum.ANONYMOUS_PARKING}
                open={anonymousParkingModalOpen}
                location={location}
                mutation={startParkingMutation}
                onClose={() => setAnonymousParkingModalOpen(false)}
                onStartParking={startParking}
              />
            </>
          )
        }
        showParkingExceededColumns={!!state.exitTimeExceeded}
      />
      <ParkingModal
        parkingRef={selectedParkingRef}
        barrierlessFacility={location?.barrierlessFacility}
        onClose={closeParkingModal}
        onClickParkingSequence={(ref) => setSelectedParkingRef(ref)}
        whenParkingUpdated={() => refresh(true)}
      />
    </>
  )
}

export default ParkingNowTab
