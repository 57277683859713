import './Login.scss'

import React, { useEffect, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { authSelectors, setRealm } from '../../redux/auth'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'semantic-ui-react'
import { localizationSelectors } from '../../redux/localization'

export default function Login({ keycloak }) {
  const [error, setError] = useState(false)
  const [realmInput, setRealmInput] = useState('')

  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const realmFromState = useSelector(authSelectors.getRealm)
  const locale = useSelector(localizationSelectors.getLocale)
  const dispatch = useDispatch()

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)

  const operatorRealmPrefix = 'oper-'
  // If we have a realm in redux, set it as initial value
  useEffect(() => {
    if (realmFromState) {
      setRealmInput(
        realmFromState.startsWith(operatorRealmPrefix)
          ? realmFromState.slice(operatorRealmPrefix.length)
          : realmFromState
      )
    }
  }, [realmFromState])

  if (isAuthenticated) {
    return <Navigate to="/" replace />
  }

  const login = async () => {
    // Check from backend operator existence
    const realmValidationUrl = `${
      process.env.REACT_APP_API_URL
    }/prelogin/operator/${encodeURIComponent(realmInput)}/validity`
    const realmOk = await (await fetch(realmValidationUrl)).json()

    if (realmOk) {
      // The superoperator realm can be used as is, but operator realms have to add 'oper-'
      const fullRealmName =
        realmInput === process.env.REACT_APP_SUPEROPERATOR_REALM
          ? realmInput
          : operatorRealmPrefix + realmInput
      // Setting the realm directly here is fine, we are redirected to keycloak anyway
      // eslint-disable-next-line no-param-reassign
      keycloak.realm = fullRealmName
      // Call the reducer so realm is saved to localStorage
      dispatch(setRealm(fullRealmName))
      keycloak.login({ locale, redirectUri: searchParams.get('redirectUrl') })
    } else {
      setError(true)
    }
  }
  return (
    <div className="Login">
      <Modal
        open={error}
        content={`Epäkelpo operaattori: "${realmInput}"`}
        closeOnDocumentClick
        closeIcon="close"
        onClose={() => setError(false)}
      />
      <div className="Login--RealmSelection">
        <div className="Login--RealmSelection--Title">Operaattori</div>
        <div className="Login--RealmSelection--Form">
          <form
            onSubmit={(event) => {
              event.preventDefault()
              realmInput && login()
            }}
          >
            <input
              placeholder="operaattori-tunnus"
              type="text"
              name="realm"
              value={realmInput}
              onChange={(ev) => setRealmInput(ev.target.value)}
            />
            <input type="submit" value="Siirry kirjautumaan" />
          </form>
        </div>
      </div>
    </div>
  )
}
