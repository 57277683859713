/* eslint react/no-array-index-key: 0 */
import React from 'react'
import { Popup, Table } from 'semantic-ui-react'

import { KeyValueTable } from '../../../components'
import { formatDateAndTime } from '../../../services/utils'

const ParkingAuditLog = ({ auditLogs }) => {
  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Tyyppi</Table.HeaderCell>
          <Table.HeaderCell>Aika</Table.HeaderCell>
          <Table.HeaderCell>Tekijä</Table.HeaderCell>
          <Table.HeaderCell>Kommentti</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {auditLogs &&
          auditLogs.map((row, rowIndex) => {
            const {
              eventType,
              timeStamp,
              initiator,
              comment,
              data: rowData
            } = row
            return (
              <Popup
                key={rowIndex}
                content={
                  <KeyValueTable
                    data={rowData.map((entry) => [entry.key, entry.value])}
                  />
                }
                on="click"
                trigger={
                  <Table.Row>
                    <Table.Cell>{eventType}</Table.Cell>
                    <Table.Cell>{formatDateAndTime(timeStamp)}</Table.Cell>
                    <Table.Cell>{initiator && initiator.name}</Table.Cell>
                    <Table.Cell>{comment}</Table.Cell>
                  </Table.Row>
                }
              />
            )
          })}
      </Table.Body>
    </Table>
  )
}

export default ParkingAuditLog
