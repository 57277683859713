import { get, post, put } from '../utils/api-renewed'

const rootPathSettlementRecipient = '/administration/settlementRecipients'

const fetchSettlementRecipientByRef = async (settlementRecipientRef) => {
  const result = await get(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}`
  )
  return result
}

const fetchSettlementRecipientsByOperatorRef = async (operatorRef) => {
  const result = await get(
    `${rootPathSettlementRecipient}/operator/${operatorRef}`
  )
  return result
}

const fetchSettlementRecipientsByOperatorRefWithLiableAccount = async (
  operatorRef
) => {
  const result = await get(
    `${rootPathSettlementRecipient}/operator/${operatorRef}?includeLiableAccount=true`
  )
  return result
}

const fetchAllSettlementRecipientsByFilter = async (
  queryParams,
  operatorRealm
) => {
  if (operatorRealm) {
    return get(`/operator/${operatorRealm}/settlementRecipients`, queryParams)
  }
  const result = await get(`${rootPathSettlementRecipient}`, queryParams)
  return result
}

const fetchSettlementRecipientNames = (queryParams, operatorRealm) => {
  if (operatorRealm) {
    return get(
      `/operator/${operatorRealm}/settlementRecipients/names`,
      queryParams
    )
  }
  return get(`${rootPathSettlementRecipient}/names`, queryParams)
}

const fetchAllUltimateBeneficialOwner = async (settlementRecipientRef) => {
  const result = await get(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}/ultimateBeneficialOwner`
  )
  return result
}

const addUltimateBeneficialOwner = async (settlementRecipient, values) => {
  const result = await post(
    `${rootPathSettlementRecipient}/${settlementRecipient}/ultimateBeneficialOwner`,
    values
  )
  return result
}

const addAccountHolder = async (settlementRecipientRef) => {
  const result = await post(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}/adyenAccountHolder`
  )
  return result
}

const addAccountHolderWithLegalEntity = async (settlementRecipientRef) => {
  const result = await post(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}/createAccountHolderWithLegalEntity`
  )
  return result
}

const fetchAccountHolder = async (settlementRecipientRef) => {
  const result = await get(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}/adyenAccountHolder`
  )
  return result
}

const createOnboardingToken = async (settlementRecipientRef) => {
  const result = await post(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}/adyenAccountHolder/onboardingToken`
  )
  return result
}

const invalidateOnboardingToken = async (settlementRecipientRef) => {
  const result = await post(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}/adyenAccountHolder/onboardingToken/invalidate`
  )
  return result
}

const createSettlementRecipient = async (newSettlementRecipient) => {
  return await post(`${rootPathSettlementRecipient}`, newSettlementRecipient)
}

const updateSettlementRecipient = async (updateSettlementRecipient) => {
  return await put(`${rootPathSettlementRecipient}`, updateSettlementRecipient)
}

const createVismaMandator = async (settlementRecipientRef, mandatorData) => {
  const result = await post(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}/vismaMandator`,
    mandatorData
  )
  return result
}

const updateVismaMandator = async (settlementRecipientRef, mandatorData) => {
  const result = await put(
    `${rootPathSettlementRecipient}/${settlementRecipientRef}/vismaMandator`,
    mandatorData
  )
  return result
}

export default {
  fetchSettlementRecipientByRef,
  fetchSettlementRecipientsByOperatorRef,
  fetchSettlementRecipientsByOperatorRefWithLiableAccount,
  fetchAllSettlementRecipientsByFilter,
  fetchSettlementRecipientNames,
  fetchAllUltimateBeneficialOwner,
  addUltimateBeneficialOwner,
  addAccountHolder,
  addAccountHolderWithLegalEntity,
  fetchAccountHolder,
  createOnboardingToken,
  invalidateOnboardingToken,
  createSettlementRecipient,
  updateSettlementRecipient,
  createVismaMandator,
  updateVismaMandator
}
