import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import {
  Breadcrumb,
  Button,
  Dimmer,
  Form,
  Grid,
  Icon,
  Input,
  Loader,
  Message,
  Modal,
  Segment,
  Table
} from 'semantic-ui-react'

import GenericMessage, {
  createErrorMessage,
  createInfoMessage
} from '../../components/GenericMessage'
import { LocalizedSubscriptionPricingProfileItemType } from '../../components/MoovyLocalizedEnum'
import useAuthInfo from '../../hooks/useAuthInfo'
import useServiceAgreement from '../../hooks/useServiceAgreement'
import userGroupService from '../../services/UserGroups'
import { ROLE_OPERATOR_USERGROUP_MANAGEMENT } from '../../services/utils'
import { subscriptionPricingProfileItemTypes } from '../../services/utils/DTOEnums'

const ProfileItemModal = ({ profileItem, onClose, onSubmit }) => {
  if (!profileItem) {
    return null
  }

  const intl = useIntl()
  const [state, setState] = useState({
    percentDiscount: profileItem.percentDiscount || 0.0,
    capacity: profileItem.capacity || '',
    itemType: profileItem.itemType || 'CONSTANT_PERCENTAGE_DISCOUNT'
  })
  const discountPercentNotValid =
    state.percentDiscount > 100 || state.percentDiscount < 0
  const onChange = (e, { name, value }) => setState({ ...state, [name]: value })

  return (
    <Modal onClose={onClose} open>
      <Modal.Content>
        <Form
          onSubmit={(event) => {
            event.preventDefault()
            onSubmit(state)
          }}
        >
          <Form.Input
            name="capacity"
            label={intl.formatMessage({
              id: 'editServiceAgreement.form.capacity'
            })}
            type="number"
            input={{ min: 0 }}
            value={state.capacity}
            onChange={onChange}
          />
          <Form.Input
            name="percentDiscount"
            label={intl.formatMessage({
              id: 'editServiceAgreement.form.percentDiscount'
            })}
            type="number"
            step="0.1"
            input={{ min: 0, max: 100.0 }}
            value={state.percentDiscount}
            error={
              discountPercentNotValid
                ? {
                    content: intl.formatMessage({
                      id: 'editServiceAgreement.form.percentDiscount.notValid.message'
                    }),
                    pointing: 'below'
                  }
                : null
            }
            onChange={onChange}
          />
          <Form.Dropdown
            fluid
            value={state.itemType}
            name="itemType"
            onChange={onChange}
            options={[
              {
                key: subscriptionPricingProfileItemTypes.CONSTANT_PERCENTAGE_DISCOUNT,
                text: (
                  <LocalizedSubscriptionPricingProfileItemType
                    value={
                      subscriptionPricingProfileItemTypes.CONSTANT_PERCENTAGE_DISCOUNT
                    }
                  />
                ),

                value:
                  subscriptionPricingProfileItemTypes.CONSTANT_PERCENTAGE_DISCOUNT
              },
              {
                key: subscriptionPricingProfileItemTypes.MASS_PERCENTAGE_DISCOUNT,
                text: (
                  <LocalizedSubscriptionPricingProfileItemType
                    value={
                      subscriptionPricingProfileItemTypes.MASS_PERCENTAGE_DISCOUNT
                    }
                  />
                ),
                value:
                  subscriptionPricingProfileItemTypes.MASS_PERCENTAGE_DISCOUNT
              }
            ]}
            search
            selection
            scrolling
            noResultsMessage={intl.formatMessage({
              id: 'common.noResultsMessage'
            })}
            selectOnBlur={false}
          />
          <Form.Button
            primary
            disabled={discountPercentNotValid}
            content={intl.formatMessage({
              id: 'common.button.save'
            })}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const EditServiceAgreement = ({ onUpdate }) => {
  const [editingItem, setEditingItem] = useState()
  const [maxCount, setMaxCount] = useState('')
  const [profileItems, setProfileItems] = useState([])
  const [unsavedChanges, setUnsavedChanges] = useState()
  const [message, setMessage] = useState()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const params = useParams()
  const intl = useIntl()

  const { isSuperOperator, operatorRealm, roles } = useAuthInfo()
  const serviceAgreementQuery = useServiceAgreement(params.id, operatorRealm)
  const serviceAgreement = serviceAgreementQuery.data

  useEffect(() => {
    if (serviceAgreement) {
      setMaxCount(serviceAgreement.maxCount || '')
      setProfileItems(
        !serviceAgreement.profileItems
          ? []
          : serviceAgreement.profileItems.map((item, index) => ({
              ...item,
              id: index
            }))
      )
    }
  }, [serviceAgreement])

  const allowEdit =
    isSuperOperator || roles.includes(ROLE_OPERATOR_USERGROUP_MANAGEMENT)

  const updateProfileItem = (item) => {
    const existingItem = profileItems.find((i) => i.id === item.id)
    setProfileItems(
      existingItem
        ? profileItems.map((i) => (i.id === editingItem.id ? { ...item } : i))
        : profileItems.concat(item)
    )
    setUnsavedChanges(true)
  }

  const updateServiceAgreementPricingProfile = async () => {
    try {
      setIsSubmitting(true)
      await userGroupService.setServiceAgreementPricingProfile(
        {
          maxCount: maxCount ? Number.parseInt(maxCount, 10) : null,
          profileItems
        },
        serviceAgreement.ref,
        operatorRealm
      )
      setMessage(
        createInfoMessage(
          intl.formatMessage({ id: 'editServiceAgreement.submit.success' })
        )
      )
      setUnsavedChanges()
      if (onUpdate) {
        onUpdate()
      }
    } catch (e) {
      if (e.statusCode === 400) {
        setMessage(
          createErrorMessage(
            intl.formatMessage({ id: 'editServiceAgreement.submit.invalid' })
          )
        )
      } else {
        setMessage(
          createErrorMessage(
            intl.formatMessage({ id: 'editServiceAgreement.submit.error' })
          )
        )
      }
    }
    setIsSubmitting(false)
  }

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Link to={`../`}>
            <FormattedMessage id="editServiceAgreement.allServiceAgreements" />
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider />
        <Breadcrumb.Section>
          {serviceAgreement ? serviceAgreement.service.name : ''}
        </Breadcrumb.Section>
      </Breadcrumb>

      {serviceAgreementQuery.status === 'loading' ? (
        <Segment basic>
          <Dimmer active inverted>
            <Loader />
          </Dimmer>
        </Segment>
      ) : (
        <>
          <Grid stackable padded>
            <Grid.Row>
              <Grid.Column width={9}>
                {serviceAgreementQuery.status === 'error' && (
                  <Message error>
                    <FormattedMessage id="editServiceAgreement.query.error" />
                  </Message>
                )}
                <GenericMessage
                  message={message}
                  onDismiss={() => setMessage()}
                />
                {unsavedChanges && (
                  <Message warning icon>
                    <Icon name="exclamation" />
                    <Message.Content>
                      <FormattedMessage id="editServiceAgreement.unsavedChanges" />
                    </Message.Content>
                  </Message>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={5}>
                <Form onSubmit={updateServiceAgreementPricingProfile}>
                  <Form.Field
                    id="maxCount"
                    control={Input}
                    label={intl.formatMessage({
                      id: 'editServiceAgreement.form.maxCount'
                    })}
                    placeholder={intl.formatMessage({
                      id: 'editServiceAgreement.form.maxCount.placeholder'
                    })}
                    type="number"
                    input={{ min: 0 }}
                    value={maxCount}
                    onChange={(e) => setMaxCount(e.target.value)}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={10}>
                <p style={{ fontWeight: 'bold' }}>Alennukset</p>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>
                        <FormattedMessage id="editServiceAgreement.table.header.capacity" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <FormattedMessage id="editServiceAgreement.table.header.percentDiscount" />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <FormattedMessage id="editServiceAgreement.table.header.type" />
                      </Table.HeaderCell>
                      <Table.HeaderCell width="1" />
                      <Table.HeaderCell width="1" />
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {profileItems.map((item) => (
                      <Table.Row key={item.id}>
                        <Table.Cell>
                          {item.capacity
                            ? item.capacity
                            : intl.formatMessage({
                                id: 'editServiceAgreement.table.body.capacity.undefined'
                              })}
                        </Table.Cell>
                        <Table.Cell>{item.percentDiscount} %</Table.Cell>
                        <Table.Cell>
                          <FormattedMessage
                            id={`subscriptionPricingProfile.itemTypes.${item.itemType}`}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {allowEdit ? (
                            <Button
                              onClick={() => {
                                setEditingItem(item)
                              }}
                            >
                              <Icon name="pencil" className="buttonIcon" />
                              <FormattedMessage id="common.button.modify" />
                            </Button>
                          ) : null}
                        </Table.Cell>
                        <Table.Cell>
                          {allowEdit ? (
                            <Button
                              onClick={() => {
                                setProfileItems(
                                  profileItems.filter((i) => i.id !== item.id)
                                )
                                setUnsavedChanges(true)
                              }}
                            >
                              <Icon name="delete" className="buttonIcon" />
                              <FormattedMessage id="common.button.delete" />
                            </Button>
                          ) : null}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                  <Table.Footer fullWidth>
                    <Table.Row>
                      <Table.HeaderCell colSpan="5" textAlign="right">
                        {allowEdit ? (
                          <Button
                            size="small"
                            onClick={() => {
                              setEditingItem({ id: new Date() })
                            }}
                            disabled={!serviceAgreement}
                          >
                            <Icon name="plus" className="buttonIcon" />
                            <FormattedMessage id="editServiceAgreement.table.footer.new.discount" />
                          </Button>
                        ) : null}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Button
                  primary
                  onClick={updateServiceAgreementPricingProfile}
                  style={{ display: 'block', marginTop: '1em' }}
                  disabled={!serviceAgreement || isSubmitting}
                >
                  <FormattedMessage id="common.button.save" />
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          {editingItem && (
            <ProfileItemModal
              profileItem={editingItem}
              onClose={() => setEditingItem(null)}
              onSubmit={(values) => {
                const update = { id: editingItem.id, ...values }

                if (update.capacity === '') {
                  delete update.capacity
                }
                updateProfileItem(update)
                setEditingItem(null)
              }}
            />
          )}
        </>
      )}
    </>
  )
}

export default EditServiceAgreement
