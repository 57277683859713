import Keycloak from 'keycloak-js'

const initialize = async () => {
  const keycloak = Keycloak({
    url: process.env.REACT_APP_AUTH_URL,
    realm: localStorage.getItem('moovy-admin-realm') || 'operators',
    clientId: process.env.REACT_APP_AUTH_CLIENT_ID
  })
  await keycloak.init({
    promiseType: 'native',
    checkLoginIframe: false,
    onLoad: 'check-sso'
  })
  return keycloak
}
export default {
  initialize
}
