import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../../components'
import React from 'react'

const validationSchema = () => {
  return yup.object().shape({ doorCode: yup.string().max(10) })
}

const ModalEditDoorCode = ({ onClose, location, onSubmit, mutation }) => {
  const intl = useIntl()

  const initialFormikData = (location) => {
    const { doorCode } = location
    return {
      doorCode
    }
  }

  const hasValueChanged = (doorCode) => {
    return location.doorCode !== doorCode
  }

  const handleFormitSubmit = (values) => {
    const { doorCode } = values

    if (hasValueChanged(doorCode)) {
      onSubmit({
        locationRef: location.ref,
        doorCode
      })
    } else {
      onClose()
    }
  }

  return (
    <Formik
      initialValues={initialFormikData(location)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'modalEditDoorCode.title'
              },
              {
                name: location.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="modalEditDoorCode.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'modalEditDoorCode.label.doorCode'
                })}
                value={values.doorCode}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                maxLength={10}
                name="doorCode"
                error={!!errors.doorCode}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={mutation.isPending || !hasValueChanged(values.doorCode)}
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditDoorCode
