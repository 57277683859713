import { FormattedMessage, useIntl } from 'react-intl'
import { ParkingTypeLabel, ServiceWithTags } from '../../../components'
import {
  formatDateAndTime,
  getFormattedLocationName,
  serviceType
} from '../../../services/utils'

import React from 'react'
import { Table } from 'semantic-ui-react'

const ParkingSequence = ({ parkings, activeRef, onRowClick }) => {
  const intl = useIntl()

  return (
    <Table selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.type" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.location" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.started" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.ended" />
          </Table.HeaderCell>

          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.parkingProduct" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {parkings.map((row) => {
          const { ref, location, startTime, endTime, type, service } = row
          return (
            <Table.Row
              key={ref}
              positive={activeRef === ref}
              onClick={() => onRowClick(ref)}
            >
              <Table.Cell style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                <ParkingTypeLabel type={type} />
              </Table.Cell>
              <Table.Cell>{getFormattedLocationName(location)}</Table.Cell>
              <Table.Cell>{formatDateAndTime(startTime)}</Table.Cell>
              <Table.Cell>{formatDateAndTime(endTime)}</Table.Cell>
              <Table.Cell>
                {service?.type == serviceType.SUBSCRIPTION
                  ? intl.formatMessage(
                      {
                        id: 'parkingSequence.table.column.service.subscription'
                      },
                      { name: service.name }
                    )
                  : intl.formatMessage({
                      id: 'parkingSequence.table.column.service.payg'
                    })}
                <ServiceWithTags parkingService={service} />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default ParkingSequence
