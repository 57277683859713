import './styles.scss'

import { Button } from 'semantic-ui-react'
import React from 'react'

export default function MoovyButton({ children, disabled = false, onClick }) {
  return (
    <Button className="moovy-button" onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  )
}
