import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../components'
import React from 'react'

const validationSchema = () => {
  return yup.object().shape({ doorCode: yup.string().max(10) })
}

const ModalEditVisibility = ({ onClose, service, onSubmit, mutation }) => {
  const intl = useIntl()

  const serviceVisibility = {
    PUBLIC: 'PUBLIC',
    PRIVATE: 'PRIVATE'
  }

  const initialFormikData = (service) => {
    const { publicService } = service
    return {
      visibility: publicService
        ? serviceVisibility.PUBLIC
        : serviceVisibility.PRIVATE
    }
  }

  const hasValueChanged = (visibility) => {
    const publicService = visibility === serviceVisibility.PUBLIC
    return service.publicService !== publicService
  }

  const handleFormitSubmit = ({ visibility }) => {
    if (hasValueChanged(visibility)) {
      onSubmit({
        serviceRef: service.ref,
        visibility
      })
    } else {
      onClose()
      return
    }
  }

  return (
    <Formik
      initialValues={initialFormikData(service)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, setFieldValue, handleSubmit }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'modalEditVisibility.title'
              },
              {
                name: service.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="modalEditVisibility.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Field>
                <label>
                  {intl.formatMessage({
                    id: 'modalEditVisibility.label.visibility'
                  })}
                </label>
              </Form.Field>
              <Form.Radio
                label={intl.formatMessage({
                  id: 'modalEditVisibility.visibility.PUBLIC'
                })}
                value={serviceVisibility.PUBLIC}
                checked={values.visibility === serviceVisibility.PUBLIC}
                onChange={(event, data) => {
                  setFieldValue('visibility', data.value)
                }}
              />
              <Form.Radio
                label={intl.formatMessage({
                  id: 'modalEditVisibility.visibility.PRIVATE'
                })}
                value={serviceVisibility.PRIVATE}
                checked={values.visibility === serviceVisibility.PRIVATE}
                onChange={(event, data) => {
                  setFieldValue('visibility', data.value)
                }}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={
                mutation.isPending || !hasValueChanged(values.visibility)
              }
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditVisibility
