export const ROLE_OPERATOR_USERGROUP_MANAGEMENT =
  'operator_usergroup_management'
export const ROLE_FINANCES = 'finances'
export const ROLE_MANAGEMENT = 'management'

export const ROLE_USER_ADMINISTRATION = 'user_administration'
export const ROLE_USER_MANAGEMENT = 'user_management'
export const ROLE_USER_EDIT_INVOICE = 'user_editInvoice'
export const ROLE_USER_SEND_RECEIPT = 'user_sendReceipt'
export const ROLE_USER_REMOVE_PAYMENT_CARD = 'user_remove_payment_card'
export const ROLE_USER_VEHICLE_DETAILS = 'user_vehicle_details'

export const ROLE_ENTERPRISE_USER_EDIT_INVOICING_INFO =
  'enterpriseUser_edit_invoicing_info'
export const ROLE_ENTERPRISE_USER_ADMINISTRATION =
  'enterpriseUser_administration'

export const ROLE_LOCATION_ADMINISTRATION = 'location_administration'
export const ROLE_LOCATION_MANAGEMENT = 'location_management'
export const ROLE_LOCATION_CREATE = 'location_create'
export const ROLE_LOCATION_EDIT_LOCATION_CAPACITY =
  'location_edit_location_capacity'
export const ROLE_LOCATION_EDIT_AUTO_CLEAN_PARKING =
  'location_edit_auto_clean_parking'
export const ROLE_LOCATION_VIEW_VEHICLE_ACCESS_LOGS =
  'location_view_vehicle_access_logs'

export const ROLE_SERVICE_ADMINISTRATION = 'service_administration'
export const ROLE_SERVICE_EDIT_NAME = 'service_edit_name'
export const ROLE_SERVICE_EDIT_CAPACITY = 'service_edit_capacity'
export const ROLE_SERVICE_EDIT_VISIBILITY = 'service_edit_visibility'
export const ROLE_SERVICE_EDIT_ALLOW_EXCEEDING_CAPACITY =
  'service_edit_allow_exceeding_capacity'

export const ROLE_VOUCHER_REMOVE = 'voucher_remove'

export const ROLE_WEBPAYMENT_EDIT = 'webpayment_edit'
export const ROLE_DEBT_COLLECTION_EDIT = 'debt_collection_edit'

export const ROLE_POTENTIALLY_INCORRECT_LICENSE_PLATE_VIEW =
  'potentially_incorrect_license_plate_view'
export const ROLE_POTENTIALLY_INCORRECT_LICENSE_PLATE_EDIT =
  'potentially_incorrect_license_plate_edit'

export const PermissionLocation = {
  HasAdministration: (roles) => {
    // Both superoperator and operator realm has the role at the same name
    return roles.some((role) => role === ROLE_LOCATION_ADMINISTRATION)
  },
  HasEditDoorCode: (roles) => {
    return PermissionLocation.HasAdministration(roles)
  },
  HasEditActivity: (isSuperOperator, roles) => {
    if (isSuperOperator) {
      return roles.some(
        (role) =>
          role === ROLE_LOCATION_ADMINISTRATION ||
          role === ROLE_LOCATION_MANAGEMENT
      )
    }
    return PermissionLocation.HasAdministration(roles)
  },
  HasEditGeoJson: (isSuperOperator, roles) => {
    return isSuperOperator && PermissionLocation.HasAdministration(roles)
  },
  HasEditName: (isSuperOperator, roles) => {
    return isSuperOperator && PermissionLocation.HasAdministration(roles)
  },
  HasEditLocationCard: (isSuperOperator, roles) => {
    return isSuperOperator && PermissionLocation.HasAdministration(roles)
  },
  HasCreateData: (isSuperOperator, roles) => {
    return (
      isSuperOperator && roles.some((role) => role === ROLE_LOCATION_CREATE)
    )
  },
  HasEditData: (isSuperOperator, roles) => {
    if (isSuperOperator) {
      return roles.some(
        (role) =>
          role === ROLE_LOCATION_ADMINISTRATION ||
          role === ROLE_LOCATION_MANAGEMENT
      )
    }
    return PermissionLocation.HasAdministration(roles)
  },
  HasEditLocationCapacity: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_LOCATION_EDIT_LOCATION_CAPACITY)
    )
  },
  HasEditLocationAutoCleanParking: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_LOCATION_EDIT_AUTO_CLEAN_PARKING)
    )
  },
  HasViewVehicleAccessLogs: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_LOCATION_VIEW_VEHICLE_ACCESS_LOGS)
    )
  },
  HasEditLocationSercicePriority: (isSuperOperator, roles) => {
    return isSuperOperator && PermissionLocation.HasAdministration(roles)
  }
}

export const PermissionUser = {
  HasEditInvoice: (isSuperOperator, roles) => {
    return (
      isSuperOperator && roles.some((role) => role === ROLE_USER_EDIT_INVOICE)
    )
  },
  HasSendReceipt: (isSuperOperator, roles) => {
    return (
      isSuperOperator && roles.some((role) => role === ROLE_USER_SEND_RECEIPT)
    )
  },
  HasRemovePaymentCard: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_USER_REMOVE_PAYMENT_CARD)
    )
  },
  HasVehicleDetails: (roles) => {
    return roles.some((role) => role === ROLE_USER_VEHICLE_DETAILS)
  }
}

export const PermissionEnterpriseUser = {
  HasEditInvoiceInfo: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_ENTERPRISE_USER_EDIT_INVOICING_INFO)
    )
  },
  HasAdministration: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_ENTERPRISE_USER_ADMINISTRATION)
    )
  }
}

export const PermissionService = {
  HasEditName: (isSuperOperator, roles) => {
    return (
      isSuperOperator && roles.some((role) => role === ROLE_SERVICE_EDIT_NAME)
    )
  },
  HasEditVisibility: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_SERVICE_EDIT_VISIBILITY)
    )
  },
  HasEditOverbooking: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_SERVICE_ADMINISTRATION)
    )
  },
  HasEditCapacities: (isSuperOperator, roles) => {
    if (isSuperOperator) {
      return roles.some((role) => role === ROLE_SERVICE_EDIT_CAPACITY)
    } else {
      return roles.some((role) => role === ROLE_SERVICE_ADMINISTRATION)
    }
  },
  HasEditPricing: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_SERVICE_ADMINISTRATION)
    )
  },
  HasEditLocation: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_SERVICE_ADMINISTRATION)
    )
  },
  HasEditDescription: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_SERVICE_ADMINISTRATION)
    )
  },
  HasEditValidityTimes: (isSuperOperator, roles) => {
    return (
      isSuperOperator &&
      roles.some((role) => role === ROLE_SERVICE_ADMINISTRATION)
    )
  },
  HasEditAllowExceedingCapacity: (roles) => {
    return roles.some(
      (role) => role === ROLE_SERVICE_EDIT_ALLOW_EXCEEDING_CAPACITY
    )
  }
}

export const PermissionVoucher = {
  HasDeleteVoucher: (isSuperOperator, roles) => {
    return isSuperOperator && roles.some((role) => role === ROLE_VOUCHER_REMOVE)
  }
}

export const PermissionWebPayment = {
  HasWebPaymentEdit: (roles) => {
    return roles.some((role) => role === ROLE_WEBPAYMENT_EDIT)
  },
  HasDebtCollectionEdit: (roles) => {
    return roles.some((role) => role === ROLE_DEBT_COLLECTION_EDIT)
  }
}

export const PermissionLicensePlatePotentiallyIncorrect = {
  HasView: (roles) => {
    return roles.some(
      (role) => role === ROLE_POTENTIALLY_INCORRECT_LICENSE_PLATE_VIEW
    )
  },
  HasEdit: (roles) => {
    return roles.some(
      (role) => role === ROLE_POTENTIALLY_INCORRECT_LICENSE_PLATE_EDIT
    )
  }
}
