import React from 'react'

const BlocklistCandidatesToolbar = () => {
  return (
    <div>
      <div className="toolbar-filters"></div>
      <div />
    </div>
  )
}

export default BlocklistCandidatesToolbar
