import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Button, Confirm, Icon, Table } from 'semantic-ui-react'

import { MoovyLink, TablePagination } from '../../../../components'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import messageCenterService from '../../../../services/MessageCenter'
import {
  formatDateAndTime,
  getSortDirection,
  sortColumn
} from '../../../../services/utils'

const TargetTable = ({
  targets,
  sorting,
  activePage,
  onSort,
  onActivePageChanged,
  onAddTargetGroupClicked,
  onDuplicateTarget,
  refreshTable
}) => {
  const intl = useIntl()
  const [confirmOpen, setConfirmOpen] = useState(null)

  const { mutate: removeTarget } = useMutation({
    mutationFn: (target) => messageCenterService.deleteTarget(target.ref),
    onSuccess: (result, variables) => {
      showLocalizedMoovyToast(intl, {
        title:
          'messageCenter.targetGroup.selector.action.remove.success.toast.title',
        description:
          'messageCenter.targetGroup.selector.action.remove.success.toast.body',
        values: {
          name: variables.name
        }
      })
      setConfirmOpen(null)
      refreshTable()
    },
    onError: () => {
      showLocalizedMoovyToast(intl, {
        title:
          'messageCenter.targetGroup.selector.action.remove.error.toast.title',
        description:
          'messageCenter.targetGroup.selector.action.remove.error.toast.body',
        type: 'error'
      })
    }
  })

  return (
    <>
      <Table sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('sortByName', sorting)}
              onClick={() => sortColumn('sortByName', sorting, onSort)}
            >
              <FormattedMessage id="messageCenter.targetGroup.selector.targetTable.title.groupName" />
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan="2"
              sorted={getSortDirection('sortByCreationTime', sorting)}
              onClick={() => sortColumn('sortByCreationTime', sorting, onSort)}
            >
              <FormattedMessage id="messageCenter.targetGroup.selector.targetTable.title.created" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {targets && targets.data && (
          <>
            <Table.Body>
              {targets.data.map((target) => {
                return (
                  <Table.Row key={target.ref}>
                    <Table.Cell collapsing>
                      {(target.selected && (
                        <b style={{ margin: '5px', padding: '5px' }}>
                          <FormattedMessage id="messageCenter.targetGroup.selector.targetTable.link.selected" />
                        </b>
                      )) || (
                        <MoovyLink
                          bold
                          onClick={() => onAddTargetGroupClicked(target.ref)}
                        >
                          <FormattedMessage id="messageCenter.targetGroup.selector.targetTable.link.select" />
                        </MoovyLink>
                      )}
                    </Table.Cell>
                    <Table.Cell>{target.name}</Table.Cell>
                    <Table.Cell>
                      {formatDateAndTime(target.creationDate)}
                    </Table.Cell>
                    <Table.Cell textAlign="right">
                      <MoovyLink bold onClick={() => onDuplicateTarget(target)}>
                        <FormattedMessage id="common.button.copy" />
                      </MoovyLink>
                      <Button basic icon onClick={() => setConfirmOpen(target)}>
                        <Icon name="trash alternate" color="red" />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
            <TablePagination
              colSpan={8}
              activePage={activePage}
              totalPages={targets.totalPages || 0}
              onPageChange={(e, { activePage: page }) =>
                onActivePageChanged(page)
              }
            />
          </>
        )}
      </Table>
      <Confirm
        open={confirmOpen ? true : false}
        onCancel={() => setConfirmOpen(null)}
        onConfirm={() => removeTarget(confirmOpen)}
        content={intl.formatMessage({
          id: 'messageCenter.targetGroup.selector.targetTable.confirm.remove'
        })}
        cancelButton={intl.formatMessage({
          id: 'common.button.cancel'
        })}
        confirmButton={intl.formatMessage({
          id: 'common.button.confirm'
        })}
      />
    </>
  )
}

export default TargetTable
