import {
  CompensationInvoiceColumnInfo,
  MoovyTable,
  TablePagination
} from '../../components'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  formatDate,
  formatPrice,
  getSortDirection,
  getStringValue,
  sale,
  sortColumn
} from '../../services/utils'

import { LocalizedInvoicingStatus } from '../../components/MoovyLocalizedEnum'
import React from 'react'
import { Table } from 'semantic-ui-react'

const InvoiceTable = ({
  invoicesQuery,
  activePage,
  sorting,
  onSort,
  setActivePage,
  onClickedInvoice
}) => {
  const intl = useIntl()
  return (
    <>
      <MoovyTable
        compact="very"
        celled
        items={invoicesQuery?.data?.data || []}
        query={invoicesQuery}
        selectable
        sortable
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={getSortDirection('sortByInvoiceNumber', sorting)}
              onClick={() => sortColumn('sortByInvoiceNumber', sorting, onSort)}
            >
              <FormattedMessage id="invoiceTable.header.invoiceNumber" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('sortByCreationTime', sorting)}
              onClick={() => sortColumn('sortByCreationTime', sorting, onSort)}
            >
              <FormattedMessage id="invoiceTable.header.creationTime" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('sortByStatus', sorting)}
              onClick={() => sortColumn('sortByStatus', sorting, onSort)}
            >
              <FormattedMessage id="invoiceTable.header.status" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="invoiceTable.header.invoiceCount" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="invoiceTable.header.totalPrice" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="invoiceTable.header.paymentMethod" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="invoiceTable.header.additionalInfo" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoicesQuery?.data?.data?.map((row) => (
            <Table.Row
              key={row.ref}
              negative={!!row.compensatedInvoice || row.totalPrice < 0}
              onClick={() => {
                onClickedInvoice(row)
              }}
            >
              <Table.Cell>{row.invoiceNumber}</Table.Cell>
              <Table.Cell>{formatDate(row.creationTime)}</Table.Cell>
              <Table.Cell>
                <LocalizedInvoicingStatus
                  value={row.status}
                  paymentMethodType={row.paymentMethodType}
                />
              </Table.Cell>
              <Table.Cell>{row.rowCount}</Table.Cell>
              <Table.Cell>{formatPrice(row.totalPrice)}</Table.Cell>
              <Table.Cell>
                <FormattedMessage
                  id={getStringValue(
                    sale.paymentMethodTypes.localizationKeys,
                    row.paymentMethodType
                  )}
                  defaultMessage={row.paymentMethodType}
                />
              </Table.Cell>
              <Table.Cell>
                <CompensationInvoiceColumnInfo invoice={row} />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TablePagination
          colSpan={7}
          activePage={activePage}
          totalPages={invoicesQuery?.data?.totalPages || 0}
          onPageChange={(e, { activePage: newPage }) => {
            setActivePage(newPage)
          }}
        />
      </MoovyTable>
    </>
  )
}

export default InvoiceTable
