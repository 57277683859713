import { Icon, Popup } from 'semantic-ui-react'

import React from 'react'

const MoovyIconButton = ({
  padding,
  iconName,
  content,
  operation,
  onClick
}) => {
  return (
    <Popup
      trigger={
        <Icon
          loading={!operation ? false : operation.isFetching}
          style={{
            marginTop:
              operation?.isLoading || operation?.isFetching ? '1px' : '0px',
            paddingLeft: padding?.left || 0,
            paddingRight: padding?.right || 0
          }}
          link
          color="blue"
          name={iconName}
          onClick={onClick}
        />
      }
      content={content}
      position="bottom right"
    />
  )
}

export default MoovyIconButton
