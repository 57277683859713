import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { Button, Form, Icon, Input } from 'semantic-ui-react'

import MoovyLink from '../MoovyLink'

export const TariffTimeRangeForm = ({ onChangeParkingRange }) => {
  // Backend wants datetime in this specific format
  const getBackendDateTime = (newDateTime) => {
    const dateBackendFormat = 'yyyy-MM-dd'
    const timeBackendFormat = 'HH:mm'
    const dateTimeStringForBackend = `${newDateTime.toFormat(
      dateBackendFormat
    )}T${newDateTime.toFormat(timeBackendFormat)}`
    return dateTimeStringForBackend
  }

  const getDateTimeAtMidnight = () => {
    return DateTime.local(
      DateTime.local().year,
      DateTime.local().month,
      DateTime.local().day,
      0,
      0
    )
  }

  const initialTimeRange = [
    {
      item: 1,
      begin: getBackendDateTime(getDateTimeAtMidnight()),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ minutes: 15 }))
    },
    {
      item: 2,
      begin: getBackendDateTime(getDateTimeAtMidnight()),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 1 }))
    },
    {
      item: 3,
      begin: getBackendDateTime(getDateTimeAtMidnight()),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 6 }))
    },
    {
      item: 4,
      begin: getBackendDateTime(getDateTimeAtMidnight()),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ days: 1 }))
    },
    {
      item: 5,
      begin: getBackendDateTime(getDateTimeAtMidnight()),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ days: 2 }))
    },
    {
      item: 6,
      begin: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 6 })),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 8 }))
    },
    {
      item: 7,
      begin: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 6 })),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 9 }))
    },
    {
      item: 8,
      begin: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 6 })),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 10 }))
    },
    {
      item: 9,
      begin: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 10 })),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 14 }))
    },
    {
      item: 10,
      begin: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 15 })),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 16 }))
    },
    {
      item: 11,
      begin: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 15 })),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 17 }))
    },
    {
      item: 12,
      begin: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 15 })),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 18 }))
    },
    {
      item: 13,
      begin: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 15 })),
      end: getBackendDateTime(getDateTimeAtMidnight().plus({ hours: 19 }))
    }
  ]

  const [timeRangeItems, setTimeRangeItems] = useState(initialTimeRange)

  useEffect(() => {
    onChangeParkingRange(
      timeRangeItems.map((item) => ({
        begin: item.begin,
        end: item.end
      }))
    )
  }, [timeRangeItems])

  const onChange = (e, { name, value }) => {
    const begin = 'begin'
    const end = 'end'
    let itemName = ''

    if (name.includes(begin)) {
      itemName = name.substring(begin.length, name.length)
    } else if (name.includes(end)) {
      itemName = name.substring(end.length, name.length)
    }

    const currentItemIndex = timeRangeItems.findIndex(
      (item) => item.item.toString() === itemName
    )

    if (currentItemIndex === -1) {
      return
    }

    let newTimeRangeItems = [...timeRangeItems]
    if (name.includes(begin)) {
      newTimeRangeItems[currentItemIndex][begin] = value
    } else if (name.includes(end)) {
      newTimeRangeItems[currentItemIndex][end] = value
    }
    setTimeRangeItems(newTimeRangeItems)
  }

  const onAddClicked = () => {
    let newTimeRangeItem = {
      item: (timeRangeItems[timeRangeItems.length - 1] ?? { item: 0 }).item + 1,
      begin: (timeRangeItems[timeRangeItems.length - 1] ?? { begin: '' }).begin,
      end: (timeRangeItems[timeRangeItems.length - 1] ?? { end: '' }).end
    }

    setTimeRangeItems([...timeRangeItems, newTimeRangeItem])
  }

  const onRemoveClicked = (e, { name }) => {
    setTimeRangeItems(timeRangeItems.filter((item) => item.item !== name))
  }

  return (
    <>
      {timeRangeItems.map((item) => {
        return (
          <Form.Group key={'manualTimeRangeGroup' + item.item}>
            <Form.Field>
              <Input
                key={'begin' + item.item}
                label="Aloitus"
                name={'begin' + item.item}
                value={item.begin}
                onChange={onChange}
              ></Input>
            </Form.Field>
            <Form.Field>
              <Input
                key={'end' + item.item}
                label="Lopetus"
                name={'end' + item.item}
                value={item.end}
                onChange={onChange}
              ></Input>
            </Form.Field>
            <Form.Field>
              <Button
                icon
                key={'remove' + item.item}
                name={item.item}
                onClick={onRemoveClicked}
                type="button"
              >
                <Icon name="trash alternate" />
              </Button>
            </Form.Field>
          </Form.Group>
        )
      })}
      <Form.Group>
        <Form.Field>
          <MoovyLink bold onClick={() => onAddClicked()}>
            Lisää rivi
          </MoovyLink>
        </Form.Field>
      </Form.Group>
    </>
  )
}

export default TariffTimeRangeForm
