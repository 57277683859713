import { useSelector } from 'react-redux'

import { authSelectors } from '../redux/auth'

export default function() {
  const isInitialized = useSelector(authSelectors.isInitialized)
  const isAuthenticated = useSelector(authSelectors.isAuthenticated)
  const isSuperOperator = useSelector(authSelectors.isSuperOperator)
  const operatorRealm = useSelector(authSelectors.getOperatorRealm)
  const roles = useSelector(authSelectors.getRoles)
  const userName = useSelector(authSelectors.getUserName)
  const preferredUsername = useSelector(authSelectors.getPreferredUsername)
  return {
    isInitialized,
    isAuthenticated,
    isSuperOperator,
    operatorRealm,
    roles,
    userName,
    preferredUsername
  }
}
