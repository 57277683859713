import { Button, Confirm, Grid, Icon, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import ModalAddLocation from './ModalAddLocation'
import { MoovyButton } from '../../components'
import { PermissionService } from '../../services/utils'
import PropTypes from 'prop-types'
import RelativeLinkForCurrentUser from '../RelativeLinkForCurrentUser'
import servicesService from '../../services/Services'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'

const LocationTable = ({ locations, serviceNameRef, refresh }) => {
  const { isSuperOperator, roles } = useAuthInfo()
  const [addLocationModalOpen, setAddLocationModalOpen] = useState(false)
  const [removeLocationRef, setRemoveLocationRef] = useState('')
  const intl = useIntl()

  const { mutate: removeLocationLink, ...removeLocationLinkMutation } =
    useMutation({
      mutationFn: (data) =>
        servicesService.removeLocationFromService(
          serviceNameRef.ref,
          data.locationRef
        ),
      onSuccess: (response, variables) => {
        showLocalizedMoovyToast(intl, {
          title:
            'parkingServiceDetails.tab.locations.toast.title.locationRemoved',
          description:
            'parkingServiceDetails.tab.locations.toast.description.locationRemoved'
        })
        refresh()
      },
      onError: () => {
        showLocalizedMoovyToast(intl, {
          title: 'parkingServiceDetails.tab.locations.toast.error.title',
          description:
            'parkingServiceDetails.tab.locations.toast.error.description',
          type: 'error'
        })
      }
    })

  return (
    <>
      {serviceNameRef &&
        PermissionService.HasEditLocation(isSuperOperator, roles) && (
          <Grid style={{ paddingTop: '10px' }}>
            <Grid.Column floated="right" width={8} textAlign="right">
              <MoovyButton onClick={() => setAddLocationModalOpen(true)}>
                <Icon name="plus" />
                <FormattedMessage id="parkingServiceDetails.tab.locations.button.addLocation" />
              </MoovyButton>
            </Grid.Column>
          </Grid>
        )}
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="parkingServiceDetails.tab.locations.table.header.name" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingServiceDetails.tab.locations.table.header.internalName" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingServiceDetails.tab.locations.table.header.city" />
            </Table.HeaderCell>
            <Table.HeaderCell
              colSpan={
                (PermissionService.HasEditLocation(isSuperOperator, roles) &&
                  locations.length > 1 &&
                  2) ||
                1
              }
            >
              <FormattedMessage id="parkingServiceDetails.tab.locations.table.header.anonymousParking" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {locations &&
            locations.map((location) => (
              <Table.Row key={location.ref}>
                <Table.Cell>
                  <RelativeLinkForCurrentUser to={`locations/${location.ref}`}>
                    {location.name}
                  </RelativeLinkForCurrentUser>
                </Table.Cell>
                <Table.Cell>{location.internalName}</Table.Cell>
                <Table.Cell>{location.city}</Table.Cell>
                <Table.Cell>
                  {location.anonymousParkingSupported ? (
                    <FormattedMessage id="common.yes" />
                  ) : (
                    <FormattedMessage id="common.no" />
                  )}
                </Table.Cell>
                {PermissionService.HasEditLocation(isSuperOperator, roles) &&
                  locations.length > 1 && (
                    <Table.Cell textAlign="right">
                      <Button
                        disabled={removeLocationLinkMutation.isPending}
                        loading={removeLocationLinkMutation.isPending}
                        basic
                        icon
                        onClick={() => setRemoveLocationRef(location.ref)}
                      >
                        <Icon name="trash alternate" color="red" />
                      </Button>
                    </Table.Cell>
                  )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      <ModalAddLocation
        serviceNameRef={serviceNameRef}
        open={addLocationModalOpen}
        onAdded={() => {
          refresh()
          setAddLocationModalOpen(false)
        }}
        onClose={() => {
          setAddLocationModalOpen(false)
        }}
      />

      <Confirm
        size={'tiny'}
        open={!!removeLocationRef}
        content={intl.formatMessage(
          {
            id: 'parkingServiceDetails.tab.locations.table.confirm.removeLocation'
          },
          {
            locationName:
              locations.find((location) => location.ref === removeLocationRef)
                ?.name || '',
            serviceName: serviceNameRef.name
          }
        )}
        cancelButton={intl.formatMessage({ id: 'common.button.cancel' })}
        confirmButton={intl.formatMessage({ id: 'common.button.confirm' })}
        onCancel={() => setRemoveLocationRef('')}
        onConfirm={() => {
          removeLocationLink({ locationRef: removeLocationRef })
          setRemoveLocationRef('')
        }}
      />
    </>
  )
}

LocationTable.propTypes = {
  locations: PropTypes.array.isRequired
}

export default LocationTable
