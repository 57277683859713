import {
  AdyenAccountBpStatusEnum,
  getStringValue
} from '../../../../services/utils/DTOEnums'
import { Button, Header } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { MutationErrorMessage } from '../../../../components'
import React from 'react'
import { accountHolderUIStatusEnum } from './tabAdyenNew'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import { useMutation } from '@tanstack/react-query'

const AccountHolder = ({
  settlementRecipient,
  queryAccountHolder,
  accountHolder,
  accountHolderUIStatus,
  refetchAccountHolder
}) => {
  const intl = useIntl()

  const { mutate: addAccountHolder, ...addAccountHolderMutation } = useMutation(
    {
      mutationFn: () => {
        return settlementRecipientService.addAccountHolderWithLegalEntity(
          settlementRecipient?.ref
        )
      },
      onSuccess: () => {
        showLocalizedMoovyToast(intl, {
          title: 'accountHolder.toast.action.accountHolderCreated.title',
          description:
            'accountHolder.toast.action.accountHolderCreated.description'
        })
        refetchAccountHolder()
      }
    }
  )

  const RenderShowOrCreateAccountHolder = () => {
    if (accountHolderUIStatus !== accountHolderUIStatusEnum.NONE) {
      return (
        <>
          <div>
            <div>
              <b>
                <FormattedMessage id="accountHolder.label.accountHolderCode" />
              </b>
              {` ${accountHolder.accountHolderCode}`}
            </div>
            <div>
              <b>
                <FormattedMessage id="accountHolder.label.accountHolderId" />
              </b>
              {` ${accountHolder.accountHolderId ? accountHolder.accountHolderId : ''}`}
            </div>
            <div>
              <b>
                <FormattedMessage id="accountHolder.label.adyenAccountBpStatus" />
              </b>
              {` `}
              {(accountHolder.adyenAccountBpStatus && (
                <FormattedMessage
                  id={getStringValue(
                    AdyenAccountBpStatusEnum.localizationKeys,
                    accountHolder.adyenAccountBpStatus
                  )}
                />
              )) ||
                ''}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <p>
            <FormattedMessage id="accountHolder.description.accountHolder" />
          </p>
          <Button
            primary
            onClick={() => addAccountHolder()}
            disabled={addAccountHolderMutation.isPending}
            loading={addAccountHolderMutation.isPending}
          >
            <FormattedMessage id="accountHolder.button.createAndContinue" />
          </Button>
        </>
      )
    }
  }

  return (
    <>
      <Header as="h4">
        <FormattedMessage id="accountHolder.header.accountHolder" />
      </Header>
      <MutationErrorMessage
        mutation={addAccountHolderMutation}
        defaultErrorTextLangId={'accountHolder.accountHolderCreated.failed'}
        messageNegative
      />
      <MutationErrorMessage
        mutation={queryAccountHolder}
        defaultErrorTextLangId={'stepOnboardingLink.fetchAccountHolder.failed'}
        messageNegative
      />
      <RenderShowOrCreateAccountHolder />
    </>
  )
}

export default AccountHolder
