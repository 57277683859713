import { get, post } from '../utils/api-renewed'

const fetchServicePricing = async (serviceRef, operatorRealm) => {
  const url = operatorRealm
    ? `/operator/${operatorRealm}/services/${serviceRef}/pricing`
    : `/administration/services/${serviceRef}/pricing`
  try {
    const result = await get(url)
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchServicePricingByRef = async (servicePricingRef, operatorRealm) => {
  const url = operatorRealm
    ? `/operator/${operatorRealm}/servicePricing/${servicePricingRef}`
    : `/administration/servicePricing/${servicePricingRef}`
  try {
    const result = await get(url)
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const testTariffPricing = async (parkingRanges, tariffStructure) => {
  const url = `/administration/services/tariffs/test`
  try {
    const result = await post(url, { parkingRanges, tariffStructure })
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const addServicePricing = async (serviceRef, data) => {
  const url = `/administration/services/${serviceRef}/servicePricing`
  const result = await post(url, { ...data })
  return result
}

export default {
  fetchServicePricing,
  fetchServicePricingByRef,
  testTariffPricing,
  addServicePricing
}
