import * as yup from 'yup'

import {
  Checkbox,
  Confirm,
  Form,
  Header,
  Icon,
  Item,
  Popup,
  Radio,
  TextArea,
  Message,
  Divider
} from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import {
  addMissingValues,
  findChangedValues,
  isEmptyObject,
  isObject
} from './ManagementUtils'

import { Formik } from 'formik'
import GeoJSONEditor from '../../../components/GeoJSON/GeoJSONEditor'
import { MoovyHtmlPreview, MoovyIconInfo } from '../../../components'
import StepNextButton from './StepNextButton'
import locationsService from '../../../services/Locations'
import { transform } from '../../../services/utils/JSONTransformer'
import { useIntl, FormattedMessage } from 'react-intl'
import { locationEnums } from '../../../services/utils'

export const LocationForm = ({ formValues, onDataCompleted }) => {
  const intl = useIntl()

  const validationSchema = () => {
    return yup.object().shape({
      location: yup.object().shape({
        name: yup.string().required('Kohteen nimi puuttuu.'),
        city: yup.string().required('Kaupunki puuttuu.'),
        type: yup.string().required('Kohdetyyppiä ei ole määritetty.'),
        parkingCancelGracePeriodSeconds: yup
          .string()
          .required('Kohteen vapaa läpiajoaika puuttuu.'),
        locationAreas: yup.string().required('Aluemääritys (GeoJSON) puuttuu.'),
        parkingControlId: yup
          .string()
          .test(
            'parkingControlId',
            'Pysäköinninvalvontatunniste pitää syöttää.',
            function (parkingControlId) {
              const { parkingControlled } = this.parent
              return (
                !parkingControlled || (parkingControlled && parkingControlId)
              )
            }
          ),
        licensePlateRecognitionSupported: yup
          .boolean()
          .test(
            'licensePlateRecognitionSupported',
            'Rekisterikilven tunnistus pitää olla päällä puomittomalla kohteella.',
            function (licensePlateRecognitionSupported) {
              const { barrierlessFacility } = this.parent
              return (
                licensePlateRecognitionSupported ||
                (!licensePlateRecognitionSupported && !barrierlessFacility)
              )
            }
          ),
        propertiesNotAllowed: yup
          .boolean()
          .test(
            'propertiesNotAllowed',
            'Kohdetyyppi vyöhykkeellä ei sallita ominaisuuksia. Vaihda kohdetyyppiä.',
            function () {
              const {
                type,
                barrierlessFacility,
                licensePlateRecognitionSupported,
                anonymousParkingSupported
              } = this.parent
              return type !== locationEnums.locationType.ZONE
                ? true
                : !barrierlessFacility &&
                    !licensePlateRecognitionSupported &&
                    !anonymousParkingSupported
            }
          )
      })
    })
  }

  const numberFields = [
    'location.heatedParkingSpaces',
    'location.notHeatedParkingSpaces',
    'location.maximumParkingTime',
    'location.parkingCancelGracePeriodSeconds'
  ]

  const [initialValues, setInitialValues] = useState({
    location: {
      name: '',
      internalName: '',
      city: '',
      streetAddress: '',
      parkingControlId: '',
      active: false,
      anonymousParkingSupported: false,
      barrierlessFacility: false,
      licensePlateRecognitionSupported: false,
      parkingControlled: false,
      maximumParkingTime: '',
      parkingCancelGracePeriodSeconds: '',
      timezone: 'Europe/Helsinki',
      type: '',
      description: '',
      doorCode: '',
      autoCleanThresholdInDays: '',
      locationAreas: ''
    }
  })
  const [locationValues, setLocationValues] = useState(initialValues)
  const [geoJsonError, setGeoJsonError] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    if (isEmptyObject(formValues)) {
      setLocationValues(initialValues)
    } else {
      const currentValues = addMissingValues(formValues, initialValues.location)

      if (isObject(currentValues.locationAreas)) {
        try {
          currentValues.locationAreas = JSON.stringify(
            transform(currentValues.locationAreas, numberFields, false),
            null,
            2
          )
        } catch (error) {
          console.warn(error)
        }
      }

      const changedValues = findChangedValues(
        currentValues,
        locationValues.location
      )
      if (changedValues) {
        setLocationValues({ location: { ...currentValues } })
        setInitialValues({ location: { ...currentValues } })
      }
    }
  }, [formValues])

  const onCheckboxClicked = (e, { name, checked }, setFieldValue) => {
    if (name === 'location.parkingControlled') {
      updateValue('location.parkingControlId', '')
    }
    updateValue(name, checked)
    setFieldValue(name, checked)
  }

  const onChange = (
    e,
    { name, value },
    formValidationValues,
    setFormValidationValues
  ) => {
    if (name === 'location.type') {
      if (
        value === locationEnums.locationType.ZONE ||
        value === locationEnums.locationType.LOT
      ) {
        updateValue('location.anonymousParkingSupported', false)
        updateValue('location.barrierlessFacility', false)
        updateValue('location.licensePlateRecognitionSupported', false)
        updateValue('location.doorCode', '')
        updateValue('location.autoCleanThresholdInDays', '')
        let newValues = { ...formValidationValues }
        newValues.location.type = value
        newValues.location.barrierlessFacility = false
        newValues.location.licensePlateRecognitionSupported = false
        setFormValidationValues(newValues)
      }
      if (value === locationEnums.locationType.FACILITY) {
        updateValue('location.maximumParkingTime', '')
      }
    }
    updateValue(name, value)
  }

  const updateValue = (name, value) => {
    const newValues = { ...locationValues }
    const rootName = name.split('.')[0]
    const childName = name.split('.')[1]
    newValues[rootName][childName] = value
    setLocationValues({ ...newValues })
  }

  const onSubmitForm = () => {
    locationsService.fetchLocations().then(
      (data) => {
        if (data != null && data.content) {
          const theSameLocationNameExists = data.content.find((item) => {
            const nameFound =
              item.name
                .toLowerCase()
                .localeCompare(locationValues.location.name.toLowerCase()) === 0
            const cityFound =
              item.city
                .toLowerCase()
                .localeCompare(locationValues.location.city.toLowerCase()) === 0
            // Check intenal name only if it exists
            if (
              nameFound &&
              cityFound &&
              locationValues.location.internalName
            ) {
              return (
                (item.internalName &&
                  item.internalName
                    .toLowerCase()
                    .localeCompare(
                      locationValues.location.internalName.toLowerCase()
                    ) === 0) ||
                false
              )
            }
            return nameFound && cityFound
          })
          if (theSameLocationNameExists) {
            setConfirmOpen(true)
          } else {
            formFinished()
          }
        }
      },
      (error) => {
        formFinished()
      }
    )
  }

  const formFinished = () => {
    const completeValues = { ...locationValues.location }
    if (completeValues.locationAreas) {
      completeValues.locationAreas = JSON.parse(completeValues.locationAreas)
    }

    onDataCompleted({ ...completeValues }, 'location')
  }

  const getMissingConfigurationInfo = (errorObject) => {
    if (!errorObject) return []
    return Object.keys(errorObject)
      .map((key) => errorObject[key])
      .filter((item) => item)
  }

  const FormDivider = () => (
    <Divider style={{ marginTop: '25px', marginBottom: '25px' }} />
  )

  const isZone =
    locationValues.location.type === locationEnums.locationType.ZONE
  const isLot = locationValues.location.type === locationEnums.locationType.LOT

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={() => {
          onSubmitForm()
        }}
        enableReinitialize
      >
        {({
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          setValues,
          values
        }) => (
          <Form onSubmit={handleSubmit}>
            <StepNextButton />
            <Header>Kohde</Header>
            <Form.Field>
              {getMissingConfigurationInfo(errors?.location).length > 0 && (
                <Message color="blue">
                  <Icon name="warning"></Icon> Vaatii lisäkonfigurointia
                  <br />
                  <ul>
                    {getMissingConfigurationInfo(errors?.location).map(
                      (item) => (
                        <li key={item}>{item}</li>
                      )
                    )}
                  </ul>
                </Message>
              )}
            </Form.Field>
            <Form.Group>
              <Form.Field width={8}>
                <Form.Input
                  label="Nimi"
                  name="location.name"
                  type="text"
                  value={locationValues.location.name}
                  onChange={(event, data) => {
                    onChange(event, data)
                    handleChange(event, data)
                  }}
                  error={errors.location && !!errors.location.name}
                />
                <Form.Input
                  label={
                    <label>
                      <MoovyIconInfo iconLabel="Sisäinen nimi">
                        Sisäinen nimi on tarkoitettu pääasiallisesti
                        Vyöhyke-tyyppisille kohteille, koska niissä voi olla
                        samannimisiä kohdenimiä. Muissa kohdetyypeissä nimen
                        pitäisi toimia yksilöllisenä tunnisteena.
                      </MoovyIconInfo>
                    </label>
                  }
                  name="location.internalName"
                  type="text"
                  value={locationValues.location.internalName}
                  onChange={onChange}
                />
                <Form.Group widths={'equal'}>
                  <Form.Input
                    label="Osoite"
                    name="location.streetAddress"
                    type="text"
                    onChange={onChange}
                    value={locationValues.location.streetAddress}
                  />
                  <Form.Input
                    label="Kaupunki"
                    name="location.city"
                    type="text"
                    value={locationValues.location.city}
                    onChange={(event, data) => {
                      onChange(event, data)
                      handleChange(event, data)
                    }}
                    error={errors.location && !!errors.location.city}
                  />
                </Form.Group>
              </Form.Field>
              <Form.Field
                error={errors.location && !!errors.location.type}
                width={8}
              >
                <label>Kohdetyyppi</label>
                <Form.Group>
                  <Form.Field
                    control={Radio}
                    label="Vyöhyke"
                    name="location.type"
                    value={locationEnums.locationType.ZONE}
                    checked={
                      locationValues.location.type ===
                      locationEnums.locationType.ZONE
                    }
                    onChange={(event, data) =>
                      onChange(event, data, values, setValues)
                    }
                  />
                  <Popup
                    trigger={<Icon name="info" circular size="small" />}
                    content={intl.formatMessage({
                      id: 'locations.toolbar.info.type.zone'
                    })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Radio}
                    label="Laitos"
                    name="location.type"
                    value={locationEnums.locationType.FACILITY}
                    checked={
                      locationValues.location.type ===
                      locationEnums.locationType.FACILITY
                    }
                    onChange={(event, data) => {
                      onChange(event, data)
                      setFieldValue(data.name, data.value)
                    }}
                  />
                  <Popup
                    trigger={<Icon name="info" circular size="small" />}
                    content={intl.formatMessage({
                      id: 'locations.toolbar.info.type.facility'
                    })}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Field
                    control={Radio}
                    label="Alue"
                    name="location.type"
                    value={locationEnums.locationType.LOT}
                    checked={
                      locationValues.location.type ===
                      locationEnums.locationType.LOT
                    }
                    onChange={(event, data) => {
                      onChange(event, data, values, setValues)
                    }}
                  />
                  <Popup
                    trigger={<Icon name="info" circular size="small" />}
                    content={intl.formatMessage({
                      id: 'locations.toolbar.info.type.lot'
                    })}
                  />
                </Form.Group>
              </Form.Field>
            </Form.Group>
            <FormDivider />
            <Form.Group>
              <Form.Field
                width={8}
                disabled={!locationValues.location.type || isZone || isLot}
              >
                <Form.Group widths={'equal'}>
                  <Form.Field
                    error={
                      errors.location && !!errors.location.propertiesNotAllowed
                    }
                  >
                    <label>Ominaisuudet</label>
                    <Form.Field>
                      <Checkbox
                        label="Anonyymipysäköinti"
                        name="location.anonymousParkingSupported"
                        onClick={(event, data) =>
                          onCheckboxClicked(event, data, setFieldValue)
                        }
                        checked={
                          locationValues.location.anonymousParkingSupported
                        }
                      />
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        label="Puomiton kohde (verkkomaksu)"
                        name="location.barrierlessFacility"
                        onClick={(event, data) =>
                          onCheckboxClicked(event, data, setFieldValue)
                        }
                        checked={locationValues.location.barrierlessFacility}
                      />
                    </Form.Field>
                    <Form.Field
                      error={
                        errors.location &&
                        !!errors.location.licensePlateRecognitionSupported
                      }
                    >
                      <Checkbox
                        label="Rekisterikilven tunnistus"
                        name="location.licensePlateRecognitionSupported"
                        onClick={(event, data) =>
                          onCheckboxClicked(event, data, setFieldValue)
                        }
                        checked={
                          locationValues.location
                            .licensePlateRecognitionSupported
                        }
                      />
                    </Form.Field>
                  </Form.Field>
                </Form.Group>
              </Form.Field>
              <Form.Field width={4} disabled={!locationValues.location.type}>
                <Form.Input
                  label="Maksimipysäköintiaika (min)"
                  name="location.maximumParkingTime"
                  type="number"
                  min={0}
                  onChange={onChange}
                  value={locationValues.location.maximumParkingTime}
                  disabled={
                    locationValues.location.type ===
                    locationEnums.locationType.FACILITY
                  }
                />
                <Form.Input
                  label="Kohteen kokonaispaikkamäärä"
                  name="location.locationCapacity"
                  type="text"
                  onChange={onChange}
                  value={locationValues.location.locationCapacity}
                />
                <Form.Input
                  label="Ovikoodi"
                  name="location.doorCode"
                  type="string"
                  onChange={onChange}
                  value={locationValues.location.doorCode}
                  disabled={isZone || isLot}
                />
              </Form.Field>
              <Form.Field width={4} disabled={!locationValues.location.type}>
                <Form.Input
                  label="Pysäköinnin vapaa läpiajoaika (s)"
                  name="location.parkingCancelGracePeriodSeconds"
                  type="number"
                  min={0}
                  value={
                    locationValues.location.parkingCancelGracePeriodSeconds
                  }
                  onChange={(event, data) => {
                    onChange(event, data)
                    handleChange(event, data)
                  }}
                  error={
                    errors.location &&
                    !!errors.location.parkingCancelGracePeriodSeconds
                  }
                />
                <Form.Input
                  label="Aikavyöhyke"
                  name="location.timezone"
                  type="text"
                  onChange={onChange}
                  value={locationValues.location.timezone}
                  disabled={true}
                />

                <Form.Field disabled={isZone || isLot}>
                  <label>
                    Anonyymien automaattinen lopettaminen (pv)
                    <Popup
                      trigger={
                        <Icon
                          name="info"
                          circular
                          size="small"
                          style={{ marginLeft: '5px', verticalAlign: 'bottom' }}
                        />
                      }
                      content={intl.formatMessage({
                        id: 'locations.toolbar.label.automaticEnding.info'
                      })}
                    />
                  </label>
                  <Form.Input
                    name="location.autoCleanThresholdInDays"
                    type="string"
                    onChange={onChange}
                    value={locationValues.location.autoCleanThresholdInDays}
                    disabled={
                      locationValues.location.type ===
                      locationEnums.locationType.ZONE
                    }
                  />
                </Form.Field>
              </Form.Field>
            </Form.Group>
            <FormDivider />
            <Form.Group>
              <Form.Field width={8}>
                <label>Pysäköinninvalvonta</label>
                <Checkbox
                  label="Pysäköinninvalvonnan piirissä"
                  name="location.parkingControlled"
                  onClick={(event, data) =>
                    onCheckboxClicked(event, data, setFieldValue)
                  }
                  checked={locationValues.location.parkingControlled}
                />
              </Form.Field>
              <Form.Input
                label="Pysäköinninvalvontatunniste"
                name="location.parkingControlId"
                type="text"
                onChange={(event, data) => {
                  onChange(event, data)
                  handleChange(event, data)
                }}
                value={locationValues.location.parkingControlId}
                disabled={!locationValues.location.parkingControlled}
                width={4}
                error={errors.location && !!errors.location.parkingControlId}
              />
            </Form.Group>
            <FormDivider />
            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  <MoovyIconInfo iconLabel="Kuvaus HTML-muodossa">
                    <FormattedMessage id="locationDescriptionEditModal.label.description.info" />
                  </MoovyIconInfo>
                </label>
                <TextArea
                  placeholder="Kuvaus (HTML)"
                  name="location.description"
                  value={locationValues.location.description}
                  onChange={onChange}
                  rows={25}
                />
              </Form.Field>
              <Form.Field>
                <label>Kuvaus HTML-muodossa, esikatselu</label>
                <Item>
                  <Item.Content>
                    <MoovyHtmlPreview
                      html={locationValues.location.description}
                    />
                  </Item.Content>
                </Item>
              </Form.Field>
            </Form.Group>
            <GeoJSONEditor
              value={locationValues.location.locationAreas}
              onChange={(event, data) => {
                setGeoJsonError(false)
                onChange(event, data)
                handleChange(event, data)
              }}
              error={
                !!(
                  (errors.location && errors.location.locationAreas) ||
                  geoJsonError
                )
              }
              onError={(error) => {
                setGeoJsonError(error)
              }}
            />
          </Form>
        )}
      </Formik>
      <Confirm
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={() => {
          setConfirmOpen(false)
          formFinished()
        }}
        content="Samassa kaupungissa on jo kohde samalla nimellä. Haluatko varmasti jatkaa?"
        cancelButton="Peruuta"
        confirmButton="Kyllä"
      />
    </>
  )
}

export default LocationForm
