import React from 'react'
import { Form, Icon } from 'semantic-ui-react'

export const StepNextButton = ({ name }) => {
  return (
    <Form.Group widths="equal">
      <Form.Field></Form.Field>
      <Form.Field>
        <Form.Button
          type="submit"
          floated="right"
          icon
          primary
          labelPosition="right"
        >
          <Icon name="right arrow" />
          {name ?? 'Tallenna ja jatka'}
        </Form.Button>
      </Form.Field>
    </Form.Group>
  )
}

export default StepNextButton
