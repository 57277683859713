import { Button, Confirm, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

import { MutationErrorMessage } from '..'
import recognitionService from '../../services/utils/license-plate-recognition'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const EditLicensePlateModal = ({
  open,
  licensePlateData,
  onChangeLicensePlateData,
  onSubmit,
  onClose,
  mutation
}) => {
  const intl = useIntl()
  const { operatorRealm } = useAuthInfo()
  const queryClient = useQueryClient()

  const [confirmationMoovyAccount, setConfirmationMoovyAccount] =
    useState(false)

  const vehiclesQuery = useQuery({
    queryKey: ['licensePlateRecognition'],
    queryFn: () =>
      recognitionService.queryByLicensePlate(
        licensePlateData.licensePlate,
        operatorRealm
      ),
    enabled: false
  })

  useEffect(() => {
    if (vehiclesQuery.isSuccess) {
      if (vehiclesQuery.data?.length > 0) {
        setConfirmationMoovyAccount(true)
      } else {
        onSubmit()
      }
    }
  }, [vehiclesQuery.isSuccess])

  useEffect(() => {
    if (vehiclesQuery.isError) {
      onSubmit()
    }
  }, [vehiclesQuery.isError])
  const onSubmitEditLicensePlate = () => {
    queryClient.removeQueries({ queryKey: ['licensePlateRecognition'] })
    vehiclesQuery.refetch()
  }

  return (
    <>
      <Modal onClose={onClose} open={open} size={'tiny'}>
        <Modal.Header>
          <FormattedMessage id="parkingTable.editLicensePlate.modal.header" />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="parkingTable.editLicensePlate.modal.error.editing"
              messageNegative
            />
            <Form onSubmit={onSubmitEditLicensePlate}>
              <Form.Input
                label={intl.formatMessage({
                  id: 'parkingTable.editLicensePlate.modal.licensePlate.label'
                })}
                value={
                  (licensePlateData && licensePlateData.licensePlate) || ''
                }
                onChange={(event, data) =>
                  onChangeLicensePlateData({
                    ...licensePlateData,
                    licensePlate: data.value
                  })
                }
              />
              <Form.Field>
                <label>
                  <FormattedMessage id="parkingTable.editLicensePlate.modal.comment.label" />
                </label>
                <Form.TextArea
                  value={(licensePlateData && licensePlateData.comment) || ''}
                  onChange={(e, { value }) =>
                    onChangeLicensePlateData({
                      ...licensePlateData,
                      comment: value
                    })
                  }
                  rows={5}
                />
              </Form.Field>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button
            primary
            onClick={onSubmitEditLicensePlate}
            loading={mutation.isPending}
            disabled={mutation.isPending}
          >
            <FormattedMessage id="common.button.change" />
          </Button>
        </Modal.Actions>
        <Confirm
          open={confirmationMoovyAccount}
          size="tiny"
          content={intl.formatMessage({
            id: 'parkingTable.confirm.moovyParking'
          })}
          cancelButton={intl.formatMessage({
            id: 'common.button.cancel'
          })}
          confirmButton={intl.formatMessage({
            id: 'common.button.confirm'
          })}
          onCancel={() => setConfirmationMoovyAccount(false)}
          onConfirm={() => {
            onSubmit()
            setConfirmationMoovyAccount(false)
          }}
        />
      </Modal>
    </>
  )
}

export default EditLicensePlateModal
