import { Button, Table } from 'semantic-ui-react'
import {
  getStringValue,
  vismaRoleEnum as vismaRole
} from '../../../../services/utils/DTOEnums'

import { FormattedMessage } from 'react-intl'
import { MoovyTable } from '../../../../components'
import React from 'react'

const TableMandator = ({ vismaMandators, onEditMandator, query }) => {
  const VismaMandatorActive = ({ mandatorActive }) => {
    return mandatorActive ? (
      <FormattedMessage id="settlementRecipients.settlementRecipientTable.vismaMandatorsActive" />
    ) : (
      <FormattedMessage id="settlementRecipients.settlementRecipientTable.vismaMandatorsInActive" />
    )
  }

  return (
    <>
      <MoovyTable items={vismaMandators} query={query}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="tableMandator.header.bankAccountIban" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="tableMandator.header.bankAccountBic" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="tableMandator.header.customerSupportPhone" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="tableMandator.header.customerSupportEmail" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="tableMandator.header.mandatorRole" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="tableMandator.header.vismaStatus" />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {vismaMandators &&
            vismaMandators.map((mandator) => (
              <Table.Row key={mandator.role}>
                <Table.Cell>{mandator.bankAccountIban}</Table.Cell>
                <Table.Cell>{mandator.bankAccountBic}</Table.Cell>
                <Table.Cell>
                  {mandator.customerSupportPhone ? (
                    <a href={'tel:' + mandator.customerSupportPhone}>
                      {mandator.customerSupportPhone}
                    </a>
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell>
                  {mandator.customerSupportEmail ? (
                    <a href={'mailto:' + mandator.customerSupportEmail}>
                      {mandator.customerSupportEmail}
                    </a>
                  ) : (
                    ''
                  )}
                </Table.Cell>
                <Table.Cell>
                  <FormattedMessage
                    id={getStringValue(
                      vismaRole.localizationKeys,
                      mandator.role
                    )}
                    defaultMessage={mandator.role}
                  />
                </Table.Cell>
                <Table.Cell>
                  <VismaMandatorActive
                    mandatorActive={mandator.mandatorActive}
                  />
                </Table.Cell>
                <Table.Cell collapsing>
                  <Button primary onClick={() => onEditMandator(mandator)}>
                    <FormattedMessage id="common.button.modify" />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </MoovyTable>
    </>
  )
}

export default TableMandator
