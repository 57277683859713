import { Button, Form, Message, Modal } from 'semantic-ui-react'
import React, { useState } from 'react'

import { validateEmail } from '../../services/utils'

function SendEmailReceipt({ onClose, onSubmit, customerEmail }) {
  const [email, setEmail] = useState(customerEmail)
  const [error, setError] = useState(false)

  return (
    <Modal onClose={onClose} open closeIcon size="small">
      <Modal.Header>Lähetä kuitti laskusta sähköpostilla</Modal.Header>
      <Modal.Content>
        {error && (
          <Message
            error
            header="Tarkista sähköpostiosoite"
            content={`Sähköpostiosoite '${email}' ei ole kelvollinen sähköpostiosoite`}
          />
        )}
        <Form
          onSubmit={(e) => {
            e.preventDefault()
            if (validateEmail(email)) {
              onSubmit(email)
            } else {
              setError(true)
            }
          }}
        >
          <Form.Field>
            <label>Sähköpostiosoite</label>
            <input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
                setError(false)
              }}
            />
          </Form.Field>
          <Button type="submit" disabled={error} primary>
            Lähetä
          </Button>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

export default SendEmailReceipt
