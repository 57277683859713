import { Button, Grid, GridColumn, Header, Input } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyLink, MutationErrorMessage } from '../../../../components'

import React from 'react'
import { copyToClipboard } from '../../../../services/utils/clipboard'
import { formatDateAndTime } from '../../../../services/utils'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import { useMutation } from '@tanstack/react-query'

const StepOnboardingLink = ({
  settlementRecipient,
  accountHolder,
  refetchAccountHolder,
  queryAccountHolder,
  onNextClick,
  nextDisabled
}) => {
  const intl = useIntl()

  const { mutate: createOnboardingToken, ...createOnboardingTokenMutation } =
    useMutation({
      mutationFn: () => {
        return settlementRecipientService.createOnboardingToken(
          settlementRecipient?.ref
        )
      },
      onSuccess: () => {
        showLocalizedMoovyToast(intl, {
          title: 'stepOnboardingLink.toast.action.onboardingTokenCreate.title',
          description:
            'stepOnboardingLink.toast.action.onboardingTokenCreate.description'
        })
        onNextClick()
        refetchAccountHolder()
      }
    })

  const {
    mutate: invalidateOnboardingToken,
    ...invalidateOnboardingTokenMutation
  } = useMutation({
    mutationFn: () => {
      return settlementRecipientService.invalidateOnboardingToken(
        settlementRecipient?.ref
      )
    },
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title:
          'stepOnboardingLink.toast.action.onboardingTokenInvalidate.title',
        description:
          'stepOnboardingLink.toast.action.onboardingTokenInvalidate.description'
      })
      refetchAccountHolder()
    }
  })

  const RenderShowLinkOrCreateLink = () => {
    if (accountHolder?.onboardingToken) {
      return (
        <>
          <p>
            <FormattedMessage id="stepOnboardingLink.toast.action.onboardingToken.valid" />
            {` ${formatDateAndTime(
              accountHolder?.onboardingToken?.validUntil
            )}`}
          </p>
          <Input
            action={{
              color: 'blue',
              labelPosition: 'right',
              icon: 'copy',
              content: intl.formatMessage({
                id: 'stepOnboardingLink.button.copyLink'
              }),
              onClick: () =>
                copyToClipboard(
                  intl,
                  accountHolder?.onboardingToken?.onboardingURL,
                  intl.formatMessage({
                    id: 'stepOnboardingLink.toast.copiedToClipboard.text'
                  })
                )
            }}
            defaultValue={accountHolder?.onboardingToken?.onboardingURL}
            fluid
          />
          <br />
          <MoovyLink
            bold
            deleteLink
            onClick={() => invalidateOnboardingToken()}
            disabled={
              invalidateOnboardingTokenMutation.isPending ||
              nextDisabled ||
              queryAccountHolder.isPending
            }
            loading={
              invalidateOnboardingTokenMutation.isPending ||
              queryAccountHolder.isPending
            }
          >
            <FormattedMessage id="stepOnboardingLink.link.invalidateToken" />
          </MoovyLink>
        </>
      )
    } else {
      return (
        <>
          <p>
            <FormattedMessage id="stepOnboardingLink.description.onboardingToken" />
          </p>

          <Button
            primary
            onClick={() => createOnboardingToken()}
            disabled={
              createOnboardingTokenMutation.isPending ||
              nextDisabled ||
              queryAccountHolder.isPending
            }
            loading={
              createOnboardingTokenMutation.isPending ||
              queryAccountHolder.isPending
            }
          >
            <FormattedMessage id="stepOnboardingLink.button.createAndContinue" />
          </Button>
        </>
      )
    }
  }

  return (
    <>
      <Header as="h4">
        <FormattedMessage id="stepAccountHolder.header.onboardingToken" />
      </Header>
      <Grid>
        <GridColumn>
          <MutationErrorMessage
            mutation={createOnboardingTokenMutation}
            defaultErrorTextLangId={
              'stepOnboardingLink.onboardingTokenCreated.failed'
            }
            messageNegative
          />
          <MutationErrorMessage
            mutation={queryAccountHolder}
            defaultErrorTextLangId={
              'stepOnboardingLink.fetchOnboardingToken.failed'
            }
            messageNegative
          />
          <RenderShowLinkOrCreateLink />
        </GridColumn>
      </Grid>
    </>
  )
}

export default StepOnboardingLink
