import './Finances.scss'

import React, { useState } from 'react'

import ReportAdyenMoovyCreditLossByMonth from './reportAdyenMoovyCreditLossByMonth'
import ReportAdyenMoovyReturnedCreditLoss from './reportAdyenMoovyReturnedCreditLoss'
import ReportAdyenPaymentReconciliation from './reportAdyenPaymentReconciliation'
import ReportAdyenSuccessfulPaymentsForMoovy from './reportAdyenSuccessfulPaymentsForMoovy'
import ReportAdyenSuccessfulRefunds from './reportAdyenSuccessfulRefunds'
import ReportCancelledBarrierlessFacilityDebtCollectionCases from './ReportCancelledBarrierlessFacilityDebtCollectionCases'
import ReportCancelledWebPayments from './ReportCancelledWebPayments'
import ReportCompletedBarrierlessFacilityDebtCollectionCases from './ReportCompletedBarrierlessFacilityDebtCollectionCases'
import ReportInfoKiosk from './ReportInfoKiosk'
import ReportOpenBarrierlessFacilityDebtCollectionCases from './ReportOpenBarrierlessFacilityDebtCollectionCases'
import ReportOverdueWebpayments from './ReportOverdueWebpayments'
import ReportSales from './ReportSales'
import ReportVismaMandators from './ReportVismaMandators'
import { Tab } from 'semantic-ui-react'
import Toolbar from '../../components/Toolbar'
import { useIntl } from 'react-intl'
import ReportAdyenSuccessfulPaymentsByProducts from './ReportAdyenSuccessfulPaymentsByProducts'

const Finances = () => {
  const intl = useIntl()
  const [tabIndex, setTabIndex] = useState(0)

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'finances.title.reports'
        })}
      />
      <div className="Admin--Page--Content">
        <Tab
          activeIndex={tabIndex}
          menu={{ fluid: true, vertical: true }}
          onTabChange={(ev, data) => {
            setTabIndex(data.activeIndex)
          }}
          panes={[
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportSales.tabLabel'
              }),
              render: () => <ReportSales />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportInfoKiosk.tabLabel'
              }),
              render: () => <ReportInfoKiosk />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportVismaMandators.tabLabel'
              }),
              render: () => <ReportVismaMandators />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportOpenBarrierlessFacilityDebtCollectionCases.tabLabel'
              }),
              render: () => <ReportOpenBarrierlessFacilityDebtCollectionCases />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportCompletedBarrierlessFacilityDebtCollectionCases.tabLabel'
              }),
              render: () => (
                <ReportCompletedBarrierlessFacilityDebtCollectionCases />
              )
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportOverdueWebpayments.tabLabel'
              }),
              render: () => <ReportOverdueWebpayments />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportCancelledBarrierlessFacilityDebtCollectionCases.tabLabel'
              }),
              render: () => (
                <ReportCancelledBarrierlessFacilityDebtCollectionCases />
              )
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportCancelledWebPayments.tabLabel'
              }),
              render: () => <ReportCancelledWebPayments />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportAdyendPaymentReconciliation.tabLabel'
              }),
              render: () => <ReportAdyenPaymentReconciliation />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportAdyenMoovyCreditLossByMonth.tabLabel'
              }),
              render: () => <ReportAdyenMoovyCreditLossByMonth />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportAdyenMoovyReturnedCreditLoss.tabLabel'
              }),
              render: () => <ReportAdyenMoovyReturnedCreditLoss />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportAdyenSuccessfulPaymentsForMoovy.tabLabel'
              }),
              render: () => <ReportAdyenSuccessfulPaymentsForMoovy />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportAdyenSuccessfulRefunds.tabLabel'
              }),
              render: () => <ReportAdyenSuccessfulRefunds />
            },
            {
              menuItem: intl.formatMessage({
                id: 'finances.tab.reportAdyenSuccessfulPayments.tabLabel'
              }),
              render: () => <ReportAdyenSuccessfulPaymentsByProducts />
            }
          ]}
        />
      </div>
    </>
  )
}

export default Finances
