import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../../components'
import React from 'react'

const ModalEditLocationCapacity = ({
  onClose,
  location,
  onSubmit,
  mutation
}) => {
  const validationSchema = () => {
    return yup.object().shape({
      locationCapacity: yup.number().min(0).max(100000)
    })
  }

  const intl = useIntl()

  const initialFormikData = (location) => {
    const { locationCapacity } = location
    return {
      locationCapacity
    }
  }

  const hasValueChanged = (locationCapacity) => {
    return location.locationCapacity !== locationCapacity
  }

  const handleFormitSubmit = (values) => {
    const { locationCapacity } = values

    if (hasValueChanged(locationCapacity)) {
      onSubmit({
        locationRef: location.ref,
        locationCapacity
      })
    } else {
      onClose()
    }
  }

  return (
    <Formik
      initialValues={initialFormikData(location)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'modalEditLocationCapacity.title'
              },
              {
                name: location.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="modalEditLocationCapacity.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'modalEditLocationCapacity.label.locationCapacity'
                })}
                value={values.locationCapacity}
                placeholder={intl.formatMessage(
                  {
                    id: 'common.unlimited'
                  },
                  {
                    name: location.name
                  }
                )}
                onChange={handleChange}
                onBlur={handleBlur}
                type="number"
                name="locationCapacity"
                error={!!errors.locationCapacity}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={
                mutation.isPending || !hasValueChanged(values.locationCapacity)
              }
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditLocationCapacity
