import React from 'react'
import { useIntl } from 'react-intl'
import { Select } from 'semantic-ui-react'

import { LocalizedServiceType } from '../../components/MoovyLocalizedEnum'
import MoovyTextInput from '../../components/MoovyTextInput'
import useOperators from '../../hooks/useOperators'
import { serviceType as parkingType } from '../../services/utils/DTOEnums'

const ParkingServiceToolbar = ({ values, setValues, isSuperOperator }) => {
  const operators = (isSuperOperator && useOperators()) || []
  const intl = useIntl()

  const serviceTypeValues = [
    {
      key: parkingType.SUBSCRIPTION,
      value: parkingType.SUBSCRIPTION,
      text: <LocalizedServiceType value={parkingType.SUBSCRIPTION} />
    },
    {
      key: parkingType.PAY_AS_YOU_GO,
      value: parkingType.PAY_AS_YOU_GO,
      text: <LocalizedServiceType value={parkingType.PAY_AS_YOU_GO} />
    }
  ]

  const operatorOptions = operators.data
    ? operators.data.content
        .sort((a, b) => `${a.name}`.localeCompare(b.name))
        .map((op) => ({
          key: op.ref,
          value: op.ref,
          text: op.name
        }))
    : []

  return (
    <div>
      <div className="toolbar-filters">
        <MoovyTextInput
          value={values.searchInputValue}
          onChange={(ev) =>
            setValues({ ...values, searchInputValue: ev.target.value })
          }
          onClear={() => setValues({ ...values, searchInputValue: '' })}
          placeholder={intl.formatMessage({
            id: 'parkingService.toolbar.filter.search.placeholder'
          })}
        />

        {isSuperOperator ? (
          <Select
            clearable
            placeholder={intl.formatMessage({
              id: 'parkingService.toolbar.filter.operator.placeholder'
            })}
            search
            value={values.operatorRef}
            options={operatorOptions}
            onChange={(e, data) =>
              setValues({ ...values, operatorRef: data.value })
            }
            selectOnBlur={false}
          />
        ) : (
          ''
        )}
        <Select
          placeholder={intl.formatMessage({
            id: 'parkingService.toolbar.filter.service.placeholder'
          })}
          value={values.type}
          options={serviceTypeValues}
          onChange={(e, data) => setValues({ ...values, type: data.value })}
          selectOnBlur={false}
        />
      </div>
    </div>
  )
}

export default ParkingServiceToolbar
