import { Grid, List } from 'semantic-ui-react'

import MoovyActiveItem from '../../../containers/MoovyActiveItem'
import React from 'react'
import { convertMinutes } from '../../../services/utils'
import { getVoucherTemplateDescription } from '../../../services/VoucherTemplates'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { voucher as voucherEnums } from '../../../services/utils/DTOEnums'

const VoucherDetails = ({
  voucherTemplate,
  showConfirmation,
  onShowConfirmation,
  updateVoucherTemplateMutation,
  onActiveFlagChanged
}) => {
  const { isSuperOperator } = useAuthInfo()

  return (
    <>
      {voucherTemplate && (
        <Grid columns="2" stackable style={{ maxWidth: '1200px' }}>
          <Grid.Column width={10}>
            <List>
              <List.Item>
                <MoovyActiveItem
                  active={voucherTemplate.active}
                  name="pysäköintietu"
                  toggleable={isSuperOperator}
                  onToggleChanged={onActiveFlagChanged}
                  confirmationEnabled
                  showConfirmation={showConfirmation}
                  mutation={updateVoucherTemplateMutation}
                  onShowConfirmation={onShowConfirmation}
                />
              </List.Item>
              <List.Item>
                <b>Kuvaus:</b>{' '}
                {getVoucherTemplateDescription(voucherTemplate) || '-'}
              </List.Item>
              <List.Item>
                <b>Operaattori:</b> {voucherTemplate.operator.name}
              </List.Item>
              {(voucherTemplate.grantedDurationInMinutes && (
                <List.Item>
                  <b>Edun pituus:</b>{' '}
                  {convertMinutes(voucherTemplate.grantedDurationInMinutes)}
                </List.Item>
              )) ||
                ''}
              <List.Item>
                <b>Voimassa myöntämisestä:</b>{' '}
                {convertMinutes(voucherTemplate.validityInMinutes)}
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={6}>
            <List>
              <List.Item>
                <b>Edun kategoria:</b>{' '}
                {
                  voucherEnums.categoryTypes.stringValues[
                    voucherTemplate.categoryType
                  ]
                }
              </List.Item>
              <List.Item>
                <b>Edun tyyppi:</b>{' '}
                {
                  voucherEnums.benefitTypes.stringValues[
                    voucherTemplate.benefitType
                  ]
                }
              </List.Item>
              {voucherTemplate.grantedDiscountPercent && (
                <List.Item>
                  <b>Alennus:</b>
                  {` ${voucherTemplate.grantedDiscountPercent}%`}
                </List.Item>
              )}
              {voucherTemplate.grantedValue && (
                <List.Item>
                  <b>Arvo:</b>
                  {` ${voucherTemplate.grantedValue}e`}
                </List.Item>
              )}
              <List.Item>
                <b>Myöntämistyyppi:</b>{' '}
                {
                  voucherEnums.grantTypes.stringValues[
                    voucherTemplate.allowedGrantType
                  ]
                }
              </List.Item>
              <List.Item>
                <b>Kustannus antajalle:</b> {`${voucherTemplate.salePrice}e`}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      )}
    </>
  )
}

export default VoucherDetails
