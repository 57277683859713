import React from 'react'
import { Routes, Route } from 'react-router-dom'

import CreateMessage from '../pages/messageCenter/CreateMessage/CreateMessage'
import Customers from '../pages/Customers'
import GrantedVouchers from '../pages/grantedVouchers'
import Locations from '../pages/locations'
import LocationsList from '../pages/locations/locationsList'
import MessageCenter from '../pages/messageCenter'
import ParkingPage from '../pages/parking'
import ParkingService from '../containers/ParkingService'
import ParkingServiceDetails from '../containers/ParkingServiceDetails'
import Sales from '../pages/sales'
import ServiceTariff from '../containers/ServiceTariff'
import UserGroup from '../containers/UserGroup'
import UserGroups from '../containers/UserGroups'
import VoucherTemplateDetails from '../pages/voucherTemplates/voucherTemplateDetails'
import VoucherTemplates from '../pages/voucherTemplates'
import RedirectUser from './RedirectUser'
import PotentiallyIncorrectLicensePlates from '../pages/potentiallyIncorrectLicensePlates'
import PotentiallyIncorrectLicensePlate from '../pages/potentiallyIncorrectLicensePlate'

/* Generic routes for both SuperOperator and Operator users */
const AppRoutes = () => {
  return (
    <Routes>
      <Route path="services" element={<ParkingService />} />
      <Route path="services/:serviceRef" element={<ParkingServiceDetails />} />
      <Route
        path="services/:serviceRef/tariff/:tariffRef"
        element={<ServiceTariff />}
      />
      <Route path="grantedVouchers" element={<GrantedVouchers />} />
      <Route path="locations" element={<LocationsList />} />
      <Route path="locations/:locationRef" element={<Locations />} />
      <Route path="customers/:customerRef" element={<Customers />} />
      <Route path="userGroups/:userGroupRef/*" element={<UserGroup />} />
      <Route path="userGroups" element={<UserGroups />} />
      <Route
        path="voucherTemplates/:voucherTemplateRef"
        element={<VoucherTemplateDetails />}
      />
      <Route path="voucherTemplates" element={<VoucherTemplates />} />
      <Route path="messageCenter" element={<MessageCenter />} />
      <Route
        path="messageCenter/edit/:messageRef"
        element={<CreateMessage />}
      />
      <Route path="messageCenter/edit" element={<CreateMessage />} />
      <Route path="sales" element={<Sales />} />
      <Route path="parking/:ref" element={<ParkingPage />} />
      <Route
        path="potentiallyIncorrectLicensePlates"
        element={<PotentiallyIncorrectLicensePlates />}
      />
      <Route
        path="potentiallyIncorrectLicensePlate/:ref"
        element={<PotentiallyIncorrectLicensePlate />}
      />

      <Route path="*" element={<RedirectUser />} />
    </Routes>
  )
}

export default AppRoutes
