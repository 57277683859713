import PropTypes from 'prop-types'
import React from 'react'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import { localizationSelectors } from '../../redux/localization'

const mapState = (state) => ({
  locale: localizationSelectors.getLocale(state),
  messages: localizationSelectors.getMessages(state)
})

export const IntlContainer = (props) => <IntlProvider {...props} />

IntlContainer.propTypes = {
  locale: PropTypes.string,
  messages: PropTypes.object,
  children: PropTypes.node.isRequired
}

export default connect(mapState)(IntlContainer)
