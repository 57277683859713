import { Button, Icon, Loader, Popup, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyModalConfirmation,
  showLocalizedMoovyToast
} from '../../components'
import { PermissionVoucher, formatDateAndTime } from '../../services/utils'
import React, { useState } from 'react'

import { DateTime } from 'luxon'
import TablePagination from '../TablePagination'
import { getVoucherLocationDescription } from '../../services/GrantedVouchers'
import moovyDateTime from '../../services/utils/moovyDateTime'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'
import { userVoucherService } from '../../services'

const getStatus = (voucher) => {
  const validFrom =
    voucher?.validFrom &&
    moovyDateTime.backendDateTimeToDateTime(voucher?.validFrom)
  const validUntil =
    voucher?.validUntil &&
    moovyDateTime.backendDateTimeToDateTime(voucher?.validUntil)
  const now = DateTime.now()

  if (now > validUntil) {
    return <FormattedMessage id="grantedVouchersTable.enum.status.expired" />
  }
  if (now < validFrom) {
    return (
      <FormattedMessage id="grantedVouchersTable.enum.status.notValidYet" />
    )
  }
  return <FormattedMessage id="grantedVouchersTable.enum.status.valid" />
}

const GrantedVouchersTable = ({
  vouchers,
  activePage,
  totalPages,
  onPageChange,
  sortColumn,
  direction,
  handleSort,
  onSelectedVoucher,
  onRefresh,
  emptyTextId,
  isLoading
}) => {
  const [confirmVoucherDeletion, setConfirmVoucherDeletion] = useState(null)
  const isEmpty = !vouchers || vouchers.length === 0
  const isSelectable = onSelectedVoucher != null && !isEmpty

  const intl = useIntl()
  const { isSuperOperator, roles } = useAuthInfo()

  const { mutate: deleteUnusedVoucher, ...deleteUnusedVoucherMutation } =
    useMutation({
      mutationFn: (voucherRef) =>
        userVoucherService.deleteUnusedVoucher(voucherRef),
      onSuccess: () => {
        setConfirmVoucherDeletion(null)
        onRefresh()

        showLocalizedMoovyToast(intl, {
          title: 'grantedVouchersTable.deleteVoucher.action.success.title',
          description:
            'grantedVouchersTable.deleteVoucher.action.success.description'
        })
      }
    })

  const tableCells = (voucher) => {
    return (
      <>
        <Table.Cell>{voucher.licensePlateNumber}</Table.Cell>
        <Table.Cell>{voucher.grantedTo}</Table.Cell>
        <Table.Cell>{voucher.operator && voucher.operator.name}</Table.Cell>
        <Table.Cell>{getVoucherLocationDescription(voucher)}</Table.Cell>
        <Table.Cell>{formatDateAndTime(voucher.grantedDate)}</Table.Cell>
        <Table.Cell>{formatDateAndTime(voucher.validFrom)}</Table.Cell>
        <Table.Cell>{formatDateAndTime(voucher.validUntil)}</Table.Cell>
        <Table.Cell>{getStatus(voucher)}</Table.Cell>
        {PermissionVoucher.HasDeleteVoucher(isSuperOperator, roles) && (
          <Table.Cell onClick={(event) => event.stopPropagation()}>
            <Popup
              trigger={
                <Button
                  basic
                  icon
                  onClick={() => setConfirmVoucherDeletion(voucher)}
                >
                  <Icon name="trash alternate" color="red" />
                </Button>
              }
              content={
                <FormattedMessage id="grantedVouchersTable.button.deleteVoucher" />
              }
            />
          </Table.Cell>
        )}
      </>
    )
  }

  return (
    <>
      <Table sortable selectable={isSelectable}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={
                sortColumn === 'sortByLicensePlateNumber' ? direction : null
              }
              onClick={handleSort('sortByLicensePlateNumber')}
            >
              <FormattedMessage id="grantedVouchersTable.table.header.licensePlateNumber" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="grantedVouchersTable.table.header.voucherOwner" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'sortByOperator' ? direction : null}
              onClick={handleSort('sortByOperator')}
            >
              <FormattedMessage id="grantedVouchersTable.table.header.operator" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="grantedVouchersTable.table.header.locationsDescription" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'sortByGrantedDate' ? direction : null}
              onClick={handleSort('sortByGrantedDate')}
            >
              <FormattedMessage id="grantedVouchersTable.table.header.granted" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'sortByValidFrom' ? direction : null}
              onClick={handleSort('sortByValidFrom')}
            >
              <FormattedMessage id="grantedVouchersTable.table.header.validFrom" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={sortColumn === 'sortByValidUntil' ? direction : null}
              onClick={handleSort('sortByValidUntil')}
            >
              <FormattedMessage id="grantedVouchersTable.table.header.validUntil" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="grantedVouchersTable.table.header.status" />
            </Table.HeaderCell>
            {PermissionVoucher.HasDeleteVoucher(isSuperOperator, roles) && (
              <Table.HeaderCell></Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {isEmpty || isLoading ? (
            <Table.Row>
              <Table.Cell colSpan={99}>
                <div style={{ textAlign: 'center' }}>
                  {isLoading ? (
                    <Loader active inline />
                  ) : (
                    emptyTextId || (
                      <FormattedMessage id="moovyTable.action.emptySet" />
                    )
                  )}
                </div>
              </Table.Cell>
            </Table.Row>
          ) : (
            vouchers.map((voucher) =>
              isSelectable ? (
                <Table.Row
                  key={voucher.ref}
                  onClick={() => {
                    onSelectedVoucher(voucher)
                  }}
                >
                  {tableCells(voucher)}
                </Table.Row>
              ) : (
                <Table.Row key={voucher.ref}>{tableCells(voucher)}</Table.Row>
              )
            )
          )}
        </Table.Body>
        {!isLoading && (
          <TablePagination
            colSpan={9}
            activePage={activePage}
            totalPages={totalPages}
            onPageChange={onPageChange}
          />
        )}
      </Table>
      <MoovyModalConfirmation
        open={!!confirmVoucherDeletion}
        onClose={() => setConfirmVoucherDeletion(null)}
        lang={{
          titleElement: (
            <FormattedMessage
              id="grantedVouchersTable.deleteVoucher.confirm.title"
              values={{
                name:
                  confirmVoucherDeletion?.grantedTo ||
                  confirmVoucherDeletion?.licensePlateNumber
              }}
            />
          ),
          bodyElement: (
            <FormattedMessage id="grantedVouchersTable.deleteVoucher.confirm.body" />
          ),
          buttonConfirmKey: 'common.button.confirm',
          defaultErroKey: 'grantedVouchersTable.deleteVoucher.action.error'
        }}
        onSubmit={() => deleteUnusedVoucher(confirmVoucherDeletion?.ref)}
        mutation={deleteUnusedVoucherMutation}
      />
    </>
  )
}

export default GrantedVouchersTable
