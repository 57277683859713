import './adyenInvoiceSummary.scss'

import {
  LocalizedInvoicingStatus,
  LocalizedPaymentCardCategory
} from '../MoovyLocalizedEnum'
import { formatPrice, getStringValue, sale } from '../../services/utils'

import { FormattedMessage } from 'react-intl'
import { List } from 'semantic-ui-react'
import { MoovyPlaceholderLoader } from '..'
import React from 'react'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'

const AdyenInvoiceSummary = ({ invoice }) => {
  if (!invoice) {
    return <MoovyPlaceholderLoader paragraphs={3} fluid={false} />
  }

  return (
    <List className="InvoiceSummary" relaxed>
      <List.Item className="totalHeader">
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.total.header" />
        </List.Header>
        <List.Content>
          <h1>{formatPrice(invoice.totalPrice)}</h1>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.invoiceNumber.header" />
        </List.Header>
        <List.Content>{invoice.invoiceNumber}</List.Content>
      </List.Item>
      <List.Item>
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.paymentMethod.header" />
        </List.Header>
        <FormattedMessage
          id={getStringValue(
            sale.paymentMethodTypes.localizationKeys,
            invoice.paymentMethodType
          )}
          defaultMessage={invoice.paymentMethodType}
        />
      </List.Item>
      <List.Item>
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.paymentCardCategory.header" />
        </List.Header>
        <LocalizedPaymentCardCategory value={invoice.paymentCardCategory} />
      </List.Item>
      {invoice.adyenLatestPspRef && (
        <List.Item>
          <List.Header>
            <FormattedMessage id="invoiceModal.invoiceSummary.paymentCardCategory.adyenLatestPspRef" />
          </List.Header>
          {invoice.adyenLatestPspRef}
        </List.Item>
      )}
      <List.Item>
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.status.header" />
        </List.Header>
        <LocalizedInvoicingStatus
          value={invoice.status}
          paymentMethodType={invoice.paymentMethodType}
        />
      </List.Item>
      <List.Item>
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.invoicingAttemptCount.header" />
        </List.Header>
        {invoice.invoicingAttemptCount}
      </List.Item>
      {invoice?.user?.ref && (
        <List.Item>
          <List.Header>
            <FormattedMessage id="invoiceModal.invoiceSummary.user.header" />
          </List.Header>
          <RelativeLinkForCurrentUser to={`customers/${invoice.user.ref}`}>
            {invoice.user.name || invoice.user.ref}
          </RelativeLinkForCurrentUser>
        </List.Item>
      )}
    </List>
  )
}

export default AdyenInvoiceSummary
