import React from 'react'
import { Button, ButtonGroup, Grid, Icon, Popup } from 'semantic-ui-react'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'
import service from '../../services/potentiallyIncorrectLicensePlates'
import { Toolbar } from '../../components'

const PreviousButton = ({ disabled }) => (
  <Button icon labelPosition="left" fluid={false} disabled={disabled}>
    <Icon name="left arrow" />
    <FormattedMessage id="potentiallyIncorrectLicensePlate.navigation.previous" />
  </Button>
)

const NextButton = ({ disabled }) => (
  <Button icon labelPosition="right" fluid={false} disabled={disabled}>
    <FormattedMessage id="potentiallyIncorrectLicensePlate.navigation.next" />
    <Icon name="right arrow" />
  </Button>
)

const PotentiallyIncorrectLicensePlateToolbar = () => {
  const { ref } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const intl = useIntl()
  const { operatorRealm, isSuperOperator } = useAuthInfo()
  let activePage = searchParams.get('activePage') ?? 1
  const operator = isSuperOperator ? searchParams.get('operator') : null
  const location = searchParams.get('location')

  const suspectsQuery = useQuery({
    queryKey: [
      'potentiallyIncorrectLicensePlates',
      operator,
      location,
      activePage
    ],
    queryFn: () => {
      return service.fetchPaginated({
        operatorRef: operator,
        operatorRealm,
        locationRef: location,
        limit: 20,
        page: activePage - 1
      })
    },
    enabled: Number(activePage) > 0 && !!operator
  })

  if (suspectsQuery.data?.totalPages < activePage) {
    setSearchParams({
      activePage: 1,
      operator: operator ?? '',
      location: location ?? ''
    })
  }

  const suspectRefs = suspectsQuery.data?.data?.map((row) => row.ref) ?? []

  let currentIndex = suspectRefs.findIndex((row) => row === ref)

  if (currentIndex === -1) {
    currentIndex = 0
    suspectRefs[0] = ref
  }

  const previousRef =
    suspectRefs.length && currentIndex > 0
      ? suspectRefs[currentIndex - 1]
      : null
  const nextRef =
    suspectRefs.length > currentIndex && currentIndex >= 0 && currentIndex < 20
      ? suspectRefs[currentIndex + 1]
      : null

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'potentiallyIncorrectLicensePlate.breadcrumb.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'potentiallyIncorrectLicensePlate.breadcrumb.potentiallyIncorrectLicensePlates'
      }),
      href: '/potentiallyIncorrectLicensePlates?' + searchParams.toString()
    },
    {
      text: intl.formatMessage({
        id: 'potentiallyIncorrectLicensePlate.breadcrumb.potentiallyIncorrectLicensePlate'
      }),
      active: true
    }
  ])

  return (
    <Grid style={{ marginRight: 0 }}>
      <Grid.Column width={6}>
        <Toolbar breadcrumbs={breadcrumbs} title="" content="" />
      </Grid.Column>
      <Grid.Column width={10}>
        <ButtonGroup
          floated="right"
          style={{ marginTop: '30px', marginRight: '50px' }}
        >
          {previousRef ? (
            <Link
              to={{
                pathname: isSuperOperator
                  ? `/admin/potentiallyIncorrectLicensePlate/${previousRef}`
                  : `/operator/potentiallyIncorrectLicensePlate/${previousRef}`,
                search: `?${searchParams.toString()}`
              }}
            >
              <PreviousButton />
            </Link>
          ) : (
            <PreviousButton disabled />
          )}
          <Link
            to={{
              pathname: isSuperOperator
                ? '/admin/potentiallyIncorrectLicensePlates'
                : '/operator/potentiallyIncorrectLicensePlates',
              search: `?${searchParams.toString()}`
            }}
          >
            <Popup
              content={
                <FormattedMessage id="potentiallyIncorrectLicensePlate.navigation.back" />
              }
              trigger={
                <Button>
                  {currentIndex + 1} / {suspectsQuery?.data?.data.length ?? 1}
                </Button>
              }
            />
          </Link>
          {nextRef ? (
            <Link
              to={{
                pathname: isSuperOperator
                  ? `/admin/potentiallyIncorrectLicensePlate/${nextRef}`
                  : `/operator/potentiallyIncorrectLicensePlate/${nextRef}`,
                search: `?${searchParams.toString()}`
              }}
            >
              <NextButton />
            </Link>
          ) : (
            <NextButton disabled />
          )}
        </ButtonGroup>
      </Grid.Column>
    </Grid>
  )
}
export default PotentiallyIncorrectLicensePlateToolbar
