import CreditCoverCreditLossContent from './creditCoverCreditLossContent'
import CreditCoverCreditLossToolbar from './creditCoverCreditLossToolbar'
import React from 'react'
import Toolbar from '../../components/Toolbar'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import { useIntl } from 'react-intl'

const CreditCoverCreditLoss = () => {
  const intl = useIntl()

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.creditCoverCreditLoss'
      }),
      active: true
    }
  ])

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'common.breadcrumbs.creditCoverCreditLoss'
        })}
        breadcrumbs={breadcrumbs}
        content={<CreditCoverCreditLossToolbar />}
      />
      <div className="Admin--Page--Content">
        <CreditCoverCreditLossContent />
      </div>
    </>
  )
}

export default CreditCoverCreditLoss
