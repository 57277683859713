import { sale } from '../../services/utils'
import { useIntl } from 'react-intl'

const CompensationInvoiceColumnInfo = ({ invoice }) => {
  const intl = useIntl()

  if (!invoice?.compensatedInvoice) return ''

  return (
    <>
      {invoice?.paymentMethodType === sale.paymentMethodTypes.VISMA
        ? intl.formatMessage(
            {
              id: 'compensationInvoiceColumnInfo.compensationVisma'
            },
            { invoiceName: invoice.compensatedInvoice.name }
          )
        : intl.formatMessage(
            {
              id: 'compensationInvoiceColumnInfo.compensationOther'
            },
            { invoiceName: invoice.compensatedInvoice.name }
          )}
    </>
  )
}

export default CompensationInvoiceColumnInfo
