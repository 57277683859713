import { MoovyTable, TablePagination } from '../../components'

import { FormattedMessage } from 'react-intl'
import React from 'react'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import { Table } from 'semantic-ui-react'
import { formatPrice } from '../../services/utils'
import moovyPagination from '../../components/TablePagination/pagination'

const CreditCoverCreditLossTable = ({
  tableItems,
  creditCoverCreditLossQuery,
  dateRange,
  onPageChange,
  onClickedCreditLoss,
  totalPages,
  activePage,
  sorting,
  onSort
}) => {
  return (
    <>
      <div>
        <b>
          <FormattedMessage
            id="creditCoverCreditLossTable.table.title"
            values={{ dateRange: dateRange.month + '/' + dateRange.year }}
          />
        </b>
      </div>
      <MoovyTable
        compact="very"
        celled
        items={tableItems}
        query={creditCoverCreditLossQuery}
        sortable
        selectable
      >
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell
              sorted={moovyPagination.getSortDirection(
                'settlementRecipientName',
                sorting
              )}
              onClick={() =>
                moovyPagination.sortColumn(
                  'settlementRecipientName',
                  sorting,
                  onSort
                )
              }
            >
              <FormattedMessage id="creditCoverCreditLossTable.table.header.settlementRecipientName" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={moovyPagination.getSortDirection(
                'accountHolderCode',
                sorting
              )}
              onClick={() =>
                moovyPagination.sortColumn('accountHolderCode', sorting, onSort)
              }
            >
              <FormattedMessage id="creditCoverCreditLossTable.table.header.accountHolderCode" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={moovyPagination.getSortDirection('creditLoss', sorting)}
              onClick={() =>
                moovyPagination.sortColumn('creditLoss', sorting, onSort)
              }
            >
              <FormattedMessage id="creditCoverCreditLossTable.table.header.creditLoss" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={moovyPagination.getSortDirection(
                'isReadyToBeCovered',
                sorting
              )}
              onClick={() =>
                moovyPagination.sortColumn(
                  'isReadyToBeCovered',
                  sorting,
                  onSort
                )
              }
            >
              <FormattedMessage id="creditCoverCreditLossTable.table.header.isReadyToBeCovered" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {tableItems.map((row) => (
            <Table.Row
              key={row.settlementRecipientRef}
              onClick={() => {
                onClickedCreditLoss(row)
              }}
            >
              <Table.Cell>
                <RelativeLinkForCurrentUser
                  to={`settlementRecipients/${row.settlementRecipientRef}`}
                >
                  {row.settlementRecipientName}
                </RelativeLinkForCurrentUser>
              </Table.Cell>
              <Table.Cell>{row.accountHolderCode}</Table.Cell>
              <Table.Cell>{formatPrice(row.creditLoss)}</Table.Cell>
              <Table.Cell collapsing>
                {row.isReadyToBeCovered ? (
                  <FormattedMessage id="common.yes" />
                ) : (
                  <FormattedMessage id="common.no" />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <TablePagination
          colSpan={4}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
      </MoovyTable>
    </>
  )
}

export default CreditCoverCreditLossTable
