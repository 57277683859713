import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Button, Form, Grid, Segment } from 'semantic-ui-react'
import * as yup from 'yup'

import { MoovyLink } from '../../../../components'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import messageCenterService from '../../../../services/MessageCenter'
import { messageCenterEnums } from '../../../../services/utils/DTOEnums'
import { operatorEnum } from './Row'
import { getNextBiggestId, parseRoot, parseTree } from './TargetGroupParser'
import Tree from './Tree'

export const buttonOperatorEnum = {
  AND: '$and',
  OR: '$or'
}

const TargetGroupCreator = ({ targetGroupData }) => {
  const intl = useIntl()

  const validationSchema = () => {
    return yup.object().shape({
      targetGroupName: yup.string().required('Cannot be empty'),
      timeIntervalValue: yup
        .string()
        .test('timeIntervalType', true, function () {
          return (
            (!this.parent.timeIntervalType && !this.parent.timeIntervalValue) ||
            this.parent.timeIntervalValue
          )
        }),
      timeIntervalType: yup
        .string()
        .test('timeIntervalType', true, function () {
          return (
            (!this.parent.timeIntervalType && !this.parent.timeIntervalValue) ||
            this.parent.timeIntervalType
          )
        }),
      timeIntervalDisabled: yup
        .bool()
        .test('timeIntervalDisabled', true, function () {
          return (
            this.parent.timeIntervalDisabled ||
            (this.parent.timeIntervalType && this.parent.timeIntervalValue)
          )
        })
    })
  }

  const emptyTreeObject = {
    id: 1,
    value: '',
    targetPath: '',
    activeButton: false,
    parentId: 0,
    hasChildren: false
  }

  const [nestedTreeData, setNestedTreeData] = useState([emptyTreeObject])
  const [timeIntervalValue, setTimeIntervalValue] = useState(undefined)
  const [timeIntervalType, setTimeIntervalType] = useState(undefined)
  const [timeIntervalDisabled, setTimeIntervalDisabled] = useState(undefined)

  useEffect(() => {
    if (targetGroupData && targetGroupData.value) {
      let structure = []
      let level = 0
      const uiItems = parseRoot(
        structure,
        JSON.parse(targetGroupData.value),
        '',
        level
      )

      setNestedTreeData(
        uiItems.map((item) => ({
          ...item,
          hasChildren: uiItems.filter((i) => i.parentId === item.id).length > 0
        }))
      )
    } else {
      if (JSON.stringify(nestedTreeData) != JSON.stringify([emptyTreeObject])) {
        setNestedTreeData([emptyTreeObject])
      }
    }
  }, [targetGroupData])

  const [totalCount, setTotalCount] = useState(0)

  const { mutate: groupsCount, ...groupsCountMutation } = useMutation({
    mutationFn: (data) => messageCenterService.fetchTargetUserCountData(data),
    onSuccess: (response) => {
      setTotalCount(response.count)
    },
    onError: () => {
      setTotalCount(0)
    }
  })

  const updateCount = () => {
    const targetFilters = { $and: parseTree([], nestedTreeData) }
    groupsCount({
      targetType: messageCenterEnums.targetType.GROUPS,
      value: JSON.stringify(targetFilters),
      intervalValue: timeIntervalValue,
      intervalType: timeIntervalType,
      intervalEnabled: !timeIntervalDisabled
    })
  }

  const { mutate: saveTarget, ...saveTargetMutation } = useMutation({
    mutationFn: (submitContent) =>
      messageCenterService.saveTarget(null, submitContent.target),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'messageCenter.targetGroup.action.success.create.title',
        description: 'messageCenter.targetGroup.action.success.create.body',
        values: {
          name: response.name
        }
      })
      setNestedTreeData([emptyTreeObject])
      const { resetForm } = variables
      resetForm({
        values: {
          targetGroupName: '',
          timeIntervalValue: '',
          timeIntervalType: '',
          timeIntervalDisabled: false
        }
      })
    },
    onError: (error) => {
      if (
        error &&
        error.body &&
        error.body.errorDescriptor &&
        error.body.errorDescriptor.errorType === 'DUPLICATE_REQUEST'
      ) {
        showLocalizedMoovyToast(intl, {
          title: 'messageCenter.targetGroup.action.error.create.title',
          description:
            'messageCenter.targetGroup.action.error.create.targetExist.body',
          type: 'error'
        })
      } else {
        showLocalizedMoovyToast(intl, {
          title: 'messageCenter.targetGroup.action.error.create.title',
          description: 'messageCenter.targetGroup.action.error.create.body',
          type: 'error'
        })
      }
    }
  })

  const timeRange = []
  for (let timeRangeIndex = 1; timeRangeIndex <= 31; timeRangeIndex++) {
    timeRange.push({
      key: timeRangeIndex,
      text: timeRangeIndex,
      value: timeRangeIndex
    })
  }

  const timeIntervalTypes = [
    {
      key: messageCenterEnums.targetGroupTimeInterval.MONTHS,
      text: intl.formatMessage({
        id: 'messageCenter.targetGroup.filter.dateType.months'
      }),
      value: messageCenterEnums.targetGroupTimeInterval.MONTHS
    },
    {
      key: messageCenterEnums.targetGroupTimeInterval.WEEKS,
      text: intl.formatMessage({
        id: 'messageCenter.targetGroup.filter.dateType.weeks'
      }),
      value: messageCenterEnums.targetGroupTimeInterval.WEEKS
    },
    {
      key: messageCenterEnums.targetGroupTimeInterval.DAYS,
      text: intl.formatMessage({
        id: 'messageCenter.targetGroup.filter.dateType.days'
      }),
      value: messageCenterEnums.targetGroupTimeInterval.DAYS
    }
  ]

  const addFilterClick = () => {
    let idNumber = getNextBiggestId(nestedTreeData)

    const emptyItem = {
      id: idNumber,
      value: '',
      targetPath: '',
      parentId: 0
    }

    setNestedTreeData([...nestedTreeData, emptyItem])
  }

  const rowButtonClick = (operator, item) => {
    if (
      operator !== buttonOperatorEnum.AND &&
      operator !== buttonOperatorEnum.OR
    )
      return

    let tempData = [...nestedTreeData]
    let currentItemIndex = tempData.indexOf(item)

    let idNumber = getNextBiggestId(nestedTreeData)

    const operatorNode = {
      id: idNumber,
      value: '',
      targetPath: '',
      operator: operator,
      parentId: item.parentId
    }

    idNumber++
    let parentNodeId

    if (operator === buttonOperatorEnum.OR) {
      const parentItem = tempData.find(
        (parentItem) => parentItem.id === item.parentId
      )
      if (parentItem && parentItem.operator === buttonOperatorEnum.OR) {
        parentNodeId = parentItem.id
      }
    }

    if (!parentNodeId) {
      tempData.splice(currentItemIndex, 0, operatorNode)
      currentItemIndex++
    }

    const currentItem = {
      ...item,
      id: idNumber,
      parentId: (parentNodeId && parentNodeId) || operatorNode.id,
      activeButton: true
    }

    tempData.splice(tempData.indexOf(item), 1)
    tempData.splice(currentItemIndex, 0, currentItem)
    currentItemIndex++
    idNumber++

    const emptyItem = {
      id: idNumber,
      value: '',
      targetPath: '',
      parentId: (parentNodeId && parentNodeId) || operatorNode.id
    }

    tempData.splice(currentItemIndex, 0, emptyItem)

    tempData = tempData.map((item) => ({
      ...item,
      hasChildren: tempData.filter((i) => i.parentId === item.id).length > 0
    }))

    setNestedTreeData(tempData)
  }

  const rowButtonDeleteClick = (deletableItem) => {
    let data = [...nestedTreeData]

    const parentItem = data.find((item) => item.id === deletableItem.parentId)

    let parentChildren = []

    if (parentItem) {
      parentChildren = data.filter(
        (item) => item.parentId === parentItem.id && item.id != deletableItem.id
      )
    }

    data.splice(data.indexOf(deletableItem), 1)

    if (parentChildren.length == 1) {
      const movableChild = {
        ...parentChildren[0],
        parentId: parentItem.parentId,
        activeButton: false
      }

      data[data.indexOf(parentChildren[0])] = movableChild
      data.splice(data.indexOf(parentItem), 1)
    }

    setNestedTreeData(data)
  }

  const onChangeValue = (item, value) => {
    let data = [...nestedTreeData]
    const index = data.indexOf(item)
    if (index !== -1) {
      const updatedItem = {
        ...item,
        value: value
      }
      data[index] = updatedItem
      setNestedTreeData(data)
    }
  }

  const onOperatorSelected = (item, value) => {
    let data = [...nestedTreeData]
    const index = data.indexOf(item)
    if (index !== -1) {
      const updatedItem = {
        ...item,
        itemOperator:
          (value === operatorEnum.NOT_EQUAL && operatorEnum.NOT) || ''
      }
      data[index] = updatedItem
      setNestedTreeData(data)
    }
  }

  const onMenuItemClick = (menuItem, rowItemId) => {
    let data = [...nestedTreeData]

    const item = data.find((item) => item.id === rowItemId)
    if (item) {
      const index = data.indexOf(item)
      const updatedItem = {
        ...item,
        targetPath: menuItem.name
      }
      data[index] = updatedItem
      setNestedTreeData(data)
    }
  }

  const onSubmit = (values, { resetForm }) => {
    const targetFilters = { $and: parseTree([], nestedTreeData) }
    const target = {
      targetType: messageCenterEnums.targetType.GROUPS,
      name: values.targetGroupName,
      value: JSON.stringify(targetFilters),
      intervalEnabled: !values.timeIntervalDisabled,
      ...(values.timeIntervalValue && {
        intervalValue: values.timeIntervalValue
      }),
      ...(values.timeIntervalType && { intervalType: values.timeIntervalType })
    }

    const submitContent = {
      target: target,
      resetForm: resetForm
    }

    saveTarget(submitContent)
  }

  const GlobalFilters = ({
    values,
    errors,
    setFieldValue,
    handleBlur,
    handleChange
  }) => {
    return (
      <>
        <Form.Input
          name="targetGroupName"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.targetGroupName}
          error={!!errors.targetGroupName}
          label={intl.formatMessage({
            id: 'messageCenter.targetGroup.label.targetGroupName'
          })}
        ></Form.Input>
        <Form.Field>
          <label>
            <FormattedMessage id="messageCenter.targetGroup.label.filterScope" />
          </label>
        </Form.Field>
        <Form.Field>
          <Grid>
            <Grid.Row verticalAlign={'middle'}>
              <Grid.Column width={5}>
                <FormattedMessage id="messageCenter.targetGroup.label.timeIntervalValue" />
              </Grid.Column>
              <Grid.Column width={2}>
                <Form.Dropdown
                  name="timeIntervalValue"
                  compact
                  placeholder="-"
                  selection
                  value={values.timeIntervalValue || ''}
                  options={timeRange}
                  onChange={(event, data) => {
                    setTimeIntervalValue(data.value)
                    setFieldValue(data.name, data.value)
                  }}
                  error={!!errors.timeIntervalValue}
                  selectOnBlur={false}
                />
              </Grid.Column>
              <Grid.Column width={3}>
                <Form.Dropdown
                  name="timeIntervalType"
                  compact
                  placeholder="-"
                  selection
                  value={values.timeIntervalType || ''}
                  options={timeIntervalTypes}
                  onChange={(event, data) => {
                    setTimeIntervalType(data.value)
                    setFieldValue(data.name, data.value)
                  }}
                  error={!!errors.timeIntervalType}
                  selectOnBlur={false}
                />
              </Grid.Column>
              <Grid.Column width={6}>
                <Form.Checkbox
                  name="timeIntervalDisabled"
                  checked={values.timeIntervalDisabled}
                  label={intl.formatMessage({
                    id: 'messageCenter.targetGroup.label.timeIntervalDisabled'
                  })}
                  onClick={(e, { name, checked }) => {
                    setTimeIntervalDisabled(checked)
                    setFieldValue(name, checked)
                  }}
                  error={!!errors.timeIntervalDisabled}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form.Field>
      </>
    )
  }

  const getInitialValues = () => {
    if (targetGroupData) {
      return {
        targetGroupName: targetGroupData.name || '',
        timeIntervalValue: targetGroupData.intervalValue || '',
        timeIntervalType: targetGroupData.intervalType || '',
        timeIntervalDisabled: targetGroupData.intervalDisabled || false
      }
    } else {
      return {
        targetGroupName: '',
        timeIntervalValue: '',
        timeIntervalType: '',
        timeIntervalDisabled: false
      }
    }
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        handleBlur,
        handleChange
      }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <GlobalFilters
              values={values}
              errors={errors}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
            />
            <Tree
              key={'tree'}
              treeData={nestedTreeData}
              parentId={0}
              level={0}
              rowButtonClick={rowButtonClick}
              rowButtonDeleteClick={rowButtonDeleteClick}
              onChangeValue={onChangeValue}
              onOperatorSelected={onOperatorSelected}
              onMenuItemClick={onMenuItemClick}
            />
            <br></br>
            <MoovyLink onClick={addFilterClick}>
              <FormattedMessage id="messageCenter.targetGroup.filter.button.addFilter" />
            </MoovyLink>
            <Segment secondary loading={groupsCountMutation.isPending}>
              <Grid>
                <Grid.Column floated="left" width={8} textAlign="left">
                  <FontAwesomeIcon
                    icon="bullseye-arrow"
                    size="2x"
                    style={{ verticalAlign: 'middle', marginRight: '10px' }}
                  />
                  <b>{totalCount}</b>{' '}
                  <FormattedMessage id="messageCenter.targetGroup.selector.segment.label.potentialCustomers" />
                </Grid.Column>
                <Grid.Column
                  floated="right"
                  width={8}
                  textAlign="right"
                  verticalAlign="middle"
                >
                  <MoovyLink bold onClick={updateCount}>
                    <FormattedMessage id="messageCenter.targetGroup.selector.segment.link.updateUserCount" />
                  </MoovyLink>
                </Grid.Column>
              </Grid>
            </Segment>
            <Button
              primary
              type="submit"
              loading={saveTargetMutation.isPending}
              disabled={saveTargetMutation.isPending}
            >
              <FormattedMessage id="messageCenter.targetGroup.button.createTargetGroup" />
            </Button>
          </Form>
        </>
      )}
    </Formik>
  )
}

export default TargetGroupCreator
