import { get, post } from '../../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/adyen/transferMoney${relativePath}`
    : `/administration/adyen/transferMoney${relativePath}`

const getTransferMoneyTypes = async (typeFilter) => {
  const result = typeFilter
    ? await get(createFullPath(`/types`, null), {
        typeFilter: typeFilter
      })
    : await get(createFullPath(`/types`, null))
  return result
}

const transferMoneyFromAccount = async (
  fromAccountRef,
  data,
  operatorRealm
) => {
  const result = await post(
    createFullPath(`/fromAccount/${fromAccountRef}`, operatorRealm),
    data
  )
  return result
}

const transferMoneyToAccount = async (toAccountRef, data, operatorRealm) => {
  const result = await post(
    createFullPath(`/toAccount/${toAccountRef}`, operatorRealm),
    data
  )
  return result
}

export default {
  getTransferMoneyTypes,
  transferMoneyFromAccount,
  transferMoneyToAccount
}
