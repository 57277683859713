import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Form, Header } from 'semantic-ui-react'
import * as yup from 'yup'

import locationsService from '../../../services/Locations'
import { isEmptyObject } from '../Management/ManagementUtils'
import StepNextButton from '../Management/StepNextButton'

export const LocationForm = ({ formValues, operatorRef, onDataCompleted }) => {
  const validationSchema = () => {
    return yup.object().shape({
      ref: yup.string().required('Cannot be empty')
    })
  }

  const [initialValues, setInitialValues] = useState({
    ref: ''
  })
  const [values, setValues] = useState(initialValues)
  const [locations, setLocations] = useState([])

  useEffect(() => {
    if (isEmptyObject(formValues)) {
      setValues(initialValues)
    } else {
      const newValues = { ...formValues }
      setValues({ ref: newValues.ref })
      setInitialValues({ ref: newValues.ref })
    }
  }, [formValues])

  useEffect(() => {
    if (operatorRef) {
      locationsService.fetchLocationsForOperator(operatorRef).then(
        (data) => {
          setLocations(
            data != null && data.content
              ? data.content.map((item) => ({
                  key: item.ref,
                  value: item.ref,
                  text: item.internalName
                    ? `${item.name} (${item.internalName})`
                    : item.name
                }))
              : []
          )
        },
        (error) => {
          setLocations([])
        }
      )
    } else {
      setLocations([])
    }
  }, [operatorRef])

  const onChange = (e, data) => {
    const { name, value } = data
    setValues({ ...values, [name]: value })
  }

  const onSubmitForm = () => {
    onDataCompleted(values, 'location')
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={(values, actions) => {
          onSubmitForm(values, actions)
        }}
        enableReinitialize
      >
        {({ errors, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <StepNextButton />
            <Header>Kohde</Header>
            <Form.Group widths="equal">
              <Form.Dropdown
                label="Valitse kohde"
                placeholder="Valitse kohde"
                name="ref"
                fluid
                search
                selection
                options={locations}
                value={values.ref}
                onChange={(event, data) => {
                  onChange(event, data)
                  setFieldValue(data.name, data.value)
                }}
                error={!!errors.ref}
                selectOnBlur={false}
              />
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default LocationForm
