import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

import { Dropdown } from 'semantic-ui-react'
import settlementRecipientService from '../../services/SettlementRecipients'
import useOperators from '../../hooks/useOperators'

const FinancesFilterOperator = ({ onChangeMandatorValue }) => {
  const intl = useIntl()
  const operators = useOperators()

  const [mandators, setMandators] = useState([])
  const [selectedOperatorRef, setSelectedOperatorRef] = useState(null)

  useEffect(() => {
    onChangeMandatorValue(undefined)
    if (selectedOperatorRef) {
      settlementRecipientService
        .fetchSettlementRecipientsByOperatorRefWithLiableAccount(
          selectedOperatorRef
        )
        .then(
          (data) => {
            setMandators(getMandators(data))
          },
          () => {
            setMandators([])
          }
        )
    } else {
      setMandators([])
    }
  }, [selectedOperatorRef])

  const getOperators = () => {
    return operators.data != null && operators.data.content != null
      ? operators.data.content.map((item) => ({
          key: item.ref,
          text: item.name,
          value: item.ref
        }))
      : []
  }

  const getMandators = (givenMandators) => {
    return givenMandators != null
      ? givenMandators.map((item) => ({
          key: item.ref,
          text: item.fullName,
          value: item.ref
        }))
      : []
  }

  return (
    <>
      <div>
        <label>
          <FormattedMessage id="financesFilterOperator.label.operator" />
        </label>
      </div>
      <Dropdown
        className="financial-operator"
        placeholder={intl.formatMessage({
          id: 'financesFilterOperator.label.operator'
        })}
        selection
        options={getOperators()}
        onChange={(e, data) => setSelectedOperatorRef(data.value)}
        selectOnBlur={false}
      />
      <div>
        <label>
          <FormattedMessage id="financesFilterOperator.label.settlementRecipient" />
        </label>
      </div>
      <Dropdown
        className="financial-operator"
        placeholder={intl.formatMessage({
          id: 'financesFilterOperator.label.settlementRecipient'
        })}
        selection
        options={mandators}
        onChange={(e, data) => onChangeMandatorValue(data.value)}
        selectOnBlur={false}
      />
    </>
  )
}
export default FinancesFilterOperator
