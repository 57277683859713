import { get, post, put } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/${relativePath}`
    : `/administration/${relativePath}`

const fetchLocation = (locationRef, operatorRealm) => {
  try {
    return get(createFullPath(`location/${locationRef}`, operatorRealm))
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchLocations = (operatorRealm) => {
  return get(createFullPath(`location`, operatorRealm), {
    size: 9999
  })
}

const fetchLocationsForOperator = (operatorRef) => {
  if (operatorRef) {
    try {
      return get(`/administration/operator/${operatorRef}/location`, {
        page: 0,
        size: 200
      })
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('No operatorRef found'))
}

const fetchLocationsByOperatorRealm = (operatorRealm) => {
  if (operatorRealm) {
    try {
      return get(`/operator/${operatorRealm}/location`, {
        page: 0,
        size: 200
      })
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('No operatorRealm found'))
}

const fetchLocationVouchers = (locationRef, operatorRealm, queryParams) => {
  if (locationRef) {
    try {
      return get(
        createFullPath(`location/${locationRef}/vouchers`, operatorRealm),
        queryParams
      )
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing locationRef for fetching vouchers'))
}

const fetchParkings = (locationRef, operatorRealm, query) => {
  // We have some extra params in query and sort is in two params, have to combine for backend.
  const queryParams = {
    ...query,
    beginString: null,
    endString: null,
    sort:
      query.sort && query.direction ? `${query.sort},${query.direction}` : null
  }
  return get(
    createFullPath(`location/${locationRef}/sessions`, operatorRealm),
    queryParams
  )
}

const fetchOngoingParking = (locationRef, queryParams, operatorRealm) => {
  return get(
    createFullPath(`location/${locationRef}/sessions/ongoing`, operatorRealm),
    queryParams
  )
}

const fetchEndedParking = (locationRef, queryParams, operatorRealm) => {
  return get(
    createFullPath(`location/${locationRef}/sessions/ended`, operatorRealm),
    queryParams
  )
}

const fetchServiceQuotas = (locationRef, operatorRealm) => {
  if (locationRef) {
    return get(
      createFullPath(`location/${locationRef}/serviceQuotas`, operatorRealm)
    )
  }
  return Promise.reject(Error('Missing locationRef for fetching quotas'))
}

const startParking = (data, operatorRealm) => {
  return post(
    createFullPath(`location/${data.location}/sessions/start`, operatorRealm),
    data
  )
}

const importLocation = (data) => {
  return post(createFullPath(`location/import`, null), data)
}

const fetchLocationServices = (locationRef, operatorRealm) => {
  if (locationRef) {
    return get(
      createFullPath(`location/${locationRef}/services`, operatorRealm)
    )
  }
  return Promise.reject(Error('Missing locationRef for fetching services'))
}

const fetchGeoJSON = (locationRef, operatorRealm) => {
  if (!locationRef) {
    throw new Error('Missing locationRef')
  }
  return get(createFullPath(`location/${locationRef}/geoJson`, operatorRealm))
}

const updateLocationData = (operatorRealm, locationRef, body) => {
  return put(
    createFullPath(`location/${locationRef}/update`, operatorRealm),
    body
  )
}

const updateLocationOperationalData = (operatorRealm, locationRef, body) => {
  return put(
    createFullPath(
      `location/${locationRef}/updateOperationalData`,
      operatorRealm
    ),
    body
  )
}

const updateDoorCode = (operatorRealm, locationRef, body) => {
  return put(
    createFullPath(`location/${locationRef}/doorCode`, operatorRealm),
    body
  )
}

const updateLocationCapacity = (locationRef, body) => {
  return put(createFullPath(`location/${locationRef}/locationCapacity`), body)
}

const updateLocationServicePriority = (locationRef, serviceRef, body) => {
  return put(
    createFullPath(`location/${locationRef}/service/${serviceRef}/update`),
    body
  )
}

const updateAutoCleanThreshold = (locationRef, body) => {
  return put(createFullPath(`location/${locationRef}/autoCleanThreshold`), body)
}

export default {
  fetchLocation,
  fetchLocations,
  fetchLocationsForOperator,
  fetchLocationVouchers,
  fetchParkings,
  fetchOngoingParking,
  fetchEndedParking,
  fetchLocationsByOperatorRealm,
  fetchLocationServices,
  fetchServiceQuotas,
  fetchGeoJSON,
  startParking,
  importLocation,
  updateLocationData,
  updateLocationOperationalData,
  updateDoorCode,
  updateLocationCapacity,
  updateLocationServicePriority,
  updateAutoCleanThreshold
}
