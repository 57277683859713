import { buttonOperatorEnum } from './index'

export const isObject = (item) => {
  return typeof item === 'object' && item !== null
}

export const getNextBiggestId = (data) => {
  if (data.length === 0) return 1

  return (
    data.reduce((prev, current) => (current.id > prev ? current.id : prev), 1) +
    1
  )
}

export const parseLevel = (structure, data, parentId, level) => {
  let index = 0
  Object.keys(data).forEach(function(key) {
    const findItem = Object.keys(data[key])[0]
    let operatorItem = findItem.startsWith('$')
    if (operatorItem) {
      level++
      structure.push({
        id: getNextBiggestId(structure),
        value: '',
        targetPath: '',
        operator: findItem,
        parentId: parentId
      })
      return parseLevel(structure, data[key][findItem], structure.length, level)
    } else {
      let value
      if (!isObject(data[key][findItem])) {
        value = data[key][findItem]
      }

      structure.push({
        id: getNextBiggestId(structure),
        value: value,
        targetPath: findItem,
        operator: '',
        parentId: parentId,
        itemOperator: (data[key].operator && data[key].operator) || '',
        activeButton: index < Object.keys(data).length - 1
      })
    }
    index++
  })

  return structure
}

export const parseRoot = (structure, data, level) => {
  let operator = Object.keys(data).find((item) => item.startsWith('$'))
  if (operator) {
    switch (operator) {
      case buttonOperatorEnum.AND:
        return parseRoot(structure, data[operator], level)
      case buttonOperatorEnum.OR:
        return parseRoot(structure, data[operator], level)
    }
  }

  Object.keys(data).forEach(function(key) {
    const findItem = Object.keys(data[key])[0]
    let operatorItem = findItem.startsWith('$')
    if (operatorItem) {
      structure.push({
        id: getNextBiggestId(structure),
        value: '',
        targetPath: '',
        operator: findItem,
        parentId: 0
      })
      structure = parseLevel(
        structure,
        data[key][findItem],
        structure.length,
        level
      )
    } else {
      let value
      if (!isObject(data[key][findItem])) {
        value = data[key][findItem]
      }

      structure.push({
        id: getNextBiggestId(structure),
        value: value,
        targetPath: findItem,
        itemOperator: (data[key].operator && data[key].operator) || '',
        parentId: 0
      })
    }
  })

  return structure
}

export const parseRow = (jsonStructure, treeData, item, level) => {
  return parseTree(jsonStructure, treeData, item.id, level + 1)
}

export const parseTree = (jsonStructure, treeData, parentId = 0, level = 0) => {
  const items = treeData.filter((item) => item.parentId === parentId)

  if (!items.length) {
    if (jsonStructure.length === 0) {
      treeData.forEach((item) => {
        let itemContent
        if (item.itemOperator) {
          itemContent = {
            [item.targetPath]: item.value,
            operator: item.itemOperator
          }
        } else {
          itemContent = { [item.targetPath]: item.value }
        }
        jsonStructure.push(itemContent)
      })
    }
    return jsonStructure
  }

  items.forEach((item) => {
    if (item.operator) {
      jsonStructure.push({
        [item.operator]: []
      })

      return parseRow(
        jsonStructure[jsonStructure.length - 1][item.operator],
        treeData,
        item,
        level
      )
    } else {
      let itemContent
      if (item.itemOperator) {
        itemContent = {
          [item.targetPath]: item.value,
          operator: item.itemOperator
        }
      } else {
        itemContent = { [item.targetPath]: item.value }
      }
      jsonStructure.push(itemContent)
    }
  })
  return jsonStructure
}
