import './VoucherTemplates.scss'

import { Button, Icon, Input, Label, Popup, Select } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  GrantedVoucherManageModal,
  MoovyButton,
  Toolbar,
  showMoovyToast
} from '../../components'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  useOperatorLocations,
  useOperatorRealmLocations
} from '../../hooks/useLocations'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import VoucherTemplateManageModal from './VoucherTemplateManageModal'
import VoucherTemplateTable from './VoucherTemplateTable'
import { getTotalPages } from '../../services/utils'
import { getVoucherTemplateDescription } from '../../services/VoucherTemplates'
import { showToastGrantedVoucherCreated } from '../../components/MoovyToast/GrantedVoucherToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useDebounce } from 'use-debounce'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useNavigate } from 'react-router-dom'
import useOperators from '../../hooks/useOperators'
import { voucherTemplateService } from '../../services'

const pageLimit = 20

const VoucherTemplates = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const operators = useOperators()
  const { operatorRealm } = useAuthInfo()
  const queryClient = useQueryClient()

  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [grantVoucherModal, setGrantVoucherModal] = useState(null)

  const [globalState, setGlobalState] = useGlobalUI({
    key: 'voucherTemplates',
    initialValue: {
      voucherDescription: '',
      selectedOperator: '',
      selectedLocation: '',
      active: true,
      activePage: 1,
      totalPages: 1
    }
  })

  const [voucherDescriptionDebounce] = useDebounce(
    globalState.voucherDescription,
    500
  )

  const query = useQuery({
    queryKey: [
      'voucherTemplates',
      voucherDescriptionDebounce,
      globalState.selectedOperator,
      globalState.selectedLocation,
      globalState.active,
      globalState.activePage
    ],
    queryFn: () =>
      voucherTemplateService.fetchVoucherTemplatesPaginated(operatorRealm, {
        description: globalState.voucherDescription,
        operatorRef: globalState.selectedOperator,
        locationRef: globalState.selectedLocation,
        active: globalState.active.toString(),
        page: String(globalState.activePage - 1),
        limit: pageLimit
      }),

    enabled: Boolean(
      !voucherDescriptionDebounce || voucherDescriptionDebounce.length > 2
    ),
    staleTime: 1000 * 60
  })

  // Might be a good idea to fetch operator users Operator entity and save it globally on login.
  // Then we could use the operator ref to fetch locations instead of realm and get rid of this query.
  const operatorRealmLocations = useOperatorRealmLocations(operatorRealm, {
    enabled: !!operatorRealm
  })

  const selectedOperatorLocations = useOperatorLocations(
    globalState.selectedOperator,
    {
      enabled: !!globalState.selectedOperator
    }
  )

  // Location data from different query depending if operator or superoperator
  const locationsData = operatorRealm
    ? operatorRealmLocations.data?.content ?? []
    : selectedOperatorLocations.data?.content ?? []

  const onSaveGrantedVoucher = (voucher) => {
    setGrantVoucherModal(false)
    showToastGrantedVoucherCreated(voucher)
  }

  const { mutate: createVoucherTemplate, ...createVoucherTemplateMutation } =
    useMutation({
      mutationFn: (voucherTemplate) =>
        voucherTemplateService.createVoucherTemplate(
          operators.data.content.find(
            (op) => op.ref === voucherTemplate.operatorRef
          ).realm,
          voucherTemplate
        ),
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ['voucherTemplates'] })
        setSelectedTemplate(null)
        showMoovyToast(
          intl.formatMessage({
            id: 'voucherTemplates.createVoucherTemplate.success'
          }),
          getVoucherTemplateDescription(res)
        )
      }
    })

  const { mutate: updateVoucherTemplate, ...updateVoucherTemplateMutation } =
    useMutation({
      mutationFn: (voucherTemplate) =>
        voucherTemplateService.updateVoucherTemplate(
          operators.data.content.find(
            (op) => op.ref === voucherTemplate.operatorRef
          ).realm,
          voucherTemplate.ref,
          voucherTemplate
        ),
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ['voucherTemplates'] })
        setSelectedTemplate(null)
        showMoovyToast(
          intl.formatMessage({
            id: 'voucherTemplates.updateVoucherTemplate.success'
          }),
          getVoucherTemplateDescription(res)
        )
      }
    })

  const saveVoucherTemplate = (voucherTemplate) => {
    const createNewVoucher = !voucherTemplate.ref
    if (createNewVoucher) {
      createVoucherTemplate(voucherTemplate)
    } else {
      updateVoucherTemplate(voucherTemplate)
    }
  }

  const onChangeInputVoucher = (value) => {
    setGlobalState({ ...globalState, voucherDescription: value, activePage: 1 })
  }

  const onChangeOperatorValue = (value) => {
    setGlobalState({
      ...globalState,
      selectedOperator: value,
      selectedLocation: null,
      activePage: 1
    })
  }

  const onChangeActiveValue = (value) => {
    setGlobalState({ ...globalState, active: value, activePage: 1 })
  }

  const onChangeLocationValue = (value) => {
    setGlobalState({ ...globalState, selectedLocation: value, activePage: 1 })
  }

  const ToolBarSearchFilter = (
    <div>
      <div className="toolbar-filters">
        <Input
          icon="search"
          iconPosition="left"
          placeholder={intl.formatMessage({
            id: 'voucherTemplates.toolbar.search.placeholder'
          })}
          value={globalState.voucherDescription}
          onChange={(e) => onChangeInputVoucher(e.target.value)}
        />
        <Select
          fluid
          loading={query.isInitialLoading}
          disabled={query.isInitialLoading}
          value={globalState.active}
          options={[
            {
              key: 'active',
              value: true,
              text: intl.formatMessage({
                id: 'voucherTemplates.statusOption.active'
              })
            },
            {
              key: 'inactive',
              value: false,
              text: intl.formatMessage({
                id: 'voucherTemplates.statusOption.inactive'
              })
            }
          ]}
          onChange={(e, data) => onChangeActiveValue(data.value)}
          selectOnBlur={false}
        />
        {!operatorRealm ? (
          <Select
            clearable
            placeholder={intl.formatMessage({
              id: 'voucherTemplates.toolbar.operator.placeholder'
            })}
            fluid
            loading={query.isInitialLoading}
            disabled={query.isInitialLoading}
            value={globalState.selectedOperator}
            search
            options={
              operators.data?.content?.map((item) => ({
                key: item.ref,
                text: item.name,
                value: item.ref
              })) ?? []
            }
            onChange={(e, data) => onChangeOperatorValue(data.value)}
            selectOnBlur={false}
          />
        ) : (
          ''
        )}
        <Select
          clearable
          placeholder={intl.formatMessage({
            id:
              operatorRealm || globalState.selectedOperator
                ? 'voucherTemplates.toolbar.location.placeholder'
                : 'voucherTemplates.toolbar.location.disabled.placeholder'
          })}
          fluid
          loading={operatorRealmLocations.isInitialLoading}
          disabled={
            query.isInitialLoading || operatorRealmLocations.isInitialLoading
          }
          search
          options={locationsData.map((location) => ({
            key: location.ref,
            value: location.ref,
            text: location.name
          }))}
          value={globalState.selectedLocation}
          onChange={(e, data) => onChangeLocationValue(data.value)}
          selectOnBlur={false}
        />
        <Button primary onClick={query.refetch}>
          <FormattedMessage id="voucherTemplates.toolbar.search" />
        </Button>
      </div>
      {voucherDescriptionDebounce && voucherDescriptionDebounce.length < 3 && (
        <Label basic color="red" pointing>
          <FormattedMessage id="voucherTemplates.toolbar.search.invalid" />
        </Label>
      )}
      <div />
    </div>
  )

  return (
    <>
      <Toolbar
        title={intl.formatMessage({ id: 'voucherTemplates.toolbar.title' })}
        content={ToolBarSearchFilter}
      />
      <div className="Admin--Page--Content">
        <div className="add-new-voucher-template-container">
          {!operatorRealm ? (
            <MoovyButton
              onClick={() =>
                setSelectedTemplate({
                  ref: '',
                  customDescription: '',
                  benefitType: '',
                  grantedDurationInMinutes: 0,
                  operator: { name: '', ref: '' },
                  salePrice: 0,
                  validLocationsDescription: '',
                  validityInMinutes: 0,
                  validityLocationRefs: []
                })
              }
            >
              <Icon name="ticket" />
              <FormattedMessage id="voucherTemplates.createVoucherTemplate" />
            </MoovyButton>
          ) : (
            ''
          )}
        </div>
        <VoucherTemplateTable
          query={query}
          voucherTemplates={query.data?.content ?? []}
          activePage={globalState.activePage}
          onPageChange={(e, { activePage }) =>
            setGlobalState({ ...globalState, activePage })
          }
          totalPages={getTotalPages(
            query.data?.pageable?.total,
            query.data?.pageable?.size
          )}
          onSelectedVoucherTemplate={(voucherTemplate) =>
            navigate(
              `/${!operatorRealm ? 'admin' : 'operator'}/voucherTemplates/${
                voucherTemplate.ref
              }`
            )
          }
          renderActions={(voucherTemplate) => (
            <>
              {!operatorRealm ? (
                <>
                  {voucherTemplate.active && (
                    <Popup
                      trigger={
                        <Button
                          basic
                          icon
                          onClick={() => setGrantVoucherModal(voucherTemplate)}
                        >
                          <FontAwesomeIcon
                            icon={['fa', 'hand-holding-dollar']}
                            style={{ color: '#2185CF' }}
                          />
                        </Button>
                      }
                      content={
                        <FormattedMessage id="voucherTemplates.button.popup.grantVoucher" />
                      }
                    />
                  )}
                  <Popup
                    trigger={
                      <Button
                        basic
                        icon
                        onClick={() => setSelectedTemplate(voucherTemplate)}
                      >
                        <Icon name="edit" color="blue" />
                      </Button>
                    }
                    content={
                      <FormattedMessage id="voucherTemplates.button.popup.edit" />
                    }
                  />
                </>
              ) : (
                ''
              )}
            </>
          )}
        />
      </div>
      {selectedTemplate && (
        <VoucherTemplateManageModal
          mutation={
            selectedTemplate?.ref
              ? updateVoucherTemplateMutation
              : createVoucherTemplateMutation
          }
          mutationDefaultErrorMsgId={
            selectedTemplate?.ref
              ? 'voucherTemplates.updateVoucherTemplate.error'
              : 'voucherTemplates.createVoucherTemplate.error'
          }
          voucherTemplate={selectedTemplate}
          operators={operators.data.content}
          onSaveVoucherTemplate={saveVoucherTemplate}
          onClose={() => {
            updateVoucherTemplateMutation.reset()
            createVoucherTemplateMutation.reset()
            setSelectedTemplate(null)
          }}
        />
      )}
      {grantVoucherModal && (
        <GrantedVoucherManageModal
          defaultTemplate={grantVoucherModal}
          onSaveGrantedVoucher={onSaveGrantedVoucher}
          onClose={() => {
            setGrantVoucherModal(null)
          }}
        />
      )}
    </>
  )
}

export default VoucherTemplates
