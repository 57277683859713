import './Finances.scss'

import React, { useState } from 'react'
import {
  calendarDateInputToBackendFormat,
  dateNowToCalendarInput
} from '../../services/utils'

import FinancesFilterDateRange from './FinancesFilterDateRange'
import FinancesPane from './FinancesPane'
import financesService from '../../services/Finances'
import { useIntl } from 'react-intl'

const ReportAdyenMoovyReturnedCreditLoss = () => {
  const intl = useIntl()
  const [startTime, setStartTime] = useState(dateNowToCalendarInput())
  const [endTime, setEndTime] = useState(dateNowToCalendarInput())

  return (
    <FinancesPane
      text={intl.formatMessage({
        id: 'finances.tab.reportAdyenMoovyReturnedCreditLoss.header'
      })}
      buttonCallback={() =>
        financesService.downloadAdyenMoovyReturnedCreditLossReport({
          from: calendarDateInputToBackendFormat(startTime),
          until: calendarDateInputToBackendFormat(endTime)
        })
      }
    >
      <FinancesFilterDateRange
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
      />
    </FinancesPane>
  )
}

export default ReportAdyenMoovyReturnedCreditLoss
