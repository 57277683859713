import * as yup from 'yup'

import { Accordion, Form, Header, Icon, Segment } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'

import { Formik } from 'formik'
import StepNextButton from './StepNextButton'
import { isEmptyObject } from './ManagementUtils'
import settlementRecipientService from '../../../services/SettlementRecipients'
import useOperators from '../../../hooks/useOperators'

export const OperatorForm = ({ formValues, onDataCompleted }) => {
  const validationSchema = () => {
    return yup.object().shape({
      realm: yup.string().required('Cannot be empty'),
      settlementRecipientName: yup.string().required('Cannot be empty')
    })
  }

  const [initialValues, setInitialValues] = useState({
    realm: '',
    settlementRecipientName: '',
    operatorRef: '',
    settlementRecipientRef: ''
  })
  const [values, setValues] = useState(initialValues)
  const [selectedOperatorRef, setSelectedOperatorRef] = useState('')
  const [settlementRecipients, setSettlementRecipients] = useState([])
  const [operatorInfoOpen, setOperatorInfoOpen] = useState(false)

  const operators = useOperators()
  const operatorsData =
    operators.data != null && operators.data.content != null
      ? operators.data.content
      : []

  const getOperatorOptions = () => {
    return operatorsData.length > 0
      ? operators.data.content.map((item) => ({
          key: item.ref,
          text: item.name,
          value: item.ref
        }))
      : []
  }

  useEffect(() => {
    if (isEmptyObject(formValues)) {
      setValues(initialValues)
    } else if (
      formValues.realm === undefined &&
      formValues.settlementRecipientName === undefined &&
      formValues.operatorRef === undefined &&
      formValues.settlementRecipientRef === undefined
    ) {
      const newValues = { ...formValues, ...initialValues }
      setValues({ ...newValues })
      setSelectedOperatorRef(newValues.operatorRef)
    } else {
      setValues({ ...formValues })
      setSelectedOperatorRef(formValues.operatorRef)
    }
  }, [formValues])

  useEffect(() => {
    if (selectedOperatorRef && getSettlementRecipientValue()) {
      setInitialValues({ ...values })
    }
  }, [operatorsData, settlementRecipients])

  const getOperatorValue = () => {
    if (values.operatorRef === undefined || values.operatorRef === '') {
      const foundOperator = operatorsData.find(
        (item) => item.realm === values.realm
      )
      return foundOperator ? foundOperator.ref : ''
    } else {
      return values.operatorRef
    }
  }

  const getSettlementRecipientValue = () => {
    if (
      values.settlementRecipientRef === undefined ||
      values.settlementRecipientRef === ''
    ) {
      const foundSettlementRecipient = settlementRecipients.find(
        (item) => item.text === values.settlementRecipientName
      )
      return foundSettlementRecipient ? foundSettlementRecipient.value : ''
    } else {
      return values.settlementRecipientRef
    }
  }

  useEffect(() => {
    if (selectedOperatorRef) {
      settlementRecipientService
        .fetchSettlementRecipientsByOperatorRef(selectedOperatorRef)
        .then(
          (data) => {
            setSettlementRecipients(
              data != null
                ? data.map((item) => ({
                    key: item.ref,
                    text: item.fullName,
                    value: item.ref
                  }))
                : []
            )
          },
          (error) => {
            setSettlementRecipients([])
          }
        )
    } else {
      setSettlementRecipients([])
    }
  }, [selectedOperatorRef])

  const onChange = (e, data, setFieldValue) => {
    const { name, value } = data

    if (name === 'realm') {
      onChangeRealm(data, setFieldValue)
    } else if (name === 'settlementRecipientName') {
      onChangeSettlementRecipientName(data)
    } else {
      setValues({ ...values, [name]: value })
    }
    setFieldValue(name, value)
  }

  const onChangeRealm = (data, setFieldValue) => {
    const { name, value } = data

    const seletectOperator = operatorsData.find((item) => item.ref === value)
    setValues({
      ...values,
      [name]: seletectOperator.realm,
      operatorRef: seletectOperator.ref,
      settlementRecipientName: ''
    })
    setFieldValue('settlementRecipientName', '')
    setSelectedOperatorRef(seletectOperator.ref)
  }

  const onChangeSettlementRecipientName = (data) => {
    const { name, value } = data

    const optionItem = data.options.find((item) => item.value === value)
    setValues({
      ...values,
      [name]: optionItem.text,
      settlementRecipientRef: optionItem.value
    })
  }

  const onSubmitForm = () => {
    let newValues = { ...values }
    newValues.operatorRef = getOperatorValue()
    newValues.settlementRecipientRef = getSettlementRecipientValue()
    onDataCompleted(newValues, 'operator')
  }

  const operatorInfo = () => {
    return (
      <Accordion styled fluid>
        <Accordion.Title
          onClick={() => setOperatorInfoOpen(!operatorInfoOpen)}
          active={operatorInfoOpen}
        >
          <Icon name="dropdown" />
          {operatorInfoOpen === true ? (
            <b>Uusien operaattoritietojen lisääminen</b>
          ) : (
            <b>Eikö tarvittavia operaattoritietoja löydy?</b>
          )}
        </Accordion.Title>
        <Accordion.Content active={operatorInfoOpen}>
          <Segment secondary>
            Jos haluamaasi operaattoria tai maksunsaajaa ei ole valittavissa,
            ota yhteys Moovy-kehitystiimiin. He lisäävät sinulle tarvittavat
            operaattoritiedot.
          </Segment>
        </Accordion.Content>
      </Accordion>
    )
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={(values, actions) => {
          onSubmitForm(values, actions)
        }}
        enableReinitialize={true}
      >
        {({ errors, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <StepNextButton />
            <Header>Operaattori</Header>
            <Form.Group widths="equal">{operatorInfo()}</Form.Group>
            <Form.Group widths="equal">
              <Form.Dropdown
                label="Valitse operaattori"
                placeholder="Valitse operaattori"
                name="realm"
                fluid
                search
                selection
                options={getOperatorOptions()}
                value={getOperatorValue()}
                onChange={(event, data) => {
                  onChange(event, data, setFieldValue)
                }}
                error={errors.realm ? true : false}
                selectOnBlur={false}
              />
              <Form.Dropdown
                label="Pysäköintimaksujen saajan nimi"
                placeholder="Valitse saaja"
                name="settlementRecipientName"
                fluid
                search
                selection
                options={settlementRecipients}
                value={getSettlementRecipientValue()}
                onChange={(event, data) => {
                  onChange(event, data, setFieldValue)
                }}
                error={errors.settlementRecipientName ? true : false}
                selectOnBlur={false}
              />
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default OperatorForm
