import { InvoiceOperationLogTypeEnum } from '../../../services/utils/DTOEnums'
import React from 'react'
import { Segment } from 'semantic-ui-react'

const TabAdyenWaiving = ({ invoice }) => {
  return (
    <>
      <Segment>
        {invoice?.operationLogs?.map(
          (item) =>
            item &&
            item.logType === InvoiceOperationLogTypeEnum.WAIVED && (
              <div key={item.ref}>{item.comment}</div>
            )
        )}
      </Segment>
    </>
  )
}

export default TabAdyenWaiving
