import { Button, Icon, Message, Modal } from 'semantic-ui-react'
import { FormattedMessage } from 'react-intl'
import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'

import FormAnonymousParking from './formAnonymousParking'
import FormMoovyParking from './formMoovyParking'
import { MutationErrorMessage } from '..'
import { parkingTypeEnum, serviceType } from '../../services/utils'
import locationService from '../../services/Locations'
import useAuthInfo from '../../hooks/useAuthInfo'

const ModalStartParking = ({
  modalType,
  customer,
  location,
  userHasActivePayment,
  open,
  onClose,
  mutation,
  onStartParking
}) => {
  const FORM_REFERENCE = 'formStartParking'
  const [allowedPlateRecognitionVehicle, setAllowedPlateRecognitionVehicle] =
    useState(true)

  const [isVehicleQueryLoading, setIsVehicleQueryLoading] = useState(false)
  const [selectedLocationRef, setSelectedLocationRef] = useState(location?.ref)
  const { operatorRealm } = useAuthInfo()

  const servicesQuery = useQuery({
    queryKey: ['locationServices', selectedLocationRef],
    queryFn: () =>
      locationService.fetchLocationServices(selectedLocationRef, operatorRealm),
    enabled: !!selectedLocationRef
  })

  useEffect(() => {
    setSelectedLocationRef(location?.ref)
  }, [location, open])

  const onCloseModal = () => {
    mutation.reset()
    setAllowedPlateRecognitionVehicle(true)
    onClose()
  }

  const startParking = (parkingData) => {
    onStartParking(parkingData)
  }

  const services =
    servicesQuery.data
      ?.filter((service) => service.type === serviceType.PAY_AS_YOU_GO)
      .sort((a, b) => a.tags.length - b.tags.length) ?? []

  return (
    <>
      <Modal open={open} onClose={onCloseModal} size="tiny">
        <Modal.Header>
          <FormattedMessage id="modalStartParking.header" />
        </Modal.Header>
        <Modal.Content>
          {modalType === parkingTypeEnum.MOOVY_PARKING && (
            <>
              {!userHasActivePayment && (
                <Message warning>
                  <Icon name="exclamation" />
                  <FormattedMessage id="modalStartParking.warning.noActivePayment" />
                </Message>
              )}
              {!allowedPlateRecognitionVehicle && (
                <Message warning>
                  <Icon name="exclamation" />
                  <FormattedMessage id="modalStartParking.warning.noCameraRecognition" />
                </Message>
              )}
            </>
          )}
          {servicesQuery.isError && (
            <Message warning>
              <Icon name="exclamation" />
              <FormattedMessage id="modalStartParking.warning.servicesError" />
            </Message>
          )}
          <MutationErrorMessage
            mutation={mutation}
            defaultErrorTextLangId="modalStartParking.action.error"
            messageNegative
          />
          {modalType === parkingTypeEnum.MOOVY_PARKING ? (
            <FormMoovyParking
              customerRef={customer?.ref}
              formReference={FORM_REFERENCE}
              onAllowedPlateRecognitionVehicle={(value) =>
                setAllowedPlateRecognitionVehicle(value)
              }
              onSubmit={startParking}
              onChangeLocation={(newLocation) =>
                setSelectedLocationRef(newLocation)
              }
              services={services}
              loading={servicesQuery.isFetching}
            />
          ) : (
            <FormAnonymousParking
              location={location}
              formReference={FORM_REFERENCE}
              onLoadingStateChange={setIsVehicleQueryLoading}
              services={services}
              onSubmit={startParking}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onCloseModal}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button
            type="submit"
            content={<FormattedMessage id="modalStartParking.button.start" />}
            primary
            loading={mutation.isPending || isVehicleQueryLoading}
            disabled={mutation.isPending || isVehicleQueryLoading}
            form={FORM_REFERENCE}
          />
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default ModalStartParking
