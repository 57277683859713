import React, { useEffect, useState } from 'react'
import {
  commonEnum,
  frontendParkingTypeEnum,
  sortColumn,
  tablePaginationDirections
} from '../../../services/utils'

import { DateTime } from 'luxon'
import ParkingEndedContent from './parkingEndedContent'
import ParkingEndedFilters from './parkingEndedFilters'
import ParkingModal from '../../../containers/ParkingModal'
import { getTotalPages } from '../../../services/utils/tablePagination'
import locationService from '../../../services/Locations'
import moovyDateTime from '../../../services/utils/moovyDateTime'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

export const activeSearchTypeEnum = {
  SEARCH_BY_LICENSE_PLATE_NUMBER: 'SEARCH_BY_LICENSE_PLATE_NUMBER',
  SEARCH_BY_DATE_RANGE: 'SEARCH_BY_DATE_RANGE'
}

const ParkingEndedTab = ({ location }) => {
  const { operatorRealm } = useAuthInfo()

  const [viewRefreshed, setViewRefreshed] = useState(false)
  const [selectedParkingRef, setSelectedParkingRef] = useState()
  const [initiallySelectedParkingRef, setInitiallySelectedParkingRef] =
    useState(null)

  const pageLimit = 50

  const [activeSearchButtonType, setActiveSearchButtonType] = useState(
    activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER
  )

  const initialDateTime = DateTime.local().setLocale('fi')

  const [state, setState] = useState({
    partialLicensePlateNumber: '',
    parkingType: frontendParkingTypeEnum.ALL,
    serviceType: commonEnum.ALL,
    timeRange: {
      startTime: initialDateTime.startOf('day').toJSDate(),
      endTime: initialDateTime.endOf('day').toJSDate(),
      valid: true
    },
    activePage: 1,
    sort: 'startTime',
    direction: tablePaginationDirections.DESC
  })

  const getQueryParams = () => {
    return {
      licensePlateNumber:
        activeSearchButtonType ===
        activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER
          ? state.partialLicensePlateNumber
          : undefined,
      type:
        state.parkingType === frontendParkingTypeEnum.ALL
          ? undefined
          : state.parkingType,
      serviceType:
        state.serviceType === commonEnum.ALL ? undefined : state.serviceType,
      startTime: moovyDateTime.dateTimeToBackendFormat(
        DateTime.fromJSDate(state.timeRange.startTime)
      ),
      endTime: moovyDateTime.dateTimeToBackendFormat(
        DateTime.fromJSDate(state.timeRange.endTime)
      ),
      page: state.activePage - 1,
      sort: state.sort,
      direction: state.direction,
      limit: pageLimit
    }
  }

  useEffect(() => {
    viewRefreshed && refresh()
  }, [state.direction, state.sort, state.activePage])

  const endedParkingsQuery = useQuery({
    queryKey: ['endedParkingsQuery'],
    queryFn: () => {
      return locationService.fetchEndedParking(
        location?.ref,
        getQueryParams(),
        operatorRealm
      )
    },
    enabled: false
  })

  const totalPages = endedParkingsQuery?.data?.pageable
    ? getTotalPages(endedParkingsQuery.data.pageable.total, pageLimit)
    : 0

  const refresh = () => {
    if (!location || !state.timeRange.valid) return
    switch (activeSearchButtonType) {
      case activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER:
        if (state.partialLicensePlateNumber) {
          setViewRefreshed(true)
          endedParkingsQuery.refetch()
        }
        break
      case activeSearchTypeEnum.SEARCH_BY_DATE_RANGE:
        setViewRefreshed(true)
        endedParkingsQuery.refetch()
        break
    }
  }

  const closeParkingModal = () => {
    setInitiallySelectedParkingRef(undefined)
    setSelectedParkingRef(undefined)
  }

  const setSort = (column, direction) => {
    switch (activeSearchButtonType) {
      case activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER:
        if (state.partialLicensePlateNumber) {
          setState({
            ...state,
            sort: column,
            direction
          })
          setViewRefreshed(true)
        }
        break
      case activeSearchTypeEnum.SEARCH_BY_DATE_RANGE:
        setState({
          ...state,
          sort: column,
          direction
        })
        setViewRefreshed(true)
        break
    }
  }

  return (
    <>
      <ParkingEndedFilters
        updatedAt={endedParkingsQuery.dataUpdatedAt}
        viewRefreshed={viewRefreshed}
        loading={endedParkingsQuery.isFetching}
        resetView={() => setViewRefreshed(false)}
        onSearch={() => refresh()}
        values={state}
        setValues={(values) => setState({ ...state, ...values })}
        activeSearchButtonType={activeSearchButtonType}
        setActiveSearchButtonType={setActiveSearchButtonType}
      />
      <ParkingEndedContent
        parkingsQuery={endedParkingsQuery}
        activePage={state.activePage}
        noContent={!viewRefreshed}
        onPageChange={(activePage) => {
          setState({ ...state, activePage })
          setViewRefreshed(true)
        }}
        totalPages={totalPages}
        sorting={{
          column: state.sort,
          direction: state.direction
        }}
        initiallySelectedParkingRef={initiallySelectedParkingRef}
        onClickParking={(parking) => {
          setSelectedParkingRef(parking.ref)
          setInitiallySelectedParkingRef(parking.ref)
        }}
        onSort={(clickedColumn) =>
          sortColumn(
            clickedColumn,
            { column: state.sort, direction: state.direction },
            setSort
          )
        }
      />
      <ParkingModal
        parkingRef={selectedParkingRef}
        barrierlessFacility={location?.barrierlessFacility}
        onClose={closeParkingModal}
        onClickParkingSequence={(ref) => setSelectedParkingRef(ref)}
        whenParkingUpdated={() => refresh()}
      />
    </>
  )
}

export default ParkingEndedTab
