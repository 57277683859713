import { Button, Form, Header, Message, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import CreateCustomInvoiceRowForm from './CreateCustomInvoiceRowForm'
import CreateCustomInvoiceRowTable from './CreateCustomInvoiceRowTable'
import PropTypes from 'prop-types'
import SelectProduct from './SelectProduct'
import { fetchAllProducts } from '../../../services/Products'
import settlementRecipientsService from '../../../services/SettlementRecipients'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

export const CreateInvoiceModal = ({
  open,
  invoiceRows,
  onRowDelete,
  selectedProduct,
  onSelectedProduct,
  rowValues,
  onChangeRowValue,
  onAddRow,
  onClose,
  rowCreationDisabled,
  message,
  onCreate,
  isSubmitting
}) => {
  const intl = useIntl()
  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const [settlementRecipientRef, setSettlementRecipientRef] = useState('')

  const allProductsQuery = useQuery({
    queryKey: ['fetchAllProducts'],
    queryFn: () => fetchAllProducts(operatorRealm),
    enabled: !!open
  })

  const settlemenRecipientProducts = allProductsQuery.data
    ? allProductsQuery.data
        .filter(
          (p) =>
            (isSuperOperator &&
              p?.settlementRecipient?.ref === settlementRecipientRef) ||
            !isSuperOperator
        )
        .sort((a, b) => a.name.localeCompare(b.name))
    : []

  const recipientsQuery = useQuery({
    queryKey: ['fetchSettlementRecipients'],
    queryFn: () => {
      return settlementRecipientsService.fetchSettlementRecipientNames()
    },
    enabled: isSuperOperator
  })

  const getProducts = () => {
    return settlemenRecipientProducts.map((item) => ({
      key: item.ref,
      text: item.name,
      value: item.ref
    }))
  }

  const getSettlementRecipients = () => {
    return recipientsQuery.data
      ? recipientsQuery.data.map((item) => ({
          key: item.ref,
          text: item.fullName,
          value: item.ref
        }))
      : []
  }

  return (
    <Modal open={open} onClose={() => onClose()}>
      <Modal.Header>
        <FormattedMessage id="createInvoiceModal.header" />
      </Modal.Header>
      <Modal.Content>
        {invoiceRows.length > 0 && (
          <CreateCustomInvoiceRowTable
            rows={invoiceRows}
            onRowDelete={onRowDelete}
            isSubmitting={isSubmitting}
          />
        )}
        <Header />
        <Form>
          <Form.Group widths="equal">
            {isSuperOperator && (
              <Form.Dropdown
                label={intl.formatMessage({
                  id: 'createInvoiceModal.form.label.settlementRecipient'
                })}
                placeholder={intl.formatMessage({
                  id: 'createInvoiceModal.form.placholder.settlementRecipient'
                })}
                value={settlementRecipientRef}
                search
                selection
                options={getSettlementRecipients()}
                onChange={(e, data) => {
                  setSettlementRecipientRef(data.value)
                  onSelectedProduct(null)
                }}
                error={!!!settlementRecipientRef}
                loading={recipientsQuery.isLoading}
                disabled={recipientsQuery.isLoading}
                selectOnBlur={false}
              />
            )}
            <SelectProduct
              value={selectedProduct?.ref}
              productOptions={getProducts()}
              onChange={(e, { value }) =>
                onSelectedProduct(
                  settlemenRecipientProducts.find((item) => item.ref === value)
                )
              }
            />
          </Form.Group>
        </Form>
        <CreateCustomInvoiceRowForm
          onSubmit={onAddRow}
          values={rowValues}
          onChange={onChangeRowValue}
          disabled={rowCreationDisabled || isSubmitting}
        />
        {message && (
          <Message
            info={message.type === 'info'}
            error={message.type === 'error'}
          >
            {message.message}
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => onClose()}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          primary
          onClick={onCreate}
          loading={isSubmitting}
          disabled={invoiceRows.length === 0 || isSubmitting}
        >
          <FormattedMessage id="createInvoiceModal.button.sendInvoice" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

CreateInvoiceModal.propTypes = {
  open: PropTypes.bool,
  invoiceRows: PropTypes.arrayOf(PropTypes.shape({}).isRequired),
  onRowDelete: PropTypes.func.isRequired,
  selectedProduct: PropTypes.shape({ ref: PropTypes.string.isRequired }),
  productOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  rowValues: PropTypes.shape({}),
  onChangeRowValue: PropTypes.func.isRequired,
  onAddRow: PropTypes.func.isRequired,
  rowCreationDisabled: PropTypes.bool,
  message: PropTypes.shape({
    type: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
  }),
  onCreate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool
}

export default CreateInvoiceModal
