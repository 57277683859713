import './Finances.scss'

import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dropdown } from 'semantic-ui-react'

import useOperators from '../../hooks/useOperators'
import financesService from '../../services/Finances'
import {
  calendarDateInputToBackendFormat,
  dateNowToCalendarInput
} from '../../services/utils'
import FinancesFilterDateRange from './FinancesFilterDateRange'
import FinancesPane from './FinancesPane'

const ReportInfoKiosk = () => {
  const intl = useIntl()
  const operators = useOperators()

  const [startTime, setStartTime] = useState(dateNowToCalendarInput())
  const [endTime, setEndTime] = useState(dateNowToCalendarInput())
  const [operatorRealm, setOperatorRealm] = useState(null)

  const getOperators = () => {
    return operators.data != null && operators.data.content != null
      ? operators.data.content.map((item) => ({
          key: item.ref,
          text: item.name,
          value: item.ref
        }))
      : []
  }

  const onChangeOperatorValue = (value) => {
    if (value) {
      setOperatorRealm(
        operators.data.content.find((op) => op.ref === value).realm
      )
    } else {
      setOperatorRealm(null)
    }
  }

  return (
    <FinancesPane
      extraContent={
        <>
          <div>
            <label>
              <FormattedMessage id="finances.tab.reportInfoKiosk.label.operator" />
            </label>
          </div>
          <Dropdown
            className="financial-operator"
            placeholder={intl.formatMessage({
              id: 'finances.tab.reportInfoKiosk.label.operator'
            })}
            selection
            clearable
            options={getOperators()}
            onChange={(e, data) => onChangeOperatorValue(data.value)}
            selectOnBlur={false}
          />
        </>
      }
      text={intl.formatMessage({
        id: 'finances.tab.reportInfoKiosk.header'
      })}
      buttonCallback={() =>
        financesService.downloadInfoKioskReport({
          from: calendarDateInputToBackendFormat(startTime),
          until: calendarDateInputToBackendFormat(endTime),
          operatorRealm: operatorRealm
        })
      }
    >
      <FinancesFilterDateRange
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
      />
    </FinancesPane>
  )
}

export default ReportInfoKiosk
