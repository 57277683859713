import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  Grid,
  Icon,
  Message,
  Popup
} from 'semantic-ui-react'

import { Toolbar } from '../../components'
import { LocalizedWebpaymentStatus } from '../../components/MoovyLocalizedEnum'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import { formatDateAndTime } from '../../services/utils'
import { webpayment } from '../../services/utils/DTOEnums'
import ModalCancelWebPayment from './ModalCancelWebPayment'

const CANCEL_STATE_WEBPAYMENT = 'webpayment'
const CANCEL_STATE_DEBT_COLLECTION = 'debtCollection'

const ParkingToolbar = ({
  query,
  parkingCarouselData,
  state,
  isSuperOperator,
  hasWebpaymentEdit,
  hasDebtCollectionEdit,
  moveToDebtCollection,
  revokeDebtCollection,
  cancelNotPaidWebpayment,
  loading
}) => {
  const [cancelState, setCancelState] = useState('')
  const parking = query.data ? query.data : {}
  const title = (
    <FormattedMessage
      id="parkingToolbar.title.parking"
      values={{
        licencePlateNumber: parking.licensePlateNumber ?? ''
      }}
    />
  )
  const intl = useIntl()

  const breadcrumbs = createBreadcrumbs([
    { text: intl.formatMessage({ id: 'common.breadcrumbs.home' }), href: '/' },
    { text: title, active: true }
  ])

  const ParkingDetails = () => {
    if (query.isLoading) {
      return <p>....</p>
    }

    const { location } = query.data

    const statusColor = {
      WEBPAYMENT_FAILED: 'red',
      DEBT_COLLECTION_FAILED: 'red',
      WEBPAYMENT_SUCCESSFUL: 'green',
      DEBT_COLLECTION_DONE: 'green',
      DEBT_COLLECTION_REVOKED: 'green'
    }

    const statusIcon = {
      WEBPAYMENT_FAILED: 'exclamation triangle',
      DEBT_COLLECTION_FAILED: 'exclamation triangle',
      WEBPAYMENT_SUCCESSFUL: 'check circle',
      DEBT_COLLECTION_DONE: 'check circle',
      DEBT_COLLECTION_REVOKED: 'check circle'
    }

    return (
      <>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={5}>
              <b>
                <FormattedMessage id="parkingToolbar.label.location" />
              </b>
              <br />
              <RelativeLinkForCurrentUser to={`locations/${location.ref}`}>
                {location.name}
              </RelativeLinkForCurrentUser>
            </Grid.Column>
            {parkingCarouselData && parkingCarouselData.webPaymentSaleState && (
              <Grid.Column width={11}>
                <b>
                  <FormattedMessage id="parkingToolbar.label.paymentStatus" />
                </b>
                <br />
                {statusColor[
                  parkingCarouselData.webPaymentSaleState.status
                ] && (
                  <Icon
                    color={
                      statusColor[
                        parkingCarouselData.webPaymentSaleState.status
                      ]
                    }
                    name={
                      statusIcon[parkingCarouselData.webPaymentSaleState.status]
                    }
                  />
                )}

                <LocalizedWebpaymentStatus
                  value={parkingCarouselData.webPaymentSaleState.status}
                />
                <Grid style={{ marginTop: '0px' }}>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <FormattedMessage id="parkingToolbar.status.sentToPayment" />
                      <br />
                      <b>
                        {formatDateAndTime(
                          parkingCarouselData.webPaymentSaleState.sendTime
                        )}
                      </b>
                    </Grid.Column>
                    <Grid.Column>
                      <FormattedMessage id="parkingToolbar.status.handledPayment" />
                      <br />
                      <b>
                        {formatDateAndTime(
                          parkingCarouselData.webPaymentSaleState.resolveTime
                        )}
                      </b>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </>
    )
  }

  const WebPaymentActionButtons = ({ status, webPaymentSaleStateRef }) => {
    switch (status) {
      case webpayment.webpaymentStatus.WEBPAYMENT_PENDING:
        return hasWebpaymentEdit ? (
          <ButtonGroup style={{ marginTop: '40px', marginRight: '50px' }}>
            <Button
              color="red"
              onClick={() => setCancelState(CANCEL_STATE_WEBPAYMENT)}
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="parkingToolbar.button.cancelPayment" />
            </Button>
          </ButtonGroup>
        ) : null
      case webpayment.webpaymentStatus.WEBPAYMENT_FAILED:
      case webpayment.webpaymentStatus.DEBT_COLLECTION_REQUIRES_MANUAL_STEP:
        return (
          <ButtonGroup style={{ marginTop: '40px', marginRight: '50px' }}>
            {hasWebpaymentEdit && (
              <Button
                style={{ marginRight: '20px' }}
                color="red"
                onClick={() => setCancelState(CANCEL_STATE_WEBPAYMENT)}
                disabled={loading}
                loading={loading}
              >
                <FormattedMessage id="parkingToolbar.button.cancelPayment" />
              </Button>
            )}

            {hasDebtCollectionEdit && (
              <Button
                primary
                onClick={() => moveToDebtCollection(webPaymentSaleStateRef)}
                disabled={loading}
                loading={loading}
              >
                <FormattedMessage id="parkingToolbar.button.takeDebtCollection" />
              </Button>
            )}
          </ButtonGroup>
        )
      case webpayment.webpaymentStatus.MOVED_TO_DEBT_COLLECTION:
        return hasDebtCollectionEdit ? (
          <ButtonGroup style={{ marginTop: '40px', marginRight: '50px' }}>
            <Button
              color="red"
              onClick={() => setCancelState(CANCEL_STATE_DEBT_COLLECTION)}
              disabled={loading}
              loading={loading}
            >
              <FormattedMessage id="parkingToolbar.button.revoke" />
            </Button>
          </ButtonGroup>
        ) : null
      default:
        return ''
    }
  }

  return (
    <>
      <Grid>
        <Grid.Column width={10}>
          <Toolbar
            title={title}
            breadcrumbs={breadcrumbs}
            content={
              query.isError ? (
                <Message warning>
                  <FormattedMessage id="parkingToolbar.loading.error" />
                </Message>
              ) : (
                <ParkingDetails />
              )
            }
          />
        </Grid.Column>
        {parkingCarouselData && (
          <Grid.Column width={6} textAlign="right">
            <Grid.Row>
              <ButtonGroup style={{ marginTop: '30px', marginRight: '50px' }}>
                <Link
                  to={
                    isSuperOperator
                      ? `/admin/parking/${parkingCarouselData.previousParkingRef}`
                      : `/operator/parking/${parkingCarouselData.previousParkingRef}`
                  }
                  state={state}
                >
                  <Button icon labelPosition="left">
                    <Icon name="left arrow" />
                    <FormattedMessage id="parkingToolbar.button.previous" />
                  </Button>
                </Link>
                <Link
                  to={isSuperOperator ? '/admin/sales' : '/operator/sales'}
                  state={{ refreshSales: true }}
                >
                  <Popup
                    content={
                      <FormattedMessage id="parkingToolbar.popup.backToWebPayment" />
                    }
                    trigger={
                      <Button>
                        {parkingCarouselData.currentIndex + 1} /{' '}
                        {parkingCarouselData.totalResults}
                      </Button>
                    }
                  />
                </Link>
                <Link
                  to={
                    isSuperOperator
                      ? `/admin/parking/${parkingCarouselData.nextParkingRef}`
                      : `/operator/parking/${parkingCarouselData.nextParkingRef}`
                  }
                  state={state}
                >
                  <Button icon labelPosition="right">
                    <FormattedMessage id="parkingToolbar.button.next" />
                    <Icon name="right arrow" />
                  </Button>
                </Link>
              </ButtonGroup>
            </Grid.Row>
            <Grid.Row>
              <WebPaymentActionButtons
                status={parkingCarouselData.webPaymentSaleState.status}
                webPaymentSaleStateRef={
                  parkingCarouselData.webPaymentSaleState.ref
                }
              />
            </Grid.Row>
          </Grid.Column>
        )}
      </Grid>

      <ModalCancelWebPayment
        open={!!cancelState}
        cancellingDebtCollection={cancelState === CANCEL_STATE_DEBT_COLLECTION}
        initialLicensePlate={parking.licensePlateNumber}
        onClose={() => setCancelState('')}
        onSubmit={(reason) => {
          if (cancelState === CANCEL_STATE_DEBT_COLLECTION) {
            revokeDebtCollection({
              invoiceRef: parkingCarouselData.invoice.ref,
              reason
            })
          } else {
            cancelNotPaidWebpayment({
              reason,
              ref: parkingCarouselData.sale.ref
            })
          }
          setCancelState('')
        }}
      />
    </>
  )
}

export default ParkingToolbar
