/**
 * Validate time
 *
 * Validate format of given time string.
 * Valid formats: HH:MM, H:MM
 */
export function validateTime(time) {
  return /^\d\d:\d\d$/.test(time) || /^\d:\d\d$/.test(time)
}

export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}
