import { invoicingStatus, sale } from '../../services/utils'

const validateInvoiceRowRevert = (current, invoiceRow) => {
  return current > 0 && current <= invoiceRow.maximumRevertAmount
}

const isCardPayment = (invoice) =>
  invoice.paymentMethodType ===
  sale.paymentMethodTypes.PAYMENT_HIGHWAY_TRANSACTION

const isWebPayment = (invoice) =>
  invoice.paymentMethodType === sale.paymentMethodTypes.WEBPAYMENT

const isVismaPayment = (invoice) =>
  invoice.paymentMethodType === sale.paymentMethodTypes.VISMA

const isCreditNotePossible = (invoice, invoiceReverts) =>
  isVismaPayment(invoice) &&
  invoice.totalPrice > 0 &&
  (!invoiceReverts || invoiceReverts.length === 0) &&
  (invoice.status === invoicingStatus.INVOICED ||
    invoice.status === invoicingStatus.IN_PROGRESS ||
    invoice.status === invoicingStatus.PAUSED)

const isReceiptSendingPossible = (invoice) =>
  (isCardPayment(invoice) || isWebPayment(invoice)) &&
  invoice.status === invoicingStatus.INVOICED &&
  (invoice.totalPrice > 0 ||
    (invoice.totalPrice < 0 && invoice.compensatedInvoice))

const isCardPaymentRetryable = (invoice, invoiceReverts) =>
  isCardPayment(invoice) &&
  invoice.totalPrice > 0 &&
  (!invoiceReverts || invoiceReverts.length === 0) &&
  (invoice.status === invoicingStatus.PAYMENT_FAILED ||
    invoice.status === invoicingStatus.PERMANENTLY_FAILED)

const isCardPaymentRevertable = false

const isSetAsWaivedAllowed = (invoice) =>
  (invoice.paymentMethodType ===
    sale.paymentMethodTypes.PAYMENT_HIGHWAY_TRANSACTION ||
    invoice.paymentMethodType === sale.paymentMethodTypes.ADYEN_TRANSACTION) &&
  (invoice.status === invoicingStatus.PAYMENT_FAILED ||
    invoice.status === invoicingStatus.PERMANENTLY_FAILED)

export {
  validateInvoiceRowRevert,
  isCreditNotePossible,
  isCardPayment,
  isVismaPayment,
  isReceiptSendingPossible,
  isCardPaymentRetryable,
  isCardPaymentRevertable,
  isSetAsWaivedAllowed
}
