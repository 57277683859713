import 'moment/locale/fi'
import 'semantic-ui-css/semantic.min.css'
import './index.scss'

import * as Sentry from '@sentry/react'

import App from './app'
import { BrowserRouter } from 'react-router-dom'
import { Integrations } from '@sentry/tracing'
import IntlContainer from './containers/IntlContainer'
import { MoovyErrorBoundary } from './components'
import { Provider } from 'react-redux'
import React from 'react'
import { createRoot } from 'react-dom/client'
import store from './redux/store'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const { REACT_APP_RAVEN_URL, REACT_APP_ENV } = process.env

// Set up sentry
Sentry.init({
  dsn: REACT_APP_RAVEN_URL,
  environment: REACT_APP_ENV,
  beforeSend(ev) {
    if (REACT_APP_ENV === 'local-dev') {
      return null
    }
    return ev
  },
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
})

const container = document.getElementById('root')
const root = createRoot(container)

const queryClient = new QueryClient()

root.render(
  <Provider store={store}>
    <IntlContainer>
      <BrowserRouter>
        <MoovyErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools />
          </QueryClientProvider>
        </MoovyErrorBoundary>
      </BrowserRouter>
    </IntlContainer>
  </Provider>
)
