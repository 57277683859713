import {
  Button,
  Dimmer,
  Header,
  List,
  Loader,
  Modal,
  Ref,
  Segment,
  Sidebar,
  Tab
} from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyIconInfo,
  QueryContainer,
  showLocalizedMoovyToast,
  VoucherModal
} from '../../components'
import React, { useEffect, useRef, useState } from 'react'
import {
  formatDateAndTime,
  formatPrice,
  formatSeconds,
  frontendParkingTypeEnum,
  getFormattedLocationName,
  parkingTypeEnum,
  sale
} from '../../services/utils'
import { useQueryClient, useQuery } from '@tanstack/react-query'

import AdditionalTabs from './AdditionalTabs'
import AnonymousParkingPaymentEvents from './AnonymousParkingPaymentEvents/AnonymousParkingPaymentEvents'
import EditAnonymousParking from './EditAnonymousParking/EditAnonymousParking'
import EditParking from './EditMoovyParking'
import { LocalizedFrontendParkingType } from '../../components/MoovyLocalizedEnum'
import ParkingSequence from './ParkingSequence/ParkingSequence'
import RelativeLinkForCurrentUser from '../RelativeLinkForCurrentUser'
import parkingService from '../../services/Parking'
import { renderUserName } from '../UserInfo'
import useAuthInfo from '../../hooks/useAuthInfo'

const ParkingModal = ({
  parkingRef,
  barrierlessFacility = false,
  onClose,
  onClickParkingSequence,
  whenParkingUpdated
}) => {
  const [parking, setParking] = useState({ loading: true, data: {} })
  const [activeTab, setActiveTab] = useState(0)
  const [anonymousParking, setAnonymousParking] = useState({})
  const [editMode, setEditMode] = useState(false)
  const [selectedVoucher, setSelectedVoucher] = useState(null)
  const [parkingRemainingPrice, setParkingRemainingPrice] = useState(null)
  const { operatorRealm } = useAuthInfo()
  const modalRef = useRef()
  const intl = useIntl()
  const queryClient = useQueryClient()

  const parkingSequence = useQuery({
    queryKey: ['parkingSequence', parkingRef],
    queryFn: () =>
      parkingService.fetchParkingSequence(parkingRef, operatorRealm),
    staleTime: 5 * 60000,
    enabled: !!parkingRef
  })

  const onUpdateParking = (updated) => {
    setParking({ loading: false, data: updated })
    setActiveTab(0)
    queryClient.invalidateQueries({ queryKey: ['parkingSequence', parkingRef] })
    if (whenParkingUpdated) {
      whenParkingUpdated()
    }
  }

  const getParkingType = (parkingData) => {
    if (parkingData?.type === parkingTypeEnum.MOOVY_PARKING) {
      return frontendParkingTypeEnum.MOOVY_PARKING
    } else if (parkingData?.type === parkingTypeEnum.ANONYMOUS_PARKING) {
      if (parkingData?.paymentMethod === sale.paymentMethodTypes.PARKMAN) {
        return frontendParkingTypeEnum.PARKMAN_PARKING
      }
      if (parkingData?.paymentMethod === sale.paymentMethodTypes.EASYPARK) {
        return frontendParkingTypeEnum.EASYPARK_PARKING
      }
      return frontendParkingTypeEnum.ANONYMOUS_PARKING
    }
  }

  useEffect(() => {
    // Reset active tab when parking changes
    setActiveTab(0)

    if (parkingRef) {
      parkingService
        .fetchParking(parkingRef, operatorRealm)
        .then((data) => {
          setParking({ loading: false, data })
          // Fetch some additional data for anonymousParkings.
          if (
            getParkingType(data) === frontendParkingTypeEnum.ANONYMOUS_PARKING
          ) {
            // TODO: PaymentEvents could be replaced with Sales. Still needed for receipt sending and showing infodata transaction
            parkingService
              .fetchAnonymousPaymentEvents(data.ref, operatorRealm)
              .then((anonymousData) => setAnonymousParking(anonymousData))
            parkingService
              .fetchRemainingPrice(data.ref, operatorRealm)
              .then(({ remainingPrice }) =>
                setParkingRemainingPrice(remainingPrice)
              )
          } else {
            setAnonymousParking({})
            setParkingRemainingPrice(null)
          }
        })
        .catch(() => {
          showLocalizedMoovyToast(intl, {
            title: 'parkingModal.fetchError.title',
            description: 'parkingModal.fetchError.description',
            type: 'error'
          })
          onClose()
        })
    }
  }, [parkingRef])

  const {
    price,
    canModify,
    licensePlateNumber,
    start,
    ongoing,
    end,
    location,
    user,
    durationSeconds,
    priceBeforeDiscounts
  } = parking.data
  const { endTime, events, exitBefore } = anonymousParking
  const frontendParkingType = getParkingType(parking.data)

  const totalPaid = events
    ? events.reduce((acc, event) => acc + event.paid, 0)
    : 0
  const sortedParkings =
    parkingSequence.data && parkingSequence.data.parkings
      ? parkingSequence.data.parkings.sort(
          (a, b) => new Date(a.startTime) - new Date(b.startTime)
        )
      : []

  const allPanes = [
    {
      menuItem: intl.formatMessage({
        id: 'parkingModal.tab.parkingSequence'
      }),
      render: () => (
        <Tab.Pane>
          {parkingSequence.data && (
            <List horizontal>
              <List.Item>
                <List.Header>Alkoi</List.Header>
                {formatDateAndTime(parkingSequence.data.startTime)}
              </List.Item>
              <List.Item>
                <List.Header>Päättyi</List.Header>
                {parkingSequence.data.endTime
                  ? formatDateAndTime(parkingSequence.data.endTime)
                  : 'Käynnissä'}
              </List.Item>
              <List.Item>
                <List.Header>Kesto</List.Header>
                {formatSeconds(parkingSequence.data.durationSeconds)}
              </List.Item>
            </List>
          )}
          <QueryContainer query={parkingSequence}>
            <ParkingSequence
              parkings={sortedParkings}
              activeRef={parkingRef}
              onRowClick={onClickParkingSequence}
            />
          </QueryContainer>
        </Tab.Pane>
      )
    },
    {
      menuItem: intl.formatMessage({
        id: 'parkingModal.tab.sales'
      }),
      render: () => (
        <Tab.Pane>
          <AdditionalTabs.SalesContainer
            parkingRef={parkingRef}
            parking={parking.data}
            operatorRealm={operatorRealm}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: intl.formatMessage({
        id: 'parkingModal.tab.vouchers'
      }),
      render: () => (
        <Tab.Pane>
          <AdditionalTabs.VouchersContainer
            parkingRef={parkingRef}
            operatorRealm={operatorRealm}
            onClickVoucher={setSelectedVoucher}
          />
          {selectedVoucher && (
            <VoucherModal
              voucher={selectedVoucher}
              operatorRealm={operatorRealm}
              onClose={() => setSelectedVoucher(null)}
            />
          )}
        </Tab.Pane>
      )
    },
    {
      menuItem: intl.formatMessage({
        id: 'parkingModal.tab.externalFacilityData'
      }),
      render: () => (
        <Tab.Pane>
          <AdditionalTabs.ExternalFacilityDataContainer
            parkingRef={parkingRef}
            operatorRealm={operatorRealm}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: intl.formatMessage({
        id: 'parkingModal.tab.paymentEvents'
      }),
      filterOutIf:
        frontendParkingType !== frontendParkingTypeEnum.ANONYMOUS_PARKING,
      render: () => (
        <Tab.Pane>
          <AnonymousParkingPaymentEvents events={events} />
        </Tab.Pane>
      )
    },
    {
      menuItem: intl.formatMessage({
        id: 'parkingModal.tab.auditLogs'
      }),
      render: () => (
        <Tab.Pane>
          <AdditionalTabs.ParkingAuditLogContainer
            parkingRef={parkingRef}
            operatorRealm={operatorRealm}
          />
        </Tab.Pane>
      )
    }
  ]

  const onCloseModal = () => {
    setEditMode(false)
    onClose()
  }

  const panes = allPanes.filter((pane) => !pane.filterOutIf)
  const canEdit =
    frontendParkingType !== frontendParkingTypeEnum.ANONYMOUS_PARKING
      ? frontendParkingType === frontendParkingTypeEnum.PARKMAN_PARKING
        ? canModify && ongoing
        : canModify
      : !endTime

  return (
    <Modal
      closeOnEscape={!editMode}
      onClose={onCloseModal}
      open={Boolean(parkingRef)}
      size="large"
    >
      <Modal.Header>
        <Header>
          <LocalizedFrontendParkingType value={frontendParkingType} />
        </Header>
      </Modal.Header>
      <Modal.Content>
        <Sidebar.Pushable as={Segment} raised>
          <Sidebar
            animation="push"
            visible={editMode}
            onHide={() => setEditMode(false)}
            width="wide"
            target={modalRef}
          >
            {frontendParkingType ===
            frontendParkingTypeEnum.ANONYMOUS_PARKING ? (
              <EditAnonymousParking
                parking={parking.data}
                barrierlessFacility={barrierlessFacility}
                onUpdate={onUpdateParking}
                onHide={() => setEditMode(false)}
                onAllowExit={() => {
                  const exitTime = new Date()
                  exitTime.setHours(exitTime.getHours() + 1)
                  queryClient.invalidateQueries({
                    queryKey: ['auditLog', parkingRef]
                  })
                  setAnonymousParking({
                    ...anonymousParking,
                    exitBefore: exitTime
                  })
                }}
              />
            ) : (
              <EditParking parking={parking.data} onUpdate={onUpdateParking} />
            )}
          </Sidebar>

          <Ref innerRef={modalRef}>
            <Sidebar.Pusher dimmed={editMode}>
              <Segment basic>
                {!parking.loading && (
                  <List>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="parkingModal.label.location" />
                      </List.Header>
                      <RelativeLinkForCurrentUser
                        to={`locations/${location.ref}`}
                      >
                        {getFormattedLocationName(location)}
                      </RelativeLinkForCurrentUser>
                    </List.Item>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="parkingModal.label.customer" />
                      </List.Header>
                      {frontendParkingType ===
                      frontendParkingTypeEnum.MOOVY_PARKING ? (
                        <RelativeLinkForCurrentUser
                          to={`customers/${user.ref}`}
                        >
                          {renderUserName(user)}
                        </RelativeLinkForCurrentUser>
                      ) : (
                        <LocalizedFrontendParkingType
                          value={frontendParkingType}
                        />
                      )}
                    </List.Item>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="parkingModal.label.licensePlateNumber" />
                      </List.Header>
                      {licensePlateNumber}
                    </List.Item>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="parkingModal.label.started" />
                      </List.Header>
                      {formatDateAndTime(start)}
                    </List.Item>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="parkingModal.label.ended" />
                      </List.Header>
                      {ongoing ? (
                        <FormattedMessage id="parkingModal.label.ongoing" />
                      ) : (
                        formatDateAndTime(end)
                      )}
                    </List.Item>
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="parkingModal.label.duration" />
                      </List.Header>
                      {formatSeconds(durationSeconds)}
                    </List.Item>
                    {frontendParkingType ===
                    frontendParkingTypeEnum.MOOVY_PARKING ? (
                      <List.Item>
                        <List.Header>
                          <FormattedMessage id="parkingModal.label.price" />
                        </List.Header>
                        {formatPrice(price)}
                        {price !== priceBeforeDiscounts && (
                          <span style={{ textDecoration: 'line-through' }}>
                            {formatPrice(priceBeforeDiscounts)}
                          </span>
                        )}
                      </List.Item>
                    ) : (
                      <>
                        <List.Item>
                          <List.Header>
                            <FormattedMessage id="parkingModal.label.price" />
                          </List.Header>
                          {formatPrice(totalPaid)}
                        </List.Item>
                        <List.Item>
                          <List.Header>
                            <MoovyIconInfo
                              iconLabel={
                                <FormattedMessage id="parkingModal.label.priceLeft" />
                              }
                            >
                              <FormattedMessage id="parkingModal.label.priceLeft.popup.title" />
                              <ul>
                                <li>
                                  <FormattedMessage id="parkingModal.label.priceLeft.popup.bullet1" />
                                </li>
                                <li>
                                  <FormattedMessage id="parkingModal.label.priceLeft.popup.bullet2" />
                                </li>
                              </ul>
                            </MoovyIconInfo>
                          </List.Header>
                          {formatPrice(parkingRemainingPrice)}
                          {price !== priceBeforeDiscounts && (
                            <span style={{ textDecoration: 'line-through' }}>
                              {formatPrice(priceBeforeDiscounts)}
                            </span>
                          )}
                        </List.Item>
                      </>
                    )}
                    <List.Item>
                      <List.Header>
                        <FormattedMessage id="parkingModal.label.sessionId" />
                      </List.Header>
                      {parkingRef}
                    </List.Item>
                    {frontendParkingType !==
                      frontendParkingType.MOOVY_PARKING &&
                      exitBefore && (
                        <List.Item>
                          <List.Header>
                            <FormattedMessage id="parkingModal.label.exitBefore" />
                          </List.Header>
                          {formatDateAndTime(exitBefore)}
                        </List.Item>
                      )}
                  </List>
                )}
                <Button
                  type="button"
                  style={{ marginBottom: '1em' }}
                  disabled={!canEdit}
                  onClick={() => setEditMode(true)}
                >
                  <FormattedMessage id="common.button.modify" />
                </Button>
                <Segment basic>
                  <Dimmer
                    active={parking.loading || parkingSequence.loading}
                    inverted
                  >
                    <Loader inverted content="Loading" />
                  </Dimmer>
                  <Tab
                    panes={panes}
                    activeIndex={activeTab}
                    onTabChange={(e, { activeIndex }) =>
                      setActiveTab(activeIndex)
                    }
                  />
                </Segment>
              </Segment>
            </Sidebar.Pusher>
          </Ref>
        </Sidebar.Pushable>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseModal}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ParkingModal
