import { Button, Form, Grid, Header, Icon, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import {
  dateTimeFromFormat,
  dateTimeNowToCalendarInput
} from '../../services/utils'

import { DateTimeInput } from 'semantic-ui-calendar-react'
import MoovyLink from '../../components/MoovyLink'
import { MutationErrorMessage } from '../../components'
import ReactDiffViewer from 'react-diff-viewer-continued'
import moovyDateTime from '../../services/utils/moovyDateTime'
import servicesService from '../../services/Services'
import { transform } from '../../services/utils/JSONTransformer'
import { useMutation } from '@tanstack/react-query'

const ModalEditValidityTimes = ({
  onClose,
  validityTimesAsString,
  onSubmit,
  mutation
}) => {
  const intl = useIntl()

  const originalJSONString = validityTimesAsString

  const [jsonString, setJsonString] = useState(validityTimesAsString)
  const [jsonError, setJsonError] = useState(false)

  const handleSubmit = () => {
    onSubmit(jsonString)
  }

  const numberFields = []
  const isJsonValid = (jsonStructure) => {
    if (!jsonStructure) return true
    try {
      JSON.stringify(
        transform(JSON.parse(jsonStructure), numberFields, true),
        null,
        2
      )
      return true
    } catch (error) {
      return false
    }
  }

  useEffect(() => {
    setJsonError(!isJsonValid(jsonString))
  }, [jsonString])

  const hasValidJson = jsonString && isJsonValid(jsonString)

  return (
    <Modal open onClose={onClose} size="large">
      <Modal.Header
        content={intl.formatMessage({
          id: 'modalEditValidityTimes.title'
        })}
      />
      <Modal.Content scrolling>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId="modalEditValidityTimes.edit.error"
          messageNegative
        />
        <Form onSubmit={handleSubmit}>
          <Form.Field error={jsonError}>
            <Form.TextArea
              value={jsonString}
              onChange={(e, { value }) => setJsonString(value)}
              rows={15}
            />
          </Form.Field>
        </Form>
        {originalJSONString !== jsonString ? (
          <>
            <Header as="h4">
              <FormattedMessage id="modalEditValidityTimes.diff.header" />
            </Header>
            <ReactDiffViewer
              oldValue={originalJSONString}
              newValue={jsonString}
              splitView
            />
          </>
        ) : (
          ''
        )}
        {hasValidJson && (
          <>
            <Header as="h4">
              <FormattedMessage id="modalEditValidityTimes.validityTest.header" />
            </Header>
            <TestServiceValidity validityTimes={JSON.parse(jsonString)} />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          autoFocus
          type="submit"
          primary
          onClick={handleSubmit}
          disabled={mutation.isPending || originalJSONString === jsonString}
          loading={mutation.isPending}
        >
          <FormattedMessage id="common.button.save" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const TestServiceValidity = ({ validityTimes }) => {
  const now = dateTimeNowToCalendarInput()
  const [tests, setTests] = useState([
    { id: 1, date: now },
    { id: 2, date: now },
    { id: 3, date: now }
  ])
  const mutation = useMutation({
    mutationFn: () => {
      return servicesService.testServiceValidityTimes({
        validityTimes,
        candidateTimes: tests.map((test) =>
          moovyDateTime.dateTimeToBackendFormat(
            // Keep localTime since ValidityPeriods start and end have no notion of timeZones
            dateTimeFromFormat(test.date).setZone('UTC', {
              keepLocalTime: true
            })
          )
        )
      })
    },
    onMutate: () => {
      setTests((prevState) =>
        prevState.map((test) => ({
          ...test,
          valid: null,
          invalid: null
        }))
      )
    },
    onSuccess: (data) => {
      setTests((prevState) =>
        prevState.map((test, index) => ({
          ...prevState[index],
          valid: data[index].valid
        }))
      )
    }
  })

  const onChangeDate = (value, id) => {
    setTests((prevState) =>
      prevState.map((element) =>
        element.id !== id
          ? element
          : {
              id: element.id,
              date: value
            }
      )
    )
  }
  return (
    <>
      <div style={{ marginBottom: '1em', maxWidth: '440px' }}>
        <Form>
          {mutation.isPending && <Icon loading name="spinner" />}
          {mutation.isError && (
            <Header
              size="tiny"
              content={
                mutation.error?.body?.errorDescriptor?.errorDescription?.fi ?? (
                  <FormattedMessage id="testServiceValidity.query.error" />
                )
              }
            />
          )}
          {tests.map((test) => (
            <ServiceValidityTester
              key={test.id}
              validityTimes={validityTimes}
              date={test.date}
              setDate={(value) => onChangeDate(value, test.id)}
              isValid={test.valid}
              isInvalid={test.valid === false}
            />
          ))}
        </Form>
      </div>
      <MoovyLink
        bold
        onClick={() =>
          setTests((previous) =>
            previous.concat({
              id: previous.length + 1,
              date: dateTimeNowToCalendarInput()
            })
          )
        }
      >
        <FormattedMessage id="testServiceValidity.button.addRow" />
      </MoovyLink>
      <Button
        style={{ marginTop: '1em', display: 'block' }}
        type="button"
        primary
        onClick={mutation.mutate}
      >
        <FormattedMessage id="testServiceValidity.button.test" />
      </Button>
    </>
  )
}

const ServiceValidityTester = ({ isValid, isInvalid, date, setDate }) => {
  const dateValid = dateTimeFromFormat(date).isValid

  return (
    <Grid container columns={2}>
      <Grid.Column>
        <DateTimeInput
          value={date}
          onChange={(ev, { value }) => {
            setDate(value)
          }}
          dateFormat="DD.MM.YYYY"
          iconPosition="left"
          animation="none"
          closable
          error={!dateValid}
        />
      </Grid.Column>
      <Grid.Column style={{ alignSelf: 'center' }}>
        {isValid && (
          <Header
            size="tiny"
            icon="check circle"
            color="green"
            content={<FormattedMessage id="testServiceValidity.serviceValid" />}
          />
        )}
        {isInvalid && (
          <Header
            size="tiny"
            icon="remove circle"
            color="red"
            content={
              <FormattedMessage id="testServiceValidity.serviceInvalid" />
            }
          />
        )}
      </Grid.Column>
    </Grid>
  )
}
export default ModalEditValidityTimes
