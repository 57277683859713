import { Formik } from 'formik'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Header, Tab } from 'semantic-ui-react'
import * as yup from 'yup'

import { messageCenterEnums } from '../../../services/utils/DTOEnums'
import { STEP_KEYS } from './CreateMessage'
import MessageContent from './MessageContent'

export const StepMessageContent = ({
  formValues,
  readOnly,
  loading,
  onDataCompleted,
  onChangeValue,
  copyToAllLanguages
}) => {
  const intl = useIntl()

  const isValidVideoUrl = (parent) => {
    return !parent.videoUrl || (parent.videoUrl && parent.imageUrl)
  }

  const isValidExternal = (parent) => {
    return (
      !parent.type ||
      parent.type !== messageCenterEnums.callToActionType.EXTERNAL ||
      parent.external
    )
  }

  const isValidInternal = (parent) => {
    return (
      !parent.type ||
      parent.type !== messageCenterEnums.callToActionType.INTERNAL ||
      parent.internal
    )
  }

  const isValidDescription = (parent) => {
    return !parent.type || (parent.description && parent.type)
  }

  const validationSchema = () => {
    return yup.object().shape({
      fi: yup.object().shape({
        title: yup.string().required('Cannot be empty'),
        imageUrl: yup.string().test('imageUrl', true, function() {
          return isValidVideoUrl(this.parent)
        }),
        callToAction: yup.object().shape({
          external: yup.string().test('external', true, function() {
            return isValidExternal(this.parent)
          }),
          internal: yup.string().test('internal', true, function() {
            return isValidInternal(this.parent)
          }),
          description: yup.string().test('description', true, function() {
            return isValidDescription(this.parent)
          })
        })
      }),
      en: yup.object().shape({
        imageUrl: yup.string().test('imageUrl', true, function() {
          return isValidVideoUrl(this.parent)
        }),
        callToAction: yup.object().shape({
          external: yup.string().test('external', true, function() {
            return isValidExternal(this.parent)
          }),
          internal: yup.string().test('internal', true, function() {
            return isValidInternal(this.parent)
          }),
          description: yup.string().test('description', true, function() {
            return isValidDescription(this.parent)
          })
        })
      }),
      sv: yup.object().shape({
        imageUrl: yup.string().test('imageUrl', true, function() {
          return isValidVideoUrl(this.parent)
        }),
        callToAction: yup.object().shape({
          external: yup.string().test('external', true, function() {
            return isValidExternal(this.parent)
          }),
          internal: yup.string().test('internal', true, function() {
            return isValidInternal(this.parent)
          }),
          description: yup.string().test('description', true, function() {
            return isValidDescription(this.parent)
          })
        })
      })
    })
  }

  const onChange = (e, data) => {
    const { name, value } = data
    updateValues(name, value)
  }

  const onChecked = (name, value) => {
    updateValues(name, value)
  }

  const updateValues = (name, value) => {
    onChangeValue(STEP_KEYS.MESSAGECONTENT, name, value)
  }

  const onSubmitForm = () => {
    onDataCompleted(STEP_KEYS.MESSAGECONTENT)
  }

  const renderPanes = (formValues, readOnly, setFieldValue, errors) => {
    return [
      {
        menuItem: intl.formatMessage({
          id: 'messageCenter.createMessage.step.tab.title.finnish'
        }),
        render: () => (
          <Tab.Pane>
            <MessageContent
              key="fiMessageContent"
              formValues={formValues}
              readOnly={readOnly}
              onChangeValue={(event, data) => {
                onChange(event, data)
                setFieldValue(data.name, data.value)
              }}
              onChecked={onChecked}
              errors={errors}
              prefix={'fi'}
              copyToAllLanguages={copyToAllLanguages}
            ></MessageContent>
          </Tab.Pane>
        )
      },
      {
        menuItem: intl.formatMessage({
          id: 'messageCenter.createMessage.step.tab.title.english'
        }),
        render: () => (
          <Tab.Pane>
            <MessageContent
              key="enMessageContent"
              formValues={formValues}
              readOnly={readOnly}
              onChangeValue={(event, data) => {
                onChange(event, data)
                setFieldValue(data.name, data.value)
              }}
              onChecked={onChecked}
              errors={errors}
              prefix={'en'}
              copyToAllLanguages={copyToAllLanguages}
            ></MessageContent>
          </Tab.Pane>
        )
      },
      {
        menuItem: intl.formatMessage({
          id: 'messageCenter.createMessage.step.tab.title.swedish'
        }),
        render: () => (
          <Tab.Pane>
            <MessageContent
              key="svMessageContent"
              formValues={formValues}
              readOnly={readOnly}
              onChangeValue={(event, data) => {
                onChange(event, data)
                setFieldValue(data.name, data.value)
              }}
              onChecked={onChecked}
              errors={errors}
              prefix={'sv'}
              copyToAllLanguages={copyToAllLanguages}
            ></MessageContent>
          </Tab.Pane>
        )
      }
    ]
  }

  return (
    <>
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema()}
        onSubmit={() => {
          onSubmitForm()
        }}
        enableReinitialize
      >
        {({ errors, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            {!readOnly && (
              <Header>
                <FormattedMessage id="messageCenter.createMessage.step.messageContent.description" />
              </Header>
            )}
            <Tab
              panes={renderPanes(formValues, readOnly, setFieldValue, errors)}
            />
            {!readOnly && (
              <Button
                primary
                type="submit"
                loading={loading}
                disabled={loading}
              >
                <FormattedMessage id="messageCenter.createMessage.action.saveAndContinue" />
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default StepMessageContent
