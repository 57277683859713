import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Checkbox, Loader, Message } from 'semantic-ui-react'

import useAuthInfo from '../../../hooks/useAuthInfo'
import userService from '../../../services/User/UserServices'
import SubscribedService from './SubscribedService'
import SubscribedServicesTable from './SubscribedServicesTable'

const pages = {
  ALL: 'all',
  SINGLE: 'single'
}

export default function CustomerSubscribedServices({ customer }) {
  const [includeHistory, setIncludeHistory] = useState(false)
  const [selected, setSelected] = useState(null)
  const [internalPage, setInternalPage] = useState(pages.ALL)

  const { operatorRealm } = useAuthInfo()

  const subscribedServices = useQuery({
    queryKey: ['subscribedServices', customer.ref, includeHistory],
    queryFn: () =>
      userService.fetchSubscribedServices(
        customer.ref,
        operatorRealm,
        includeHistory
      )
  })

  const selectSubscribedService = (ss) => () => {
    setSelected(ss)
    setInternalPage(pages.SINGLE)
  }

  return (
    <>
      {internalPage === pages.ALL ? (
        <>
          <Checkbox
            checked={includeHistory}
            onClick={() => setIncludeHistory((prev) => !prev)}
            label="Näytä myös päättyneet"
          />
          {subscribedServices.isLoading && <Loader active inline="centered" />}
          {subscribedServices.isError && (
            <Message error>
              Tilattujen tuotteiden lataamisessa tapahtui virhe
            </Message>
          )}
          <SubscribedServicesTable
            subscribedServices={
              subscribedServices.data ? subscribedServices.data : []
            }
            openSubscriptions={selectSubscribedService}
          />
        </>
      ) : (
        <>
          <Breadcrumb>
            <Breadcrumb.Section link onClick={() => setInternalPage(pages.ALL)}>
              Kaikki tilaukset
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section>
              {selected ? selected.service.name : ''}
            </Breadcrumb.Section>
          </Breadcrumb>
          <SubscribedService subscribedService={selected} />
        </>
      )}
    </>
  )
}
