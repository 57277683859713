import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyLink,
  MoovyTable,
  ParkingTypeLabel,
  ServiceWithTags
} from '../../../components'
import { Popup, Table } from 'semantic-ui-react'
import {
  formatDateAndTime,
  formatPrice,
  formatSeconds,
  serviceType
} from '../../../services/utils'

import PropTypes from 'prop-types'
import React from 'react'
import Routing from '../../../routing/Routing'
import { TablePagination } from '../../../components'
import { getSortDirection } from '../../../services/utils'
import { locationShape } from '../../../services/utils/shapes'
import moovyDateTime from '../../../services/utils/moovyDateTime'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useNavigate } from 'react-router-dom'

const ParkingEndedTable = ({
  parkingQuery,
  parkings,
  initiallySelectedParkingRef,
  activePage,
  totalPages,
  sorting,
  onPageChange,
  onClickParking,
  onSort
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { isSuperOperator } = useAuthInfo()

  const RenderServiceType = ({ parking }) => {
    return (
      <>
        <Popup
          trigger={
            <MoovyLink bold key={parking.ref}>
              {parking?.service?.type == serviceType.SUBSCRIPTION
                ? intl.formatMessage({
                    id: 'parkingTable.parkingProduct.subscription'
                  })
                : intl.formatMessage({
                    id: 'parkingTable.parkingProduct.shortParking'
                  })}
            </MoovyLink>
          }
        >
          <MoovyLink
            bold
            key={parking.ref}
            onClick={() =>
              navigate(
                Routing.getServiceUrl(parking.service?.ref, isSuperOperator)
              )
            }
          >
            {parking.service?.name}
          </MoovyLink>
        </Popup>
        <ServiceWithTags parkingService={parking?.service} />
      </>
    )
  }

  const getParkingEndTime = (backendStartTime, durationInSeconds) => {
    const parkingStartTime =
      backendStartTime &&
      moovyDateTime.backendDateTimeToDateTime(backendStartTime)
    const parkingEndTime =
      parkingStartTime &&
      parkingStartTime.plus({ seconds: durationInSeconds || 0 })
    return parkingEndTime ? moovyDateTime.dateTimeToUI(parkingEndTime) : ''
  }

  return (
    <>
      <MoovyTable sortable selectable items={parkings} query={parkingQuery}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.type" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('startTime', sorting)}
              onClick={() => onSort('startTime')}
            >
              <FormattedMessage id="parkingTable.header.startTime" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('endTime', sorting)}
              onClick={() => onSort('endTime')}
            >
              <FormattedMessage id="parkingTable.header.endTime" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('duration', sorting)}
              onClick={() => onSort('duration')}
            >
              <FormattedMessage id="parkingTable.header.duration" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.totalDuration" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.licensePlateNumber" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.customer" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('priceBeforeDiscounts', sorting)}
              onClick={() => onSort('priceBeforeDiscounts')}
            >
              <FormattedMessage id="parkingTable.header.price" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.discount" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('price', sorting)}
              onClick={() => onSort('price')}
            >
              <FormattedMessage id="parkingTable.header.total" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.parkingProduct" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {parkings &&
            parkings.map((parking) => (
              <Table.Row
                key={parking.ref}
                active={initiallySelectedParkingRef === parking.ref}
                onClick={() => onClickParking(parking)}
              >
                <Table.Cell
                  textAlign="center"
                  collapsing
                  style={{ padding: '0px' }}
                >
                  <ParkingTypeLabel
                    type={parking.type}
                    paymentMethodType={parking.paymentMethodType}
                  />
                </Table.Cell>
                <Table.Cell>
                  {formatDateAndTime(parking.temporalDetails?.startTime)}
                </Table.Cell>
                <Table.Cell collapsing>
                  {getParkingEndTime(
                    parking.temporalDetails?.startTime,
                    parking.temporalDetails?.parkingDurationInSeconds
                  )}
                </Table.Cell>
                <Table.Cell>
                  {formatSeconds(
                    parking.temporalDetails?.parkingDurationInSeconds
                  )}
                </Table.Cell>
                <Table.Cell>
                  {parking.temporalDetails?.parkingSequence
                    ? formatSeconds(
                        parking.temporalDetails?.parkingSequence
                          ?.durationInSeconds
                      )
                    : ''}
                </Table.Cell>
                <Table.Cell
                  collapsing
                  onClick={(event) => event.stopPropagation()}
                >
                  {parking.licensePlateNumber}
                </Table.Cell>
                <Table.Cell>{parking.user?.name || ''}</Table.Cell>
                <Table.Cell>
                  {formatPrice(parking.price?.priceBeforeDiscounts)}
                </Table.Cell>
                <Table.Cell>{formatPrice(parking.price?.discounts)}</Table.Cell>
                <Table.Cell>{formatPrice(parking.price?.price)}</Table.Cell>
                <Table.Cell
                  singleLine={true}
                  onClick={(event) => event.stopPropagation()}
                >
                  <RenderServiceType parking={parking} />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <TablePagination
          colSpan={11}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={(e, { activePage: newPage }) => {
            onPageChange(newPage)
          }}
        />
      </MoovyTable>
    </>
  )
}

ParkingEndedTable.propTypes = {
  fetchSessionsByLocation: PropTypes.func,
  isSuperOperator: PropTypes.bool,
  location: PropTypes.shape(locationShape)
}

export default ParkingEndedTable
