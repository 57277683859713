import './Finances.scss'

import React, { useState } from 'react'
import {
  calendarDateInputToBackendFormat,
  dateNowToCalendarInput
} from '../../services/utils'

import { DateTime } from 'luxon'
import FinancesFilterDateRange from './FinancesFilterDateRange'
import FinancesFilterYearMonth from './FinancesFilterYearMonth'
import FinancesPane from './FinancesPane'
import financesService from '../../services/Finances'
import { useIntl } from 'react-intl'

const ReportAdyenSuccessfulPaymentsForMoovy = () => {
  const intl = useIntl()

  const [yearAndMonth, setYearAndMonth] = useState({
    year: DateTime.local().year.toString(),
    month: DateTime.local().month.toString()
  })

  const [startTime, setStartTime] = useState(dateNowToCalendarInput())
  const [endTime, setEndTime] = useState(dateNowToCalendarInput())

  return (
    <FinancesPane
      text={intl.formatMessage({
        id: 'finances.tab.reportAdyenSuccessfulPaymentsForMoovy.header'
      })}
      buttonCallback={() =>
        financesService.downloadAdyenSuccessfulPaymentsForMoovyReport({
          ...yearAndMonth,
          from: calendarDateInputToBackendFormat(startTime),
          until: calendarDateInputToBackendFormat(endTime)
        })
      }
    > 
      <FinancesFilterYearMonth
      yearAndMonth={yearAndMonth}
      setYearAndMonth={setYearAndMonth}
      />
      <FinancesFilterDateRange
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
      />
    </FinancesPane>
  )
}

export default ReportAdyenSuccessfulPaymentsForMoovy
