import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'
import { Icon, Input, Menu, Pagination, Table } from 'semantic-ui-react'
import { useDebounce } from 'use-debounce'

import servicesService from '../../services/Services'
import { concatVehicles, formatDate } from '../../services/utils'
import RelativeLinkForCurrentUser from '../RelativeLinkForCurrentUser'

const roles = {
  ROLE_USER: 'Yksityinen',
  ROLE_ENTERPRISE_USER: 'Yritys'
}

const concatEndDates = (dates) => {
  const sorted = dates.sort((a, b) => new Date(a) - new Date(b))
  const joined = sorted
    .slice(0, 3)
    .map((date) => formatDate(date))
    .join(', ')
  return dates.length > 3 ? `${joined}...` : joined
}

export const sortColumns = {
  owner: 'lastName',
  type: 'role',
  phone: 'phone',
  email: 'email',
  vehicles: 'vehicles',
  subscriptions: 'subscriptions',
  endTime: 'endTime'
}

const SubscribedServicesTable = ({ subscriptionServiceRef, operatorRealm }) => {
  const SORT_ASC = 'ascending'
  const SORT_DESC = 'descending'

  const [activePage, setActivePage] = useState(1)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm] = useDebounce(searchTerm, 700)
  const [sortColumn, setSortcolumn] = useState({
    [sortColumns.owner]: SORT_ASC
  })
  const intl = useIntl()

  const subscribedServices = useQuery({
    queryKey: [
      'subscribedServices',
      subscriptionServiceRef,
      activePage,
      debouncedSearchTerm,
      sortColumn
    ],
    queryFn: () =>
      servicesService.fetchActiveSubscribedServices(
        subscriptionServiceRef,
        operatorRealm,
        {
          page: activePage - 1,
          limit: 20,
          searchTerm,
          sort: Object.keys(sortColumn)[0],
          direction:
            Object.values(sortColumn)[0] === 'ascending' ? 'ASC' : 'DESC'
        }
      )
  })

  const handleSort = (clickedColumn) => () => {
    const direction =
      Object.values(sortColumn)[0] === SORT_ASC ? SORT_DESC : SORT_ASC
    setSortcolumn({ [clickedColumn]: direction })
  }

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value)
    setActivePage(1)
  }

  const handleClearSearch = () => {
    setSearchTerm('')
    setActivePage(1)
  }

  const handlePaginationChange = (e, { activePage: page }) => {
    setActivePage(page)
  }

  const results = subscribedServices.data
    ? subscribedServices.data
    : { totalPages: 0, totalResults: 0, data: [] }

  return (
    <Table structured compact sortable basic>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Input
              placeholder={intl.formatMessage({
                id: 'parkingServiceDetails.tab.subscribedServices.table.filter.search.placeholder'
              })}
              value={searchTerm}
              onChange={handleSearchTermChange}
              icon={<Icon name="times" link onClick={handleClearSearch} />}
            />
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="6">
            {results.totalPages > 1 && (
              <Menu floated="right">
                <Pagination
                  activePage={activePage}
                  totalPages={results.totalPages}
                  onPageChange={handlePaginationChange}
                />
              </Menu>
            )}
          </Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell
            sorted={sortColumn[sortColumns.owner]}
            onClick={handleSort(sortColumns.owner)}
          >
            <FormattedMessage id="parkingServiceDetails.tab.subscribedServices.table.header.owner" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn[sortColumns.type]}
            onClick={handleSort(sortColumns.type)}
          >
            <FormattedMessage id="parkingServiceDetails.tab.subscribedServices.table.header.type" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn[sortColumns.phone]}
            onClick={handleSort(sortColumns.phone)}
          >
            <FormattedMessage id="parkingServiceDetails.tab.subscribedServices.table.header.phone" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn[sortColumns.email]}
            onClick={handleSort(sortColumns.email)}
          >
            <FormattedMessage id="parkingServiceDetails.tab.subscribedServices.table.header.email" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn[sortColumns.vehicles]}
            onClick={handleSort(sortColumns.vehicles)}
          >
            <FormattedMessage id="parkingServiceDetails.tab.subscribedServices.table.header.vehicles" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn[sortColumns.subscriptions]}
            onClick={handleSort(sortColumns.subscriptions)}
          >
            <FormattedMessage id="parkingServiceDetails.tab.subscribedServices.table.header.subscriptions" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={sortColumn[sortColumns.endTime]}
            onClick={handleSort(sortColumns.endTime)}
          >
            <FormattedMessage id="parkingServiceDetails.tab.subscribedServices.table.header.endTime" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {results.data.map((subscribedService) => {
          const {
            owner,
            currentActiveVehicles,
            subscriptionCount,
            endDatesInFuture
          } = subscribedService
          const ownerName =
            owner.role === 'ROLE_ENTERPRISE_USER'
              ? `${owner.company}, ${owner.businessCode}`
              : `${owner.firstName} ${owner.lastName}`
          return (
            <Table.Row key={subscribedService.ref}>
              <Table.Cell>
                <RelativeLinkForCurrentUser to={`customers/${owner.ref}`}>
                  {ownerName}
                </RelativeLinkForCurrentUser>
              </Table.Cell>
              <Table.Cell>{roles[owner.role]}</Table.Cell>
              <Table.Cell>{owner.phone}</Table.Cell>
              <Table.Cell>{owner.email}</Table.Cell>
              <Table.Cell>
                {currentActiveVehicles
                  ? concatVehicles(currentActiveVehicles)
                  : ''}
              </Table.Cell>
              <Table.Cell>{subscriptionCount}</Table.Cell>
              <Table.Cell>{concatEndDates(endDatesInFuture)}</Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default SubscribedServicesTable
