import * as yup from 'yup'

import { Button, Divider, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyIconInfo, MutationErrorMessage } from '../../components'
import React, { useMemo } from 'react'
import {
  adyenReportProfileTypeEnum,
  settlementRecipientCompanyTypeEnum as companyTypeEnum
} from '../../services/utils/DTOEnums'
import { isValidPhoneNumber, validateEmail } from '../../services/utils'

import { Formik } from 'formik'
import { getCountryCodeAsSelectOptions } from '../../services/utils'
import useOperators from '../../hooks/useOperators'

const ModalManageSettlementRecipient = ({
  onCreateSettlementRecipient,
  onUpdateSettlementRecipient,
  open,
  edit,
  onClose,
  mutation
}) => {
  const validationSchema = () => {
    return yup.object().shape({
      operatorRef: yup.string().required('Cannot be empty'),
      companyName: yup.string().required('Cannot be empty'),
      businessId: yup.string().required('Cannot be empty'),
      companyType: yup.string().required('Cannot be empty'),
      countryCode: yup.string().required('Cannot be empty'),
      streetAddress: yup.string().required('Cannot be empty'),
      postalCode: yup.string().required('Cannot be empty'),
      city: yup.string().required('Cannot be empty'),
      adyenReportProfileType: yup.string().required('Cannot be empty'),
      contact: yup.object().shape({
        name: yup.string().required('Cannot be empty'),
        phone: yup
          .string()
          .test(
            'InvalidPhoneNumber',
            'Phone number is not valid',
            function (phoneNumber) {
              return !!phoneNumber && isValidPhoneNumber(phoneNumber.trim())
            }
          ),
        email: yup
          .string()
          .test(
            'validEmailAddress',
            'Check if email is valid',
            function (email) {
              return !!email && validateEmail(email)
            }
          )
      })
    })
  }

  const intl = useIntl()
  const countryOptions = useMemo(() => getCountryCodeAsSelectOptions(), [])
  const operators = useOperators()
  const operatorOptions =
    operators?.data?.content != null
      ? operators.data.content.map((item) => ({
          key: item.ref,
          text: item.name,
          value: item.ref
        }))
      : []

  const companyTypeOptions = [
    {
      key: companyTypeEnum.BUSINESS,
      text: intl.formatMessage({
        id: companyTypeEnum.localizationKeys.BUSINESS
      }),
      value: companyTypeEnum.BUSINESS
    },
    {
      key: companyTypeEnum.PUBLIC_COMPANY,
      text: intl.formatMessage({
        id: companyTypeEnum.localizationKeys.PUBLIC_COMPANY
      }),
      value: companyTypeEnum.PUBLIC_COMPANY
    }
  ]

  const adyenReportProfileTypeOptions = [
    adyenReportProfileTypeEnum.NORMAL,
    adyenReportProfileTypeEnum.ONLY_CLOSING
  ].map((type) => ({
    key: type,
    text: intl.formatMessage({
      id: adyenReportProfileTypeEnum.localizationKeys[type]
    }),
    value: type
  }))

  const disabledProperties = {
    companyName: !!edit,
    businessId: !!edit,
    countryCode: true,
    companyType: !!edit && edit.companyType !== companyTypeEnum.NOT_DEFINED
  }

  return (
    <Formik
      initialValues={{
        operatorRef: edit?.operator?.ref || '',
        companyName: edit?.companyName || '',
        nameExtension: edit?.nameExtension || '',
        businessId: edit?.businessId || '',
        companyType: edit?.companyType || '',
        countryCode: edit?.countryCode || 'FI',
        streetAddress: edit?.streetAddress || '',
        postalCode: edit?.postalCode || '',
        city: edit?.city || '',
        adyenReportProfileType: edit?.adyenReportProfileType || '',
        contact: {
          name: edit?.contact?.name || '',
          phone: edit?.contact?.phone || '',
          email: edit?.contact?.email || ''
        }
      }}
      onSubmit={(values, { resetForm }) => {
        if (!!edit) {
          let filledValues = {
            ...values,
            type: 'update',
            settlementRecipientRef: edit.ref,
            companyName: disabledProperties.companyName
              ? undefined
              : values.companyName,
            nameExtension: disabledProperties.nameExtension
              ? undefined
              : values.nameExtension,
            businessId: disabledProperties.businessId
              ? undefined
              : values.businessId,
            countryCode: disabledProperties.countryCode
              ? undefined
              : values.countryCode,
            companyType: disabledProperties.companyType
              ? undefined
              : values.companyType
          }
          onUpdateSettlementRecipient({
            data: filledValues,
            resetForm: resetForm
          })
        } else {
          let filledValues = { ...values, type: 'create' }
          onCreateSettlementRecipient({
            data: filledValues,
            resetForm: resetForm
          })
        }
      }}
      validationSchema={validationSchema()}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
      }) => (
        <Modal open={open} onClose={onClose} size="small">
          <Modal.Header
            content={
              (edit && (
                <FormattedMessage id="modalManage.settlementRecipient.form.header.editRecipient" />
              )) || (
                <FormattedMessage id="modalManage.settlementRecipient.form.header.addNewRecipient" />
              )
            }
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId={
                (edit && 'settlementRecipient.action.update.failed') ||
                'settlementRecipient.action.create.failed'
              }
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Select
                label={intl.formatMessage({
                  id: 'modalManage.settlementRecipient.form.operator'
                })}
                placeholder={intl.formatMessage({
                  id: 'modalManage.settlementRecipient.form.operator.placeholder'
                })}
                value={values.operatorRef}
                name="operatorRef"
                options={operatorOptions}
                onChange={(e, value) => {
                  setFieldValue(value.name, value.value)
                }}
                error={!!errors.operatorRef}
                selectOnBlur={false}
              ></Form.Select>
              <Form.Group widths="equal">
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.companyName'
                  })}
                  name="companyName"
                  value={values.companyName}
                  onChange={handleChange}
                  disabled={disabledProperties.companyName}
                  error={!!errors.companyName}
                />
                <Form.Field>
                  <label>
                    <MoovyIconInfo
                      iconLabel={intl.formatMessage({
                        id: 'modalManage.settlementRecipient.form.nameExtension'
                      })}
                    >
                      {intl.formatMessage({
                        id: 'modalManage.settlementRecipient.form.info.nameExtension'
                      })}
                    </MoovyIconInfo>
                  </label>
                  <Form.Input
                    name="nameExtension"
                    value={values.nameExtension}
                    onChange={handleChange}
                    disabled={disabledProperties.nameExtension}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.businessId'
                  })}
                  name="businessId"
                  value={values.businessId}
                  onChange={handleChange}
                  error={!!errors.businessId}
                  disabled={disabledProperties.businessId}
                />
                <Form.Select
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.companyType'
                  })}
                  placeholder={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.companyType.placeholder'
                  })}
                  name="companyType"
                  options={companyTypeOptions}
                  value={values.companyType}
                  onChange={(e, value) => {
                    setFieldValue(value.name, value.value)
                  }}
                  disabled={disabledProperties.companyType}
                  error={!!errors.companyType}
                  selectOnBlur={false}
                ></Form.Select>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Select
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.country'
                  })}
                  name="countryCode"
                  value={values.countryCode}
                  options={countryOptions}
                  onChange={(e, value) => {
                    setFieldValue(value.name, value.value)
                  }}
                  disabled={disabledProperties.countryCode}
                  error={!!errors.countryCode}
                  selectOnBlur={false}
                ></Form.Select>
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.streetAddress'
                  })}
                  name="streetAddress"
                  value={values.streetAddress}
                  onChange={handleChange}
                  error={!!errors.streetAddress}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.postalCode'
                  })}
                  name="postalCode"
                  value={values.postalCode}
                  onChange={handleChange}
                  error={!!errors.postalCode}
                />
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.city'
                  })}
                  name="city"
                  value={values.city}
                  onChange={handleChange}
                  error={!!errors.city}
                />
              </Form.Group>
              <Form.Select
                label={intl.formatMessage({
                  id: 'modalManage.settlementRecipient.form.adyenReportProfileType'
                })}
                name="adyenReportProfileType"
                value={values.adyenReportProfileType}
                options={adyenReportProfileTypeOptions}
                onChange={(e, value) => {
                  setFieldValue(value.name, value.value)
                }}
                error={!!errors.adyenReportProfileType}
                selectOnBlur={false}
              />
              <Divider section />
              <Form.Field>
                <label>
                  <FormattedMessage id="modalManage.settlementRecipient.form.contact.details" />
                </label>
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.contact.name'
                  })}
                  name="contact.name"
                  value={values.contact.name}
                  onChange={handleChange}
                  error={!!errors.contact?.name}
                />
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalManage.settlementRecipient.form.contact.phone'
                  })}
                  name="contact.phone"
                  value={values.contact.phone}
                  onChange={handleChange}
                  error={!!errors.contact?.phone}
                />
              </Form.Group>
              <Form.Input
                label={intl.formatMessage({
                  id: 'modalManage.settlementRecipient.form.contact.email'
                })}
                name="contact.email"
                value={values.contact.email}
                onChange={handleChange}
                error={!!errors.contact?.email}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                resetForm({})
                onClose()
              }}
            >
              <FormattedMessage id="common.button.cancel" />
            </Button>
            {(edit && (
              <Button
                type="submit"
                primary
                onClick={handleSubmit}
                loading={mutation.isPending}
                disabled={mutation.isPending}
              >
                <FormattedMessage id="modalManage.settlementRecipient.button.updateRecipient" />
              </Button>
            )) || (
              <Button
                type="submit"
                primary
                onClick={handleSubmit}
                loading={mutation.isPending}
                disabled={mutation.isPending}
              >
                <FormattedMessage id="modalManage.settlementRecipient.button.addRecipient" />
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalManageSettlementRecipient
