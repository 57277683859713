import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Grid } from 'semantic-ui-react'

import { MoovyButton } from '../../components'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import settlementRecipientService from '../../services/SettlementRecipients'
import { ROLE_FINANCES } from '../../services/utils'
import ModalManageSettlementRecipient from './modalManageSettlementRecipient'
import SettlementRecipientsTable from './settlementRecipientsTable'

const SettlementRecipientsContent = ({
  query,
  recipients,
  sortColumn,
  sortDirection,
  onSort,
  onPageChange,
  totalPages,
  activePage,
  refresh
}) => {
  const intl = useIntl()
  const { isSuperOperator, roles } = useAuthInfo()
  const [openModal, setOpenModal] = useState(false)

  const {
    mutate: createSettlementRecipient,
    ...createSettlementRecipientMutation
  } = useMutation({
    mutationFn: (settlementRecipient) =>
      settlementRecipientService.createSettlementRecipient(
        settlementRecipient.data
      ),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'settlementRecipient.action.create.success.title',
        description: 'settlementRecipient.action.create.success.description'
      })
      const { resetForm } = variables
      resetForm({})
      setOpenModal(false)
      refresh()
    }
  })

  return (
    <>
      {roles.includes(ROLE_FINANCES) && isSuperOperator && (
        <Grid style={{ paddingTop: '10px' }}>
          <Grid.Column floated="right" width={8} textAlign="right">
            <MoovyButton onClick={() => setOpenModal(true)}>
              <>
                <FontAwesomeIcon
                  icon={['fal', 'plus-circle']}
                  style={{ marginRight: '6px' }}
                />
                <FormattedMessage id="modalManage.settlementRecipient.button.addRecipient" />
              </>
            </MoovyButton>
          </Grid.Column>
        </Grid>
      )}

      <SettlementRecipientsTable
        query={query}
        recipients={recipients}
        onPageChange={onPageChange}
        totalPages={totalPages}
        activePage={activePage}
        sortedColumn={sortColumn}
        sortDirection={sortDirection}
        onSort={onSort}
      />
      <ModalManageSettlementRecipient
        open={openModal}
        onCreateSettlementRecipient={(data) => createSettlementRecipient(data)}
        mutation={createSettlementRecipientMutation}
        onClose={() => setOpenModal(false)}
      />
    </>
  )
}

export default SettlementRecipientsContent
