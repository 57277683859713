import React, { useState } from 'react'
import { Icon, Menu, Popup, Table } from 'semantic-ui-react'

import { KeyValueTable } from '../../../components'
import { formatDateAndTime, formatPrice } from '../../../services/utils'
import SendPaymentReceipt from '../../AnonymousParkingModal/SendPaymentReceipt'

const INVOICED = 'INVOICED'
const NOT_INVOICED = 'NOT_INVOICED'
const AnonymousParkingPaymentEvents = ({ events }) => {
  const [selectedPaymentEvent, setSelectedPaymentEvent] = useState()

  return (
    <>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Aika</Table.HeaderCell>
            <Table.HeaderCell>Tila</Table.HeaderCell>
            <Table.HeaderCell>Maksettu</Table.HeaderCell>
            <Table.HeaderCell></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {events.map((event) => (
            <Table.Row
              key={event.ref}
              positive={event.status === INVOICED}
              negative={event.status === NOT_INVOICED}
            >
              <Table.Cell>{formatDateAndTime(event.creationTime)}</Table.Cell>
              <Table.Cell>
                {event.status === INVOICED ? (
                  <div>
                    <Icon name="credit card" /> Maksettu
                  </div>
                ) : (
                  <div>
                    <Icon name="user cancel" /> Ei laskutettu
                  </div>
                )}
              </Table.Cell>
              <Table.Cell>{formatPrice(event.paid)}</Table.Cell>
              <Table.Cell>
                {event.status === INVOICED && (
                  <Menu fluid widths={2}>
                    <Menu.Item onClick={() => setSelectedPaymentEvent(event)}>
                      <Icon name="mail" />
                      Lähetä kuitti
                    </Menu.Item>

                    <Popup
                      key={event.ref}
                      content={
                        <KeyValueTable
                          data={
                            event.markAsPaidRequest
                              ? Object.entries(
                                  JSON.parse(event.markAsPaidRequest)
                                )
                              : []
                          }
                        />
                      }
                      on="click"
                      trigger={
                        <Menu.Item>
                          <Icon name="search" />
                          Näytä maksudata
                        </Menu.Item>
                      }
                    />
                  </Menu>
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {selectedPaymentEvent && (
        <SendPaymentReceipt
          event={selectedPaymentEvent}
          handleClose={() => setSelectedPaymentEvent()}
        />
      )}
    </>
  )
}

export default AnonymousParkingPaymentEvents
