import { Button, Modal, Segment } from 'semantic-ui-react'
import React, { useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { MoovyModalConfirmation } from '../../components'
import adyenSRService from '../../services/Adyen/SettlementRecipients'
import { formatPrice } from '../../services/utils'
import { useQuery } from '@tanstack/react-query'

const ModalCoverCreditLoss = ({
  onClose,
  creditLossItem,
  coverCreditLoss,
  coverCreditLossMutation
}) => {
  const [confirmCoverRequest, setConfirmCoverRequest] = useState(false)

  const creditLossValidationDataQuery = useQuery({
    queryKey: [
      'creditLossValidationDataQuery',
      creditLossItem.settlementRecipientRef
    ],
    queryFn: () =>
      adyenSRService.getSettlementRecipientCreditLossValidationData(
        creditLossItem.settlementRecipientRef
      ),
    enabled: !!creditLossItem
  })

  const creditLossValidationDataQueryIsLoading =
    !!creditLossItem &&
    (creditLossValidationDataQuery.isLoading ||
      creditLossValidationDataQuery.isFetching)

  const validationData = creditLossValidationDataQuery.data
  const creditLossSum = (
    Math.round(
      (validationData?.productSalesAmount -
        validationData?.successfulPaymentsAmount) *
        100
    ) / 100
  ).toFixed(2)

  const difference =
    (Math.round(creditLossItem.creditLoss * 100) / 100).toFixed(2) -
    creditLossSum

  const RenderValidationData = () => {
    if (!validationData) {
      return ''
    }

    return (
      <>
        <Segment>
          <div>
            <b>
              <FormattedMessage id="modalCoverCreditLoss.content.label.sale" />:{' '}
            </b>
            {formatPrice(validationData.productSalesAmount)}
          </div>
          <div>
            <b>
              <FormattedMessage id="modalCoverCreditLoss.content.label.receivedPayments" />
              :{' '}
            </b>
            {formatPrice(validationData.successfulPaymentsAmount)}
          </div>
          <div>
            <b>
              <FormattedMessage id="modalCoverCreditLoss.content.label.countedCreditLoss" />
              :{' '}
            </b>
            {formatPrice(validationData.productSalesAmount)} -{' '}
            {formatPrice(validationData.successfulPaymentsAmount)} ={' '}
            {formatPrice(
              validationData.productSalesAmount -
                validationData.successfulPaymentsAmount
            )}
          </div>
        </Segment>

        {!creditLossValidationDataQueryIsLoading && difference !== 0 && (
          <Segment>
            <div style={{ color: '#9f3a38' }}>
              <FormattedMessage
                id="modalCoverCreditLoss.content.info.noMatchingCreditLoss"
                values={{ difference: formatPrice(difference) }}
              />
            </div>
          </Segment>
        )}
      </>
    )
  }

  return (
    <>
      <Modal open onClose={onClose} size="small">
        <Modal.Header>
          <FormattedMessage id="modalCoverCreditLoss.title" />
        </Modal.Header>
        <Modal.Content>
          <div>
            <b>
              <FormattedMessage id="modalCoverCreditLoss.content.label.settlementRecipient" />
              :{' '}
            </b>
            {creditLossItem.settlementRecipientName}
          </div>
          <div>
            <b>
              <FormattedMessage id="modalCoverCreditLoss.content.label.accountHolderCode" />
              :{' '}
            </b>
            {creditLossItem.accountHolderCode}
          </div>
          <div>
            <b>
              <FormattedMessage id="modalCoverCreditLoss.content.label.isReadyToBeCovered" />
              :{' '}
            </b>
            {creditLossItem.isReadyToBeCovered ? (
              <FormattedMessage id="common.yes" />
            ) : (
              <FormattedMessage id="common.no" />
            )}
          </div>
          <div>
            <b>
              <FormattedMessage id="modalCoverCreditLoss.content.label.description" />
              :{' '}
            </b>
            {creditLossItem.description}
          </div>
          <Segment>
            <b>
              <FormattedMessage id="modalCoverCreditLoss.content.label.creditLoss" />
              :{' '}
            </b>
            {formatPrice(creditLossItem.creditLoss)}
          </Segment>
          <RenderValidationData />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>
            <FormattedMessage id="common.button.cancel" />
          </Button>
          <Button
            primary
            onClick={() => setConfirmCoverRequest(true)}
            disabled={
              difference !== 0 || creditLossValidationDataQueryIsLoading
            }
          >
            <FormattedMessage id="modalCoverCreditLoss.button.cover" />
          </Button>
        </Modal.Actions>
      </Modal>
      <MoovyModalConfirmation
        open={confirmCoverRequest}
        onClose={() => setConfirmCoverRequest(false)}
        lang={{
          titleElement: <FormattedMessage id="modalCoverCreditLoss.title" />,
          bodyElement: (
            <FormattedMessage
              id="modalCoverCreditLoss.confirmation.body"
              values={{
                name: creditLossItem.settlementRecipientName,
                dateRange: creditLossItem.dateRange,
                amountOfcreditLoss: formatPrice(creditLossItem.creditLoss)
              }}
            />
          ),

          buttonConfirmKey: 'common.button.confirm',
          defaultErroKey: 'modalCoverCreditLoss.coverCreditLoss.action.error'
        }}
        onSubmit={() => coverCreditLoss(creditLossItem)}
        mutation={coverCreditLossMutation}
      />
    </>
  )
}

export default ModalCoverCreditLoss
