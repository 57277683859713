import AddAgreementModal, { VOUCHER_AGREEMENT } from './AddAgreementModal'
import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Icon, Loader, Message, Tab, Table } from 'semantic-ui-react'
import { MoovyButton, MoovyDeleteButton } from '../../components'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import voucherTemplateService, {
  getVoucherTemplateDescription
} from '../../services/VoucherTemplates'

import useAuthInfo from '../../hooks/useAuthInfo'
import { useNavigate } from 'react-router-dom'
import userGroupService from '../../services/UserGroups'

export default function VoucherGrantAgreementsTab({ userGroup, allowEdit }) {
  const [addModalOpen, setAddModalOpen] = useState(false)

  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const queryClient = useQueryClient()
  const intl = useIntl()

  const voucherTemplates = useQuery({
    queryKey: ['voucherTemplates'],
    queryFn: () => voucherTemplateService.fetchVoucherTemplates(operatorRealm)
  })

  const queryKey = 'voucherGrantAgreements'
  const voucherGrantAgreements = useQuery({
    queryKey: [queryKey, userGroup.ref],
    queryFn: () =>
      userGroupService.fetchVoucherGrantAgreements(userGroup.ref, operatorRealm)
  })

  const { mutate: addAgreement, ...addMutation } = useMutation({
    mutationFn: (templateRef) =>
      voucherTemplateService.addVoucherGrantAgreement(
        templateRef,
        userGroup.ref,
        operatorRealm
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey, userGroup.ref] })
      setAddModalOpen(false)
    }
  })

  const { mutate: deleteAgreement, ...deleteMutation } = useMutation({
    mutationFn: (agreementRef) =>
      voucherTemplateService.deleteVoucherGrantAgreement(
        agreementRef,
        operatorRealm
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey, userGroup.ref] })
    }
  })

  const navigate = useNavigate()

  let voucherTemplateOptions = voucherTemplates.data
    ? voucherTemplates.data
        .filter(
          (template) => template?.operator?.ref === userGroup?.operator?.ref
        )
        .map((template) => ({
          key: template.ref,
          value: template.ref,
          text: getVoucherTemplateDescription(template)
        }))
    : []

  if (voucherGrantAgreements.data) {
    const existingAgreementRefs = voucherGrantAgreements.data.map(
      (agreement) => agreement.voucherTemplate.ref
    )
    voucherTemplateOptions = voucherTemplateOptions.filter(
      (option) => !existingAgreementRefs.includes(option.key)
    )
  }

  const onSelectedVoucherTemplate = (voucherTemplate) => {
    navigate(
      `/${isSuperOperator ? 'admin' : 'operator'}/voucherTemplates/${
        voucherTemplate.ref
      }`
    )
  }

  return (
    <Tab.Pane>
      <Grid>
        {allowEdit && (
          <Grid.Row textAlign="right">
            <Grid.Column>
              <MoovyButton onClick={() => setAddModalOpen(true)}>
                <Icon name="plus" />
                <FormattedMessage id="voucherGrantAgreementsTab.button.add" />
              </MoovyButton>
            </Grid.Column>
            <AddAgreementModal
              open={addModalOpen}
              onClose={() => setAddModalOpen(false)}
              options={voucherTemplateOptions}
              onSubmit={addAgreement}
              mutation={addMutation}
              type={VOUCHER_AGREEMENT}
            />
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column>
            {voucherGrantAgreements.isError && (
              <Message error>
                <FormattedMessage id="voucherGrantAgreementsTab.query.error" />
              </Message>
            )}
            {deleteMutation.isError && (
              <Message error>
                <FormattedMessage id="voucherGrantAgreementsTab.delete.error" />
              </Message>
            )}
            {voucherGrantAgreements.isLoading ? (
              <Loader active inline="centered" />
            ) : (
              <Table selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      <FormattedMessage id="voucherGrantAgreementsTab.table.header.voucher" />
                    </Table.HeaderCell>
                    {allowEdit && <Table.HeaderCell width="1" />}
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {voucherGrantAgreements.data.map((agreement) => (
                    <Table.Row
                      key={agreement.ref}
                      onClick={() => {
                        onSelectedVoucherTemplate(agreement.voucherTemplate)
                      }}
                    >
                      <Table.Cell>
                        {voucherTemplates.data &&
                        voucherTemplates.data.find(
                          (vt) => vt.ref === agreement.voucherTemplate.ref
                        )
                          ? getVoucherTemplateDescription(
                              voucherTemplates.data.find(
                                (vt) => vt.ref === agreement.voucherTemplate.ref
                              )
                            )
                          : agreement.voucherTemplate.name}
                      </Table.Cell>
                      {allowEdit && (
                        <Table.Cell
                          textAlign="right"
                          onClick={(event) => event.stopPropagation()}
                        >
                          <MoovyDeleteButton
                            confirmText={intl.formatMessage(
                              {
                                id: 'voucherGrantAgreementsTab.delete.confirm'
                              },
                              { name: agreement.voucherTemplate.name }
                            )}
                            onConfirm={() => {
                              deleteAgreement(agreement.ref)
                            }}
                            loading={deleteMutation.isPending}
                          />
                        </Table.Cell>
                      )}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Tab.Pane>
  )
}
