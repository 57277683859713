import { get, post } from '../../utils/api-renewed'

const createFullInvoicesPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/adyen/invoices${relativePath}`
    : `/administration/adyen/invoices${relativePath}`

const createFullInvoicingPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/adyen/invoicing${relativePath}`
    : `/administration/adyen/invoicing${relativePath}`

const retryCardPayment = (invoiceRef, operatorRealm) => {
  return post(
    createFullInvoicesPath(`/${invoiceRef}/retryCardPayment`, operatorRealm)
  )
}

const setCardPaymentAsWaived = (invoiceRef, comment, operatorRealm) => {
  return post(createFullInvoicesPath(`/${invoiceRef}/waive`, operatorRealm), {
    userComment: comment
  })
}

const refundCardPayment = (invoiceRef, body, operatorRealm) => {
  return post(createFullInvoicesPath(`/${invoiceRef}/refund`, operatorRealm), {
    ...body
  })
}

const listCardPaymentReverts = (invoiceRef, operatorRealm) => {
  return get(
    createFullInvoicingPath(`/${invoiceRef}/cardPaymentRevert`, operatorRealm),
    {}
  )
}

const cardPaymentRefundableAmounts = (invoiceRef, operatorRealm) => {
  return get(
    createFullInvoicingPath(`/${invoiceRef}/refundableAmounts`, operatorRealm),
    {}
  )
}

export default {
  retryCardPayment,
  setCardPaymentAsWaived,
  refundCardPayment,
  cardPaymentRefundableAmounts,
  listCardPaymentReverts
}
