import { Button, Form, Grid, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import { MutationErrorMessage } from '../../components'

const ModalEditDescription = ({
  onClose,
  subscriptionService,
  onSubmit,
  mutation
}) => {
  const intl = useIntl()

  const [description, setDescription] = useState(
    subscriptionService?.description
  )

  const handleSubmit = () => {
    onSubmit(description)
  }

  return (
    <Modal open onClose={onClose}>
      <Modal.Header
        content={intl.formatMessage({
          id: 'modalEditDescription.title'
        })}
      />
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId="modalEditDescription.edit.error"
          messageNegative
        />
        <Form onSubmit={handleSubmit}>
          <Grid>
            <Grid.Column>
              <Form.TextArea
                value={description}
                onChange={(e, { value }) => setDescription(value)}
                name="description"
                rows={15}
              />
            </Grid.Column>
          </Grid>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          autoFocus={true}
          type="submit"
          primary
          onClick={handleSubmit}
          disabled={
            mutation.isPending ||
            subscriptionService?.description === description
          }
          loading={mutation.isPending}
        >
          <FormattedMessage id="common.button.save" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalEditDescription
