import { showLocalizedMoovyToast } from '../../components/MoovyToast'

export const copyToClipboard = async (intl, text, optionalToastValue) => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(text)
    showLocalizedMoovyToast(intl, {
      title: 'copyToClipboard.toast.copiedToClipboard.title',
      values: { clipboardData: optionalToastValue || text },
      description: 'copyToClipboard.toast.copiedToClipboard.description'
    })
  }
}
