import * as yup from 'yup'

import { Button, Checkbox, Form, Icon, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../../../components'
import React from 'react'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import { useMutation } from '@tanstack/react-query'
import userBlocklistService from '../../../../services/UserBlocklist'

const ModalUserPermission = ({
  open,
  onClose,
  userRef,
  userBlockingPrevented,
  onSubmit
}) => {
  const intl = useIntl()

  const validationSchema = () => {
    return yup.object().shape({
      automaticHandlingDescription: yup
        .string()
        .test(
          'automaticHandlingDescription',
          'description is needed when blocking disabled',
          function (automaticHandlingDescription) {
            const { automaticBlockingEnabled } = this.parent
            return (
              automaticBlockingEnabled ||
              (!automaticBlockingEnabled && !!automaticHandlingDescription)
            )
          }
        )
    })
  }

  const { mutate: setBlockingPrevented, ...setBlockingPreventedMutation } =
    useMutation({
      mutationFn: (submitContent) =>
        userBlocklistService.setBlockingPrevented(
          userRef,
          submitContent.values.automaticHandlingDescription
        ),
      onSuccess: (response, variables) => {
        showLocalizedMoovyToast(intl, {
          title: 'modalUserPermission.toast.title.setBlockingPrevented',
          description:
            'modalUserPermission.toast.description.setBlockingPrevented'
        })

        const { resetForm } = variables
        submitAndReset(resetForm)
      }
    })

  const {
    mutate: removeBlockingPrevented,
    ...removeBlockingPreventedMutation
  } = useMutation({
    mutationFn: () => userBlocklistService.removeBlockingPrevented(userRef),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'modalUserPermission.toast.title.removeBlockingPrevented',
        description:
          'modalUserPermission.toast.description.removeBlockingPrevented'
      })

      const { resetForm } = variables
      submitAndReset(resetForm)
    }
  })

  const isSubmitAllowed = (values) => {
    return values.automaticBlockingEnabled === userBlockingPrevented
  }

  const handleFormitSubmit = (values, { resetForm }) => {
    if (isSubmitAllowed(values)) {
      const submitContent = {
        values: { ...values },
        resetForm: resetForm
      }

      if (values.automaticBlockingEnabled === false) {
        setBlockingPrevented(submitContent)
      } else {
        removeBlockingPrevented(submitContent)
      }
    }
  }

  const submitAndReset = (handleReset) => {
    setBlockingPreventedMutation.reset()
    removeBlockingPreventedMutation.reset()
    handleReset()
    onSubmit()
  }

  const closeAndReset = (handleReset) => {
    setBlockingPreventedMutation.reset()
    removeBlockingPreventedMutation.reset()
    handleReset()
    onClose()
  }

  const ActiveItem = ({ active, onToggleChanged }) => {
    return (
      <div>
        {active ? (
          <Icon
            name="check circle"
            color="green"
            style={{ marginRight: '0.35em' }}
          />
        ) : (
          <Icon
            color="yellow"
            name="exclamation triangle"
            style={{ marginRight: '0.35em' }}
          />
        )}
        {active ? (
          <FormattedMessage id="modalUserPermission.automaticHandlingDisabled" />
        ) : (
          <FormattedMessage id="modalUserPermission.automaticHandlingEnabled" />
        )}
        <Checkbox
          toggle
          checked={!active}
          onChange={() => onToggleChanged()}
          style={{
            verticalAlign: 'middle',
            marginTop: '-2px',
            marginLeft: '5px'
          }}
        />
      </div>
    )
  }

  return (
    <>
      <Formik
        initialValues={{
          automaticBlockingEnabled: !userBlockingPrevented,
          automaticHandlingDescription: ''
        }}
        validationSchema={validationSchema()}
        onSubmit={handleFormitSubmit}
        enableReinitialize={true}
      >
        {({ values, errors, handleSubmit, setFieldValue, handleReset }) => (
          <Modal
            open={open}
            onClose={() => closeAndReset(handleReset)}
            size={'small'}
          >
            <Modal.Header
              content={
                <FormattedMessage id="modalUserPermission.title.editUser" />
              }
            />
            <Modal.Content>
              <MutationErrorMessage
                mutation={setBlockingPreventedMutation}
                defaultErrorTextLangId="modalUserPermission.action.setPrevented.default.error"
                messageNegative
              />
              <MutationErrorMessage
                mutation={removeBlockingPreventedMutation}
                defaultErrorTextLangId="modalUserPermission.action.removePrevented.default.error"
                messageNegative
              />
              <Form onSubmit={handleSubmit}>
                <Form.Field>
                  <label>
                    <FormattedMessage id="modalUserPermission.label.pausedUser" />
                  </label>
                  <ActiveItem
                    active={values.automaticBlockingEnabled}
                    onToggleChanged={() =>
                      setFieldValue(
                        'automaticBlockingEnabled',
                        !values.automaticBlockingEnabled
                      )
                    }
                  />
                </Form.Field>
                {!userBlockingPrevented && !values.automaticBlockingEnabled && (
                  <Form.TextArea
                    label={intl.formatMessage({
                      id: 'modalUserPermission.automaticHandlingReason'
                    })}
                    name="automaticHandlingDescription"
                    onChange={(event, data) => {
                      setFieldValue(data.name, data.value)
                    }}
                    error={!!errors.automaticHandlingDescription}
                  />
                )}
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => closeAndReset(handleReset)}>
                <FormattedMessage id="common.button.cancel" />
              </Button>
              <Button
                type="submit"
                primary
                onClick={handleSubmit}
                disabled={
                  !isSubmitAllowed(values) ||
                  setBlockingPreventedMutation.isPending ||
                  removeBlockingPreventedMutation.isPending
                }
                loading={
                  setBlockingPreventedMutation.isPending ||
                  removeBlockingPreventedMutation.isPending
                }
              >
                <FormattedMessage id="common.button.save" />
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </Formik>
    </>
  )
}

export default ModalUserPermission
