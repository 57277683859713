import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Header, Icon, Segment } from 'semantic-ui-react'
import React, { useState } from 'react'

import ModalEditValidityTimes from './ModalEditValidityTimes'
import { MoovyButton } from '../../components'
import { PermissionService } from '../../services/utils'
import servicesService from '../../services/Services'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'

const ParkingServiceValidityTimesTab = ({
  serviceRef,
  parkingService,
  refresh
}) => {
  const { isSuperOperator, roles } = useAuthInfo()
  const [editModalOpen, setEditModalOpen] = useState(false)

  const intl = useIntl()

  const { mutate: updateNewValidityTimes, ...updateNewValidityTimesMutation } =
    useMutation({
      mutationFn: (JSONStructure) =>
        servicesService.updateServiceValidityTimes(
          serviceRef,
          JSONStructure ? JSON.parse(JSONStructure) : ''
        ),
      onSuccess: () => {
        setEditModalOpen(false)
        refresh()
        showLocalizedMoovyToast(intl, {
          title:
            'parkingServiceValidityTimesTab.toast.title.validityTimesUpdated',
          description:
            'parkingServiceValidityTimesTab.toast.description.validityTimesUpdated'
        })
      }
    })

  const validityTimesAsString =
    (parkingService &&
      parkingService.validityTimes &&
      JSON.stringify(parkingService.validityTimes, null, 4)) ||
    ''

  return (
    <>
      <br />
      <Grid>
        <Grid.Column floated="left" width={8}>
          <Header>
            <FormattedMessage id="parkingServiceValidityTimesTab.validityTimes" />
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={8} textAlign="right">
          {PermissionService.HasEditValidityTimes(isSuperOperator, roles) && (
            <MoovyButton onClick={() => setEditModalOpen(true)}>
              <Icon name="pencil" />
              <FormattedMessage id="common.button.modify" />
            </MoovyButton>
          )}
        </Grid.Column>
      </Grid>
      {(validityTimesAsString && (
        <Segment style={{ whiteSpace: 'pre' }}>{validityTimesAsString}</Segment>
      )) || (
        <>
          <Segment>
            <FormattedMessage id="parkingServiceValidityTimesTab.validityTimesNotDefined" />
          </Segment>
        </>
      )}
      {editModalOpen && (
        <ModalEditValidityTimes
          onClose={() => {
            updateNewValidityTimesMutation.reset()
            setEditModalOpen(false)
          }}
          validityTimesAsString={validityTimesAsString}
          onSubmit={updateNewValidityTimes}
          mutation={updateNewValidityTimesMutation}
        />
      )}
    </>
  )
}

export default ParkingServiceValidityTimesTab
