import React, { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import ModalManageSettlementRecipient from '../modalManageSettlementRecipient'
import SettlementRecipientToolbar from './settlementRecipientToolbar'
import { Tab } from 'semantic-ui-react'
import TabAdyen from './adyen/tabAdyen'
import TabAdyenNew from './adyenNew/tabAdyenNew'
import TabVisma from './visma/tabVisma'
import Toolbar from '../../../components/Toolbar'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import settlementRecipientService from '../../../services/SettlementRecipients'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'

const SettlementRecipient = () => {
  const intl = useIntl()
  const { isSuperOperator } = useAuthInfo()
  const [settlementRecipientRef] = useState(useParams().settlementRecipientRef)
  const [openModal, setOpenModal] = useState(null)

  const settlementRecipientQuery = useQuery({
    queryKey: ['fetchSettlementRecipient', settlementRecipientRef],
    queryFn: () =>
      settlementRecipientService.fetchSettlementRecipientByRef(
        settlementRecipientRef
      ),
    enabled: Boolean(isSuperOperator && settlementRecipientRef)
  })

  const settlementRecipient =
    (settlementRecipientQuery &&
      settlementRecipientQuery.data &&
      settlementRecipientQuery.data) ||
    {}

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.settlementRecipients'
      }),
      href: '/settlementRecipients'
    },
    { text: settlementRecipient.fullName, active: true }
  ])

  const panes = [
    {
      menuItem: 'Adyen',
      pane: {
        key: 'tabAdyen',
        content: <TabAdyen settlementRecipient={settlementRecipient} />
      }
    },
    {
      menuItem: 'Visma',
      pane: {
        key: 'tabVisma',
        content: (
          <TabVisma
            settlementRecipient={settlementRecipient}
            refresh={() => settlementRecipientQuery.refetch()}
          />
        )
      }
    },
    {
      menuItem: 'Adyen (uusi)',
      pane: {
        key: 'tabAdyenNew',
        content: (
          <TabAdyenNew
            settlementRecipient={settlementRecipient}
            refresh={() => settlementRecipientQuery.refetch()}
          />
        )
      }
    }
  ]

  const {
    mutate: onUpdateSettlementRecipient,
    ...updateSettlementRecipientMutation
  } = useMutation({
    mutationFn: (settlementRecipient) =>
      settlementRecipientService.updateSettlementRecipient(
        settlementRecipient.data
      ),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'settlementRecipient.action.update.success.title',
        description: 'settlementRecipient.action.update.success.description'
      })
      const { resetForm } = variables
      resetForm({})
      setOpenModal(null)
      settlementRecipientQuery.refetch()
    }
  })

  const toolbarButtons = []
  toolbarButtons.push({
    icon: 'setting',
    tooltip: intl.formatMessage({
      id: 'customers.detail.toolbar.button.editUser'
    }),
    action: () => setOpenModal(settlementRecipient)
  })

  return (
    <>
      <Toolbar
        title={settlementRecipient.companyName || ''}
        breadcrumbs={breadcrumbs}
        content={
          <SettlementRecipientToolbar
            settlementRecipient={settlementRecipient}
          />
        }
        buttons={toolbarButtons}
      />
      <Tab
        style={{
          marginLeft: '3em',
          marginRight: '3em',
          background: 'white'
        }}
        panes={panes}
        renderActiveOnly={false}
      />
      <ModalManageSettlementRecipient
        open={!!openModal}
        edit={openModal}
        onUpdateSettlementRecipient={(data) =>
          onUpdateSettlementRecipient(data)
        }
        mutation={updateSettlementRecipientMutation}
        onClose={() => {
          updateSettlementRecipientMutation.reset()
          setOpenModal(null)
        }}
      />
    </>
  )
}

export default SettlementRecipient
