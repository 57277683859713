import { Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'

const SelectProduct = ({ productOptions, value, onChange }) => {
  const intl = useIntl()

  return (
    <Form.Dropdown
      label={intl.formatMessage({
        id: 'createInvoiceModal.form.label.product'
      })}
      fluid
      value={value}
      onChange={onChange}
      placeholder={intl.formatMessage({
        id: 'createInvoiceModal.form.placeholder'
      })}
      options={productOptions}
      search
      selection
      scrolling
      error={!!!value}
      selectOnBlur={false}
    />
  )
}

SelectProduct.propTypes = {
  productOptions: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
}

export default SelectProduct
