import React from 'react'

import HeaderComponent from '../../components/HeaderComponent'
import useAuthInfo from '../../hooks/useAuthInfo'

const Header = () => {
  const { isSuperOperator, isAuthenticated, roles, userName } = useAuthInfo()

  return (
    <HeaderComponent
      userName={userName}
      isSuperOperator={isSuperOperator}
      isAuthenticated={isAuthenticated}
      roles={roles}
    />
  )
}

export default Header
