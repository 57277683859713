import { get } from '../services/utils/api-renewed'
import { useQuery } from '@tanstack/react-query'

const getServiceAgreement = (ref, operatorRealm) => {
  const url = operatorRealm
    ? `/operator/${operatorRealm}/serviceAgreements/${ref}`
    : `/administration/serviceAgreements/${ref}`
  return get(url)
}

export default function useServiceAgreement(ref, operatorRealm) {
  return useQuery({
    queryKey: ['serviceAgreement'],
    queryFn: () => getServiceAgreement(ref, operatorRealm)
  })
}
