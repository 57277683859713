import { Button, Grid, Message, Modal, Tab } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import InvoiceAdministration from './InvoiceAdministration'
import { InvoiceOperationLogTypeEnum } from '../../services/utils'
import InvoiceRefundComments from './InvoiceRefundComments'
import InvoiceReverts from './InvoiceReverts'
import InvoiceSummary from './InvoiceSummary'
import WaivingInvoice from './WaivingInvoice'
import invoiceService from '../../services/Invoicing'
import { isVismaPayment } from './helpers'
import { showLocalizedMoovyToast } from '../MoovyToast'
import VismaInvoiceAdministration from './VismaInvoiceAdministration'

const ModalInvoice = ({
  onClose,
  invoiceRef,
  operatorRealm,
  updateInvoicesCallback,
  onOpenAdyenModal
}) => {
  const [message, setMessage] = useState()
  const [openAdyenModal, setOpenAdyen] = useState(false)
  const intl = useIntl()

  const invoiceQuery = useQuery({
    queryKey: ['invoice', invoiceRef, operatorRealm],
    queryFn: () => invoiceService.fetchInvoice(invoiceRef, operatorRealm)
  })

  const invoice = invoiceQuery.data

  if (
    openAdyenModal &&
    !invoiceQuery.isLoading &&
    !invoiceQuery.isFetching &&
    invoice
  ) {
    setOpenAdyen(false)
    onOpenAdyenModal(invoice)
  }

  const invoiceReverts = useQuery({
    queryKey: ['invoiceReverts', invoiceRef, operatorRealm],
    queryFn: () =>
      invoiceService.listCardPaymentReverts(invoiceRef, operatorRealm),
    enabled: Boolean(invoice && !isVismaPayment(invoice))
  })

  const getPanes = () => {
    const tabs = []

    invoice?.operationLogs?.find(
      (item) => item.logType === InvoiceOperationLogTypeEnum.WAIVED
    ) !== undefined &&
      tabs.push({
        menuItem: intl.formatMessage({
          id: 'modalInvoice.tab.waived.title'
        }),
        render: () => (
          <Tab.Pane>
            <WaivingInvoice invoice={invoice} />
          </Tab.Pane>
        )
      })

    const isVismaInvoice = invoice && isVismaPayment(invoice)
    tabs.push({
      menuItem: intl.formatMessage({
        id: 'modalInvoice.tab.invoiceRows.title'
      }),
      render: () => (
        <Tab.Pane>
          {isVismaInvoice && (
            <VismaInvoiceAdministration
              invoice={invoice}
              operatorRealm={operatorRealm}
              setMessageCallback={setMessage}
              updateInvoicesCallback={() => {
                invoiceQuery.refetch()
                updateInvoicesCallback()
              }}
              onClose={onClose}
            />
          )}
          {invoice && !isVismaInvoice && (
            <InvoiceAdministration
              invoice={invoice}
              operatorRealm={operatorRealm}
              setMessageCallback={setMessage}
              updateInvoicesCallback={() => {
                invoiceQuery.refetch()
                invoiceReverts.refetch()
                updateInvoicesCallback()
              }}
              onOpenAdyenModal={() => {
                invoiceQuery.refetch()
                invoiceReverts.refetch()
                setOpenAdyen(true)
                showLocalizedMoovyToast(intl, {
                  title: 'modalInvoice.toast.title.paymentPostChargeSuccess',
                  description:
                    'modalInvoice.toast.description.paymentPostChargeSuccess'
                })
              }}
            />
          )}
        </Tab.Pane>
      )
    })

    if (invoiceReverts.data?.reverts?.length > 0) {
      tabs.push({
        menuItem: intl.formatMessage({
          id: 'modalInvoice.tab.refunds.title'
        }),
        render: () => (
          <Tab.Pane>
            <InvoiceReverts invoiceReverts={invoiceReverts.data.reverts} />
          </Tab.Pane>
        )
      })
    }

    if (invoiceReverts.data?.comments?.length > 0) {
      tabs.push({
        menuItem: intl.formatMessage({
          id: 'modalInvoice.tab.refundsComments.title'
        }),
        render: () => (
          <Tab.Pane>
            <InvoiceRefundComments comments={invoiceReverts.data.comments} />
          </Tab.Pane>
        )
      })
    }

    return tabs
  }

  return (
    <Modal open onClose={onClose} size="large">
      <Modal.Content>
        {message && (
          <Message info={message.info} negative={message.error}>
            <Message.Header>{message.header}</Message.Header>
            <p>{message.content}</p>
          </Message>
        )}
        <Grid container stackable columns="equal">
          <Grid.Column width={3}>
            <InvoiceSummary invoice={invoice} />
          </Grid.Column>

          <Grid.Column>
            <Tab panes={getPanes()} />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

ModalInvoice.propTypes = {
  onClose: PropTypes.func.isRequired,
  invoiceRef: PropTypes.string,
  operatorRealm: PropTypes.string,
  updateInvoicesCallback: PropTypes.func
}

export default ModalInvoice
