import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Button, Form, List } from 'semantic-ui-react'

import pricingService from '../../services/Pricing'

// These are currently only related to tariffRoleTesting, so defined here
const MOOVY_APP_USER = 'MOOVY_APP_USER'
const ANONYMOUS_USER = 'ANONYMOUS_USER'

export const TariffTestPricing = ({
  parkingRanges,
  tariffStructure,
  tariffTestError
}) => {
  const [role, setRole] = useState(MOOVY_APP_USER)
  const { mutate: testTariffPricing, ...mutation } = useMutation({
    mutationFn: (tariff) =>
      // Add the selected tariff role to each parkingRange
      pricingService.testTariffPricing(
        parkingRanges.map((range) => ({
          ...range,
          tariffRoles: [role]
        })),
        JSON.parse(tariff)
      ),
    onSuccess: () => {
      tariffTestError && tariffTestError(false)
    },
    onError: () => {
      tariffTestError && tariffTestError(true)
    }
  })

  const getHumanReadableDateTime = (dateTimeISO) => {
    const dateTimeHumanReadableFormat = 'dd.MM.yyyy HH:mm'
    const dateTime = DateTime.fromISO(dateTimeISO)
    return dateTime.toFormat(dateTimeHumanReadableFormat)
  }

  const getHumanReadableDateTimeDiff = (beginDateTimeISO, endDateTimeISO) => {
    const begin = DateTime.fromISO(beginDateTimeISO)
    const end = DateTime.fromISO(endDateTimeISO)
    const diff = end
      .diff(begin, ['days', 'hours', 'minutes', 'seconds'])
      .toObject()

    return `${diff.days}pv ${diff.hours}h ${diff.minutes}min ${diff.seconds}s`
  }

  return (
    <>
      <Form.Group>
        <Form.Field>
          <Form.Group inline>
            <label>
              <FormattedMessage id="tariffTestPricing.label.parkingRole" />
            </label>
            <Form.Radio
              label={MOOVY_APP_USER}
              value={MOOVY_APP_USER}
              checked={role === MOOVY_APP_USER}
              onChange={() => setRole(MOOVY_APP_USER)}
            />
            <Form.Radio
              label={ANONYMOUS_USER}
              value={ANONYMOUS_USER}
              checked={role === ANONYMOUS_USER}
              onChange={() => setRole(ANONYMOUS_USER)}
            />
          </Form.Group>
          <Button
            type="button"
            primary
            onClick={() => testTariffPricing(tariffStructure)}
          >
            <FormattedMessage id="tariffTestPricing.button.calculateTariff" />
          </Button>
        </Form.Field>
      </Form.Group>
      <Form.Group key="calculatedTariffPriceGroup">
        <Form.Field>
          {mutation.data ? (
            <List celled key="tariffPriceList">
              {mutation.data.map((result, index) => (
                <List.Item key={index}>
                  <List.Header>Hinta {result.price} €</List.Header>
                  {getHumanReadableDateTime(result.parkingStartTime)}
                  {' - '}
                  {getHumanReadableDateTime(result.parkingEndTime)}
                  {', '}
                  {getHumanReadableDateTimeDiff(
                    result.parkingStartTime,
                    result.parkingEndTime
                  )}
                </List.Item>
              ))}
            </List>
          ) : (
            ''
          )}
        </Form.Field>
      </Form.Group>
    </>
  )
}

export default TariffTestPricing
