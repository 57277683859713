import React, { useEffect } from 'react'
import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import useWebPaymentCancelForm from '../../hooks/useWebPaymentCancelForm'

const ModalCancelWebPayment = ({
  open,
  cancellingDebtCollection,
  onClose,
  initialLicensePlate = '',
  onSubmit
}) => {
  const intl = useIntl()
  const {
    comment,
    setComment,
    licensePlate,
    setLicensePlate,
    hasErrors,
    errors,
    reason
  } = useWebPaymentCancelForm(initialLicensePlate)

  useEffect(() => {
    setComment('')
    setLicensePlate(initialLicensePlate)
  }, [open])

  const submitHandler = () => {
    onSubmit(reason)
  }

  return (
    <Modal open={open} onClose={onClose} size="mini">
      <Modal.Header>
        <FormattedMessage
          id={
            cancellingDebtCollection
              ? 'modalCancelWebPayment.debtCollection.header'
              : 'modalCancelWebPayment.sale.header'
          }
        />
      </Modal.Header>
      <Modal.Content>
        <Form style={{ marginTop: '10px' }}>
          <Form.Input
            required
            label={intl.formatMessage({
              id: 'common.vehicles.licensePlateNumber'
            })}
            value={licensePlate}
            onChange={(e) => setLicensePlate(e.target.value)}
            error={errors.licensePlate}
          />
          <Form.TextArea
            autoFocus
            required
            label={intl.formatMessage({
              id: cancellingDebtCollection
                ? 'modalCancelWebPayment.debtCollection.comment'
                : 'modalCancelWebPayment.sale.comment'
            })}
            value={comment}
            error={errors.comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.close" />
        </Button>
        <Button primary onClick={submitHandler} disabled={hasErrors}>
          <FormattedMessage
            id={
              cancellingDebtCollection
                ? 'modalCancelWebPayment.debtCollection.submitButton'
                : 'modalCancelWebPayment.sale.submitButton'
            }
          />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalCancelWebPayment
