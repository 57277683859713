import React from 'react'

const CreditCoverCreditLossToolbar = () => {
  return (
    <div>
      <div className="toolbar-filters"></div>
      <div />
    </div>
  )
}

export default CreditCoverCreditLossToolbar
