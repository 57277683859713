import './UserGroups.scss'

import moment from 'moment'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Grid, Icon, Table } from 'semantic-ui-react'

import { MoovyButton, TablePagination, Toolbar } from '../../components'
import MoovyTextInput from '../../components/MoovyTextInput'
import useAuthInfo from '../../hooks/useAuthInfo'
import useGlobalUI from '../../hooks/useGlobalUI'
import useUserGroups from '../../hooks/useUserGroups'
import { ROLE_OPERATOR_USERGROUP_MANAGEMENT } from '../../services/utils/roles'
import {
  getDataForPage,
  getTablePaginationInitialValues,
  getTotalPages,
  tablePaginationDirections
} from '../../services/utils/tablePagination'
import ModalCreateUserGroup from './ModalCreateUserGroup'
import UserGroupInstructions from './UserGroupInstructions'

const initialState = {
  inputFilter: '',
  ...getTablePaginationInitialValues('sortByName')
}

const pageLimit = 20

const UserGroups = () => {
  const { isSuperOperator, operatorRealm, roles } = useAuthInfo()
  const navigate = useNavigate()
  const intl = useIntl()
  const [userGroupsQuery, invalidateUserGroups] = useUserGroups(operatorRealm)
  const [open, setOpen] = useState(false)

  const [state, setState] = useGlobalUI({
    key: 'UserGroups',
    initialValue: initialState
  })

  const setInputFilter = (value) =>
    setState({
      ...state,
      inputFilter: value,
      activePage: 1
    })

  const setActivePage = (page) => setState({ ...state, activePage: page })
  const setSortColumn = (sortColumn) => setState({ ...state, sortColumn })
  const setSortDirection = (direction) => setState({ ...state, direction })

  const handleRowClick = (ref) => {
    navigate(`/${isSuperOperator ? 'admin' : 'operator'}/usergroups/${ref}`)
  }

  if (userGroupsQuery.isLoading) return null

  const onHandleSort = (clickedColumn) => () => {
    if (clickedColumn === state.sortColumn) {
      const direction =
        state.direction === tablePaginationDirections.DESC
          ? 'ASC'
          : tablePaginationDirections.DESC
      setSortDirection(direction)
    } else {
      setSortDirection(tablePaginationDirections.DESC)
      setSortColumn(clickedColumn)
    }
  }

  const allowEditing =
    isSuperOperator || roles.includes(ROLE_OPERATOR_USERGROUP_MANAGEMENT)

  const sortData = (data) => {
    const { sortColumn, direction } = state
    switch (sortColumn) {
      case 'sortByName':
        return direction === tablePaginationDirections.ASC
          ? data.sort((a, b) => a.name.localeCompare(b.name))
          : data.sort((a, b) => b.name.localeCompare(a.name))
      case 'sortByOperator':
        if (direction === tablePaginationDirections.ASC) {
          return data.sort((a, b) =>
            a.operator && b.operator
              ? a.operator.name.localeCompare(b.operator.name)
              : true
          )
        }
        return data.sort((a, b) =>
          a.operator && b.operator
            ? b.operator.name.localeCompare(a.operator.name)
            : true
        )

      case 'sortByDate':
        if (direction === tablePaginationDirections.ASC) {
          return data.sort(
            (a, b) =>
              new Date(a.modificationTime) - new Date(b.modificationTime)
          )
        }
        return data.sort(
          (a, b) => new Date(b.modificationTime) - new Date(a.modificationTime)
        )

      default:
        return data
    }
  }

  const filterData = (data) => {
    const search = state.inputFilter.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
    if (search.length > 0) {
      const regex = new RegExp(search, 'i')
      const filtered = data.filter((g) =>
        g.operator
          ? regex.test(g.name) || regex.test(g.operator.name)
          : regex.test(g.name)
      )
      return filtered
    }
    return data
  }

  const { inputFilter, activePage, sortColumn, direction } = state
  const sortedData = sortData(userGroupsQuery.data)
  const filteredData = inputFilter ? filterData(sortedData) : sortedData
  const rows = getDataForPage(filteredData, activePage, pageLimit)

  const pageCount = getTotalPages(filteredData.length, pageLimit)
  return (
    <>
      <Toolbar
        title={intl.formatMessage({ id: 'userGroups.toolbar.title' })}
        content={
          <>
            <MoovyTextInput
              value={inputFilter}
              onChange={(ev, data) => setInputFilter(data.value)}
              onClear={() => setInputFilter('')}
              placeholder={intl.formatMessage({
                id: 'userGroups.toolbar.input.placeholder'
              })}
              style={{ width: '275px' }}
            />
          </>
        }
      />

      <div className="Admin--Page--Content">
        <UserGroupInstructions />
        <Grid>
          <Grid.Column floated="left" width={8}></Grid.Column>
          <Grid.Column floated="right" width={8} textAlign="right">
            {allowEditing ? (
              <MoovyButton onClick={() => setOpen(true)}>
                <Icon name="plus" />
                <FormattedMessage id="userGroups.create.button" />
              </MoovyButton>
            ) : null}
          </Grid.Column>
        </Grid>
        <ModalCreateUserGroup
          open={open}
          onClose={() => {
            invalidateUserGroups()
            setOpen(false)
          }}
        />
        <Table sortable selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={
                  sortColumn === 'sortByName'
                    ? tablePaginationDirections.stringValues[direction]
                    : null
                }
                onClick={onHandleSort('sortByName')}
              >
                <FormattedMessage id="userGroups.table.header.userGroup" />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  sortColumn === 'sortByOperator'
                    ? tablePaginationDirections.stringValues[direction]
                    : null
                }
                onClick={onHandleSort('sortByOperator')}
              >
                <FormattedMessage id="userGroups.table.header.operator" />
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  sortColumn === 'sortByDate'
                    ? tablePaginationDirections.stringValues[direction]
                    : null
                }
                onClick={onHandleSort('sortByDate')}
              >
                <FormattedMessage id="userGroups.table.header.modificationTime" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows.map((g) => (
              <Table.Row key={g.ref} onClick={() => handleRowClick(g.ref)}>
                <Table.Cell>{g.name}</Table.Cell>
                <Table.Cell>
                  {g.operator
                    ? g.operator.name
                    : intl.formatMessage({
                        id: 'userGroups.table.body.operator.undefined'
                      })}
                </Table.Cell>
                <Table.Cell>
                  {moment(g.modificationTime).format('DD.MM.YYYY HH:mm')}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
          <TablePagination
            colSpan={3}
            activePage={activePage}
            totalPages={pageCount}
            onPageChange={(e, { activePage: newPage }) => {
              setActivePage(newPage)
            }}
          />
        </Table>
      </div>
    </>
  )
}

export default UserGroups
