import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Header, Message } from 'semantic-ui-react'

export const NOTHING = 'NOTHING'
export const DONE = 'DONE'
export const PENDING = 'PENDING'

const CancelProgressInfo = ({ state, done, total, failed, messageId }) => {
  if (state === PENDING) {
    return (
      <Header as="h4" textAlign="center">
        <FormattedMessage
          id="modalCancelWebpayments.webpayment.progress.info"
          values={{ done, total }}
        />
        {Boolean(failed) && (
          <FormattedMessage
            id="modalCancelWebpayments.webpayment.progress.info.failed"
            values={{ count: failed }}
          />
        )}
      </Header>
    )
  }
  if (state === DONE) {
    return (
      <Message positive={failed === 0} negative={failed > 0}>
        <FormattedMessage id={messageId} />
      </Message>
    )
  }
  return null
}

export default CancelProgressInfo
