import {
  Card,
  Dimmer,
  Grid,
  List,
  Loader,
  Message,
  Modal,
  Tab
} from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'

import RelativeLinkForCurrentUser from '../../../../../containers/RelativeLinkForCurrentUser'
import { fetchVehiclesByUserRef } from '../../../../../services/Vehicles'
import renderPanes from './AssignmentPanes'
import { useQuery } from '@tanstack/react-query'

const AssignedSubscribedServiceModal = ({
  customer,
  subscribedServiceWithAssignments,
  closeAssignments,
  acceptAssignment,
  isLoading,
  error,
  operatorRealm,
  subscribedService,
  revokeAssignment
}) => {
  const [showEnded, setShowEnded] = useState(false)

  const vehicles = useQuery({
    queryKey: ['vehicles', customer.ref],
    queryFn: () => {
      return fetchVehiclesByUserRef(operatorRealm, customer.ref)
    }
  })

  const { serviceName, owner, assignments } = subscribedServiceWithAssignments

  const sortByStartTime = (a, b) =>
    new Date(b.startTime) - new Date(a.startTime)

  const active = assignments.filter((a) => !a.endTime).sort(sortByStartTime)
  const ended = assignments.filter((a) => a.endTime).sort(sortByStartTime)

  useEffect(() => {
    if (active.length === 0 && assignments.length > 0) {
      setShowEnded(true)
    } else {
      setShowEnded(false)
    }
  }, [assignments])

  const activeVehicles =
    subscribedService &&
    subscribedService.currentActiveVehicles &&
    vehicles.data
      ? subscribedService.currentActiveVehicles.reduce((acc, activeVehicle) => {
          if (
            vehicles.data.find((vehicle) => vehicle.ref === activeVehicle.ref)
          ) {
            acc.push(activeVehicle)
          }
          return acc
        }, [])
      : []

  const activeIndex = showEnded ? 1 : 0

  const showInactiveSubscriptionWarning =
    !isLoading &&
    subscribedService &&
    !subscribedService.active &&
    active.length > 0

  return (
    <Modal
      open
      closeOnEscape
      closeIcon="close"
      onClose={closeAssignments}
      size="small"
    >
      <Modal.Header>{serviceName}</Modal.Header>
      <Modal.Content>
        {showInactiveSubscriptionWarning && (
          <Message negative>
            <p>
              Pysäköintioikeuden myöntäjän tilaama sopimus ei ole voimassa.
              Käyttäjä ei näe eikä pysty käyttämään pysäköintioikeuttaan.
            </p>
            <p>
              Pysäköintioikeuden myöntäjän on tilattava lisää sopimuspaikkoja
              palauttaakseen käyttäjän pysäköintioikeuden voimaan.
            </p>
          </Message>
        )}
        <Grid columns={2}>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Tilaaja</Card.Header>
                <Card.Description>
                  <List>
                    <List.Item>
                      <RelativeLinkForCurrentUser to={`customers/${owner.ref}`}>
                        {owner.company} ({owner.businessCode})
                      </RelativeLinkForCurrentUser>
                    </List.Item>
                    <List.Item>
                      {owner.firstName} {owner.lastName}
                    </List.Item>
                    <List.Item>{owner.phone}</List.Item>
                    <List.Item>{owner.email}</List.Item>
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
          <Grid.Column>
            <Card fluid>
              <Card.Content>
                <Card.Header>Aktiiviset ajoneuvot</Card.Header>
                <Card.Description>
                  <List>
                    {activeVehicles.map((vehicle) => (
                      <List.Item key={vehicle.ref}>{vehicle.name}</List.Item>
                    ))}
                  </List>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Content>
        {error && (
          <Message negative>
            <p>{error}</p>
          </Message>
        )}
        <Dimmer active={isLoading}>
          <Loader />
        </Dimmer>
        <Tab
          activeIndex={activeIndex}
          panes={renderPanes({
            active,
            ended,
            onAccept: acceptAssignment,
            onRevoke: revokeAssignment,
            isSuperOperator: !operatorRealm
          })}
          onTabChange={(e, { activeIndex: newIndex }) =>
            setShowEnded(newIndex === 1)
          }
        />
      </Modal.Content>
    </Modal>
  )
}

export default AssignedSubscribedServiceModal
