import './Finances.scss'

import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DateInput } from 'semantic-ui-calendar-react'
import { Form } from 'semantic-ui-react'

import { calendarDateInputFormat } from '../../services/utils'

const FinancesFilterDateRange = ({
  startTime,
  setStartTime,
  endTime,
  setEndTime
}) => {
  const intl = useIntl()

  return (
    <Form>
      <Form.Group>
        <Form.Field>
          <label>
            <FormattedMessage id="financesFilterDateRange.label.startDate" />
          </label>
          <DateInput
            value={startTime}
            onChange={(ev, { value }) => {
              setStartTime(value)
            }}
            dateFormat={calendarDateInputFormat}
            startMode="month"
            name="startDate"
            placeholder={intl.formatMessage({
              id: 'financesFilterDateRange.label.startDate'
            })}
            iconPosition="left"
            animation="none"
            maxDate={endTime}
            closable
          />
        </Form.Field>
        <Form.Field>
          <label>
            <FormattedMessage id="financesFilterDateRange.label.endDate" />
          </label>
          <DateInput
            value={endTime}
            onChange={(ev, { value }) => setEndTime(value)}
            dateFormat={calendarDateInputFormat}
            startMode="month"
            name="endDate"
            placeholder={intl.formatMessage({
              id: 'financesFilterDateRange.label.endDate'
            })}
            iconPosition="left"
            animation="none"
            minDate={startTime}
            closable
          />
        </Form.Field>
      </Form.Group>
    </Form>
  )
}
export default FinancesFilterDateRange
