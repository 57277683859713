/**
 * Compare two search result items, used for sorting
 * @param {Object} a search result
 * @param {Object} b search result
 * @returns {Number}
 */
function compareSearchResultItem(a, b) {
  return a.title.localeCompare(b.title)
}
/** get search results, grouped by type
 *
 * @returns results in format expected by SearchField
 */
export const getResultsByType = (state) => {
  return [
    {
      type: 'OPERATOR',
      matches: getOperators(state)
    },
    {
      type: 'LOCATION',
      matches: getLocations(state)
    },
    {
      type: 'USER',
      matches: getUsers(state)
    },
    {
      type: 'ENTERPRISE_USER',
      matches: getEnterpriseUsers(state)
    },
    {
      type: 'VEHICLE',
      matches: getVehicles(state)
    }
  ].filter((item) => item.matches.length > 0)
}

const getUsers = (state) =>
  state
    .filter((match) => match.type === 'USER')
    .map((match) => ({
      type: match.type,
      ref: match.ref,
      href: `/customers/${match.ref}`,
      title: match.description,
      extra: null
    }))
    .sort(compareSearchResultItem)

const getEnterpriseUsers = (state) =>
  state
    .filter((match) => match.type === 'ENTERPRISE_USER')
    .map((match) => ({
      type: match.type,
      ref: match.ref,
      href: `/customers/${match.ref}`,
      title: match.description,
      extra: null
    }))
    .sort(compareSearchResultItem)

const getVehicles = (state) =>
  state
    .filter((match) => match.type === 'VEHICLE')
    .map((match) => ({
      type: match.type,
      ref: match.ref,
      href: `/customers/${match.parent.ref}`,
      title: match.description,
      extra: [match.parent.description]
    }))
    .sort(compareSearchResultItem)

const getLocations = (state) =>
  state
    .filter((match) => match.type === 'LOCATION')
    .map((match) => ({
      type: match.type,
      ref: match.ref,
      href: `/locations/${match.ref}`,
      title: match.description,
      extra: [match.parent.description]
    }))
    .sort(compareSearchResultItem)

const getOperators = (state) =>
  state
    .filter((match) => match.type === 'OPERATOR')
    .map((match) => ({
      type: match.type,
      ref: match.ref,
      href: `/operators/${match.ref}`,
      title: match.description,
      extra: null
    }))
    .sort(compareSearchResultItem)

export const getCategoryName = (type) =>
  ({
    USER: 'Sovelluskäyttäjä',
    ENTERPRISE_USER: 'Yrityskäyttäjä',
    VEHICLE: 'Ajoneuvo',
    LOCATION: 'Kohde',
    OPERATOR: 'Operaattori'
  }[type])
