import { Button, Form, Modal } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { MutationErrorMessage } from '../..'
import { validateEmail } from '../../../services/utils'

const ModalSendEmailReceipt = ({
  open,
  onClose,
  onSubmit,
  customerEmail,
  mutation
}) => {
  const [email, setEmail] = useState(customerEmail)
  const [error, setError] = useState(false)

  useEffect(() => {
    setEmail(customerEmail)
  }, [customerEmail])

  const resetView = () => {
    setError(false)
    mutation.reset()
    setEmail('')
  }

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => {
        resetView()
        onClose()
      }}
    >
      <Modal.Header>
        <FormattedMessage id="modalSendEmailReceipt.title" />
      </Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId="modalSendEmailReceipt.operation.emailSending.failed"
          messageNegative
        />
        <Form
          id="form-send-receipt"
          onSubmit={(e) => {
            e.preventDefault()
            if (validateEmail(email)) {
              onSubmit(email)
            } else {
              setError(true)
            }
          }}
        >
          <Form.Field error={error}>
            <label>
              <FormattedMessage id="modalSendEmailReceipt.label.email" />
            </label>
            <input
              autoFocus
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
                setError(false)
              }}
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            resetView()
            onClose()
          }}
        >
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          form="form-send-receipt"
          type="submit"
          disabled={error || mutation.isPending}
          loading={mutation.isPending}
          primary
        >
          <FormattedMessage id="modalSendEmailReceipt.button.send" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalSendEmailReceipt
