import {
  getDataForPage,
  getTotalPages,
  tablePaginationDirections
} from '../../../services/utils/tablePagination'

const filterByOperator = (operatorRef) => (item) =>
  operatorRef ? item.operator && item.operator.ref === operatorRef : true

const filterBySearchTerm = (searchTerm) => (item) =>
  searchTerm
    ? item.name.toLocaleLowerCase().includes(searchTerm) ||
      item.internalName?.toLocaleLowerCase().includes(searchTerm) ||
      item.city?.toLocaleLowerCase().includes(searchTerm) ||
      (item.operator &&
        item.operator.name.toLocaleLowerCase().includes(searchTerm))
    : true

const sortBy = (column) => {
  switch (column) {
    case 'sortByLocation':
      return (a, b) => a.name.localeCompare(b.name)
    case 'sortByCity':
      return (a, b) => a.city.localeCompare(b.city)
    case 'sortByOperator':
      return (a, b) =>
        a.operator &&
        b.operator &&
        a.operator.name.localeCompare(b.operator.name)
    default:
      return undefined
  }
}

export default (data, searchTerm, operatorRef, pagination) => {
  if (!data) {
    return []
  }

  const { sortColumn, pageLimit, activePage, direction } = pagination

  const filteredAndSorted = data
    .filter(filterByOperator(operatorRef))
    .filter(filterBySearchTerm(searchTerm.toLocaleLowerCase()))
    .sort(sortBy(sortColumn))

  const totalPages = getTotalPages(filteredAndSorted.length, pageLimit)
  const rows = getDataForPage(
    direction === tablePaginationDirections.ASC
      ? filteredAndSorted
      : filteredAndSorted.reverse(),
    activePage,
    pageLimit
  )

  return {
    rows,
    totalPages
  }
}
