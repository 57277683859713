import { Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import {
  calendarDateInputFormat,
  dateInputFormat,
  dateTimeFromFormat
} from '../../../services/utils'
import { DateInput } from 'semantic-ui-calendar-react'
import { DateTime } from 'luxon'

const CreateCustomInvoiceRowForm = ({
  values,
  onChange,
  onSubmit,
  disabled
}) => {
  const intl = useIntl()
  const incurredAt = dateTimeFromFormat(values.incurredAt, dateInputFormat)
  const incurredAtValid = incurredAt.isValid && incurredAt <= DateTime.now()
  // If user types max date by hand and it is in the future, calendar max date must match that value. Normally current date.
  const maxDate =
    incurredAt.isValid && incurredAt >= DateTime.now()
      ? incurredAt.toJSDate()
      : new Date()
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group widths="equal">
        <Form.Input
          label={intl.formatMessage({
            id: 'createInvoiceModal.form.label.sum'
          })}
          name="amount"
          value={values.amount}
          onChange={onChange}
          type="number"
        />
        <Form.Field>
          <label>
            {intl.formatMessage({
              id: 'createInvoiceModal.form.label.incurredAt'
            })}
          </label>
          <DateInput
            value={values.incurredAt}
            onChange={onChange}
            dateFormat={calendarDateInputFormat}
            startMode="month"
            name="incurredAt"
            iconPosition="left"
            animation="none"
            maxDate={maxDate}
            closable
            error={!incurredAtValid}
          />
        </Form.Field>
      </Form.Group>
      <Form.Input
        label={intl.formatMessage({
          id: 'createInvoiceModal.form.label.detail'
        })}
        name="info"
        value={values.info}
        onChange={onChange}
      />
      <Form.Button
        color="green"
        disabled={disabled || !incurredAtValid}
        content={intl.formatMessage({
          id: 'createInvoiceModal.form.button.addRow'
        })}
      />
    </Form>
  )
}

CreateCustomInvoiceRowForm.propTypes = {
  values: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    incurredAt: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default CreateCustomInvoiceRowForm
