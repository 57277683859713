import { Button, Modal, Table } from 'semantic-ui-react'
import { LicensePlateRecognitionIcon, MoovyTable } from '../../../../components'

import { FormattedMessage } from 'react-intl'
import React from 'react'
import RelativeLinkForCurrentUser from '../../../../containers/RelativeLinkForCurrentUser'
import { formatDateAndTime } from '../../../../services/utils'
import { getLpnRecognitionHistory } from '../../../../services/Vehicles'
import useAuthInfo from '../../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

const ModalLicensePlateRecognitionStateHistory = ({
  userRef,
  onClose,
  vehicleRef
}) => {
  const { operatorRealm } = useAuthInfo()

  const lpnRecognitionHistoryQuery = useQuery({
    queryKey: ['lpnRecognitionHistory', vehicleRef],
    queryFn: () => {
      return getLpnRecognitionHistory(operatorRealm, vehicleRef)
    },
    enabled: !!vehicleRef,
    refetchOnWindowFocus: false
  })

  const lpnRecognitionHistory =
    lpnRecognitionHistoryQuery?.data?.recognitionAllowedLogs || []

  const licensePlateNumber =
    lpnRecognitionHistoryQuery?.data?.vehicle?.licensePlateNumber || ''

  const RenderVehicleDetail = ({ licensePlateNumber }) => {
    return (
      <>
        <div>
          <label>
            <b>
              <FormattedMessage id="modalLicensePlateRecognitionStateHistory.label.licensePlate" />{' '}
            </b>
          </label>
          {licensePlateNumber}
        </div>
      </>
    )
  }

  return (
    <Modal open onClose={onClose} size="large">
      <Modal.Header>
        <FormattedMessage id="modalLicensePlateRecognitionStateHistory.modal.header" />
      </Modal.Header>
      <Modal.Content scrolling>
        <RenderVehicleDetail licensePlateNumber={licensePlateNumber} />
        <MoovyTable
          compact="very"
          celled
          items={lpnRecognitionHistory}
          query={lpnRecognitionHistoryQuery}
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="modalLicensePlateRecognitionStateHistory.header.edited" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="modalLicensePlateRecognitionStateHistory.header.editor" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="modalLicensePlateRecognitionStateHistory.header.cameraRecognition" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="modalLicensePlateRecognitionStateHistory.header.owner" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {lpnRecognitionHistory.map((row) => (
              <Table.Row key={row.ref}>
                <Table.Cell>
                  {formatDateAndTime(row.stateChangeTime)}
                </Table.Cell>
                <Table.Cell>{row.changeByUser?.name}</Table.Cell>
                <Table.Cell>
                  <LicensePlateRecognitionIcon
                    cameraRecognitionAllowed={
                      row.licensePlateRecognitionAllowed
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  {userRef === row.ownerUser?.ref ? (
                    row.ownerUser?.name
                  ) : (
                    <RelativeLinkForCurrentUser
                      to={`customers/${row.ownerUser?.ref}`}
                    >
                      {row.ownerUser?.name}
                    </RelativeLinkForCurrentUser>
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </MoovyTable>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalLicensePlateRecognitionStateHistory
