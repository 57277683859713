import moment from 'moment'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Button, Container, Header, Table } from 'semantic-ui-react'

import useAuthInfo from '../../../hooks/useAuthInfo'
import subscriptionsService from '../../../services/Subscriptions'
import { formatDate } from '../../../services/utils'

export default function SubscriptionsTable({
  subscriptions,
  onClick,
  onUpdate
}) {
  const intl = useIntl()

  const { operatorRealm } = useAuthInfo()
  const { mutate: restartSubscription, ...restartSubscriptiontMutation } =
    useMutation({
      mutationFn: (subscription) =>
        subscriptionsService.restartSubscription(
          subscription.ref,
          operatorRealm
        ),
      onSuccess: () => {
        onUpdate()
      }
    })

  const sorted = [...subscriptions].sort((a, b) =>
    a.active === b.active
      ? moment(b.startDate).valueOf() - moment(a.startDate).valueOf()
      : b.active - a.active
  )

  const renderButton = (subscription) =>
    /* eslint-disable no-nested-ternary */
    subscription.active ? (
      subscription.endDate ? (
        <Button
          color="green"
          loading={restartSubscriptiontMutation.isPending}
          onClick={() => restartSubscription(subscription)}
        >
          {intl.formatMessage({ id: 'subscriptionsTable.button.activate' })}
        </Button>
      ) : (
        <Button color="red" onClick={() => onClick(subscription)}>
          {intl.formatMessage({ id: 'subscriptionsTable.button.cancel' })}
        </Button>
      )
    ) : (
      <>{intl.formatMessage({ id: 'subscriptionsTable.button.orderEnded' })}</>
    )

  return (
    <>
      <Header>
        <FormattedMessage id="subscriptionsTable.title" />
      </Header>
      <Container style={{ maxHeight: '700px', overflowY: 'auto' }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="subscriptionsTable.header.orderDate" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="subscriptionsTable.header.cancelDate" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="subscriptionsTable.header.endDate" />
              </Table.HeaderCell>
              <Table.HeaderCell width="1"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sorted.map((subscription) => (
              <Table.Row key={subscription.ref} negative={!subscription.active}>
                <Table.Cell>{formatDate(subscription.startDate)}</Table.Cell>
                <Table.Cell>
                  {formatDate(subscription.cancelledTime)}
                </Table.Cell>
                <Table.Cell>{formatDate(subscription.endDate)}</Table.Cell>
                <Table.Cell>{renderButton(subscription)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    </>
  )
}
