import { cloneDeep, get, set } from 'lodash'

const jsonFields = ['location.locationAreas', 'servicePricing.tariffStructure']

function transformValues(formState, fields, transformer) {
  return fields.reduce((accumulator, valuePath) => {
    const value = get(formState, valuePath, null)
    if (!value || typeof value === 'object') {
      return accumulator
    }

    return set(accumulator, valuePath, transformer(valuePath, value))
  }, formState)
}

function jsonTransformer(path, value, strict) {
  try {
    return JSON.parse(value)
  } catch (error) {
    if (strict) {
      throw new Error(`Couldn't parse ${path} (${value}) to JSON`)
    }
    return value
  }
}

function numberTransformer(path, value, strict) {
  const transformed = Number(value)

  if (isNaN(transformed)) {
    if (strict) {
      throw new Error(`Couldn't parse ${path} (${value}) to number`)
    }

    return value
  }

  return transformed
}

export function transform(values, numberFields, strict = true) {
  const copy = cloneDeep(values)
  return {
    ...copy,
    ...transformValues(copy, jsonFields, (valuePath, value) =>
      jsonTransformer(valuePath, value, strict)
    ),
    ...transformValues(copy, numberFields, (valuePath, value) =>
      numberTransformer(valuePath, value, strict)
    )
  }
}
