import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'
import { useQueryClient, useMutation } from '@tanstack/react-query'

import InvoicingInformationByType from './InvoicingInformationByType'
import { MutationErrorMessage } from '../../../../components'
import PropTypes from 'prop-types'
import helpers from './helpers'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import userServices from '../../../../services/User/UserServices'

const EditEnterpriseInvoicingInfo = ({ invoicingInfo, onClose }) => {
  const [selectedInvoicingType, setSelectedInvoicingType] = useState(
    invoicingInfo?.type
  )
  const [ovt, setOvt] = useState(invoicingInfo?.ovt ?? '')
  const [operator, setOperator] = useState(invoicingInfo?.operator ?? '')
  const [customInvoiceReference, setCustomInvoiceReference] = useState(
    invoicingInfo?.customInvoiceReference ?? ''
  )
  const intl = useIntl()
  const queryClient = useQueryClient()
  const headerText = intl.formatMessage(
    {
      id: 'enterpriseCustomer.editInvoicing.header'
    },
    {
      company: invoicingInfo?.company
    }
  )

  const { mutate: updateInvoicing, ...updateInvoicingMutation } = useMutation({
    mutationFn: () => {
      const data = {
        billingMethod: selectedInvoicingType,
        customInvoiceReference: customInvoiceReference.trim()
      }
      if (selectedInvoicingType === helpers.DIRECT) {
        data.eInvoiceOvtNumber = ovt.trim()
        data.eInvoiceOperator = operator.trim()
      }
      return userServices.updateInvoicing(invoicingInfo.userRef, data)
    },
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'enterpriseCustomer.editInvoicing.toast.success.title',
        description: 'enterpriseCustomer.editInvoicing.toast.success.body'
      })
      onClose()
      queryClient.invalidateQueries({
        queryKey: ['customer', invoicingInfo.userRef]
      })
    }
  })

  const noChanges =
    ovt === invoicingInfo.ovt &&
    operator === invoicingInfo.operator &&
    invoicingInfo.customInvoiceReference === customInvoiceReference &&
    invoicingInfo.type === selectedInvoicingType

  const hasMissingInputs =
    selectedInvoicingType === helpers.DIRECT && (!ovt || !operator)
  const onChangeHandler = (e, data) => setSelectedInvoicingType(data.value)

  const selectableInvoicingTypes = [
    helpers.PAPER,
    helpers.EMAIL,
    helpers.DIRECT
  ]

  return (
    <Modal open onClose={onClose}>
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={updateInvoicingMutation}
          defaultErrorTextLangId="enterpriseCustomer.editInvoicing.mutation.error"
          messageNegative
        />
        <Form>
          <Form.Field
            label={intl.formatMessage({
              id: 'enterpriseCustomer.invoicing.type'
            })}
          />
          <Form.Group inline>
            {selectableInvoicingTypes.map((invoicingType) => (
              <Form.Radio
                key={invoicingType}
                label={intl.formatMessage({
                  id: `enterpriseCustomer.invoicing.type.${invoicingType}`
                })}
                value={invoicingType}
                checked={selectedInvoicingType === invoicingType}
                onChange={onChangeHandler}
              />
            ))}
          </Form.Group>
          {selectedInvoicingType === helpers.DIRECT ? (
            <>
              <Form.Group widths="equal">
                <Form.Input
                  fluid
                  label={intl.formatMessage({
                    id: 'enterpriseCustomer.invoicing.ovt'
                  })}
                  value={ovt}
                  onChange={(event) => setOvt(event.target.value)}
                  error={!ovt}
                />
                <Form.Input
                  fluid
                  label={intl.formatMessage({
                    id: 'enterpriseCustomer.invoicing.operator'
                  })}
                  value={operator}
                  onChange={(event) => setOperator(event.target.value)}
                  error={!operator}
                />
              </Form.Group>
            </>
          ) : (
            <InvoicingInformationByType
              invoicingInfo={{
                ...invoicingInfo,
                type: selectedInvoicingType
              }}
              showInfo
            />
          )}
          <Form.Input
            fluid
            label={intl.formatMessage({
              id: 'enterpriseCustomer.invoicing.customReference'
            })}
            value={customInvoiceReference}
            onChange={(event) => setCustomInvoiceReference(event.target.value)}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose} disabled={updateInvoicingMutation.isPending}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          primary
          onClick={updateInvoicing}
          disabled={noChanges || hasMissingInputs}
          loading={updateInvoicingMutation.isPending}
        >
          <FormattedMessage id="common.button.save" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

EditEnterpriseInvoicingInfo.propTypes = {
  invoicingInfo: PropTypes.shape(helpers.shape).isRequired,
  onClose: PropTypes.func.isRequired
}

export default EditEnterpriseInvoicingInfo
