import { get } from '../utils/api-renewed'

export const search = async (operatorRealm, term) => {
  try {
    const url = operatorRealm
      ? `/operator/${operatorRealm}/search`
      : `/administration/search`
    const result = await get(url, { term })
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export const searchUser = async (operatorRealm, term) => {
  try {
    const url = operatorRealm
      ? `/operator/${operatorRealm}/search/users`
      : `/administration/search/users`
    const result = await get(url, { term })
    return result
  } catch (e) {
    return Promise.reject(e)
  }
}

export const searchLocation = async (
  searchTerm,
  includeEvcAvailableOnly = undefined
) => {
  const url = `/administration/location/search`
  return await get(url, { searchTerm, includeEvcAvailableOnly })
}
