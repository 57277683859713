import { DateTime, Duration } from 'luxon'

import countryList from 'react-select-country-list'
import libphonenumber from 'google-libphonenumber'
import moment from 'moment'

/** Binds given selectors to sub-state (key)
 *
 * @template S
 * @param {S} selectors object containing selector functions
 * @returns {S}
 */
export function bindSelectorsToKey(selectors, selectorKey) {
  if (typeof selectorKey !== 'string')
    throw new Error('selectorKey should be string')

  return Object.keys(selectors).reduce((boundSelectors, selectorName) => {
    const selector = selectors[selectorName]
    boundSelectors[selectorName] = function(state, ...params) {
      return selector(state[selectorKey], ...params)
    }
    return boundSelectors
  }, {})
}

export function formatTime(dateTimeValue) {
  if (dateTimeValue) {
    const timeFormat = 'HH:mm:ss'
    return dateTimeValue.toFormat(timeFormat)
  }
  return ''
}

export function formatDateAndTime(date) {
  if (date) {
    return moment(date)
      .local()
      .format('D.M.YYYY HH:mm:ss')
  }
  return ''
}

export function formatDate(date) {
  if (date) {
    return moment(date)
      .local()
      .format('D.M.YYYY')
  }
  return ''
}

export function dateAfterNow(date) {
  return moment(date).isAfter(moment())
}

export const concatVehicles = (vehicles) => {
  const unique = vehicles.reduce((acc, value) => {
    if (acc.includes(value.name)) {
      return acc
    }
    return `${acc} ${value.name},`
  }, '')
  return unique.slice(0, -1)
}

export function filterArrayByDistinctProperty(array, property) {
  return array.reduce((accumulator, currentValue) => {
    if (
      !accumulator.find(
        (element) => element[property] === currentValue[property]
      )
    ) {
      return accumulator.concat([currentValue])
    }
    return accumulator
  }, [])
}

export const formatPrice = (price) => {
  const options = {
    useGrouping: false,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency',
    currency: 'EUR'
  }

  if (Number.isFinite(price)) {
    return price.toLocaleString('fi', options)
  }

  const parsed = Number.parseFloat(price)
  return Number.isNaN(parsed) ? price : parsed.toLocaleString('fi', options)
}

export const formatSeconds = (durationSeconds) => {
  if (!durationSeconds) {
    return '0 s'
  }
  // Shift to these units for normalization
  const duration = Duration.fromMillis(durationSeconds * 1000)
    .shiftTo('years', 'months', 'weeks', 'days', 'hours', 'minutes', 'seconds')
    .normalize()
    .toObject()

  if (duration.invalidReason) {
    return duration.invalidReason
  }

  // Todo: localization?
  const stringUnits = {
    years: 'v',
    months: 'kk',
    weeks: 'vk',
    days: 'p',
    hours: 'h',
    minutes: 'min',
    seconds: 's'
  }

  // Truncate the duration by finding the first field with actual value and splice from there
  const entries = Object.entries(duration)
  const firstWithValue = entries.findIndex(([key, value]) => value > 0)
  const formatted = entries
    .splice(firstWithValue)
    .map(([key, value]) => (value ? value + ' ' + stringUnits[key] : null))
    .join(' ')
  return formatted || '0s'
}

export const formatEnergy = (energyWh) => {
  if (!energyWh) return '0 kWh'
  let parsedEnergyWh = parseFloat(energyWh)
  if (parsedEnergyWh <= 0) return '0 kWh'

  parsedEnergyWh = parsedEnergyWh / 1000
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }

  return `${parsedEnergyWh.toLocaleString('fi', options)} kWh`
}

export const isValidPhoneNumber = (phoneNumber) => {
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance()

  try {
    return phoneUtil.isValidNumberForRegion(
      phoneUtil.parse(phoneNumber, 'FI'),
      'FI'
    )
  } catch {
    return false
  }
}

export const isValidLicensePlateNumber = (licensePlateNumber) => {
  // Just accept anything over 2 characters since that is how mobile client works too. Let backend handle converting to standard form.
  return licensePlateNumber && licensePlateNumber.length > 2
}

export const creditCardExpired = (card, now = new Date()) => {
  const { expireYear } = card
  const expireMonth = card.expireMonth - 1 // Date object has zero based months
  return (
    now.getFullYear() > expireYear ||
    (now.getFullYear() === expireYear && now.getMonth() > expireMonth)
  )
}

// Formats for converting semantic-ui-calendar-react components value to Luxon DateTimes
export const dateTimeInputFormat = 'd.M.yyyy HH:mm'
export const dateInputFormat = 'd.M.yyyy'
export const calendarDateInputFormat = 'D.M.YYYY'

export const dateTimeFromFormat = (value, format = dateTimeInputFormat) =>
  DateTime.fromFormat(value, format)

export const dateTimeInputStringToISO = (dateTime) =>
  dateTimeFromFormat(dateTime)
    .toUTC()
    .toISO()

export const convertIsoToDateTime = (dateTimeISO) => {
  if (!dateTimeISO) return ''
  const dateTime = DateTime.fromISO(dateTimeISO)
  return dateTime.toFormat(dateTimeInputFormat)
}

export const dateNowToCalendarInput = () =>
  DateTime.local().toFormat(dateInputFormat)

export const dateTimeNowToCalendarInput = () =>
  DateTime.local().toFormat(dateTimeInputFormat)

export const calendarDateInputToBackendFormat = (calendarValue) => {
  const backendDateFormat = 'yyyy-MM-dd'
  return DateTime.fromFormat(calendarValue, dateInputFormat).toFormat(
    backendDateFormat
  )
}

export const convertMinutes = (minutes) => {
  if (!minutes || minutes <= 0) {
    return '0 minuuttia'
  }

  const days = Math.floor(minutes / (60 * 24))
  const hours = Math.floor((minutes - days * 1440) / 60)
  const restMinutes = minutes - days * 24 * 60 - hours * 60

  let str = ''

  if (days > 0) {
    str = `${days} pv`
  }
  if (hours > 0) {
    str = (days > 0 && `${str} `) || str
    str = (days > 0 && restMinutes === 0 && `${str}ja `) || str
    str = `${str}${hours} h`
  }
  if (restMinutes > 0) {
    str = (str && `${str} ja ${restMinutes} min`) || `${restMinutes} min`
  }

  return str
}

// Returns localization string by given preferred language.
// errorBody object is expected to be in a backend localized error format.
export const localizedErrorString = (errorBody, preferredLang) => {
  if (
    errorBody &&
    errorBody.errorDescriptor &&
    errorBody.errorDescriptor.errorDescription
  ) {
    return errorBody.errorDescriptor.errorDescription[preferredLang]
  }

  return null
}

export const sortedVehiclesByCameraRecognition = (vehicles) => {
  if (!vehicles) {
    return []
  }

  return vehicles
    .sort((a, b) => a.licensePlateNumber.localeCompare(b.licensePlateNumber))
    .sort((a, b) =>
      a.licensePlateRecognitionPermissions
        ?.allowLicensePlateBasedParkingFacilityAccess ===
      b.licensePlateRecognitionPermissions
        ?.allowLicensePlateBasedParkingFacilityAccess
        ? 0
        : a.licensePlateRecognitionPermissions
            ?.allowLicensePlateBasedParkingFacilityAccess
        ? -1
        : 1
    )
}

export const getCountryCodeAsSelectOptions = () => {
  return countryList()
    .getData()
    .map((item) => {
      return { key: item.value, text: item.label, value: item.value }
    })
}

export const getCountryNameByCode = (countryCode) => {
  const countries = countryList()
    .getData()
    .filter((item) => item.value === countryCode)
  if (!countries || countries.length === 0) {
    console.error('Country not found by country code ', countryCode)
    return ''
  }
  if (countries.length > 1) {
    console.error('Multiple countries found by country code ', countryCode)
    return ''
  }

  return countries[0].label
}
