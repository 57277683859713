import { get } from '../utils/api-renewed'

const createPath = (relativePath) =>
  `/administration/financialReport/${relativePath}`
const download = (blob, filename) => {
  let url = window.URL.createObjectURL(blob)
  let a = document.createElement('a')
  a.href = url
  a.download = filename
  a.click()
}

const downloadSalesReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'salesReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.year}-${query.month}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing year/month query'))
}

const downloadVismaMandatorList = async (query) => {
  if (query) {
    try {
      const apiPath = 'settlementRecipientVismaInvoicingReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.from}-${query.until}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadInfoKioskReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'infoKioskReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.from}-${query.until}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadOpenBarrierlessFacilityDebtCollectionCasesReport = async (
  query
) => {
  if (query) {
    try {
      const apiPath = 'openBarrierlessFacilityDebtCollectionCasesReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.year}-${query.month}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadCompletedBarrierlessFacilityDebtCollectionCasesReport = async (
  query
) => {
  if (query) {
    try {
      const apiPath = 'completedBarrierlessFacilityDebtCollectionCasesReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.year}-${query.month}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadOverdueWebpaymentsReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'overdueWebpaymentsReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.year}-${query.month}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadCancelledBarrierlessFacilityDebtCollectionCasesReport = async (
  query
) => {
  if (query) {
    try {
      const apiPath = 'cancelledBarrierlessFacilityDebtCollectionCasesReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.year}-${query.month}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadCancelledWebPaymentsReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'cancelledWebpaymentsReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.startDate}-${query.endDate}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadAdyenPaymentReconciliationReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'adyenPaymentReconciliationReport'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.year}-${query.month}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadAdyenMoovyCreditLossByMonthReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'adyenMoovyCreditLossByMonth'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.year}-${query.month}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadAdyenMoovyReturnedCreditLossReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'adyenMoovyReturnedCreditLoss'
      get(createPath(apiPath), query).then((blob) => {
        download(blob, `${apiPath}-${query.from}-${query.until}.csv`)
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadAdyenSuccessfulPaymentsForMoovyReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'adyenSuccessfulPaymentsForMoovy'
      get(createPath(apiPath), query).then((blob) => {
        download(
          blob,
          `${apiPath}-${query.year}_${query.month}-${query.from}-${query.until}.csv`
        )
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadAdyenSuccessfulRefundsReport = async (query) => {
  if (query) {
    try {
      const apiPath = 'adyenSuccessfulRefunds'
      get(createPath(apiPath), query).then((blob) => {
        download(
          blob,
          `${apiPath}-${query.year}_${query.month}-${query.from}-${query.until}.csv`
        )
      })
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  }
  return Promise.reject(Error('Missing date range query'))
}

const downloadAdyenSuccessfulPaymentsReport = async (query) => {
  try {
    const apiPath = 'adyenSuccessfulPayments'
    await get(createPath(apiPath), query).then((blob) => {
      download(blob, `${apiPath}-${query.from}-${query.until}.csv`)
    })
    return Promise.resolve()
  } catch (e) {
    return Promise.reject(e)
  }
}

export default {
  downloadSalesReport,
  downloadVismaMandatorList,
  downloadInfoKioskReport,
  downloadOpenBarrierlessFacilityDebtCollectionCasesReport,
  downloadCompletedBarrierlessFacilityDebtCollectionCasesReport,
  downloadOverdueWebpaymentsReport,
  downloadCancelledBarrierlessFacilityDebtCollectionCasesReport,
  downloadCancelledWebPaymentsReport,
  downloadAdyenPaymentReconciliationReport,
  downloadAdyenMoovyCreditLossByMonthReport,
  downloadAdyenMoovyReturnedCreditLossReport,
  downloadAdyenSuccessfulPaymentsForMoovyReport,
  downloadAdyenSuccessfulRefundsReport,
  downloadAdyenSuccessfulPaymentsReport
}
