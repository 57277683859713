import './HeaderComponent.scss'

import React from 'react'
import { Image } from 'semantic-ui-react'

import Logo from './moovy-logo-nega_310517-10.png'

const ErrorHeader = () => (
  <div className="Header">
    <div className="Header--Nav">
      <div className="Header--Nav--Logo">
        <Image src={Logo} verticalAlign="middle" />
      </div>
    </div>
  </div>
)

export default ErrorHeader
