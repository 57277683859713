import { Button, Modal, Segment } from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import { MutationErrorMessage } from '../..'
import React from 'react'

const ModalRetryCardPaymentConfirmation = ({
  open,
  onClose,
  totalPrice,
  onSubmit,
  mutation
}) => {
  const resetView = () => {
    mutation.reset()
  }

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => {
        resetView()
        onClose()
      }}
    >
      <Modal.Header>
        <FormattedMessage id="modalRetryCardPaymentConfirmation.title" />
      </Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId="modalRetryCardPaymentConfirmation.operation.attemptCharge.failed"
          messageNegative
        />
        <Segment>
          <FormattedMessage
            id="modalRetryCardPaymentConfirmation.tryToChargeTotal"
            values={{ totalPrice }}
          />
        </Segment>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            resetView()
            onClose()
          }}
        >
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          disabled={mutation.isPending}
          loading={mutation.isPending}
          primary
          onClick={() => {
            onSubmit()
          }}
        >
          <FormattedMessage id="modalRetryCardPaymentConfirmation.button.attemptCharge" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalRetryCardPaymentConfirmation
