import React, { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Button, Form, List, Message, Modal, Popup } from 'semantic-ui-react'

import useAuthInfo from '../../hooks/useAuthInfo'
import parkingService from '../../services/Parking'
import { formatDateAndTime, validateEmail } from '../../services/utils'

const SendPaymentReceipt = ({ event, handleClose }) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [error, setError] = useState(null)
  const { operatorRealm } = useAuthInfo()

  const paid = event.paid ? event.paid : 0

  const { mutate: sendReceipt, ...mutation } = useMutation({
    mutationFn: () =>
      parkingService.sendAnonymousPaymentEventReceipt(
        event.ref,
        emailAddress,
        operatorRealm
      ),
    onSuccess: () => {
      handleClose()
    },
    onError: () => {
      setError('Kuitin lähettämisessä tapahtui virhe')
    }
  })
  const handleSubmit = (e) => {
    e.preventDefault()
    if (validateEmail(emailAddress)) {
      sendReceipt()
    } else {
      setError(`Sähköpostiosoite on virheellinen`)
    }
  }
  return (
    <Modal closeIcon="close" open centered={false} onClose={handleClose}>
      <Modal.Header>Lähetä kuitti maksutapahtumalle</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <List>
            <List.Item>
              <List.Header>Maksuaika</List.Header>
              {formatDateAndTime(event.paymentTime)}
            </List.Item>
            <List.Item>
              <List.Header>Maksettu</List.Header>
              {`${paid.toFixed(2)}€`}
            </List.Item>
          </List>
          <Form onSubmit={handleSubmit} error={error}>
            <Form.Input
              label="Sähköpostiosoite"
              value={emailAddress}
              focus
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            <Message error content={error} />
            <Button primary type="submit" loading={mutation.isPending}>
              Lähetä
            </Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Popup
          trigger={<Button onClick={handleClose}>Takaisin</Button>}
          content="Takaisin anonyymiin pysäköintiin"
        />
      </Modal.Actions>
    </Modal>
  )
}

export default SendPaymentReceipt
