import { FormattedMessage, useIntl } from 'react-intl'

import { List } from 'semantic-ui-react'
import { MoovyIconInfo } from '../../../../components'
import PropTypes from 'prop-types'
import React from 'react'
import helpers from './helpers'

const InvoicingInformationByType = ({ invoicingInfo, showInfo = false }) => {
  const intl = useIntl()
  const {
    type,
    company,
    firstName,
    lastName,
    streetAddress,
    city,
    postalCode,
    email,
    ovt,
    operator
  } = invoicingInfo

  if (type === helpers.PAPER) {
    return (
      <List>
        <List.Item>
          <List.Header>
            <MoovyIconInfo
              iconLabel={
                <FormattedMessage id="enterpriseCustomer.invoicing.address" />
              }
              visible={showInfo}
            >
              {intl.formatMessage({
                id: 'enterpriseCustomer.editInvoicing.paper.info'
              })}
            </MoovyIconInfo>
          </List.Header>
        </List.Item>
        <List.Item>{company}</List.Item>
        <List.Item>
          {firstName} {lastName}
        </List.Item>
        <List.Item>{streetAddress}</List.Item>
        <List.Item>
          {postalCode} {city}
        </List.Item>
      </List>
    )
  }
  if (type === helpers.EMAIL) {
    return (
      <List>
        <List.Item>
          <List.Header>
            <MoovyIconInfo
              iconLabel={
                <FormattedMessage id="enterpriseCustomer.invoicing.email" />
              }
              visible={showInfo}
            >
              {intl.formatMessage({
                id: 'enterpriseCustomer.editInvoicing.email.info'
              })}
            </MoovyIconInfo>
          </List.Header>
          <div style={showInfo ? { marginTop: '5px' } : null}>{email}</div>
        </List.Item>
      </List>
    )
  }

  if (type === helpers.DIRECT) {
    return (
      <List>
        <List.Item>
          <List.Header>
            <FormattedMessage id="enterpriseCustomer.invoicing.ovt" />
          </List.Header>
          {ovt}
        </List.Item>
        <List.Item>
          <List.Header>
            <FormattedMessage id="enterpriseCustomer.invoicing.operator" />
          </List.Header>
          {operator}
        </List.Item>
      </List>
    )
  }
  return null
}

InvoicingInformationByType.propTypes = {
  invoicingInfo: PropTypes.shape(helpers.shape).isRequired,
  showInfo: PropTypes.bool
}

export default InvoicingInformationByType
