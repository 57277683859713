import './InvoiceSummary.scss'

import { FormattedMessage, useIntl } from 'react-intl'
import {
  LocalizedInvoicingStatus,
  LocalizedPaymentCardCategory
} from '../../MoovyLocalizedEnum'
import {
  formatDate,
  formatPrice,
  getStringValue,
  sale,
  vismaStatus
} from '../../../services/utils'

import { List } from 'semantic-ui-react'
import { MoovyIconInfo } from '../../../components'
import React from 'react'
import RelativeLinkForCurrentUser from '../../../containers/RelativeLinkForCurrentUser'

const InvoiceSummary = ({ invoice }) => {
  const intl = useIntl()

  return !invoice ? (
    ''
  ) : (
    <List className="InvoiceSummary" relaxed>
      <List.Item className="totalHeader">
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.total.header" />
        </List.Header>
        <List.Content>
          <h1>{formatPrice(invoice.totalPrice)}</h1>
        </List.Content>
      </List.Item>
      <List.Item>
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.invoiceNumber.header" />
        </List.Header>
        <List.Content>{invoice.invoiceNumber}</List.Content>
      </List.Item>
      <List.Item>
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.paymentMethod.header" />
        </List.Header>
        <FormattedMessage
          id={getStringValue(
            sale.paymentMethodTypes.localizationKeys,
            invoice.paymentMethodType
          )}
          defaultMessage={invoice.paymentMethodType}
        />
      </List.Item>
      {invoice.paymentMethodType ===
        sale.paymentMethodTypes.PAYMENT_HIGHWAY_TRANSACTION && (
        <List.Item>
          <List.Header>
            <FormattedMessage id="invoiceModal.invoiceSummary.paymentCardCategory.header" />
          </List.Header>
          <LocalizedPaymentCardCategory value={invoice.paymentCardCategory} />
        </List.Item>
      )}
      <List.Item>
        <List.Header>
          <FormattedMessage id="invoiceModal.invoiceSummary.status.header" />
        </List.Header>
        <LocalizedInvoicingStatus
          value={invoice.status}
          paymentMethodType={invoice.paymentMethodType}
        />
      </List.Item>
      {invoice.paymentMethodType ===
        sale.paymentMethodTypes.PAYMENT_HIGHWAY_TRANSACTION && (
        <List.Item>
          <List.Header>
            <FormattedMessage id="invoiceModal.invoiceSummary.invoicingAttemptCount.header" />
          </List.Header>
          {invoice.invoicingAttemptCount}
        </List.Item>
      )}
      {invoice.paymentMethodType === sale.paymentMethodTypes.VISMA && (
        <>
          <List.Item>
            <List.Header>
              <FormattedMessage id="invoiceModal.invoiceSummary.vismaDueDate.header" />
            </List.Header>
            {invoice.vismaDueDate && formatDate(new Date(invoice.vismaDueDate))}
          </List.Item>
          <List.Item>
            <List.Header>
              <FormattedMessage id="invoiceModal.invoiceSummary.vismaStatus.header" />
            </List.Header>
            <MoovyIconInfo iconLabel={invoice.vismaStatus}>
              {intl.formatMessage({
                id: getStringValue(
                  vismaStatus.localizationKeys,
                  invoice.vismaStatus
                )
              })}
            </MoovyIconInfo>
          </List.Item>
          {invoice.compensatingInvoiceNumber && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="invoiceModal.invoiceSummary.compensatingInvoiceNumber.header" />
              </List.Header>
              {invoice.compensatingInvoiceNumber}
            </List.Item>
          )}
          {invoice.compensatedInvoice && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="invoiceModal.invoiceSummary.compensatedInvoice.header" />
              </List.Header>
              {invoice.compensatedInvoice.name}
            </List.Item>
          )}
        </>
      )}
      {invoice?.user?.ref && (
        <List.Item>
          <List.Header>
            <FormattedMessage id="invoiceModal.invoiceSummary.user.header" />
          </List.Header>
          <RelativeLinkForCurrentUser to={`customers/${invoice.user.ref}`}>
            {invoice.user.name || invoice.user.ref}
          </RelativeLinkForCurrentUser>
        </List.Item>
      )}
    </List>
  )
}

export default InvoiceSummary
