import { Form, Select } from 'semantic-ui-react'
import { MoovyTableFilter, MoovyTextInput, Toolbar } from '../../components'
import React from 'react'

import { fetchWebPaymentProducts } from '../../services/Products'
import moovyDateTime from '../../services/utils/moovyDateTime'
import settlementRecipientsService from '../../services/SettlementRecipients'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'
import { webpayment } from '../../services/utils/DTOEnums'

const WebpaymentToolbar = ({ state, onChangeFilter }) => {
  const intl = useIntl()

  const {
    licensePlateNumber,
    invoiceNumber,
    webPaymentStatus,
    settlementRecipientRef,
    productRef,
    tableFilterRange,
    tableCustomStartTime,
    tableCustomEndTime
  } = state

  const { operatorRealm } = useAuthInfo()

  const productsQuery = useQuery({
    queryKey: ['fetchWebpaymentProducts', settlementRecipientRef],
    queryFn: () =>
      fetchWebPaymentProducts(operatorRealm, settlementRecipientRef),
    staleTime: 1000 * 60 * 30, // Cache data for 30 minute
    enabled: !!settlementRecipientRef
  })

  const recipientsQuery = useQuery({
    queryKey: ['fetchWebPaymentRecipients'],
    queryFn: () => {
      const queryParams = {
        onlyDebtCollection: true
      }
      return settlementRecipientsService.fetchSettlementRecipientNames(
        queryParams,
        operatorRealm
      )
    },
    staleTime: 1000 * 60 * 30 // Cache data for 30 minute
  })

  const getSettlementRecipients = () => {
    return recipientsQuery.data
      ? recipientsQuery.data.map((item) => ({
          key: item.ref,
          text: item.fullName,
          value: item.ref
        }))
      : []
  }

  const getProducts = () => {
    return productsQuery.data
      ? productsQuery.data.map((item) => ({
          key: item.ref,
          text: item.name,
          value: item.ref
        }))
      : []
  }

  const createStatusOptions = (statuses) =>
    statuses
      .map((s) => ({
        key: webpayment.webpaymentStatus[s],
        value: webpayment.webpaymentStatus[s],
        text: intl.formatMessage({
          id: webpayment.webpaymentStatus.localizationKeys[s]
        })
      }))
      .sort((a, b) => a.text.localeCompare(b.text))

  const statusOptions = [
    {
      key: 'empty',
      value: '',
      text: ''
    }
  ].concat(
    createStatusOptions([
      webpayment.webpaymentStatus.WEBPAYMENT_PENDING,
      webpayment.webpaymentStatus.WEBPAYMENT_SUCCESSFUL,
      webpayment.webpaymentStatus.WEBPAYMENT_FAILED,
      webpayment.webpaymentStatus.WEBPAYMENT_CANCELLED,
      webpayment.webpaymentStatus.MOVED_TO_DEBT_COLLECTION,
      webpayment.webpaymentStatus.DEBT_COLLECTION_DONE,
      webpayment.webpaymentStatus.DEBT_COLLECTION_FAILED,
      webpayment.webpaymentStatus.DEBT_COLLECTION_REVOKED,
      webpayment.webpaymentStatus.WEBPAYMENT_ERROR,
      webpayment.webpaymentStatus.DEBT_COLLECTION_REQUIRES_MANUAL_STEP
    ])
  )

  return (
    <Toolbar
      title={intl.formatMessage({ id: 'sales.toolbar.title' })}
      content={
        <>
          <div>
            <MoovyTableFilter
              tableFilterRange={tableFilterRange}
              initialDateRange={{
                startTime: moovyDateTime.isoToJSDate(tableCustomStartTime),
                endTime: moovyDateTime.isoToJSDate(tableCustomEndTime)
              }}
              onSelectedDateTimeRange={(range) => {
                const createdFromTimeAsCalenderFormat =
                  moovyDateTime.dateToCalendarInput(range.startTime)
                const createdUntilTimeAsCalenderFormat =
                  moovyDateTime.dateToCalendarInput(range.endTime)

                onChangeFilter('dateRange', {
                  createdFromTime: createdFromTimeAsCalenderFormat,
                  createdUntilTime: createdUntilTimeAsCalenderFormat,
                  tableFilterRange: range.tableFilterRange,
                  tableCustomStartTime: range?.startTime?.toJSDate(),
                  tableCustomEndTime: range?.endTime?.toJSDate()
                })
              }}
            >
              <Form.Group>
                <Form.Dropdown
                  placeholder={intl.formatMessage({
                    id: 'sales.toolbar.settlementRecipient.placeholder'
                  })}
                  value={settlementRecipientRef}
                  selection
                  clearable
                  options={getSettlementRecipients()}
                  onChange={(e, data) => {
                    onChangeFilter('settlementRecipientRef', data.value)
                  }}
                  error={!settlementRecipientRef}
                  loading={recipientsQuery.isLoading}
                  disabled={recipientsQuery.isLoading}
                  selectOnBlur={false}
                />
                <Form.Dropdown
                  placeholder={intl.formatMessage({
                    id: 'sales.toolbar.product.placeholder'
                  })}
                  value={productRef}
                  selection
                  clearable
                  options={getProducts()}
                  onChange={(e, data) => {
                    onChangeFilter('productRef', data.value)
                  }}
                  error={!productRef}
                  loading={productsQuery.isInitialLoading}
                  disabled={productsQuery.isInitialLoading}
                  selectOnBlur={false}
                />
                <Form.Field>
                  <Select
                    value={webPaymentStatus}
                    clearable
                    options={statusOptions}
                    onChange={(e, { value }) =>
                      onChangeFilter('webPaymentStatus', value)
                    }
                    placeholder={intl.formatMessage({
                      id: 'sales.toolbar.status.placeholder'
                    })}
                    selectOnBlur={false}
                  />
                </Form.Field>
                <Form.Field>
                  <MoovyTextInput
                    value={licensePlateNumber}
                    onChange={(e) =>
                      onChangeFilter('licensePlateNumber', e.target.value)
                    }
                    onClear={() => onChangeFilter('licensePlateNumber', '')}
                    placeholder={intl.formatMessage({
                      id: 'sales.toolbar.licensePlate.placeholder'
                    })}
                  />
                </Form.Field>
                <Form.Field>
                  <MoovyTextInput
                    value={invoiceNumber}
                    onChange={(e) =>
                      onChangeFilter('invoiceNumber', e.target.value)
                    }
                    onClear={() => onChangeFilter('invoiceNumber', '')}
                    placeholder={intl.formatMessage({
                      id: 'sales.toolbar.invoiceNumber.placeholder'
                    })}
                    type="number"
                    min="0"
                  />
                </Form.Field>
              </Form.Group>
            </MoovyTableFilter>
          </div>
        </>
      }
    />
  )
}

export default WebpaymentToolbar
