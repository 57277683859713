import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Grid, Icon, Step } from 'semantic-ui-react'

import { settlementRecipientCompanyTypeEnum as companyTypeEnum } from '../../../../services/utils/DTOEnums'
import { stepAdyenEnum } from './tabAdyen'

const OnboardingSteps = ({
  settlementRecipient,
  finishedSteps,
  activeStep,
  onStepClicked,
  children
}) => {
  const RenderFirstStep = () => {
    switch (settlementRecipient.companyType) {
      case companyTypeEnum.BUSINESS:
        return (
          <Step
            active={activeStep === stepAdyenEnum.STEP_OWNER}
            onClick={() => onStepClicked(stepAdyenEnum.STEP_OWNER)}
            completed={finishedSteps.includes(stepAdyenEnum.STEP_OWNER)}
          >
            <Icon name="address card" />
            <Step.Content>
              <Step.Title>
                <FormattedMessage id="onboardingSteps.step.beneficialOwners" />
              </Step.Title>
            </Step.Content>
          </Step>
        )
      case companyTypeEnum.PUBLIC_COMPANY:
        return (
          <Step
            active={activeStep === stepAdyenEnum.STEP_OWNER}
            onClick={() => onStepClicked(stepAdyenEnum.STEP_OWNER)}
            completed={finishedSteps.includes(stepAdyenEnum.STEP_OWNER)}
          >
            <Icon name="address card" />
            <Step.Content>
              <Step.Title>
                <FormattedMessage id="onboardingSteps.step.signatories" />
              </Step.Title>
            </Step.Content>
          </Step>
        )
      default:
        return ''
    }
  }

  return (
    <Grid columns={2}>
      <Grid.Column widescreen={3} largeScreen={5} computer={5} tablet={6}>
        <Step.Group vertical>
          <RenderFirstStep />
          <Step
            active={activeStep === stepAdyenEnum.STEP_ACCOUNT_HOLDER}
            onClick={() => onStepClicked(stepAdyenEnum.STEP_ACCOUNT_HOLDER)}
            completed={finishedSteps.includes(
              stepAdyenEnum.STEP_ACCOUNT_HOLDER
            )}
          >
            <Icon name="signup" />
            <Step.Content>
              <Step.Title>
                <FormattedMessage id="onboardingSteps.step.accountHolder" />
              </Step.Title>
            </Step.Content>
          </Step>
          <Step
            active={activeStep === stepAdyenEnum.STEP_ONBOARDING_LINK}
            onClick={() => onStepClicked(stepAdyenEnum.STEP_ONBOARDING_LINK)}
            completed={finishedSteps.includes(
              stepAdyenEnum.STEP_ONBOARDING_LINK
            )}
          >
            <Icon name="linkify" />
            <Step.Content>
              <Step.Title>
                <FormattedMessage id="onboardingSteps.step.onboardingLink" />
              </Step.Title>
            </Step.Content>
          </Step>
        </Step.Group>
      </Grid.Column>
      <Grid.Column widescreen={13} largeScreen={11} computer={11} tablet={10}>
        {children}
      </Grid.Column>
    </Grid>
  )
}

export default OnboardingSteps
