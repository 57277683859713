import { MoovyTable, TablePagination } from '../../../../components'
import {
  UserBlockingHistoryTypeEnum,
  getStringValue
} from '../../../../services/utils'

import { FormattedMessage } from 'react-intl'
import React from 'react'
import { Table } from 'semantic-ui-react'
import { formatDateAndTime } from '../../../../services/utils'
import moovyPagination from '../../../../components/TablePagination/pagination'

const PauseAndBlocklistTable = ({
  pauseAndBlocklistItems,
  pauseAndBlocklistQuery,
  onPageChange,
  totalPages,
  activePage,
  sorting,
  onSort
}) => {
  return (
    <MoovyTable
      compact="very"
      celled
      items={pauseAndBlocklistItems}
      query={pauseAndBlocklistQuery}
      sortable
    >
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell
            width={2}
            sorted={moovyPagination.getSortDirection('sortByBegin', sorting)}
            onClick={() =>
              moovyPagination.sortColumn('sortByBegin', sorting, onSort)
            }
          >
            <FormattedMessage id="pauseAndBlocklistTable.header.blockBegin" />
          </Table.HeaderCell>
          <Table.HeaderCell
            width={2}
            sorted={moovyPagination.getSortDirection('sortByEnd', sorting)}
            onClick={() =>
              moovyPagination.sortColumn('sortByEnd', sorting, onSort)
            }
          >
            <FormattedMessage id="pauseAndBlocklistTable.header.blockEnd" />
          </Table.HeaderCell>

          <Table.HeaderCell
            collapsing
            sorted={moovyPagination.getSortDirection(
              'sortByCreatedUserEmail',
              sorting
            )}
            onClick={() =>
              moovyPagination.sortColumn(
                'sortByCreatedUserEmail',
                sorting,
                onSort
              )
            }
          >
            <FormattedMessage id="pauseAndBlocklistTable.header.blockCreatedByUserEmail" />
          </Table.HeaderCell>

          <Table.HeaderCell
            collapsing
            sorted={moovyPagination.getSortDirection(
              'sortByModifiedUserEmail',
              sorting
            )}
            onClick={() =>
              moovyPagination.sortColumn(
                'sortByModifiedUserEmail',
                sorting,
                onSort
              )
            }
          >
            <FormattedMessage id="pauseAndBlocklistTable.header.blockModifiedByUserEmail" />
          </Table.HeaderCell>
          <Table.HeaderCell
            width={2}
            sorted={moovyPagination.getSortDirection('sortByType', sorting)}
            onClick={() =>
              moovyPagination.sortColumn('sortByType', sorting, onSort)
            }
          >
            <FormattedMessage id="pauseAndBlocklistTable.header.blockType" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={moovyPagination.getSortDirection('sortByComment', sorting)}
            onClick={() =>
              moovyPagination.sortColumn('sortByComment', sorting, onSort)
            }
          >
            <FormattedMessage id="pauseAndBlocklistTable.header.comment" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {pauseAndBlocklistItems.map((row) => (
          <Table.Row key={row.index}>
            <Table.Cell>{formatDateAndTime(row.begin)}</Table.Cell>
            <Table.Cell>{formatDateAndTime(row.end)}</Table.Cell>
            <Table.Cell>{row.createdUser?.email}</Table.Cell>
            <Table.Cell>{row.modifiedUser?.email}</Table.Cell>
            <Table.Cell>
              <FormattedMessage
                id={getStringValue(
                  UserBlockingHistoryTypeEnum.localizationKeys,
                  row.type
                )}
              />
            </Table.Cell>
            <Table.Cell>{row.comment}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TablePagination
        colSpan={6}
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </MoovyTable>
  )
}

export default PauseAndBlocklistTable
