import React from 'react'
import useAuthInfo from '../hooks/useAuthInfo'
import RedirectUser from './RedirectUser'

function RequireRoles({ children, roles = [] }) {
  const { isInitialized, roles: userRoles } = useAuthInfo()

  if (!isInitialized) return null

  if (!roles.every((requiredRole) => userRoles.includes(requiredRole))) {
    return <RedirectUser />
  }

  return children
}

export default RequireRoles
