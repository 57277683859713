import { Button, Item, List } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import {
  MoovyModalConfirmation,
  showLocalizedMoovyToast
} from '../../components'
import useAuthInfo from '../../hooks/useAuthInfo'
import userBlocklistService from '../../services/UserBlocklist'
import userService from '../../services/User/UserServices'

const UserStatus = ({ user, onRefreshUser }) => {
  const intl = useIntl()
  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const [confirmDeletionRequest, setConfirmDeletionRequest] = useState(false)

  const blockingPreventedQuery = useQuery({
    queryKey: ['blockingPreventedQuery', user?.ref],
    queryFn: () =>
      userBlocklistService.fetchBlockingPrevented(user.ref, operatorRealm),
    enabled: !!user?.ref
  })

  const renderBlockListStatus = () =>
    user.activeBlockListEntry ? (
      <>
        <List.Item>
          <List.Icon
            name="remove circle"
            color="red"
            style={{ marginRight: '0.35em' }}
            verticalAlign="middle"
          />
          <List.Content>
            <FormattedMessage
              className="BlockListed"
              id="customers.detail.toolbar.contact.userIsBlocked"
              values={{
                reason: intl.formatMessage({
                  id: user.activeBlockListEntry.allowAutomaticUnblock
                    ? 'blocklist.allowAutomaticUnblock.true'
                    : 'blocklist.allowAutomaticUnblock.false'
                })
              }}
            />
            <p>
              <FormattedMessage
                id={
                  user.activeBlockListEntry.allowAutomaticUnblock
                    ? 'blocklist.allowAutomaticUnblock.true'
                    : 'blocklist.allowAutomaticUnblock.false'
                }
              />
            </p>
          </List.Content>
        </List.Item>
      </>
    ) : (
      <List.Item>
        <List.Icon
          name="check circle"
          color="green"
          style={{ marginRight: '0.35em' }}
        />{' '}
        <List.Content>
          <FormattedMessage id="customers.detail.toolbar.contact.userIsNotBlocked" />
        </List.Content>
      </List.Item>
    )

  const {
    mutate: cancelUserDeletionRequest,
    ...cancelUserDeletionRequestMutation
  } = useMutation({
    mutationFn: (userRef) => userService.cancelUserDeletionRequest(userRef),
    onSuccess: () => {
      onRefreshUser()
      setConfirmDeletionRequest(false)
      showLocalizedMoovyToast(intl, {
        title:
          'customers.detail.toolbar.cancelDeletionRequest.action.success.title',
        description:
          'customers.detail.toolbar.cancelDeletionRequest.action.success.description'
      })
    }
  })

  const renderDeletionRequest = () => (
    <>
      <List.Item>
        <Item>
          <Item.Content>
            <List.Icon
              name="warning"
              color="red"
              style={{ marginRight: '0.35em' }}
            />{' '}
            <FormattedMessage id="customers.detail.toolbar.user.requestedUserDeletion" />
          </Item.Content>
        </Item>
      </List.Item>
      <List.Item>
        <Button
          color="red"
          basic
          onClick={() => setConfirmDeletionRequest(true)}
          loading={cancelUserDeletionRequestMutation.isPending}
          disabled={cancelUserDeletionRequestMutation.isPending}
        >
          <FormattedMessage id="customers.detail.toolbar.user.cancelUserDeletion" />
        </Button>
      </List.Item>
    </>
  )

  const renderBlockingPrevented = () => (
    <>
      {blockingPreventedQuery.data === true && (
        <List.Item>
          <Item>
            <Item.Content>
              <List.Icon
                name="exclamation triangle"
                color="yellow"
                style={{ marginRight: '0.35em' }}
              />{' '}
              <FormattedMessage id="customers.detail.toolbar.user.blockingPrevented" />
            </Item.Content>
          </Item>
        </List.Item>
      )}
    </>
  )

  return (
    <>
      <List relaxed verticalAlign="bottom">
        <List.Item>Sovelluskäyttäjä</List.Item>
        {user.active !== true && (
          <List.Item className="DeletedUser">
            <List.Icon className="DeletedUser" name="x" />
            <FormattedMessage id="customers.detail.toolbar.user.userDeleted" />
          </List.Item>
        )}
        {renderBlockListStatus()}
        {renderBlockingPrevented()}
        {isSuperOperator &&
          user.userDeletionRequested &&
          renderDeletionRequest()}
      </List>
      <MoovyModalConfirmation
        open={confirmDeletionRequest}
        onClose={() => setConfirmDeletionRequest(false)}
        lang={{
          titleElement: (
            <FormattedMessage
              id="customers.detail.toolbar.cancelDeletionRequest.confirmation.title"
              values={{ name: user.name }}
            />
          ),
          bodyElement: (
            <FormattedMessage id="customers.detail.toolbar.cancelDeletionRequest.confirmation.body" />
          ),

          buttonConfirmKey: 'common.button.confirm',
          defaultErroKey:
            'customers.detail.toolbar.cancelDeletionRequest.action.error'
        }}
        onSubmit={() => cancelUserDeletionRequest(user.ref)}
        mutation={cancelUserDeletionRequestMutation}
      />
    </>
  )
}

export default UserStatus
