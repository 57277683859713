import React, { useState } from 'react'

import CreditCoverCreditLossTable from './creditCoverCreditLossTable'
import ModalCoverCreditLoss from './modalCoverCreditLoss'
import { ROLE_FINANCES } from '../../services/utils'
import adyenSRService from '../../services/Adyen/SettlementRecipients'
import moovyPagination from '../../components/TablePagination/pagination'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useIntl } from 'react-intl'
import { useMutation, useQuery } from '@tanstack/react-query'

const CreditCoverCreditLossContent = () => {
  const intl = useIntl()
  const { isSuperOperator, roles } = useAuthInfo()
  const pageLimit = 20

  const [state, setState] = useGlobalUI({
    key: 'creditCoverCreditLossList',
    initialValue: {
      ...moovyPagination.getInitialValues('settlementRecipientName'),
      direction: moovyPagination.directions.DESC
    }
  })

  const [selectedCreditLoss, setSelectedCreditLoss] = useState(null)

  const creditCoverCreditLossQuery = useQuery({
    queryKey: [
      'creditCoverCreditLossQuery',
      state.activePage,
      state.sort,
      state.direction
    ],
    queryFn: () => {
      const queryParams = {
        lastMonth: true,
        page: state.activePage - 1,
        limit: pageLimit,
        sort: state.sort,
        direction: state.direction
      }
      return adyenSRService.getSettlementRecipientCreditLosses(queryParams)
    }
  })

  const { mutate: coverCreditLoss, ...coverCreditLossMutation } = useMutation({
    mutationFn: (data) =>
      adyenSRService.coverSettlementRecipientCreditLoss(
        data.settlemenRecipientRef,
        data,
        null
      ),
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'modalCoverCreditLoss.action.coverCreditLoss.success.title',
        description:
          'modalCoverCreditLoss.action.coverCreditLoss.success.description'
      })
      setSelectedCreditLoss(null)
      creditCoverCreditLossQuery.refetch()
    }
  })

  const totalPages = moovyPagination.getTotalPages(
    creditCoverCreditLossQuery.data?.pageable
  )

  const creditLossItems = creditCoverCreditLossQuery.data?.content ?? []
  const dateRange =
    creditCoverCreditLossQuery.data?.month +
    '/' +
    creditCoverCreditLossQuery.data?.year

  const onPageChange = (e, { activePage }) => {
    setState({
      ...state,
      activePage
    })
    setMultiSelect([])
  }

  const setSort = (sort, direction) => {
    setState({ ...state, sort, direction })
  }

  return (
    <>
      {roles.includes(ROLE_FINANCES) && isSuperOperator && (
        <>
          <CreditCoverCreditLossTable
            tableItems={creditLossItems}
            creditCoverCreditLossQuery={creditCoverCreditLossQuery}
            dateRange={{
              month: creditCoverCreditLossQuery.data?.month,
              year: creditCoverCreditLossQuery.data?.year
            }}
            onPageChange={onPageChange}
            onClickedCreditLoss={(creditLoss) =>
              setSelectedCreditLoss(creditLoss)
            }
            totalPages={totalPages}
            activePage={state.activePage}
            sorting={{
              sort: state.sort,
              direction:
                moovyPagination.directions.stringValues[state.direction]
            }}
            onSort={setSort}
          />
          {selectedCreditLoss && (
            <ModalCoverCreditLoss
              onClose={() => {
                setSelectedCreditLoss(null)
              }}
              creditLossItem={{
                dateRange: dateRange,
                description: intl.formatMessage(
                  {
                    id: 'modalCoverCreditLoss.operation.description'
                  },
                  { dateRange: dateRange }
                ),
                ...selectedCreditLoss
              }}
              coverCreditLoss={(creditLossItem) => {
                coverCreditLoss({
                  settlemenRecipientRef: creditLossItem.settlementRecipientRef,
                  amount: creditLossItem.creditLoss,
                  description: creditLossItem.description
                })
              }}
              coverCreditLossMutation={coverCreditLossMutation}
            />
          )}
        </>
      )}
    </>
  )
}

export default CreditCoverCreditLossContent
