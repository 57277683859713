import { Container, Icon, Loader, Table } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'

import { FormattedMessage } from 'react-intl'
import { MoovyPlaceholderLoader } from '../..'
import { formatPrice } from '../../../services/utils'
import invoiceService from '../../../services/Invoicing'
import moovyDateTime from '../../../services/utils/moovyDateTime'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

export const AdyenPaymentEventStatusEnum = {
  CREATED: 'Created',
  RECEIVED: 'Received',
  SUCCESS: 'Success',
  ERROR: 'Error'
}

export const isAllSuccessRefundInvoiceRows = (refundInvoice) => {
  return !refundInvoice?.refundAttempts
    ?.map(
      (refundAttempt) =>
        refundAttempt?.adyenPaymentEventStatus ===
        AdyenPaymentEventStatusEnum.SUCCESS
    )
    .includes(false)
}

export const isAnyErrorRefundInvoiceRows = (refundInvoice) => {
  return !!refundInvoice?.refundAttempts
    ?.map(
      (refundAttempt) =>
        refundAttempt?.adyenPaymentEventStatus ===
        AdyenPaymentEventStatusEnum.ERROR
    )
    .includes(true)
}

export const isAnyCreatedOrReceivedRefundInvoiceRows = (refundInvoice) => {
  return !!refundInvoice?.refundAttempts
    ?.map(
      (refundAttempt) =>
        refundAttempt?.adyenPaymentEventStatus ===
          AdyenPaymentEventStatusEnum.RECEIVED ||
        refundAttempt?.adyenPaymentEventStatus ===
          AdyenPaymentEventStatusEnum.CREATED
    )
    .includes(true)
}

const TabAdyenRefunds = ({ refundInvoice, originalInvoice }) => {
  const [refundAttempts, setRefundAttempts] = useState([])
  const { operatorRealm } = useAuthInfo()

  const originalInvoicesRowsQuery = useQuery({
    queryKey: ['originalInvoicesRows', originalInvoice?.ref],
    queryFn: () =>
      invoiceService.fetchInvoiceRows(originalInvoice.ref, operatorRealm),
    enabled: Boolean(refundInvoice && !!originalInvoice?.ref)
  })

  useEffect(() => {
    if (originalInvoicesRowsQuery.isSuccess) {
      setRefundAttempts(
        refundInvoice?.refundAttempts
          .map((refundAttempt) => {
            return {
              attemptTimestamp: refundAttempt.timestamp,
              refundingInvoiceNumber: refundAttempt.refundingInvoiceNumber,
              adyenPaymentEventStatus: refundAttempt.adyenPaymentEventStatus,
              totalRefundedAmount: refundAttempt.totalRefundedAmount,
              comment: refundAttempt?.comments
                ?.map((item) => item.comment)
                .join(', '),
              refundedRows: refundAttempt?.refundedRows?.map(
                (refundInvoiceRow) => {
                  const mappingOriginalInvoiceRow =
                    originalInvoicesRowsQuery.data?.find(
                      (item) => item.ref === refundInvoiceRow.invoiceRowRef
                    )
                  return {
                    originalInvoiceRow: {
                      ref: refundInvoiceRow.invoiceRowRef,
                      rowNumber: mappingOriginalInvoiceRow.rowNumber,
                      product: {
                        ...mappingOriginalInvoiceRow?.product
                      }
                    },
                    amount: refundInvoiceRow.amount
                  }
                }
              )
            }
          })
          .sort(
            (a, b) =>
              new Date(b.attemptTimestamp) - new Date(a.attemptTimestamp)
          )
      )
    } else if (originalInvoicesRowsQuery.isError) {
      setRefundAttempts([])
    }
  }, [originalInvoicesRowsQuery.status])

  const totalRefundedAmount = refundAttempts
    ?.map((refundAttempt) => refundAttempt.totalRefundedAmount)
    ?.reduce((partialSum, value) => partialSum + value, 0)

  const isLoading =
    originalInvoicesRowsQuery.isLoading || originalInvoicesRowsQuery.isFetching

  const RefundIcon = ({ adyenPaymentEventStatus }) => {
    switch (adyenPaymentEventStatus) {
      case 'Error':
        return <Icon name="warning" color={'red'} />
      case 'Received':
        return <Icon name="time" color={'yellow'} />
      default:
        return ''
    }
  }

  return (
    <>
      <Container className="invoiceRowTableContainer">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="tabAdyenRefundInvoice.table.header.invoiceRow" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="tabAdyenRefundInvoice.table.header.created" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="tabAdyenRefundInvoice.table.header.status" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="tabAdyenRefundInvoice.table.header.service" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="tabAdyenRefundInvoice.table.header.refundNumber" />{' '}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="tabAdyenRefundInvoice.table.header.comment" />{' '}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="tabAdyenRefundInvoice.table.header.price" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body key="adyenRefunds">
            {refundAttempts?.map((refundAttempt) =>
              refundAttempt?.refundedRows.map((refundedRow) => (
                <Table.Row
                  key={
                    refundAttempt.refundingInvoiceNumber +
                    refundedRow.originalInvoiceRow.rowNumber
                  }
                >
                  <Table.Cell collapsing>
                    {refundedRow.originalInvoiceRow.rowNumber}
                  </Table.Cell>
                  <Table.Cell singleLine>
                    {moovyDateTime.backendDateTimeToUI(
                      refundAttempt.attemptTimestamp
                    )}
                  </Table.Cell>
                  <Table.Cell singleLine>
                    <div>
                      <RefundIcon
                        adyenPaymentEventStatus={
                          refundAttempt.adyenPaymentEventStatus
                        }
                      />
                      {refundAttempt.adyenPaymentEventStatus}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    {refundedRow.originalInvoiceRow.product.name}
                  </Table.Cell>
                  <Table.Cell collapsing>
                    {refundAttempt.refundingInvoiceNumber}
                  </Table.Cell>
                  <Table.Cell>{refundAttempt.comment}</Table.Cell>
                  <Table.Cell collapsing>
                    {formatPrice(refundedRow.amount)}
                  </Table.Cell>
                </Table.Row>
              ))
            )}
          </Table.Body>
          {!isLoading && (
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="6">
                  <b>
                    <FormattedMessage id="tabAdyenRefundInvoice.table.footer.total" />{' '}
                  </b>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <b>{formatPrice(totalRefundedAmount)}</b>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          )}
        </Table>
        {isLoading && (
          <>
            <MoovyPlaceholderLoader paragraphs={2} />
            <Loader active />
          </>
        )}
      </Container>
    </>
  )
}

export default TabAdyenRefunds
