import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import FormMinutes from './formMinutes'
import { Formik } from 'formik'
import { MutationErrorMessage } from '../../../components'
import locationsService from '../../../services/Locations'
import { useQuery } from '@tanstack/react-query'
import { validationSchema } from './formValidationSchema'
import { voucher as voucherEnums } from '../../../services/utils/DTOEnums'

const VoucherTemplateManageModal = ({
  mutation,
  mutationDefaultErrorMsgId,
  voucherTemplate,
  operators,
  onSaveVoucherTemplate,
  onClose
}) => {
  const intl = useIntl()

  const allBenefitTypeOptions = [
    voucherEnums.benefitTypes.TIME,
    voucherEnums.benefitTypes.MONEY,
    voucherEnums.benefitTypes.PERIOD,
    voucherEnums.benefitTypes.PERCENT
  ].map((type) => ({
    key: type,
    text: voucherEnums.benefitTypes.stringValues[type],
    value: type
  }))

  const allCategoryTypeOptions = [
    voucherEnums.categoryTypes.BENEFIT,
    voucherEnums.categoryTypes.PREBOOKING,
    voucherEnums.categoryTypes.LOW_EMISSION_VEHICLE,
    voucherEnums.categoryTypes.HYPERIN_SINGLE_USE
  ].map((type) => ({
    key: type,
    text: voucherEnums.categoryTypes.stringValues[type],
    value: type
  }))

  const allGrantTypeOptions = [
    voucherEnums.grantTypes.PHONE_NUMBER,
    voucherEnums.grantTypes.LICENSE_PLATE_NUMBER,
    voucherEnums.grantTypes.ALL
  ].map((type) => ({
    key: type,
    value: type,
    text: voucherEnums.grantTypes.stringValues[type]
  }))

  const operatorOptions = operators.map((operator) => ({
    key: operator.ref,
    text: operator.name,
    value: operator.ref
  }))

  // 'BENEFIT' category vouchers can be granted freely. Other categories need licenseplate so remove phone only option
  const getGrantTypeOptions = (categoryType) => {
    return categoryType === voucherEnums.categoryTypes.BENEFIT
      ? allGrantTypeOptions
      : allGrantTypeOptions.filter(
          (type) => type.value !== voucherEnums.grantTypes.PHONE_NUMBER
        )
  }

  const editVoucherTemplate = !!voucherTemplate?.operator?.ref
  const createFormData = (voucherTemplate) => {
    const {
      ref,
      benefitType,
      categoryType,
      grantedDurationInMinutes,
      grantedValue,
      grantedDiscountPercent,
      salePrice,
      validLocationsDescription,
      customDescription,
      allowedGrantType,
      validityInMinutes,
      active
    } = voucherTemplate
    return {
      ref,
      benefitType,
      categoryType,
      grantedDurationInMinutes: grantedDurationInMinutes || 0,
      grantedValue: grantedValue || 0,
      grantedDiscountPercent: grantedDiscountPercent || 0,
      salePrice: salePrice || 0,
      validLocationsDescription,
      customDescription,
      allowedGrantType,
      validityInMinutes: validityInMinutes || 0,
      operatorRef: voucherTemplate.operator.ref,
      validityLocationRefs: voucherTemplate.validityLocationRefs.map(
        (loc) => loc.ref
      ),
      active: !editVoucherTemplate ? true : active
    }
  }
  const initialFormValues = createFormData(voucherTemplate)

  const [selectedOperatorRef, setSelectedOperatorRef] = useState(
    (voucherTemplate.operator && voucherTemplate.operator.ref) || ''
  )
  const [benefitTypeOptions, setBenefitTypeOptions] = useState(
    (editVoucherTemplate && allBenefitTypeOptions) || []
  )
  const [grantTypeOptions, setGrantTypeOptions] = useState(
    (editVoucherTemplate &&
      getGrantTypeOptions(voucherTemplate.categoryType)) ||
      []
  )

  const locationsQuery = useQuery({
    queryKey: ['fetchLocationsByOperator', selectedOperatorRef],
    queryFn: () =>
      locationsService.fetchLocationsForOperator(selectedOperatorRef),
    enabled: !!selectedOperatorRef
  })

  const locationOptions =
    locationsQuery.data?.content.map((location) => ({
      key: location.ref,
      value: location.ref,
      text: location.internalName
        ? `${location.name}, ${location.internalName}`
        : location.name
    })) ?? []

  // Called when the dialog form is submitted.
  const onSubmitForm = (values) => {
    const {
      ref,
      benefitType,
      categoryType,
      grantedDurationInMinutes,
      grantedValue,
      grantedDiscountPercent,
      salePrice,
      validLocationsDescription,
      customDescription,
      allowedGrantType,
      validityInMinutes,
      operatorRef,
      validityLocationRefs,
      active
    } = values

    // Hyperin voucher is valid for the same time as it grants free parking
    let validTime =
      categoryType === voucherEnums.categoryTypes.HYPERIN_SINGLE_USE
        ? grantedDurationInMinutes
        : validityInMinutes

    onSaveVoucherTemplate({
      ref,
      benefitType,
      categoryType,
      grantedDurationInMinutes,
      grantedValue,
      grantedDiscountPercent,
      salePrice,
      validLocationsDescription,
      customDescription,
      allowedGrantType,
      validityInMinutes: validTime,
      operatorRef,
      validityLocationRefs,
      active
    })
  }

  const onChangeCategoryType = (name, categoryType, setFieldValue) => {
    let allowedBenefitType = ''

    // Set category type
    setFieldValue(name, categoryType)

    // Set benefit type options
    switch (categoryType) {
      case voucherEnums.categoryTypes.PREBOOKING:
        allowedBenefitType = voucherEnums.benefitTypes.PERIOD
        break
      case voucherEnums.categoryTypes.LOW_EMISSION_VEHICLE:
        allowedBenefitType = voucherEnums.benefitTypes.PERCENT
        break
      case voucherEnums.categoryTypes.HYPERIN_SINGLE_USE:
        allowedBenefitType = voucherEnums.benefitTypes.TIME
        break
    }
    if (allowedBenefitType === '') {
      setBenefitTypeOptions(allBenefitTypeOptions)
    } else {
      setBenefitTypeOptions(
        allBenefitTypeOptions.filter(
          (benefit) => benefit.value === allowedBenefitType
        )
      )
    }
    setFieldValue('benefitType', '', false)
    setGrantTypeOptions(getGrantTypeOptions(categoryType))
    setFieldValue('allowedGrantType', '', false)
  }

  const onChangeBenefitType = (name, benefitType, setFieldValue) => {
    setFieldValue(name, benefitType)
    setFieldValue('grantedValue', 0, false)
    setFieldValue('grantedDiscountPercent', 0, false)
    setFieldValue('grantedDurationInMinutes', 0, false)
  }

  const onChangeOperator = (name, operatorRef, setFieldValue) => {
    setFieldValue(name, operatorRef)
    setFieldValue('validityLocationRefs', [], false)
    setSelectedOperatorRef(operatorRef)
  }

  return (
    <Formik
      initialValues={initialFormValues}
      validationSchema={validationSchema()}
      onSubmit={(values, actions) => {
        onSubmitForm(values, actions)
      }}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue
      }) => (
        <Modal open onClose={onClose}>
          <Modal.Header
            content={
              !editVoucherTemplate
                ? intl.formatMessage({
                    id: 'voucherTemplateManageModal.header.createTemplate'
                  })
                : intl.formatMessage({
                    id: 'voucherTemplateManageModal.header.editTemplate'
                  })
            }
          />
          <Modal.Content>
            <Form onSubmit={handleSubmit}>
              <MutationErrorMessage
                mutation={mutation}
                defaultErrorTextLangId={mutationDefaultErrorMsgId}
                messageNegative
              />
              {editVoucherTemplate ? (
                <Form.Field>
                  <i aria-hidden="true" className="yellow warning icon" />
                  <FormattedMessage id="voucherTemplateManageModal.info.editWarning" />
                </Form.Field>
              ) : (
                ''
              )}
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.label.customDescription'
                })}
                value={values.customDescription}
                onChange={handleChange}
                name="customDescription"
              />
              <Form.Dropdown
                placeholder={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.placeholder.categoryType'
                })}
                label={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.label.categoryType'
                })}
                name="categoryType"
                disabled={editVoucherTemplate}
                fluid
                selection
                options={allCategoryTypeOptions}
                value={values.categoryType}
                onChange={(e, value) =>
                  onChangeCategoryType(value.name, value.value, setFieldValue)
                }
                error={!!errors.categoryType}
                selectOnBlur={false}
              />
              <Form.Dropdown
                placeholder={
                  values.categoryType
                    ? intl.formatMessage({
                        id: 'voucherTemplateManageModal.form.placeholder.chooseType'
                      })
                    : intl.formatMessage({
                        id: 'voucherTemplateManageModal.form.placeholder.chooseCategoryFirst'
                      })
                }
                label={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.label.benefitType'
                })}
                name="benefitType"
                disabled={editVoucherTemplate}
                fluid
                selection
                options={benefitTypeOptions}
                value={values.benefitType}
                onChange={(e, value) => {
                  onChangeBenefitType(value.name, value.value, setFieldValue)
                }}
                error={!!errors.benefitType}
                selectOnBlur={false}
              />
              <Form.Select
                placeholder={
                  values.benefitType
                    ? intl.formatMessage({
                        id: 'voucherTemplateManageModal.form.placeholder.chooseType'
                      })
                    : intl.formatMessage({
                        id: 'voucherTemplateManageModal.form.placeholder.chooseCategoryFirst'
                      })
                }
                label={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.label.allowedGrantType'
                })}
                name="allowedGrantType"
                fluid
                selection
                value={values.allowedGrantType}
                options={grantTypeOptions}
                onChange={(e, value) => {
                  setFieldValue(value.name, value.value)
                }}
                error={!!errors.allowedGrantType}
                selectOnBlur={false}
              />
              <Form.Dropdown
                placeholder={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.placeholder.chooseOperator'
                })}
                label={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.label.operator'
                })}
                name="operatorRef"
                fluid
                selection
                disabled={editVoucherTemplate}
                value={values.operatorRef}
                options={operatorOptions}
                onChange={(e, value) =>
                  onChangeOperator(value.name, value.value, setFieldValue)
                }
                error={!!errors.operatorRef}
                selectOnBlur={false}
              />
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.label.locationsDescription'
                })}
                name="validLocationsDescription"
                value={values.validLocationsDescription}
                onChange={handleChange}
              />
              <Form.Dropdown
                label={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.label.locations'
                })}
                placeholder={
                  values.operatorRef
                    ? intl.formatMessage({
                        id: 'voucherTemplateManageModal.form.placeholder.chooseLocations'
                      })
                    : intl.formatMessage({
                        id: 'voucherTemplateManageModal.form.placeholder.chooseOperatorFirst'
                      })
                }
                fluid
                multiple
                search
                selection
                value={values.validityLocationRefs}
                name="validityLocationRefs"
                options={locationOptions}
                onChange={(e, value) => {
                  setFieldValue(value.name, value.value)
                }}
                error={!!errors.validityLocationRefs}
                selectOnBlur={false}
              />
              {values.benefitType === voucherEnums.benefitTypes.MONEY && (
                <Form.Input
                  label={intl.formatMessage({
                    id: 'voucherTemplateManageModal.form.label.grantedValue'
                  })}
                  name="grantedValue"
                  value={values.grantedValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={!!errors.grantedValue}
                  type="number"
                />
              )}
              {values.benefitType === voucherEnums.benefitTypes.TIME && (
                <FormMinutes
                  label={intl.formatMessage({
                    id: 'voucherTemplateManageModal.form.label.grantedDurationInMinutes'
                  })}
                  labelMinutes={intl.formatMessage({
                    id: 'voucherTemplateManageModal.form.label.durationInMinutes'
                  })}
                  name="grantedDurationInMinutes"
                  minutes={values.grantedDurationInMinutes}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={(name, value) => setFieldValue(name, value)}
                  errorMinutes={!!errors.grantedDurationInMinutes}
                />
              )}
              {values.benefitType === voucherEnums.benefitTypes.PERCENT && (
                <Form.Input
                  label={intl.formatMessage({
                    id: 'voucherTemplateManageModal.form.label.grantedDiscountPercent'
                  })}
                  value={values.grantedDiscountPercent}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="grantedDiscountPercent"
                  error={!!errors.grantedDiscountPercent}
                />
              )}
              {values.categoryType !==
                voucherEnums.categoryTypes.HYPERIN_SINGLE_USE && (
                <FormMinutes
                  label={intl.formatMessage({
                    id: 'voucherTemplateManageModal.form.label.validityFromGranting'
                  })}
                  labelMinutes={intl.formatMessage({
                    id: 'voucherTemplateManageModal.form.label.valid'
                  })}
                  name="validityInMinutes"
                  minutes={values.validityInMinutes}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldValue={(name, value) => setFieldValue(name, value)}
                  errorMinutes={!!errors.validityInMinutes}
                />
              )}
              <Form.Input
                label={intl.formatMessage({
                  id: 'voucherTemplateManageModal.form.label.salePrice'
                })}
                name="salePrice"
                value={values.salePrice}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.salePrice}
                type="number"
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => onClose()}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button type="submit" primary onClick={handleSubmit}>
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default VoucherTemplateManageModal
