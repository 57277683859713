/**
 * Returns Location's name how it should be shown to users
 *
 * Location has `name` and `internalName`
 * Internal name specifies the location if the default is too generic
 *
 * If internalName is given and it's different from default name
 * function should return string in format:
 * "<name> (<internalName>)"
 */
export function getFormattedLocationName(location) {
  if (!location) {
    return ''
  }
  if (
    location.internalName &&
    location.internalName.length > 0 &&
    location.internalName !== location.name
  ) {
    return `${location.name} (${location.internalName})`
  }
  return location.name ? location.name : ''
}
