import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'semantic-ui-react'

import { MoovyTable } from '../../../../components'
import { getCountryNameByCode } from '../../../../services/utils'

const TableSignatoryOwner = ({ ultimateBeneficialOwners, query }) => {
  return (
    <>
      <MoovyTable items={ultimateBeneficialOwners} query={query}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="ultimateBeneficialOwnerTable.header.firstNames" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="ultimateBeneficialOwnerTable.header.lastName" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="ultimateBeneficialOwnerTable.header.countryOfResidence" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="ultimateBeneficialOwnerTable.header.jobTitle" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {ultimateBeneficialOwners &&
            ultimateBeneficialOwners.map((ultimateBeneficialOwner) => (
              <Table.Row key={ultimateBeneficialOwner.ref}>
                <Table.Cell>{ultimateBeneficialOwner.firstNames}</Table.Cell>
                <Table.Cell>{ultimateBeneficialOwner.lastName}</Table.Cell>
                <Table.Cell>
                  {getCountryNameByCode(
                    ultimateBeneficialOwner.countryCodeOfResidence
                  )}
                </Table.Cell>
                <Table.Cell>{ultimateBeneficialOwner.jobTitle}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </MoovyTable>
    </>
  )
}

export default TableSignatoryOwner
