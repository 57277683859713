import {
  Button,
  Card,
  Grid,
  Image,
  Popup,
  Segment,
  Tab,
  Table
} from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { formatDateAndTime, formatSeconds } from '../../services/utils'

import AdditionalTabs from '../../containers/ParkingModal/AdditionalTabs'
import ParkingSales from '../../containers/ParkingModal/ParkingSales'
import ParkingSequence from './ParkingSequence'
import React from 'react'
import parkingService from '../../services/Parking'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

const fiveMinutes = 5 * 60000

const ParkingContent = ({ parkingRef, parkingQuery }) => {
  const intl = useIntl()
  const { operatorRealm } = useAuthInfo()

  const externalDataQuery = useQuery({
    queryKey: ['externalFacilityData', parkingRef],
    queryFn: () =>
      parkingService.fetchExternalFacilityData(parkingRef, operatorRealm),
    staleTime: fiveMinutes
  })

  const parkingSequence = useQuery({
    queryKey: ['parkingSequence', parkingRef],
    queryFn: () =>
      parkingService.fetchParkingSequence(parkingRef, operatorRealm),
    staleTime: fiveMinutes,
    enabled: !!parkingRef
  })

  const sales = useQuery({
    queryKey: ['sales', parkingRef],
    queryFn: () => parkingService.fetchSales(parkingRef, operatorRealm),
    staleTime: fiveMinutes
  })

  function ParkingFacilityDataContainer({ query }) {
    const data = query.data ?? []

    const PlaceHolderCard = ({ children }) => (
      <Card>
        <Card.Content>
          <Card.Header>
            <FormattedMessage id="parkingContent.label.cameraRecognation" />
          </Card.Header>
          <Card.Description>{children}</Card.Description>
        </Card.Content>
      </Card>
    )
    if (query.isLoading) {
      return (
        <PlaceHolderCard>
          <FormattedMessage id="parkingContent.placeHolderCard.cameraRecognationLoading" />
        </PlaceHolderCard>
      )
    }

    if (query.isError) {
      return (
        <PlaceHolderCard>
          <FormattedMessage id="parkingContent.placeHolderCard.cameraRecognationLoadingFailed" />
        </PlaceHolderCard>
      )
    }
    if (data.length === 0) {
      return (
        <PlaceHolderCard>
          <FormattedMessage id="parkingContent.placeHolderCard.cameraRecognationDataMissing" />
        </PlaceHolderCard>
      )
    }

    return (
      <Card.Group>
        {data
          .sort((a, b) => {
            if (a?.state === b?.state) {
              return 0
            }
            if (a?.state === 'ONGOING') {
              return -1
            } else {
              return 1
            }
          })
          .map((parkingImage) => (
            <ParkingFacilityData key={parkingImage.ref} data={parkingImage} />
          ))}
      </Card.Group>
    )
  }

  function ParkingFacilityData({ data }) {
    const metadata = useQuery({
      queryKey: ['externalFacilityMetaData', data.metaDataURL],
      queryFn: async () => {
        const response = await fetch(data.metaDataURL)
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        return response.json()
      },
      enabled: !!data.metaDataURL
    })

    const Metadata = ({ query }) => {
      if (query.isError) {
        return intl.formatMessage({
          id: 'parkingContent.error.metadata'
        })
      }
      if (query.isLoading) {
        return '...'
      }

      if (!query.data) {
        return null
      }

      return (
        <Popup
          trigger={
            <Button>
              <FormattedMessage id="parkingContent.popup.metadata" />
            </Button>
          }
          position="bottom left"
          hoverable
        >
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage id="common.key" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="common.value" />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(query.data).map((entry) => (
                <Table.Row key={entry[0]}>
                  <Table.Cell>{entry[0]}</Table.Cell>
                  <Table.Cell>
                    {entry[1] === Object(entry[1])
                      ? JSON.stringify(entry[1])
                      : entry[1]}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Popup>
      )
    }
    return (
      <Card>
        {data.imageURL ? (
          <Image src={data.imageURL} href={data.imageURL} target="_new" />
        ) : (
          <Card.Content>
            <FormattedMessage id="parkingContent.card.content.noImage" />
          </Card.Content>
        )}
        <Card.Content>
          <Card.Header>{formatDateAndTime(data.creationTime)}</Card.Header>
          <Card.Meta>{data.state}</Card.Meta>
          <Card.Description>
            <Metadata query={metadata} />
          </Card.Description>
        </Card.Content>
      </Card>
    )
  }

  return (
    <div className="Admin--Page--Content">
      <Grid stackable>
        <Grid.Row columns="2">
          <Grid.Column width={5}>
            <Segment>
              <Grid>
                {parkingQuery.data && (
                  <>
                    <Grid.Row>
                      <Grid.Column>
                        <FormattedMessage id="parkingContent.grid.label.parking" />{' '}
                        <b>{parkingQuery.data.licensePlateNumber}</b>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FormattedMessage id="parkingContent.label.parkStarted" />
                        <br />
                        <b>{formatDateAndTime(parkingQuery.data.start)}</b>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FormattedMessage id="parkingContent.label.parkEnded" />
                        <br />
                        <b>
                          {parkingQuery.data.ongoing ? (
                            <FormattedMessage id="parkingContent.label.parkOngoing" />
                          ) : (
                            formatDateAndTime(parkingQuery.data.end)
                          )}
                        </b>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <FormattedMessage id="parkingContent.label.parkDuration" />
                        <br />
                        <b>
                          {formatSeconds(parkingQuery.data.durationSeconds)}
                        </b>
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <FormattedMessage id="parkingContent.label.parkTotalDuration" />
                        <br />
                        <b>
                          {formatSeconds(
                            (parkingQuery.data.parkingSequence &&
                              parkingQuery.data.parkingSequence
                                .durationSeconds) ||
                              0
                          )}
                        </b>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                )}
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column width={11}>
            <ParkingFacilityDataContainer query={externalDataQuery} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns="1">
          <Grid.Column>
            <Tab
              panes={[
                {
                  menuItem: intl.formatMessage({
                    id: 'parkingContent.tab.sales'
                  }),
                  render: () => (
                    <Tab.Pane>
                      <ParkingSales
                        sales={sales.data ? sales.data : []}
                        parking={parkingQuery.data}
                        disableRowSelecting
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: intl.formatMessage({
                    id: 'parkingContent.tab.vouchers'
                  }),
                  render: () => (
                    <Tab.Pane>
                      <AdditionalTabs.VouchersContainer
                        parkingRef={parkingRef}
                        operatorRealm={operatorRealm}
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: intl.formatMessage({
                    id: 'parkingContent.tab.parkingSequence'
                  }),
                  render: () => (
                    <Tab.Pane>
                      <ParkingSequence
                        parkingSequence={parkingSequence.data}
                        activeRef={parkingRef}
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: intl.formatMessage({
                    id: 'parkingContent.tab.auditLogs'
                  }),
                  render: () => (
                    <Tab.Pane>
                      <AdditionalTabs.ParkingAuditLogContainer
                        parkingRef={parkingRef}
                        operatorRealm={operatorRealm}
                      />
                    </Tab.Pane>
                  )
                }
              ]}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default ParkingContent
