import './Finances.scss'

import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import financesService from '../../services/Finances'
import {
  calendarDateInputToBackendFormat,
  dateNowToCalendarInput
} from '../../services/utils'
import FinancesFilterDateRange from './FinancesFilterDateRange'
import FinancesFilterOperator from './FinancesFilterOperator'
import FinancesPane from './FinancesPane'

const ReportVismaMandators = () => {
  const intl = useIntl()

  const [startTime, setStartTime] = useState(dateNowToCalendarInput())
  const [endTime, setEndTime] = useState(dateNowToCalendarInput())
  const [mandator, setMandator] = useState([])

  const onChangeMandatorValue = (value) => {
    setMandator(value)
  }

  return (
    <FinancesPane
      extraContent={
        <FinancesFilterOperator onChangeMandatorValue={onChangeMandatorValue} />
      }
      text={intl.formatMessage({
        id: 'finances.tab.reportVismaMandators.header'
      })}
      buttonCallback={() =>
        financesService.downloadVismaMandatorList({
          from: calendarDateInputToBackendFormat(startTime),
          until: calendarDateInputToBackendFormat(endTime),
          settlementRecipientRef: mandator
        })
      }
    >
      <FinancesFilterDateRange
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
      />
    </FinancesPane>
  )
}

export default ReportVismaMandators
