import {
  Button,
  Checkbox,
  Form,
  Loader,
  Segment,
  Table
} from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import {
  RefundButton,
  RetryCardPaymentButton,
  SendReceiptButton,
  SetAsWaivedButton
} from './invoiceButtons'
import { useMutation, useQuery } from '@tanstack/react-query'

import { ModalRefundConfirmation } from '../..'
import ModalRetryCardPaymentConfirmation from './modalRetryCardPaymentConfirmation'
import ModalSendEmailReceipt from './modalSendEmailReceipt'
import ModalWaivedInvoice from '../../../pages/Customers/CustomerInvoicing/ModalWaivedInvoice'
import { copyToClipboard } from '../../../services/utils/clipboard'
import { formatPrice } from '../../../services/utils'
import invoiceService from '../../../services/Invoicing'
import invoicesService from '../../../services/Adyen/Invoices'
import { showLocalizedMoovyToast } from '../../MoovyToast'
import userService from '../../../services/User/UserServices'
import withSelections from '../../../HOC/withSelections'

const TabAdyenInvoiceRows = ({
  invoice,
  compensatedInvoice,
  isAnyInvoiceRowRefundOngoing,
  operatorRealm,
  refreshInvoiceCallback,
  areAllIndeterminate,
  areAllSelected,
  areAnySelected,
  handleClearAll,
  handleSelect,
  handleSelectAll,
  isItemSelected,
  selected,
  refundingSelected,
  onRefreshUser
}) => {
  const intl = useIntl()

  const [editInvoiceRows, setEditInvoiceRows] = useState(false)
  const [invoiceRows, setInvoiceRows] = useState([])
  const [showSendByEmail, setShowSendByEmail] = useState(false)
  const [showRefundConfirmation, setShowRefundConfirmation] = useState(false)
  const [retryCardPaymentConfirmation, setRetryCardPaymentConfirmation] =
    useState(false)
  const [infoPopupOpen, setInfoPopupOpen] = useState(false)
  const [showWaiveConfirmation, setWaiveConfirmation] = useState(false)
  const [enableInvoiceQuery, setEnableInvoiceQuery] = useState(false)

  useEffect(() => {
    setEnableInvoiceQuery(true)
  }, [])

  useEffect(() => {
    if (editInvoiceRows) {
      const revertable = invoiceRows.map((row) => ({
        ...row,
        currentRevertAmount: Number(
          row?.refundableAmounts?.refundableAmount || 0
        )
      }))
      setInvoiceRows(revertable)
    } else {
      const revertable = invoiceRows.map((row) => ({
        ...row,
        currentRevertAmount: undefined
      }))
      setInvoiceRows(revertable)
    }
  }, [editInvoiceRows])

  const invoicesRowsQuery = useQuery({
    queryKey: ['invoiceRows', invoice?.ref],
    queryFn: () => invoiceService.fetchInvoiceRows(invoice.ref, operatorRealm),
    enabled: enableInvoiceQuery
  })

  const invoicesRowsQueryResponse = invoicesRowsQuery?.data || []

  const refreshInvoice = () => {
    refreshInvoiceCallback()
    setEnableInvoiceQuery(true)
  }

  const enableRefundableAmountsQuery =
    enableInvoiceQuery && invoicesRowsQueryResponse.length > 0
  const refundableAmountsQuery = useQuery({
    queryKey: ['refundableAmounts', invoice?.ref],
    queryFn: () =>
      invoicesService.cardPaymentRefundableAmounts(invoice.ref, operatorRealm),
    enabled: enableRefundableAmountsQuery
  })

  useEffect(() => {
    if (refundableAmountsQuery.isSuccess) {
      setInvoiceRows(
        invoicesRowsQueryResponse.map(
          (invoiceRow) =>
            ({
              ...invoiceRow,
              refundableAmounts: refundableAmountsQuery.data?.find(
                (refundable) => refundable.invoiceRowRef === invoiceRow.ref
              )
            }) || []
        )
      )
      setEnableInvoiceQuery(false)
    } else if (refundableAmountsQuery.isError) {
      setInvoiceRows([])
    }
  }, [refundableAmountsQuery.status])

  const refundableAmountsQueryIsLoading =
    enableRefundableAmountsQuery &&
    (refundableAmountsQuery.isLoading || refundableAmountsQuery.isFetching)

  const queryIsLoading =
    invoicesRowsQuery.isLoading ||
    refundableAmountsQueryIsLoading ||
    invoicesRowsQuery.isFetching

  const validateAdyenInvoiceRowRevert = (current, invoiceRow) => {
    return (
      current > 0 &&
      current <= Number(invoiceRow?.refundableAmounts?.refundableAmount || 0)
    )
  }

  const maxRevertSum = invoiceRows
    ?.map((invoiceRow) =>
      Number(invoiceRow?.refundableAmounts?.refundableAmount || 0)
    )
    ?.reduce((total, currentValue) => total + currentValue, 0)

  const revertSum = invoiceRows
    ?.filter((row) => isItemSelected(row.ref))
    ?.map((row) => Number(row?.currentRevertAmount || 0))
    ?.reduce((total, currentValue) => total + currentValue, 0)

  const invalidRevertTotalSum =
    invoiceRows?.filter(
      (invoiceRow) =>
        isItemSelected(invoiceRow.ref) &&
        !validateAdyenInvoiceRowRevert(
          invoiceRow.currentRevertAmount,
          invoiceRow
        )
    ).length > 0

  const customerQuery = useQuery({
    queryKey: ['customer'],
    queryFn: () => userService.fetchUser(invoice.user.ref, null),
    enabled: !!invoice?.user?.ref,
    refetchOnWindowFocus: false
  })
  const invoiceCustomerEmail = customerQuery?.data?.userDetails?.email || ''

  const { mutate: sendReceipt, ...sendReceiptMutation } = useMutation({
    mutationFn: (email) =>
      invoiceService.sendInvoiceReceipt(invoice.ref, email),
    onSuccess: () => {
      setShowSendByEmail(false)
      showLocalizedMoovyToast(intl, {
        title: 'tabAdyenInvoice.toast.title.invoiceSentByEmail',
        description: 'tabAdyenInvoice.toast.description.invoiceSentByEmail'
      })
    }
  })

  const { mutate: refundPayment, ...refundPaymentMutation } = useMutation({
    mutationFn: (details) =>
      invoicesService.refundCardPayment(invoice.ref, {
        ...details,
        invoiceRowsToRefund: invoiceRows
          .filter((row) => selected.includes(row.ref))
          .map((row) => ({
            invoiceRowRef: row.ref,
            amount: row.currentRevertAmount
          }))
      }),
    onSuccess: (response, variables) => {
      if (variables?.copyToClipboard && response?.revertingInvoiceNumber) {
        copyToClipboard(intl, response?.revertingInvoiceNumber)
      }
      setShowRefundConfirmation(false)
      handleClearAll()
      setEditInvoiceRows(false)
      refreshInvoice()
      showLocalizedMoovyToast(intl, {
        title: 'tabAdyenInvoice.toast.title.refundPayment',
        description: 'tabAdyenInvoice.toast.description.refundPayment'
      })
    }
  })

  const { mutate: retryCardPayment, ...retryCardPaymentMutation } = useMutation(
    {
      mutationFn: () =>
        invoicesService.retryCardPayment(invoice.ref, operatorRealm),
      onSuccess: (response) => {
        setRetryCardPaymentConfirmation(false)
        refreshInvoice()
        showLocalizedMoovyToast(intl, {
          title: 'tabAdyenInvoice.toast.title.retryCardPayment',
          description: 'tabAdyenInvoice.toast.description.retryCardPayment'
        })
        if (response?.userWasRemovedFromBlocklist) {
          showLocalizedMoovyToast(intl, {
            title: 'tabAdyenInvoice.toast.title.userWasRemovedFromBlocklist',
            description:
              'tabAdyenInvoice.toast.description.userWasRemovedFromBlocklist'
          })
          onRefreshUser && onRefreshUser()
        }
      }
    }
  )

  const { mutate: markAsWaivedInvoice, ...markAsWaivedInvoiceMutation } =
    useMutation({
      mutationFn: ({ comment, invoiceRef }) =>
        invoicesService.setCardPaymentAsWaived(
          invoiceRef,
          comment,
          operatorRealm
        ),
      onSuccess: (response) => {
        setWaiveConfirmation(false)
        refreshInvoice()
        showLocalizedMoovyToast(intl, {
          title: 'tabAdyenInvoice.toast.title.waivedInvoice',
          description: 'tabAdyenInvoice.toast.description.waivedInvoice'
        })
        if (response?.userWasRemovedFromBlocklist) {
          showLocalizedMoovyToast(intl, {
            title: 'tabAdyenInvoice.toast.title.userWasRemovedFromBlocklist',
            description:
              'tabAdyenInvoice.toast.description.userWasRemovedFromBlocklist'
          })
          onRefreshUser && onRefreshUser()
        }
      }
    })

  const ActionButtons = ({
    invoice,
    invoicesRowsQuery,
    areAnySelected,
    setShowRefundConfirmation,
    setShowSendByEmail,
    setChargeConfirmation,
    refundingSelected
  }) => {
    if (invoice.totalPrice == 0) {
      return ''
    }

    return (
      <>
        <RefundButton
          invoice={invoice}
          invalidRevertTotalSum={invalidRevertTotalSum}
          maxRevertSum={maxRevertSum}
          queryIsLoading={queryIsLoading}
          areAnySelected={areAnySelected}
          editInvoiceRows={editInvoiceRows}
          setEditInvoiceRows={setEditInvoiceRows}
          handleClearAll={handleClearAll}
          setShowRefundConfirmation={setShowRefundConfirmation}
          refundingSelected={refundingSelected}
        />
        <RetryCardPaymentButton
          invoice={invoice}
          queryIsLoading={queryIsLoading}
          infoPopupOpen={infoPopupOpen}
          setInfoPopupOpen={setInfoPopupOpen}
          setChargeConfirmation={setChargeConfirmation}
        />
        <SetAsWaivedButton
          invoice={invoice}
          queryIsLoading={queryIsLoading}
          invoicesRowsQuery={invoicesRowsQuery}
          setInfoPopupOpen={setInfoPopupOpen}
          setWaiveConfirmation={setWaiveConfirmation}
        />
        <SendReceiptButton
          invoice={invoice}
          queryIsLoading={queryIsLoading}
          editInvoiceRows={editInvoiceRows}
          setShowSendByEmail={setShowSendByEmail}
        />
      </>
    )
  }

  const HeaderRevertInfo = ({ handleClearAll }) => {
    const RevertTextInfo = () => {
      const len = selected.length
      if (len === 0) {
        return (
          <FormattedMessage id="tabAdyenInvoice.label.notChosenInvoiceRows" />
        )
      }

      if (len > 0 && !invalidRevertTotalSum) {
        return (
          <FormattedMessage
            id="tabAdyenInvoice.label.chosenInvoiceRows"
            values={{ rowCount: len, totalPrice: formatPrice(revertSum) }}
          />
        )
      }

      return (
        <div style={{ color: 'red' }}>
          <FormattedMessage
            id="tabAdyenInvoice.label.error.chosenInvoiceRows"
            values={{ rowCount: len }}
          />
        </div>
      )
    }

    return (
      <Segment className="invoiceRowTableToolbar">
        <div>
          <FormattedMessage
            id="tabAdyenInvoice.label.maxRefund"
            values={{ price: formatPrice(maxRevertSum) }}
          />
          <br />
          <RevertTextInfo />
        </div>
        <div className="invoiceRowTableToolbarGrow" />
        <Button onClick={() => handleClearAll()}>
          <FormattedMessage id="common.button.clear" />
        </Button>
      </Segment>
    )
  }

  const HeaderCompensatedInfo = ({ compensatedInvoice }) => {
    return (
      <Segment>
        <FormattedMessage
          id="tabAdyenInvoice.label.refundForInvoice"
          values={{ invoiceNumber: compensatedInvoice.name }}
        />
      </Segment>
    )
  }

  const handleRowRevertAmountChange = (ev, rowIndex) => {
    const revertNum = parseFloat(ev.target.value)
    const newArr = [...invoiceRows]
    newArr[rowIndex].currentRevertAmount = revertNum
    setInvoiceRows(newArr)
  }

  const RenderHeaderInvoiceRows = () => {
    return (
      <>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.invoiceRow" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.product" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.charged" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.alv" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.description" />
        </Table.HeaderCell>
      </>
    )
  }

  const RenderHeaderRefundInvoiceRows = ({ isAnyInvoiceRowRefundOngoing }) => {
    return (
      <>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.invoiceRow" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.product" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.charged" />
        </Table.HeaderCell>
        {!isAnyInvoiceRowRefundOngoing && (
          <>
            <Table.HeaderCell>
              <FormattedMessage id="tabAdyenInvoice.table.header.refundable" />
            </Table.HeaderCell>
          </>
        )}
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.alv" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.description" />
        </Table.HeaderCell>
      </>
    )
  }

  const RenderHeaderEditInvoiceRows = ({ invoiceRows }) => {
    return (
      <>
        <Table.HeaderCell>
          <Checkbox
            checked={areAllSelected(invoiceRows)}
            indeterminate={areAllIndeterminate(invoiceRows)}
            onChange={() =>
              handleSelectAll(
                invoiceRows,
                invoiceRows.filter((item) => !item.refundableAmounts)
              )
            }
          />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.invoiceRow" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.product" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.charged" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.refund" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.refundable" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.alv" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="tabAdyenInvoice.table.header.description" />
        </Table.HeaderCell>
      </>
    )
  }

  const RenderHeaders = ({
    invoiceRows,
    editInvoiceRows,
    isRefunded,
    isAnyInvoiceRowRefundOngoing
  }) => {
    if (editInvoiceRows) {
      return <RenderHeaderEditInvoiceRows invoiceRows={invoiceRows} />
    }

    if (isRefunded) {
      return (
        <RenderHeaderRefundInvoiceRows
          isAnyInvoiceRowRefundOngoing={isAnyInvoiceRowRefundOngoing}
        />
      )
    }

    return <RenderHeaderInvoiceRows />
  }

  const RenderBodyInvoiceRows = ({ row }) => {
    return (
      <>
        <Table.Cell collapsing>{row.rowNumber}</Table.Cell>
        <Table.Cell>{row.product.name}</Table.Cell>
        <Table.Cell>{formatPrice(row.totalPrice)}</Table.Cell>
        <Table.Cell>{row.vatPercent}%</Table.Cell>
        <Table.Cell>{row.description}</Table.Cell>
      </>
    )
  }

  const RenderBodyRefundInvoiceRows = ({
    row,
    isAnyInvoiceRowRefundOngoing
  }) => {
    return (
      <>
        <Table.Cell collapsing>{row.rowNumber}</Table.Cell>
        <Table.Cell>{row.product.name}</Table.Cell>
        <Table.Cell>{formatPrice(row.totalPrice)}</Table.Cell>
        {!isAnyInvoiceRowRefundOngoing && (
          <>
            <Table.Cell>
              {formatPrice(row.refundableAmounts?.refundableAmount)}
            </Table.Cell>
          </>
        )}
        <Table.Cell>{row.vatPercent}%</Table.Cell>
        <Table.Cell>{row.description}</Table.Cell>
      </>
    )
  }

  const getTotalSum = (invoiceRows) => {
    const totalRevertSum = invoiceRows.reduce(
      (total, value) => total + value,
      0
    )
    return Math.round(totalRevertSum * 100) / 100
  }

  return (
    <>
      {editInvoiceRows && <HeaderRevertInfo handleClearAll={handleClearAll} />}
      {compensatedInvoice && (
        <HeaderCompensatedInfo compensatedInvoice={compensatedInvoice} />
      )}
      <Table compact="very" celled>
        <Table.Header fullWidth>
          <Table.Row>
            <RenderHeaders
              invoiceRows={invoiceRows}
              editInvoiceRows={editInvoiceRows}
              isRefunded={invoice?.isRefunded}
              isAnyInvoiceRowRefundOngoing={isAnyInvoiceRowRefundOngoing}
            />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoiceRows?.map((row, index) => (
            <Table.Row key={row.ref}>
              {editInvoiceRows ? (
                <>
                  <Table.Cell>
                    <Checkbox
                      checked={isItemSelected(row.ref)}
                      onChange={() => handleSelect(row.ref)}
                      disabled={!row.refundableAmounts}
                    />
                  </Table.Cell>
                  <Table.Cell collapsing>{row.rowNumber}</Table.Cell>
                  <Table.Cell>{row.product.name}</Table.Cell>
                  <Table.Cell>{formatPrice(row.totalPrice)}</Table.Cell>
                  <Table.Cell>
                    {(isItemSelected(row.ref) && (
                      <Form>
                        <Form.Input
                          style={{ width: '100px' }}
                          key={row.ref}
                          className="invoiceRevertInputField"
                          value={row?.currentRevertAmount}
                          error={
                            !validateAdyenInvoiceRowRevert(
                              row.currentRevertAmount,
                              row
                            )
                          }
                          placeholder="0,00"
                          onChange={(ev) =>
                            handleRowRevertAmountChange(ev, index)
                          }
                          type="number"
                          min={0.01}
                          max={row.currentRevertAmount}
                        />
                      </Form>
                    )) ||
                      formatPrice(0)}
                  </Table.Cell>
                  <Table.Cell>
                    {isItemSelected(row.ref)
                      ? formatPrice(
                          row.refundableAmounts?.refundableAmount -
                            (isNaN(row?.currentRevertAmount)
                              ? 0
                              : row?.currentRevertAmount)
                        )
                      : formatPrice(row.refundableAmounts?.refundableAmount)}
                  </Table.Cell>
                  <Table.Cell>{row.vatPercent}%</Table.Cell>
                  <Table.Cell>{row.description}</Table.Cell>
                </>
              ) : invoice.isRefunded ? (
                <RenderBodyRefundInvoiceRows
                  row={row}
                  isAnyInvoiceRowRefundOngoing={isAnyInvoiceRowRefundOngoing}
                />
              ) : (
                <RenderBodyInvoiceRows row={row} />
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {queryIsLoading && <Loader active inline="centered" />}
      <ActionButtons
        invoice={invoice}
        invoicesRowsQuery={invoicesRowsQuery}
        areAnySelected={areAnySelected()}
        setShowRefundConfirmation={setShowRefundConfirmation}
        setShowSendByEmail={setShowSendByEmail}
        setChargeConfirmation={setRetryCardPaymentConfirmation}
        refundingSelected={refundingSelected}
      />
      <ModalSendEmailReceipt
        open={showSendByEmail}
        onClose={() => setShowSendByEmail(false)}
        customerEmail={invoiceCustomerEmail}
        mutation={sendReceiptMutation}
        onSubmit={(email) => {
          sendReceipt(email)
        }}
      />
      <ModalRefundConfirmation
        open={showRefundConfirmation}
        invoiceUser={customerQuery?.data}
        onClose={() => setShowRefundConfirmation(false)}
        lang={{
          titleElement: (
            <FormattedMessage id="modalRefundConfirmation.adyen.title" />
          ),
          bodyElement: (
            <FormattedMessage
              id="modalRefundConfirmation.adyen.refundTotal"
              values={{
                totalPrice: getTotalSum(
                  invoiceRows
                    .filter((row) => selected.includes(row.ref))
                    .map((row) => row.currentRevertAmount)
                )
              }}
            />
          ),

          buttonConfirmKey: 'modalRefundConfirmation.adyen.button.refund',
          defaultErroKey:
            'modalRefundConfirmation.adyen.operation.refund.failed'
        }}
        onSubmit={(details) => refundPayment(details)}
        mutation={refundPaymentMutation}
      />
      <ModalRetryCardPaymentConfirmation
        open={retryCardPaymentConfirmation}
        totalPrice={invoice?.totalPrice}
        onClose={() => setRetryCardPaymentConfirmation(false)}
        mutation={retryCardPaymentMutation}
        onSubmit={() => {
          retryCardPayment()
        }}
      />
      <ModalWaivedInvoice
        open={showWaiveConfirmation}
        invoice={invoice}
        onClose={() => {
          markAsWaivedInvoiceMutation.reset()
          setWaiveConfirmation(false)
        }}
        onSubmit={(comment) =>
          markAsWaivedInvoice({ comment, invoiceRef: invoice.ref })
        }
        mutation={markAsWaivedInvoiceMutation}
      />
    </>
  )
}

export default withSelections(TabAdyenInvoiceRows)
