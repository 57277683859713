import './styles.scss'

import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Modal, Progress } from 'semantic-ui-react'

import { webpayment as webpaymentEnums } from '../../services/utils/DTOEnums'
import WebpaymentContainer from './WebpaymentsContainer'

const initialCancelStatus = {
  total: 0,
  succeededRefs: [],
  failedRefs: []
}

export default function ModalCancelWebpayments({
  payments = [],
  open,
  onClose
}) {
  const [webpaymentStatus, setWebpaymentStatus] = useState(initialCancelStatus)

  useEffect(() => {
    setWebpaymentStatus(initialCancelStatus)
  }, [open])

  const pendingOrFailed = payments.filter((payment) =>
    [
      webpaymentEnums.webpaymentStatus.WEBPAYMENT_PENDING,
      webpaymentEnums.webpaymentStatus.WEBPAYMENT_FAILED,
      webpaymentEnums.webpaymentStatus.DEBT_COLLECTION_REQUIRES_MANUAL_STEP
    ].includes(payment.webpayment.status)
  )

  const beforeOnClose = () => onClose(webpaymentStatus.succeededRefs.length > 0)

  const cancelingTotal = webpaymentStatus.total
  const failedTotal = webpaymentStatus.failedRefs.length

  const doneTotal = webpaymentStatus.succeededRefs.length + failedTotal

  return (
    <Modal open={open} onClose={beforeOnClose}>
      <Modal.Header>
        <FormattedMessage id="modalCancelWebpayments.header" />
      </Modal.Header>
      {doneTotal !== cancelingTotal && (
        <Progress
          value={doneTotal}
          total={cancelingTotal}
          active
          size="tiny"
          attached="top"
          color="blue"
          warning={failedTotal > 0}
        />
      )}
      <Modal.Content>
        <WebpaymentContainer
          webpayments={pendingOrFailed}
          cancelingTotal={webpaymentStatus.total}
          succeededRefs={webpaymentStatus.succeededRefs}
          failedRefs={webpaymentStatus.failedRefs}
          onStartCanceling={(amount) => {
            setWebpaymentStatus((state) => ({
              ...state,
              total: amount
            }))
          }}
          onCanceled={(ref) =>
            setWebpaymentStatus((state) => ({
              ...state,
              succeededRefs: state.succeededRefs.concat([ref])
            }))
          }
          onFailed={(ref) => {
            setWebpaymentStatus((state) => ({
              ...state,
              failedRefs: state.failedRefs.concat([ref])
            }))
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={beforeOnClose}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
