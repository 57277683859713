import React, { useEffect, useState } from 'react'
import { sortColumn, tablePaginationDirections } from '../../services/utils'

import SettlementRecipientsContent from './settlementRecipientsContent'
import SettlementRecipientsToolbar from './settlementRecipientsToolbar'
import Toolbar from '../../components/Toolbar'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import { getTablePaginationInitialValues } from '../../services/utils/tablePagination'
import settlementRecipientService from '../../services/SettlementRecipients'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useDebounce } from 'use-debounce'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'

const SettlementRecipients = () => {
  const intl = useIntl()
  const { isSuperOperator } = useAuthInfo()
  const [partialSRName, setPartialSRName] = useState('')
  const minCharsForPartialName = 3

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.settlementRecipients'
      }),
      active: true
    }
  ])

  const pageLimit = 20

  const [state, setState] = useGlobalUI({
    key: 'settlementRecipientList',
    initialValue: {
      ...getTablePaginationInitialValues('sortByName'),
      settlementRecipientName: ''
    }
  })

  const [settlementRecipientNameDebounce] = useDebounce(
    state.settlementRecipientName,
    750
  )

  useEffect(() => {
    setPartialSRName(settlementRecipientNameDebounce)
    setState({
      ...state,
      activePage: 1
    })
  }, [settlementRecipientNameDebounce])

  const recipientsQuery = useQuery({
    queryKey: [
      'fetchAllRecipients',
      state.page,
      state.sort,
      state.direction,
      state.activePage,
      partialSRName
    ],
    queryFn: () => {
      const partialName = partialSRName?.trim()
      const queryParams = {
        partialName:
          !partialName || partialName.length < minCharsForPartialName
            ? undefined
            : partialName,
        page: state.activePage - 1,
        limit: pageLimit,
        sort: state.sortColumn,
        direction: state.direction
      }
      return settlementRecipientService.fetchAllSettlementRecipientsByFilter(
        queryParams
      )
    },

    enabled: !!isSuperOperator
  })

  const recipients =
    (recipientsQuery.data &&
      recipientsQuery.data.data &&
      recipientsQuery.data.data) ||
    []

  const onPageChange = (e, { activePage }) => {
    setState({
      ...state,
      activePage
    })
  }

  const setSort = (column, direction) => {
    setState({ ...state, sort: column, direction })
  }

  const totalPages =
    (recipientsQuery.data && recipientsQuery.data.totalPages) || 0

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'common.breadcrumbs.settlementRecipients'
        })}
        breadcrumbs={breadcrumbs}
        content={
          <SettlementRecipientsToolbar
            minCharsForPartialName={minCharsForPartialName}
            settlementRecipientName={state.settlementRecipientName}
            setSettlementRecipientName={(settlementRecipientName) =>
              setState({
                ...state,
                settlementRecipientName
              })
            }
          />
        }
      />
      <div className="Admin--Page--Content">
        <SettlementRecipientsContent
          query={recipientsQuery}
          recipients={recipients}
          onPageChange={onPageChange}
          totalPages={totalPages}
          activePage={state.activePage}
          sortColumn={state.sort}
          sortDirection={
            tablePaginationDirections.stringValues[state.direction]
          }
          onSort={(clickedColumn) =>
            sortColumn(
              clickedColumn,
              { column: state.sort, direction: state.direction },
              setSort
            )
          }
          refresh={() => recipientsQuery.refetch()}
        />
      </div>
    </>
  )
}

export default SettlementRecipients
