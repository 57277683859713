import { Icon, Popup } from 'semantic-ui-react'

import React from 'react'

export default function MoovyIconInfo({ children, iconLabel, visible = true }) {
  return (
    <>
      {visible ? (
        <div>
          <span style={{ verticalAlign: 'middle' }}>{iconLabel}</span>
          <Popup
            trigger={
              <Icon
                name="info"
                circular
                size="small"
                style={{ marginLeft: '5px' }}
              />
            }
          >
            {children}
          </Popup>
        </div>
      ) : (
        ''
      )}
    </>
  )
}
