import { ChargerTypeEnum, getStringValue } from '../../services/utils/DTOEnums'

import { FormattedMessage } from 'react-intl'
import { Image } from 'semantic-ui-react'
import React from 'react'
import evChargerCCS from '../../resources/evChargerCCS.svg'
import evChargerCHAdeMO from '../../resources/evChargerCHAdeMO.svg'
import evChargerSchuko from '../../resources/evChargerSchuko.svg'
import evChargerType1 from '../../resources/evChargerType1.svg'
import evChargerType2 from '../../resources/evChargerType2.svg'

const ChargerTypeIcon = ({ chargerType }) => {
  if (!chargerType) return ''

  const ChargerName = ({ chargerType }) => {
    return (
      <FormattedMessage
        id={getStringValue(ChargerTypeEnum.localizationKeys, chargerType)}
        defaultMessage={chargerType}
      />
    )
  }

  const ChargerIcon = ({ chargerType }) => {
    switch (chargerType) {
      case ChargerTypeEnum.CHADEMO:
        return <Image src={evChargerCHAdeMO} avatar />
      case ChargerTypeEnum.CCS:
        return <Image src={evChargerCCS} avatar />
      case ChargerTypeEnum.SCHUKO:
        return <Image src={evChargerSchuko} avatar />
      case ChargerTypeEnum.TYPE1:
        return <Image src={evChargerType1} avatar />
      case ChargerTypeEnum.TYPE2:
        return <Image src={evChargerType2} avatar />
    }
  }

  return (
    <>
      <ChargerIcon chargerType={chargerType} />
      <ChargerName chargerType={chargerType} />
    </>
  )
}

export default ChargerTypeIcon
