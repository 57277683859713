import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Modal } from 'semantic-ui-react'

import { MutationErrorMessage } from '..'

function MoovyModalConfirmation({
  open,
  onClose,
  lang,
  onSubmit,
  mutation,
  disableSubmit
}) {
  const resetView = () => {
    mutation.reset()
  }

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => {
        resetView()
        onClose()
      }}
    >
      <Modal.Header>{lang.titleElement}</Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId={lang.defaultErroKey}
          messageNegative
        />
        <div style={{ padding: '5px' }}>{lang.bodyElement}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            resetView()
            onClose()
          }}
        >
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          disabled={mutation.isPending || disableSubmit}
          loading={mutation.isPending}
          primary
          onClick={() => {
            onSubmit()
          }}
        >
          <FormattedMessage id={lang.buttonConfirmKey} />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

MoovyModalConfirmation.propTypes = {
  lang: PropTypes.shape({
    titleElement: PropTypes.object,
    bodyElement: PropTypes.object,
    buttonConfirmKey: PropTypes.string,
    defaultErroKey: PropTypes.string
  })
}

export default MoovyModalConfirmation
