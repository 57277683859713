import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useMemo, useState } from 'react'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../../../components'
import countryList from 'react-select-country-list'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import { useMutation } from '@tanstack/react-query'

const ModalAddUltimateBeneficialOwner = ({
  settlementRecipientRef,
  onClose,
  onAdded
}) => {
  const intl = useIntl()
  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((item) => {
          return { key: item.value, text: item.label, value: item.value }
        }),
    []
  )

  const jobTitles = [
    'Chief Executive Officer',
    'Chief Financial Officer',
    'Chief Operating Officer',
    'Chief Information Officer',
    'Chief Strategy Officer',
    'President',
    'Vice President',
    'Executive President',
    'Managing Member',
    'General Partner',
    'Managing Partner',
    'Partner',
    'Treasurer',
    'Director'
  ].map((value) => ({
    key: value,
    text: value,
    value: value
  }))

  const [defaultValues] = useState({
    firstNames: '',
    lastName: '',
    countryCodeOfResidence: 'FI',
    ownershipPercentage: '',
    jobTitle: '',
    reason: ''
  })

  const [jobTitleOptions, setJobTitleOptions] = useState(jobTitles)

  const validationSchema = () => {
    return yup.object().shape({
      firstNames: yup.string().required('Cannot be empty'),
      lastName: yup.string().required('Cannot be empty'),
      countryCodeOfResidence: yup.string().required('Cannot be empty'),
      ownershipPercentage: yup
        .number()
        .min(0)
        .max(100)
        .required('Cannot be empty'),
      jobTitle: yup
        .string()
        .test(
          'EitherNotNullOrReasonOwnership',
          'Either not null or reason is DUE_TO_OWNERSHIP',
          function (jobTitle) {
            const { reason } = this.parent
            return jobTitle?.trim() || reason === 'DUE_TO_OWNERSHIP'
          }
        ),
      reason: yup.string().required('Cannot be empty')
    })
  }

  const { mutate: addOwner, ...addOwnerMutation } = useMutation({
    mutationFn: (values) => {
      return settlementRecipientService.addUltimateBeneficialOwner(
        settlementRecipientRef,
        values
      )
    },
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'modalOwner.toast.title.ultimateBeneficialOwnerAdded',
        description: 'modalOwner.toast.description.ultimateBeneficialOwnerAdded'
      })
      onAdded()
      const { resetForm } = variables
      resetForm({})
    }
  })

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema()}
      onSubmit={(values, { resetForm }) =>
        addOwner({ ...values, resetForm: resetForm })
      }
      enableReinitialize={true}
    >
      {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
        <Modal open onClose={onClose}>
          <Modal.Header>
            <FormattedMessage id="ultimateBeneficialOwnerTab.button.addUltimateBeneficialOwner" />
          </Modal.Header>
          <Modal.Content>
            <MutationErrorMessage
              mutation={addOwnerMutation}
              defaultErrorTextLangId="modalOwner.form.add.error"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Group widths="equal">
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.firstNames'
                  })}
                  name="firstNames"
                  value={values.firstNames}
                  onChange={handleChange}
                  error={!!errors.firstNames}
                />
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.lastName'
                  })}
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Dropdown
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.countryCodeOfResidence'
                  })}
                  name="countryCodeOfResidence"
                  fluid
                  search
                  selection
                  options={countryOptions}
                  value={values.countryCodeOfResidence}
                  onChange={(event, data) => {
                    setFieldValue(data.name, data.value)
                  }}
                  error={!!errors.countryCodeOfResidence}
                  selectOnBlur={false}
                />
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.ownershipPercentage'
                  })}
                  name="ownershipPercentage"
                  type="number"
                  value={values.ownershipPercentage}
                  onChange={handleChange}
                  error={!!errors.ownershipPercentage}
                  selectOnBlur={false}
                />
              </Form.Group>
              <Form.Field error={!!errors.reason}>
                <Form.Group inline>
                  <label>
                    {intl.formatMessage({
                      id: 'modalOwner.form.label.reason'
                    })}
                  </label>
                  <Form.Radio
                    label={intl.formatMessage({
                      id: 'enum.ultimateBeneficialOwnerReason.DUE_TO_OWNERSHIP'
                    })}
                    value="DUE_TO_OWNERSHIP"
                    checked={values.reason === 'DUE_TO_OWNERSHIP'}
                    onChange={(event, data) => {
                      setFieldValue('reason', data.value)
                    }}
                  />
                  <Form.Radio
                    label={intl.formatMessage({
                      id: 'enum.ultimateBeneficialOwnerReason.DUE_TO_ACTUAL_CONTROL'
                    })}
                    value="DUE_TO_ACTUAL_CONTROL"
                    checked={values.reason === 'DUE_TO_ACTUAL_CONTROL'}
                    onChange={(event, data) => {
                      setFieldValue('reason', data.value)
                    }}
                  />
                </Form.Group>
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Dropdown
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.jobTitle'
                  })}
                  name="jobTitle"
                  fluid
                  search
                  selection
                  options={jobTitleOptions}
                  clearable
                  allowAdditions={true}
                  additionLabel={
                    intl.formatMessage({
                      id: 'modalOwner.form.dropdown.jobTitle.add'
                    }) + ' '
                  }
                  value={values.jobTitle}
                  onChange={(event, data) => {
                    if (
                      !!!jobTitleOptions.find(
                        (item) => item.value === data.value
                      )
                    ) {
                      setJobTitleOptions([
                        ...jobTitleOptions,
                        {
                          key: data.value,
                          text: data.value,
                          value: data.value
                        }
                      ])
                    }
                    setFieldValue(data.name, data.value)
                  }}
                  error={!!errors.jobTitle}
                  selectOnBlur={false}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              loading={addOwnerMutation.isPending}
              disabled={addOwnerMutation.isPending}
            >
              <FormattedMessage id="common.button.add" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalAddUltimateBeneficialOwner
