import React, { useState } from 'react'
import { Form, Header } from 'semantic-ui-react'

import JSONView from './JSONView'
import StepNextButton from './StepNextButton'

export const initialValues = {
  realm: '',
  settlementRecipientName: ''
}

export const StartingForm = ({ onDataCompleted }) => {
  const [jsonData, setJsonData] = useState('')
  const [jsonError, setJsonError] = useState(false)

  const onNextClicked = () => {
    if (jsonError) {
      return
    }
    onDataCompleted(jsonData, 'starting')
  }

  return (
    <>
      <Form onSubmit={onNextClicked}>
        <StepNextButton />
        <Header>Syötä JSON</Header>
        <Form.Field error={jsonError}>
          <JSONView
            onChangeJson={(data) => setJsonData(data)}
            onJsonError={(error) => setJsonError(error)}
          ></JSONView>
        </Form.Field>
      </Form>
    </>
  )
}

export default StartingForm
