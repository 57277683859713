import { Dimmer, Loader, Segment, Table } from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import { MutationErrorMessage } from '..'
import React from 'react'

const MoovyTable = (props) => {
  const { query } = props

  const EmptyTable = ({ children, query, emptyTextId, errorTextId }) => {
    return (
      <>
        {React.Children.map(children, (child, i) => {
          // Ignore the second child, i.e. empty table cell content
          if (i > 1) return
          return child
        })}
        <Table.Body>
          <Table.Row>
            <Table.Cell colSpan={99}>
              {query?.isError ? (
                <MutationErrorMessage
                  mutation={query}
                  defaultErrorTextLangId={
                    errorTextId || 'moovyTable.action.failed'
                  }
                  messageNegative
                />
              ) : (
                <div style={{ textAlign: 'center' }}>
                  {emptyTextId || (
                    <FormattedMessage id="moovyTable.action.emptySet" />
                  )}
                </div>
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </>
    )
  }

  const ContentTable = (props) => {
    const getTableProps = (props) => {
      let tableProps = { ...props }
      delete tableProps.children
      delete tableProps.items
      delete tableProps.query
      delete tableProps.emptyTextId
      delete tableProps.errorTextId
      return tableProps
    }

    const { children, items, query, emptyTextId, errorTextId } = props
    return (
      <Table {...getTableProps(props)}>
        {(items?.length > 0 && children) || (
          <EmptyTable
            query={query}
            emptyTextId={emptyTextId}
            errorTextId={errorTextId}
          >
            {children}
          </EmptyTable>
        )}
      </Table>
    )
  }

  const LoadingTable = (props) => {
    return (
      <Segment>
        <ContentTable {...props} />
        <Dimmer active inverted>
          <Loader inverted />
        </Dimmer>
      </Segment>
    )
  }

  return query?.isFetching ? (
    <LoadingTable {...props} />
  ) : (
    <ContentTable {...props} />
  )
}

export default MoovyTable
