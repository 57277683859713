import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import {
  Button,
  Container,
  Form,
  Header,
  Message,
  Popup,
  TextArea
} from 'semantic-ui-react'

import useAuthInfo from '../../../hooks/useAuthInfo'
import parkingService from '../../../services/Parking'
import { MutationErrorMessage } from '../../../components'

const dateTimeFormat = 'DD.MM.YYYY HH:mm'

const EditParking = ({ parking, onUpdate }) => {
  const [startTime, setStartTime] = useState(moment().format(dateTimeFormat))
  const [endTime, setEndTime] = useState(moment().format(dateTimeFormat))
  const [comment, setComment] = useState('')
  const [message, setMessage] = useState()
  const [touched, setTouched] = useState(false)
  // React-query isn't used for mutations, but we want to use MutationErrorMessage component to show localized and other errors
  // => create mutation-like object for errors by hand.
  const [mutationErrorMessage, setMutationErrorMessage] = useState()

  const { operatorRealm } = useAuthInfo()

  useEffect(() => {
    setStartTime(moment(parking.start).format(dateTimeFormat))
    // If parking is ongoing, don't use possible end time but current time.
    const initialParkingEnd = parking.ongoing ? undefined : parking.end
    setEndTime(moment(initialParkingEnd).format(dateTimeFormat))
  }, [parking.ref])

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage()
    }, 3000)
    return () => clearTimeout(timer)
  }, [message])

  const startMoment = moment(startTime, dateTimeFormat, true)
  const endMoment = moment(endTime, dateTimeFormat, true)
  const startValid = startMoment.isValid()
  const endValid = endMoment.isValid()
  const dateRangeValid =
    startValid && endValid && startMoment.isBefore(endMoment)

  const { ongoing } = parking
  const editLabel = ongoing ? 'Lopeta pysäköinti' : 'Muokkaa pysäköintiä'

  const endParking = () => {
    parkingService
      .endParking(
        parking.ref,
        moment(endTime, dateTimeFormat).toISOString(),
        comment,
        'END',
        operatorRealm
      )
      .then((data) => {
        setMessage('Pysäköinti päätetty')
        setTouched(false)
        onUpdate(data)
      })
      .catch((err) => setMutationErrorMessage({ isError: true, error: err }))
  }

  const modifyParking = () => {
    parkingService
      .modifyParking(
        parking.ref,
        moment(startTime, dateTimeFormat).toISOString(),
        moment(endTime, dateTimeFormat).toISOString(),
        comment,
        operatorRealm
      )
      .then((data) => {
        setMessage('Pysäköinti muutettu')
        setTouched(false)
        onUpdate(data)
      })
      .catch((err) => setMutationErrorMessage({ isError: true, error: err }))
  }

  const resetParking = () => {
    if (ongoing) {
      parkingService
        .endParking(parking.ref, parking.start, comment, 'END', operatorRealm)
        .then((data) => {
          setMessage('Pysäköinti nollattu')
          setEndTime(moment(parking.start).format(dateTimeFormat))
          setTouched(false)
          onUpdate(data)
        })
        .catch((err) => setMutationErrorMessage({ isError: true, error: err }))
    } else {
      parkingService
        .modifyParking(
          parking.ref,
          parking.start,
          parking.start,
          comment,
          operatorRealm
        )
        .then((data) => {
          setMessage('Pysäköinti nollattu')
          setEndTime(moment(parking.start).format(dateTimeFormat))
          setTouched(false)
          onUpdate(data)
        })
        .catch((err) => setMutationErrorMessage({ isError: true, error: err }))
    }
  }

  return (
    <Container textAlign="center" style={{ marginTop: '1em' }}>
      <Header>{editLabel}</Header>
      <Form style={{ padding: '1em' }}>
        <Form.Field disabled={ongoing} error={!startValid}>
          <label>Aloitusaika</label>
          <DateTimeInput
            value={startTime}
            onChange={(ev, { value }) => {
              setStartTime(value)
              setTouched(true)
            }}
            dateFormat="DD.MM.YYYY"
            startMode="month"
            name="startDateTime"
            placeholder="Lopetusaika"
            iconPosition="left"
            animation="none"
            maxDate={endTime}
            closable
          />
        </Form.Field>

        <Form.Field error={!endValid}>
          <label>Päättymisaika</label>
          <DateTimeInput
            value={endTime}
            onChange={(ev, { value }) => {
              setEndTime(value)
              setTouched(true)
            }}
            dateFormat="DD.MM.YYYY"
            name="endDateTime"
            placeholder="Lopetusaika"
            iconPosition="left"
            animation="none"
            startMode="month"
            minDate={startTime}
            closable
          />
        </Form.Field>
        <Form.Field>
          <label>Kommentti</label>
          <TextArea
            placeholder="Voit lisätä muutokseen liittyvän kommentin"
            value={comment}
            onChange={(ev) => setComment(ev.target.value)}
          />
        </Form.Field>
        <Form.Field>
          {touched && !dateRangeValid && (
            <p style={{ color: 'red' }}>
              Aloitusaika on oltava ennen päättymisaikaa
            </p>
          )}
          <Button
            primary
            style={{ marginTop: '1em' }}
            disabled={!dateRangeValid}
            onClick={ongoing ? endParking : modifyParking}
          >
            {editLabel}
          </Button>
        </Form.Field>
        <Popup
          trigger={
            <Button
              color="red"
              style={{ marginTop: '1em' }}
              onClick={resetParking}
            >
              Nollaa pysäköinti
            </Button>
          }
          content="Aseta päättymisajaksi pysäköinnin aloitusaika"
          position="bottom center"
        />
      </Form>

      <div style={{ margin: '1em' }}>
        {message && (
          <Message info>
            <Message.Header>{message}</Message.Header>
          </Message>
        )}
        <MutationErrorMessage
          mutation={mutationErrorMessage}
          defaultErrorTextLangId="common.mutation.error"
          messageNegative
        />
      </div>
    </Container>
  )
}

export default EditParking
