import { showMoovyToast } from '.'

export const showToastGrantedVoucherCreated = (grantedVoucher) => {
  if (!grantedVoucher) {
    return
  }

  let description = 'Etu myönnetty'

  if (grantedVoucher.licensePlateNumber) {
    description += ` tiedoilla ${grantedVoucher.licensePlateNumber}`
    if (grantedVoucher.grantedTo) {
      description += ` ja  ${grantedVoucher.grantedTo}`
    }
  } else if (grantedVoucher.grantedTo) {
    description += ` tiedoilla ${grantedVoucher.grantedTo}`
  }
  description += '.'

  showMoovyToast('Pysäköintietu myönnetty', description)
}
