const directions = {
  DESC: 'DESC',
  ASC: 'ASC',
  stringValues: {
    DESC: 'descending',
    ASC: 'ascending'
  }
}

const getInitialValues = (sort) => {
  return {
    activePage: 1,
    totalPages: 1,
    sort,
    direction: directions.ASC
  }
}

const getTotalPages = (pageable) => {
  const total = pageable?.total
  const size = pageable?.size
  if (!size || size === 0) return total || 0
  if (!total || total === 0) return 0
  return Math.ceil(total / size)
}

const getSortDirection = (clickedColumnName, { sort, direction }) => {
  return clickedColumnName === sort ? direction : null
}

const sortColumn = (clickedColumnName, { sort, direction }, onSort) => {
  if (clickedColumnName === sort) {
    onSort(
      clickedColumnName,
      direction === directions.stringValues.DESC
        ? directions.ASC
        : directions.DESC
    )
  } else {
    onSort(clickedColumnName, directions.DESC)
  }
}

export default {
  directions,
  getInitialValues,
  getTotalPages,
  getSortDirection,
  sortColumn
}
