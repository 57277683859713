import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Select } from 'semantic-ui-react'
import { useDebounce } from 'use-debounce'

import { MoovyTableFilter } from '../../components'

function ParkingFilters({ values, setValues, listFor }) {
  const intl = useIntl()

  // LicensePlate has it's own state, since we want to debounce it but not other search params
  const [licensePlate, setLicensePlate] = useState('')
  const [debouncedLicensePlate] = useDebounce(licensePlate, 750)
  // And update the query only when the debouncedValue has changed
  useEffect(() => {
    setValues({ ...values, licensePlate: debouncedLicensePlate, page: 0 })
  }, [debouncedLicensePlate])

  const updateQuery = (event, { name, value }) => {
    const newValues = {
      ...values,
      [name]: value,
      page: 0
    }
    setValues(newValues)
  }

  return (
    <MoovyTableFilter
      defaultFilterRange={'MONTH'}
      onSelectedDateTimeRange={(range) => {
        setValues({
          ...values,
          begin: range.startTime,
          end: range.endTime
        })
      }}
    >
      <Form.Group>
        {listFor === 'LOCATION' && (
          <Form.Field
            control={Select}
            label={intl.formatMessage({
              id: 'parkingFilters.form.label.type'
            })}
            options={[
              { key: 'ALL', text: 'Kaikki', value: 'ALL' },
              {
                key: 'MOOVY_PARKING',
                text: intl.formatMessage({
                  id: 'parkingFilters.form.label.type.options.MOOVY_PARKING'
                }),
                value: 'MOOVY_PARKING'
              },
              {
                key: 'ANONYMOUS_PARKING',
                text: intl.formatMessage({
                  id: 'parkingFilters.form.label.type.options.ANONYMOUS_PARKING'
                }),
                value: 'ANONYMOUS_PARKING'
              }
            ]}
            value={values.parkingType}
            name="parkingType"
            onChange={updateQuery}
          />
        )}
        <Form.Field
          control={Select}
          label={intl.formatMessage({
            id: 'parkingFilters.form.label.status'
          })}
          options={[
            {
              key: 'ALL',
              text: intl.formatMessage({
                id: 'parkingFilters.form.label.status.options.ALL'
              }),
              value: 'ALL'
            },
            {
              key: 'ONGOING',
              text: intl.formatMessage({
                id: 'parkingFilters.form.label.status.options.ONGOING'
              }),
              value: 'ONGOING'
            },
            {
              key: 'ENDED',
              text: intl.formatMessage({
                id: 'parkingFilters.form.label.status.options.ENDED'
              }),
              value: 'ENDED'
            }
          ]}
          value={values.includeStatuses}
          name="includeStatuses"
          onChange={updateQuery}
        />
        <Form.Field
          control={Select}
          label="Pysäköintitapa"
          options={[
            {
              key: 'ALL',
              text: intl.formatMessage({
                id: 'parkingFilters.form.label.parkingMethod.options.ALL'
              }),
              value: 'ALL'
            },
            {
              key: 'SUBSCRIPTION',
              text: intl.formatMessage({
                id:
                  'parkingFilters.form.label.parkingMethod.options.SUBSCRIPTION'
              }),
              value: 'SUBSCRIPTION'
            },
            {
              key: 'SHORT_TERM',
              text: intl.formatMessage({
                id: 'parkingFilters.form.label.parkingMethod.options.SHORT_TERM'
              }),
              value: 'SHORT_TERM'
            }
          ]}
          value={values.parkingMode}
          name="parkingMode"
          onChange={updateQuery}
        />
        <Form.Field>
          <Form.Input
            label={intl.formatMessage({
              id: 'parkingFilters.form.label.licensePlate'
            })}
            name="licensePlate"
            value={licensePlate}
            onChange={(e, { value }) => setLicensePlate(value)}
          />
        </Form.Field>
      </Form.Group>
    </MoovyTableFilter>
  )
}
export default ParkingFilters
