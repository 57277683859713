import { configureStore } from '@reduxjs/toolkit'

import auth from './auth'
import ui from './globalUI'
import localization from './localization'

const store = configureStore({
  reducer: {
    auth,
    localization,
    ui
  }
})
export default store
