import {
  Button,
  Grid,
  Header,
  Icon,
  Popup,
  Segment,
  Table
} from 'semantic-ui-react'
import { formatDateAndTime, formatPrice } from '../../../services/utils'

import PropTypes from 'prop-types'
import React from 'react'
import RelativeLinkForCurrentUser from '../../../containers/RelativeLinkForCurrentUser'
import pricingService from '../../../services/Pricing'
import servicesService from '../../../services/Services'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

export function ServiceCard({ service, loading = false }) {
  return (
    <Segment basic loading={loading}>
      {service.publicService ? (
        <Header as="h4" color="blue">
          <Icon name="users" />
          Julkinen tuote
        </Header>
      ) : (
        <Header as="h4" color="yellow">
          <Icon name="lock" />
          Yksityinen tuote
        </Header>
      )}
      {service.description && (
        <>
          <Header as="h4" attached="top">
            Kuvaus
          </Header>
          <Segment secondary attached>
            {service.description.split('\n').map((line, index) => (
              <p key={index}>{line}</p>
            ))}
          </Segment>
        </>
      )}
    </Segment>
  )
}

export function ServicePricingCard({ pricing, loading = false }) {
  return (
    <Segment basic loading={loading}>
      <Header>Hinnoittelu</Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Alkamispäivä</Table.HeaderCell>
            <Table.HeaderCell>Päättymispäivä</Table.HeaderCell>
            <Table.HeaderCell>Kuukausimaksu</Table.HeaderCell>
            <Table.HeaderCell>Tuotteen hinta tekstinä</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {pricing &&
            pricing.map((p) => (
              <Table.Row key={p.ref}>
                <Table.Cell>{formatDateAndTime(p.startTime)}</Table.Cell>
                <Table.Cell>{formatDateAndTime(p.endTime)}</Table.Cell>
                <Table.Cell>{formatPrice(p.periodicFee)}</Table.Cell>
                <Table.Cell>
                  {p.description && (
                    <Popup
                      trigger={<Button>Näytä</Button>}
                      on="click"
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: p.description
                          }}
                        />
                      }
                      basic
                    />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </Segment>
  )
}

export function ServiceLocationCard({ locations, loading = false }) {
  return (
    <Segment basic loading={loading}>
      <Header>Kohteet</Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Nimi</Table.HeaderCell>
            <Table.HeaderCell>Sisäinen nimi</Table.HeaderCell>
            <Table.HeaderCell>Kaupunki</Table.HeaderCell>
            <Table.HeaderCell>Anonyymi pysäköinti</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {locations.map((location) => (
            <Table.Row key={location.ref}>
              <Table.Cell>
                <RelativeLinkForCurrentUser to={`locations/${location.ref}`}>
                  {location.name}
                </RelativeLinkForCurrentUser>
              </Table.Cell>
              <Table.Cell>{location.internalName}</Table.Cell>
              <Table.Cell>{location.city}</Table.Cell>
              <Table.Cell>
                {location.anonymousParkingSupported ? 'Kyllä' : 'Ei'}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Segment>
  )
}
const ServiceInformation = ({ serviceRef }) => {
  const { operatorRealm } = useAuthInfo()
  const serviceQuery = useQuery({
    queryKey: ['service', serviceRef],
    queryFn: () => servicesService.fetchService(serviceRef, operatorRealm)
  })

  const service = serviceQuery.data ? serviceQuery.data : {}

  const pricing = useQuery({
    queryKey: ['servicePricing', serviceRef],
    queryFn: () => pricingService.fetchServicePricing(serviceRef, operatorRealm)
  })

  const locations = service && service.locations ? service.locations : []
  return (
    <Segment basic loading={serviceQuery.isLoading}>
      <Grid stackable columns="2">
        <Grid.Column width="7">
          <ServiceCard service={service} />
        </Grid.Column>
        <Grid.Column width="9">
          <ServicePricingCard pricing={pricing} />
          <ServiceLocationCard locations={locations} />
        </Grid.Column>
      </Grid>
    </Segment>
  )
}

ServiceInformation.propTypes = {
  serviceRef: PropTypes.string.isRequired
}

export default ServiceInformation
