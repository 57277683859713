import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'semantic-ui-react'

import { ServiceWithTags } from '../../components'
import TablePagination from '../../components/TablePagination'
import { tablePaginationDirections } from '../../services/utils/tablePagination'

const ParkingServiceTable = ({
  parkingServices,
  isSuperOperator,
  onSelectedRow,
  onHandleSort,
  onPageChange,
  pagination
}) => {
  return (
    <Table selectable sortable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={
              pagination.sortColumn === 'sortByName'
                ? tablePaginationDirections.stringValues[pagination.direction]
                : null
            }
            onClick={onHandleSort('sortByName')}
          >
            <FormattedMessage id="parkingService.header.service" />
          </Table.HeaderCell>
          {isSuperOperator && (
            <Table.HeaderCell
              sorted={
                pagination.sortColumn === 'sortByOperator'
                  ? tablePaginationDirections.stringValues[pagination.direction]
                  : null
              }
              onClick={onHandleSort('sortByOperator')}
            >
              <FormattedMessage id="parkingService.header.operator" />
            </Table.HeaderCell>
          )}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {parkingServices &&
          parkingServices.map((parkingService) => (
            <Table.Row
              key={parkingService.ref}
              onClick={() => {
                onSelectedRow(parkingService)
              }}
            >
              <Table.Cell>
                {parkingService.name}
                <ServiceWithTags parkingService={parkingService} />
              </Table.Cell>
              {isSuperOperator && parkingService.operator && (
                <Table.Cell>{parkingService.operator.name}</Table.Cell>
              )}
            </Table.Row>
          ))}
      </Table.Body>
      <TablePagination
        colSpan={2}
        activePage={pagination.activePage}
        totalPages={pagination.totalPages}
        onPageChange={onPageChange}
      />
    </Table>
  )
}

export default ParkingServiceTable
