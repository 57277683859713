import React, { Component } from 'react'

const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'

/**
 * Higher-order component for multi-select functionality.
 * Works only for items which have 'ref' field.
 * @param {*} WrappedComponent Component to wrap
 */
export default (WrappedComponent) =>
  class WithSelections extends Component {
    static displayName = `WithSelections(${getDisplayName(WrappedComponent)})`

    state = {
      selected: [],
      selectedCount: 0
    }

    handleClearAll = () => {
      this.setState({ selected: [], selectedCount: 0 })
    }

    handleSelect = (ref) => {
      const { selected } = this.state
      const selectedIndex = selected.indexOf(ref)
      let newSelected = []

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, ref)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }

      this.setState({
        selected: newSelected,
        selectedCount: newSelected.length
      })
    }

    handleSelectAll = (items, notSelectableItems) => {
      let newSelected = []

      if (!this.areAnySelected()) {
        let filteredItems =
          notSelectableItems?.length > 0
            ? items.filter((item) => !notSelectableItems.includes(item))
            : items
        filteredItems.forEach((item) => {
          newSelected.push(item.ref)
        })
      }

      this.setState({
        selected: newSelected,
        selectedCount: newSelected.length
      })
    }

    areAllIndeterminate = (items) =>
      this.areAnySelected() && !this.areAllSelected(items)

    areAllSelected = (items) =>
      items.every((item) => this.state.selected.includes(item.ref))

    areAnySelected = () => this.state.selectedCount > 0

    isItemSelected = (ref) => this.state.selected.includes(ref)

    render() {
      return (
        <WrappedComponent
          {...this.props}
          {...this.state}
          handleClearAll={this.handleClearAll}
          handleSelect={this.handleSelect}
          handleSelectAll={this.handleSelectAll}
          areAllIndeterminate={this.areAllIndeterminate}
          areAllSelected={this.areAllSelected}
          areAnySelected={this.areAnySelected}
          isItemSelected={this.isItemSelected}
        />
      )
    }
  }
