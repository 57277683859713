import { get, post, put } from '../utils/api-renewed'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/${relativePath}`
    : `/administration/${relativePath}`

const fetchParking = async (parkingRef, operatorRealm) => {
  try {
    const result = await get(
      createFullPath(`sessions/${parkingRef}`, operatorRealm)
    )
    return result
  } catch (e) {
    return Promise.reject(new Error(`Error ${e}`))
  }
}

const fetchParkingSequence = async (parkingRef, operatorRealm) => {
  try {
    const result = await get(
      createFullPath(`parkings/${parkingRef}/parkingSequence`, operatorRealm)
    )
    return result
  } catch (e) {
    return Promise.reject(new Error(`Error ${e}`))
  }
}

const fetchAuditLog = async (parkingRef, operatorRealm) => {
  try {
    const result = await get(
      createFullPath(`parkings/${parkingRef}/auditLog`, operatorRealm)
    )
    return result
  } catch (e) {
    return Promise.reject(new Error(`Error ${e}`))
  }
}

const fetchExternalFacilityData = async (parkingRef, operatorRealm) => {
  try {
    const result = await get(
      createFullPath(
        `parkings/${parkingRef}/externalFacilityData`,
        operatorRealm
      )
    )
    return result
  } catch (e) {
    return Promise.reject(new Error(`Error ${e}`))
  }
}

const endParking = async (
  parkingRef,
  endTime,
  comment,
  endType,
  operatorRealm
) => {
  return await post(
    createFullPath(`sessions/${parkingRef}/end`, operatorRealm),
    { endType, endTime, comment }
  )
}

const endMultipleParking = async (
  parkingRefs,
  endTime,
  comment,
  endType,
  operatorRealm
) => {
  try {
    const result = await post(
      createFullPath(`sessions/multiple/end`, operatorRealm),
      { parkingRefs, endType, endTime, comment }
    )
    return result
  } catch (e) {
    return Promise.reject(new Error(`Error ${e}`))
  }
}

const modifyParking = async (
  parkingRef,
  startTime,
  endTime,
  comment,
  operatorRealm
) => {
  return await post(
    createFullPath(`sessions/${parkingRef}/modify`, operatorRealm),
    { startTime, endTime, comment }
  )
}

const editLicensePlate = async (
  parkingRef,
  licensePlateData,
  operatorRealm
) => {
  const path = operatorRealm
    ? `/operator/${operatorRealm}/anonymousparking/${parkingRef}/editLicensePlate`
    : `/administration/operator/${operatorRealm}/anonymousparking/${parkingRef}/editLicensePlate`
  const result = await put(path, licensePlateData)
  return result
}

const fetchAnonymousPaymentEvents = async (parkingRef, operatorRealm) => {
  try {
    const path = operatorRealm
      ? `/operator/${operatorRealm}/anonymousparking/${parkingRef}/paymentEvents`
      : `/administration/operator/${operatorRealm}/anonymousparking/${parkingRef}/paymentEvents`
    const result = await get(path)
    return result
  } catch (e) {
    return Promise.reject(new Error(`Error ${e}`))
  }
}

const anonymousparkingAllowExit = async (
  parkingRef,
  comment,
  operatorRealm
) => {
  try {
    const path = operatorRealm
      ? `/operator/${operatorRealm}/anonymousparking/${parkingRef}/allowExit`
      : `/administration/operator/${operatorRealm}/anonymousparking/${parkingRef}/allowExit`
    const result = await put(path, { comment })
    return result
  } catch (e) {
    return Promise.reject(new Error(`Error ${e}`))
  }
}

const fetchUsedVouchers = async (parkingRef, operatorRealm) => {
  try {
    const result = await get(
      createFullPath(`parkings/${parkingRef}/vouchers`, operatorRealm)
    )
    return result
  } catch (e) {
    return Promise.reject(new Error())
  }
}

const fetchRemainingPrice = async (
  parkingRef,
  operatorRealm,
  queryParams = {}
) => {
  try {
    const result = await get(
      createFullPath(`parkings/${parkingRef}/remainingPrice`, operatorRealm),
      queryParams
    )
    return result
  } catch (e) {
    return Promise.reject(new Error())
  }
}

const fetchSales = async (parkingRef, operatorRealm) => {
  const result = await get(
    createFullPath(`parkings/${parkingRef}/sale`, operatorRealm)
  )
  return result
}

const fetchSaleWebPayment = async (saleRef, operatorRealm) => {
  const result = await get(
    createFullPath(`sale/${saleRef}/webPaymentData`, operatorRealm)
  )
  return result
}

const cancelSaleWebPayment = async (saleRef, reason, operatorRealm) => {
  const result = await post(
    createFullPath(`sale/${saleRef}/webPaymentData/cancel`, operatorRealm),
    { reason }
  )
  return result
}

const sendAnonymousPaymentEventReceipt = async (
  ref,
  emailAddress,
  operatorRealm
) => {
  // The superoperator url is a bit funky in this one.
  const url = operatorRealm
    ? `/operator/${operatorRealm}/anonymousparking/paymentEvent/${ref}/sendReceipt`
    : `/administration/operator/operators/anonymousparking/paymentEvent/${ref}/sendReceipt`
  const result = await post(url, { emailAddress })
  return result
}

export default {
  fetchParking,
  fetchAnonymousPaymentEvents,
  fetchParkingSequence,
  fetchAuditLog,
  fetchExternalFacilityData,
  fetchUsedVouchers,
  fetchRemainingPrice,
  fetchSales,
  fetchSaleWebPayment,
  endParking,
  endMultipleParking,
  modifyParking,
  anonymousparkingAllowExit,
  cancelSaleWebPayment,
  sendAnonymousPaymentEventReceipt,
  editLicensePlate
}
