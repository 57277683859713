import './InvoiceAdministration.scss'

import {
  Button,
  Checkbox,
  Confirm,
  Container,
  Input,
  Message,
  Popup,
  Segment,
  Table
} from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PermissionUser, formatPrice } from '../../services/utils'
import React, { useEffect, useState } from 'react'
import {
  isCardPayment,
  isCardPaymentRetryable,
  isCardPaymentRevertable,
  isReceiptSendingPossible,
  isSetAsWaivedAllowed,
  validateInvoiceRowRevert
} from './helpers'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ModalRefundConfirmation } from '..'
import ModalWaivedInvoice from '../../pages/Customers/CustomerInvoicing/ModalWaivedInvoice'
import MoovyPlaceholderLoader from '../MoovyPlaceholderLoader'
import SendEmailReceipt from './SendEmailReceipt'
import { copyToClipboard } from '../../services/utils/clipboard'
import invoiceService from '../../services/Invoicing'
import { sale } from '../../services/utils/DTOEnums'
import useAuthInfo from '../../hooks/useAuthInfo'
import userService from '../../services/User/UserServices'
import withSelections from '../../HOC/withSelections'

const emptyConfirm = {
  open: false,
  content: undefined,
  onConfirm: undefined,
  onCancel: undefined
}

const InvoiceAdministration = ({
  invoice,
  operatorRealm,
  setMessageCallback,
  updateInvoicesCallback,
  areAllIndeterminate,
  areAllSelected,
  areAnySelected,
  handleClearAll,
  handleSelect,
  handleSelectAll,
  isItemSelected,
  selectedCount,
  selected,
  onOpenAdyenModal
}) => {
  const intl = useIntl()
  const { roles } = useAuthInfo()
  const queryClient = useQueryClient()

  const selectingType = {
    NONE: 'NONE',
    CREDIT_NOTE: 'CREDIT_NOTE'
  }

  const [loading, setLoading] = useState(true)
  const [selecting, setSelecting] = useState(selectingType.NONE)
  const [invoiceRows, setInvoiceRows] = useState([])
  const [confirm, setConfirm] = useState(emptyConfirm)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [confirmCardRefund, setConfirmCardRefund] = useState(false)
  const [filter, setFilter] = useState('ALL')
  const [filteredInvoiceRows, setFilteredInvoiceRows] = useState([])
  const [showSendByEmail, setShowSendByEmail] = useState(false)
  const [sendReceiptStatus, setSendReceiptStatus] = useState()
  const { isSuperOperator } = useAuthInfo()
  const [infoPopupOpen, setInfoPopupOpen] = useState(false)
  const [showWaiveConfirmation, setShowWaiveConfirmation] = useState(false)

  const updateInvoiceRows = () => {
    setLoading(true)
    const rowsPromise = invoiceService.fetchInvoiceRows(
      invoice.ref,
      operatorRealm
    )

    // Get revert info only for Payment Highway invoices
    if (isCardPayment(invoice)) {
      const revertsPromise = invoiceService.listCardPaymentRevertableAmounts(
        invoice.ref,
        operatorRealm
      )

      Promise.all([rowsPromise, revertsPromise])
        .then(([rowsData, revertsData]) => {
          const rows = rowsData.sort((a, b) => a.rowNumber - b.rowNumber)
          const rowsWithRevertInfo = rows.map((r) => {
            const revertRow = revertsData.find(
              (rev) => rev.invoiceRowRef === r.ref
            )
            return {
              ...r,
              maximumRevertAmount: revertRow.maximumRevertAmount,
              currentRevertAmount: revertRow.maximumRevertAmount
            }
          })
          setInvoiceRows(rowsWithRevertInfo)
          setFilteredInvoiceRows(rowsWithRevertInfo)
        })
        .catch(() => {
          setMessageCallback({
            error: true,
            header: intl.formatMessage({
              id: 'invoiceAdministration.error.header'
            }),
            content: intl.formatMessage({
              id: 'invoiceAdministration.invoiceRows.error.content'
            })
          })
        })
        .finally(() => setLoading(false))
    } else {
      rowsPromise
        .then((res) => {
          const rows = res.sort((a, b) => a.rowNumber - b.rowNumber)
          setInvoiceRows(rows)
          setFilteredInvoiceRows(rows)
        })
        .catch(() => {
          setMessageCallback({
            error: true,
            header: 'Virhe',
            content: 'Laskurivien lataamisessa tapahtui virhe'
          })
        })
        .finally(() => setLoading(false))
    }
  }

  const customer = useQuery({
    queryKey: ['customer', invoice.user && invoice.user.ref],
    queryFn: () => userService.fetchUser(invoice.user.ref, operatorRealm),
    enabled: Boolean(invoice.user && invoice.user.ref)
  })

  const invoiceActionAvailability = {
    cardPaymentRetry:
      PermissionUser.HasEditInvoice(isSuperOperator, roles) &&
      isCardPaymentRetryable(invoice),
    cardPaymentRevert:
      PermissionUser.HasEditInvoice(isSuperOperator, roles) &&
      isCardPaymentRevertable,
    sendByEmail:
      PermissionUser.HasSendReceipt(isSuperOperator, roles) &&
      isReceiptSendingPossible(invoice),
    allowToSetWaived:
      PermissionUser.HasEditInvoice(isSuperOperator, roles) &&
      isSetAsWaivedAllowed(invoice)
  }

  const { mutate: markAsWaivedInvoice, ...markAsWaivedInvoiceMutation } =
    useMutation({
      mutationFn: ({ comment, invoiceRef }) =>
        invoiceService.setCardPaymentAsWaived(
          invoiceRef,
          comment,
          operatorRealm
        ),
      onSuccess: (data) => {
        const messageContent = data.userWasRemovedFromBlocklist
          ? 'Lasku asetettu aiheettomaksi, käyttäjä poistettu estolistalta'
          : 'Lasku asetettu aiheettomaksi'
        setShowWaiveConfirmation(false)
        setMessageCallback({
          info: true,
          header: 'Onnistui',
          content: messageContent
        })
        updateInvoicesCallback()
        updateInvoiceRows()
        if (data.userWasRemovedFromBlocklist) {
          queryClient.invalidateQueries({
            queryKey: ['customer', invoice.user.ref]
          })
        }
      }
    })

  const { mutate: refundCardPayment, ...refundCardPaymentMutation } =
    useMutation({
      mutationFn: (detail) =>
        invoiceService.refundCardPayment(
          invoice.ref,
          {
            invoiceRowsToRevert: filteredInvoiceRows
              .filter((row) => selected.includes(row.ref))
              .map((row) => ({
                invoiceRowRef: row.ref,
                amount: row.currentRevertAmount
              })),
            customerName: detail.customerName,
            comment: detail.comment,
            copyToClipboard: detail.copyToClipboard
          },
          operatorRealm
        ),
      onSuccess: (response, variables) => {
        if (variables?.copyToClipboard && response?.revertingInvoiceNumber) {
          copyToClipboard(intl, response?.revertingInvoiceNumber)
        }
        setConfirmCardRefund(false)
        handleClearAll()
        setFilter('ALL')
        setSelecting(selectingType.NONE)
        setMessageCallback({
          info: true,
          header: 'Onnistui',
          content: 'Palautettu onnistuneesti'
        })
        updateInvoicesCallback()
        updateInvoiceRows()
      }
    })

  useEffect(() => {
    updateInvoiceRows()
  }, [])

  useEffect(() => {
    if (filter === 'ALL') {
      setFilteredInvoiceRows(invoiceRows)
    } else if (filter === 'REVERT') {
      const revertable = invoiceRows
        .filter((row) => row.maximumRevertAmount > 0)
        .map((row) => ({
          ...row,
          currentRevertAmount: row.maximumRevertAmount
        }))
      setFilteredInvoiceRows(revertable)
    }
  }, [filter])

  const submit = async (action, successMessage, errorMessage) => {
    setIsSubmitting(true)
    setSelecting(selectingType.NONE)
    setConfirm(emptyConfirm)
    let refreshInvoice = true
    try {
      const result = await action()
      if (
        result?.paymentMethodType === sale.paymentMethodTypes.ADYEN_TRANSACTION
      ) {
        onOpenAdyenModal()
        refreshInvoice = false
      } else {
        setMessageCallback({
          info: true,
          header: 'Onnistui',
          content: successMessage
        })
      }
    } catch (e) {
      setMessageCallback({
        error: true,
        header: 'Virhe',
        content: errorMessage
      })
    } finally {
      setIsSubmitting(false)
      handleClearAll()
      setFilter('ALL')
      if (refreshInvoice) {
        updateInvoicesCallback()
        updateInvoiceRows()
      }
    }
  }

  const handleRowRevertAmountChange = (ev, rowIndex) => {
    const newArr = [...filteredInvoiceRows]
    newArr[rowIndex].currentRevertAmount = ev.target.value
    setFilteredInvoiceRows(newArr)
  }

  const handleRowRevertAmountBlur = (ev, rowIndex) => {
    const num = parseFloat(ev.target.value)
    const cleanNum = num.toFixed(2)

    const newArr = [...filteredInvoiceRows]
    newArr[rowIndex].currentRevertAmount = cleanNum
    setFilteredInvoiceRows(newArr)
  }

  const maxRevertSum = filteredInvoiceRows
    .map((row) => Number(row.maximumRevertAmount))
    .reduce((total, currentValue) => total + currentValue, 0)

  const revertSum = filteredInvoiceRows
    .filter((row) => isItemSelected(row.ref))
    .map((row) => Number(row.currentRevertAmount))
    .reduce((total, currentValue) => total + currentValue, 0)

  const revertValid = filteredInvoiceRows
    .filter((row) => isItemSelected(row.ref))
    .reduce(
      (acc, cur) =>
        acc
          ? cur.currentRevertAmount > 0 &&
            cur.currentRevertAmount <= cur.maximumRevertAmount
          : false,
      true
    )

  const confirmModals = {
    CARD_RETRY: {
      open: true,
      content:
        'Oletko varma, että haluat yrittää laskuttaa korttimaksun uudelleen?',
      onConfirm: () =>
        submit(
          () => invoiceService.retryCardPayment(invoice.ref, operatorRealm),
          'Laskutettu onnistuneesti',
          'Korttimaksun uudelleenlaskuttamisessa tapahtui virhe'
        ),
      onCancel: () => setConfirm(emptyConfirm)
    },

    CREDIT_NOTE: {
      open: true,
      content: 'Oletko varma, että haluat luoda laskulle hyvityslaskun?',
      onConfirm: () =>
        submit(
          () => invoiceService.createCreditNote(invoice.ref, operatorRealm),
          'Laskusta on luotu hyvityslasku',
          'Hyvityslaskun luonnissa tapahtui virhe'
        ),
      onCancel: () => setConfirm(emptyConfirm)
    }
  }

  const SelectedText = () => {
    const len = selected.length
    if (len === 0) {
      return 'Ei valittuja laskurivejä'
    }
    if (len > 0 && revertValid) {
      return `${len} laskuriviä valittu, yhteensä ${formatPrice(revertSum)}.`
    }
    return (
      <div style={{ color: 'red' }}>
        {len} laskuriviä valittu, virheellinen hyvitysmäärä!
      </div>
    )
  }

  if (loading) {
    return <MoovyPlaceholderLoader paragraphs={2} />
  }

  return (
    <>
      {selecting === selectingType.CREDIT_NOTE && (
        <Segment className="invoiceRowTableToolbar">
          <div>
            Laskusta on palautettavissa maksimissaan {formatPrice(maxRevertSum)}
            .
            <br />
            <SelectedText />
          </div>
          <div className="invoiceRowTableToolbarGrow" />
          <Button onClick={() => handleClearAll()}>Tyhjennä</Button>
        </Segment>
      )}
      <Container className="invoiceRowTableContainer">
        <Table compact="very" size="small" celled>
          <Table.Header fullWidth>
            <Table.Row>
              {selecting !== selectingType.NONE ? (
                <Table.HeaderCell>
                  <Checkbox
                    checked={areAllSelected(filteredInvoiceRows)}
                    indeterminate={areAllIndeterminate(filteredInvoiceRows)}
                    onChange={() => handleSelectAll(filteredInvoiceRows)}
                  />
                </Table.HeaderCell>
              ) : (
                ''
              )}
              <Table.HeaderCell>
                <FormattedMessage id="invoiceRowTable.product" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                {selecting === selectingType.CREDIT_NOTE ? (
                  <FormattedMessage id="invoiceRowTable.maxRevert" />
                ) : (
                  <FormattedMessage id="invoiceRowTable.totalPrice" />
                )}
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="invoiceRowTable.VAT" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="invoiceRowTable.description" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredInvoiceRows.map((row, index) => (
              <Table.Row
                key={row.ref}
                error={
                  !isSubmitting &&
                  selecting !== selectingType.DEBT_COLLECTION &&
                  isItemSelected(row.ref) &&
                  !validateInvoiceRowRevert(row.currentRevertAmount, row)
                }
              >
                {selecting !== selectingType.NONE ? (
                  <Table.Cell>
                    <Checkbox
                      checked={isItemSelected(row.ref)}
                      onChange={() => handleSelect(row.ref)}
                    />
                  </Table.Cell>
                ) : (
                  ''
                )}
                <Table.Cell>{row.product.name}</Table.Cell>
                <Table.Cell>
                  {!isSubmitting && isItemSelected(row.ref) ? (
                    <Input
                      className="invoiceRevertInputField"
                      value={row.currentRevertAmount}
                      error={
                        !validateInvoiceRowRevert(row.currentRevertAmount, row)
                      }
                      placeholder="0,00"
                      onChange={(ev) => handleRowRevertAmountChange(ev, index)}
                      onBlur={(ev) => handleRowRevertAmountBlur(ev, index)}
                      size="small"
                      type="number"
                    >
                      <input />€
                    </Input>
                  ) : (
                    <>
                      {selecting === selectingType.CREDIT_NOTE
                        ? formatPrice(row.maximumRevertAmount)
                        : formatPrice(row.totalPrice)}
                    </>
                  )}
                </Table.Cell>
                <Table.Cell>{row.vatPercent}%</Table.Cell>
                <Table.Cell>{row.description}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
      <div className="invoiceRowTableToolbar">
        <div className="invoiceRowTableToolbarGrow" />
        {selecting === selectingType.NONE && (
          <>
            {invoiceActionAvailability.allowToSetWaived && (
              <Button
                style={{ marginLeft: '10px' }}
                floated="right"
                primary
                onClick={() => {
                  invoice.invoicingAttemptCount < 3
                    ? setInfoPopupOpen(true)
                    : setShowWaiveConfirmation(true)
                }}
                disabled={isSubmitting}
                loading={isSubmitting}
              >
                <FormattedMessage id="modalWaivedInvoice.button.setAsWaived" />
              </Button>
            )}
            {invoiceActionAvailability.sendByEmail && (
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={() => setShowSendByEmail(true)}
              >
                Lähetä kuitti
              </Button>
            )}

            {invoiceActionAvailability.cardPaymentRetry && (
              <Popup
                content={intl.formatMessage({
                  id: 'tabAdyenInvoice.waived.popup.label'
                })}
                on="click"
                onClose={() => setInfoPopupOpen(false)}
                open={infoPopupOpen}
                trigger={
                  <Button
                    primary
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    onClick={() => setConfirm(confirmModals.CARD_RETRY)}
                  >
                    Korttimaksun uudelleenveloitus
                  </Button>
                }
              />
            )}
            {invoiceActionAvailability.cardPaymentRevert && (
              <Button
                primary
                disabled={isSubmitting}
                loading={isSubmitting}
                onClick={() => {
                  handleClearAll()
                  setFilter('REVERT')
                  setSelecting(selectingType.CREDIT_NOTE)
                }}
              >
                Palauta korttimaksua
              </Button>
            )}
          </>
        )}

        {selecting === selectingType.CREDIT_NOTE ? (
          <>
            <Button
              onClick={() => {
                handleClearAll()
                setFilter('ALL')
                setSelecting(selectingType.NONE)
              }}
            >
              Peruuta
            </Button>
            <Button
              primary
              disabled={isSubmitting || !revertValid || !areAnySelected()}
              loading={isSubmitting}
              onClick={() => setConfirmCardRefund(true)}
            >
              Palauta valitut laskurivit
            </Button>
          </>
        ) : (
          ''
        )}
      </div>
      <Confirm {...confirm} cancelButton="Peruuta" confirmButton="Hyväksy" />
      <ModalRefundConfirmation
        open={confirmCardRefund}
        invoiceUser={customer?.data}
        onClose={() => setConfirmCardRefund(false)}
        lang={{
          titleElement: (
            <FormattedMessage
              id="modalRefundConfirmation.title"
              values={{
                selectedCount,
                revertSum: formatPrice(revertSum)
              }}
            />
          ),
          bodyElement: (
            <FormattedMessage
              id="modalRefundConfirmation.body"
              values={{
                selectedCount,
                revertSum: formatPrice(revertSum)
              }}
            />
          ),

          buttonConfirmKey: 'common.button.confirm',
          defaultErroKey:
            'customers.detail.toolbar.cancelDeletionRequest.action.error'
        }}
        onSubmit={(detail) => refundCardPayment(detail)}
        mutation={refundCardPaymentMutation}
      />

      {sendReceiptStatus === 'SUCCESS' && (
        <Message success>Kuitti lähetetty sähköpostilla</Message>
      )}
      {sendReceiptStatus === 'ERROR' && (
        <Message error>Kuitti lähettämisessä tapahtui virhe</Message>
      )}

      {showSendByEmail && (
        <SendEmailReceipt
          onClose={() => setShowSendByEmail(false)}
          customerEmail={
            customer.data && customer.data.userDetails
              ? customer.data.userDetails.email
              : ''
          }
          onSubmit={(email) => {
            invoiceService
              .sendInvoiceReceipt(invoice.ref, email)
              .then(() => setSendReceiptStatus('SUCCESS'))
              .catch(() => setSendReceiptStatus('ERROR'))
            setShowSendByEmail(false)
            setTimeout(() => setSendReceiptStatus(null), 5000)
          }}
        />
      )}
      <ModalWaivedInvoice
        open={showWaiveConfirmation}
        invoice={invoice}
        onClose={() => {
          markAsWaivedInvoiceMutation.reset()
          setShowWaiveConfirmation(false)
        }}
        onSubmit={(comment) =>
          markAsWaivedInvoice({ comment, invoiceRef: invoice.ref })
        }
        mutation={markAsWaivedInvoiceMutation}
      />
    </>
  )
}

export default withSelections(InvoiceAdministration)
