import {
  Button,
  Grid,
  Icon,
  Label,
  Loader,
  Menu,
  Modal,
  Popup,
  Tab
} from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'
import TabAdyenRefunds, {
  isAllSuccessRefundInvoiceRows,
  isAnyCreatedOrReceivedRefundInvoiceRows,
  isAnyErrorRefundInvoiceRows
} from './tabAdyenRefunds/tabAdyenRefunds'

import AdyenInvoiceSummary from './adyenInvoiceSummary'
import { InvoiceOperationLogTypeEnum } from '../../services/utils'
import { MoovyPlaceholderLoader } from '../../components'
import TabAdyenInvoiceRows from './tabAdyenInvoice/tabAdyenInvoiceRows'
import TabAdyenWaiving from './tabAdyenWaiving/tabAdyenWaiving'
import invoiceService from '../../services/Invoicing'
import invoicesService from '../../services/Adyen/Invoices'
import { useQuery, useQueryClient } from '@tanstack/react-query'

const ModalAdyenInvoice = ({
  onClose,
  invoiceRef,
  operatorRealm,
  updateInvoicesCallback,
  onRefreshUser
}) => {
  const intl = useIntl()
  const queryClient = useQueryClient()

  const [refundingSelected, setRefundingSelected] = useState(false)
  const invoiceQuery = useQuery({
    queryKey: ['invoice', invoiceRef, operatorRealm],
    queryFn: () => invoiceService.fetchInvoice(invoiceRef),
    refetchOnWindowFocus: false
  })
  const invoice = invoiceQuery.data

  const adyenInvoiceRefundQuery = useQuery({
    queryKey: ['adyenInvoiceRefund', invoiceRef, operatorRealm],
    queryFn: () =>
      invoicesService.listCardPaymentReverts(invoiceRef, operatorRealm),
    enabled: !!invoice?.isRefunded,
    refetchOnWindowFocus: false
  })
  const invoiceRefund = adyenInvoiceRefundQuery.data || null

  const compensatedInvoice = invoice?.compensatedInvoice

  const getWaivedTab = () => {
    if (
      invoice?.operationLogs?.find(
        (item) => item.logType === InvoiceOperationLogTypeEnum.WAIVED
      ) !== undefined
    ) {
      return {
        menuItem: intl.formatMessage({
          id: 'modalAdyenInvoice.tab.waiving.title'
        }),
        render: () => (
          <Tab.Pane>
            <TabAdyenWaiving invoice={invoice} />
          </Tab.Pane>
        )
      }
    }

    return null
  }

  const getCardPaymentsTab = () => {
    if (invoice) {
      return {
        menuItem: compensatedInvoice
          ? intl.formatMessage({
              id: 'modalAdyenInvoice.tab.cardRefunds.title'
            })
          : intl.formatMessage({
              id: 'modalAdyenInvoice.tab.cardPayments.title'
            }),
        render: () => (
          <Tab.Pane>
            <TabAdyenInvoiceRows
              invoice={invoice}
              compensatedInvoice={compensatedInvoice}
              isAnyInvoiceRowRefundOngoing={isAnyCreatedOrReceivedRefundInvoiceRows(
                invoiceRefund
              )}
              operatorRealm={operatorRealm}
              refreshInvoiceCallback={() => {
                invoiceQuery.refetch()
                queryClient.invalidateQueries({
                  queryKey: ['adyenInvoiceRefund', invoiceRef, operatorRealm]
                })
                updateInvoicesCallback()
              }}
              refundingSelected={(selected) => setRefundingSelected(selected)}
              onRefreshUser={onRefreshUser}
            />
          </Tab.Pane>
        )
      }
    }

    return null
  }

  const RefundTabMenuIcon = ({ invoiceRefund }) => {
    if (!invoiceRefund) {
      return ''
    }

    let popupContent

    if (isAnyCreatedOrReceivedRefundInvoiceRows(invoiceRefund)) {
      popupContent = {}
      popupContent.popupTextId =
        'modalAdyenInvoice.tab.cardRefunds.title.popup.receivedRefund'
      popupContent.popupIconName = 'time'
      popupContent.popupIconColor = 'yellow'
    } else if (isAnyErrorRefundInvoiceRows(invoiceRefund)) {
      popupContent = {}
      popupContent.popupTextId =
        'modalAdyenInvoice.tab.cardRefunds.title.popup.errorRefund'
      popupContent.popupIconName = 'warning'
      popupContent.popupIconColor = 'red'
    } else if (!isAllSuccessRefundInvoiceRows(invoiceRefund)) {
      popupContent = {}
      popupContent.popupTextId =
        'modalAdyenInvoice.tab.cardRefunds.title.popup.abnormality'
      popupContent.popupIconName = 'time'
      popupContent.popupIconColor = 'yellow'
    }

    if (popupContent) {
      return (
        <Popup
          content={intl.formatMessage({
            id: popupContent.popupTextId
          })}
          trigger={
            <Label
              floating
              style={{ backgroundColor: 'transparent', fontSize: '1em' }}
            >
              <Icon
                name={popupContent.popupIconName}
                color={popupContent.popupIconColor}
              />
            </Label>
          }
        />
      )
    }

    return ''
  }

  const getRefundsTab = () => {
    if (invoiceRefund) {
      return {
        menuItem: (
          <Menu.Item key="messages">
            {intl.formatMessage({
              id: 'modalAdyenInvoice.tab.cardRefunds.title'
            })}
            <RefundTabMenuIcon invoiceRefund={invoiceRefund} />
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane>
            <TabAdyenRefunds
              refundInvoice={invoiceRefund}
              originalInvoice={invoice}
            />
          </Tab.Pane>
        )
      }
    }

    return null
  }

  const getPanes = () => {
    const allTabs = [getWaivedTab(), getCardPaymentsTab(), getRefundsTab()]
    const tabs = allTabs.filter((item) => item)
    tabs.length === 0 &&
      tabs.push({
        render: () => (
          <Tab.Pane>
            <MoovyPlaceholderLoader paragraphs={2} />
            <Loader active inline="centered"></Loader>
          </Tab.Pane>
        )
      })

    return tabs
  }

  return (
    <Modal open onClose={onClose} size="large">
      <Modal.Content>
        <Grid container stackable columns="equal">
          <Grid.Column width={invoiceRefund || refundingSelected ? 2 : 3}>
            <AdyenInvoiceSummary invoice={invoice} />
          </Grid.Column>
          <Grid.Column>
            <Tab panes={getPanes()} />
          </Grid.Column>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>
          <FormattedMessage id="common.button.close" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalAdyenInvoice
