import { Button, Grid, Icon, Popup, Table } from 'semantic-ui-react'
import React, { useState } from 'react'
import { formatDateAndTime, formatPrice } from '../../services/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { FormattedMessage } from 'react-intl'
import ModalAddTariff from './ModalAddTariff'
import { MoovyButton } from '../../components'
import { PermissionService } from '../../services/utils'
import PropTypes from 'prop-types'
import pricingService from '../../services/Pricing'
import useAuthInfo from '../../hooks/useAuthInfo'

const PricingTable = ({ serviceRef, operatorRealm, onClickTariff }) => {
  const queryKey = 'servicePricing'

  const pricing = useQuery({
    queryKey: ['servicePricing', serviceRef],
    queryFn: () => pricingService.fetchServicePricing(serviceRef, operatorRealm)
  })
  const [addTariffModalOpen, setAddTariffModalOpen] = useState(false)

  const { isSuperOperator, roles } = useAuthInfo()

  const queryClient = useQueryClient()

  const onClickRow = (ref) => {
    if (isSuperOperator) {
      onClickTariff(ref)
    }
  }

  return (
    <>
      {PermissionService.HasEditPricing(isSuperOperator, roles) && (
        <Grid style={{ paddingTop: '10px' }}>
          <Grid.Column floated="right" width={8} textAlign="right">
            <MoovyButton onClick={() => setAddTariffModalOpen(true)}>
              <Icon name="credit card" />
              <FormattedMessage id="parkingServiceDetails.tab.pricing.button.addPricing" />
            </MoovyButton>
          </Grid.Column>
        </Grid>
      )}
      <Table selectable={isSuperOperator}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="parkingServiceDetails.tab.pricing.table.header.startTime" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingServiceDetails.tab.pricing.table.header.endTime" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingServiceDetails.tab.pricing.table.header.periodicFee" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingServiceDetails.tab.pricing.table.header.price" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {pricing.data &&
            pricing.data.map((p) => (
              <Table.Row key={p.ref} onClick={() => onClickRow(p.ref)}>
                <Table.Cell>{formatDateAndTime(p.startTime)}</Table.Cell>
                <Table.Cell>{formatDateAndTime(p.endTime)}</Table.Cell>
                <Table.Cell>{formatPrice(p.periodicFee)}</Table.Cell>

                {p.description ? (
                  <Table.Cell onClick={(event) => event.stopPropagation()}>
                    <Popup
                      trigger={
                        <Button>
                          <FormattedMessage id="parkingServiceDetails.tab.pricing.table.button.show" />
                        </Button>
                      }
                      on="click"
                      content={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: p.description
                          }}
                        />
                      }
                      basic
                    />
                  </Table.Cell>
                ) : (
                  <Table.Cell></Table.Cell>
                )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
      {addTariffModalOpen && (
        <ModalAddTariff
          pricing={pricing.data}
          serviceRef={serviceRef}
          onAdded={() => {
            queryClient.invalidateQueries({ queryKey: [queryKey, serviceRef] })
            setAddTariffModalOpen(false)
          }}
          onClose={() => {
            setAddTariffModalOpen(false)
          }}
        />
      )}
    </>
  )
}

PricingTable.propTypes = {
  serviceRef: PropTypes.string.isRequired
}

export default PricingTable
