import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { reset, setAuthentication, setInfo, setToken } from '../redux/auth'
import keycloakService from '../services/keycloak'

// Initialize keycloak on mount, checks authentication and saves results to redux.
function useKeycloak() {
  const [keycloak, setKeycloak] = useState()
  const dispatch = useDispatch()

  useEffect(() => {
    keycloakService
      .initialize()
      .then((kc) => setKeycloak(kc))
      .catch((error) =>
        // eslint-disable-next-line no-console
        console.error('Error initializing keycloak client', error)
      )
  }, [])

  useEffect(() => {
    if (keycloak) {
      if (keycloak.authenticated) {
        dispatch(
          setAuthentication({
            token: keycloak.token,
            roles: keycloak.tokenParsed.realm_access.roles
          })
        )
        keycloak.loadUserInfo().then((info) => dispatch(setInfo(info)))

        // Set the token refresh
        setInterval(() => {
          keycloak
            .updateToken(360)
            .then(() => {
              const { token } = keycloak
              sessionStorage.setItem('authToken', token)
              dispatch(setToken(token))
            })
            .catch(() => {
              dispatch(reset())
              keycloak.logout()
            })
        }, 300000)
      } else {
        dispatch(setAuthentication({}))
      }
    }
  }, [keycloak])
  return keycloak
}

export default useKeycloak
