import { FormattedMessage, useIntl } from 'react-intl'

import { Form } from 'semantic-ui-react'
import { MoovyTextInput } from '../../components'
import React from 'react'

const SettlementRecipientsToolbar = ({
  minCharsForPartialName,
  settlementRecipientName,
  setSettlementRecipientName
}) => {
  const intl = useIntl()

  return (
    <div className="toolbar-filters">
      <Form>
        <Form.Field
          error={
            settlementRecipientName
              ? settlementRecipientName.trim().length < minCharsForPartialName
              : false
          }
        >
          <label>
            <FormattedMessage id="settlementRecipients.toolbar.label.settlementRecipientName" />
          </label>
          <MoovyTextInput
            value={settlementRecipientName}
            onChange={(ev) => setSettlementRecipientName(ev.target.value)}
            onClear={() => setSettlementRecipientName('')}
            placeholder={intl.formatMessage({
              id:
                'settlementRecipients.toolbar.filter.settlementRecipientName.placeholder'
            })}
          />
        </Form.Field>
      </Form>
    </div>
  )
}

export default SettlementRecipientsToolbar
