import { Formik } from 'formik'
import { validateBIC, validateIBAN } from 'ibantools'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button, Form, Message, Modal } from 'semantic-ui-react'
import * as yup from 'yup'

import { MutationErrorMessage } from '../../../../components'
import { isValidPhoneNumber, validateEmail } from '../../../../services/utils'
import {
  getStringValue,
  vismaRoleEnum
} from '../../../../services/utils/DTOEnums'

const ModalManageMandator = ({
  existingMandators,
  onCreateMandator,
  onUpdateMandator,
  open,
  edit,
  onClose,
  createMutation,
  updateMutation
}) => {
  const creationValidationSchema = () => {
    return yup.object().shape({
      role: yup.string().required('Cannot be empty'),
      bankAccountBic: yup
        .string()
        .test('bankAccountBic', 'Check if BIC is valid', function (bic) {
          return validateBIC(bic).valid
        }),
      bankAccountIban: yup
        .string()
        .test('bankAccountIban', 'Check if IBAN is valid', function (iban) {
          return validateIBAN(iban).valid
        }),
      customerSupportPhone: yup
        .string()
        .test(
          'InvalidPhoneNumber',
          'Phone number is not valid',
          function (phoneNumber) {
            return (
              !phoneNumber ||
              (!!phoneNumber && isValidPhoneNumber(phoneNumber.trim()))
            )
          }
        ),
      customerSupportEmail: yup
        .string()
        .test('validEmailAddress', 'Check if email is valid', function (email) {
          return !email || (!!email && validateEmail(email))
        })
    })
  }

  const editingValidationSchema = () => {
    return yup.object().shape({
      customerSupportPhone: yup
        .string()
        .test(
          'InvalidPhoneNumber',
          'Phone number is not valid',
          function (phoneNumber) {
            return (
              !phoneNumber ||
              (!!phoneNumber && isValidPhoneNumber(phoneNumber.trim()))
            )
          }
        ),
      customerSupportEmail: yup
        .string()
        .test('validEmailAddress', 'Check if email is valid', function (email) {
          return !email || (!!email && validateEmail(email))
        })
    })
  }

  const intl = useIntl()

  let roleOptions = []
  !existingMandators?.find((item) => item.role === vismaRoleEnum.INVOICING) &&
    roleOptions.push({
      key: vismaRoleEnum.INVOICING,
      text: intl.formatMessage({
        id: vismaRoleEnum.localizationKeys.INVOICING
      }),
      value: vismaRoleEnum.INVOICING
    })
  !existingMandators?.find(
    (item) => item.role === vismaRoleEnum.DEBT_COLLECTION
  ) &&
    roleOptions.push({
      key: vismaRoleEnum.DEBT_COLLECTION,
      text: intl.formatMessage({
        id: vismaRoleEnum.localizationKeys.DEBT_COLLECTION
      }),
      value: vismaRoleEnum.DEBT_COLLECTION
    })

  const RenderForm = ({
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    errors
  }) => {
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Select
          label={intl.formatMessage({
            id: 'modalManageMandator.form.vismaRole'
          })}
          placeholder={intl.formatMessage({
            id: 'modalManageMandator.form.vismaRole.placeholder'
          })}
          name="role"
          value={values.role}
          options={roleOptions}
          onChange={(e, value) => {
            setFieldValue(value.name, value.value)
          }}
          disabled={!!edit}
          error={!!errors.role}
          selectOnBlur={false}
        ></Form.Select>
        <Form.Group widths="equal">
          <Form.Input
            label={intl.formatMessage({
              id: 'modalManageMandator.form.bankAccountIban'
            })}
            name="bankAccountIban"
            value={values.bankAccountIban}
            onChange={handleChange}
            disabled={!!edit}
            error={!!errors.bankAccountIban}
          />
          <Form.Input
            label={intl.formatMessage({
              id: 'modalManageMandator.form.bankAccountBic'
            })}
            name="bankAccountBic"
            value={values.bankAccountBic}
            onChange={handleChange}
            disabled={!!edit}
            error={!!errors.bankAccountBic}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Input
            label={intl.formatMessage({
              id: 'modalManageMandator.form.customerSupportPhone'
            })}
            name="customerSupportPhone"
            value={values.customerSupportPhone}
            onChange={handleChange}
            error={!!errors.customerSupportPhone}
          />
          <Form.Input
            label={intl.formatMessage({
              id: 'modalManageMandator.form.customerSupportEmail'
            })}
            name="customerSupportEmail"
            value={values.customerSupportEmail}
            onChange={handleChange}
            error={!!errors.customerSupportEmail}
          />
        </Form.Group>
      </Form>
    )
  }

  const RenderNotAllowed = ({ mandators }) => {
    return (
      <Message info>
        {intl.formatMessage(
          { id: 'modalManageMandator.message.addNotAllowed' },
          {
            mandatorsCount: mandators.length,
            mandatorRoles: mandators
              .map((mandator) =>
                intl.formatMessage({
                  id: getStringValue(
                    vismaRoleEnum.localizationKeys,
                    mandator.role
                  ),
                  defaultMessage: mandator.role
                })
              )
              .join(', ')
          }
        )}
      </Message>
    )
  }

  const isNewMandatoryAllowed = (mandators) => {
    return !mandators || mandators.length < 2 || !!edit
  }

  return (
    <Formik
      initialValues={{
        bankAccountIban: edit?.bankAccountIban || '',
        bankAccountBic: edit?.bankAccountBic || '',
        role: edit?.role || '',
        customerSupportPhone: edit?.customerSupportPhone || '',
        customerSupportEmail: edit?.customerSupportEmail || ''
      }}
      onSubmit={(values, { resetForm }) => {
        if (!!edit) {
          let filledValues = {
            ref: edit?.ref,
            customerSupportPhone: values.customerSupportPhone,
            customerSupportEmail: values.customerSupportEmail
          }
          onUpdateMandator({
            ...filledValues,
            resetForm
          })
        } else {
          let filledValues = { ...values }
          if (!filledValues.customerSupportPhone)
            delete filledValues.customerSupportPhone
          if (!filledValues.customerSupportEmail)
            delete filledValues.customerSupportEmail
          onCreateMandator({
            ...filledValues,
            resetForm
          })
        }
      }}
      validationSchema={
        (edit && editingValidationSchema()) || creationValidationSchema()
      }
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        setFieldValue,
        resetForm
      }) => (
        <Modal open={open} onClose={onClose} size="small">
          <Modal.Header
            content={
              (edit && (
                <FormattedMessage id="modalManageMandator.header.update" />
              )) || <FormattedMessage id="modalManageMandator.header.create" />
            }
          />
          <Modal.Content>
            {(edit && (
              <MutationErrorMessage
                mutation={updateMutation}
                defaultErrorTextLangId={
                  'modalManageMandator.action.update.failed'
                }
                messageNegative
              />
            )) || (
              <MutationErrorMessage
                mutation={createMutation}
                defaultErrorTextLangId={
                  'modalManageMandator.action.create.failed'
                }
                messageNegative
              />
            )}
            {(isNewMandatoryAllowed(existingMandators) && (
              <RenderForm
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
              />
            )) || <RenderNotAllowed mandators={existingMandators} />}
          </Modal.Content>
          <Modal.Actions>
            {(isNewMandatoryAllowed(existingMandators) && (
              <>
                <Button
                  onClick={() => {
                    resetForm({})
                    createMutation.reset()
                    updateMutation.reset()
                    onClose()
                  }}
                >
                  <FormattedMessage id="common.button.cancel" />
                </Button>
                <Button
                  type="submit"
                  primary
                  onClick={handleSubmit}
                  loading={createMutation.isPending || updateMutation.isPending}
                  disabled={
                    createMutation.isPending || updateMutation.isPending
                  }
                >
                  {(edit && (
                    <FormattedMessage id="modalManageMandator.button.updateMandator" />
                  )) || (
                    <FormattedMessage id="modalManageMandator.button.addMandator" />
                  )}
                </Button>
              </>
            )) || (
              <Button
                onClick={() => {
                  resetForm({})
                  createMutation.reset()
                  updateMutation.reset()
                  onClose()
                }}
              >
                <FormattedMessage id="common.button.OK" />
              </Button>
            )}
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalManageMandator
