import React, { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'

import { convertMinutes } from '../../../services/utils'

const FormMinutes = ({
  label,
  labelMinutes,
  name,
  minutes,
  handleChange,
  handleBlur,
  errorMinutes,
  setFieldValue
}) => {
  const HOUR_IN_MINUTES = 60
  const DAY_IN_MINUTES = 24 * HOUR_IN_MINUTES

  const buttonClicked = (event, currentMinutes, addMinutes) => {
    event.preventDefault()

    let value = 0
    if (!isNaN(currentMinutes) && Number(currentMinutes) + addMinutes > 0) {
      value = Number(currentMinutes) + addMinutes
    }

    setFieldValue(name, value)
  }

  return (
    <>
      <Form.Field inline control="label">
        {`${label} ${convertMinutes(minutes)}`}
      </Form.Field>
      <Form.Group>
        <Form.Button
          circular
          label="1 päivä"
          onClick={(event) => buttonClicked(event, minutes, -DAY_IN_MINUTES)}
        >
          -
        </Form.Button>
        <Form.Button
          circular
          label="1 tunti"
          onClick={(event) => buttonClicked(event, minutes, -HOUR_IN_MINUTES)}
        >
          -
        </Form.Button>
        <Form.Input
          type="number"
          min="0"
          label={labelMinutes}
          value={minutes}
          onChange={handleChange}
          onBlur={handleBlur}
          name={name}
          error={errorMinutes}
        />
        <Form.Button
          circular
          label="1 tunti"
          onClick={(event) => buttonClicked(event, minutes, HOUR_IN_MINUTES)}
        >
          +
        </Form.Button>
        <Form.Button
          circular
          label="1 päivä"
          onClick={(event) => buttonClicked(event, minutes, DAY_IN_MINUTES)}
        >
          +
        </Form.Button>
      </Form.Group>{' '}
    </>
  )
}

export default FormMinutes
