import * as yup from 'yup'

import { Form, Header, Item, TextArea } from 'semantic-ui-react'
import {
  MoovyHtmlPreview,
  MoovyIconInfo,
  MoovyLink,
  TariffTestPricing,
  TariffTimeRangeForm
} from '../../../components'
import React, { useEffect, useState } from 'react'
import {
  addMissingValues,
  findChangedValues,
  isEmptyObject,
  isObject,
  valuesFromFormValues,
  valuesToFormValues
} from './ManagementUtils'

import { FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import StepNextButton from './StepNextButton'
import { ZeroTariff } from './ZeroTariff'
import pricingService from '../../../services/Pricing'
import { transform } from '../../../services/utils/JSONTransformer'
import { useMutation } from '@tanstack/react-query'

export const ServicePricingForm = ({ formValues, onDataCompleted }) => {
  const validationSchema = () => {
    return yup.object().shape({
      servicepricingtariffStructure: yup.string().required('Cannot be empty')
    })
  }

  const numberFields = [
    'servicePricing.dailyFee',
    'servicePricing.periodicFee',
    'servicePricing.subscriptionFee'
  ]

  const [parkingRanges, setParkingRanges] = useState([])
  const [initialValues, setInitialValues] = useState({
    servicepricingdailyFee: 0,
    servicepricingperiodicFee: 0,
    servicepricingdescription: '',
    servicepricingtariffStructure: ''
  })
  const [values, setValues] = useState(initialValues)
  const [jsonError, setJsonError] = useState(false)

  useEffect(() => {
    setJsonError(false)
    if (isEmptyObject(formValues)) {
      setValues(initialValues)
    } else {
      let currentValues = addMissingValues(
        valuesFromFormValues(formValues, 'servicepricing'),
        initialValues
      )

      if (isObject(currentValues.servicepricingtariffStructure)) {
        try {
          currentValues.servicepricingtariffStructure = JSON.stringify(
            transform(
              currentValues.servicepricingtariffStructure,
              numberFields,
              false
            ),
            null,
            2
          )
        } catch (error) {
          setJsonError(true)
        }
      }

      const changedValues = findChangedValues(currentValues, values)
      if (changedValues) {
        setValues({ ...currentValues })
        setInitialValues({ ...currentValues })
      }
    }
  }, [formValues])

  const validateJsonData = (name, value) => {
    if (name === 'servicepricingtariffStructure') {
      try {
        setJsonError(false)
        JSON.parse(value)
        return true
      } catch (error) {
        setJsonError(true)
        return false
      }
    }
  }

  const { mutate: testTariffPricing } = useMutation({
    mutationFn: (tariff) =>
      pricingService.testTariffPricing(parkingRanges, tariff),
    onSuccess: () => {
      setJsonError(false)
      // If the tariff is valid, the form can be finished.
      onDataCompleted(
        { ...valuesToFormValues(values, 'servicepricing') },
        'tariff'
      )
    },
    onError: () => {
      setJsonError(true)
    }
  })

  const onChange = (e, data) => {
    const { name, value } = data
    setValues({ ...values, [name]: value })
    validateJsonData(name, value)
  }

  const onSubmitForm = () => {
    // Validate tariff JSON before finishing the form.
    if (
      validateJsonData(
        'servicepricingtariffStructure',
        values.servicepricingtariffStructure
      )
    ) {
      testTariffPricing(JSON.parse(values.servicepricingtariffStructure))
    }
  }

  const fillFormByZeroTariff = (setFieldValue) => {
    const tariffAsString = JSON.stringify(
      transform(ZeroTariff.tariffStructure, numberFields, false),
      null,
      2
    )
    setValues({
      servicepricingdailyFee: ZeroTariff.dailyFee,
      servicepricingperiodicFee: ZeroTariff.periodicFee,
      servicepricingdescription: ZeroTariff.description,
      servicepricingtariffStructure: tariffAsString
    })

    setInitialValues({
      servicepricingdailyFee: ZeroTariff.dailyFee,
      servicepricingperiodicFee: ZeroTariff.periodicFee,
      servicepricingdescription: ZeroTariff.description,
      servicepricingtariffStructure: tariffAsString
    })
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={(values, actions) => {
          onSubmitForm(values, actions)
        }}
        enableReinitialize={true}
      >
        {({ errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <StepNextButton />
            <Header>
              Hinnoittelu
              <MoovyLink
                bold
                onClick={() => fillFormByZeroTariff(setFieldValue)}
              >
                Täytä hinnoittelu nollatariffilla
              </MoovyLink>
            </Header>
            <Form.Group widths="equal">
              <Form.Input
                label="Tilaustuotteen kuukausimaksu (€/kk)"
                name="servicepricingperiodicFee"
                type="number"
                value={values.servicepricingperiodicFee}
                onChange={onChange}
              />
              <Form.Field />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>
                  <MoovyIconInfo iconLabel="Kuvaus HTML-muodossa">
                    <FormattedMessage id="modalAddTariff.form.label.description.info" />
                  </MoovyIconInfo>
                </label>
                <TextArea
                  placeholder="Kuvaus (HTML)"
                  name="servicepricingdescription"
                  value={values.servicepricingdescription}
                  onChange={onChange}
                  rows={25}
                />
              </Form.Field>
              <Form.Field>
                <label>Kuvaus HTML-muodossa, esikatselu</label>
                <Item>
                  <Item.Content>
                    <MoovyHtmlPreview html={values.servicepricingdescription} />
                  </Item.Content>
                </Item>
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                error={!!(errors.servicepricingtariffStructure || jsonError)}
              >
                <label>Tariffin rakenne</label>
                <TextArea
                  placeholder="Tariffin rakenne (JSON)"
                  name="servicepricingtariffStructure"
                  value={values.servicepricingtariffStructure}
                  onChange={(event, data) => {
                    onChange(event, data)
                    handleChange(event, data)
                  }}
                  rows={40}
                />
              </Form.Field>
              <Form.Field>
                <label>Testaa tariffin rakenne</label>
                <TariffTimeRangeForm
                  key="tariffTimeRangeForm"
                  onChangeParkingRange={(ranges) => setParkingRanges(ranges)}
                />
                <label>Lasketut hinnat yllä määritetyistä ajoista</label>
                <TariffTestPricing
                  key="tariffTestPricing"
                  parkingRanges={parkingRanges}
                  tariffStructure={values.servicepricingtariffStructure}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ServicePricingForm
