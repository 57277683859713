import PropTypes from 'prop-types'
import React from 'react'
import { Grid, Header, Segment } from 'semantic-ui-react'

import ServiceAgreementTable from '../ServiceAgreementTable'
import VoucherGrantAgreementTable from './VoucherGrantAgreementTable'

const UserGroupAgreements = ({ serviceAgreements, voucherGrantAgreements }) => (
  <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Segment basic textAlign="left" loading={serviceAgreements.isLoading}>
          <Header color="blue">Tilattavat tuotteet</Header>
          <ServiceAgreementTable serviceAgreements={serviceAgreements.data} />
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <Segment basic loading={voucherGrantAgreements.isLoading}>
          <Header color="green">Jaettavat pysäköintiedut</Header>
          <VoucherGrantAgreementTable
            voucherGrantAgreements={voucherGrantAgreements.data}
          />
        </Segment>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

UserGroupAgreements.propTypes = {
  serviceAgreements: PropTypes.shape({
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool
  }),
  voucherGrantAgreements: PropTypes.shape({
    data: PropTypes.array.isRequired,
    isLoading: PropTypes.bool
  })
}
export default UserGroupAgreements
