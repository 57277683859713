import useGlobalUI from './useGlobalUI'

export default function useMultiSelect(options) {
  const { key, initialValue } = options
  const [state, setState] = useGlobalUI({
    key: `multiSelect_${key}`,
    initialValue: initialValue ?? []
  })

  // Use this if saving primitive values
  const onMultiSelect = (values) => {
    const newRefs = includes.map((value) => value.ref)
    const includes = values.every((value) => state.includes(value))
    const updatedArray = includes
      ? state.filter((item) => !newRefs.includes(item.ref))
      : (newRefs.length === 0 && []) || state.concat(values)
    setState(updatedArray)
  }

  // Use this when saving objects. Objects must have an ref attribute.
  const onMultiSelectObjects = (values) => {
    const newRefs = values.map((value) => value.ref)
    const existingRefs = state.map((value) => value.ref)
    const includes = newRefs.some((ref) => existingRefs.includes(ref))
    const updatedArray = includes
      ? state.filter((item) => !newRefs.includes(item.ref))
      : (newRefs.length === 0 && []) || state.concat(values)
    setState(updatedArray)
  }

  return [state, onMultiSelectObjects, onMultiSelect]
}
