import React from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

import Toolbar from '../../components/Toolbar'
import useAuthInfo from '../../hooks/useAuthInfo'
import useGlobalUI from '../../hooks/useGlobalUI'
import Routing from '../../routing/Routing'
import serviceService from '../../services/Services'
import { serviceType } from '../../services/utils/DTOEnums'
import {
  getDataForPage,
  getTablePaginationInitialValues,
  getTotalPages,
  getUpdatedPaginationSort
} from '../../services/utils/tablePagination'
import { getFilteredSubscriptionServiceData } from './ParkingServiceFilterData'
import ParkingServiceTable from './ParkingServiceTable'
import ParkingServiceToolbar from './ParkingServiceToolbar'

const key = 'Services'
const initialState = {
  searchInputValue: '',
  operatorRef: '',
  type: serviceType.SUBSCRIPTION,
  ...getTablePaginationInitialValues('sortByName')
}

export default function ParkingService() {
  const pageLimit = 20

  const intl = useIntl()
  const navigate = useNavigate()
  const { isSuperOperator, operatorRealm } = useAuthInfo()

  const [state, setState] = useGlobalUI({
    key,
    initialValue: initialState
  })

  const { type } = state
  const serviceQuery = useQuery({
    queryKey: ['serviceQuery', type],
    queryFn: () => serviceService.fetchServicesByType(operatorRealm, type),
    enabled: !!type,
    staleTime: 1000 * 60 * 5
  })

  const setFilterValues = (values) => {
    setState({ ...state, ...values, activePage: 1 })
  }

  const onHandleSort = (column) => () => {
    const { sortColumn, direction } = state
    const updatedPagination = getUpdatedPaginationSort(column, {
      sortColumn,
      direction
    })
    setState({
      ...state,
      ...updatedPagination
    })
  }

  const onPageChange = (e, { activePage }) => {
    setState({
      ...state,
      activePage
    })
  }

  const onSelectedSubscribedService = (subscribedService) => {
    navigate(Routing.getServiceUrl(subscribedService.ref, isSuperOperator))
  }

  const services = serviceQuery.data ? serviceQuery.data : []

  const { activePage, sortColumn, direction } = state
  const filteredAndSorted = getFilteredSubscriptionServiceData(
    services,
    state,
    {
      sortColumn,
      direction
    }
  )
  const totalPages = getTotalPages(filteredAndSorted.length, pageLimit)
  const rows = getDataForPage(filteredAndSorted, activePage, pageLimit)

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'parkingService.toolbar.title'
        })}
        content={
          <ParkingServiceToolbar
            isSuperOperator={isSuperOperator}
            values={state}
            setValues={setFilterValues}
          />
        }
      />
      <div className="Admin--Page--Content">
        <ParkingServiceTable
          parkingServices={rows}
          isSuperOperator={isSuperOperator}
          onSelectedRow={onSelectedSubscribedService}
          onHandleSort={onHandleSort}
          onPageChange={onPageChange}
          pagination={{
            activePage,
            totalPages,
            sortColumn,
            direction
          }}
        />
      </div>
    </>
  )
}
