import './GeoJSONPreview.scss'

import L from 'leaflet'
import { get, uniqueId } from 'lodash'
import React, { useEffect, useState } from 'react'
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet'

const GeoJSONPreview = ({ geoJson, onError }) => {
  const tampereCenterCoordinates = [61.49446887, 23.76134239]

  const [geoJsonData, setGeoJsonData] = useState('')
  const [center, setCenter] = useState(tampereCenterCoordinates)
  const zoomLevel = 15

  const getUniqueKey = () => uniqueId('geojson')

  useEffect(() => {
    if (!geoJson) {
      setGeoJsonData('')
      return
    }
    try {
      const parsed = JSON.parse(geoJson)
      const firstCoordinates = get(
        parsed,
        'features[0].geometry.coordinates[0][0]'
      )
      setCenter(
        (firstCoordinates && firstCoordinates) || tampereCenterCoordinates
      )

      // This is used to verify that parsed JSON is valid Geo JSON
      L.geoJSON(parsed)
      // Set data on UI
      setGeoJsonData(parsed)
    } catch (err) {
      onError(true)
    }
  }, [geoJson])

  return (
    <>
      <div id="mapid">
        {geoJsonData && (
          <MapContainer center={[center[1], center[0]]} zoom={zoomLevel}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <GeoJSON data={geoJsonData} key={getUniqueKey()} />
          </MapContainer>
        )}
      </div>
    </>
  )
}

export default GeoJSONPreview
