import { formatDateAndTime, formatSeconds } from '../../services/utils'

import { FormattedMessage } from 'react-intl'
import { ParkingTypeLabel } from '../../components'
import React from 'react'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import { Table } from 'semantic-ui-react'

/* Lists the parkingSequence in a table. Has a lot of similarities with the ParkingSequence component that ParkingModal uses,
  but also some custom implementation (links between single parkings of a sequence).
*/

const LinkToParking = ({ parkingRef, children }) => (
  <RelativeLinkForCurrentUser to={`parking/${parkingRef}`}>
    {children}
  </RelativeLinkForCurrentUser>
)

const ParkingSequence = ({ parkingSequence, activeRef }) => {
  return (
    <Table attached stackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.location" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.started" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.ended" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.duration" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.type" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="parkingSequence.table.header.parkingProduct" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {parkingSequence?.parkings?.map((row) => {
          const {
            ref,
            location,
            startTime,
            endTime,
            durationSeconds,
            type,
            subscribedService
          } = row
          return (
            <Table.Row key={ref} positive={activeRef === ref}>
              <Table.Cell>
                <RelativeLinkForCurrentUser to={`/locations/${location.ref}`}>
                  {location.name ?? location.ref}
                </RelativeLinkForCurrentUser>
              </Table.Cell>
              <Table.Cell>{formatDateAndTime(startTime)}</Table.Cell>
              <Table.Cell>{formatDateAndTime(endTime)}</Table.Cell>
              <Table.Cell>{formatSeconds(durationSeconds)}</Table.Cell>
              <Table.Cell>
                {activeRef !== ref ? (
                  <LinkToParking parkingRef={ref}>
                    <ParkingTypeLabel type={type} />
                  </LinkToParking>
                ) : (
                  <ParkingTypeLabel type={type} />
                )}
              </Table.Cell>
              <Table.Cell>
                {subscribedService ? (
                  <FormattedMessage
                    id="parkingSequence.label.subscription"
                    values={{
                      name: subscribedService.name
                    }}
                  />
                ) : (
                  <FormattedMessage id="parkingSequence.label.payg" />
                )}
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>
            {formatDateAndTime(parkingSequence.startTime)}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {parkingSequence.endTime ? (
              formatDateAndTime(parkingSequence.endTime)
            ) : (
              <FormattedMessage id="parkingSequence.label.ongoing" />
            )}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {formatSeconds(parkingSequence.durationSeconds)}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="2" />
        </Table.Row>
      </Table.Footer>
    </Table>
  )
}

export default ParkingSequence
