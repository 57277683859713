import './Toolbar.scss'

import {
  Breadcrumb,
  Button,
  Grid,
  Header,
  Icon,
  Popup
} from 'semantic-ui-react'

import PropTypes from 'prop-types'
import React from 'react'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import SubMenu from './components/SubMenu'

const Toolbar = ({
  title,
  breadcrumbs = [
    { key: 0, href: '/', content: 'Koti' },
    { key: 1, content: title, active: true }
  ],
  menus = [],
  buttons = [],
  content = ''
}) => {
  const maybeLinks = breadcrumbs.slice(0, -1)
  // Assume that the last breadCrumb is always the active one and should not be a link.
  const activeCrumb = breadcrumbs.slice(-1)[0]

  return (
    <div className="Toolbar">
      {breadcrumbs && (
        <Breadcrumb id="toolbar-navigation">
          {maybeLinks.map((crumb) => (
            <React.Fragment key={crumb.key}>
              <Breadcrumb.Section>
                {crumb.href ? (
                  <RelativeLinkForCurrentUser to={crumb.href}>
                    {crumb.content}
                  </RelativeLinkForCurrentUser>
                ) : (
                  <span>{crumb.content}</span>
                )}
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
            </React.Fragment>
          ))}
          <Breadcrumb.Section active>{activeCrumb.content}</Breadcrumb.Section>
        </Breadcrumb>
      )}
      <Grid>
        <Grid.Row
          columns={2}
          style={{ minHeight: '64px', marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid.Column verticalAlign="middle">
            <Header>{title}</Header>
          </Grid.Column>
          <Grid.Column>
            {buttons &&
              buttons.map((b, index) => (
                <Popup
                  key={index}
                  trigger={
                    <Button
                      style={{ marginRight: '5px' }}
                      floated="right"
                      icon
                      basic
                      onClick={b.action}
                      className="Toolbar--Button"
                    >
                      {b.color ? (
                        <Icon name={b.icon} color={b.color} />
                      ) : (
                        <Icon name={b.icon} />
                      )}
                    </Button>
                  }
                  content={b.tooltip}
                  position="bottom right"
                />
              ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <div className="Toolbar--Content">
        <div className="Toolbar--MainContent">{content}</div>
        <div className="Toolbar--SubMenus">
          {!menus
            ? null
            : menus.map((menu) => <SubMenu key={menu.title} {...menu} />)}
        </div>
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node,
  breadcrumbs: PropTypes.array,
  buttons: PropTypes.array
}

export default Toolbar
