import './Finances.scss'

import React, { useState } from 'react'

import { DateTime } from 'luxon'
import FinancesFilterYearMonth from './FinancesFilterYearMonth'
import FinancesPane from './FinancesPane'
import financesService from '../../services/Finances'
import { useIntl } from 'react-intl'

const ReportAdyenMoovyCreditLossByMonth = () => {
  const intl = useIntl()

  const [yearAndMonth, setYearAndMonth] = useState({
    year: DateTime.local().year.toString(),
    month: DateTime.local().month.toString()
  })


  return (
    <FinancesPane
      text={intl.formatMessage({
        id: 'finances.tab.reportAdyenMoovyCreditLossByMonth.header'
      })}
      buttonCallback={() =>
        financesService.downloadAdyenMoovyCreditLossByMonthReport({
          ...yearAndMonth
        })
      }
    >
      <FinancesFilterYearMonth
        yearAndMonth={yearAndMonth}
        setYearAndMonth={setYearAndMonth}
      />
    </FinancesPane>
  )
}

export default ReportAdyenMoovyCreditLossByMonth
