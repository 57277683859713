import { get } from '../utils/api-renewed'

export const fetchAllProducts = async (operatorRealm) => {
  try {
    const path = operatorRealm
      ? `/operator/${operatorRealm}/product`
      : '/administration/product'
    const result = await get(path)
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

export const fetchWebPaymentProducts = (
  operatorRealm,
  settlementRecipientRef
) => {
  const path = operatorRealm
    ? `/operator/${operatorRealm}/product/webPayment`
    : '/administration/product/webPayment'
  const result = get(path, {
    ref: settlementRecipientRef
  })
  return result
}
