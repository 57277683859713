import './EnterpriseCustomer.scss'

import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Header, Icon, List } from 'semantic-ui-react'
import React, { useState } from 'react'

import InvoicingToolbar from './InvoicingToolbar/InvoicingToolbar'
import ModalEditEnterpriseUser from './ModalEditEnterpriseUser'
import { PermissionEnterpriseUser } from '../../../services/utils'
import Toolbar from '../../../components/Toolbar'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useNavigate } from 'react-router-dom'

const EnterpriseCustomerToolbar = ({ customer }) => {
  const [openEnterpriseUserModal, setOpenEnterpriseUserModal] = useState(false)
  const [showEditInvoicingModal, setShowEditInvoicing] = useState(false)

  const { isSuperOperator, roles } = useAuthInfo()
  const intl = useIntl()
  const navigate = useNavigate()

  const canEditInvoicingInfo =
    customer &&
    PermissionEnterpriseUser.HasEditInvoiceInfo(isSuperOperator, roles)

  const canRemoveUser =
    customer &&
    PermissionEnterpriseUser.HasAdministration(isSuperOperator, roles)

  const {
    firstName,
    lastName,
    email,
    phone,
    streetAddress,
    city,
    postalCode,
    businessCode,
    company,
    keycloakUsername,
    keycloakEmail
  } = customer.userDetails

  const breadcrumbs = createBreadcrumbs([
    { text: intl.formatMessage({ id: 'common.breadcrumbs.home' }), href: '/' },
    { text: company, active: true }
  ])

  let toolbarButtons = []

  if (canRemoveUser) {
    toolbarButtons.push({
      icon: 'setting',
      tooltip: intl.formatMessage({
        id: 'enterpriseCustomer.toolbar.button.editUser'
      }),
      action: () => setOpenEnterpriseUserModal(true)
    })
  }
  if (canEditInvoicingInfo) {
    toolbarButtons.push({
      icon: 'edit',
      tooltip: intl.formatMessage({
        id: 'enterpriseCustomer.toolbar.button.editInvoicing'
      }),
      action: () => setShowEditInvoicing(true)
    })
  }

  return (
    <Toolbar
      title={intl.formatMessage({ id: 'enterpriseCustomer.toolbar.title' })}
      breadcrumbs={breadcrumbs}
      buttons={toolbarButtons}
      content={
        <>
          <Grid>
            <Grid.Column floated="left" width={5}>
              <Grid columns="equal">
                <Grid.Column>
                  <Header as="h2" className="companyHeader">
                    {company}
                  </Header>
                  <Header as="h2" className="companyHeader">
                    {businessCode}
                  </Header>
                  {!customer.active && (
                    <Header color="red" size="tiny">
                      <Icon name="x" />
                      <FormattedMessage id="enterpriseCustomer.toolbar.inactive" />
                    </Header>
                  )}
                  <p>
                    {streetAddress}, {postalCode} {city}
                  </p>
                  {isSuperOperator && (
                    <>
                      <p
                        style={{ marginBottom: 0 }}
                        title={intl.formatMessage({
                          id: 'enterpriseCustomer.toolbar.keycloak.username'
                        })}
                      >
                        <Icon name="user" /> {keycloakUsername}
                      </p>
                      <p
                        title={intl.formatMessage({
                          id: 'enterpriseCustomer.toolbar.keycloak.email'
                        })}
                      >
                        <Icon name="mail" /> {keycloakEmail}
                      </p>
                    </>
                  )}
                </Grid.Column>
                <Grid.Column />
              </Grid>
            </Grid.Column>
            <Grid.Column width={11}>
              <Grid>
                <Grid.Column width="5" floated="right" className="menuColumn">
                  <Header as="h4">
                    <FormattedMessage id="enterpriseCustomer.toolbar.contactPerson" />
                  </Header>
                  <List relaxed verticalAlign="bottom">
                    <List.Item>
                      {firstName} {lastName}
                    </List.Item>
                    <List.Item>
                      <List.Icon name="at" />
                      {email}
                    </List.Item>
                    <List.Item>
                      <List.Icon name="phone" />
                      {phone}
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width="5" className="menuColumn">
                  <InvoicingToolbar
                    customer={customer}
                    showEditInvoicingModal={showEditInvoicingModal}
                    setShowEditInvoicing={setShowEditInvoicing}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
          {openEnterpriseUserModal ? (
            <ModalEditEnterpriseUser
              onClose={() => {
                setOpenEnterpriseUserModal(false)
              }}
              user={customer}
              onRefreshUser={() => {
                setOpenEnterpriseUserModal(false)
                navigate(isSuperOperator ? `/admin` : `/operator`)
              }}
            />
          ) : (
            ''
          )}
        </>
      }
    />
  )
}

export default EnterpriseCustomerToolbar
