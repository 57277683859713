import './HeaderComponent.scss'

import { Dropdown, Header, Icon, Image } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  PermissionLocation,
  PermissionWebPayment,
  PermissionLicensePlatePotentiallyIncorrect,
  ROLE_FINANCES,
  ROLE_MANAGEMENT
} from '../../services/utils'

import HeaderSearch from './HeaderSearch'
import Logo from './moovy-logo-nega_310517-10.png'
import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

const createOptions = (options) =>
  options.map((opt) => ({
    key: opt.text,
    text: opt.text,
    value: opt.text,
    as: Link,
    to: opt.path
  }))

const HeaderComponent = ({
  userName,
  isSuperOperator,
  isAuthenticated,
  roles
}) => {
  const location = useLocation()
  const intl = useIntl()
  const navigate = useNavigate()
  const getActiveClass = (paths) => {
    const pathArray = Array.isArray(paths) ? paths : [paths]

    return classNames({
      active: pathArray.some((p) => location.pathname.includes(p))
    })
  }

  const MenuLocations = () => {
    // Create Dropdown grouping for locations if user has rights for multiple pages
    if (PermissionLicensePlatePotentiallyIncorrect.HasView(roles)) {
      const options = createOptions([
        {
          text: intl.formatMessage({
            id: 'menu.locations.locations'
          }),
          path: isSuperOperator ? '/admin/locations' : '/operator/locations'
        },
        {
          text: intl.formatMessage({
            id: 'menu.locations.potentiallyIncorrect'
          }),
          path: isSuperOperator
            ? '/admin/potentiallyIncorrectLicensePlates'
            : '/operator/potentiallyIncorrectLicensePlates'
        }
      ])
      return (
        <Dropdown
          text={intl.formatMessage({
            id: 'menu.locations'
          })}
          options={options}
          className={getActiveClass(options.map((opt) => opt.to))}
        />
      )
    }

    return (
      <Link
        to={isSuperOperator ? '/admin/locations' : '/operator/locations'}
        className={getActiveClass(['admin/locations', '/operator/locations'])}
      >
        <FormattedMessage id="menu.locations" />
      </Link>
    )
  }

  const MenuCustomers = () => {
    const customerOptions = createOptions([
      {
        text: intl.formatMessage({
          id: 'menu.customers.userGroups'
        }),
        path: '/operator/usergroups'
      }
    ])

    const superOperatorCustomerOptions = createOptions([
      {
        text: intl.formatMessage({
          id: 'menu.customers.userGroups'
        }),
        path: '/admin/usergroups'
      }
    ])

    return (
      <Dropdown
        text={intl.formatMessage({
          id: 'menu.customers'
        })}
        options={
          isSuperOperator ? superOperatorCustomerOptions : customerOptions
        }
        className={
          isSuperOperator
            ? getActiveClass(superOperatorCustomerOptions.map((opt) => opt.to))
            : getActiveClass(customerOptions.map((opt) => opt.to))
        }
      />
    )
  }

  const MenuServices = () => {
    return (
      <Link
        to={isSuperOperator ? '/admin/services' : '/operator/services'}
        className={getActiveClass(['/admin/services', '/operator/services'])}
      >
        <FormattedMessage id="menu.services" />
      </Link>
    )
  }

  const MenuVouchers = () => {
    const voucherOptions = createOptions([
      {
        text: intl.formatMessage({
          id: 'menu.vouchers.voucherTemplates'
        }),
        path: isSuperOperator
          ? '/admin/voucherTemplates'
          : '/operator/voucherTemplates'
      },
      {
        text: intl.formatMessage({
          id: 'menu.vouchers.grantedVouchers'
        }),
        path: isSuperOperator
          ? '/admin/grantedVouchers'
          : '/operator/grantedVouchers'
      }
    ])

    return (
      <Dropdown
        text={intl.formatMessage({
          id: 'menu.vouchers'
        })}
        options={voucherOptions}
        className={getActiveClass(voucherOptions.map((opt) => opt.to))}
      />
    )
  }

  const MenuFinance = () => {
    let financeItems = []

    if (PermissionWebPayment.HasWebPaymentEdit(roles)) {
      financeItems.push({
        text: intl.formatMessage({
          id: 'menu.finance.webPayment'
        }),
        path: isSuperOperator ? '/admin/sales' : '/operator/sales'
      })
    }
    if (roles.includes(ROLE_FINANCES)) {
      if (isSuperOperator) {
        financeItems.push({
          text: intl.formatMessage({
            id: 'menu.finance.invoices'
          }),
          path: isSuperOperator ? '/admin/invoices' : ''
        })
        financeItems.push({
          text: intl.formatMessage({
            id: 'menu.finance.reports'
          }),
          path: isSuperOperator ? '/admin/finances' : ''
        })
        financeItems.push({
          text: intl.formatMessage({
            id: 'menu.finance.settlementRecipients'
          }),
          path: isSuperOperator ? '/admin/settlementRecipients' : ''
        })
        financeItems.push({
          text: intl.formatMessage({
            id: 'menu.finance.moneyTransfer'
          }),
          path: isSuperOperator ? '/admin/moneyTransfers' : ''
        })
        financeItems.push({
          text: intl.formatMessage({
            id: 'menu.finance.creditCoverCreditLoss'
          }),
          path: isSuperOperator ? '/admin/creditCoverCreditLoss' : ''
        })
        financeItems.push({
          text: intl.formatMessage({
            id: 'menu.finance.blocklistCandidates'
          }),
          path: isSuperOperator ? '/admin/blocklistCandidates' : ''
        })
      }
    }

    if (financeItems.length > 0) {
      const financeOptions = createOptions(financeItems)

      return (
        <Dropdown
          text={intl.formatMessage({
            id: 'menu.finance'
          })}
          options={financeOptions}
          className={getActiveClass(financeOptions.map((opt) => opt.to))}
        />
      )
    }
    return null
  }

  const MenuMessageCenter = () => {
    return (
      <Link
        to={
          isSuperOperator ? '/admin/messageCenter' : '/operator/messageCenter'
        }
        className={getActiveClass([
          'admin/messageCenter',
          '/operator/messageCenter'
        ])}
      >
        <FormattedMessage id="menu.messageCenter" />
      </Link>
    )
  }

  const MenuManagement = () => {
    let managementItems = []

    if (PermissionLocation.HasCreateData(isSuperOperator, roles)) {
      managementItems.push({
        text: intl.formatMessage({
          id: 'menu.management.newLocation'
        }),
        path: isSuperOperator ? '/admin/management/locationService/new' : ''
      })
    }

    if (roles.includes(ROLE_MANAGEMENT) && isSuperOperator) {
      managementItems.push({
        text: intl.formatMessage({
          id: 'menu.management.newService'
        }),
        path: isSuperOperator ? '/admin/management/service/new' : ''
      })
    }

    if (managementItems.length <= 0) {
      return ''
    }

    const managementOptions = createOptions(managementItems)

    return (
      <Dropdown
        text={intl.formatMessage({
          id: 'menu.management'
        })}
        options={managementOptions}
        className={getActiveClass(managementOptions.map((opt) => opt.to))}
      />
    )
  }

  const MenuCharging = () => {
    return (
      <Link
        to={isSuperOperator ? '/admin/chargings' : '/operator/chargings'}
        className={getActiveClass(['/admin/chargings', '/operator/chargings'])}
      >
        <FormattedMessage id="menu.charging" />
      </Link>
    )
  }

  return (
    <div className="Header">
      <div className="Header--Nav">
        <div className="Header--Nav--Logo">
          <Link to="/">
            <Image src={Logo} verticalAlign="middle" />
          </Link>
          {isAuthenticated && (
            <p>
              {!isSuperOperator ? (
                <FormattedMessage id="menu.header.operator" />
              ) : (
                <FormattedMessage id="menu.header.superoperator" />
              )}
            </p>
          )}
        </div>
        {isAuthenticated && (
          <>
            <HeaderSearch />
            <div className="Header--Nav--Items">
              <MenuLocations />
              <MenuCustomers />
              <MenuServices />
              <MenuVouchers />
              {isSuperOperator && <MenuCharging />}
              <MenuFinance />
              {isSuperOperator && <MenuMessageCenter />}
              {isSuperOperator && <MenuManagement />}
              {process.env.REACT_APP_ENV !== 'production' && (
                <Header as="span" style={{ color: 'red', marginLeft: '2em' }}>
                  Ympäristö: {process.env.REACT_APP_ENV}
                </Header>
              )}
            </div>
          </>
        )}
      </div>
      {isAuthenticated && (
        <div
          className="Header--Nav--Logout"
          onClick={() => navigate('/logout')}
        >
          <Icon name="log out" />
          {userName}
        </div>
      )}
    </div>
  )
}

HeaderComponent.propTypes = {
  userName: PropTypes.string,
  isSuperOperator: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  roles: PropTypes.array,
  location: PropTypes.object
}

export default HeaderComponent
