import { Button, Label, Segment } from 'semantic-ui-react'
import { ModalInvoice, ModalWebPaymentSaleStatus } from '../../components'
import React, { useEffect, useState } from 'react'
import { sortColumn, tablePaginationDirections } from '../../services/utils'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import { FormattedMessage } from 'react-intl'
import ModalCancelWebpayments from './ModalCancelWebpayments'
import WebpaymentSalesTable from './WebpaymentSalesTable'
import WebpaymentToolbar from './WebpaymentToolbar'
import invoiceService from '../../services/Invoicing'
import moovyDateTime from '../../services/utils/moovyDateTime'
import salesService from '../../services/Sales'
import useAuthInfo from '../../hooks/useAuthInfo'
import useGlobalUI from '../../hooks/useGlobalUI'
import { useLocation } from 'react-router-dom'
import useMultiSelect from '../../hooks/useMultiSelect'

export default function Sales() {
  const [invoiceRef, setInvoiceRef] = useState(null)
  const [selectedWebPaymentSale, setSelectedWebPaymentSale] = useState(null)
  const [showCancelDialog, setShowCancelDialog] = useState(false)

  const { isSuperOperator, operatorRealm } = useAuthInfo()
  const queryClient = useQueryClient()

  const location = useLocation()

  const [state, setState] = useGlobalUI({
    key: 'Sales',
    initialValue: {
      licensePlateNumber: '',
      invoiceNumber: '',
      webPaymentStatus: '',
      createdFromTime: moovyDateTime.dateNowToCalendarInput(),
      createdUntilTime: moovyDateTime.dateNowToCalendarInput(),
      page: 0,
      limit: 20,
      sort: 'sortByCreationTime',
      direction: tablePaginationDirections.DESC,
      productRef: '',
      settlementRecipientRef: '',
      tableFilterRange: '',
      tableCustomStartTime: '',
      tableCustomEndTime: ''
    }
  })

  const [multiSelect, setMultiSelect] = useMultiSelect({
    key: 'webpayments'
  })

  const salesQueryKeys = [
    'sales',
    state.productRef,
    state.licensePlateNumber,
    state.invoiceNumber,
    state.webPaymentStatus,
    state.createdFromTime,
    state.createdUntilTime,
    state.page,
    state.sort,
    state.direction
  ]
  const salesQuery = useQuery({
    queryKey: salesQueryKeys,
    queryFn: () => {
      const params = { ...state }
      params.createdFromTime = moovyDateTime.calendarDateInputToBeginOfDayISO(
        params.createdFromTime
      )
      params.createdUntilTime = moovyDateTime.calendarDateInputToEndOfDayISO(
        params.createdUntilTime
      )
      params.settlementRecipientRef && delete params.settlementRecipientRef
      return salesService.fetchSales(operatorRealm, params)
    },
    staleTime: 1000 * 60 * 5,
    enabled: Boolean(
      ((isSuperOperator && state.settlementRecipientRef) || operatorRealm) &&
        state.productRef
    )
  })

  const sales = salesQuery.data

  const onChangeFilter = (key, value) => {
    let newValues

    if (key === 'dateRange') {
      const dateValues = {
        createdFromTime: value.createdFromTime,
        createdUntilTime: value.createdUntilTime,
        tableFilterRange: value.tableFilterRange,
        tableCustomStartTime: moovyDateTime.jsDateToISOString(
          value.tableCustomStartTime
        ),
        tableCustomEndTime: moovyDateTime.jsDateToISOString(
          value.tableCustomEndTime
        )
      }
      newValues = { ...state, ...dateValues, page: 0 }
    } else if (key === 'settlementRecipientRef') {
      value
        ? (newValues = { ...state, [key]: value, page: 0 })
        : (newValues = { ...state, [key]: value, productRef: '', page: 0 })
    } else {
      newValues = { ...state, [key]: value, page: 0 }
    }

    setState(newValues)
    setMultiSelect(multiSelect)
  }

  const setActivePage = (newActivePage) => {
    setState({ ...state, page: newActivePage - 1 })
  }

  const invoiceQuery = useQuery({
    queryKey: ['invoiceQuery', invoiceRef],
    queryFn: () => {
      return invoiceService.fetchInvoice(invoiceRef)
    },
    enabled: !!invoiceRef
  })

  const refetchSales = () => {
    salesQuery.refetch()
  }

  useEffect(() => {
    if (location.state?.refreshSales) {
      queryClient.invalidateQueries({ queryKey: salesQueryKeys })
    }
  }, [location.state?.refreshSales])

  useEffect(() => {
    if (
      ((isSuperOperator && state.settlementRecipientRef) || operatorRealm) &&
      state.productRef
    ) {
      refetchSales()
    }
  }, [])

  const getSortValues = () => {
    return {
      column: state.sort,
      direction: state.direction
    }
  }

  const setSort = (column, direction) => {
    setState({ ...state, sort: column, direction })
  }

  const onCloseCancelModal = (wasSomethingCanceled) => {
    /* Not happy with this. If something was canceled in the modal, refetch the current page and
    deselect everything that was selected. It would be cleaner if the selections would stay after canceling.
    It can be done, but had some weird state issues and don't have time to debug and refactor.

    One part of the problem is that selecting between different pages is allowed. This would be easier
    if you could select rows from a single page only. Maybe that is the way to refactor this and increase the page size? */
    if (wasSomethingCanceled) {
      queryClient.invalidateQueries({ queryKey: salesQueryKeys })
      setMultiSelect(multiSelect)
    }
    setShowCancelDialog(false)
  }
  return (
    <>
      <WebpaymentToolbar state={state} onChangeFilter={onChangeFilter} />

      <div className="Admin--Page--Content">
        <Segment attached="top" secondary>
          <Button
            as="div"
            labelPosition="right"
            primary
            onClick={() => setShowCancelDialog(true)}
          >
            <Button primary>
              <FormattedMessage id="sales.cancel.selected.button" />
            </Button>
            <Label as="a" basic pointing="left">
              <FormattedMessage
                id="sales.cancel.selected.info"
                values={{ count: multiSelect.length }}
              />
            </Label>
          </Button>
        </Segment>
        <WebpaymentSalesTable
          sortedColumn={state.sort}
          sortDirection={
            tablePaginationDirections.stringValues[state.direction]
          }
          data={salesQuery.data?.data}
          itemsQuery={salesQuery}
          onSort={(clickedColumn) =>
            sortColumn(clickedColumn, getSortValues(), setSort)
          }
          onClickSale={(sale) => setSelectedWebPaymentSale(sale)}
          onClickInvoice={(ref) => setInvoiceRef(ref)}
          onMultiSelect={setMultiSelect}
          activePage={state.page + 1}
          totalPages={(sales && sales.totalPages) || 0}
          selectedSales={multiSelect}
          onPageChange={(e, { activePage: page }) => setActivePage(page)}
        />
        {invoiceQuery.data && invoiceQuery.data.ref === invoiceRef && (
          <ModalInvoice
            onClose={() => {
              setInvoiceRef(null)
            }}
            invoiceRef={invoiceRef}
            operatorRealm={null}
            updateInvoicesCallback={refetchSales}
          />
        )}
        {selectedWebPaymentSale && (
          <ModalWebPaymentSaleStatus
            sale={selectedWebPaymentSale}
            licensePlate={selectedWebPaymentSale.parking?.licensePlate}
            onClose={(saleCancelled) => {
              if (saleCancelled) {
                refetchSales()
              }
              setSelectedWebPaymentSale(null)
            }}
          />
        )}
        <ModalCancelWebpayments
          open={showCancelDialog}
          onClose={onCloseCancelModal}
          payments={multiSelect}
        />
      </div>
    </>
  )
}
