const getLocationUrl = (locationRef, isSuperOperator) => {
  return isSuperOperator
    ? `/admin/locations/${locationRef}`
    : `/operator/locations/${locationRef}`
}

const getServiceUrl = (ref, isSuperOperator) => {
  return isSuperOperator
    ? `/admin/services/${ref}`
    : `/operator/services/${ref}`
}

const getParkingUrl = (parkingRef, isSuperOperator) => {
  return isSuperOperator
    ? `/admin/parking/${parkingRef}`
    : `/operator/parking/${parkingRef}`
}

export default {
  getLocationUrl,
  getServiceUrl,
  getParkingUrl
}
