import * as yup from 'yup'

import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Formik } from 'formik'
import { MutationErrorMessage } from '../../../components'
import React from 'react'

const validationSchema = () => {
  return yup.object().shape({ autoCleanThresholdInDays: yup.string().max(10) })
}

const ModalEditAutoCleanParking = ({
  onClose,
  location,
  onSubmit,
  mutation
}) => {
  const intl = useIntl()

  const initialFormikData = (location) => {
    const { autoCleanThresholdInDays } = location
    return {
      autoCleanThresholdInDays
    }
  }

  const hasValueChanged = (autoCleanThreshold) => {
    return location.autoCleanThreshold !== autoCleanThreshold
  }

  const handleFormitSubmit = (values) => {
    const { autoCleanThresholdInDays } = values

    if (hasValueChanged(autoCleanThresholdInDays)) {
      onSubmit({
        locationRef: location.ref,
        autoCleanThresholdInDays
      })
    } else {
      onClose()
    }
  }

  return (
    <Formik
      initialValues={initialFormikData(location)}
      validationSchema={validationSchema()}
      onSubmit={handleFormitSubmit}
    >
      {({ values, errors, handleChange, handleSubmit, handleBlur }) => (
        <Modal open onClose={onClose} size={'small'}>
          <Modal.Header
            content={intl.formatMessage(
              {
                id: 'modalEditAutoCleanParking.title'
              },
              {
                name: location.name
              }
            )}
          />
          <Modal.Content>
            <MutationErrorMessage
              mutation={mutation}
              defaultErrorTextLangId="modalEditAutoCleanParking.action.error.update"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Input
                fluid
                label={intl.formatMessage({
                  id: 'modalEditAutoCleanParking.label.autoCleanThreshold'
                })}
                value={values.autoCleanThresholdInDays}
                placeholder={intl.formatMessage({
                  id: 'common.unlimited'
                })}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                maxLength={10}
                name="autoCleanThresholdInDays"
                error={!!errors.autoCleanThresholdInDays}
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={
                mutation.isPending ||
                !hasValueChanged(values.autoCleanThresholdInDays)
              }
              loading={mutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalEditAutoCleanParking
