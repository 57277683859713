import { get, remove } from '../utils/api-renewed'

const getServiceAgreement = async (ref, operatorRealm) => {
  const url = operatorRealm
    ? `/operator/${operatorRealm}/serviceAgreements/${ref}`
    : `/administration/serviceAgreements/${ref}`
  const result = await get(url)
  return result
}

const deleteServiceAgreement = async (ref, operatorRealm) => {
  const url = operatorRealm
    ? `/operator/${operatorRealm}/serviceAgreements/${ref}`
    : `/administration/serviceAgreements/${ref}`
  const result = await remove(url)
  return result
}

export default {
  getServiceAgreement,
  deleteServiceAgreement
}
