import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useQuery } from '@tanstack/react-query'
import { Header, Segment } from 'semantic-ui-react'

import useGlobalUI from '../../../hooks/useGlobalUI'
import messageCenterService from '../../../services/MessageCenter'
import StepMessageContent from './StepMessageContent'
import StepSendingDetails from './StepSendingDetails'
import SelectedTargetGroups from './TargetGroupSelector/SelectedTargetGroups'

export const StepSummary = ({ formValues }) => {
  // This is an existing state from TargetGroupSelector which contains
  // selected targets and data of them and it is utilized here in the summary page.
  const [state] = useGlobalUI({
    key: 'TargetGroupSelector'
  })

  const [targets, setTargets] = useState(
    (state &&
      state.selectedTargetGroups &&
      state.selectedTargetGroups.filter((item) =>
        formValues.targetRefs.includes(item.ref)
      )) ||
      []
  )
  const [missingRef, setMissingRef] = useState('')

  const targetQuery = useQuery({
    queryKey: ['targetQuery', missingRef],
    queryFn: () => messageCenterService.fetchTarget(missingRef),
    enabled: !!missingRef
  })

  useEffect(() => {
    if (targetQuery.isSuccess) {
      setTargets([...targets, targetQuery.data])
    }
  }, [targetQuery.isSuccess])

  useEffect(() => {
    const missingRefs = formValues.targetRefs.filter(
      (ref) => !targets.find((item) => item.ref === ref)
    )
    if (missingRefs.length > 0) {
      setMissingRef(missingRefs[0])
    } else {
      setMissingRef('')
    }
  }, [targets])

  return (
    <>
      <Segment>
        <Header>
          <FormattedMessage id="messageCenter.createMessage.step.targetGroup.title" />
        </Header>
        <SelectedTargetGroups selectedTargetGroups={targets} readOnly={true} />
      </Segment>
      <Segment>
        <Header>
          <FormattedMessage id="messageCenter.createMessage.step.messageContent.title" />
        </Header>
        <StepMessageContent
          formValues={formValues.messageContent}
          readOnly={true}
        />
      </Segment>
      <Segment>
        <Header>
          <FormattedMessage id="messageCenter.createMessage.step.sendingDetails.title" />
        </Header>
        <StepSendingDetails
          formValues={formValues.sendingDetails}
          readOnly={true}
        />
      </Segment>
    </>
  )
}

export default StepSummary
