import './Finances.scss'

import React, { useState } from 'react'
import {
  calendarDateInputFormat,
  calendarDateInputToBackendFormat,
  dateNowToCalendarInput
} from '../../services/utils'

import { DateInput } from 'semantic-ui-calendar-react'
import { DateTime } from 'luxon'
import FinancesFilterYearMonth from './FinancesFilterYearMonth'
import FinancesPane from './FinancesPane'
import { Form } from 'semantic-ui-react'
import financesService from '../../services/Finances'
import { useIntl } from 'react-intl'

const ReportAdyenPaymentReconciliation = () => {
  const intl = useIntl()
  const PAYMENT_OPTIONS = {
    SETTLED: 'SETTLED',
    PENDING: 'PENDING'
  }

  const [startTime, setStartTime] = useState(dateNowToCalendarInput())
  const [yearAndMonth, setYearAndMonth] = useState({
    year: DateTime.local().year.toString(),
    month: DateTime.local().month.toString()
  })

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(
    PAYMENT_OPTIONS.SETTLED
  )

  const settledOptions = [
    {
      key: PAYMENT_OPTIONS.SETTLED,
      text: 'Settled',
      value: PAYMENT_OPTIONS.SETTLED
    },
    {
      key: PAYMENT_OPTIONS.PENDING,
      text: 'Pending',
      value: PAYMENT_OPTIONS.PENDING
    }
  ]

  return (
    <FinancesPane
      text={intl.formatMessage({
        id: 'finances.tab.reportAdyendPaymentReconciliation.header'
      })}
      buttonCallback={() =>
        financesService.downloadAdyenPaymentReconciliationReport({
          ...yearAndMonth,
          paymentStatus: selectedPaymentOption,
          eventBeforeDate: calendarDateInputToBackendFormat(startTime)
        })
      }
    >
      <Form style={{ maxWidth: '600px' }}>
        <Form.Group widths={'equal'}>
          <Form.Dropdown
            label={intl.formatMessage({
              id: 'finances.tab.reportAdyendPaymentReconciliation.paymentStatus'
            })}
            className="financial-operator"
            placeholder={intl.formatMessage({
              id: 'finances.tab.reportAdyendPaymentReconciliation.paymentStatus'
            })}
            selection
            options={settledOptions}
            onChange={(e, data) => setSelectedPaymentOption(data.value)}
            defaultValue={'SETTLED'}
            selectOnBlur={false}
          />
          <Form.Field />
        </Form.Group>
        <Form.Group>
          <Form.Field width="16">
            <FinancesFilterYearMonth
              yearAndMonth={yearAndMonth}
              setYearAndMonth={setYearAndMonth}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths={'equal'}>
          <Form.Field>
            <label>
              {intl.formatMessage({
                id: 'finances.tab.reportAdyendPaymentReconciliation.settledBefore'
              })}
            </label>
            <DateInput
              value={startTime}
              onChange={(ev, { value }) => setStartTime(value)}
              startMode="month"
              name="startDate"
              dateFormat={calendarDateInputFormat}
              placeholder={intl.formatMessage({
                id: 'financesFilterSingleDate.label.date'
              })}
              iconPosition="left"
              animation="none"
              closable
              maxDate={dateNowToCalendarInput()}
            />
          </Form.Field>
          <Form.Field />
        </Form.Group>
      </Form>
    </FinancesPane>
  )
}

export default ReportAdyenPaymentReconciliation
