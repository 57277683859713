import { Message, Progress } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import {
  adyenAccountHolderMainStatusEnum,
  settlementRecipientCompanyTypeEnum as companyTypeEnum
} from '../../../../services/utils/DTOEnums'

import { FormattedMessage } from 'react-intl'
import OnboardingSteps from './onboardingSteps'
import StepAccountHolder from './stepAccountHolder'
import StepOnboardingLink from './stepOnboardingLink'
import StepSignatoryOwner from './stepSignatoryOwner'
import StepUltimateBeneficialOwner from './stepUltimateBeneficialOwner'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import useAuthInfo from '../../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

export const stepAdyenEnum = {
  STEP_OWNER: 'STEP_OWNER',
  STEP_ACCOUNT_HOLDER: 'STEP_ACCOUNT_HOLDER',
  STEP_ONBOARDING_LINK: 'STEP_ONBOARDING_LINK'
}

export const accountHolderUIStatusEnum = {
  NONE: 'NONE',
  ACCOUNT_INITIALIZED: 'ACCOUNT_INITIALIZED',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  TOKEN_CREATED: 'TOKEN_CREATED'
}

const TabAdyen = ({ settlementRecipient }) => {
  const { isSuperOperator } = useAuthInfo()
  const [activeStep, setActiveStep] = useState(stepAdyenEnum.STEP_OWNER)
  const [finishedSteps, setFinishedSteps] = useState([])
  const [accountHolder, setAccountHolder] = useState(null)
  const [progressValue, setProgressValue] = useState(0)
  const [accountHolderUIStatus, setAccountHolderUIStatus] = useState(
    accountHolderUIStatusEnum.NONE
  )
  const progressTotalSteps = 3

  const getAccountHolderUIStatus = (accountHolder) => {
    const accountInitialized =
      !!accountHolder?.accountHolderCode && !!accountHolder?.ref
    const accountCreated =
      accountInitialized &&
      accountHolder.mainStatus != adyenAccountHolderMainStatusEnum.NOT_CREATED
    const tokenCreated =
      accountInitialized && accountCreated && !!accountHolder?.onboardingToken
    if (tokenCreated) {
      return accountHolderUIStatusEnum.TOKEN_CREATED
    } else if (accountCreated) {
      return accountHolderUIStatusEnum.ACCOUNT_CREATED
    } else if (accountInitialized) {
      return accountHolderUIStatusEnum.ACCOUNT_INITIALIZED
    } else {
      return accountHolderUIStatusEnum.NONE
    }
  }

  const ownersQuery = useQuery({
    queryKey: ['fetchAllRecipients'],
    queryFn: () => {
      return settlementRecipientService.fetchAllUltimateBeneficialOwner(
        settlementRecipient.ref
      )
    },
    enabled: Boolean(isSuperOperator && settlementRecipient.ref)
  })

  const fetchAccountHolder = useQuery({
    queryKey: ['fetchAccountHolder', settlementRecipient?.ref || ''],
    queryFn: () =>
      settlementRecipientService.fetchAccountHolder(settlementRecipient?.ref),
    enabled: !!settlementRecipient.ref
  })

  useEffect(() => {
    if (fetchAccountHolder.isSuccess) {
      setAccountHolder(fetchAccountHolder.data)
      const uiStatus = getAccountHolderUIStatus(fetchAccountHolder.data)
      setAccountHolderUIStatus(uiStatus)
      switch (uiStatus) {
        case accountHolderUIStatusEnum.TOKEN_CREATED:
          onNextClick(stepAdyenEnum.STEP_ONBOARDING_LINK)
          break
        case accountHolderUIStatusEnum.ACCOUNT_CREATED:
          onNextClick(stepAdyenEnum.STEP_ACCOUNT_HOLDER)
          break
        case accountHolderUIStatusEnum.ACCOUNT_INITIALIZED:
          onNextClick(stepAdyenEnum.STEP_OWNER)
          break
        default:
          break
      }
    }
  }, [fetchAccountHolder.isFetching])

  useEffect(() => {
    setProgressValue(
      Math.trunc(((finishedSteps.length || 0) / progressTotalSteps) * 100)
    )
  }, [finishedSteps.length])

  const RenderNoAdyenAllowed = () => {
    return (
      <Message error>
        <FormattedMessage id="tabAdyen.companyType.error" />
      </Message>
    )
  }

  const onNextClick = (step) => {
    let addFinishedSteps = []
    switch (step) {
      case stepAdyenEnum.STEP_OWNER:
        !finishedSteps.includes(step) &&
          setFinishedSteps([...finishedSteps, step])
        setActiveStep(stepAdyenEnum.STEP_ACCOUNT_HOLDER)
        break
      case stepAdyenEnum.STEP_ACCOUNT_HOLDER:
        finishedSteps.includes(step) &&
          finishedSteps.splice(finishedSteps.indexOf(step))
        !finishedSteps.includes(stepAdyenEnum.STEP_OWNER) &&
          addFinishedSteps.push(stepAdyenEnum.STEP_OWNER)
        !finishedSteps.includes(step) && addFinishedSteps.push(step)
        setFinishedSteps(finishedSteps.concat(addFinishedSteps))
        setActiveStep(stepAdyenEnum.STEP_ONBOARDING_LINK)
        break
      case stepAdyenEnum.STEP_ONBOARDING_LINK:
        !finishedSteps.includes(stepAdyenEnum.STEP_OWNER) &&
          addFinishedSteps.push(stepAdyenEnum.STEP_OWNER)
        !finishedSteps.includes(stepAdyenEnum.STEP_ACCOUNT_HOLDER) &&
          addFinishedSteps.push(stepAdyenEnum.STEP_ACCOUNT_HOLDER)
        !finishedSteps.includes(step) && addFinishedSteps.push(step)
        setFinishedSteps(finishedSteps.concat(addFinishedSteps))
        setActiveStep(stepAdyenEnum.STEP_ONBOARDING_LINK)
        break
      default:
        setActiveStep(stepAdyenEnum.STEP_OWNER)
        break
    }
  }

  const StepContent = ({ activeStep, finishedSteps }) => {
    switch (activeStep) {
      case stepAdyenEnum.STEP_OWNER:
        switch (settlementRecipient.companyType) {
          case companyTypeEnum.BUSINESS:
            return (
              <StepUltimateBeneficialOwner
                settlementRecipient={settlementRecipient}
                ownersQuery={ownersQuery}
                onNextClick={() => onNextClick(activeStep)}
                accountHolderUIStatus={accountHolderUIStatus}
              />
            )
          case companyTypeEnum.PUBLIC_COMPANY:
            return (
              <StepSignatoryOwner
                settlementRecipient={settlementRecipient}
                ownersQuery={ownersQuery}
                onNextClick={() => onNextClick(activeStep)}
                accountHolderUIStatus={accountHolderUIStatus}
              />
            )
          default:
            return ''
        }
      case stepAdyenEnum.STEP_ACCOUNT_HOLDER:
        return (
          <StepAccountHolder
            settlementRecipient={settlementRecipient}
            queryAccountHolder={fetchAccountHolder}
            accountHolder={accountHolder}
            accountHolderUIStatus={accountHolderUIStatus}
            refetchAccountHolder={() => fetchAccountHolder.refetch()}
            onNextClick={() => onNextClick(activeStep)}
            nextDisabled={!finishedSteps.includes(stepAdyenEnum.STEP_OWNER)}
          />
        )
      case stepAdyenEnum.STEP_ONBOARDING_LINK:
        return (
          <StepOnboardingLink
            settlementRecipient={settlementRecipient}
            accountHolder={accountHolder}
            refetchAccountHolder={() => fetchAccountHolder.refetch()}
            queryAccountHolder={fetchAccountHolder}
            onNextClick={() => onNextClick(activeStep)}
            nextDisabled={
              !finishedSteps.includes(stepAdyenEnum.STEP_OWNER) ||
              !finishedSteps.includes(stepAdyenEnum.STEP_ACCOUNT_HOLDER)
            }
          />
        )
      default:
        return ''
    }
  }

  const StepsProgress = ({ activeStep }) => {
    switch (activeStep) {
      case stepAdyenEnum.STEP_OWNER:
        if (settlementRecipient.companyType === companyTypeEnum.BUSINESS)
          return (
            <FormattedMessage id="tabAdyen.progress.label.beneficialOwner" />
          )
        if (settlementRecipient.companyType === companyTypeEnum.PUBLIC_COMPANY)
          return <FormattedMessage id="tabAdyen.progress.label.signatory" />
        break
      case stepAdyenEnum.STEP_ACCOUNT_HOLDER:
        return <FormattedMessage id="tabAdyen.progress.label.accountHolder" />
      case stepAdyenEnum.STEP_ONBOARDING_LINK:
        return <FormattedMessage id="tabAdyen.progress.label.onboardingLink" />
      default:
        return ''
    }
  }

  return (
    <>
      {settlementRecipient.companyType === companyTypeEnum.NOT_DEFINED ? (
        <RenderNoAdyenAllowed />
      ) : (
        <>
          <label>
            <b>
              <FormattedMessage id="tabAdyen.progress.label.proceeding" />
            </b>
          </label>
          <Progress
            value={progressValue}
            total={100}
            progress="percent"
            warning={progressValue < 100}
            success={progressValue === 100}
          >
            <StepsProgress activeStep={activeStep} />
          </Progress>
          <OnboardingSteps
            settlementRecipient={settlementRecipient}
            finishedSteps={finishedSteps}
            activeStep={activeStep}
            onStepClicked={(step) => setActiveStep(step)}
          >
            <StepContent
              activeStep={activeStep}
              finishedSteps={finishedSteps}
            />
          </OnboardingSteps>
        </>
      )}
    </>
  )
}

export default TabAdyen
