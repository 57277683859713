import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Table } from 'semantic-ui-react'

import { formatDateAndTime } from '../../services/utils'

export default function ExternalFacilityDataTable({ data, onRowClick }) {
  return (
    <>
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="externalFacilityDataTable.header.createdTime" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="externalFacilityDataTable.header.state" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {data.map((row) => (
            <Table.Row key={row.ref} onClick={() => onRowClick(row)}>
              <Table.Cell>{formatDateAndTime(row.creationTime)}</Table.Cell>
              <Table.Cell>{row.state}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
