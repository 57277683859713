import {
  frontendParkingTypeEnum,
  invoicingCardPaymentStatus,
  invoicingStatus,
  invoicingVismaStatus,
  paymentCardCategory,
  sale,
  serviceType,
  subscriptionPricingProfileItemTypes,
  webpayment
} from '../../services/utils'

import { FormattedMessage } from 'react-intl'
import React from 'react'

export default function MoovyLocalizedEnum({ localizationKeys, value }) {
  if (!value) {
    return null
  }
  if (!localizationKeys[value]) {
    return value
  }

  return <FormattedMessage id={localizationKeys[value]} />
}

export const LocalizedWebpaymentStatus = ({ value }) => (
  <MoovyLocalizedEnum
    localizationKeys={webpayment.webpaymentStatus.localizationKeys}
    value={value}
  />
)

export const LocalizedInvoicingStatus = ({ value, paymentMethodType }) => {
  const getLocalizationKeys = (paymentMethod) => {
    switch (paymentMethod) {
      case sale.paymentMethodTypes.VISMA:
        return invoicingVismaStatus.localizationKeys
      case sale.paymentMethodTypes.ADYEN_TRANSACTION:
      case sale.paymentMethodTypes.PAYMENT_HIGHWAY_TRANSACTION:
        return invoicingCardPaymentStatus.localizationKeys
      default:
        return invoicingStatus.localizationKeys
    }
  }

  return (
    <MoovyLocalizedEnum
      localizationKeys={getLocalizationKeys(paymentMethodType)}
      value={value}
    />
  )
}

export const LocalizedServiceType = ({ value }) => (
  <MoovyLocalizedEnum
    localizationKeys={serviceType.localizationKeys}
    value={value}
  />
)

export const LocalizedPaymentCardCategory = ({ value }) => {
  return (
    <MoovyLocalizedEnum
      localizationKeys={paymentCardCategory.localizationKeys}
      value={value}
    />
  )
}

export const LocalizedSubscriptionPricingProfileItemType = ({ value }) => {
  return (
    <MoovyLocalizedEnum
      localizationKeys={subscriptionPricingProfileItemTypes.localizationKeys}
      value={value}
    />
  )
}

export const LocalizedFrontendParkingType = ({ value }) => {
  return (
    <MoovyLocalizedEnum
      localizationKeys={frontendParkingTypeEnum.localizationKeys}
      value={value}
    />
  )
}
