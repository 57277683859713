import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import {
  Button,
  Confirm,
  Container,
  Form,
  Header,
  Modal,
  Table
} from 'semantic-ui-react'

import { LocalizedWebpaymentStatus } from '../../components/MoovyLocalizedEnum'
import useAuthInfo from '../../hooks/useAuthInfo'
import parkingService from '../../services/Parking'
import { formatDateAndTime, formatPrice } from '../../services/utils'
import CancelProgressInfo, * as cancelStates from './CancelProgressInfo'
import useWebPaymentCancelForm from '../../hooks/useWebPaymentCancelForm'

const WebpaymentsContainer = ({
  webpayments,
  succeededRefs,
  failedRefs,
  cancelingTotal,
  onStartCanceling,
  onCanceled,
  onFailed
}) => {
  const [cancelState, setCancelState] = useState(cancelStates.NOTHING)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const { operatorRealm } = useAuthInfo()

  const intl = useIntl()
  const { comment, setComment, errors } = useWebPaymentCancelForm()

  const { mutate: mutation } = useMutation({
    mutationFn: ({ ref, licensePlate }) => {
      const combinedReason = `${licensePlate}, ${comment.trim()}`
      parkingService.cancelSaleWebPayment(ref, combinedReason, operatorRealm)
    },
    onSuccess: (response, saleRef) => {
      onCanceled(saleRef)
    },
    onError: (response, saleRef) => {
      onFailed(saleRef)
    }
  })

  const cancelableWebpayments = webpayments.filter(
    (payment) => !succeededRefs.includes(payment.ref)
  )

  const cancelWebpayments = async () => {
    setCancelState(cancelStates.PENDING)
    onStartCanceling(cancelableWebpayments.length)

    for (let i = 0, len = cancelableWebpayments.length; i < len; i += 1) {
      // Send the requests one after another.
      const payment = cancelableWebpayments[i]
      // eslint-disable-next-line no-await-in-loop
      await mutation({
        ref: payment.ref,
        licensePlate: payment.parking.licensePlate
      })
    }
    setCancelState(cancelStates.DONE)
  }

  const getWebpaymentStatus = (wp) => {
    if (succeededRefs.includes(wp.ref)) {
      return (
        <FormattedMessage id="modalCancelWebpayments.cancel.status.success" />
      )
    }

    if (failedRefs.includes(wp.ref)) {
      return (
        <FormattedMessage id="modalCancelWebpayments.cancel.status.failure" />
      )
    }

    return <LocalizedWebpaymentStatus value={wp.webpayment?.status} />
  }

  const cancelingDone = succeededRefs.length + failedRefs.length

  return (
    <>
      <Header size="small">
        <FormattedMessage
          id="modalCancelWebpayments.webpayment.cancelable.info"
          values={{ count: webpayments.length }}
        />
      </Header>
      <Container className="cancelWebPaymentsTableContainer">
        <Table compact="very" size="small" celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <FormattedMessage id="modalCancelWebpayments.webpayment.table.creationTime" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="modalCancelWebpayments.webpayment.table.price" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="modalCancelWebpayments.webpayment.table.licensePlateNumber" />
              </Table.HeaderCell>
              <Table.HeaderCell>
                <FormattedMessage id="modalCancelWebpayments.webpayment.table.status" />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {webpayments.map((row) => (
              <Table.Row key={row.ref}>
                <Table.Cell>{formatDateAndTime(row.creationTime)}</Table.Cell>
                <Table.Cell>{formatPrice(row.price)}</Table.Cell>
                <Table.Cell>{row.parking.licensePlate}</Table.Cell>
                <Table.Cell
                  warning={failedRefs.includes(row.ref)}
                  positive={succeededRefs.includes(row.ref)}
                >
                  {getWebpaymentStatus(row)}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
      <CancelProgressInfo
        state={cancelState}
        done={cancelingDone}
        total={cancelingTotal}
        failed={failedRefs.length}
        messageId={
          failedRefs.length === 0
            ? 'modalCancelWebpayments.webpayment.progress.done.success'
            : 'modalCancelWebpayments.webpayment.progress.done.failure'
        }
      />
      {cancelState === cancelStates.NOTHING && (
        <Button
          primary
          onClick={() => setShowCancelModal(true)}
          disabled={
            cancelState === cancelStates.PENDING ||
            cancelState === cancelStates.DONE ||
            cancelableWebpayments.length === 0
          }
        >
          <FormattedMessage id="modalCancelWebpayments.webpayment.cancel" />
        </Button>
      )}

      <Modal open={showCancelModal}>
        <Modal.Header>
          <FormattedMessage id="modalCancelWebpayments.cancelModal.header" />
        </Modal.Header>
        <Modal.Content>
          <Form style={{ marginTop: '10px' }}>
            <Form.TextArea
              autoFocus
              required
              label={intl.formatMessage({
                id: 'modalCancelWebpayments.cancelModal.comment'
              })}
              value={comment}
              error={errors.comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowCancelModal(false)}>
            <FormattedMessage id="common.button.close" />
          </Button>
          <Button
            primary
            onClick={() => setShowConfirmation(true)}
            disabled={errors.comment}
          >
            <FormattedMessage id="modalCancelWebpayments.webpayment.cancel" />
          </Button>
        </Modal.Actions>
      </Modal>

      <Confirm
        open={showConfirmation}
        content={intl.formatMessage({
          id: 'modalCancelWebpayments.webpayment.cancel.confirm'
        })}
        cancelButton={intl.formatMessage({ id: 'common.button.cancel' })}
        confirmButton={intl.formatMessage({ id: 'common.button.confirm' })}
        onCancel={() => setShowConfirmation(false)}
        onConfirm={() => {
          setShowConfirmation(false)
          setShowCancelModal(false)
          cancelWebpayments()
        }}
      />
    </>
  )
}

export default WebpaymentsContainer
