import { Button, Checkbox, Modal, Popup, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyLink,
  MoovyTable,
  ParkingTypeLabel,
  ServiceWithTags
} from '../../../components'
import React, { useEffect, useState } from 'react'
import {
  formatDateAndTime,
  formatSeconds,
  parkingTypeEnum,
  sale,
  serviceType
} from '../../../services/utils'

import { DateTime } from 'luxon'
import EditLicensePlateModal from '../../../components/ParkingDataTable/EditLicensePlateModal'
import EndParkingBanner from '../../../components/ParkingDataTable/EndParkingBanner'
import EndParkingModal from '../../../components/ParkingDataTable/EndParkingModal'
import PropTypes from 'prop-types'
import Routing from '../../../routing/Routing'
import { TablePagination } from '../../../components'
import { locationShape } from '../../../services/utils/shapes'
import moovyDateTime from '../../../services/utils/moovyDateTime'
import parkingService from '../../../services/Parking'
import { showMoovyToast } from '../../../components/MoovyToast'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

const ParkingNowTable = ({
  parkingQuery,
  initiallySelectedParkingRef,
  activePage,
  totalPages,
  showParkingExceededColumns,
  onPageChange,
  onClickParking,
  onRefresh
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { operatorRealm, isSuperOperator } = useAuthInfo()

  const [selectedCheckBoxes, setSelectedCheckBoxes] = useState([])
  const [errorDialog, setErrorDialog] = useState(false)
  const [endParkingItems, setEndParkingItems] = useState([])
  const [endParkingDialog, setEndParkingDialog] = useState(false)
  const [showLicensePlateModal, setShowLicensePlateModal] = useState(false)
  const [editableLicensePlateData, setEditableLicensePlateData] = useState(null)

  const parkings = parkingQuery?.data?.content || []

  const { mutate: endParking, ...endParkingMutation } = useMutation({
    mutationFn: ({ parkingRefs, endTime, comment }) =>
      parkingService.endMultipleParking(
        parkingRefs,
        endTime,
        comment,
        'END_WITH_ERROR',
        operatorRealm
      ),
    onSuccess: () => {
      setEndParkingItems([])
      setEndParkingDialog(false)
      onRefresh(true)
      showMoovyToast(
        intl.formatMessage({
          id: 'parkingTable.endParking.toast.title'
        }),
        intl.formatMessage({
          id: 'parkingTable.endParking.toast.body'
        })
      )
    }
  })

  const { mutate: editLicensePlateNumber, ...editLicensePlateNumberMutation } =
    useMutation({
      mutationFn: ({ ref, licensePlate, comment }) =>
        parkingService.editLicensePlate(
          ref,
          {
            newLicensePlate: licensePlate,
            comment: comment
          },
          operatorRealm
        ),
      onSuccess: () => {
        onRefresh()
        setShowLicensePlateModal(false)
        setEditableLicensePlateData(null)
        showMoovyToast(
          intl.formatMessage({
            id: 'parkingTable.editLicensePlate.toast.title'
          }),
          intl.formatMessage({
            id: 'parkingTable.editLicensePlate.toast.body'
          })
        )
      }
    })

  useEffect(() => {
    const matchingItems = parkings.filter(
      (item) => selectedCheckBoxes.indexOf(item.ref) >= 0
    )
    if (matchingItems.length != selectedCheckBoxes.length) {
      setSelectedCheckBoxes([])
    }
  }, [parkingQuery.dataUpdatedAt])

  const getValidAnonymousRef = (parking) => {
    return (
      parking?.type === parkingTypeEnum.ANONYMOUS_PARKING &&
      parking?.paymentMethodType !== sale.paymentMethodTypes.PARKMAN &&
      parking?.paymentMethodType !== sale.paymentMethodTypes.EASYPARK &&
      parking?.ref
    )
  }

  const getValidAnonymousRefWithParkman = (parking) => {
    return parking?.type === parkingTypeEnum.ANONYMOUS_PARKING && parking?.ref
  }

  const isLicensePlateEditAllowed = (parking) => {
    return (
      parking?.type === parkingTypeEnum.ANONYMOUS_PARKING &&
      parking?.paymentMethodType !== sale.paymentMethodTypes.PARKMAN &&
      parking?.paymentMethodType !== sale.paymentMethodTypes.EASYPARK
    )
  }

  const SelectAll = (checked) => {
    if (checked) {
      setSelectedCheckBoxes(parkings.map((item) => item.ref))
    } else {
      setSelectedCheckBoxes([])
    }
  }

  const selectAllAnonymous = () => {
    const anonymousParking = parkings.filter((item) =>
      getValidAnonymousRef(item)
    )

    if (anonymousParking) {
      setSelectedCheckBoxes(
        anonymousParking.map((item) => getValidAnonymousRef(item))
      )
    } else {
      setSelectedCheckBoxes([])
    }
  }

  const openEndParkingModal = () => {
    const selectedEndParkingData = parkings.filter(
      (item) => selectedCheckBoxes.indexOf(item.ref) >= 0 && item
    )

    if (selectedEndParkingData.length > 0) {
      const validEndParkingData = selectedEndParkingData.filter((item) =>
        getValidAnonymousRefWithParkman(item)
      )

      if (selectedEndParkingData.length != validEndParkingData.length) {
        setErrorDialog(true)
        return
      }

      setEndParkingItems(selectedEndParkingData)
      setEndParkingDialog(true)
    }
  }

  const CheckboxClicked = ({ value, checked }) => {
    if (checked) {
      setSelectedCheckBoxes([...selectedCheckBoxes, value])
    } else {
      const index = selectedCheckBoxes.indexOf(value)
      if (index > -1) {
        selectedCheckBoxes.splice(index, 1)
        setSelectedCheckBoxes([...selectedCheckBoxes])
      }
    }
  }

  const SelectParkingCheckBox = ({ item }) => {
    const checked = !!selectedCheckBoxes.find(
      (selectedItem) => selectedItem === item.ref
    )
    return (
      <Checkbox
        checked={checked}
        value={item.ref}
        onClick={(e, data) => CheckboxClicked(data)}
        style={{ verticalAlign: 'middle' }}
      />
    )
  }

  const RenderServiceType = ({ parking }) => {
    return (
      <>
        <Popup
          trigger={
            <MoovyLink bold key={parking.ref}>
              {parking?.service?.type == serviceType.SUBSCRIPTION
                ? intl.formatMessage({
                    id: 'parkingTable.parkingProduct.subscription'
                  })
                : intl.formatMessage({
                    id: 'parkingTable.parkingProduct.shortParking'
                  })}
            </MoovyLink>
          }
        >
          <MoovyLink
            bold
            key={parking.ref}
            onClick={() =>
              navigate(
                Routing.getServiceUrl(parking.service?.ref, isSuperOperator)
              )
            }
          >
            {parking.service?.name}
          </MoovyLink>
        </Popup>
        <ServiceWithTags parkingService={parking?.service} />
      </>
    )
  }

  const getExceededTimeDiff = (exitAllowedBefore) => {
    const dateTimeExitAllowedBefore =
      moovyDateTime.backendDateTimeToDateTime(exitAllowedBefore)
    const dateTimeNow = DateTime.now()
    const diff = dateTimeExitAllowedBefore
      ? dateTimeNow.diff(dateTimeExitAllowedBefore, [
          'years',
          'months',
          'days',
          'hours',
          'minutes',
          'seconds'
        ])
      : undefined

    if (!diff) return ''

    let exceededTimeValue = ''
    diff.years > 0 &&
      (exceededTimeValue += `${diff.years}${intl.formatMessage({
        id: 'common.abbreviation.years'
      })} `)
    diff.months > 0 &&
      (exceededTimeValue += `${diff.months}${intl.formatMessage({
        id: 'common.abbreviation.months'
      })} `)
    diff.days > 0 &&
      (exceededTimeValue += `${diff.days}${intl.formatMessage({
        id: 'common.abbreviation.days'
      })} `)
    diff.hours > 0 &&
      (exceededTimeValue += `${diff.hours}${intl.formatMessage({
        id: 'common.abbreviation.hours'
      })} `)
    diff.minutes > 0 &&
      (exceededTimeValue += `${diff.minutes}${intl.formatMessage({
        id: 'common.abbreviation.minutes'
      })} `)
    exceededTimeValue += `${Math.floor(diff.seconds)}${intl.formatMessage({
      id: 'common.abbreviation.seconds'
    })}`

    return exceededTimeValue
  }

  return (
    <>
      <EndParkingBanner
        selectAllAnonymous={selectAllAnonymous}
        endAnonymousParkings={openEndParkingModal}
        disableEndButton={selectedCheckBoxes.length <= 0}
        hideBanner={selectedCheckBoxes.length <= 0}
      />

      <MoovyTable selectable items={parkings} query={parkingQuery}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Checkbox
                onClick={(e, data) => SelectAll(data.checked)}
                style={{ verticalAlign: 'middle' }}
                checked={selectedCheckBoxes.length > 0}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.type" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.startTime" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.endTime" />
            </Table.HeaderCell>
            {showParkingExceededColumns && (
              <>
                <Table.HeaderCell>
                  <FormattedMessage id="parkingTable.header.exitAllowedBefore" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="parkingTable.header.exitTimeExceeded" />
                </Table.HeaderCell>
              </>
            )}
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.duration" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.totalDuration" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.licensePlateNumber" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.customer" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingTable.header.parkingProduct" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {parkings &&
            parkings.map((parking) => (
              <Table.Row
                key={parking.ref}
                active={initiallySelectedParkingRef === parking.ref}
                onClick={() => onClickParking(parking)}
              >
                <Table.Cell
                  collapsing
                  onClick={(event) => event.stopPropagation()}
                >
                  <SelectParkingCheckBox item={parking} />
                </Table.Cell>
                <Table.Cell
                  textAlign="center"
                  collapsing
                  style={{ padding: '0px' }}
                >
                  <ParkingTypeLabel
                    type={parking.type}
                    paymentMethodType={parking.paymentMethodType}
                  />
                </Table.Cell>
                <Table.Cell>
                  {formatDateAndTime(parking.temporalDetails.startTime)}
                </Table.Cell>
                <Table.Cell collapsing>
                  <span className="primaryColor">
                    <FormattedMessage id="parkingTable.endTime.ongoing" />
                  </span>
                </Table.Cell>
                {showParkingExceededColumns && (
                  <>
                    <Table.Cell>
                      {formatDateAndTime(
                        parking.temporalDetails.exitAllowedBefore
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      {getExceededTimeDiff(
                        parking.temporalDetails.exitAllowedBefore
                      )}
                    </Table.Cell>
                  </>
                )}
                <Table.Cell>
                  {formatSeconds(
                    parking.temporalDetails.parkingDurationInSeconds
                  )}
                </Table.Cell>
                <Table.Cell>
                  {parking.temporalDetails?.parkingSequence
                    ? formatSeconds(
                        parking.temporalDetails.parkingSequence
                          .durationInSeconds
                      )
                    : ''}
                </Table.Cell>
                <Table.Cell
                  collapsing
                  onClick={(event) => event.stopPropagation()}
                >
                  {isLicensePlateEditAllowed(parking) ? (
                    <MoovyLink
                      style={{
                        margin: '0px',
                        padding: '0px'
                      }}
                      onClick={() => {
                        setShowLicensePlateModal(true)
                        setEditableLicensePlateData({
                          ref: parking.ref,
                          licensePlate: parking.licensePlateNumber
                        })
                      }}
                    >
                      {parking.licensePlateNumber}
                    </MoovyLink>
                  ) : (
                    parking.licensePlateNumber
                  )}
                </Table.Cell>
                <Table.Cell>{parking?.user?.name || ''}</Table.Cell>
                <Table.Cell
                  singleLine={true}
                  onClick={(event) => event.stopPropagation()}
                >
                  <RenderServiceType parking={parking} />
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
        <TablePagination
          colSpan={9}
          activePage={activePage}
          totalPages={totalPages}
          onPageChange={(e, { activePage: newPage }) => {
            onPageChange(newPage)
          }}
        />
      </MoovyTable>
      <EditLicensePlateModal
        open={showLicensePlateModal}
        licensePlateData={editableLicensePlateData}
        onChangeLicensePlateData={(licensePlateData) =>
          setEditableLicensePlateData(licensePlateData)
        }
        onClose={() => {
          setShowLicensePlateModal(false)
          setEditableLicensePlateData(null)
          editLicensePlateNumberMutation.reset()
        }}
        onSubmit={() =>
          showLicensePlateModal &&
          editLicensePlateNumber(editableLicensePlateData)
        }
        mutation={editLicensePlateNumberMutation}
      />
      <EndParkingModal
        endParkingItems={endParkingItems}
        mutation={endParkingMutation}
        open={endParkingDialog}
        onClose={() => setEndParkingDialog(false)}
        onSubmit={endParking}
      />
      <Modal onClose={() => setErrorDialog(false)} open={errorDialog}>
        <Modal.Header>
          <FormattedMessage id="parkingTable.endParking.modal.error.notValidParking.header" />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              <FormattedMessage id="parkingTable.endParking.modal.error.notValidParking" />
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={() => setErrorDialog(false)}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

ParkingNowTable.propTypes = {
  fetchSessionsByLocation: PropTypes.func,
  isSuperOperator: PropTypes.bool,
  location: PropTypes.shape(locationShape)
}

export default ParkingNowTable
