import * as yup from 'yup'

import {
  isValidLicensePlateNumber,
  isValidPhoneNumber
} from '../../services/utils'

import moovyDateTime from '../../services/utils/moovyDateTime'

export const validationSchema = () => {
  const validateValues = (phoneNumber, licensePlateNumber) => {
    if (!phoneNumber && !licensePlateNumber) {
      return false
    }

    let phoneNumberValid = true
    if (phoneNumber) {
      phoneNumberValid = isValidPhoneNumber(phoneNumber)
    }

    let licensePlateNumberValid = true
    if (licensePlateNumber) {
      licensePlateNumberValid = isValidLicensePlateNumber(licensePlateNumber)
    }

    return phoneNumberValid && licensePlateNumberValid ? true : false
  }

  return yup.object().shape({
    voucherTemplateRef: yup.string().required('Cannot be empty'),
    startDateTime: yup.string().required('Cannot be empty'),
    endDateTime: yup
      .string()
      .test(
        'NotTheSameAsStartDateTime',
        'Set end date time and start date time cannot be equal.',
        function(endDateTime) {
          const { startDateTime } = this.parent
          const start =
            startDateTime &&
            moovyDateTime.calendarDateTimeInputToDateTime(startDateTime)
          const end =
            endDateTime &&
            moovyDateTime.calendarDateTimeInputToDateTime(endDateTime)
          return end > start
        }
      ),
    phoneNumber: yup
      .string()
      .test('EitherPhoneOrLPN', 'Either phone or license is needed', function(
        phoneNumber
      ) {
        const { licensePlateNumber } = this.parent
        return validateValues(phoneNumber || '', licensePlateNumber || '')
      }),
    licensePlateNumber: yup
      .string()
      .test('EitherPhoneOrLPN', 'Either phone or license is needed', function(
        licensePlateNumber
      ) {
        const { phoneNumber } = this.parent
        return validateValues(phoneNumber || '', licensePlateNumber || '')
      })
  })
}
