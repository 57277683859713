import './CustomerToolbar.scss'

import React, { useEffect, useState } from 'react'
import UserInfo, { renderUserName } from '../../../containers/UserInfo'
import { useMutation } from '@tanstack/react-query'

import ModalChangeUserBlockList from './ModalChangeUserBlockList'
import Toolbar from '../../../components/Toolbar'
import UserEditModal from './UserEditModal'
import { createBreadcrumbs } from '../../../components/Toolbar/helpers'
import { showMoovyToast } from '../../../components/MoovyToast'
import { sortedVehiclesByCameraRecognition } from '../../../services/utils'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useIntl } from 'react-intl'
import userService from '../../../services/User/UserServices'

const CustomerToolbar = ({
  customer,
  vehicles,
  onRefreshUser,
  onShowAllVehicles
}) => {
  const intl = useIntl()

  const [toolbarButtons, setToolbarButtons] = useState([])
  const [updateUserModalOpen, setUpdateUserModalOpen] = useState(false)
  const [updateBlocklistModalOpen, setUpdateBlocklistModalOpen] =
    useState(false)
  const { operatorRealm, isSuperOperator } = useAuthInfo()

  const getToolbarButtons = (customer) => {
    if (!customer || !isSuperOperator) {
      return []
    }

    const buttons = []

    buttons.push({
      icon: 'setting',
      tooltip: intl.formatMessage({
        id: 'customers.detail.toolbar.button.editUser'
      }),
      action: () => setUpdateUserModalOpen(true)
    })

    if (customer.activeBlockListEntry) {
      buttons.push({
        icon: 'ban',
        tooltip: intl.formatMessage({
          id: 'customers.detail.toolbar.button.removeFromBlocklist'
        }),
        color: 'red',
        action: () => setUpdateBlocklistModalOpen(true)
      })
    } else {
      buttons.push({
        icon: 'ban',
        tooltip: intl.formatMessage({
          id: 'customers.detail.toolbar.button.setToBlocklist'
        }),
        action: () => setUpdateBlocklistModalOpen(true)
      })
    }

    return buttons
  }

  useEffect(() => {
    setToolbarButtons(getToolbarButtons(customer))
  }, [customer])

  const { mutate: updateUser, ...updateUserMutation } = useMutation({
    mutationFn: (userUpdateData) => {
      return userService.updateUser(
        operatorRealm,
        userUpdateData.userRef,
        userUpdateData
      )
    },
    onSuccess: (updatedUser) => {
      onRefreshUser()
      setUpdateUserModalOpen(false)
      showMoovyToast(
        intl.formatMessage({
          id: 'customers.detail.toolbar.toast.edited.title'
        }),
        intl.formatMessage(
          {
            id: 'customers.detail.toolbar.toast.edited.body'
          },
          { name: updatedUser.name }
        )
      )
    }
  })

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.applicationUsers'
      })
    },
    { text: customer.name, active: true }
  ])

  return (
    <div>
      <Toolbar
        title={renderUserName(customer)}
        breadcrumbs={breadcrumbs}
        buttons={toolbarButtons}
        content={
          <UserInfo
            customer={customer}
            vehicles={sortedVehiclesByCameraRecognition(vehicles)}
            onRefreshCustomer={onRefreshUser}
            onShowAllVehicles={onShowAllVehicles}
          />
        }
      />
      {updateUserModalOpen ? (
        <UserEditModal
          onClose={() => {
            updateUserMutation.reset()
            setUpdateUserModalOpen(false)
          }}
          user={customer}
          onSubmit={updateUser}
          mutation={updateUserMutation}
          onRefreshUser={() => {
            setUpdateUserModalOpen(false)
            onRefreshUser()
          }}
        />
      ) : null}
      <ModalChangeUserBlockList
        open={customer && updateBlocklistModalOpen}
        user={customer}
        onClose={() => setUpdateBlocklistModalOpen(false)}
        onConfirm={() => {
          setUpdateBlocklistModalOpen(false)
          onRefreshUser()
        }}
      />
    </div>
  )
}

export default CustomerToolbar
