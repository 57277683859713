import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import service from '../../services/potentiallyIncorrectLicensePlates'
import {
  Grid,
  Header,
  Image,
  List,
  Loader,
  Modal,
  Segment
} from 'semantic-ui-react'
import moovyDateTime from '../../services/utils/moovyDateTime'
import { formatSeconds } from '../../services/utils'
import useAuthInfo from '../../hooks/useAuthInfo'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import { FormattedMessage, useIntl } from 'react-intl'
import parkingService from '../../services/Parking'
import { LocalizedFrontendParkingType } from '../../components/MoovyLocalizedEnum'
import PotentiallyIncorrectLicensePlateToolbar from './PotentiallyIncorrectLicensePlateToolbar'
import GenericMessage, {
  createErrorMessage
} from '../../components/GenericMessage'
import PotentiallyIncorrectSegment from './PotentiallyIncorrectSegment'

const PotentiallyIncorrectLicensePlate = () => {
  const { ref } = useParams()
  const intl = useIntl()

  const { operatorRealm } = useAuthInfo()

  const query = useQuery({
    queryKey: ['potentiallyIncorrectLicensePlate', ref],
    queryFn: () => {
      return service.fetchByRef(ref, operatorRealm)
    },
    enabled: !!ref
  })

  return (
    <>
      <PotentiallyIncorrectLicensePlateToolbar />
      <div className="Admin--Page--Content">
        {query.isSuccess && (
          <Grid stackable columns={2}>
            <Grid.Row>
              <Grid.Column>
                <ParkingInfo
                  parking={query.data?.parking}
                  potentiallyIncorrectLpn={
                    query.data?.potentiallyIncorrectLicensePlateNumber
                  }
                />
              </Grid.Column>
              <Grid.Column>
                <PotentiallyIncorrectSegment suspected={query.data} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        {query.error && (
          <GenericMessage
            message={createErrorMessage(
              intl.formatMessage({
                id: 'potentiallyIncorrectLicensePlate.queryError'
              })
            )}
          />
        )}
      </div>
    </>
  )
}

const ParkingInfo = ({ parking, potentiallyIncorrectLpn }) => {
  const [fullScreenImage, setFullScreenImage] = useState()
  const { operatorRealm } = useAuthInfo()

  const externalDataQuery = useQuery({
    queryKey: ['externalFacilityData', parking.ref],
    queryFn: () =>
      parkingService.fetchExternalFacilityData(parking?.ref, operatorRealm),
    staleTime: 5 * 60000,
    retry: false,
    enabled: !!parking?.ref
  })

  return (
    <>
      <Header attached="top">
        <FormattedMessage id="potentiallyIncorrectLicensePlate.parkingInfo.header" />
      </Header>
      <Segment attached>
        {externalDataQuery.isLoading && <Loader />}

        {externalDataQuery.isSuccess &&
        externalDataQuery.data &&
        externalDataQuery.data.length > 0 ? (
          <Image
            src={externalDataQuery.data[0].imageURL}
            fluid
            onClick={() => setFullScreenImage(true)}
            style={{ cursor: 'pointer' }}
          />
        ) : null}
        <Header size="large">
          {potentiallyIncorrectLpn}
          <Header.Subheader>
            <LocalizedFrontendParkingType value={parking.parkingType} />
          </Header.Subheader>
        </Header>
        <List horizontal>
          <List.Item>
            <List.Header>
              <FormattedMessage id="potentiallyIncorrectLicensePlate.parkingInfo.start" />
            </List.Header>
            {moovyDateTime.backendDateTimeToUI(parking.start)}
          </List.Item>
          {parking.end && (
            <List.Item>
              <List.Header>
                <FormattedMessage id="potentiallyIncorrectLicensePlate.parkingInfo.end" />
              </List.Header>
              {moovyDateTime.backendDateTimeToUI(parking.end)}
            </List.Item>
          )}
          <List.Item>
            <List.Header>
              <FormattedMessage id="potentiallyIncorrectLicensePlate.parkingInfo.duration" />
            </List.Header>
            {formatSeconds(parking?.parkingDurationInSeconds)}
          </List.Item>
          <List.Item>
            <List.Header>
              <FormattedMessage id="potentiallyIncorrectLicensePlate.parkingInfo.location" />
            </List.Header>
            <RelativeLinkForCurrentUser
              to={`locations/${parking.location.ref}`}
            >
              {parking.location.name}
            </RelativeLinkForCurrentUser>
          </List.Item>
        </List>
        <Modal
          open={fullScreenImage}
          onClose={() => setFullScreenImage(false)}
          size={'large'}
        >
          <Modal.Content>
            {externalDataQuery.data?.length > 0 && (
              <Image src={externalDataQuery.data[0].imageURL} fluid />
            )}
          </Modal.Content>
        </Modal>
      </Segment>
    </>
  )
}

export default PotentiallyIncorrectLicensePlate
