import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Header, Segment } from 'semantic-ui-react'

import { Toolbar } from '../../components'
import { LocalizedServiceType } from '../../components/MoovyLocalizedEnum'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import useAuthInfo from '../../hooks/useAuthInfo'
import pricingService from '../../services/Pricing'
import servicesService from '../../services/Services'
import ServiceTariffDetails from './ServiceTariffDetails'

const ServiceTariff = () => {
  const { serviceRef, tariffRef } = useParams()
  const { operatorRealm } = useAuthInfo()
  const intl = useIntl()

  const serviceQuery = useQuery({
    queryKey: ['service', serviceRef],
    queryFn: () => servicesService.fetchService(serviceRef, operatorRealm)
  })

  const pricing = useQuery({
    queryKey: ['servicePricing', tariffRef],
    queryFn: () =>
      pricingService.fetchServicePricingByRef(tariffRef, operatorRealm)
  })

  const tariffAsString =
    pricing.data &&
    pricing.data.tariffStructure &&
    JSON.stringify(pricing.data.tariffStructure, null, 4)
  const breadcrumbsItems = [
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.parkingServices'
      }),
      href: '/services'
    }
  ]

  if (serviceQuery.data) {
    breadcrumbsItems.push({
      text: <LocalizedServiceType value={serviceQuery.data.type} />,
      href: 'services'
    })
    breadcrumbsItems.push({
      text: serviceQuery.data.name,
      href: `services/${serviceQuery.data.ref}`
    })
  }

  breadcrumbsItems.push({
    text: intl.formatMessage({
      id: 'common.breadcrumbs.tariff'
    }),
    active: true
  })
  const breadcrumbs = createBreadcrumbs(breadcrumbsItems)

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'serviceTariff.toolbar.title'
        })}
        breadcrumbs={breadcrumbs}
        content={<ServiceTariffDetails servicePricing={pricing.data} />}
      />

      <div className="Admin--Page--Content">
        <Header>
          <FormattedMessage id="serviceTariff.tariffStructure" />
        </Header>
        {(tariffAsString && (
          <Segment style={{ whiteSpace: 'pre' }}>{tariffAsString}</Segment>
        )) || (
          <Segment>
            <FormattedMessage id="serviceTariff.tariffStructureMissing" />
          </Segment>
        )}
      </div>
    </>
  )
}

export default ServiceTariff
