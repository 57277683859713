import React, { useState } from 'react'
import {
  Button,
  Container,
  Form,
  Header,
  Message,
  Popup,
  Segment
} from 'semantic-ui-react'

import GenericMessage from '../GenericMessage'

const EditVoucherForm = ({ voucher, onSubmit, message }) => {
  const [licensePlateNumber, setLicensePlateNumber] = useState(
    voucher.licensePlateNumber
  )

  const handleChange = (e, { name, value }) => setLicensePlateNumber(value)

  return (
    <Segment basic>
      <Container textAlign="center">
        <Header>Muokkaa pysäköintietua</Header>
        <Form onSubmit={() => onSubmit(licensePlateNumber)}>
          <Form.Input
            label="Rekisteritunnus"
            value={licensePlateNumber}
            onChange={handleChange}
          />
          <Form.Field>
            <Button primary>Tallenna</Button>
          </Form.Field>
        </Form>
        {message && <GenericMessage message={message} />}
      </Container>
    </Segment>
  )
}

export default EditVoucherForm
