import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu
} from 'semantic-ui-react'

import { messageCenterEnums } from '../../../../services/utils/DTOEnums'
import { buttonOperatorEnum } from './index'

export const operatorEnum = {
  NOT: '$not',
  EQUAL: 'EQUAL',
  NOT_EQUAL: 'NOT_EQUAL',
  localizationKeys: {
    EQUAL: 'messageCenter.targetGroup.operator.not.equal',
    NOT_EQUAL: 'messageCenter.targetGroup.operator.not.notEqual'
  }
}

export const Row = ({
  item,
  parentItem,
  rowButtonClick,
  rowButtonDeleteClick,
  onChangeValue,
  onOperatorSelected,
  onMenuItemClick,
  children
}) => {
  const intl = useIntl()

  const TargetFilterRowElement = ({
    operator,
    item,
    rowButtonClick,
    rowButtonDeleteClick,
    onChangeValue,
    onOperatorSelected
  }) => {
    const [inputValue, setInputValue] = useState(item.value)

    const categories = [
      {
        name: 'subscription',
        children: [
          {
            name: 'personalSubscriptionOnLocationNameV1'
          },
          {
            name: 'enterpriseSubscriptionOnLocationNameV1'
          }
        ]
      },
      {
        name: 'parking',
        children: [
          {
            name: 'parkingLocationNameV1'
          }
        ]
      }
    ]

    const getCategoryTranslation = (key) => {
      const locKey =
        messageCenterEnums.targetGroupCategories.localizationKeys[key]
      if (locKey) {
        return intl.formatMessage({
          id: locKey
        })
      } else {
        return key
      }
    }

    const getOperatorTranslation = (key) => {
      const locKey = operatorEnum.localizationKeys[key]
      if (locKey) {
        return intl.formatMessage({
          id: locKey
        })
      } else {
        return key
      }
    }

    const CategorySubMenuItem = ({
      categories,
      rootKey,
      rootId,
      onMenuItemClick
    }) => {
      return (
        <React.Fragment key={'categorySubMenuItem' + rootKey}>
          {categories.map(
            (item) =>
              (item.children?.length > 0 && (
                <React.Fragment key={'categorySubMenuItem' + item.name}>
                  <Dropdown.Item>
                    <Dropdown text={getCategoryTranslation(item.name)} fluid>
                      <Dropdown.Menu>
                        <CategorySubMenuItem
                          categories={item.children}
                          onMenuItemClick={onMenuItemClick}
                          rootKey={item.name}
                          rootId={rootId}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </Dropdown.Item>
                </React.Fragment>
              )) || (
                <React.Fragment key={'categorySubMenuItem' + item.name}>
                  <Dropdown.Item
                    onClick={(e, data) => onMenuItemClick(item, rootId)}
                  >
                    {getCategoryTranslation(item.name)}
                  </Dropdown.Item>
                </React.Fragment>
              )
          )}
        </React.Fragment>
      )
    }

    const CategoryMenu = ({ categories, rootId, onMenuItemClick }) => {
      return (
        <React.Fragment key={'categoryMenu' + rootId}>
          {categories.map(
            (item) =>
              (item.children?.length > 0 && (
                <React.Fragment key={'categoryMenu' + item.name}>
                  {categories.includes(item) && <Dropdown.Divider />}
                  <Dropdown.Header>
                    {getCategoryTranslation(item.name)}
                  </Dropdown.Header>
                  <CategorySubMenuItem
                    categories={item.children}
                    rootKey={item.name}
                    rootId={rootId}
                    onMenuItemClick={onMenuItemClick}
                  />
                </React.Fragment>
              )) || (
                <React.Fragment key={'categoryMenu' + item.name}>
                  <Dropdown.Item
                    value={'catMenuI_' + item.name}
                    onClick={(e, data) => onMenuItemClick(item, rootId)}
                  >
                    {getCategoryTranslation(item.name)}
                  </Dropdown.Item>
                </React.Fragment>
              )
          )}
        </React.Fragment>
      )
    }

    const ToolButton = ({ text, active }) => {
      return (
        <>
          <Button.Content
            style={(active && { color: '#ffffff' }) || { color: '#0098d8' }}
            href="#"
          >
            {text}
          </Button.Content>
        </>
      )
    }

    return (
      <>
        <Form.Group>
          <Form.Field width={4}>
            <Menu fluid>
              <Dropdown
                text={
                  getCategoryTranslation(item.targetPath) || 'Valitse suodatin'
                }
                className="link item"
                fluid
              >
                <Dropdown.Menu>
                  <CategoryMenu
                    rootId={item.id}
                    categories={categories}
                    onMenuItemClick={onMenuItemClick}
                  />
                </Dropdown.Menu>
              </Dropdown>
            </Menu>
          </Form.Field>
          <Form.Field
            style={{ marginTop: '2px' }}
            width={2}
            key={'operatorField' + item.id}
          >
            <Dropdown
              key={'dropoperator' + item.id}
              selection
              compact
              value={
                (item.itemOperator === operatorEnum.NOT &&
                  operatorEnum.NOT_EQUAL) ||
                operatorEnum.EQUAL
              }
              onChange={(e, { value }) => onOperatorSelected(item, value)}
              options={[
                {
                  key: operatorEnum.EQUAL,
                  text: getOperatorTranslation(operatorEnum.EQUAL),
                  value: operatorEnum.EQUAL
                },
                {
                  key: operatorEnum.NOT_EQUAL,
                  text: getOperatorTranslation(operatorEnum.NOT_EQUAL),
                  value: operatorEnum.NOT_EQUAL
                }
              ]}
            ></Dropdown>
          </Form.Field>
          <Form.Field
            style={{ marginTop: '2px' }}
            width={4}
            key={'fieldInput' + item.id}
          >
            <Input
              key={'input' + item.id}
              style={{ verticalAlign: 'middle' }}
              value={inputValue}
              onBlur={(e) =>
                item.value !== inputValue && onChangeValue(item, inputValue)
              }
              onChange={(e, value) => {
                setInputValue(value.value)
              }}
            />
          </Form.Field>
          <Form.Field style={{ marginTop: '2px' }} key={'buttons' + item.id}>
            <Button.Group basic>
              <Button
                basic
                toggle
                active={
                  item.activeButton && operator === buttonOperatorEnum.AND
                }
                onClick={() =>
                  !item.activeButton &&
                  rowButtonClick(buttonOperatorEnum.AND, item)
                }
                disabled={
                  item.activeButton && operator !== buttonOperatorEnum.AND
                }
              >
                <ToolButton
                  text={intl.formatMessage({
                    id: 'messageCenter.targetGroup.filter.button.and'
                  })}
                  active={
                    item.activeButton && operator === buttonOperatorEnum.AND
                  }
                />
              </Button>
              <Button
                basic
                toggle
                active={item.activeButton && operator === buttonOperatorEnum.OR}
                onClick={() =>
                  !item.activeButton &&
                  rowButtonClick(buttonOperatorEnum.OR, item)
                }
                disabled={
                  item.activeButton && operator !== buttonOperatorEnum.OR
                }
              >
                <ToolButton
                  text={intl.formatMessage({
                    id: 'messageCenter.targetGroup.filter.button.or'
                  })}
                  active={
                    item.activeButton && operator === buttonOperatorEnum.OR
                  }
                />
              </Button>
              <Button basic icon onClick={() => rowButtonDeleteClick(item)}>
                <Icon name="trash alternate" color="red" />
              </Button>
            </Button.Group>
          </Form.Field>
        </Form.Group>
        {item.activeButton && (
          <>
            {operator === buttonOperatorEnum.AND && (
              <Form.Field>
                <Divider section horizontal>
                  <FormattedMessage id="messageCenter.targetGroup.filter.and" />
                </Divider>
              </Form.Field>
            )}
            {operator === buttonOperatorEnum.OR && (
              <Form.Field width={16}>
                <Divider horizontal>
                  <FormattedMessage id="messageCenter.targetGroup.filter.or" />
                </Divider>
              </Form.Field>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <div key={`section-${item.id}`}>
      <div>
        {!item.operator && (
          <TargetFilterRowElement
            key={'filterRow' + item.id}
            item={item}
            operator={parentItem?.operator}
            rowButtonClick={rowButtonClick}
            rowButtonDeleteClick={rowButtonDeleteClick}
            onChangeValue={onChangeValue}
            onOperatorSelected={onOperatorSelected}
          />
        )}
      </div>
      {(item.operator === operatorEnum.NOT && <div> {children} </div>) || (
        <div style={{ paddingLeft: '20px' }}>{children}</div>
      )}
    </div>
  )
}

export default Row
