import './Finances.scss'

import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import financesService from '../../services/Finances'
import FinancesFilterOperator from './FinancesFilterOperator'
import FinancesFilterYearMonth from './FinancesFilterYearMonth'
import FinancesPane from './FinancesPane'

const ReportCancelledBarrierlessFacilityDebtCollectionCases = () => {
  const intl = useIntl()
  const [mandator, setMandator] = useState([])

  const onChangeMandatorValue = (value) => {
    setMandator(value)
  }

  const [yearAndMonth, setYearAndMonth] = useState({
    year: DateTime.local().year.toString(),
    month: DateTime.local().month.toString()
  })

  return (
    <FinancesPane
      extraContent={
        <FinancesFilterOperator onChangeMandatorValue={onChangeMandatorValue} />
      }
      text={intl.formatMessage({
        id:
          'finances.tab.reportCancelledBarrierlessFacilityDebtCollectionCases.header'
      })}
      buttonCallback={() =>
        financesService.downloadCancelledBarrierlessFacilityDebtCollectionCasesReport(
          { ...yearAndMonth, settlementRecipientRef: mandator }
        )
      }
    >
      <FinancesFilterYearMonth
        yearAndMonth={yearAndMonth}
        setYearAndMonth={setYearAndMonth}
      />
    </FinancesPane>
  )
}

export default ReportCancelledBarrierlessFacilityDebtCollectionCases
