import { get, post } from '../utils/api-renewed'

const fetchAllChargingsByFilter = async (queryParams) => {
  const result = await get(
    `/administration/electricVehicleCharging/sessions`,
    queryParams
  )
  return result
}
const forceStopChargingSession = async (sessionRef) => {
  const result = await post(
    `/administration/electricVehicleCharging/sessions/${sessionRef}/forceEnd`
  )
  return result
}

export default {
  fetchAllChargingsByFilter,
  forceStopChargingSession
}
