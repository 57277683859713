import './InvoiceAdministration.scss'

import React from 'react'
import { Container, List, Segment, Table } from 'semantic-ui-react'

import { formatPrice } from '../../services/utils'

const InvoiceReverts = ({ invoiceReverts }) => {
  const totalRevertSum = invoiceReverts
    .map((rev) => rev.totalRevertAmount)
    .reduce((acc, cur) => acc + cur, 0)

  return (
    <>
      <Segment>
        <List>
          <List.Item>
            <List.Header>Hyvitetty yhteensä</List.Header>
            <List.Content>
              <h2>{formatPrice(totalRevertSum)}</h2>
            </List.Content>
          </List.Item>
        </List>
      </Segment>
      <Container className="invoiceRowTableContainer">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Hyvitetty summa</Table.HeaderCell>
              <Table.HeaderCell>Laskurivejä</Table.HeaderCell>
              <Table.HeaderCell>Tila</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {invoiceReverts.map((rev) => (
              <Table.Row key={rev.ref}>
                <Table.Cell>{formatPrice(rev.totalRevertAmount)}</Table.Cell>
                <Table.Cell>{rev.invoiceRowReverts.length}</Table.Cell>
                <Table.Cell>{rev.status}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
    </>
  )
}

export default InvoiceReverts
