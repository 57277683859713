import { Button, Form, List, Modal, Popup } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ModalRemoveUser, MutationErrorMessage } from '../../../components'
import React, { useState } from 'react'

import { PermissionEnterpriseUser } from '../../../services/utils'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'
import userService from '../../../services/User/UserServices'

const ModalEditEnterpriseUser = ({
  onClose,
  user,
  onRefreshUser,
  mutation
}) => {
  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false)

  const { isSuperOperator, roles } = useAuthInfo()
  const intl = useIntl()

  const canRemoveUser = PermissionEnterpriseUser.HasAdministration(
    isSuperOperator,
    roles
  )

  const { mutate: removeUser, ...removeUserMutation } = useMutation({
    mutationFn: () => {
      return userService.removeEnterpriseUser(user?.ref)
    },
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'modalEditEnterpriseUser.toast.title.userRemoved',
        description: 'modalEditEnterpriseUser.toast.description.userRemove'
      })
      setRemoveUserModalOpen(false)
      onRefreshUser()
    }
  })

  const { firstName, lastName, email, phone, businessCode, company } =
    user?.userDetails

  return (
    <>
      <Modal open onClose={onClose} size="tiny">
        <Modal.Header
          content={
            <FormattedMessage id="modalEditEnterpriseUser.title.editUser" />
          }
        />
        <Modal.Content>
          <MutationErrorMessage
            mutation={mutation}
            defaultErrorTextLangId="modalEditEnterpriseUser.action.edit.default.error"
            messageNegative
          />
          <Form>
            <Form.Field>
              <label>
                <FormattedMessage id="modalEditEnterpriseUser.label.company" />
              </label>
              {company}
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="modalEditEnterpriseUser.label.businessCode" />
              </label>
              {businessCode}
            </Form.Field>
            <Form.Field>
              <label>
                <FormattedMessage id="modalEditEnterpriseUser.label.contact" />
              </label>
              <List relaxed verticalAlign="bottom">
                <List.Item>
                  <List.Icon name="user" />
                  <List.Content>
                    {firstName} {lastName}
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="at" />
                  <List.Content>{email}</List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="phone" />
                  <List.Content>{phone}</List.Content>
                </List.Item>
              </List>
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {canRemoveUser && (
            <Popup
              content={
                <FormattedMessage id="modalEditEnterpriseUser.button.tooltip.deleteUser" />
              }
              trigger={
                <Button
                  color="red"
                  floated="left"
                  onClick={() => setRemoveUserModalOpen(true)}
                >
                  <FormattedMessage id="common.button.delete" />
                </Button>
              }
            />
          )}
          <Button onClick={onClose}>
            <FormattedMessage id="common.button.OK" />
          </Button>
        </Modal.Actions>
      </Modal>
      <ModalRemoveUser
        user={user}
        open={removeUserModalOpen}
        onClose={() => setRemoveUserModalOpen(false)}
        removeUserMutation={removeUserMutation}
        onRemove={removeUser}
      />
    </>
  )
}

export default ModalEditEnterpriseUser
