import { Button, Form, Icon, Select } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'
import {
  commonEnum,
  frontendParkingTypeEnum,
  getStringValue,
  serviceType
} from '../../../services/utils'

import { MoovyRefreshTime } from '../../../components/'
import { useDebounce } from 'use-debounce'

function ParkingNowFilters({
  showExitTimeFilter,
  updatedAt,
  loading,
  onRefresh,
  values,
  setValues
}) {
  const intl = useIntl()

  const [partialLicensePlateNumber, setPartialLicensePlateNumber] = useState(
    values.partialLicensePlateNumber || ''
  )
  const [debouncedPartialLicensePlateNumber] = useDebounce(
    partialLicensePlateNumber,
    750
  )

  const setFilterValue = (filterType, filterValue) => {
    let updateValues = { ...values }
    if (
      filterType === 'parkingType' &&
      filterValue != frontendParkingTypeEnum.ANONYMOUS_PARKING
    ) {
      updateValues.exitTimeExceeded = false
    }

    if (
      filterType === 'parkingType' &&
      filterValue != frontendParkingTypeEnum.MOOVY_PARKING
    ) {
      updateValues.serviceType = commonEnum.ALL
    }

    updateValues[filterType] = filterValue
    updateValues.activePage = 1

    setValues({ ...updateValues })
  }

  useEffect(() => {
    setFilterValue(
      'partialLicensePlateNumber',
      debouncedPartialLicensePlateNumber
    )
  }, [debouncedPartialLicensePlateNumber])

  const parkingOptions = Object.keys(frontendParkingTypeEnum)
    .filter(
      (item) =>
        item !== 'localizationKeys' &&
        item !== frontendParkingTypeEnum.PARKMAN_PARKING &&
        item !== frontendParkingTypeEnum.EASYPARK_PARKING
    )
    .map((key) => {
      return {
        key: key,
        value: key,
        text: intl.formatMessage({
          id: getStringValue(frontendParkingTypeEnum.localizationKeys, key)
        })
      }
    })

  const serviceOptions = [
    {
      key: commonEnum.ALL,
      value: commonEnum.ALL,
      text: intl.formatMessage({
        id: 'common.all'
      })
    }
  ].concat(
    [serviceType.PAY_AS_YOU_GO, serviceType.SUBSCRIPTION].map((key) => ({
      key: key,
      value: key,
      text: intl.formatMessage({ id: serviceType.localizationKeys[key] ?? key })
    }))
  )

  return (
    <Form autoComplete="off">
      <Form.Group>
        <Form.Field
          control={Select}
          label={intl.formatMessage({
            id: 'parkingNowFilters.form.label.parkingType'
          })}
          defaultValue={frontendParkingTypeEnum.ALL}
          options={parkingOptions}
          value={values.parkingType}
          name="parkingType"
          onChange={(event, { name, value }) => setFilterValue(name, value)}
        />
        {values.parkingType === frontendParkingTypeEnum.MOOVY_PARKING && (
          <Form.Field
            control={Select}
            label={intl.formatMessage({
              id: 'parkingNowFilters.form.label.serviceType'
            })}
            options={serviceOptions}
            value={values.serviceType}
            name="serviceType"
            onChange={(event, { name, value }) => setFilterValue(name, value)}
          />
        )}
        {showExitTimeFilter &&
          values.parkingType === frontendParkingTypeEnum.ANONYMOUS_PARKING && (
            <Form.Field>
              <label>
                <FormattedMessage id="parkingNowFilters.label.exitTimeExceeded" />
              </label>
              <Form.Checkbox
                style={{ paddingTop: '10px' }}
                label={intl.formatMessage({
                  id: 'parkingNowFilters.label.checkbox.exitTimeExceeded'
                })}
                checked={values.exitTimeExceeded}
                name="exitTimeExceeded"
                onChange={(e, data) =>
                  setFilterValue('exitTimeExceeded', data.checked)
                }
              />
            </Form.Field>
          )}
        <Form.Field>
          <Form.Input
            label={intl.formatMessage({
              id: 'parkingNowFilters.form.label.licensePlate'
            })}
            name="partialLicensePlateNumber"
            autoFocus
            value={partialLicensePlateNumber}
            onChange={(e, { value }) =>
              setPartialLicensePlateNumber(value?.trim())
            }
            icon={
              <Icon
                name={partialLicensePlateNumber ? 'delete' : ''}
                link={!!partialLicensePlateNumber}
                onClick={() => setPartialLicensePlateNumber('')}
              />
            }
          />
        </Form.Field>
        <Form.Field>
          <label>
            {intl.formatMessage({
              id: 'parkingNowFilters.form.label.refreshView'
            })}
          </label>
          <Button icon primary onClick={onRefresh} loading={loading}>
            <Icon name="refresh" />
          </Button>
          <MoovyRefreshTime updatedAtAsMillis={updatedAt} loading={loading} />
        </Form.Field>
      </Form.Group>
    </Form>
  )
}
export default ParkingNowFilters
