import './UserInfo.scss'

import { Grid, Icon, Item, List } from 'semantic-ui-react'

import { FormattedMessage } from 'react-intl'
import { LicensePlateRecognitionIcon } from '../../components'
import React from 'react'

const UserVehicles = ({ vehicles, onShowAllVehicles }) => {
  const maxVehiclesCountOnView = 3

  const RenderVehicle = ({
    licensePlateNumber,
    licensePlateRecognitionActive
  }) => {
    return (
      <>
        <Grid.Column width={3}>
          <Icon name="car" />
          {` ${licensePlateNumber}`}
        </Grid.Column>
        <Grid.Column width={10}>
          <LicensePlateRecognitionIcon
            cameraRecognitionAllowed={licensePlateRecognitionActive}
          />
        </Grid.Column>
      </>
    )
  }

  return (
    <>
      <List>
        <List.Item>
          <b>
            <FormattedMessage id="customers.detail.toolbar.vehicles.title" />
          </b>
        </List.Item>
        {vehicles &&
          vehicles.slice(0, maxVehiclesCountOnView).map((vehicle) => (
            <List.Item key={vehicle.ref}>
              <List.Content>
                <Grid columns={2}>
                  <RenderVehicle
                    licensePlateNumber={vehicle.licensePlateNumber}
                    licensePlateRecognitionActive={
                      vehicle.licensePlateRecognitionPermissions &&
                      vehicle.licensePlateRecognitionPermissions
                        .allowLicensePlateBasedParkingFacilityAccess
                    }
                  />
                </Grid>
              </List.Content>
            </List.Item>
          ))}
        {vehicles && vehicles.length > maxVehiclesCountOnView ? (
          <List.Item>
            <Item onClick={onShowAllVehicles}>
              <Item.Content>
                <Item.Header as="a">
                  <FormattedMessage id="customers.detail.toolbar.vehicles.link" />
                </Item.Header>
              </Item.Content>
            </Item>
          </List.Item>
        ) : (
          ''
        )}
      </List>
    </>
  )
}

export default UserVehicles
