import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import FinancesFilterOperator from './FinancesFilterOperator'
import financesService from '../../services/Finances'
import FinancesFilterYearMonth from './FinancesFilterYearMonth'
import FinancesPane from './FinancesPane'
import {
  calendarDateInputToBackendFormat,
  dateNowToCalendarInput,
  localizedErrorString
} from '../../services/utils'
import FinancesFilterDateRange from './FinancesFilterDateRange'
import { Form } from 'semantic-ui-react'
import { GenericMessage } from '../../components'
import { createErrorMessage } from '../../components/GenericMessage'
import { useSelector } from 'react-redux'
import { localizationSelectors } from '../../redux/localization'

const ReportAdyenSuccessfulPaymentsByProducts = () => {
  const intl = useIntl()
  const locale = useSelector(localizationSelectors.getLocale)
  const [mandator, setMandator] = useState()
  const [startTime, setStartTime] = useState(dateNowToCalendarInput())
  const [endTime, setEndTime] = useState(dateNowToCalendarInput())
  const [yearAndMonth, setYearAndMonth] = useState({
    year: '',
    month: ''
  })
  const [coverCreditLoss, setCoverCreditLoss] = useState(undefined)
  const [postPayment, setPostPayment] = useState(undefined)
  const [error, setError] = useState()

  const onChangeMandatorValue = (value) => {
    setMandator(value)
  }

  const submitHandler = async () => {
    setError(null)
    if (!mandator || !startTime || !endTime) {
      setError(
        intl.formatMessage({
          id: 'finances.tab.reportAdyenSuccessfulPayments.requiredMissing'
        })
      )
      return
    }
    try {
      await financesService.downloadAdyenSuccessfulPaymentsReport({
        settlementRecipientRef: mandator,
        from: calendarDateInputToBackendFormat(startTime),
        until: calendarDateInputToBackendFormat(endTime),
        year: yearAndMonth.year,
        month: yearAndMonth.month,
        creditLossCovered:
          coverCreditLoss !== undefined ? coverCreditLoss.toString() : '',
        isPostPayment: postPayment !== undefined ? postPayment.toString() : ''
      })
    } catch (error) {
      const localizedErrorMessage = localizedErrorString(error.body, locale)
      setError(
        localizedErrorMessage
          ? localizedErrorMessage
          : intl.formatMessage({
              id: 'finances.tab.reportAdyenSuccessfulPayments.fetchError'
            })
      )
    }
  }
  return (
    <FinancesPane
      extraContent={
        <FinancesFilterOperator onChangeMandatorValue={onChangeMandatorValue} />
      }
      textParagraphs={intl
        .formatMessage({
          id: 'finances.tab.reportAdyenSuccessfulPayments.header'
        })
        .match(/[^.!?]+[.!?]/g)}
      buttonCallback={submitHandler}
    >
      <FinancesFilterDateRange
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
      />
      <FinancesFilterYearMonth
        yearAndMonth={yearAndMonth}
        setYearAndMonth={setYearAndMonth}
      />
      <Form>
        <Form.Field>
          <label>
            <FormattedMessage id="finances.tab.reportAdyenSuccessfulPayments.coverCreditLoss" />
          </label>
          <Form.Group inline>
            <Form.Radio
              label={intl.formatMessage({
                id: 'common.yes'
              })}
              checked={coverCreditLoss === true}
              onChange={() => setCoverCreditLoss(true)}
            />
            <Form.Radio
              label={intl.formatMessage({
                id: 'common.no'
              })}
              checked={coverCreditLoss === false}
              onChange={() => setCoverCreditLoss(false)}
            />
            <Form.Radio
              label={intl.formatMessage({
                id: 'common.all'
              })}
              checked={coverCreditLoss === undefined}
              onChange={() => setCoverCreditLoss(undefined)}
            />
          </Form.Group>
        </Form.Field>
        <Form.Field>
          <label>
            <FormattedMessage id="finances.tab.reportAdyenSuccessfulPayments.postPayment" />
          </label>
          <Form.Group inline>
            <Form.Radio
              label={intl.formatMessage({
                id: 'common.yes'
              })}
              checked={postPayment === true}
              onChange={() => setPostPayment(true)}
            />
            <Form.Radio
              label={intl.formatMessage({
                id: 'common.no'
              })}
              checked={postPayment === false}
              onChange={() => setPostPayment(false)}
            />
            <Form.Radio
              label={intl.formatMessage({
                id: 'common.all'
              })}
              checked={postPayment === undefined}
              onChange={() => setPostPayment(undefined)}
            />
          </Form.Group>
        </Form.Field>
      </Form>
      {error && (
        <GenericMessage
          error
          message={createErrorMessage(error)}
        ></GenericMessage>
      )}
    </FinancesPane>
  )
}

export default ReportAdyenSuccessfulPaymentsByProducts
