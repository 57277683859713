import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useState } from 'react'

import { MutationErrorMessage } from '../../../components'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import { useMutation } from '@tanstack/react-query'
import userService from '../../../services/User/UserServices'
import { validateEmail } from '../../../services/utils'

const ModalRemovingRequest = ({ user, open, onClose, onRemovingRequest }) => {
  const intl = useIntl()
  const [emailAddress, setEmailAddress] = useState(
    user?.userDetails?.email || ''
  )

  const {
    mutate: createUserDeletionRequest,
    ...createUserDeletionRequestMutation
  } = useMutation({
    mutationFn: (email) => {
      return userService.createUserDeletionRequest(user?.ref, {
        contactEmail: email
      })
    },
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title: 'modalRemovingRequest.toast.title.userRemoved',
        description: 'modalRemovingRequest.toast.description.userRemove'
      })
      onRemovingRequest()
    }
  })

  const onCloseModal = () => {
    createUserDeletionRequestMutation.reset()
    onClose()
  }

  const isEmailValid = (email) => {
    return !!(email && validateEmail(email))
  }

  return (
    <Modal open={open} onClose={onCloseModal} size={'tiny'}>
      <Modal.Header>
        {intl.formatMessage(
          { id: 'modalRemovingRequest.title.removeUser' },
          { userName: user?.name }
        )}
      </Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={createUserDeletionRequestMutation}
          defaultErrorTextLangId="modalRemovingRequest.action.default.error"
          messageNegative
        />
        <Modal.Description>
          <p>
            {intl.formatMessage(
              { id: 'modalRemovingRequest.description.removeUser' },
              { userName: user?.name }
            )}
          </p>
          <Form>
            <Form.Input
              fluid
              label={intl.formatMessage({
                id: 'modalRemovingRequest.label.email'
              })}
              value={emailAddress}
              onChange={(event, data) => setEmailAddress(data.value)}
              name="email"
              error={!isEmailValid(emailAddress)}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseModal}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          type="submit"
          primary
          onClick={() =>
            isEmailValid(emailAddress) &&
            createUserDeletionRequest(emailAddress)
          }
          loading={createUserDeletionRequestMutation.isPending}
          disabled={createUserDeletionRequestMutation.isPending}
        >
          <FormattedMessage id="modalRemovingRequest.button.removingRequest" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalRemovingRequest
