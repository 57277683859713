import { Button, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { MutationErrorMessage } from '..'
import React from 'react'

const ModalRemoveUser = ({
  user,
  open,
  onClose,
  removeUserMutation,
  onRemove
}) => {
  const intl = useIntl()

  const onCloseModal = () => {
    removeUserMutation.reset()
    onClose()
  }

  return (
    <Modal open={open} onClose={onCloseModal} size={'tiny'}>
      <Modal.Header>
        {(user?.role === 'ROLE_ENTERPRISE_USER' && (
          <FormattedMessage
            id={'modalRemoveUser.title.removeEnterpriseUser'}
            values={{ userName: user?.name }}
          />
        )) || (
          <FormattedMessage
            id={'modalRemoveUser.title.removeUser'}
            values={{ userName: user?.name }}
          />
        )}
      </Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={removeUserMutation}
          defaultErrorTextLangId="modalRemoveUser.action.default.error"
          messageNegative
        />
        <Modal.Description>
          {(user?.role === 'ROLE_ENTERPRISE_USER' && (
            <FormattedMessage
              id={'modalRemoveUser.description.removeEnterpriseUser'}
              values={{ userName: user?.name }}
            />
          )) || (
            <FormattedMessage
              id={'modalRemoveUser.description.removeUser'}
              values={{ userName: user?.name }}
            />
          )}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onCloseModal}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          type="submit"
          primary
          onClick={() => onRemove()}
          loading={removeUserMutation.isPending}
          disabled={removeUserMutation.isPending}
        >
          <FormattedMessage id="common.button.delete" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalRemoveUser
