import { Navigate } from 'react-router-dom'
import React from 'react'
import useAuthInfo from '../hooks/useAuthInfo'

function RequireAuthentication({ children }) {
  const { isAuthenticated, isInitialized } = useAuthInfo()

  if (isInitialized) {
    if (isAuthenticated) {
      return children
    }
    return (
      <Navigate to={`/login?redirectUrl=${window.location.href}`} replace />
    )
  }
  return null
}

export default RequireAuthentication
