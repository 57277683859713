export const ZeroTariff = {
  dailyFee: 0,
  subscriptionFee: 0,
  periodicFee: 0,
  description:
    '<p><strong>Hinta: </strong>0 €/kk (sis. ALV). Veroton 0 €/kk</p>\n',
  lifeCycleState: 'PUBLISHED',
  tariffStructure: {
    name: '0-hintainen',
    tariffs: [
      {
        name: 'Default',
        startDay: {
          day: 'ANY'
        },
        startTime: '00:00',
        endDay: {
          day: 'ANY'
        },
        endTime: '00:00',
        price: 0,
        stepMinutes: 60,
        type: 'STEP',
        endType: 'TARIFF_STEP',
        preconditions: []
      }
    ]
  }
}
