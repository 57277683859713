import { Button, Form, Modal } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect, useState } from 'react'

import { MutationErrorMessage } from '..'
import PropTypes from 'prop-types'
import { isValidPhoneNumber } from '../../services/utils'

function ModalRefundConfirmation({
  open,
  invoiceUser,
  onClose,
  lang,
  onSubmit,
  mutation
}) {
  const intl = useIntl()

  const [comment, setComment] = useState('')
  const [checkboxClipboard, setCheckboxClipboard] = useState(true)
  const [nameOfRevertReceiver, setNameOfRevertReceiver] = useState(
    (invoiceUser?.name &&
      !isValidPhoneNumber(invoiceUser?.name) &&
      invoiceUser?.name) ||
      ''
  )
  const [phoneNumberOfRevertReceiver, setPhoneNumberOfRevertReceiver] =
    useState(invoiceUser?.userDetails?.phone || '')

  useEffect(() => {
    if (open) {
      setNameOfRevertReceiver(
        (invoiceUser?.name &&
          !isValidPhoneNumber(invoiceUser?.name) &&
          invoiceUser?.name) ||
          ''
      )
      setPhoneNumberOfRevertReceiver(invoiceUser?.userDetails?.phone || '')
    }
  }, [open])

  const resetView = () => {
    mutation.reset()
    setComment('')
    setNameOfRevertReceiver('')
  }

  const onFormSubmit = () => {
    const formComment = comment.trim()
    const formNameOfRevertReceiver = nameOfRevertReceiver.trim()
    const formPhoneNumberOfRevertReceiver = phoneNumberOfRevertReceiver.trim()
    if (
      formComment &&
      formNameOfRevertReceiver &&
      formPhoneNumberOfRevertReceiver
    ) {
      onSubmit({
        comment: formComment,
        customerName: `${formNameOfRevertReceiver}, ${formPhoneNumberOfRevertReceiver}`,
        copyToClipboard: checkboxClipboard
      })
    }
  }

  return (
    <Modal
      size="tiny"
      open={open}
      onClose={() => {
        resetView()
        onClose()
      }}
    >
      <Modal.Header>{lang.titleElement}</Modal.Header>
      <Modal.Content>
        <MutationErrorMessage
          mutation={mutation}
          defaultErrorTextLangId={lang.defaultErroKey}
          messageNegative
        />
        <div style={{ padding: '5px' }}>
          {lang.bodyElement}
          <Form style={{ marginTop: '10px' }}>
            <Form.Input
              label={intl.formatMessage({
                id: 'modalRefundConfirmation.form.label.revertReceiverPhoneNumber'
              })}
              value={phoneNumberOfRevertReceiver}
              onChange={(event, data) =>
                setPhoneNumberOfRevertReceiver(data.value)
              }
              error={!!!phoneNumberOfRevertReceiver.trim()}
            />
            <Form.Input
              label={intl.formatMessage({
                id: 'modalRefundConfirmation.form.label.revertReceiver'
              })}
              value={nameOfRevertReceiver}
              onChange={(event, data) => setNameOfRevertReceiver(data.value)}
              error={!!!nameOfRevertReceiver.trim()}
            />
            <Form.TextArea
              autoFocus={true}
              label={intl.formatMessage({
                id: 'modalRefundConfirmation.form.label.revertReason'
              })}
              value={comment}
              onChange={(event, data) => {
                setComment(data.value)
              }}
              error={!!!comment.trim()}
            />
            <Form.Field>
              <Form.Checkbox
                label={intl.formatMessage({
                  id: 'modalRefundConfirmation.checkbox.copyToClipboard'
                })}
                checked={checkboxClipboard}
                onChange={() => setCheckboxClipboard(!checkboxClipboard)}
              />
            </Form.Field>
          </Form>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => {
            resetView()
            onClose()
          }}
        >
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          disabled={mutation.isPending}
          loading={mutation.isPending}
          primary
          onClick={() => {
            onFormSubmit()
          }}
        >
          <FormattedMessage id={lang.buttonConfirmKey} />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

ModalRefundConfirmation.propTypes = {
  lang: PropTypes.shape({
    titleElement: PropTypes.object,
    bodyElement: PropTypes.object,
    buttonConfirmKey: PropTypes.string,
    defaultErroKey: PropTypes.string
  })
}

export default ModalRefundConfirmation
