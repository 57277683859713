import { Input } from 'semantic-ui-react'
import React from 'react'

export default function MoovyTextInput(parameters) {
  const { value = '', onClear } = parameters

  const searchIcon = {
    name: 'search',
    color: 'grey'
  }
  const clearIcon = {
    name: 'remove',
    link: true,
    color: 'grey',
    onClick: onClear
  }

  let inputProps = { ...parameters }
  delete inputProps.onClear

  const showClearIcon = onClear && value?.length > 0
  return (
    <Input
      icon={showClearIcon ? clearIcon : searchIcon}
      iconPosition={showClearIcon ? null : 'left'}
      {...inputProps}
    />
  )
}
