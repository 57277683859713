import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Grid, Segment } from 'semantic-ui-react'

import { MoovyLink } from '../../../../components'
import messageCenterService from '../../../../services/MessageCenter'

const SelectedTargetGroups = ({
  selectedTargetGroups,
  unselectTargetGroup,
  readOnly
}) => {
  const [totalCount, setTotalCount] = useState(0)

  const { mutate: groupsCount, ...groupsCountMutation } = useMutation({
    mutationFn: (targetRefs) =>
      messageCenterService.fetchTargetUserCount(targetRefs),
    onSuccess: (response) => {
      setTotalCount(response.totalCount)
    },
    onError: () => {
      setTotalCount(0)
    }
  })

  const updateCount = () => {
    if (selectedTargetGroups && selectedTargetGroups.length > 0) {
      groupsCount(selectedTargetGroups.map((item) => item.ref))
    } else {
      setTotalCount(0)
    }
  }

  return (
    <Segment secondary loading={groupsCountMutation.isPending}>
      <FontAwesomeIcon
        icon="bullseye-arrow"
        size="2x"
        style={{ verticalAlign: 'middle', marginRight: '10px' }}
      />
      <b>
        <FormattedMessage id="messageCenter.targetGroup.selector.segment.label.selectedTargetGroups" />
      </b>
      <ul>
        {selectedTargetGroups &&
          selectedTargetGroups.map((item) => (
            <li key={item.ref}>
              <Grid>
                <Grid.Column floated="left" width={8}>
                  {item.name}
                </Grid.Column>
                <Grid.Column floated="right" width={8} textAlign="right">
                  {!readOnly && (
                    <MoovyLink
                      deleteLink
                      onClick={() => unselectTargetGroup(item.ref)}
                    >
                      <FormattedMessage id="messageCenter.targetGroup.selector.segment.link.unselect" />
                    </MoovyLink>
                  )}
                </Grid.Column>
              </Grid>
            </li>
          ))}
      </ul>
      <Grid>
        <Grid.Column floated="left" width={8} textAlign="left">
          <b>{totalCount}</b>{' '}
          <FormattedMessage id="messageCenter.targetGroup.selector.segment.label.potentialCustomers" />
        </Grid.Column>
        <Grid.Column
          floated="right"
          width={8}
          textAlign="right"
          verticalAlign="middle"
        >
          <MoovyLink bold onClick={updateCount}>
            <FormattedMessage id="messageCenter.targetGroup.selector.segment.link.updateUserCount" />
          </MoovyLink>
        </Grid.Column>
      </Grid>
    </Segment>
  )
}

export default SelectedTargetGroups
