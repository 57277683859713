import React from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'semantic-ui-react'

export const PushMessageContent = ({
  formValues,
  onChangeValue,
  readOnly,
  errors,
  prefix
}) => {
  const intl = useIntl()

  return (
    <>
      <Form.Input
        readOnly={readOnly}
        label={intl.formatMessage({
          id: 'messageCenter.createMessage.step.sendingDetails.push.label.title'
        })}
        type="text"
        name={prefix + '.title'}
        value={formValues[prefix].title ?? ''}
        onChange={(event, data) => {
          onChangeValue(event, data)
        }}
        error={errors && errors[prefix] && !formValues[prefix].title}
      ></Form.Input>
      <Form.Input
        readOnly={readOnly}
        label={intl.formatMessage({
          id:
            'messageCenter.createMessage.step.sendingDetails.push.label.message'
        })}
        type="text"
        name={prefix + '.description'}
        value={formValues[prefix].description ?? ''}
        onChange={(event, data) => {
          onChangeValue(event, data)
        }}
        error={errors && errors[prefix] && !formValues[prefix].description}
      ></Form.Input>
    </>
  )
}

export default PushMessageContent
