import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Grid, List } from 'semantic-ui-react'

import { formatDateAndTime } from '../../services/utils'

const ServiceTariffDetails = ({ servicePricing }) => {
  return (
    <>
      {servicePricing && (
        <Grid columns="2" stackable style={{ maxWidth: '1200px' }}>
          <Grid.Column width={5}>
            <List>
              <List.Item>
                <b>
                  <FormattedMessage id="serviceTariff.toolbar.label.startTime" />
                </b>{' '}
                {formatDateAndTime(servicePricing.startTime)}
              </List.Item>
              <List.Item>
                <b>
                  <FormattedMessage id="serviceTariff.toolbar.label.endTime" />
                </b>{' '}
                {(servicePricing.endTime &&
                  formatDateAndTime(servicePricing.endTime)) ||
                  '-'}
              </List.Item>
              <List.Item>
                <b>
                  <FormattedMessage id="serviceTariff.toolbar.label.periodicFeed" />
                </b>{' '}
                {servicePricing.service && servicePricing.periodicFee} €/kk
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column width={11}>
            <List.Item>
              <b>
                <FormattedMessage id="serviceTariff.toolbar.label.serviceName" />
              </b>{' '}
              {servicePricing.service && servicePricing.service.name}
            </List.Item>
            <List.Item>
              <b>
                <FormattedMessage id="serviceTariff.toolbar.label.serviceRef" />
              </b>{' '}
              {servicePricing.service && servicePricing.service.ref}
            </List.Item>
          </Grid.Column>
        </Grid>
      )}
    </>
  )
}

export default ServiceTariffDetails
