import React from 'react'
import { Header } from 'semantic-ui-react'

import { formatPrice } from '../../services/utils'

export default ({ parkings }) => (
  <div style={{ marginTop: '1em' }}>
    <Header size="small">
      {`Tapahtumien hintojen summa ${formatPrice(
        parkings.data.content
          .filter((parking) => parking.price)
          .map((parking) => parking.price)
          .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      )}`}
    </Header>
  </div>
)
