import React from 'react'
import { useIntl } from 'react-intl'

import Toolbar from '../../components/Toolbar'
import { createBreadcrumbs } from '../../components/Toolbar/helpers'
import MoneyTransfersContent from './moneyTransfersContent'
import MoneyTransfersToolbar from './moneyTransfersToolbar'

const MoneyTransfers = () => {
  const intl = useIntl()

  const breadcrumbs = createBreadcrumbs([
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.home'
      }),
      href: '/'
    },
    {
      text: intl.formatMessage({
        id: 'common.breadcrumbs.moneyTransfer'
      }),
      active: true
    }
  ])

  return (
    <>
      <Toolbar
        title={intl.formatMessage({
          id: 'common.breadcrumbs.moneyTransfer'
        })}
        breadcrumbs={breadcrumbs}
        content={<MoneyTransfersToolbar />}
      />
      <div className="Admin--Page--Content">
        <MoneyTransfersContent />
      </div>
    </>
  )
}

export default MoneyTransfers
