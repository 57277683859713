import { Formik } from 'formik'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Button, Form } from 'semantic-ui-react'
import * as yup from 'yup'

import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import messageCenterService from '../../../../services/MessageCenter'
import { isValidPhoneNumber } from '../../../../services/utils'
import { messageCenterEnums } from '../../../../services/utils/DTOEnums'

export const PhoneNumberSelector = ({ phoneGroupData }) => {
  const intl = useIntl()
  const validationSchema = () => {
    const validatePhoneNumber = (phoneNumber) => {
      return phoneNumber && isValidPhoneNumber(phoneNumber)
    }

    return yup.object().shape({
      targetGroupName: yup.string().required('Cannot be empty'),
      phoneNumbers: yup
        .string()
        .test(
          'InvalidPhoneNumber',
          'Phone number is not valid',
          function (phoneNumbers) {
            if (!phoneNumbers) return false
            const phoneNumberArray = phoneNumbers.split(',')
            const invalidNumber = phoneNumberArray.find(
              (item) => !validatePhoneNumber(item.trim())
            )
            return !invalidNumber
          }
        )
    })
  }

  const { mutate: saveTarget, ...saveTargetMutation } = useMutation({
    mutationFn: (submitContent) =>
      messageCenterService.saveTarget(null, submitContent.target),
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'messageCenter.targetGroup.phoneNumbers.message.success.title',
        description:
          'messageCenter.targetGroup.phoneNumbers.message.success.description',
        values: {
          targetGroupName: response.name
        }
      })
      const { resetForm } = variables
      resetForm({
        values: {
          targetGroupName: '',
          phoneNumbers: ''
        }
      })
    },
    onError: (error) => {
      if (
        error &&
        error.body &&
        error.body.errorDescriptor &&
        error.body.errorDescriptor.errorType === 'DUPLICATE_REQUEST'
      ) {
        showLocalizedMoovyToast(intl, {
          title: 'messageCenter.targetGroup.action.error.create.title',
          description:
            'messageCenter.targetGroup.action.error.create.targetExist.body',
          type: 'error'
        })
      } else {
        showLocalizedMoovyToast(intl, {
          title: 'messageCenter.targetGroup.action.error.create.title',
          description: 'messageCenter.targetGroup.action.error.create.body',
          type: 'error'
        })
      }
    }
  })

  const onSubmit = (values, { resetForm }) => {
    const submitContent = {
      target: {
        targetType: messageCenterEnums.targetType.PHONENUMBERS,
        name: values.targetGroupName,
        value: values.phoneNumbers
      },
      resetForm: resetForm
    }

    saveTarget(submitContent)
  }

  const getInitialValues = () => {
    if (phoneGroupData) {
      return {
        targetGroupName: phoneGroupData.name || '',
        phoneNumbers: phoneGroupData.value || ''
      }
    } else {
      return {
        targetGroupName: '',
        phoneNumbers: ''
      }
    }
  }

  return (
    <Formik
      initialValues={getInitialValues()}
      validationSchema={validationSchema()}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        handleSubmit,
        setFieldValue,
        handleBlur,
        handleChange
      }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Input
            name="targetGroupName"
            label={intl.formatMessage({
              id: 'messageCenter.targetGroup.phoneNumbers.label.targetGroupName'
            })}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.targetGroupName}
            error={!!errors.targetGroupName}
          ></Form.Input>
          <Form.TextArea
            label={intl.formatMessage({
              id: 'messageCenter.targetGroup.phoneNumbers.label.fillPhoneNumbers'
            })}
            name="phoneNumbers"
            onChange={(event, data) => {
              setFieldValue(data.name, data.value)
            }}
            onBlur={handleBlur}
            error={!!errors.phoneNumbers}
            value={values.phoneNumbers}
          />
          <Button
            primary
            type="submit"
            loading={saveTargetMutation.isPending}
            disabled={saveTargetMutation.isPending}
          >
            <FormattedMessage id="messageCenter.targetGroup.button.createTargetGroup" />
          </Button>
        </Form>
      )}
    </Formik>
  )
}
export default PhoneNumberSelector
