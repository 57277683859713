import PropTypes from 'prop-types'

export default {
  shape: {
    type: PropTypes.string,

    ovt: PropTypes.string,
    operator: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    streetAddress: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    company: PropTypes.string,
    userRef: PropTypes.string
  },
  PAPER: 'PAPER',
  EMAIL: 'EMAIL',
  DIRECT: 'DIRECT'
}
