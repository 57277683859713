import { get } from '../utils/api-renewed'

const createFullPath = (relativePath, sessionRef, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/sessions/${sessionRef}/parkingMetadata/${relativePath}`
    : `/administration/fetch-parking-metadata/${relativePath}`

const fetchExternalFacilityData = async (ref, sessionRef, operatorRealm) => {
  const result = await get(createFullPath(`${ref}`, sessionRef, operatorRealm))
  return result
}

export default {
  fetchExternalFacilityData
}
