import { Formik } from 'formik'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { DateTimeInput } from 'semantic-ui-calendar-react'
import { Button, Form, Header, Tab } from 'semantic-ui-react'
import * as yup from 'yup'

import {
  convertIsoToDateTime,
  dateTimeFromFormat
} from '../../../services/utils'
import { STEP_KEYS } from './CreateMessage'
import PushMessageContent from './PushMessageContent'

export const StepSendingDetails = ({
  formValues,
  readOnly,
  loading,
  onDataCompleted,
  onChangeValue
}) => {
  const intl = useIntl()

  const validationSchema = () => {
    return yup.object().shape({
      publishTime: yup.string().required('Cannot be empty'),
      expirationTime: yup.string().required('Cannot be empty'),
      fi: yup
        .object()
        .json()
        .test('PushMessageContentMandatory', true, function () {
          if (!this.parent.pushMessage.send) {
            return true
          }

          if (!this.parent.pushMessage.content.fi) {
            return false
          }

          return (
            this.parent.pushMessage.content.fi.title &&
            this.parent.pushMessage.content.fi.description
          )
        })
    })
  }

  const onChange = (e, data) => {
    const { name, value } = data
    if (name === 'publishTime' || name === 'expirationTime') {
      updateValues(name, dateTimeFromFormat(value).toISO())
    } else {
      updateValues(name, value)
    }
  }

  const onChangePushMessage = (e, data) => {
    const { name, value } = data

    updateValues('pushMessage.content.' + name, value)
  }

  const onChecked = (name, value) => {
    updateValues(name, value)
  }

  const updateValues = (name, value) => {
    onChangeValue(STEP_KEYS.SENDINGDETAILS, name, value)
  }

  const onSubmitForm = () => {
    onDataCompleted(STEP_KEYS.SENDINGDETAILS)
  }

  const getPanes = (setFieldValue, readOnly, errors) => {
    return [
      {
        menuItem: intl.formatMessage({
          id: 'messageCenter.createMessage.step.tab.title.finnish'
        }),
        render: () => (
          <Tab.Pane>
            <PushMessageContent
              key="fiMessageContent"
              formValues={formValues.pushMessage.content}
              readOnly={readOnly}
              onChangeValue={(event, data) => {
                onChangePushMessage(event, data)
                setFieldValue(data.name, data.value)
              }}
              errors={errors}
              prefix={'fi'}
            ></PushMessageContent>
          </Tab.Pane>
        )
      },
      {
        menuItem: intl.formatMessage({
          id: 'messageCenter.createMessage.step.tab.title.english'
        }),
        render: () => (
          <Tab.Pane>
            <PushMessageContent
              key="enMessageContent"
              formValues={formValues.pushMessage.content}
              readOnly={readOnly}
              onChangeValue={(event, data) => {
                onChangePushMessage(event, data)
              }}
              prefix={'en'}
            ></PushMessageContent>
          </Tab.Pane>
        )
      },
      {
        menuItem: intl.formatMessage({
          id: 'messageCenter.createMessage.step.tab.title.swedish'
        }),
        render: () => (
          <Tab.Pane>
            <PushMessageContent
              key="svMessageContent"
              formValues={formValues.pushMessage.content}
              readOnly={readOnly}
              onChangeValue={(event, data) => {
                onChangePushMessage(event, data)
              }}
              prefix={'sv'}
            ></PushMessageContent>
          </Tab.Pane>
        )
      }
    ]
  }

  return (
    <>
      <Formik
        initialValues={formValues}
        validationSchema={validationSchema()}
        onSubmit={() => {
          onSubmitForm()
        }}
        enableReinitialize
      >
        {({ errors, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            {!readOnly && (
              <Header>
                <FormattedMessage id="messageCenter.createMessage.step.sendingDetails.header" />
              </Header>
            )}
            <DateTimeInput
              readOnly={readOnly}
              label={intl.formatMessage({
                id: 'messageCenter.createMessage.step.sendingDetails.label.publishTime'
              })}
              dateFormat="DD.MM.YYYY"
              placeholder={intl.formatMessage({
                id: 'messageCenter.createMessage.step.sendingDetails.label.publishTime'
              })}
              iconPosition="left"
              animation="none"
              name="publishTime"
              closable
              value={convertIsoToDateTime(formValues.publishTime)}
              onChange={(e, data) => {
                setFieldValue(data.name, data.value)
                onChange(e, data)
              }}
              error={!!errors.publishTime}
            />
            <DateTimeInput
              readOnly={readOnly}
              label={intl.formatMessage({
                id: 'messageCenter.createMessage.step.sendingDetails.label.expirationTime'
              })}
              dateFormat="DD.MM.YYYY"
              placeholder={intl.formatMessage({
                id: 'messageCenter.createMessage.step.sendingDetails.label.expirationTime'
              })}
              iconPosition="left"
              animation="none"
              name="expirationTime"
              closable
              value={convertIsoToDateTime(formValues.expirationTime)}
              onChange={(e, data) => {
                setFieldValue(data.name, data.value)
                onChange(e, data)
              }}
              error={!!errors.expirationTime}
            />
            <Form.Field>
              <label>
                <FormattedMessage id="messageCenter.createMessage.step.sendingDetails.label.publishMethod" />
              </label>
              <Form.Radio
                readOnly={readOnly}
                label={intl.formatMessage({
                  id: 'messageCenter.createMessage.step.sendingDetails.label.publishOnlyInMsgCenter'
                })}
                checked={!formValues.pushMessage.send}
                onChange={(event, data) => {
                  onChecked('pushMessage.send', false)
                  setFieldValue('pushMessage.send', false)
                }}
              ></Form.Radio>
              <Form.Radio
                readOnly={readOnly}
                label={intl.formatMessage({
                  id: 'messageCenter.createMessage.step.sendingDetails.label.publishBothMsgCenterAndPush'
                })}
                checked={formValues.pushMessage.send}
                onChange={(event, data) => {
                  onChecked('pushMessage.send', true)
                  setFieldValue('pushMessage.send', true)
                }}
              ></Form.Radio>
            </Form.Field>
            {formValues.pushMessage.send ? (
              <Tab panes={getPanes(setFieldValue, readOnly, errors)} />
            ) : (
              ''
            )}
            {!readOnly && (
              <Button
                primary
                type="submit"
                loading={loading}
                disabled={loading}
              >
                <FormattedMessage id="messageCenter.createMessage.action.saveAndContinue" />
              </Button>
            )}
          </Form>
        )}
      </Formik>
    </>
  )
}

export default StepSendingDetails
