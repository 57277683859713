import PropTypes from 'prop-types'
import React from 'react'
import { Dimmer, Loader, Message } from 'semantic-ui-react'

const QueryContainer = ({ children, query, errorMessage }) => {
  return (
    <>
      {query.error && <Message error>{errorMessage}</Message>}
      <Dimmer.Dimmable dimmed={query.isLoading}>
        <Dimmer active={query.isLoading} inverted>
          <Loader />
        </Dimmer>
        {children}
      </Dimmer.Dimmable>
    </>
  )
}

QueryContainer.defaultProps = {
  errorMessage: 'Tietojen lataamisessa tapahtui virhe.'
}

QueryContainer.propTypes = {
  query: PropTypes.shape().isRequired,
  children: PropTypes.element.isRequired,
  errorMessage: PropTypes.string
}

export default QueryContainer
