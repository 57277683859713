import AddAgreementModal, { SERVICE_AGREEMENT } from './AddAgreementModal'
import { Grid, Icon, Loader, Message, Tab, Table } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyButton, MoovyDeleteButton, MoovyLink } from '../../components'
import React, { useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import EditServiceAgreement from './EditServiceAgreement'
import serviceAgreementService from '../../services/ServiceAgreements'
import servicesService from '../../services/Services'
import useAuthInfo from '../../hooks/useAuthInfo'
import userGroupService from '../../services/UserGroups'

const ServiceAgreementTable = ({
  serviceAgreements = [],
  onDelete,
  mutation,
  allowEdit
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="serviceAgreementTable.header.product" />
          </Table.HeaderCell>
          <Table.HeaderCell singleLine>
            <FormattedMessage id="serviceAgreementTable.header.maxCount" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="serviceAgreementTable.header.discounts" />
          </Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {serviceAgreements.map((a) => (
          <Table.Row key={a.ref}>
            <Table.Cell>{a.service.name}</Table.Cell>
            <Table.Cell>
              {a.maxCount !== undefined
                ? a.maxCount
                : intl.formatMessage({
                    id: 'serviceAgreementTable.table.maxCount.undefined'
                  })}
            </Table.Cell>
            <Table.Cell>
              {a.profileItems && a.profileItems.length
                ? a.profileItems.map((p) => `${p.percentDiscount} %`).join(', ')
                : intl.formatMessage({
                    id: 'serviceAgreementTable.table.discounts.undefined'
                  })}
            </Table.Cell>
            <Table.Cell textAlign="right">
              <MoovyLink
                bold
                onClick={() => navigate(`serviceAgreements/${a.ref}`)}
              >
                <FormattedMessage id="common.button.modify" />
              </MoovyLink>
              {allowEdit && (
                <MoovyDeleteButton
                  confirmText={intl.formatMessage(
                    { id: 'serviceAgreementTable.table.delete.confirm' },
                    { name: a.service.name }
                  )}
                  onConfirm={() => {
                    onDelete(a.ref)
                  }}
                  loading={mutation.isPending}
                />
              )}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}

export default function ServiceAgreementsTab({ userGroup, allowEdit }) {
  const { operatorRealm, isSuperOperator } = useAuthInfo()
  const queryClient = useQueryClient()
  const [addModalOpen, setAddModalOpen] = useState(false)
  const services = useQuery({
    queryKey: ['subscriptionServices'],
    queryFn: () => servicesService.fetchSubscriptionServices(operatorRealm)
  })

  const queryKey = 'serviceAgreements'
  const serviceAgreements = useQuery({
    queryKey: [queryKey, userGroup.ref],
    queryFn: () =>
      userGroupService.fetchServiceAgreements(userGroup.ref, operatorRealm)
  })

  const { mutate: addAgreement, ...addAgreementMutation } = useMutation({
    mutationFn: (serviceRef) =>
      userGroupService.addServiceAgreement(
        serviceRef,
        userGroup.ref,
        operatorRealm
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey, userGroup.ref] })
      setAddModalOpen(false)
    }
  })

  const { mutate: deleteAgreement, ...deleteAgreementMutation } = useMutation({
    mutationFn: (serviceAgreementRef) =>
      serviceAgreementService.deleteServiceAgreement(
        serviceAgreementRef,
        operatorRealm
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryKey, userGroup.ref] })
    }
  })

  let serviceOptions = services.data
    ? services.data
        .filter(
          (service) =>
            !isSuperOperator ||
            service?.operator?.ref === userGroup?.operator?.ref // No need to filter the operator response, superOperator returns all
        )
        .map((service) => ({
          key: service.ref,
          value: service.ref,
          text: service.name
        }))
    : []

  if (serviceAgreements.data) {
    const refs = serviceAgreements.data.map((sa) => sa.service.ref)
    serviceOptions = serviceOptions.filter((o) => !refs.includes(o.key))
  }

  return (
    <Tab.Pane>
      <Routes>
        <Route
          path={`serviceAgreements/:id`}
          element={
            <EditServiceAgreement
              onUpdate={() =>
                queryClient.invalidateQueries({
                  queryKey: [queryKey, userGroup.ref]
                })
              }
            />
          }
        ></Route>
        <Route
          path="*"
          element={
            <Grid>
              {allowEdit && (
                <Grid.Row textAlign="right">
                  <Grid.Column>
                    <MoovyButton onClick={() => setAddModalOpen(true)}>
                      <Icon name="plus" />
                      <FormattedMessage id="serviceAgreementsTab.button.new" />
                    </MoovyButton>
                  </Grid.Column>
                  <AddAgreementModal
                    open={addModalOpen}
                    onClose={() => setAddModalOpen(false)}
                    onSubmit={addAgreement}
                    mutation={addAgreementMutation}
                    options={serviceOptions}
                    type={SERVICE_AGREEMENT}
                  />
                </Grid.Row>
              )}
              <Grid.Row>
                <Grid.Column>
                  {serviceAgreements.isError && (
                    <Message error>
                      <FormattedMessage id="serviceAgreementsTab.query.error" />
                    </Message>
                  )}
                  {serviceAgreements.isLoading ? (
                    <Loader active inline="centered" />
                  ) : (
                    <>
                      {deleteAgreementMutation.isError && (
                        <Message error>
                          <FormattedMessage id="serviceAgreementsTab.delete.error" />
                        </Message>
                      )}
                      <ServiceAgreementTable
                        serviceAgreements={serviceAgreements.data}
                        allowEdit={allowEdit}
                        onDelete={deleteAgreement}
                        mutation={deleteAgreementMutation}
                      />
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          }
        ></Route>
      </Routes>
    </Tab.Pane>
  )
}
