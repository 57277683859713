import { Button, Form, Icon, Search } from 'semantic-ui-react'
import React, { useState } from 'react'
import {
  getStringValue,
  adyenAccountHolderTopMainStatusEnum as mainStatus
} from '../../services/utils/DTOEnums'

import settlementRecipientService from '../../services/SettlementRecipients'
import { useDebounce } from 'use-debounce'
import { useIntl } from 'react-intl'
import { useQuery } from '@tanstack/react-query'

const MoneyAccountInput = ({
  setFieldValue,
  errors,
  moneyFromPlatform,
  selectedItem,
  setSelectedItem
}) => {
  const intl = useIntl()

  const [settlementRecipientSearchValue, setSettlementRecipientSearchValue] =
    useState('')
  const [settlementRecipientSearchDebounce] = useDebounce(
    settlementRecipientSearchValue,
    750
  )

  const settlementRecipientQuery = useQuery({
    queryKey: ['searchSettlementRecipient', settlementRecipientSearchDebounce],
    queryFn: () =>
      settlementRecipientService.fetchAllSettlementRecipientsByFilter({
        page: 0,
        limit: 10,
        sort: 'sortByName',
        partialName: settlementRecipientSearchDebounce
      }),
    enabled: settlementRecipientSearchDebounce?.trim()?.length > 2
  })

  const settlementRecipients = settlementRecipientQuery.data?.data
    ? settlementRecipientQuery.data?.data
        .map((item) => {
          return {
            title: item.fullName,
            description:
              intl.formatMessage({
                id: 'settlementRecipients.settlementRecipientTable.header.adyenStatus'
              }) +
              ': ' +
              intl.formatMessage({
                id: getStringValue(
                  mainStatus.localizationKeys,
                  item.adyenMainStatus
                )
              }),
            ref: item.ref,
            key: item.ref
          }
        })
        .filter((item) => selectedItem?.ref !== item.ref)
    : []

  return (
    <Form.Field error={!!errors.accountRef}>
      <label>
        {intl.formatMessage(
          (!moneyFromPlatform && {
            id: 'moneyTransfersContent.form.label.moneySource'
          }) || {
            id: 'moneyTransfersContent.form.label.moneyTarget'
          }
        )}
      </label>
      <Search
        minCharacters={3}
        input={{
          fluid: true,
          icon: 'search',
          iconPosition: 'left',
          placeholder: intl.formatMessage(
            (moneyFromPlatform && {
              id: 'moneyTransfersContent.form.settlementRecipient.placeholder'
            }) || {
              id: 'moneyTransfersContent.form.payer.placeholder'
            }
          ),
          loading: settlementRecipientQuery.isFetching,
          value: settlementRecipientSearchValue
        }}
        showNoResults={!settlementRecipientQuery.isFetching}
        noResultsMessage={
          settlementRecipientQuery.isFetching
            ? intl.formatMessage({
                id: 'moneyTransfersContent.form.settlementRecipient.fetching'
              })
            : intl.formatMessage({
                id: 'moneyTransfersContent.form.settlementRecipient.noSearchResults'
              })
        }
        onSearchChange={(event, data) => {
          setSettlementRecipientSearchValue(data.value)
        }}
        onResultSelect={(e, data) => {
          setSelectedItem(data.result)
          setSettlementRecipientSearchValue('')
          setFieldValue('accountRef', data.result.ref)
        }}
        results={settlementRecipients}
      />
      {(selectedItem && (
        <Form.Field>
          <Button
            style={{ marginTop: '1em' }}
            icon
            size="mini"
            labelPosition="right"
            onClick={() => {
              setFieldValue('accountRef', '')
              setSelectedItem('')
            }}
          >
            {selectedItem.title}
            <Icon name="close" />
          </Button>
        </Form.Field>
      )) ||
        ''}
    </Form.Field>
  )
}

export default MoneyAccountInput
