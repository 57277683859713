import * as yup from 'yup'

import { validateEmail } from '../../../services/utils'

export const validationSchema = () => {
  return yup.object().shape({
    phone: yup.string().required('Cannot be empty'),
    email: yup
      .string()
      .test('validEmailAddress', 'Check if email is valid', function(email) {
        return !email || validateEmail(email)
      })
  })
}
