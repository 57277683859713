import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import { Button, Comment, Confirm, Form } from 'semantic-ui-react'

import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import useAuthInfo from '../../hooks/useAuthInfo'
import {
  addConversationToUser,
  removeConversation
} from '../../services/Conversation'
import { formatDateAndTime, formatTime } from '../../services/utils'

const ConversationHistory = ({
  customerRef,
  conversations,
  refetchConversations
}) => {
  const intl = useIntl()
  const { operatorRealm } = useAuthInfo()
  const [comment, setComment] = useState('')
  const [confirmOpen, setConfirmOpen] = useState('')

  const { mutate: addConversation, ...addConversationMutation } = useMutation({
    mutationFn: (comment) =>
      addConversationToUser(operatorRealm, customerRef, comment),
    onSuccess: (response) => {
      setComment('')
      refetchConversations()
    },
    onError: () => {
      showLocalizedMoovyToast(intl, {
        title: 'conversationHistory.action.addComment.error.title',
        description: 'conversationHistory.action.addComment.error.description',
        type: 'error'
      })
    }
  })

  const { mutate: removeComment, ...removeCommentMutation } = useMutation({
    mutationFn: (conversationRef) =>
      removeConversation(operatorRealm, customerRef, conversationRef),
    onSuccess: (response) => {
      setConfirmOpen('')
      refetchConversations()
    },
    onError: () => {
      setConfirmOpen('')
      showLocalizedMoovyToast(intl, {
        title: 'conversationHistory.action.remove.error.title',
        description: 'conversationHistory.action.remove.error.description',
        type: 'error'
      })
    }
  })

  const onAddComment = () => {
    if (comment) {
      addConversation(comment)
    }
  }

  const formattedDateTime = (utcDatetime) => {
    if (utcDatetime) {
      const dateTimeLocal = DateTime.fromISO(utcDatetime)
      if (dateTimeLocal) {
        const diff = DateTime.local()
          .startOf('day')
          .diff(dateTimeLocal, ['months', 'days', 'hours'])
          .toObject()

        if (diff.months === 0 && diff.days === 0 && diff.hours >= 0) {
          return (
            intl.formatMessage({
              id: 'conversationHistory.conversation.yesterday'
            }) +
            ' ' +
            formatTime(dateTimeLocal)
          )
        } else if (diff.months === 0 && diff.days === 0) {
          return (
            intl.formatMessage({
              id: 'conversationHistory.conversation.today'
            }) +
            ' ' +
            formatTime(dateTimeLocal)
          )
        } else {
          return formatDateAndTime(utcDatetime)
        }
      }
    }
  }

  return (
    <>
      <Comment.Group>
        {conversations &&
          conversations.map((item) => (
            <Comment key={item.ref}>
              <Comment.Content>
                <Comment.Author as="a">
                  {item.modifiedByUser.name}
                </Comment.Author>
                <Comment.Metadata>
                  <div>{formattedDateTime(item.creationTime)}</div>
                </Comment.Metadata>
                <Comment.Text style={{ whiteSpace: 'pre-wrap' }}>
                  {item.comment}
                </Comment.Text>
                <Comment.Actions>
                  <Comment.Action onClick={() => setConfirmOpen(item.ref)}>
                    <FormattedMessage id="common.button.delete" />
                  </Comment.Action>
                </Comment.Actions>
              </Comment.Content>
            </Comment>
          ))}
        <Form reply>
          <Form.TextArea
            value={comment}
            onChange={(event, data) => {
              setComment(data.value)
            }}
          />
          <Button
            onClick={() => onAddComment()}
            content={intl.formatMessage({
              id: 'conversationHistory.action.addComment'
            })}
            primary
            loading={
              addConversationMutation.isPending ||
              removeCommentMutation.isPending
            }
            disabled={
              addConversationMutation.isPending ||
              removeCommentMutation.isPending
            }
          />
        </Form>
      </Comment.Group>
      <Confirm
        open={confirmOpen ? true : false}
        onCancel={() => setConfirmOpen('')}
        onConfirm={() =>
          !removeCommentMutation.isPending && removeComment(confirmOpen)
        }
        content={intl.formatMessage({
          id: 'conversationHistory.confirm.remove'
        })}
        cancelButton={intl.formatMessage({
          id: 'common.button.cancel'
        })}
        confirmButton={intl.formatMessage({
          id: 'common.button.confirm'
        })}
      />
    </>
  )
}

export default ConversationHistory
