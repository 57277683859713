import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Icon, Popup, Table } from 'semantic-ui-react'

import MoovyLink from '../../components/MoovyLink'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import TablePagination from '../../components/TablePagination'
import {
  formatDateAndTime,
  getSortDirection,
  sortColumn
} from '../../services/utils'
import { messageCenterEnums } from '../../services/utils/DTOEnums'
import ModalMessagePreview from './ModalMessagePreview'

const MessageCenterTable = ({
  messages,
  sorting,
  activePage,
  onSort,
  onActivePageChanged
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [showMessageByRef, setShowMessageByRef] = useState('')

  const renderTarget = (targets) => {
    if (!targets) return

    return (
      (targets.length > 1 && (
        <Popup
          trigger={
            <MoovyLink bold>
              <FormattedMessage id="messageCenter.table.label.multipleGroups" />
            </MoovyLink>
          }
          content={targets.map((item) => (
            <div key={item.ref}>{item.name}</div>
          ))}
          position="bottom center"
        />
      )) ||
      targets.map((item) => item.name)
    )
  }

  return (
    <>
      <Table sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell
              sorted={getSortDirection('sortByStatus', sorting)}
              onClick={() => sortColumn('sortByStatus', sorting, onSort)}
            >
              <FormattedMessage id="messageCenter.table.header.status" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('sortByModificationTime', sorting)}
              onClick={() =>
                sortColumn('sortByModificationTime', sorting, onSort)
              }
            >
              <FormattedMessage id="messageCenter.table.header.updated" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('sortByPublishTime', sorting)}
              onClick={() => sortColumn('sortByPublishTime', sorting, onSort)}
            >
              <FormattedMessage id="messageCenter.table.header.publishTime" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('sortByExpirationTime', sorting)}
              onClick={() =>
                sortColumn('sortByExpirationTime', sorting, onSort)
              }
            >
              <FormattedMessage id="messageCenter.table.header.expiredTime" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('sortByDeletionTime', sorting)}
              onClick={() => sortColumn('sortByDeletionTime', sorting, onSort)}
            >
              <FormattedMessage id="messageCenter.table.header.deletionTime" />
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={getSortDirection('sortByPushMessage', sorting)}
              onClick={() => sortColumn('sortByPushMessage', sorting, onSort)}
            >
              <FormattedMessage id="messageCenter.table.header.pushMessage" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="messageCenter.table.header.targetGroup" />
            </Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        {messages && (
          <>
            <Table.Body>
              {messages.data &&
                messages.data.map((message) => {
                  return (
                    <Table.Row key={message.ref}>
                      <Table.Cell>
                        {
                          messageCenterEnums.messageStatus.stringValues[
                            message.status
                          ]
                        }
                      </Table.Cell>
                      <Table.Cell>
                        {formatDateAndTime(message.modificationTime)}
                      </Table.Cell>
                      <Table.Cell>
                        {formatDateAndTime(message.publishTime)}
                      </Table.Cell>
                      <Table.Cell>
                        {formatDateAndTime(message.expirationTime)}
                      </Table.Cell>
                      <Table.Cell>
                        {formatDateAndTime(message.deletionTime)}
                      </Table.Cell>
                      <Table.Cell>
                        {(message.pushMessage && (
                          <FormattedMessage id="common.yes" />
                        )) || <FormattedMessage id="common.no" />}
                      </Table.Cell>
                      <Table.Cell>{renderTarget(message.targets)}</Table.Cell>
                      <Table.Cell collapsing>
                        {/* TODO Antti: This is coming in future but not in July 2021
                        <MoovyLink
                        onClick={() =>
                          console.log('Implementation is missing.')
                        }
                      >
                        <FormattedMessage id="messageCenter.action.results" />
                      </MoovyLink>*/}
                        {(message.status !==
                          messageCenterEnums.messageStatus.PUBLISHED && (
                          <Link to={`edit/${message.ref}`}>
                            <Button primary style={{ marginLeft: '10px' }}>
                              <Icon name="pencil" />
                              <FormattedMessage id="common.button.modify" />
                            </Button>
                          </Link>
                        )) || (
                          <MoovyLink
                            bold
                            onClick={() => {
                              setShowMessageByRef(message.ref)
                            }}
                          >
                            <FormattedMessage id="messageCenter.table.column.link.show" />
                          </MoovyLink>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
            </Table.Body>
            <TablePagination
              colSpan={8}
              activePage={activePage}
              totalPages={messages.totalPages || 0}
              onPageChange={(e, { activePage: page }) =>
                onActivePageChanged(page)
              }
            />
          </>
        )}
      </Table>
      <ModalMessagePreview
        messageRef={showMessageByRef}
        open={!!showMessageByRef}
        onClose={() => {
          setShowMessageByRef('')
        }}
        onMessageCloned={(messageRef) => {
          showLocalizedMoovyToast(intl, {
            title: 'messageCenter.table.toast.copy.title',
            description: 'messageCenter.table.toast.copy.body'
          })
          navigate(`/admin/messageCenter/edit/${messageRef}`)
        }}
      />
    </>
  )
}

export default MessageCenterTable
