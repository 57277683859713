import { get, post } from '../utils/api-renewed'

import { sale } from '../../services/utils'

const createFullPath = (relativePath, operatorRealm) =>
  operatorRealm
    ? `/operator/${operatorRealm}/invoicing/${relativePath}`
    : `/administration/invoicing/${relativePath}`

const fetchUserInvoices = async (userRef, operatorRealm, queryParams) => {
  return await get(
    createFullPath(`${userRef}/invoices`, operatorRealm),
    queryParams
  )
}

const fetchInvoiceRows = async (invoiceRef, operatorRealm) => {
  if (invoiceRef) {
    try {
      const result = await get(
        createFullPath(`${invoiceRef}/invoiceRows`, operatorRealm)
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(Error('Missing invoiceRef for fetching invoiceRows'))
  }
}

const createCreditNote = async (invoiceRef, operatorRealm) => {
  if (invoiceRef) {
    try {
      await post(
        createFullPath(`creditNote/invoice/${invoiceRef}`, operatorRealm)
      )
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(Error('Missing invoiceRef for creating creditNote'))
  }
}

const createCustomInvoice = async (userRef, invoiceRows, operatorRealm) => {
  if (
    userRef &&
    invoiceRows &&
    Array.isArray(invoiceRows) &&
    invoiceRows.length > 0
  ) {
    try {
      await post(
        createFullPath(`customInvoice/debtor/${userRef}`, operatorRealm),
        invoiceRows
      )
      return Promise.resolve()
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(
      Error('Missing parameters for creating customInvoice')
    )
  }
}

const retryCardPayment = async (invoiceRef, operatorRealm) => {
  if (invoiceRef) {
    const res = await post(
      createFullPath(`${invoiceRef}/retryCardPayment`, operatorRealm)
    )

    // If card payment is moved from PH to Adyen, the result is handled in this way
    // that the PH modal is able to switch from the PH modal to the Adyen modal
    if (res?.paymentMethodType === sale.paymentMethodTypes.ADYEN_TRANSACTION) {
      return res
    }

    if (res.status !== 'INVOICED') {
      throw `Payment failed, returned status: ${res.status}`
    }
    return Promise.resolve()
  } else {
    return Promise.reject(Error('Missing invoiceRef for retrying card payment'))
  }
}

const refundCardPayment = async (invoiceRef, body, operatorRealm) => {
  if (invoiceRef) {
    try {
      return await post(
        createFullPath(`${invoiceRef}/cardPaymentRevert`, operatorRealm),
        { ...body }
      )
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(
      Error('Missing invoiceRef for reverting card payment')
    )
  }
}

const revokeDebtCollectionAndCreateNewInvoice = async (
  invoiceRef,
  invoiceRowRefs,
  reason,
  operatorRealm
) => {
  if (invoiceRef) {
    try {
      const result = await post(
        createFullPath(
          `${invoiceRef}/revokeDebtCollectionAndCreateNew`,
          operatorRealm
        ),
        {
          invoiceRowsToReplace: invoiceRowRefs,
          cancellationReason: reason
        }
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(
      Error('Missing invoiceRef for revoking debt collection.')
    )
  }
}

const revokeDebtCollection = async (invoiceRef, reason, operatorRealm) => {
  if (invoiceRef) {
    try {
      const result = await post(
        createFullPath(`${invoiceRef}/revokeDebtCollection`, operatorRealm),
        { reason }
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(
      Error('Missing invoiceRef for revoking debt collection.')
    )
  }
}

const listCardPaymentRevertableAmounts = async (invoiceRef, operatorRealm) => {
  if (invoiceRef) {
    try {
      const result = await get(
        createFullPath(`${invoiceRef}/revertableAmounts`, operatorRealm),
        {}
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(
      Error('Missing invoiceRef for listing card payment revertable amounts')
    )
  }
}

const listCardPaymentReverts = async (invoiceRef, operatorRealm) => {
  if (invoiceRef) {
    try {
      const result = await get(
        createFullPath(`${invoiceRef}/cardPaymentRevert`, operatorRealm),
        {}
      )
      return result
    } catch (e) {
      return Promise.reject(Error(e))
    }
  } else {
    return Promise.reject(
      Error('Missing invoiceRef for listing card payment reverts')
    )
  }
}

const sendInvoiceReceipt = async (invoiceRef, email) => {
  if (invoiceRef && email) {
    const result = await post(
      '/administration/v1/messaging/sendInvoiceReceipt',
      { invoiceRef, email }
    )
    return result
  } else {
    return Promise.reject(
      Error('InvoiceRef and email address required for receipt sending')
    )
  }
}

const fetchInvoices = async (queryValues) => {
  try {
    const result = await get(createFullPath(`invoices`, null), queryValues)
    return result
  } catch (e) {
    return Promise.reject(Error(e))
  }
}

const fetchInvoice = async (invoiceRef, operatorRealm) => {
  if (operatorRealm) {
    const result = await get(
      `/operator/${operatorRealm}/invoicing/vismaInvoices/${invoiceRef}`
    )
    return result
  } else {
    const result = await get(createFullPath(`invoices/${invoiceRef}`, null))
    return result
  }
}

const setCardPaymentAsWaived = async (invoiceRef, comment, operatorRealm) => {
  const result = await post(
    createFullPath(`${invoiceRef}/waive`, operatorRealm),
    { userComment: comment }
  )
  return result
}

export default {
  fetchUserInvoices,
  fetchInvoiceRows,
  createCreditNote,
  createCustomInvoice,
  retryCardPayment,
  refundCardPayment,
  listCardPaymentRevertableAmounts,
  listCardPaymentReverts,
  sendInvoiceReceipt,
  fetchInvoices,
  fetchInvoice,
  revokeDebtCollectionAndCreateNewInvoice,
  revokeDebtCollection,
  setCardPaymentAsWaived
}
