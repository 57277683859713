import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import useAuthInfo from '../../hooks/useAuthInfo'

function fullPath(isSuperOperator, to) {
  const withoutForwardSlash =
    typeof to === 'string' && to.startsWith('/') ? to.slice(1) : to
  return isSuperOperator
    ? `/admin/${withoutForwardSlash}`
    : `/operator/${withoutForwardSlash}`
}
const RelativeLinkForCurrentUser = ({ to, state = undefined, children }) => {
  const { isSuperOperator } = useAuthInfo()
  return (
    <Link to={fullPath(isSuperOperator, to)} state={state}>
      {children}
    </Link>
  )
}

RelativeLinkForCurrentUser.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default RelativeLinkForCurrentUser

export { fullPath }
