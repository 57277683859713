import '../Locations.scss'

import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, Icon, List, Popup } from 'semantic-ui-react'
import {
  LocationFeatureList,
  MoovyIconButton,
  MoovyIconInfo,
  MoovyLink
} from '../../../components'
import {
  PermissionLocation,
  formatSeconds,
  getFormattedLocationName,
  getStringValue,
  locationEnums
} from '../../../services/utils'
import React, { useState } from 'react'

import ModalEditAutoCleanParking from './ModalEditAutoCleanParking'
import ModalEditDoorCode from './ModalEditDoorCode'
import ModalEditExtensionInfo from './ModalEditExtensionInfo'
import MoovyActiveItem from '../../../containers/MoovyActiveItem'
import PropTypes from 'prop-types'
import Routing from '../../../routing/Routing'
import ServiceInfo from './ServiceInfo'
import locationService from '../../../services/Locations'
import { showLocalizedMoovyToast } from '../../../components/MoovyToast'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'

const LocationToolbar = ({ loc, refresh }) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { operatorRealm, isSuperOperator, roles } = useAuthInfo()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [doorCodeEditModalOpen, setDoorCodeEditModalOpen] = useState(false)
  const [locationExtensionInfoModalOpen, setLocationExtensionInfoModalOpen] =
    useState(false)
  const [autoCleanParkingEditModalOpen, setAutoCleanParkingEditModalOpen] =
    useState(false)

  const {
    mutate: activateLocationService,
    ...activateLocationServiceMutation
  } = useMutation({
    mutationFn: (parameter) =>
      locationService.updateLocationOperationalData(
        null,
        parameter.locationRef,
        {
          active: parameter.locationActive
        }
      ),
    onSuccess: (response) => {
      if (response) {
        refresh()

        setShowConfirmation(false)

        if (response.active) {
          showLocalizedMoovyToast(intl, {
            title: 'locations.toolbar.toast.actived.title',
            description: 'locations.toolbar.toast.actived.body',
            values: {
              name: response.name
            }
          })
        } else {
          showLocalizedMoovyToast(intl, {
            title: 'locations.toolbar.toast.deactived.title',
            description: 'locations.toolbar.toast.deactived.body',
            values: {
              name: response.name
            }
          })
        }
      } else {
        showLocalizedMoovyToast(intl, {
          title: 'locations.toolbar.toast.statusChanged.title',
          description: 'locations.toolbar.toast.statusChanged.body'
        })
      }
    }
  })

  const { mutate: updateExtensionInfo, ...updateExtensionInfoMutation } =
    useMutation({
      mutationFn: (locationUpdateData) => {
        return locationService.updateLocationData(
          null,
          locationUpdateData.locationRef,
          locationUpdateData
        )
      },
      onSuccess: (updatedLocation) => {
        setLocationExtensionInfoModalOpen(false)
        refresh()
        showLocalizedMoovyToast(intl, {
          title: 'modalEditExtensionInfo.toast.update.title',
          description: 'modalEditExtensionInfo.toast.update.body',
          values: {
            name: updatedLocation.name
          }
        })
      }
    })

  const { mutate: updateDoorCode, ...updateDoorCodeMutation } = useMutation({
    mutationFn: (locationUpdateData) => {
      return locationService.updateDoorCode(
        operatorRealm,
        locationUpdateData.locationRef,
        locationUpdateData
      )
    },
    onSuccess: (updatedLocation) => {
      setDoorCodeEditModalOpen(false)
      refresh()
      showLocalizedMoovyToast(intl, {
        title: 'modalEditDoorCode.toast.update.title',
        description: 'modalEditDoorCode.toast.update.body',
        values: {
          name: updatedLocation.name
        }
      })
    }
  })

  const { mutate: updateAutoCleanParking, ...updateAutoCleanParkingMutation } =
    useMutation({
      mutationFn: (locationUpdateData) => {
        return locationService.updateAutoCleanThreshold(
          locationUpdateData.locationRef,
          locationUpdateData
        )
      },
      onSuccess: () => {
        setAutoCleanParkingEditModalOpen(false)
        refresh()
        showLocalizedMoovyToast(intl, {
          title: 'modalEditAutoCleanParking.toast.update.title',
          description: 'modalEditAutoCleanParking.toast.update.body'
        })
      }
    })

  const LocationLabels = ({ location }) => {
    let typeSpecificContent = ''
    switch (location.type) {
      case locationEnums.locationType.ZONE:
        typeSpecificContent = (
          <>
            <MaximumParkingTime
              maximumParkingTime={location.maximumParkingTime}
              locationType={location.type}
            />
          </>
        )
        break
      case locationEnums.locationType.LOT:
        typeSpecificContent = (
          <>
            <DoorCode
              doorCode={location.doorCode}
              locationType={location.type}
            />
            <MaximumParkingTime
              maximumParkingTime={location.maximumParkingTime}
              locationType={location.type}
            />
            <ParkingGracePeriod
              parkingCancelGracePeriodSeconds={
                location.parkingCancelGracePeriodSeconds
              }
            />
            <AllowedExitTime
              allowedExitTimeSeconds={location.allowedExitTimeSeconds}
            />
          </>
        )
        break
      case locationEnums.locationType.FACILITY:
        typeSpecificContent = (
          <>
            <DoorCode
              doorCode={location.doorCode}
              locationType={location.type}
            />
            <AnonymousParkingEndingTime
              autoCleanThresholdInDays={location.autoCleanThresholdInDays}
              locationType={location.type}
            />
            <ParkingGracePeriod
              parkingCancelGracePeriodSeconds={
                location.parkingCancelGracePeriodSeconds
              }
            />
            <AllowedExitTime
              allowedExitTimeSeconds={location.allowedExitTimeSeconds}
            />
          </>
        )
        break
      default:
        break
    }

    const LocationCard = () => {
      return (
        <>
          <b>
            <FormattedMessage id="locations.toolbar.label.locationCard" />{' '}
          </b>
          {location.extensionInfo && (
            <a
              style={{ fontWeight: 'bold' }}
              href={location.extensionInfo}
              target="_blank"
            >
              <FormattedMessage id="locations.toolbar.link.editLocationCard" />
            </a>
          )}
          {PermissionLocation.HasEditLocationCard(isSuperOperator, roles) ? (
            <MoovyIconButton
              iconName={'edit'}
              onClick={() => setLocationExtensionInfoModalOpen(true)}
              padding={{ left: '5px' }}
              content={
                <FormattedMessage id="locations.toolbar.button.editLocationCard" />
              }
            />
          ) : (
            ''
          )}
        </>
      )
    }

    return (
      <List>
        {typeSpecificContent}
        {!!location.parkingControlId && (
          <List.Item>
            <b>
              <FormattedMessage id="locations.toolbar.label.parkingControlId" />{' '}
            </b>
            {location.parkingControlId}
          </List.Item>
        )}
        {location.customActionName && (
          <List.Item>
            <b>
              <FormattedMessage id="locations.toolbar.label.customActionName" />
            </b>
            {' ' + location.customActionName}
          </List.Item>
        )}
        {isSuperOperator ? <LocationCard /> : ''}
      </List>
    )
  }

  const DoorCode = ({ doorCode }) => {
    return (
      <List.Item>
        <div>
          <b>
            <FormattedMessage id="locations.toolbar.label.doorCode" />{' '}
          </b>{' '}
          {doorCode || <FormattedMessage id="locations.toolbar.label.notSet" />}
          {PermissionLocation.HasEditDoorCode(roles) ? (
            <MoovyIconButton
              iconName={'edit'}
              onClick={() => setDoorCodeEditModalOpen(true)}
              padding={{ left: '5px' }}
              content={intl.formatMessage({
                id: 'locations.toolbar.button.editDoorCode'
              })}
            />
          ) : (
            ''
          )}
        </div>
      </List.Item>
    )
  }

  const MaximumParkingTime = ({ maximumParkingTime }) => {
    if (!maximumParkingTime) return ''
    return (
      <List.Item>
        <b>
          <FormattedMessage id="locations.toolbar.label.maximumParkingTime" />{' '}
        </b>
        {formatSeconds(maximumParkingTime * 60)}
      </List.Item>
    )
  }

  const AnonymousParkingEndingTime = ({
    autoCleanThresholdInDays,
    locationType
  }) => {
    if (locationType === 'ZONE') return ''

    return (
      <div>
        <b>
          <FormattedMessage id="locations.toolbar.label.automaticEnding" />
        </b>{' '}
        {(autoCleanThresholdInDays && (
          <FormattedMessage
            id="locations.toolbar.label.autoCleanThresholdInDays"
            values={{ days: autoCleanThresholdInDays }}
          />
        )) || <FormattedMessage id="common.unlimited" />}
        <Popup
          trigger={
            <Icon
              name="info"
              circular
              size="small"
              style={{ marginLeft: '5px', verticalAlign: 'bottom' }}
            />
          }
          content={intl.formatMessage({
            id: 'locations.toolbar.label.automaticEnding.info'
          })}
        />
        {PermissionLocation.HasEditLocationAutoCleanParking(
          isSuperOperator,
          roles
        ) ? (
          <MoovyIconButton
            iconName={'edit'}
            onClick={() => setAutoCleanParkingEditModalOpen(true)}
            padding={{ left: '5px' }}
            content={intl.formatMessage({
              id: 'locations.toolbar.button.editAutomaticEnding'
            })}
          />
        ) : (
          ''
        )}
      </div>
    )
  }

  const ParkingGracePeriod = ({ parkingCancelGracePeriodSeconds }) => {
    return (
      <>
        {(!!parkingCancelGracePeriodSeconds ||
          parkingCancelGracePeriodSeconds === 0) && (
          <List.Item>
            <b>
              <FormattedMessage id="locations.toolbar.label.parkingGracePeriod" />{' '}
            </b>
            {formatSeconds(parkingCancelGracePeriodSeconds)}
          </List.Item>
        )}
      </>
    )
  }

  const AllowedExitTime = ({ allowedExitTimeSeconds }) => {
    return (
      <>
        {(!!allowedExitTimeSeconds || allowedExitTimeSeconds === 0) && (
          <List.Item>
            <b>
              {' '}
              <FormattedMessage id="locations.toolbar.label.allowedExitTime" />{' '}
            </b>
            {formatSeconds(allowedExitTimeSeconds)}
          </List.Item>
        )}
      </>
    )
  }

  const RenderType = ({ location }) => {
    if (!location) return ''

    const getDescription = () => {
      let description = ''

      switch (location.type) {
        case locationEnums.locationType.FACILITY:
          description = intl.formatMessage({
            id: 'locations.toolbar.info.type.facility'
          })
          break
        case locationEnums.locationType.LOT:
          description = intl.formatMessage({
            id: 'locations.toolbar.info.type.lot'
          })
          break
        case locationEnums.locationType.ZONE:
          description = intl.formatMessage({
            id: 'locations.toolbar.info.type.zone'
          })
          break
        default:
          break
      }
      return description
    }

    return (
      <>
        <MoovyIconInfo
          iconLabel={
            <>
              <b>
                <FormattedMessage id="locations.toolbar.label.type" />{' '}
              </b>{' '}
              <FormattedMessage
                id={getStringValue(
                  locationEnums.locationType.localizationKeys,
                  location.type
                )}
                defaultMessage={location.type}
              />
            </>
          }
        >
          {getDescription()}
        </MoovyIconInfo>
      </>
    )
  }

  const RenderAddress = ({ location }) => {
    if (!location) return ''

    const address = [location.streetAddress, location.city]
      .filter((address) => address)
      .join(', ')

    return (
      <>
        <b>
          <FormattedMessage id="locations.toolbar.label.address" />{' '}
        </b>{' '}
        {address || '-'}
      </>
    )
  }

  return (
    <>
      <Grid columns="4" stackable style={{ maxWidth: '1350px' }}>
        <Grid.Column width={3}>
          <div>
            <MoovyActiveItem
              active={loc.active}
              name={loc.name}
              toggleable={PermissionLocation.HasEditActivity(
                isSuperOperator,
                roles
              )}
              confirmationEnabled
              showConfirmation={showConfirmation}
              mutation={activateLocationServiceMutation}
              onToggleChanged={() =>
                activateLocationService({
                  locationRef: loc.ref,
                  locationActive: !loc.active
                })
              }
              onShowConfirmation={(enabled) => setShowConfirmation(enabled)}
            />
          </div>

          <List>
            <List.Item>
              <b>
                <FormattedMessage id="locations.toolbar.label.operator" />{' '}
              </b>{' '}
              {(loc && loc.operator?.name) ?? '-'}
            </List.Item>
            <List.Item>
              <RenderType location={loc} />
            </List.Item>
            <List.Item>
              <RenderAddress location={loc} />
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <LocationFeatureList location={loc} />
        </Grid.Column>
        <Grid.Column width={5}>
          <LocationLabels location={loc} />
        </Grid.Column>
        <Grid.Column width={5}>
          <ServiceInfo loc={loc} refresh={refresh} />
        </Grid.Column>
        <Grid.Row>
          <Grid.Column width={16}>
            <b style={{ verticalAlign: 'middle' }}>
              {(loc.mainLocation && (
                <FormattedMessage id="locations.toolbar.label.mainLocation" />
              )) ||
                (loc.subLocations && loc.subLocations.length > 0 && (
                  <FormattedMessage id="locations.toolbar.label.subLocation" />
                ))}
            </b>
            {loc.mainLocation && (
              <MoovyLink
                bold
                onClick={() =>
                  navigate(
                    Routing.getLocationUrl(
                      loc.mainLocation.ref,
                      isSuperOperator
                    )
                  )
                }
              >
                {getFormattedLocationName(loc.mainLocation)}
              </MoovyLink>
            )}
            {loc.subLocations &&
              loc.subLocations.length > 0 &&
              loc.subLocations.map((subLocation) => (
                <MoovyLink
                  bold
                  key={subLocation.ref}
                  onClick={() =>
                    navigate(
                      Routing.getLocationUrl(subLocation.ref, isSuperOperator)
                    )
                  }
                >
                  {getFormattedLocationName(subLocation)}
                </MoovyLink>
              ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {locationExtensionInfoModalOpen && loc ? (
        <ModalEditExtensionInfo
          onClose={() => {
            updateExtensionInfoMutation.reset()
            setLocationExtensionInfoModalOpen(false)
          }}
          location={loc}
          onSubmit={updateExtensionInfo}
          mutation={updateExtensionInfoMutation}
        />
      ) : (
        ''
      )}
      {doorCodeEditModalOpen && loc ? (
        <ModalEditDoorCode
          onClose={() => {
            updateDoorCodeMutation.reset()
            setDoorCodeEditModalOpen(false)
          }}
          location={loc}
          onSubmit={updateDoorCode}
          mutation={updateDoorCodeMutation}
        />
      ) : (
        ''
      )}
      {autoCleanParkingEditModalOpen && loc ? (
        <ModalEditAutoCleanParking
          onClose={() => {
            updateAutoCleanParkingMutation.reset()
            setAutoCleanParkingEditModalOpen(false)
          }}
          location={loc}
          onSubmit={updateAutoCleanParking}
          mutation={updateAutoCleanParkingMutation}
        />
      ) : (
        ''
      )}
    </>
  )
}

LocationToolbar.propTypes = {
  loc: PropTypes.object.isRequired
}

export default LocationToolbar
