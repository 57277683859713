const { REACT_APP_API_URL } = process.env

class ApiError extends Error {
  constructor(message, body, statusCode, path) {
    super(message)
    this.statusCode = statusCode
    this.path = path
    this.body = body
  }
}

const generateQueryString = (queryParams) =>
  `?${Object.entries(queryParams)
    .filter((e) => e[1])
    .map(
      (
        e // eslint-disable-line prefer-template
      ) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`
    )
    .join('&')}`

const getDefaultOptions = () => ({
  method: 'GET',
  mode: 'cors',
  cache: 'no-cache',
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Accept-Language': sessionStorage.getItem('language') || 'fi',
    ...(sessionStorage.getItem('authToken')
      ? { Authorization: `Bearer ${sessionStorage.getItem('authToken')}` }
      : {})
  },
  redirect: 'follow',
  referrer: 'no-referrer'
})

const request = async (path, options = {}) =>
  fetch(`${REACT_APP_API_URL}${path}`, {
    ...getDefaultOptions(),
    ...options
  })
    .then(async (response) => {
      if (!response.ok) {
        const { status, url } = response
        let body = null
        try {
          body = await response.json()
        } catch (e) {
          /* ignored, null body to ApiError */
        }
        throw new ApiError(response.statusText, body, status, url)
      }
      if (response.status === 204) {
        // No content, just resolve empty object, res.json() throws exception
        return new Promise((resolve) => resolve({}))
      }

      // Return CSV responses as blob for downloading
      const contentType = response.headers.get('content-type')
      if (contentType && contentType.includes('text/csv')) {
        return response.blob()
      }

      return response.json()
    })
    .catch((e) => {
      if (e instanceof SyntaxError) {
        return new Promise((resolve) => resolve({}))
      }
      throw e
    })

export const get = (path, queryParams = {}, options = {}) =>
  request(
    `${path}${
      Object.keys(queryParams).length ? generateQueryString(queryParams) : ''
    }`,
    options
  )

export const post = (path, body = {}, options = {}) =>
  request(path, {
    ...{
      body: JSON.stringify(body),
      method: 'POST'
    },
    ...options
  })

export const put = (path, body = {}, options = {}) =>
  request(path, {
    ...{
      body: JSON.stringify(body),
      method: 'PUT'
    },
    ...options
  })

export const remove = (path, body = {}, options = {}) =>
  request(path, {
    ...{
      body: JSON.stringify(body),
      method: 'DELETE'
    },
    ...options
  })
