import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'

import RelativeLinkForCurrentUser from '../../../containers/RelativeLinkForCurrentUser'
import locationsService from '../../../services/Locations'
import { getFormattedLocationName } from '../../../services/utils'

const VoucherLocationTable = ({ voucherTemplate, operatorRealm }) => {
  const [locations, setLocations] = useState([])

  useEffect(() => {
    fetchLocations()
  }, [voucherTemplate])

  // Fetch locations according to the value of the operator drowndown.
  const fetchLocations = () => {
    const operatorRef =
      voucherTemplate &&
      voucherTemplate.operator &&
      voucherTemplate.operator.ref
    if (
      operatorRef &&
      voucherTemplate.validityLocationRefs &&
      voucherTemplate.validityLocationRefs.length > 0
    ) {
      if (operatorRealm) {
        locationsService
          .fetchLocationsByOperatorRealm(operatorRealm)
          .then((res) => {
            updateLocations(res)
          })
      } else {
        locationsService
          .fetchLocationsForOperator(voucherTemplate.operator.ref)
          .then((res) => {
            updateLocations(res)
          })
      }
    }
  }

  const updateLocations = (res) => {
    if (res && res.content) {
      const validLocations = res.content.filter((item) => {
        return (
          voucherTemplate.validityLocationRefs.filter((templateItem) => {
            return templateItem && templateItem.ref === item.ref
          }).length > 0
        )
      })
      setLocations(validLocations)
    }
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Nimi</Table.HeaderCell>
          <Table.HeaderCell>Kaupunki</Table.HeaderCell>
          <Table.HeaderCell>Kohteen tyyppi</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {locations &&
          locations.map((location) => (
            <Table.Row key={location.ref}>
              <Table.Cell>
                <RelativeLinkForCurrentUser to={`locations/${location.ref}`}>
                  {getFormattedLocationName(location)}
                </RelativeLinkForCurrentUser>
              </Table.Cell>
              <Table.Cell>{location.city}</Table.Cell>
              <Table.Cell>{location.type}</Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  )
}

export default VoucherLocationTable
