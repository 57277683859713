import { FormattedMessage, useIntl } from 'react-intl'
import { Grid, GridRow, List } from 'semantic-ui-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LocalizedServiceType } from '../../../components/MoovyLocalizedEnum'
import { MoovyIconButton } from '../../../components'
import { PermissionService } from '../../../services/utils'
import React from 'react'
import ServiceSettlementRecipient from './ServiceSettlementRecipient'
import servicesService from '../../../services/Services'
import useAuthInfo from '../../../hooks/useAuthInfo'
import { useQuery } from '@tanstack/react-query'

const SubscriptionServiceToolbar = ({
  subscriptionService,
  onShowDescription,
  onEditOverBooking,
  onEditVisibility
}) => {
  const intl = useIntl()
  const { isSuperOperator, roles } = useAuthInfo()

  const linkedEvServiceQuery = useQuery({
    queryKey: ['linkedEvServiceQuery'],
    queryFn: () =>
      servicesService.fetchLinkedEvChargingService(
        subscriptionService.ref,
        null
      ),
    refetchOnWindowFocus: false,
    enabled: !!isSuperOperator && !!subscriptionService
  })

  const evChargingData = linkedEvServiceQuery.data

  const RenderCapacities = ({ service }) => {
    if (!Number.isInteger(service.capacity)) {
      if (Number.isInteger(service.usedCapacity) && service.usedCapacity > 0) {
        return <>{`${service.usedCapacity}`}</>
      } else {
        return <FormattedMessage id="common.unlimited" />
      }
    }

    return <>{`${service.usedCapacity}/${service.capacity}`}</>
  }

  return (
    <>
      {subscriptionService && (
        <>
          <Grid columns="2" stackable style={{ maxWidth: '1200px' }}>
            <GridRow>
              <Grid.Column width={4}>
                <List>
                  <List.Item>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.subscriptionServiceToolbar.label.type" />
                    </b>{' '}
                    <LocalizedServiceType value={subscriptionService.type} />
                  </List.Item>
                  <List.Item>
                    <div>
                      <b>
                        <FormattedMessage id="parkingServiceDetails.subscriptionServiceToolbar.label.visibility" />
                      </b>{' '}
                      {subscriptionService.publicService === true ? (
                        <FormattedMessage id="common.public" />
                      ) : (
                        <FormattedMessage id="common.private" />
                      )}
                      {PermissionService.HasEditVisibility(
                        isSuperOperator,
                        roles
                      ) ? (
                        <MoovyIconButton
                          iconName={'edit'}
                          onClick={() => onEditVisibility()}
                          padding={{ left: '5px' }}
                          content={intl.formatMessage({
                            id: 'parkingServiceDetails.toolbar.button.editVisibility.tooltip'
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </List.Item>
                  <List.Item>
                    <div>
                      <b>
                        <FormattedMessage id="parkingServiceDetails.subscriptionServiceToolbar.label.overbookingCoefficient" />
                      </b>{' '}
                      {subscriptionService.userAssignmentOverbookingCoefficient}
                      {PermissionService.HasEditOverbooking(
                        isSuperOperator,
                        roles
                      ) ? (
                        <MoovyIconButton
                          iconName={'edit'}
                          onClick={() => onEditOverBooking()}
                          padding={{ left: '5px' }}
                          content={intl.formatMessage({
                            id: 'parkingServiceDetails.toolbar.button.editOverbooking.tooltip'
                          })}
                        />
                      ) : (
                        ''
                      )}
                    </div>
                  </List.Item>
                  <List.Item onClick={onShowDescription}>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.subscriptionServiceToolbar.label.description" />{' '}
                    </b>
                    <a>
                      <FormattedMessage id="parkingServiceDetails.subscriptionServiceToolbar.link.description" />
                    </a>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={5}>
                <List>
                  <List.Item>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.subscriptionServiceToolbar.label.serviceOrderedCount" />
                    </b>{' '}
                    <RenderCapacities service={subscriptionService} />
                  </List.Item>
                  <List.Item>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.subscriptionServiceToolbar.label.parkingCapacity" />
                    </b>{' '}
                    {Number.isInteger(subscriptionService.parkingCapacity)
                      ? subscriptionService.parkingCapacity
                      : intl.formatMessage({
                          id: 'common.unlimited'
                        })}
                  </List.Item>
                  <List.Item>
                    <b>
                      <FormattedMessage id="parkingServiceDetails.subscriptionServiceToolbar.label.allowParkingOvercapacity" />
                    </b>{' '}
                    {subscriptionService.exceedingParkingCapacityAllowed
                      ? intl.formatMessage({
                          id: 'common.yes'
                        })
                      : intl.formatMessage({
                          id: 'common.no'
                        })}
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column width={5}>
                <ServiceSettlementRecipient service={subscriptionService} />
              </Grid.Column>
              {evChargingData?.name && (
                <Grid.Column width={2}>
                  <FontAwesomeIcon icon={['far', 'charging-station']} />{' '}
                  {evChargingData.name}
                </Grid.Column>
              )}
            </GridRow>
          </Grid>
        </>
      )}
    </>
  )
}

export default SubscriptionServiceToolbar
