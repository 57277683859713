import { Form, Grid, Icon } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyButton,
  MoovyTableFilter,
  MoovyTextInput
} from '../../../components'
import {
  getStringValue,
  invoicingCardPaymentStatus,
  invoicingVismaStatus
} from '../../../services/utils/DTOEnums'

import React from 'react'

export const InvoicingTableFilter = ({
  isEnterpriseCustomer,
  invoiceNumber,
  invoiceSum,
  invoiceStatuses,
  setDateTimeRange,
  setInvoiceNumber,
  setInvoiceSum,
  setInvoiceStatuses,
  onOpenInvoice
}) => {
  const intl = useIntl()
  const invoicingOptions = Object.keys(
    (isEnterpriseCustomer && invoicingVismaStatus) || invoicingCardPaymentStatus
  )
    .filter((item) => item !== 'localizationKeys')
    .map((item) => {
      return {
        key: item,
        text: intl.formatMessage({
          id: getStringValue(
            (isEnterpriseCustomer && invoicingVismaStatus.localizationKeys) ||
              invoicingCardPaymentStatus.localizationKeys,
            item
          )
        }),
        value: item
      }
    })

  return (
    <MoovyTableFilter
      onSelectedDateTimeRange={(range) => setDateTimeRange(range)}
    >
      <Form.Group>
        <Form.Field width={3}>
          <label>
            <FormattedMessage id="customerInvoicing.toolbarFilter.label.invoiceNumber" />
          </label>
          <MoovyTextInput
            value={invoiceNumber}
            onChange={(e) => setInvoiceNumber(e.target.value)}
            onClear={() => setInvoiceNumber('')}
            placeholder={intl.formatMessage({
              id: 'customerInvoicing.toolbarFilter.label.invoiceNumber'
            })}
            type={'number'}
          />
        </Form.Field>
        <Form.Field width={3}>
          <label>
            <FormattedMessage id="customerInvoicing.toolbarFilter.label.invoiceSum" />
          </label>
          <MoovyTextInput
            value={invoiceSum}
            onChange={(e) => setInvoiceSum(e.target.value)}
            onClear={() => setInvoiceSum('')}
            placeholder={intl.formatMessage({
              id: 'customerInvoicing.toolbarFilter.label.invoiceSum'
            })}
            type={'number'}
          />
        </Form.Field>
        <Form.Dropdown
          width={3}
          multiple
          label={intl.formatMessage({
            id: 'customerInvoicing.toolbarFilter.label.invoiceStatus'
          })}
          placeholder={intl.formatMessage({
            id: 'customerInvoicing.toolbarFilter.label.invoiceStatus'
          })}
          options={invoicingOptions}
          fluid
          selection
          clearable
          value={invoiceStatuses}
          onChange={(e, value) => {
            setInvoiceStatuses(value.value)
          }}
          selectOnBlur={false}
        />
        <Form.Field width={7}>
          <Grid>
            <Grid.Column floated="right" width={16} textAlign="right">
              {isEnterpriseCustomer && (
                <MoovyButton onClick={() => onOpenInvoice()}>
                  <Icon name="sticky note outline" />
                  <FormattedMessage id="customerInvoicing.toolbarFilter.button.createInvoice" />
                </MoovyButton>
              )}
            </Grid.Column>
          </Grid>
        </Form.Field>
      </Form.Group>
    </MoovyTableFilter>
  )
}

export default InvoicingTableFilter
