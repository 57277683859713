import React from 'react'
import { Table } from 'semantic-ui-react'

import TablePagination from '../../../components/TablePagination'
import { getFormattedLocationName } from '../../../services/utils'
import { tablePaginationDirections } from '../../../services/utils/tablePagination'

const LocationTable = ({
  locations,
  onSelectedLocation,
  onHandleSort,
  onPageChange,
  pagination
}) => (
  <Table selectable sortable>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell
          sorted={
            pagination.sortColumn === 'sortByLocation'
              ? tablePaginationDirections.stringValues[pagination.direction]
              : null
          }
          onClick={onHandleSort('sortByLocation')}
        >
          Kohde
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            pagination.sortColumn === 'sortByCity'
              ? tablePaginationDirections.stringValues[pagination.direction]
              : null
          }
          onClick={onHandleSort('sortByCity')}
        >
          Kaupunki
        </Table.HeaderCell>
        <Table.HeaderCell
          sorted={
            pagination.sortColumn === 'sortByOperator'
              ? tablePaginationDirections.stringValues[pagination.direction]
              : null
          }
          onClick={onHandleSort('sortByOperator')}
        >
          Operaattori
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {locations &&
        locations.map((location) => (
          <Table.Row
            key={location.ref}
            onClick={() => {
              onSelectedLocation(location)
            }}
          >
            <Table.Cell>{getFormattedLocationName(location)}</Table.Cell>
            <Table.Cell>{location.city}</Table.Cell>
            <Table.Cell>
              {location.operator && location.operator.name}
            </Table.Cell>
          </Table.Row>
        ))}
    </Table.Body>
    <TablePagination
      colSpan={3}
      activePage={pagination.activePage}
      totalPages={pagination.totalPages}
      onPageChange={onPageChange}
    />
  </Table>
)

export default LocationTable
