import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, List, Modal } from 'semantic-ui-react'

import GenericMessage, {
  createErrorMessage
} from '../../components/GenericMessage'
import useAuthInfo from '../../hooks/useAuthInfo'
import userGroupsService from '../../services/UserGroups'

const UserGroupDeleteModal = ({
  userGroup,
  userGroupMemberships,
  onClose,
  onSubmit
}) => {
  const { operatorRealm } = useAuthInfo()
  const intl = useIntl()

  const voucherGrantAgreements = useQuery({
    queryKey: ['voucherGrantAgreements', userGroup.ref],
    queryFn: () =>
      userGroupsService.fetchVoucherGrantAgreements(
        userGroup.ref,
        operatorRealm
      )
  })

  const serviceAgreements = useQuery({
    queryKey: ['serviceAgreements', userGroup.ref],
    queryFn: () =>
      userGroupsService.fetchServiceAgreements(userGroup.ref, operatorRealm)
  })

  const { mutate: deleteUserGroup, ...deleteUserGroupMutation } = useMutation({
    mutationFn: () =>
      userGroupsService.removeUserGroup(userGroup.ref, operatorRealm),
    onSuccess: () => {
      onSubmit()
    }
  })

  const modalDeleteNotAllowed = () => {
    return (
      <Modal size="tiny" open onClose={onClose}>
        <Modal.Header>
          <FormattedMessage
            id="userGroupDeleteModal.notAllowed.header"
            values={{ groupName: userGroup.name }}
          />
        </Modal.Header>
        <Modal.Content>
          <List>
            <List.Item>
              <p>
                <FormattedMessage id="userGroupDeleteModal.notAllowed.instructions" />
              </p>
            </List.Item>
            <List.Item>
              <ul>
                {userGroupMemberships.length > 0 ? (
                  <li>
                    <FormattedMessage
                      id="userGroupDeleteModal.notAllowed.users"
                      values={{ count: userGroupMemberships.length }}
                    />
                  </li>
                ) : (
                  ''
                )}
                {voucherGrantAgreements.data &&
                voucherGrantAgreements.data.length > 0 ? (
                  <li>
                    <FormattedMessage
                      id="userGroupDeleteModal.notAllowed.voucherGrantAgreements"
                      values={{ count: voucherGrantAgreements.data.length }}
                    />
                  </li>
                ) : (
                  ''
                )}
                {serviceAgreements.data && serviceAgreements.data.length > 0 ? (
                  <li>
                    <FormattedMessage
                      id="userGroupDeleteModal.notAllowed.serviceAgreements"
                      values={{ count: serviceAgreements.data.length }}
                    />
                  </li>
                ) : (
                  ''
                )}
              </ul>
            </List.Item>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>
            <FormattedMessage id="common.button.OK" />
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  const modalDeleteUserGroup = () => {
    return (
      <Modal size="tiny" open onClose={onClose}>
        <Modal.Header>
          <FormattedMessage
            id="userGroupDeleteModal.header"
            values={{ groupName: userGroup.name }}
          />
        </Modal.Header>
        <Modal.Content>
          <List>
            <List.Item>
              <p>
                <FormattedMessage id="userGroupDeleteModal.instructions" />
              </p>
            </List.Item>
            <List.Item style={{ marginTop: '1em' }}>
              {deleteUserGroupMutation.isError && (
                <GenericMessage
                  message={createErrorMessage(
                    intl.formatMessage({ id: 'userGroupDeleteModal.error' })
                  )}
                />
              )}
            </List.Item>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={onClose}>
            <FormattedMessage id="common.button.close" />
          </Button>
          <Button
            onClick={() => {
              deleteUserGroup()
            }}
            color="red"
            disabled={deleteUserGroupMutation.isPending}
            loading={deleteUserGroupMutation.isPending}
          >
            <FormattedMessage id="common.button.delete" />
          </Button>
        </Modal.Actions>
      </Modal>
    )
  }

  return (
    <>
      {voucherGrantAgreements.data && serviceAgreements.data
        ? voucherGrantAgreements.data.length > 0 ||
          serviceAgreements.data.length > 0 ||
          userGroupMemberships.length > 0
          ? modalDeleteNotAllowed()
          : modalDeleteUserGroup()
        : ''}
    </>
  )
}

export default UserGroupDeleteModal
