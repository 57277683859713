import React, { useEffect, useState } from 'react'

import { DateTime } from 'luxon'
import { useIntl } from 'react-intl'

const MoovyRefreshTime = ({
  updatedAtAsMillis,
  loading,
  parentheses = false,
  noPadding = false
}) => {
  const updateIntervalMs = 2000
  const intl = useIntl()
  const validUpdateTime = Number.isInteger(updatedAtAsMillis)

  let timerOfSecondsCounter
  const [countSeconds, setCountSeconds] = useState(0)

  const [
    userFriendlyRefreshDateTime,
    setUserFriendlyRefreshDateTime
  ] = useState(
    intl.formatMessage({
      id: 'moovyRefreshTime.label.refresh.notRefreshed'
    })
  )

  const getUserFriendlyRefreshDateTime = (updatedAtAsMilliSeconds) => {
    const updatedDateTime = DateTime.fromMillis(updatedAtAsMilliSeconds)
    const updatedLocalDateTime =
      (updatedDateTime && DateTime.fromISO(updatedDateTime)) || null

    if (!updatedLocalDateTime) {
      return intl.formatMessage({
        id: 'moovyRefreshTime.label.refresh.notRefreshed'
      })
    }

    const { months, days, hours, minutes, seconds } = DateTime.local()
      .diff(updatedLocalDateTime, [
        'months',
        'days',
        'hours',
        'minutes',
        'seconds'
      ])
      .toObject()

    if (months === 0 && days === 0 && hours === 0 && minutes === 0) {
      const uiSeconds = seconds > 0 ? (Math.trunc(seconds / 10) + 1) * 10 : 10
      if (uiSeconds < 2) return ''
      if (uiSeconds === 60) {
        return intl.formatMessage(
          {
            id: 'moovyRefreshTime.label.refresh.refreshedInMinutes'
          },
          {
            minutes: 1
          }
        )
      }
      return intl.formatMessage(
        {
          id: 'moovyRefreshTime.label.refresh.refreshedInSeconds'
        },
        {
          seconds: uiSeconds
        }
      )
    } else if (months === 0 && days === 0 && hours === 0) {
      const values = {
        seconds: seconds > 0 ? (Math.trunc(seconds / 10) + 1) * 10 : 10,
        minutes: minutes
      }
      if (values.seconds === 60) {
        return intl.formatMessage(
          {
            id: 'moovyRefreshTime.label.refresh.refreshedInMinutes'
          },
          {
            minutes: minutes + 1
          }
        )
      } else {
        return intl.formatMessage(
          {
            id: 'moovyRefreshTime.label.refresh.refreshedInMinutesAndSeconds'
          },
          values
        )
      }
    } else {
      return intl.formatMessage({
        id: 'moovyRefreshTime.label.refresh.oldData'
      })
    }
  }

  const updateCounter = () => {
    timerOfSecondsCounter =
      !timerOfSecondsCounter &&
      setInterval(() => {
        setCountSeconds((prevCount) => prevCount + updateIntervalMs / 1000)
      }, updateIntervalMs)

    if (validUpdateTime) {
      setUserFriendlyRefreshDateTime(
        getUserFriendlyRefreshDateTime(updatedAtAsMillis)
      )
    }
  }

  useEffect(() => {
    updateCounter()
    return () => clearInterval(timerOfSecondsCounter)
  }, [countSeconds])

  useEffect(() => {
    if (validUpdateTime) {
      setUserFriendlyRefreshDateTime(
        getUserFriendlyRefreshDateTime(updatedAtAsMillis)
      )
    }
  }, [updatedAtAsMillis])

  const ShowContent = () => {
    if (loading) {
      return ''
    }
    if (validUpdateTime) {
      return userFriendlyRefreshDateTime
    } else {
      return intl.formatMessage({
        id: 'moovyRefreshTime.label.refresh.notRefreshed'
      })
    }
  }

  return (
    <span
      style={{
        paddingLeft: !noPadding && '10px',
        paddingRight: !noPadding && '10px'
      }}
    >
      {(parentheses && (
        <>
          (<ShowContent />)
        </>
      )) || <ShowContent />}
    </span>
  )
}

export default MoovyRefreshTime
