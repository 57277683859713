import './MoovyActiveItem.scss'

import { Checkbox, Icon } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'

import { MoovyModalConfirmation } from '../../components'
import React from 'react'

const MoovyActiveItem = ({
  active,
  name,
  toggleable,
  confirmationEnabled,
  showConfirmation,
  mutation,
  onToggleChanged,
  onShowConfirmation
}) => {
  const intl = useIntl()
  const confirmContent = active
    ? intl.formatMessage(
        { id: 'moovyActiveItem.confirmContent.active' },
        { name }
      )
    : intl.formatMessage(
        { id: 'moovyActiveItem.confirmContent.inactive' },
        { name }
      )

  return (
    <>
      <div className="activeIconContent">
        <span className="activeIcon">
          {active ? (
            <Icon
              name="check circle"
              color="green"
              style={{ marginRight: '0.35em' }}
            />
          ) : (
            <Icon
              name="remove circle"
              color="red"
              style={{ marginRight: '0.35em' }}
            />
          )}
        </span>
        <span className="activeIcon">
          {active ? (
            <FormattedMessage id="moovyActiveItem.labels.active" />
          ) : (
            <FormattedMessage id="moovyActiveItem.labels.inactive" />
          )}
        </span>
        <span className="toggle">
          {toggleable && (
            <Checkbox
              toggle
              checked={active}
              onChange={() =>
                confirmationEnabled
                  ? onShowConfirmation(true)
                  : onToggleChanged()
              }
              disabled={mutation.isPending}
            />
          )}
        </span>
      </div>
      <MoovyModalConfirmation
        open={showConfirmation}
        onClose={() => onShowConfirmation(false)}
        lang={{
          titleElement: (
            <FormattedMessage id="moovyActiveItem.confirmContent.title" />
          ),
          bodyElement: <>{confirmContent}</>,
          buttonConfirmKey: 'common.button.confirm',
          defaultErroKey: 'moovyActiveItem.confirmContent.action.error'
        }}
        onSubmit={onToggleChanged}
        mutation={mutation}
      />
    </>
  )
}

export default MoovyActiveItem
