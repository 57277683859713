import { useState } from 'react'

const useWebPaymentCancelForm = (initialLicensePlate = '') => {
  const [comment, setComment] = useState('')
  const [licensePlate, setLicensePlate] = useState(initialLicensePlate)

  const errors = {
    comment: !comment.trim(),
    licensePlate: !licensePlate.trim()
  }

  const hasErrors = errors.comment || errors.licensePlate
  return {
    comment,
    setComment,
    licensePlate,
    setLicensePlate,
    hasErrors,
    errors,
    reason: `${licensePlate.trim()}, ${comment.trim()}`
  }
}

export default useWebPaymentCancelForm
