import { cloneDeep } from 'lodash'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Grid, Message, Modal, Progress } from 'semantic-ui-react'

import { MoovyLink } from '../../components'
import messageCenterService from '../../services/MessageCenter'
import { messageCenterEnums } from '../../services/utils/DTOEnums'
import { initialMessageValues } from './CreateMessage/CreateMessage'
import {
  parseMessageToBackend,
  parseMessageToFrontend
} from './CreateMessage/MessageParser'
import StepSummary from './CreateMessage/StepSummary'

const ModalMessagePreview = ({
  messageRef,
  open,
  onClose,
  onMessageCloned
}) => {
  const [message, setMessage] = useState(cloneDeep(initialMessageValues))

  const messageQuery = useQuery({
    queryKey: ['messageQuery', messageRef],
    queryFn: () => messageCenterService.fetchMessage(messageRef),
    refetchOnWindowFocus: false,
    enabled: !!messageRef
  })

  useEffect(() => {
    if (messageQuery.isSuccess) {
      setMessage(
        parseMessageToFrontend(
          messageQuery.data,
          cloneDeep(initialMessageValues)
        )
      )
    }
  }, [messageQuery.isSuccess])

  useEffect(() => {
    if (messageQuery.isError) {
      setMessage(cloneDeep(initialMessageValues))
    }
  }, [messageQuery.isError])

  const { mutate: createCopyMessage, ...createCopyMessageMutation } =
    useMutation({
      mutationFn: (message) => messageCenterService.saveMessage(null, message),
      onSuccess: (result) => {
        if (result && result.ref) {
          onMessageCloned(result.ref)
        }
      }
    })

  const createMessageCopy = () => {
    if (messageQuery.isLoading || createCopyMessageMutation.isPending) {
      return
    }

    const messageCopy = {
      ...message,
      sendingDetails: { ...message.sendingDetails, status: undefined }
    }

    createCopyMessage(
      parseMessageToBackend(messageCenterEnums.messageStatus.DRAFT, messageCopy)
    )
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Modal.Header>
        <FormattedMessage id="modalMessagePreview.header" />
      </Modal.Header>
      {(createCopyMessageMutation.isPending || messageQuery.isPending) && (
        <Progress percent={30} attached="top" color="blue" active />
      )}
      <Modal.Content scrolling>
        {(messageQuery.isError && (
          <Message negative>
            <FormattedMessage id="modalMessagePreview.error.readingMessage" />
          </Message>
        )) ||
          (!messageQuery.isLoading && <StepSummary formValues={message} />)}
        {createCopyMessage.isError && (
          <Message negative>
            <FormattedMessage id="modalMessagePreview.error.copyMessage" />
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Grid>
          <Grid.Column
            floated="left"
            width={8}
            textAlign="left"
            verticalAlign="middle"
          >
            {!messageQuery.isError && (
              <MoovyLink bold onClick={() => createMessageCopy()}>
                <FormattedMessage id="modalMessagePreview.button.copy" />
              </MoovyLink>
            )}
          </Grid.Column>
          <Grid.Column floated="right" width={8} textAlign="right">
            <Button type="submit" primary onClick={onClose}>
              <FormattedMessage id="common.button.close" />
            </Button>
          </Grid.Column>
        </Grid>
      </Modal.Actions>
    </Modal>
  )
}

export default ModalMessagePreview
