import {
  Button,
  Form,
  Header,
  Icon,
  Input,
  Popup,
  Table
} from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  MoovyIconInfo,
  ServiceWithTags,
  showLocalizedMoovyToast
} from '../../../components'
import { PermissionLocation, serviceType } from '../../../services/utils'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { LocalizedServiceType } from '../../../components/MoovyLocalizedEnum'
import RelativeLinkForCurrentUser from '../../../containers/RelativeLinkForCurrentUser'
import locationService from '../../../services/Locations'
import useAuthInfo from '../../../hooks/useAuthInfo'

const ServicePriorityElement = ({
  itemServiceType,
  isSuperOperator,
  roles,
  isLoadingService,
  priority,
  serviceRef,
  editPriorityValue,
  setEditPriorityValue,
  editServiceRef,
  setEditServiceRef,
  updateServicePriority,
  updateServicePriorityMutation
}) => {
  if (serviceRef === editServiceRef)
    return (
      <Form
        key={serviceRef}
        onSubmit={(e) => {
          e.preventDefault()
          !isNaN(editPriorityValue) &&
            editPriorityValue > 0 &&
            updateServicePriority({
              serviceRef,
              vehicleAccessPriority: editPriorityValue
            })
        }}
      >
        <Form.Group>
          <Input
            value={editPriorityValue}
            style={{ paddingRight: '5px' }}
            onChange={(ev, data) => setEditPriorityValue(data.value)}
            onKeyDown={(e) => e.key === 'Escape' && setEditServiceRef('')}
            disabled={
              updateServicePriorityMutation.isLoading || isLoadingService
            }
          />
          <Button.Group>
            <Popup
              trigger={
                <Button
                  icon
                  onClick={() => setEditServiceRef('')}
                  disabled={updateServicePriorityMutation.isLoading}
                  compact
                  type="button"
                >
                  <Icon name="cancel" />
                </Button>
              }
              content={<FormattedMessage id="common.button.cancel" />}
            />
            <Popup
              trigger={
                <Button
                  icon
                  disabled={
                    updateServicePriorityMutation.isLoading ||
                    isLoadingService ||
                    !(!isNaN(editPriorityValue) && editPriorityValue > 0)
                  }
                  loading={updateServicePriorityMutation.isLoading}
                  compact
                  type="submit"
                >
                  <Icon name="check" />
                </Button>
              }
              content={<FormattedMessage id="common.button.save" />}
            />
          </Button.Group>
        </Form.Group>
      </Form>
    )

  return (
    <>
      {priority}
      {PermissionLocation.HasEditLocationSercicePriority(
        isSuperOperator,
        roles
      ) &&
        itemServiceType === serviceType.SUBSCRIPTION && (
          <Popup
            trigger={
              <Button
                icon
                size="mini"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  setEditServiceRef(serviceRef)
                  setEditPriorityValue(priority)
                }}
                disabled={
                  updateServicePriorityMutation.isLoading || isLoadingService
                }
              >
                <Icon name="edit" />
              </Button>
            }
            content={
              <FormattedMessage id="parkingService.location.button.editPriority" />
            }
          />
        )}
    </>
  )
}

export default function LocationServices({ locationRef }) {
  const { isSuperOperator, operatorRealm, roles } = useAuthInfo()
  const [editServiceRef, setEditServiceRef] = useState('')
  const [editPriorityValue, setEditPriorityValue] = useState('')
  const intl = useIntl()

  const servicesQuery = useQuery({
    queryKey: ['fetchLocationServices', locationRef],
    queryFn: () =>
      locationService.fetchLocationServices(locationRef, operatorRealm),
    enabled: !!locationRef
  })

  const servicesQueryIsLoading =
    !!locationRef && (servicesQuery.isLoading || servicesQuery.isFetching)

  const resetServiceEdit = () => {
    setEditServiceRef('')
    setEditPriorityValue('')
  }

  const { mutate: updateServicePriority, ...updateServicePriorityMutation } =
    useMutation({
      mutationFn: (updateData) =>
        locationService.updateLocationServicePriority(
          locationRef,
          updateData.serviceRef,
          { vehicleAccessPriority: updateData.vehicleAccessPriority }
        ),
      onSuccess: () => {
        servicesQuery.refetch()
        resetServiceEdit()
        showLocalizedMoovyToast(intl, {
          title:
            'parkingService.location.action.updateServicePriority.success.title',
          description:
            'parkingService.location.action.updateServicePriority.success.desc'
        })
      },
      onError: () =>
        showLocalizedMoovyToast(intl, {
          title:
            'parkingService.location.action.updateServicePriority.failed.title',
          description:
            'parkingService.location.action.updateServicePriority.failed.desc',
          type: 'error'
        })
    })

  return (
    <div>
      <Header>
        <FormattedMessage id="locations.tab.services.title" />
      </Header>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="parkingService.header.service" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingService.header.type" />
            </Table.HeaderCell>
            <Table.HeaderCell collapsing>
              <MoovyIconInfo
                iconLabel={
                  <FormattedMessage id="parkingService.header.vehicleAccessPriority" />
                }
              >
                <FormattedMessage id="parkingService.header.popup.vehicleAccessPriority" />
              </MoovyIconInfo>
            </Table.HeaderCell>
            {isSuperOperator && (
              <Table.HeaderCell>
                <FormattedMessage id="parkingService.header.operator" />
              </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {servicesQuery.data?.map((parkingService) => (
            <Table.Row key={parkingService.ref}>
              <Table.Cell>
                <RelativeLinkForCurrentUser
                  to={`services/${parkingService.ref}`}
                >
                  {parkingService.name}
                </RelativeLinkForCurrentUser>
              </Table.Cell>
              <Table.Cell>
                <LocalizedServiceType value={parkingService.type} />
                <ServiceWithTags parkingService={parkingService} />
              </Table.Cell>
              <Table.Cell
                style={
                  parkingService.ref === editServiceRef
                    ? { paddingBottom: '0px' }
                    : undefined
                }
              >
                <ServicePriorityElement
                  isSuperOperator={isSuperOperator}
                  roles={roles}
                  itemServiceType={parkingService.type}
                  isLoadingService={servicesQueryIsLoading}
                  priority={parkingService.vehicleAccessPriority}
                  serviceRef={parkingService.ref}
                  editPriorityValue={editPriorityValue}
                  setEditPriorityValue={setEditPriorityValue}
                  editServiceRef={editServiceRef}
                  setEditServiceRef={setEditServiceRef}
                  updateServicePriority={updateServicePriority}
                  updateServicePriorityMutation={updateServicePriorityMutation}
                />
              </Table.Cell>
              {isSuperOperator && parkingService.operator && (
                <Table.Cell>
                  <RelativeLinkForCurrentUser
                    to={`operators/${parkingService.operator.ref}`}
                  >
                    {parkingService.operator.name}
                  </RelativeLinkForCurrentUser>
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  )
}
