import { tablePaginationDirections } from '../../../services/utils/tablePagination'

export function getFilteredOperatorData(originalData, filter, pagination) {
  if (!originalData) {
    return []
  }

  let filteredData = originalData

  switch (pagination.sortColumn) {
    case 'sortByName':
      if (pagination.direction === tablePaginationDirections.ASC) {
        filteredData = filteredData.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        filteredData = filteredData.sort((a, b) => b.name.localeCompare(a.name))
      }
      break
    default:
      filteredData = filteredData
      break
  }

  if (filter) {
    const filterValue = (filter && filter.toLocaleLowerCase()) || ''

    filteredData = filteredData.filter((item) =>
      item.name.toLocaleLowerCase().includes(filterValue)
    )
  }

  return filteredData
}
