import './Finances.scss'

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Tab } from 'semantic-ui-react'

const FinancesPane = ({
  extraContent,
  children,
  text,
  textParagraphs,
  buttonCallback
}) => (
  <Tab.Pane>
    {textParagraphs ? (
      textParagraphs.map((p) => <p key={p}>{p}</p>)
    ) : (
      <p>{text}</p>
    )}

    <div>{extraContent}</div>
    {children}
    <Button primary onClick={buttonCallback}>
      <FormattedMessage id="finances.tab.button.download" />
    </Button>
  </Tab.Pane>
)

export default FinancesPane
