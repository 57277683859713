import { Button, Popup } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PermissionUser, invoicingStatus } from '../../../services/utils'

import React from 'react'
import useAuthInfo from '../../../hooks/useAuthInfo'

const ActionButton = ({
  primary = true,
  loading = false,
  disabled,
  children,
  onClick
}) => {
  return (
    <Button
      style={{ marginLeft: '10px' }}
      primary={primary}
      floated="right"
      disabled={disabled}
      onClick={onClick}
      loading={loading}
    >
      {children}
    </Button>
  )
}

const SendReceiptButton = ({
  invoice,
  editInvoiceRows,
  setShowSendByEmail,
  queryIsLoading
}) => {
  const { isSuperOperator, roles } = useAuthInfo()

  if (!PermissionUser.HasSendReceipt(isSuperOperator, roles)) {
    return ''
  }

  // The button is not shown if no price or rows are being edited
  if (!invoice.totalPrice || invoice.totalPrice === 0 || editInvoiceRows) {
    return ''
  }

  // Negative total price is only allowed for compensated invoice
  if (invoice.totalPrice > 0 && invoice.compensatedInvoice) {
    return ''
  }

  return (
    <>
      {invoice.status === invoicingStatus.IN_PROGRESS ||
      invoice.status === invoicingStatus.INVOICED ||
      invoice.status === invoicingStatus.REFUNDED ||
      invoice.status === invoicingStatus.CHARGEBACK ? (
        <ActionButton
          disabled={queryIsLoading}
          onClick={() => setShowSendByEmail(true)}
        >
          <FormattedMessage id="tabAdyenInvoice.button.sendReceipt" />
        </ActionButton>
      ) : (
        ''
      )}
    </>
  )
}

const RefundButton = ({
  invoice,
  invalidRevertTotalSum,
  maxRevertSum,
  queryIsLoading,
  editInvoiceRows,
  areAnySelected,
  handleClearAll,
  setShowRefundConfirmation,
  setEditInvoiceRows,
  refundingSelected
}) => {
  const { isSuperOperator, roles } = useAuthInfo()

  const showRefundButton =
    PermissionUser.HasEditInvoice(isSuperOperator, roles) &&
    (invoice.status === invoicingStatus.INVOICED ||
      invoice.status === invoicingStatus.IN_PROGRESS ||
      invoice.status === invoicingStatus.REFUNDED) &&
    invoice.totalPrice > 0 &&
    maxRevertSum > 0

  if (!showRefundButton) {
    return ''
  }

  const RenderRefundEditButtons = () => {
    return (
      <>
        <ActionButton
          disabled={queryIsLoading || !areAnySelected || invalidRevertTotalSum}
          onClick={() => {
            refundingSelected(true)
            setShowRefundConfirmation(true)
          }}
        >
          <FormattedMessage id="tabAdyenInvoice.button.refundPayment" />
        </ActionButton>
        <ActionButton
          primary={false}
          onClick={() => {
            refundingSelected(false)
            handleClearAll()
            setEditInvoiceRows(false)
          }}
        >
          <FormattedMessage id="common.button.cancel" />
        </ActionButton>
      </>
    )
  }

  const RenderRefundButton = () => {
    return (
      <>
        <ActionButton
          disabled={queryIsLoading}
          onClick={() => {
            refundingSelected(true)
            setEditInvoiceRows(true)
          }}
        >
          <FormattedMessage id="tabAdyenInvoice.button.refundPayment" />
        </ActionButton>
      </>
    )
  }

  return (
    <>
      {editInvoiceRows ? <RenderRefundEditButtons /> : <RenderRefundButton />}
    </>
  )
}

const RetryCardPaymentButton = ({
  invoice,
  infoPopupOpen,
  setInfoPopupOpen,
  queryIsLoading,
  setChargeConfirmation
}) => {
  const { isSuperOperator, roles } = useAuthInfo()
  const intl = useIntl()

  return (
    <>
      {PermissionUser.HasEditInvoice(isSuperOperator, roles) &&
        (invoice.status === invoicingStatus.PAYMENT_FAILED ||
          invoice.status === invoicingStatus.PERMANENTLY_FAILED) && (
          <Popup
            content={intl.formatMessage({
              id: 'tabAdyenInvoice.waived.popup.label'
            })}
            on="click"
            onClose={() => setInfoPopupOpen(false)}
            open={infoPopupOpen}
            trigger={
              <ActionButton
                disabled={queryIsLoading}
                onClick={() => setChargeConfirmation(true)}
              >
                <FormattedMessage id="tabAdyenInvoice.button.retryCardPayment" />
              </ActionButton>
            }
          />
        )}
    </>
  )
}

const SetAsWaivedButton = ({
  invoice,
  setInfoPopupOpen,
  setWaiveConfirmation,
  queryIsLoading
}) => {
  const { isSuperOperator, roles } = useAuthInfo()

  return (
    <>
      {PermissionUser.HasEditInvoice(isSuperOperator, roles) &&
        (invoice.status === invoicingStatus.PAYMENT_FAILED ||
          invoice.status === invoicingStatus.PERMANENTLY_FAILED) && (
          <ActionButton
            disabled={queryIsLoading}
            onClick={() =>
              invoice.invoicingAttemptCount < 3
                ? setInfoPopupOpen(true)
                : setWaiveConfirmation(true)
            }
          >
            <FormattedMessage id="modalWaivedInvoice.button.setAsWaived" />
          </ActionButton>
        )}
    </>
  )
}

export {
  ActionButton,
  RefundButton,
  SendReceiptButton,
  RetryCardPaymentButton,
  SetAsWaivedButton
}
