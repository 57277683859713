import ChargingSessionsTable from './chargingSessionsTable'
import React from 'react'
import electricVehicleChargingService from '../../services/ElectricVehicleCharging'
import { showLocalizedMoovyToast } from '../../components/MoovyToast'
import { useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'

const ChargingSessionsContent = ({
  query,
  sessions,
  sortColumn,
  sortDirection,
  onSort,
  onPageChange,
  totalPages,
  activePage,
  refresh
}) => {
  const intl = useIntl()

  const { mutate: stopSession, ...stopSessionMutation } = useMutation({
    mutationFn: (sessionRef) =>
      electricVehicleChargingService.forceStopChargingSession(sessionRef),
    onSuccess: () => {
      showLocalizedMoovyToast(intl, {
        title:
          'chargings.chargingSessionsContent.action.stopSession.success.title',
        description:
          'chargings.chargingSessionsContent.action.stopSession.success.description'
      })
      refresh()
    },
    onError: () => {
      showLocalizedMoovyToast(intl, {
        type: 'error',
        title:
          'chargings.chargingSessionsContent.action.stopSession.error.title',
        description:
          'chargings.chargingSessionsContent.action.stopSession.error.description'
      })
    }
  })

  return (
    <>
      <ChargingSessionsTable
        query={query}
        sessions={sessions}
        sortedColumn={sortColumn}
        onPageChange={onPageChange}
        totalPages={totalPages}
        activePage={activePage}
        sortDirection={sortDirection}
        onSort={onSort}
        onStopSession={stopSession}
        stopSessionMutation={stopSessionMutation}
      />
    </>
  )
}

export default ChargingSessionsContent
