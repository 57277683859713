import { Button, Form, Icon, Popup, Select } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import { MoovyDateRangePicker, MoovyIconInfo } from '../../../components'
import React, { useEffect } from 'react'
import {
  commonEnum,
  frontendParkingTypeEnum,
  getStringValue,
  serviceType
} from '../../../services/utils'

import { activeSearchTypeEnum } from './'

function ParkingEndedFilters({
  resetView,
  loading,
  onSearch,
  values,
  setValues,
  activeSearchButtonType,
  setActiveSearchButtonType
}) {
  const intl = useIntl()

  const searchBySevenDays = 7
  const searchByThirtyOneDays = 31

  const parkingOptions = Object.keys(frontendParkingTypeEnum)
    .filter(
      (item) =>
        item !== 'localizationKeys' &&
        item !== frontendParkingTypeEnum.PARKMAN_PARKING &&
        item !== frontendParkingTypeEnum.EASYPARK_PARKING
    )
    .map((key) => {
      return {
        key: key,
        value: key,
        text: intl.formatMessage({
          id: getStringValue(frontendParkingTypeEnum.localizationKeys, key)
        })
      }
    })

  const serviceOptions = [
    {
      key: commonEnum.ALL,
      value: commonEnum.ALL,
      text: intl.formatMessage({
        id: 'common.all'
      })
    }
  ].concat(
    [serviceType.PAY_AS_YOU_GO, serviceType.SUBSCRIPTION].map((key) => ({
      key: key,
      value: key,
      text: intl.formatMessage({ id: serviceType.localizationKeys[key] ?? key })
    }))
  )

  useEffect(() => {
    resetView()
  }, [activeSearchButtonType])

  const setFilterValue = (filterType, filterValue) => {
    let updateValues = { ...values }
    if (
      filterType === 'parkingType' &&
      filterValue != frontendParkingTypeEnum.MOOVY_PARKING
    ) {
      updateValues.serviceType = commonEnum.ALL
    }
    updateValues[filterType] = filterValue
    updateValues.activePage = 1
    setValues({ ...updateValues })
  }

  const isValidLicensePlateNumber = (licensePlateNumber) => {
    return licensePlateNumber?.length >= 2
  }

  const onSubmit = () => {
    if (
      activeSearchButtonType ===
      activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER
    ) {
      isValidLicensePlateNumber(values.partialLicensePlateNumber) && onSearch()
    } else {
      onSearch()
    }
  }

  return (
    <Form autoComplete="off" onSubmit={onSubmit}>
      <Form.Group>
        <Form.Field>
          <label>
            <FormattedMessage id={'common.button.search'} />
          </label>
          <Button.Group>
            <Popup
              on={'hover'}
              content={
                <FormattedMessage
                  id={'parkingEndedFilters.popup.content.car'}
                />
              }
              trigger={
                <Button
                  primary={
                    activeSearchButtonType ===
                    activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER
                  }
                  type="button"
                  icon="car"
                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                  onClick={() =>
                    setActiveSearchButtonType(
                      activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER
                    )
                  }
                />
              }
            />
            <Button.Or
              text={intl.formatMessage({
                id: 'parkingEndedFilters.button.label.or'
              })}
            />
            <Popup
              on={'hover'}
              content={
                <FormattedMessage
                  id={'parkingEndedFilters.popup.content.calendar'}
                />
              }
              trigger={
                <Button
                  primary={
                    activeSearchButtonType ===
                    activeSearchTypeEnum.SEARCH_BY_DATE_RANGE
                  }
                  type="button"
                  icon="calendar"
                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                  onClick={() =>
                    setActiveSearchButtonType(
                      activeSearchTypeEnum.SEARCH_BY_DATE_RANGE
                    )
                  }
                />
              }
            />
          </Button.Group>
        </Form.Field>
        <Form.Field
          control={Select}
          label={intl.formatMessage({
            id: 'parkingEndedFilters.form.label.parkingType'
          })}
          defaultValue={frontendParkingTypeEnum.ALL}
          options={parkingOptions}
          value={values.parkingType}
          name="parkingType"
          onChange={(event, { name, value }) => setFilterValue(name, value)}
        />
        {values.parkingType === frontendParkingTypeEnum.MOOVY_PARKING && (
          <Form.Field
            control={Select}
            label={intl.formatMessage({
              id: 'parkingNowFilters.form.label.serviceType'
            })}
            options={serviceOptions}
            value={values.serviceType}
            name="serviceType"
            onChange={(event, { name, value }) => setFilterValue(name, value)}
          />
        )}
        {activeSearchButtonType ===
        activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER ? (
          <Form.Input
            label={intl.formatMessage({
              id: 'parkingEndedFilters.form.label.licensePlate'
            })}
            name="partialLicensePlateNumber"
            value={values.partialLicensePlateNumber}
            onChange={(e, { name, value }) =>
              setFilterValue(name, value?.trim())
            }
            autoFocus={true}
            error={!isValidLicensePlateNumber(values.partialLicensePlateNumber)}
            icon={
              <Icon
                name={values.partialLicensePlateNumber ? 'delete' : ''}
                link={!!values.partialLicensePlateNumber}
                onClick={() =>
                  setValues({ ...values, partialLicensePlateNumber: '' })
                }
              />
            }
          />
        ) : (
          ''
        )}
        <Form.Field>
          <label>
            <MoovyIconInfo
              iconLabel={
                activeSearchButtonType ===
                activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER ? (
                  <FormattedMessage
                    id={'parkingEndedFilters.form.label.dataRange'}
                    values={{ days: searchByThirtyOneDays }}
                  />
                ) : (
                  <FormattedMessage
                    id={'parkingEndedFilters.form.label.dataRange'}
                    values={{ days: searchBySevenDays }}
                  />
                )
              }
            >
              {intl.formatMessage({
                id: 'parkingEndedFilters.form.label.dataRange.info'
              })}
            </MoovyIconInfo>
          </label>
          <MoovyDateRangePicker
            name="parkingEndedDateRange"
            placeholderText={intl.formatMessage({
              id: 'moovyTableFilter.filter.placeholder.chooseTimeRange'
            })}
            maxRangeAsDays={
              activeSearchButtonType ===
              activeSearchTypeEnum.SEARCH_BY_LICENSE_PLATE_NUMBER
                ? searchByThirtyOneDays
                : searchBySevenDays
            }
            startDate={values.timeRange.startTime}
            endDate={values.timeRange.endTime}
            onChange={(dates, event) => {
              const [start, end, valid] = dates
              setFilterValue('timeRange', {
                startTime: start,
                endTime: end,
                valid
              })
            }}
          />
        </Form.Field>
        <Form.Field>
          <label>
            <FormattedMessage id={'common.button.search'} />
          </label>
          <Button type="submit" primary loading={loading}>
            <FormattedMessage id={'common.button.search'} />
          </Button>
        </Form.Field>
      </Form.Group>
    </Form>
  )
}
export default ParkingEndedFilters
