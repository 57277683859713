import './Finances.scss'

import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import financesService from '../../services/Finances'
import FinancesFilterOperator from './FinancesFilterOperator'
import FinancesFilterYearMonth from './FinancesFilterYearMonth'
import FinancesPane from './FinancesPane'

const ReportOpenBarrierlessFacilityDebtCollectionCases = () => {
  const intl = useIntl()
  const [mandator, setMandator] = useState([])

  const onChangeMandatorValue = (value) => {
    setMandator(value)
  }

  const [yearAndMonth, setYearAndMonth] = useState({
    year: DateTime.local().year.toString(),
    month: DateTime.local().month.toString()
  })

  return (
    <FinancesPane
    extraContent={
      <FinancesFilterOperator onChangeMandatorValue={onChangeMandatorValue} />
    }
      text={intl.formatMessage({
        id: 'finances.tab.reportOpenBarrierlessFacilityDebtCollectionCases.header'
      })}
      buttonCallback={() =>
        financesService.downloadOpenBarrierlessFacilityDebtCollectionCasesReport(
          {...yearAndMonth, settlementRecipientRef: mandator }
        )
      }
    >
      <FinancesFilterYearMonth
        yearAndMonth={yearAndMonth}
        setYearAndMonth={setYearAndMonth}
      />
    </FinancesPane>
  )
}

export default ReportOpenBarrierlessFacilityDebtCollectionCases
