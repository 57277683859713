import { Grid, List } from 'semantic-ui-react'
import {
  settlementRecipientCompanyTypeEnum as companyTypeEnum,
  getStringValue
} from '../../../services/utils/DTOEnums'

import { FormattedMessage } from 'react-intl'
import React from 'react'
import { getCountryNameByCode } from '../../../services/utils'

const SettlementRecipientToolbar = ({ settlementRecipient }) => {
  return (
    <>
      <Grid columns="4" style={{ maxWidth: '1200px' }}>
        <Grid.Column width={3}>
          <List>
            <List.Item>
              <b>
                <FormattedMessage id="settlementRecipient.toolbar.label.businessId" />
                {': '}
              </b>
              {settlementRecipient.businessId}
            </List.Item>
            <List.Item>{settlementRecipient.streetAddress || ''}</List.Item>
            <List.Item>{`${settlementRecipient.postalCode ||
              ''} ${settlementRecipient.city || ''}`}</List.Item>
            <List.Item>
              {settlementRecipient.countryCode &&
                getCountryNameByCode(settlementRecipient.countryCode)}
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={3}>
          <List>
            <List.Item>
              <b>
                <FormattedMessage id="settlementRecipient.toolbar.label.extensionName" />
              </b>
            </List.Item>
            <List.Item>
              <List.Content>
                {settlementRecipient.nameExtension || '-'}
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column width={4}>
          <List>
            <List.Item>
              <b>
                <FormattedMessage id="settlementRecipient.toolbar.label.contact" />
              </b>
            </List.Item>
            <List.Item>
              <List.Content>
                {settlementRecipient.contact?.name || ''}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="phone" />{' '}
              <List.Content>
                {settlementRecipient.contact?.phone ? (
                  <a href={'tel:' + settlementRecipient.contact?.phone}>
                    {settlementRecipient.contact?.phone}
                  </a>
                ) : (
                  '-'
                )}
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="at" />{' '}
              <List.Content>
                {settlementRecipient.contact?.email ? (
                  <a href={'mailto:' + settlementRecipient.contact?.email}>
                    {settlementRecipient.contact?.email}
                  </a>
                ) : (
                  '-'
                )}
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
        <Grid.Column>
          <List>
            <List.Item>
              <b>
                <FormattedMessage id="settlementRecipient.toolbar.label.companyType" />
              </b>
            </List.Item>
            <List.Item>
              <List.Content>
                {(settlementRecipient.companyType && (
                  <FormattedMessage
                    id={getStringValue(
                      companyTypeEnum.localizationKeys,
                      settlementRecipient.companyType
                    )}
                    defaultMessage={settlementRecipient.companyType}
                  />
                )) ||
                  '-'}
              </List.Content>
            </List.Item>
            <List.Item>
              <b>
                <FormattedMessage id="settlementRecipient.toolbar.label.operator" />
              </b>
            </List.Item>
            <List.Item>
              <List.Content>
                {settlementRecipient?.operator?.name || '-'}
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
    </>
  )
}

export default SettlementRecipientToolbar
