import { Form, Header } from 'semantic-ui-react'
import GenericMessage, {
  createErrorMessage
} from '../../../components/GenericMessage'
import React, { useState } from 'react'

import PropTypes from 'prop-types'
import VoucherModal from '../../../components/VoucherModal'
import VouchersTable from '../../../components/VouchersTable'
import { voucher as voucherEnums } from '../../../services/utils/DTOEnums'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import locationService from '../../../services/Locations'

const categoryOptions = [{ key: 'all', text: 'Kaikki', value: 'ALL' }].concat(
  [
    voucherEnums.categoryTypes.BENEFIT,
    voucherEnums.categoryTypes.PREBOOKING,
    voucherEnums.categoryTypes.LOW_EMISSION_VEHICLE,
    voucherEnums.categoryTypes.HYPERIN_SINGLE_USE
  ].map((type) => ({
    key: type,
    text: voucherEnums.categoryTypes.stringValues[type],
    value: type
  }))
)

const pageSize = 20

export default function LocationVouchers({ operatorRealm, locationRef }) {
  const queryClient = useQueryClient()
  const [formValues, setValues] = useState({
    licensePlateNumber: '',
    phoneNumber: '',
    categoryType: undefined
  })
  const [submittedValues, setSubmittedValues] = useState({})
  const [selectedVoucher, setSelectedVoucher] = useState(null)

  const hasBeenSubmitted = Object.keys(submittedValues).length > 0
  const query = useQuery({
    queryKey: ['locationVouchers', locationRef, submittedValues],
    queryFn: () =>
      locationService.fetchLocationVouchers(
        locationRef,
        operatorRealm,
        submittedValues
      ),
    enabled: hasBeenSubmitted,
    staleTime: 1000 * 60 * 5
  })

  const vouchers = query.data || {
    totalResults: 0,
    data: []
  }

  const activePage = submittedValues.page ? submittedValues.page + 1 : 1

  const handleSubmit = (evt) => {
    evt.preventDefault()
    const values = { ...formValues }
    if (values.categoryType === 'ALL') {
      // Backend has no 'All' type, empty means all
      delete values.categoryType
    }
    const paginatedValues = { ...values, page: 0, limit: pageSize }
    setSubmittedValues(paginatedValues)
    queryClient.invalidateQueries({
      queryKey: ['locationVouchers', locationRef, paginatedValues]
    })
  }

  const changePage = (e, { activePage }) => {
    setSubmittedValues((prevState) => ({ ...prevState, page: activePage - 1 }))
  }

  const onChange = (e) =>
    setValues({ ...formValues, [e.target.name]: e.target.value })
  const onSelect = (e, { value }) =>
    setValues({ ...formValues, categoryType: value })

  const handleUpdate = (updated) => {
    setSelectedVoucher(updated)
    queryClient.invalidateQueries(['locationVouchers', locationRef])
  }

  return (
    <div>
      <Header>Kohteeseen myönnetyt pysäköintiedut</Header>
      <Form onSubmit={handleSubmit}>
        <Form.Group widths="equal">
          <Form.Input
            label="Rekisteritunnus"
            name="licensePlateNumber"
            value={formValues.licensePlateNumber}
            onChange={onChange}
          />
          <Form.Input
            label="Puhelinnumero"
            name="phoneNumber"
            value={formValues.phoneNumber}
            onChange={onChange}
          />
          <Form.Select
            fluid
            label="Kategoria"
            name="categoryType"
            value={formValues.categoryType}
            onChange={onSelect}
            options={categoryOptions}
            placeholder="Kaikki"
            selectOnBlur={false}
          />
          <Form.Button
            className="hideLabel"
            primary
            content="Hae"
            label="hide this"
            loading={query.isFetching}
            disabled={query.isFetching}
          />
        </Form.Group>
      </Form>
      <GenericMessage
        message={
          vouchers.error
            ? createErrorMessage('Etujen lataamisessa tapahtui virhe')
            : null
        }
      />

      <VouchersTable
        vouchers={vouchers.data}
        onClickVoucher={setSelectedVoucher}
        activePage={activePage}
        pageSize={pageSize}
        totalResults={vouchers.totalResults}
        changePage={changePage}
        query={query}
        fetchPending={!hasBeenSubmitted}
      />

      {selectedVoucher && (
        <VoucherModal
          voucher={selectedVoucher}
          operatorRealm={operatorRealm}
          onClose={() => setSelectedVoucher(null)}
          onVoucherUpdate={handleUpdate}
          allowEdit
        />
      )}
    </div>
  )
}

LocationVouchers.propTypes = {
  operatorRealm: PropTypes.string,
  locationRef: PropTypes.string.isRequired
}
