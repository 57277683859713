import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, Icon, Table } from 'semantic-ui-react'

import { ModalWebPaymentSaleStatus } from '../../components'
import {
  formatDateAndTime,
  formatPrice,
  PermissionWebPayment
} from '../../services/utils'
import {
  getStringValue,
  sale as saleEnums
} from '../../services/utils/DTOEnums'
import useAuthInfo from '../../hooks/useAuthInfo'

const FILTER_ALL = 'all'
const FILTER_VALID = 'valid'

const ParkingSales = ({ sales, parking, disableRowSelecting }) => {
  const [selectedSale, setSelectedSale] = useState(null)
  const [filter, setFilter] = useState(FILTER_ALL)
  const { roles } = useAuthInfo()

  const canSelectRows =
    !disableRowSelecting && PermissionWebPayment.HasWebPaymentEdit(roles)
  const intl = useIntl()

  const isSaleValid = (sale) => saleEnums.validity.VALID === sale.validity

  const filteredSales = sales.filter((sale) =>
    filter === FILTER_ALL ? true : isSaleValid(sale)
  )

  const TableFooter = ({ filteredSales }) => {
    if (filteredSales.length < 1) {
      return <></>
    }
    const hasSaleFinalAndValid = (sale) => {
      if (!sale) {
        return false
      }

      return isSaleValid(sale) && sale.isFinal
    }

    return (
      <Table.Footer fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>
            {formatPrice(
              filteredSales.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                    (hasSaleFinalAndValid(currentValue) &&
                      currentValue?.price) ?? 0,
                0
              )
            )}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {formatPrice(
              filteredSales.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                    (hasSaleFinalAndValid(currentValue) &&
                      currentValue?.priceReductions) ?? 0,
                0
              )
            )}
          </Table.HeaderCell>
          <Table.HeaderCell>
            {formatPrice(
              filteredSales.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (hasSaleFinalAndValid(currentValue)
                    ? (currentValue?.price ?? 0) -
                      (currentValue?.priceReductions ?? 0)
                    : 0),
                0
              )
            )}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="4" />
        </Table.Row>
      </Table.Footer>
    )
  }

  return (
    <>
      <Form>
        <Form.Group inline>
          <label>
            <FormattedMessage id="parkingModal.parkingSales.filter.title" />
          </label>
          <Form.Radio
            label={intl.formatMessage({
              id: 'parkingModal.parkingSales.filter.all'
            })}
            value={FILTER_ALL}
            checked={filter === FILTER_ALL}
            onChange={() => setFilter(FILTER_ALL)}
          />
          <Form.Radio
            label={intl.formatMessage({
              id: 'parkingModal.parkingSales.filter.onlyValid'
            })}
            value={FILTER_VALID}
            checked={filter === FILTER_VALID}
            onChange={() => setFilter(FILTER_VALID)}
          />
        </Form.Group>
      </Form>
      <Table selectable={canSelectRows}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <FormattedMessage id="parkingModal.parkingSales.table.header.time" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingModal.parkingSales.table.header.price" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingModal.parkingSales.table.header.reductions" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingModal.parkingSales.table.header.totalPrice" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingModal.parkingSales.table.header.type" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingModal.parkingSales.table.header.paymentMethod" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingModal.parkingSales.table.header.final" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <FormattedMessage id="parkingModal.parkingSales.table.header.valid" />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filteredSales.map((sale) => {
            const {
              ref,
              incurredAt,
              price: salePrice,
              priceReductions,
              type: saleType,
              paymentMethod,
              isFinal
            } = sale
            const isValid = isSaleValid(sale)
            return (
              <Table.Row
                key={ref}
                positive={isValid}
                onClick={() =>
                  setSelectedSale(
                    canSelectRows &&
                      paymentMethod === saleEnums.paymentMethodTypes.WEBPAYMENT
                      ? sale
                      : null
                  )
                }
              >
                <Table.Cell>{formatDateAndTime(incurredAt)}</Table.Cell>
                <Table.Cell>{formatPrice(salePrice ?? 0)}</Table.Cell>
                <Table.Cell>{formatPrice(priceReductions ?? 0)}</Table.Cell>
                <Table.Cell>
                  {formatPrice((salePrice ?? 0) - (priceReductions ?? 0))}
                </Table.Cell>
                <Table.Cell>{saleType}</Table.Cell>
                <Table.Cell>
                  <FormattedMessage
                    id={getStringValue(
                      saleEnums.paymentMethodTypes.localizationKeys,
                      paymentMethod
                    )}
                    defaultMessage={paymentMethod}
                  />
                </Table.Cell>
                <Table.Cell>
                  {isFinal && <Icon className="primaryColor" name="check" />}
                </Table.Cell>
                <Table.Cell>
                  {isValid && <Icon className="primaryColor" name="check" />}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
        <TableFooter filteredSales={filteredSales} />
      </Table>
      {selectedSale && (
        <ModalWebPaymentSaleStatus
          licensePlate={parking.licensePlateNumber}
          sale={selectedSale}
          onClose={() => setSelectedSale(null)}
        />
      )}
    </>
  )
}

export default ParkingSales
