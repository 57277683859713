import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import './SubMenu.scss'

const Action = ({ title, action, extraClasses }) => {
  switch (typeof action) {
    case 'function':
      return (
        <span
          className={classNames('clickable', extraClasses)}
          onClick={action}
        >
          {title}
        </span>
      )
    case 'string':
      return <Link to={action}>{title}</Link>
    default:
      return <span>{title}</span>
  }
}

const SubMenu = ({ title, actions, renderContent }) => (
  <div className={classNames('SubMenu')}>
    <div className="SubMenu--Title">
      <Action title={title} />
    </div>
    <div className="SubMenu--Content">
      {actions.map(({ action, title, extraClasses }, idx) => (
        <div key={idx} className="SubMenu--Item">
          <Action title={title} action={action} extraClasses={extraClasses} />
        </div>
      ))}
      {renderContent}
    </div>
  </div>
)

SubMenu.propTypes = {
  title: PropTypes.node.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node.isRequired,
      action: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
    })
  )
}

export default SubMenu
