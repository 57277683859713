import PropTypes from 'prop-types'
import React from 'react'
import { Table } from 'semantic-ui-react'

import { serviceAgreementShape } from '../../services/utils'

const ServiceAgreementTable = ({ serviceAgreements }) => (
  <Table color="blue">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Tuote</Table.HeaderCell>
        <Table.HeaderCell>Käyttäjäkohtainen kiintiö</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {serviceAgreements.map((agreement) => (
        <Table.Row key={agreement.ref}>
          <Table.Cell>{agreement.service.name}</Table.Cell>
          <Table.Cell>{agreement.maxCount}</Table.Cell>
        </Table.Row>
      ))}
    </Table.Body>
  </Table>
)

ServiceAgreementTable.propTypes = {
  serviceAgreements: PropTypes.arrayOf(PropTypes.shape(serviceAgreementShape))
    .isRequired
}

export default ServiceAgreementTable
