import { Formik } from 'formik'
import React, { useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMutation } from '@tanstack/react-query'
import countryList from 'react-select-country-list'
import { Button, Form, Modal } from 'semantic-ui-react'
import * as yup from 'yup'

import { MutationErrorMessage } from '../../../../components'
import { showLocalizedMoovyToast } from '../../../../components/MoovyToast'
import settlementRecipientService from '../../../../services/SettlementRecipients'
import { ultimateBeneficialOwnerEnums } from '../../../../services/utils/DTOEnums'

const ModalAddSignatoryOwner = ({
  settlementRecipientRef,
  onClose,
  onAdded
}) => {
  const intl = useIntl()
  const countryOptions = useMemo(
    () =>
      countryList()
        .getData()
        .map((item) => {
          return { key: item.value, text: item.label, value: item.value }
        }),
    []
  )

  const [defaultValues] = useState({
    firstNames: '',
    lastName: '',
    countryCodeOfResidence: 'FI',
    jobTitle: '',
    reason: ultimateBeneficialOwnerEnums.reason.DUE_TO_SIGNATORY
  })

  const validationSchema = () => {
    return yup.object().shape({
      firstNames: yup.string().required('Cannot be empty'),
      lastName: yup.string().required('Cannot be empty'),
      countryCodeOfResidence: yup.string().required('Cannot be empty'),
      jobTitle: yup.string().required('Cannot be empty'),
      reason: yup.string().required('Cannot be empty')
    })
  }

  const { mutate: addOwner, ...addOwnerMutation } = useMutation({
    mutationFn: (values) => {
      return settlementRecipientService.addUltimateBeneficialOwner(
        settlementRecipientRef,
        values
      )
    },
    onSuccess: (response, variables) => {
      showLocalizedMoovyToast(intl, {
        title: 'modalOwner.toast.title.signatoryAdded',
        description: 'modalOwner.toast.description.signatoryAdded'
      })
      onAdded()
      const { resetForm } = variables
      resetForm({})
    }
  })

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema()}
      onSubmit={(values, { resetForm }) =>
        addOwner({ ...values, resetForm: resetForm })
      }
      enableReinitialize={true}
    >
      {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
        <Modal open onClose={onClose}>
          <Modal.Header>
            <FormattedMessage id="modalOwner.button.addSignatory" />
          </Modal.Header>
          <Modal.Content>
            <MutationErrorMessage
              mutation={addOwnerMutation}
              defaultErrorTextLangId="modalOwner.form.add.error"
              messageNegative
            />
            <Form onSubmit={handleSubmit}>
              <Form.Group widths="equal">
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.firstNames'
                  })}
                  name="firstNames"
                  value={values.firstNames}
                  onChange={handleChange}
                  error={!!errors.firstNames}
                />
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.lastName'
                  })}
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  error={!!errors.lastName}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Dropdown
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.countryCodeOfResidence'
                  })}
                  name="countryCodeOfResidence"
                  fluid
                  search
                  selection
                  options={countryOptions}
                  value={values.countryCodeOfResidence}
                  onChange={(event, data) => {
                    setFieldValue(data.name, data.value)
                  }}
                  error={!!errors.countryCodeOfResidence}
                  selectOnBlur={false}
                />
                <Form.Input
                  label={intl.formatMessage({
                    id: 'modalOwner.form.label.jobTitle'
                  })}
                  name="jobTitle"
                  value={values.jobTitle}
                  onChange={handleChange}
                  error={!!errors.jobTitle}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={onClose}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              loading={addOwnerMutation.isPending}
              disabled={addOwnerMutation.isPending}
            >
              <FormattedMessage id="common.button.add" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default ModalAddSignatoryOwner
