import { Button, Form, Message, Modal, Segment } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import voucherTemplateService, {
  getVoucherTemplateDescription
} from '../../services/VoucherTemplates'

import { DateTimeInput } from 'semantic-ui-calendar-react'
import { FormattedMessage } from 'react-intl'
import { Formik } from 'formik'
import { MutationErrorMessage } from '..'
import moovyDateTime from '../../services/utils/moovyDateTime'
import { useMutation } from '@tanstack/react-query'
import userVoucherService from '../../services/UserVouchers'
import { validationSchema } from './formValidationSchema'
import { voucher as voucherEnums } from '../../services/utils/DTOEnums'

const GrantedVoucherManageModal = ({
  defaultTemplate,
  onSaveGrantedVoucher,
  onClose
}) => {
  const [viewVoucherTemplates, setViewVoucherTemplates] = useState([])
  const [voucherTemplates, setVoucherTemplates] = useState([])
  const [selectedVoucherTemplate, setSelectedVoucherTemplate] = useState(
    defaultTemplate || ''
  )

  useEffect(() => {
    voucherTemplateService.fetchVoucherTemplates().then((res) => {
      setVoucherTemplates(res)
      setViewVoucherTemplates(
        (res &&
          res
            .sort((a, b) =>
              `${a.validLocationsDescription}`.localeCompare(
                b.validLocationsDescription
              )
            )
            .filter((template) => template.active)
            .map((template) => ({
              key: template.ref,
              value: template.ref,
              text: getVoucherTemplateDescription(template)
            }))) ||
          []
      )
    })
  }, [])

  const getDefaultFormikData = () => {
    return {
      voucherTemplateRef: (defaultTemplate && defaultTemplate.ref) || '',
      phoneNumber: '',
      licensePlateNumber: '',
      startDateTime: '',
      endDateTime: ''
    }
  }

  const changeVoucherTemplateValue = (value) => {
    const voucherTemplate = voucherTemplates.find((item) => item.ref === value)
    setSelectedVoucherTemplate(voucherTemplate)

    return voucherTemplate
  }

  const getEndDateTimeValue = (startValue, endValue, validityInMinutes) => {
    if (!startValue && !endValue) {
      return ''
    }

    if (endValue) {
      return endValue
    }

    const startDatetime =
      moovyDateTime.calendarDateTimeInputToDateTime(startValue)

    if (!startDatetime) {
      return ''
    }

    if (validityInMinutes) {
      return moovyDateTime.dateTimeToCalendarInput(
        startDatetime.plus({ minutes: validityInMinutes })
      )
    }

    return moovyDateTime.dateTimeToCalendarInput(startDatetime)
  }

  const { mutate: onSubmit, ...onSubmitMutation } = useMutation({
    mutationFn: ({ values }) => {
      const {
        voucherTemplateRef,
        phoneNumber,
        licensePlateNumber,
        startDateTime,
        endDateTime
      } = values
      if (selectedVoucherTemplate.ref !== voucherTemplateRef) {
        // This should never happen. It means that voucher of the state property does not match to formik voucher ref.
        console.warn('template ref does not match')
        return Promise.reject()
      }

      const voucherData = {}

      switch (selectedVoucherTemplate.allowedGrantType) {
        case voucherEnums.grantTypes.PHONE_NUMBER:
          voucherData.phoneNumber = phoneNumber
          break
        case voucherEnums.grantTypes.LICENSE_PLATE_NUMBER:
          voucherData.licensePlateNumber = licensePlateNumber
          break
        case voucherEnums.grantTypes.ALL:
          if (phoneNumber) {
            voucherData.phoneNumber = phoneNumber
          }
          if (licensePlateNumber) {
            voucherData.licensePlateNumber = licensePlateNumber
          }
          break
        default:
          console.warn('VoucherData cannot be filled')
          return Promise.reject()
      }

      voucherData.validFromTime = moovyDateTime.dateTimeToBackendFormat(
        moovyDateTime.calendarDateTimeInputToDateTime(startDateTime)
      )
      voucherData.validUntilTime = moovyDateTime.dateTimeToBackendFormat(
        moovyDateTime.calendarDateTimeInputToDateTime(endDateTime)
      )

      return userVoucherService.grantVoucher(voucherData, voucherTemplateRef)
    },

    onSuccess: (response) => onSaveGrantedVoucher(response)
  })

  const renderPhoneNumber = (values, handleChange, handleBlur, errors) => {
    return (
      <>
        <Form.Input
          label="Asiakkaan puhelinnumero"
          value={values.phoneNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          name="phoneNumber"
          error={errors.phoneNumber !== undefined}
        />
      </>
    )
  }

  const renderLicensePlateNumber = (
    values,
    handleChange,
    handleBlur,
    errors
  ) => {
    return (
      <>
        <Form.Input
          label="Asiakkaan rekisteritunnus"
          value={values.licensePlateNumber}
          onChange={handleChange}
          onBlur={handleBlur}
          name="licensePlateNumber"
          error={errors.licensePlateNumber !== undefined}
        />
      </>
    )
  }

  const renderGrantContent = (
    grantType,
    values,
    handleChange,
    handleBlur,
    errors
  ) => {
    switch (grantType) {
      case voucherEnums.grantTypes.PHONE_NUMBER:
        return renderPhoneNumber(values, handleChange, handleBlur, errors)
      case voucherEnums.grantTypes.LICENSE_PLATE_NUMBER:
        return renderLicensePlateNumber(
          values,
          handleChange,
          handleBlur,
          errors
        )
      case voucherEnums.grantTypes.ALL:
        return (
          <>
            <Form.Field label="Syötä vähintään toinen"></Form.Field>
            <Segment.Group>
              <Segment>
                {renderPhoneNumber(values, handleChange, handleBlur, errors)}
                {renderLicensePlateNumber(
                  values,
                  handleChange,
                  handleBlur,
                  errors
                )}
              </Segment>
            </Segment.Group>
          </>
        )
      default:
        break
    }
  }

  const renderDescriptionContent = ({
    customDescription,
    description,
    grantedDiscountPercent,
    benefitType,
    categoryType
  }) => {
    if (!customDescription && !description && !grantedDiscountPercent) {
      return ''
    }

    const bulletList = []

    if (customDescription) {
      bulletList.push(customDescription)
    }

    if (description) {
      bulletList.push(description)
    }

    if (benefitType) {
      bulletList.push(
        `Pysäköintiedun tyyppi: ${voucherEnums.benefitTypes.stringValues[benefitType]}`
      )
    }

    if (categoryType) {
      bulletList.push(
        `Pysäköintiedun kategoria: ${voucherEnums.categoryTypes.stringValues[categoryType]}`
      )
    }

    return <Message compact list={bulletList} />
  }

  return (
    <Formik
      initialValues={getDefaultFormikData()}
      validationSchema={validationSchema()}
      onSubmit={(values) => onSubmit({ values })}
    >
      {({
        values,
        errors,
        handleChange,
        handleSubmit,
        handleBlur,
        setFieldValue,
        setValues
      }) => (
        <Modal open onClose={onClose}>
          <Modal.Header content="Myönnä uusi pysäköintietu" />
          <Modal.Content>
            <MutationErrorMessage
              mutation={onSubmitMutation}
              defaultErrorTextLangId="voucherTemplates.grantVoucher.error"
              messageNegative
            />
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Form.Select
                placeholder="Valitse pysäköintietu"
                label="Pysäköintietu"
                name="voucherTemplateRef"
                fluid
                value={values.voucherTemplateRef}
                options={viewVoucherTemplates}
                onChange={(e, value) => {
                  const selectedTemplate = changeVoucherTemplateValue(
                    value.value
                  )
                  setValues({
                    ...values,
                    voucherTemplateRef: value.value,
                    endDateTime: getEndDateTimeValue(
                      value.startDateTime,
                      '',
                      selectedTemplate.validityInMinutes
                    )
                  })
                }}
                error={errors.voucherTemplateRef !== undefined}
                selectOnBlur={false}
              />
              {renderDescriptionContent(selectedVoucherTemplate)}
              {selectedVoucherTemplate &&
              selectedVoucherTemplate.allowedGrantType
                ? renderGrantContent(
                    selectedVoucherTemplate.allowedGrantType,
                    values,
                    handleChange,
                    handleBlur,
                    errors
                  )
                : ''}
              <Form.Group inline>
                <DateTimeInput
                  label="Voimassaolo alkaa"
                  value={values.startDateTime}
                  onChange={(e, value) => {
                    setValues({
                      ...values,
                      startDateTime: value.value,
                      endDateTime: getEndDateTimeValue(
                        value.value,
                        '',
                        selectedVoucherTemplate.validityInMinutes
                      )
                    })
                  }}
                  dateFormat="DD.MM.YYYY"
                  name="startDateTime"
                  placeholder="Alkamisaika"
                  iconPosition="left"
                  animation="none"
                  closable
                  error={!!errors.startDateTime}
                />
                <DateTimeInput
                  label="Voimassaolo päättyy"
                  value={values.endDateTime}
                  clearable
                  onChange={(e, value) => {
                    value.value
                      ? setFieldValue(
                          'endDateTime',
                          getEndDateTimeValue(values.startDateTime, value.value)
                        )
                      : setFieldValue('endDateTime', '')
                  }}
                  dateFormat="DD.MM.YYYY"
                  name="endDateTime"
                  placeholder="Päättymisaika"
                  iconPosition="left"
                  animation="none"
                  closable
                  error={!!errors.endDateTime}
                />
              </Form.Group>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => onClose()}>
              <FormattedMessage id="common.button.cancel" />
            </Button>
            <Button
              type="submit"
              primary
              onClick={handleSubmit}
              disabled={onSubmitMutation.isPending}
              loading={onSubmitMutation.isPending}
            >
              <FormattedMessage id="common.button.save" />
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Formik>
  )
}

export default GrantedVoucherManageModal
