import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Checkbox, Form, Header, Radio, TextArea } from 'semantic-ui-react'
import * as yup from 'yup'

import {
  addMissingValues,
  findChangedValues,
  isEmptyObject
} from './ManagementUtils'
import StepNextButton from './StepNextButton'

export const ServiceForm = ({ formValues, onDataCompleted }) => {
  const validationSchema = () => {
    return yup.object().shape({
      service: yup.object().shape({
        name: yup.string().required('Cannot be empty'),
        timezone: yup.string().required('Cannot be empty'),
        parkingPermitType: yup.string().required('Cannot be empty'),
        type: yup.string().required('Cannot be empty')
      })
    })
  }

  const [initialValues, setInitialValues] = useState({
    service: {
      name: '',
      type: '',
      publicService: false,
      exceedingParkingCapacityAllowed: false,
      parkingPermitType: 'SESSION_BASED_PARKING_PERMIT',
      timezone: 'Europe/Helsinki',
      capacity: '',
      parkingCapacity: '',
      userAssignmentOverbookingCoefficient: 1,
      description: ''
    }
  })
  const [values, setValues] = useState(initialValues)

  useEffect(() => {
    if (isEmptyObject(formValues)) {
      setValues(initialValues)
    } else {
      const currentValues = addMissingValues(formValues, initialValues.service)
      const changedValues = findChangedValues(currentValues, values)
      if (changedValues) {
        setValues({ service: { ...currentValues } })
        setInitialValues({ service: { ...currentValues } })
      }
    }
  }, [formValues])

  const onCheckboxClicked = (e, { name, checked }) => {
    updateValue(name, checked)
  }

  const onChange = (e, { name, value }) => {
    updateValue(name, value)
  }

  const updateValue = (name, value) => {
    let newValues = { ...values }
    const rootName = name.split('.')[0]
    const childName = name.split('.')[1]
    newValues[rootName][childName] = value
    setValues({ ...newValues })
  }

  const onSubmitForm = () => {
    onDataCompleted({ ...values.service }, 'service')
  }

  const markServiceDefaultValues = (checked, setFieldValue) => {
    let newValues = { ...values }
    newValues.service.publicService = checked
    setValues(newValues)
    setFieldValue('service.publicService', checked)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema()}
        onSubmit={(values, actions) => {
          onSubmitForm(values, actions)
        }}
        enableReinitialize={true}
      >
        {({ errors, handleChange, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <StepNextButton />
            <Header>Tuote</Header>
            <Form.Group widths="equal">
              <Form.Input
                label="Nimi"
                name="service.name"
                type="text"
                value={values.service.name}
                onChange={(event, data) => {
                  onChange(event, data)
                  handleChange(event, data)
                }}
                error={errors.service && !!errors.service.name}
              />

              <Form.Input
                label="Aikavyöhyke"
                name="service.timezone"
                type="text"
                value={values.service.timezone}
                onChange={(event, data) => {
                  onChange(event, data)
                  handleChange(event, data)
                }}
                error={errors.service && !!errors.service.timezone}
              />
            </Form.Group>
            <Form.Group inline>
              <Form.Field error={errors.service && !!errors.service.type}>
                <label>Tyyppi</label>
                <Form.Field
                  control={Radio}
                  name="service.type"
                  label="Kertapysäköinti"
                  value="PAY_AS_YOU_GO"
                  checked={values.service.type === 'PAY_AS_YOU_GO'}
                  onChange={(event, data) => {
                    onChange(event, data)
                    setFieldValue(data.name, data.value)
                    markServiceDefaultValues(true, setFieldValue)
                  }}
                />
                <Form.Field
                  control={Radio}
                  name="service.type"
                  label="Sopimuspysäköinti"
                  value="SUBSCRIPTION"
                  checked={values.service.type === 'SUBSCRIPTION'}
                  onChange={(event, data) => {
                    onChange(event, data)
                    setFieldValue(data.name, data.value)
                    markServiceDefaultValues(false, setFieldValue)
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <Checkbox
                name="service.publicService"
                label="Julkinen tuote"
                onClick={onCheckboxClicked}
                checked={values.service.publicService}
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                name="service.exceedingParkingCapacityAllowed"
                label="Maksimipysäköintimäärän ylittäminen sallittu"
                onClick={onCheckboxClicked}
                checked={values.service.exceedingParkingCapacityAllowed}
              />
            </Form.Field>
            <Form.Group inline>
              <Form.Field
                error={errors.service && !!errors.service.parkingPermitType}
              >
                <label>Pysäköintilupatyyppi</label>
                <Form.Field
                  control={Radio}
                  name="service.parkingPermitType"
                  label="Kertapysäköinti"
                  value="SESSION_BASED_PARKING_PERMIT"
                  checked={
                    values.service.parkingPermitType ===
                    'SESSION_BASED_PARKING_PERMIT'
                  }
                  onChange={(event, data) => {
                    onChange(event, data)
                    setFieldValue(data.name, data.value)
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Kapasiteetti"
                name="service.capacity"
                type="number"
                value={values.service.capacity}
                onChange={onChange}
              />
              <Form.Input
                label="Pysäköintikapasiteetti"
                name="service.parkingCapacity"
                type="number"
                value={values.service.parkingCapacity}
                onChange={onChange}
              />
              <Form.Input
                label="Ylibuukkauskerroin"
                name="service.userAssignmentOverbookingCoefficient"
                type="number"
                value={values.service.userAssignmentOverbookingCoefficient}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field>
                <label>Tuotteen kuvaus</label>
                <TextArea
                  placeholder="Tuotteen kuvaus"
                  name="service.description"
                  value={values.service.description}
                  onChange={onChange}
                  rows={25}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ServiceForm
