import { Button, Icon, List, Modal, Search } from 'semantic-ui-react'
import { FormattedMessage, useIntl } from 'react-intl'
import GenericMessage, {
  createErrorMessage
} from '../../components/GenericMessage'
import React, { useState } from 'react'
import { useMutation, useQuery } from '@tanstack/react-query'

import { search } from '../../services/Search'
import useAuthInfo from '../../hooks/useAuthInfo'
import { useDebounce } from 'use-debounce'
import userGroupsService from '../../services/UserGroups'

const UserGroupAddUserModal = ({
  userGroup,
  userGroupMemberships,
  onSubmit,
  onClose
}) => {
  const { operatorRealm } = useAuthInfo()
  const [userSearchValue, setUserSearchValue] = useState('')
  const [selectedUser, setSelectedUser] = useState('')
  const [userSearchDebounce] = useDebounce(userSearchValue, 500)
  const intl = useIntl()

  const searchUser = useQuery({
    queryKey: ['fetchUserSearchValue', userSearchDebounce],
    queryFn: () => search(operatorRealm, userSearchDebounce),
    enabled: userSearchDebounce?.length > 2
  })

  const results = searchUser.data
    ? searchUser.data
        .map((item) => {
          return {
            title: item.description,
            description: '',
            ref: item.ref,
            key: item.ref
          }
        })
        .filter(
          (item) =>
            !userGroupMemberships.some(
              (existingItem) => existingItem.user.ref === item.ref
            )
        )
    : []

  const { mutate: addUser, ...addUserMutation } = useMutation({
    mutationFn: () =>
      userGroupsService.addUserToGroup(
        selectedUser.ref,
        userGroup.ref,
        operatorRealm
      ),
    onSuccess: () => {
      onSubmit(selectedUser.title)
    }
  })

  return (
    <Modal size="tiny" open onClose={onClose}>
      <Modal.Header>
        <FormattedMessage
          id="userGroupAddUserModal.header"
          values={{ name: userGroup.name }}
        />
      </Modal.Header>
      <Modal.Content>
        <List>
          <List.Item>
            <Search
              minCharacters={3}
              input={{
                fluid: true,
                icon: 'search',
                iconPosition: 'left',
                placeholder: intl.formatMessage({
                  id: 'userGroupAddUserModal.search.placeholder'
                }),
                loading: searchUser.isFetching
              }}
              showNoResults={!searchUser.isFetching}
              noResultsMessage={
                searchUser.isFetching
                  ? intl.formatMessage({
                      id: 'userGroupAddUserModal.search.fetching'
                    })
                  : intl.formatMessage({
                      id: 'common.noResultsMessage'
                    })
              }
              onSearchChange={(ev, data) => setUserSearchValue(data.value)}
              onResultSelect={(e, data) => {
                setSelectedUser(data.result)
                setUserSearchValue(data.result.title)
              }}
              results={results}
            />
          </List.Item>
          <List.Item>
            {(selectedUser && (
              <Button
                style={{ marginTop: '1em', marginLeft: '1em' }}
                icon
                size="mini"
                labelPosition="right"
                onClick={() => setSelectedUser('')}
              >
                {selectedUser.title}
                <Icon name="close" />
              </Button>
            )) ||
              ''}
          </List.Item>
          <List.Item style={{ marginTop: '1em' }}>
            {addUserMutation.isError && (
              <GenericMessage
                message={createErrorMessage(
                  intl.formatMessage({
                    id: 'userGroupAddUserModal.error'
                  })
                )}
              />
            )}
          </List.Item>
        </List>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={onClose}>
          <FormattedMessage id="common.button.cancel" />
        </Button>
        <Button
          primary
          onClick={addUser}
          disabled={!selectedUser || addUserMutation.isPending}
          loading={addUserMutation.isPending}
        >
          <FormattedMessage id="common.button.accept" />
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default UserGroupAddUserModal
