import { get, post } from '../utils/api-renewed'

export const fetchSales = async (operatorRealm, queryValues) => {
  const path = operatorRealm
    ? `/operator/${operatorRealm}/sales/webPayment`
    : '/administration/sales/webPayment'

  return get(path, queryValues)
}

export const createDebtCollectionInvoice = async (
  operatorRealm,
  webPaymentData
) => {
  const path = operatorRealm
    ? `/operator/${operatorRealm}/create-debtcollection-invoice-from-webpayment`
    : '/administration/create-debtcollection-invoice-from-webpayment'

  const result = post(path, webPaymentData)
  return result
}

export default { fetchSales, createDebtCollectionInvoice }
