import { tablePaginationDirections } from '../../../services/utils/tablePagination'

// TODO: these could be used in other components also to filter/sort locations. Maybe move to utils?

export const filterByOperator = (operatorRef) => (item) =>
  item.operator && item.operator.ref === operatorRef

export const filterBySearchTerm = (searchTerm) => (item) => {
  if (!searchTerm) {
    return true
  }
  const lowerCaseSearch = searchTerm.toLocaleLowerCase()
  return (
    item.name.toLocaleLowerCase().includes(lowerCaseSearch) ||
    item.city.toLocaleLowerCase().includes(lowerCaseSearch) ||
    (item.operator &&
      item.operator.name.toLocaleLowerCase().includes(lowerCaseSearch))
  )
}

export const sortLocations = (pagination) => {
  const { sortColumn, direction } = pagination
  switch (sortColumn) {
    case 'sortByLocation':
      if (direction === tablePaginationDirections.ASC) {
        return (a, b) => a.name.localeCompare(b.name)
      }
      return (a, b) => b.name.localeCompare(a.name)
    case 'sortByCity':
      if (direction === tablePaginationDirections.ASC) {
        return (a, b) => a.city.localeCompare(b.city)
      }
      return (a, b) => b.city.localeCompare(a.city)

    default:
      return undefined
  }
}
