import {
  formatDate,
  formatPrice,
  grantedVoucherShape
} from '../../../../services/utils'

import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import React from 'react'
import { Table } from 'semantic-ui-react'
import TablePagination from '../../../../components/TablePagination'

const GrantedVouchersTable = ({
  vouchers,
  activePage,
  totalPages,
  changePage
}) => (
  <Table compact="very">
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>
          <FormattedMessage id="grantedVouchers.table.header.date" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="grantedVouchers.table.header.licensePlateNumber" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="grantedVouchers.table.header.phoneNumber" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="grantedVouchers.table.header.voucher" />
        </Table.HeaderCell>
        <Table.HeaderCell>
          <FormattedMessage id="grantedVouchers.table.header.price" />
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {vouchers.map(
        ({
          ref,
          grantedDate,
          phoneNumber,
          licensePlateNumber,
          description,
          price
        }) => (
          <Table.Row key={ref}>
            <Table.Cell>{formatDate(grantedDate)}</Table.Cell>
            <Table.Cell>{licensePlateNumber}</Table.Cell>
            <Table.Cell>{phoneNumber}</Table.Cell>
            <Table.Cell>{description}</Table.Cell>
            <Table.Cell>{formatPrice(price)}</Table.Cell>
          </Table.Row>
        )
      )}
    </Table.Body>
    <TablePagination
      colSpan={5}
      activePage={activePage}
      totalPages={totalPages}
      onPageChange={changePage}
    />
  </Table>
)

GrantedVouchersTable.propTypes = {
  vouchers: PropTypes.arrayOf(PropTypes.shape(grantedVoucherShape)).isRequired,
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  changePage: PropTypes.func.isRequired
}
export default GrantedVouchersTable
