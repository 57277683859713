import './HeaderSearch.scss'

import classNames from 'classnames'
import React, { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { Icon, Search } from 'semantic-ui-react'
import { useDebounce } from 'use-debounce'

import { fullPath } from '../../containers/RelativeLinkForCurrentUser/RelativeLinkForCurrentUser'
import useAuthInfo from '../../hooks/useAuthInfo'
import { search } from '../../services/Search'
import { getCategoryName, getResultsByType } from './helpers'

const HeaderSearch = () => {
  const navigate = useNavigate()
  const inputRef = React.useRef()
  const [open, setOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [debouncedSearchTerm] = useDebounce(searchTerm, 600)
  const { operatorRealm, isSuperOperator } = useAuthInfo()

  const query = useQuery({
    queryKey: ['globalSearch', debouncedSearchTerm],
    queryFn: () => search(operatorRealm, debouncedSearchTerm),
    enabled: debouncedSearchTerm.length > 2,
    staleTime: 300000 // Cache searchTerm results for 5 mins
  })

  const searchResults = query.data
    ? getResultsByType(query.data).reduce((acc, cur) => {
        acc[cur.type] = {
          name: getCategoryName(cur.type),
          results: cur.matches.map((c, i) => ({ key: i, as: 'span', ...c }))
        }
        return acc
      }, {})
    : {}

  return (
    <div className="Search">
      {!open && (
        <div
          className="Search--Icon"
          onClick={(ev) => {
            setOpen(true)
            inputRef.current.focus()
          }}
        >
          <Icon name="search" />
        </div>
      )}
      <div className={classNames({ 'Search--Input': true, active: open })}>
        <Search
          category
          fluid
          input={{
            ref: inputRef,
            icon: 'search',
            iconPosition: 'left',
            placeholder:
              'Syötä puhelinnumero, rekisteritunnus, kohde tai operaattori',
            loading: query.isInitialLoading
          }}
          value={searchTerm}
          className={classNames({ 'Search--Input--Hidden': !open })}
          noResultsMessage={
            query.isInitialLoading ? 'Hetkinen...' : 'Ei hakutuloksia'
          }
          onSearchChange={(ev, data) => setSearchTerm(data.value)}
          results={searchResults}
          resultRenderer={({ ...props }) => {
            let { title } = props
            if (props.extra) {
              props.extra.forEach((m) => (title += `, ${m}`))
            }
            return title
          }}
          onBlur={() => {
            setOpen(false)
          }}
          onResultSelect={(ev, data) => {
            setOpen(false)
            navigate(fullPath(isSuperOperator, data.result.href))
          }}
        />
      </div>
    </div>
  )
}

export default HeaderSearch
