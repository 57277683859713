import { Checkbox, Table } from 'semantic-ui-react'
import { MoovyLink, MoovyTable, TablePagination } from '../../components'
import { formatDate, formatPrice } from '../../services/utils'

import { FormattedMessage } from 'react-intl'
import React from 'react'
import RelativeLinkForCurrentUser from '../../containers/RelativeLinkForCurrentUser'
import moovyPagination from '../../components/TablePagination/pagination'
import { multiSelectPage } from '../../services/utils'

const BlocklistCandidatesTable = ({
  candidates,
  bloclistCandidatesQuery,
  currentPageHasCheckedRows,
  selectedRefs,
  multiSelect,
  setMultiSelect,
  onClickedInvoiceRef,
  onPageChange,
  totalPages,
  activePage,
  sorting,
  onSort
}) => {
  const renderUsername = (item) => {
    if (!item) return ''
    const firstName = item?.user?.firstName?.trim() || ''
    const lastName = item?.user?.lastName?.trim() || ''
    return (lastName && lastName + ' ' + firstName) || firstName
  }

  return (
    <MoovyTable
      compact="very"
      celled
      items={candidates}
      query={bloclistCandidatesQuery}
      sortable
    >
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell>
            <Checkbox
              onClick={() => {
                setMultiSelect(
                  multiSelectPage(
                    candidates.filter(
                      (item) => !item?.user.blockingPreventedStart
                    ),
                    multiSelect
                  )
                )
              }}
              checked={currentPageHasCheckedRows}
              style={{ verticalAlign: 'middle' }}
            />
          </Table.HeaderCell>
          <Table.HeaderCell
            width={'2'}
            sorted={moovyPagination.getSortDirection('userLastName', sorting)}
            onClick={() =>
              moovyPagination.sortColumn('userLastName', sorting, onSort)
            }
          >
            <FormattedMessage id="blocklistCandidates.table.header.name" />
          </Table.HeaderCell>
          <Table.HeaderCell collapsing>
            <FormattedMessage id="blocklistCandidates.table.header.phone" />
          </Table.HeaderCell>
          <Table.HeaderCell
            width={'1'}
            sorted={moovyPagination.getSortDirection(
              'totalFailedPrice',
              sorting
            )}
            onClick={() =>
              moovyPagination.sortColumn('totalFailedPrice', sorting, onSort)
            }
          >
            <FormattedMessage id="blocklistCandidates.table.header.totalFailedPrice" />
          </Table.HeaderCell>
          <Table.HeaderCell width={'1'}>
            <FormattedMessage id="blocklistCandidates.table.header.totalFailedInvoices" />
          </Table.HeaderCell>
          <Table.HeaderCell width={'1'}>
            <FormattedMessage id="blocklistCandidates.table.header.lastFailedInvoiceNumber" />
          </Table.HeaderCell>
          <Table.HeaderCell
            width={'1'}
            sorted={moovyPagination.getSortDirection(
              'adyenFinalFailureTime',
              sorting
            )}
            onClick={() =>
              moovyPagination.sortColumn(
                'adyenFinalFailureTime',
                sorting,
                onSort
              )
            }
          >
            <FormattedMessage id="blocklistCandidates.table.header.adyenFinalFailureTime" />
          </Table.HeaderCell>
          <Table.HeaderCell width={'1'}>
            <FormattedMessage id="blocklistCandidates.table.header.userBlockingPreventedStart" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="blocklistCandidates.table.header.userBlockingPreventedComment" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {candidates.map((row) => (
          <Table.Row key={row.ref}>
            <Table.Cell collapsing onClick={(event) => event.stopPropagation()}>
              <Checkbox
                checked={selectedRefs?.includes(row.ref)}
                disabled={!!row.user?.blockingPreventedStart}
                onClick={() =>
                  !!!row.user?.blockingPreventedStart && setMultiSelect([row])
                }
                style={{ verticalAlign: 'middle' }}
              />
            </Table.Cell>
            <Table.Cell>
              <RelativeLinkForCurrentUser to={`customers/${row.user.ref}`}>
                <>{renderUsername(row)}</>
              </RelativeLinkForCurrentUser>
            </Table.Cell>
            <Table.Cell>
              <RelativeLinkForCurrentUser to={`customers/${row.user.ref}`}>
                <>{row.user.phone}</>
              </RelativeLinkForCurrentUser>
            </Table.Cell>
            <Table.Cell>{formatPrice(row.totalFailedPrice)}</Table.Cell>
            <Table.Cell>{row.totalFailedInvoices}</Table.Cell>
            <Table.Cell onClick={(event) => event.stopPropagation()}>
              <MoovyLink
                onClick={() => onClickedInvoiceRef(row.lastFailedInvoice.ref)}
              >
                {row.lastFailedInvoice.invoiceNumber}
              </MoovyLink>
            </Table.Cell>
            <Table.Cell>
              {formatDate(row.lastFailedInvoice.adyenFinalFailureTime)}
            </Table.Cell>
            <Table.Cell>
              {formatDate(row.user.blockingPreventedStart)}
            </Table.Cell>
            <Table.Cell>{row.user.blockingPreventedComment}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TablePagination
        colSpan={9}
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </MoovyTable>
  )
}

export default BlocklistCandidatesTable
