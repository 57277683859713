import {
  CompensationInvoiceColumnInfo,
  MoovyTable,
  TablePagination
} from '../../../components'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  formatDate,
  formatPrice,
  getStringValue,
  invoiceShape,
  paymentMethod,
  sale
} from '../../../services/utils'

import { LocalizedInvoicingStatus } from '../../../components/MoovyLocalizedEnum'
import PropTypes from 'prop-types'
import React from 'react'
import { Table } from 'semantic-ui-react'
import { getSortDirection } from '../../../services/utils'

const renderHeadersForInvoicingAttemptCount = (invoiceType) => {
  if (invoiceType !== paymentMethod.VISMA) {
    return (
      <Table.HeaderCell>
        <FormattedMessage id="invoiceTable.header.invoicingAttemptCount" />
      </Table.HeaderCell>
    )
  }
  return null
}

const renderHeadersForLatestInvoicingAttemptTime = (invoiceType) => {
  if (invoiceType !== paymentMethod.VISMA) {
    return (
      <Table.HeaderCell>
        <FormattedMessage id="invoiceTable.header.invoicingAttemptTime" />
      </Table.HeaderCell>
    )
  }
  return null
}

const renderRowForInvoicingAttemptCount = (invoiceType, row) => {
  if (invoiceType !== paymentMethod.VISMA) {
    return <Table.Cell collapsing>{row.invoicingAttemptCount}</Table.Cell>
  }
  return null
}

const renderRowForInvoicingAttemptTime = (invoiceType, row) => {
  if (invoiceType !== paymentMethod.VISMA) {
    return (
      <Table.Cell collapsing>
        {formatDate(row.latestInvoicingAttemptTime)}
      </Table.Cell>
    )
  }
  return null
}

const renderHeadersForInvoiceType = (invoiceType) => {
  if (!invoiceType || invoiceType === 'ALL') {
    return (
      <Table.HeaderCell>
        <FormattedMessage id="invoiceTable.header.paymentMethod" />
      </Table.HeaderCell>
    )
  }
  if (invoiceType === paymentMethod.VISMA) {
    return (
      <Table.HeaderCell>
        <FormattedMessage id="invoiceTable.header.expired" />
      </Table.HeaderCell>
    )
  }
  return null
}

const renderRowForInvoiceType = (invoiceType, row) => {
  if (!invoiceType || invoiceType === 'ALL') {
    return (
      <Table.Cell>
        <FormattedMessage
          id={getStringValue(
            sale.paymentMethodTypes.localizationKeys,
            row.paymentMethodType
          )}
          defaultMessage={row.paymentMethodType}
        />
      </Table.Cell>
    )
  }
  if (invoiceType === paymentMethod.VISMA) {
    return (
      <Table.Cell>
        {row.vismaDueDate && formatDate(new Date(row.vismaDueDate))}
      </Table.Cell>
    )
  }
  return null
}

const InvoiceTable = ({
  invoices,
  activePage,
  totalPages,
  sorting,
  invoiceType,
  invoicesQuery,
  onRowClick,
  onPageChange,
  onSort
}) => {
  const intl = useIntl()

  return (
    <MoovyTable sortable selectable items={invoices} query={invoicesQuery}>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            sorted={getSortDirection('sortByInvoiceNumber', sorting)}
            onClick={() => onSort('sortByInvoiceNumber')}
          >
            <FormattedMessage id="invoiceTable.header.invoiceNumber" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={getSortDirection('sortByCreationTime', sorting)}
            onClick={() => onSort('sortByCreationTime')}
          >
            <FormattedMessage id="invoiceTable.header.creationTime" />
          </Table.HeaderCell>
          <Table.HeaderCell
            sorted={getSortDirection('sortByStatus', sorting)}
            onClick={() => onSort('sortByStatus')}
          >
            <FormattedMessage id="invoiceTable.header.status" />
          </Table.HeaderCell>
          {renderHeadersForInvoicingAttemptCount(invoiceType)}
          {renderHeadersForLatestInvoicingAttemptTime(invoiceType)}
          <Table.HeaderCell>
            <FormattedMessage id="invoiceTable.header.invoiceCount" />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <FormattedMessage id="invoiceTable.header.totalPrice" />
          </Table.HeaderCell>
          {renderHeadersForInvoiceType(invoiceType)}
          <Table.HeaderCell>
            <FormattedMessage id="invoiceTable.header.additionalInfo" />
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {invoices.map((row) => (
          <Table.Row
            key={row.ref}
            onClick={onRowClick(row)}
            negative={!!row.compensatedInvoice || row.totalPrice < 0}
          >
            <Table.Cell>
              <span
                onClick={(event) => {
                  event.stopPropagation()
                }}
              >
                {row.invoiceNumber}
              </span>
            </Table.Cell>
            <Table.Cell>{formatDate(row.creationTime)}</Table.Cell>
            <Table.Cell>
              <LocalizedInvoicingStatus
                value={row.status}
                paymentMethodType={row.paymentMethodType}
              />
            </Table.Cell>
            {renderRowForInvoicingAttemptCount(invoiceType, row)}
            {renderRowForInvoicingAttemptTime(invoiceType, row)}
            <Table.Cell>{row.rowCount}</Table.Cell>
            <Table.Cell>{formatPrice(row.totalPrice)}</Table.Cell>
            {renderRowForInvoiceType(invoiceType, row)}
            <Table.Cell>
              <CompensationInvoiceColumnInfo invoice={row} />
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
      <TablePagination
        colSpan={
          invoiceType === sale.paymentMethodTypes.PAYMENT_HIGHWAY_TRANSACTION
            ? 8
            : 9
        }
        activePage={activePage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </MoovyTable>
  )
}

InvoiceTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape(invoiceShape)),
  activePage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  sorting: PropTypes.object,
  invoiceType: PropTypes.string,
  invoicesQuery: PropTypes.object,
  onRowClick: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired
}

export default InvoiceTable
